import React, { useState } from 'react';
import SiteLayout from '../SiteLayout';
import { useTranslation } from 'react-i18next';
import Typo from '../components/Typo';
import chroma from 'chroma-js';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { toast } from 'react-toastify';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import TextField from '../../stories/general-components/TextField';
import Select from '../../stories/general-components/Select';

const Demo = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';

  const localStorageColor = localStorage.getItem('mainColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';


  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  const [formData, setFormData] = useState({
    page: 'demo',
  });

  const handleFieldChange = (value, fieldName) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const handleSendForm = async () => {
    try {
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `https://hook.us1.make.com/z0wn6qfhf6jihsbq82e2jgl2at763gxg`,
        body: formData,
      });
    } catch (error) {
      console.error('Error', error);
    }
    toast.success(t('sent'));
    setFormData({
      page: 'demo',
      business: '',
      email: '',
      phone: '',
      available: '',
      product: '',
      message: '',
    });
  };

  const pageCode = 'demo';
  return (
    <SiteLayout
      helmetData={{
        name: t(pageCode + 'SEO'),
        description: t(pageCode + 'SEODesc'),
      }}
    >
      <div className="container">
        <div className="row py-5 mt-5">
          <div className="col-md-6 col-12 py-5 mt-5 px-5 align-left">
            <img
              src="/assets/website/v3/demo.jpeg"
              alt="Node"
              className="mb-3"
              height={200}
              style={{ borderRadius: '15px' }}
            />
            <div>
              <Typo text={t(pageCode + 'Title')} variant="h1" />
            </div>
            <div>
              <Typo
                text={t(pageCode + 'Sub')}
                className="mt-4"
                variant="body2"
              />
            </div>
            <div>
              <Typo text={t(pageCode + 'Text')} className="mt-3" variant="p" />
            </div>
          </div>{' '}
          <div className="col-md-6 col-12 mt-4">
            <div
              style={{
                backgroundColor: isDarkMode ? '#00000090' : '#FFFFFF',
                borderRadius: '15px',
                padding: isMobile ? '27px' : '35px',
              }}
            >
              <TextField
                value={formData?.business}
                label={t('business')}
                keyStructure={'business'}
                onChange={(e) => handleFieldChange(e.target.value, 'business')}
              />
              <TextField
                value={formData?.email}
                label={t('email')}
                type="email"
                keyStructure={'email'}
                onChange={(e) => handleFieldChange(e.target.value, 'email')}
              />
              <TextField
                value={formData?.phone}
                label={t('phone')}
                type="phone"
                keyStructure={'phone'}
                onChange={(e) => handleFieldChange(e.target.value, 'phone')}
              />
              <Select
                value={formData?.available}
                label={t('available')}
                keyStructure={'available'}
                noEmpty
                selections={[
                  {
                    id: 'MON',
                    value: 'MON',
                    label: t('monday'),
                  },
                  {
                    id: 'TUE',
                    value: 'TUE',
                    label: t('tuesday'),
                  },
                  {
                    id: 'WED',
                    value: 'WED',
                    label: t('wednesday'),
                  },
                  {
                    id: 'THU',
                    value: 'THU',
                    label: t('thursday'),
                  },
                  {
                    id: 'FRI',
                    value: 'FRI',
                    label: t('friday'),
                  },
                ]}
                onChange={(key, value) => handleFieldChange(value, 'available')}
              />
              <Select
                value={formData?.product}
                label={t('product')}
                keyStructure={'product'}
                noEmpty
                selections={[
                  {
                    id: 'BOS',
                    value: 'BOS',
                    label: 'BOS',
                  },
                  {
                    id: 'Growth',
                    value: 'Growth',
                    label: 'Growth',
                  },
                  {
                    id: 'Pulse',
                    value: 'Pulse',
                    label: 'Pulse',
                  },
                  {
                    id: 'Roam',
                    value: 'Roam',
                    label: 'Roam',
                  },
                ]}
                onChange={(key, value) => handleFieldChange(value, 'product')}
              />
              <TextField
                value={formData?.message}
                type="text"
                keyStructure={'message'}
                label={t('message')}
                onChange={(e) => handleFieldChange(e.target.value, 'message')}
                multiline
              />
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleSendForm}
                style={{
                  marginTop: '10px',
                  textTransform: 'none',
                  borderRadius: '50px',
                  padding: '8px 20px',
                  backgroundColor: darkMainColor,
                }}
              >
                {t('send')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </SiteLayout>
  );
};

export default Demo;
