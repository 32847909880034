import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typo from '../../components/Typo';
import Btn from '../../components/Btn';

const HeaderSection = ({ translationCode }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t, } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="container">
      <div
        className={isMobile ? 'row px-4' : 'row'}
        style={{ minHeight: '250px', paddingTop: isMobile ? '60px' : '125px' }}
      >
        <div className="col-12 col-md-6 mt-3 d-flex flex-column justify-content-between">
          <div className="mb-5">
            <div className="mt-3">
              <Typo
                variant={isMobile ? 'h2' : 'h1'}
                text={t(translationCode + 'Title')}
              />
            </div>
            <div className="mt-4">
              <Typo variant="subTitle" text={t(translationCode + 'Sub')} />
            </div>
          </div>

          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            elevation={0}
            sx={{ m: 0, p: 0, backgroundColor: 'transparent' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typo
                variant="smallTitle"
                text={t(translationCode + 'Bloc1Title')}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typo variant="p" text={t(translationCode + 'Bloc1Desc')} />
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
            elevation={0}
            sx={{ m: 0, p: 0, backgroundColor: 'transparent' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typo
                variant="smallTitle"
                text={t(translationCode + 'Bloc2Title')}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typo variant="p" text={t(translationCode + 'Bloc2Desc')} />
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
            elevation={0}
            sx={{ m: 0, p: 0, backgroundColor: 'transparent' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typo
                variant="smallTitle"
                text={t(translationCode + 'Bloc3Title')}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typo variant="p" text={t(translationCode + 'Bloc3Desc')} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}
            elevation={0}
            sx={{ m: 0, p: 0, backgroundColor: 'transparent' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typo
                variant="smallTitle"
                text={t(translationCode + 'Bloc4Title')}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typo variant="p" text={t(translationCode + 'Bloc4Desc')} />
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="col-12 col-md-6 mt-4 align-c">
          <img
            src={'/assets/website/v3/amanda.png'}
            width={isMobile ? 320 : 360}
            alt={t('imageAmandaConsultant')}
            style={{ borderRadius: '20px' }}
          />
        </div>
      </div>
      <div className="align-c" style={{ marginTop: '30px' }}>
        <div className="row align-c mb-5 mt-5">
          <Btn
            text={t(translationCode + 'CTA')}
            onClick={() => navigate('/contact')}
          />
        </div>
        <div className="mt-5 align-c">
          <div>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '70px',
                gap: { xs: 2, sm: 6 },
              }}
            >
              <Box
                component="img"
                src="/assets/website/v3/garex.png"
                alt="Garex Logo"
                sx={{ height: { xs: '30px', sm: '35px' }, maxWidth: '100%' }}
              />
              <Box
                component="img"
                src="/assets/website/v3/point-s.png"
                alt="Point S Logo"
                sx={{ height: { xs: '30px', sm: '35px' }, maxWidth: '100%' }}
              />
              <Box
                component="img"
                src="/assets/website/v3/packmat.png"
                alt="Packmat Logo"
                sx={{
                  height: { xs: '20px', sm: '25px' },
                  maxWidth: '100%',
                  display: { xs: 'block', md: 'block' },
                }}
              />
              <Box
                component="img"
                src="/assets/website/v3/trivel.png"
                alt="Trivel Logo"
                sx={{
                  height: { xs: '30px', sm: '35px' },
                  maxWidth: '100%',
                  display: { xs: 'block', md: 'block' },
                }}
              />
              <Box
                component="img"
                src="/assets/website/v3/automod.png"
                alt="Automod Logo"
                sx={{
                  height: { xs: '30px', sm: '35px' },
                  maxWidth: '100%',
                  marginTop: { xs: '-2px', sm: '-4px' },
                  display: { xs: 'block', md: 'block' },
                }}
              />
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;
