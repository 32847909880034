import React, { } from 'react';
import SiteLayout from '../SiteLayout';
import Perfo from '../components/bos/Perfo';
import BlueLinkCTA from '../components/bos/BlueLinkCTA';
import DragDropBos from '../components/bos/DragDropBos';
import CreateElement from '../components/bos/CreateElement';
import Display from '../components/bos/Display';
import { useTranslation } from 'react-i18next';

const PerformBos = () => {
  const { t } = useTranslation();
  const pageCode = 'perfoBOS';
  return (
    <div>
      <SiteLayout
        helmetData={{
          name: t(pageCode + 'SEO'),
          description: t(pageCode + 'SEODesc'),
        }}
        themeLayout={'dark'}
        themePosition={80}
        backgroundPage="full"
      >
        <div className="mt-5 mb-5">
          <Perfo translationCode={pageCode + 'Header'} />
        </div>
        <div className="mt-5 mb-5">
          <DragDropBos translationCode={pageCode + 'DragDrop'} />
        </div>
        <div className="mt-5 mb-5">
          <CreateElement translationCode={pageCode + 'Create'} />
        </div>
        <div className="mt-5 mb-5">
          <Display translationCode={pageCode + 'Display'} />
        </div>
        <div className="mt-5 mb-5">
          <BlueLinkCTA translationCode={pageCode + 'BlueLink'} />
        </div>
      </SiteLayout>
    </div>
  );
};

export default PerformBos;
