import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';

function PrivateRoute({ children }) {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  const onCall = useSelector((state) => state.core.onCall);

  // Attach listener on component mount and clean it up on unmount
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticated(true);
        // Perform additional user setup if needed
      } else {
        setAuthenticated(false);
      }
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  if (loading) {
    return 'LOADING';
  }

  if (!authenticated) {
    return <Navigate to="/signin" />;
  }

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  return (
    <>
      <div
        style={{
          zIndex: onCall ? 99999999999 : 0,
          position: 'absolute',
        }}
      ></div>
      {children}
    </>
  );
}

export default PrivateRoute;
