import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

//utilities
import { useTranslation } from 'react-i18next';
import Signature from '../../general-components/Signature';
import nodeAxiosFirebase from '../../../utils/nodeAxiosFirebase';

const SignaturePad = ({ }) => {
  const { t } = useTranslation();
  const [signatureField, setSignatureField] = useState(null);
  const [value, setValue] = useState('');

  const elementData = useSelector(
    (state) => state.element.singleElementDetails
  );

  const currentUser = useSelector((state) => state.core.user);
  const businessFirebaseID = localStorage.getItem('businessId');

  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );

  const structures = businessStructure?.structures;
  const currentStructure = structures?.find(
    (s) => s.id === elementData?.structureId
  );

  useEffect(() => {
    //find the signature field
    if (elementData) {
      const signatureField = currentStructure?.fields.find(
        (field) => field.typeData === 'signature'
      );
      setSignatureField(signatureField);
      const fieldValue = elementData?.[signatureField?.value];
      setValue(fieldValue);
    }
  }, [elementData, currentStructure]);

  const handleFieldBlur = (url) => {
    setValue(null);
  };

  const handleFieldChange = async (url) => {
    try {
      setValue(url);
      let formatedPath = elementData?.documentPath.split('/');
      formatedPath = formatedPath
        .filter((part) => part !== elementData?.id)
        .join('/');

      await nodeAxiosFirebase({
        t,
        method: 'PATCH',
        url: `coreSeqV3`,
        body: {
          documentId: elementData?.id,
          elementPath: formatedPath,
          key: signatureField?.value,
          value: url,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <Signature
        fieldKey={signatureField?.value}
        fullWidth
        value={value}
        onChange={handleFieldChange}
        onBlur={handleFieldBlur}
      />
    </div>
  );
};
export default SignaturePad;
