import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormControl, InputLabel, List, ListItem, ListItemText, Select, TextField, Button } from "@mui/material";
import Box from '@mui/material/Box';

// Components
import { useDrop } from 'react-dnd';
import GeneralText from '../../../stories/general-components/GeneralText';
import Lottie from 'react-lottie';
import animationData from '../../../lotties/empty-box';
import {
  DeleteOutlined, FormatSizeOutlined, LabelOutlined, LineWeightOutlined, MoreVert,
  PhotoSizeSelectSmallOutlined, SettingsOutlined, WidthFull, DeleteForeverOutlined,
  AddCircleOutline
} from '@mui/icons-material';
import { Divider, Menu, MenuItem, Slider, Typography, Tooltip } from '@mui/material';
import BusinessInformationTag from '../../../components/@generalComponents/BusinessInformationTag';
import CardItems from '../../../components/finances/invoice/CardItems';
import QrCodeGen from '../../../components/@generalComponents/QrCodeGen';
import IconUploader from '../../../components/@generalComponents/IconUploader';
import moment from 'moment';
import ReportProfile from '../../../components/@generalComponents/ReportProfile';

// --- DroppableSection (Unchanged Internally) ---
const DroppableSection = ({ sectionId, components, setComponents, elementData, editMode }) => {
  const { t, } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [tempPrefix, setTempPrefix] = useState('');
  const [tempSuffix, setTempSuffix] = useState('');

  const businessStructure = useSelector((state) => state.core.businessStructure);
  const structures = businessStructure?.structures;
  const currentStructure = structures?.find((structure) => structure?.id === elementData?.structureDetails?.id) || {};

  const [{ isOver }, drop] = useDrop(() => ({ accept: "component", drop: (item) => addComponentToSection(item, sectionId), collect: (monitor) => ({ isOver: !!monitor.isOver(), }), }));

  const addComponentToSection = (item, sectionId) => { setComponents((prev) => { const currentSection = prev[sectionId] || []; let newItem = { ...item }; if (item?.value === 'freeText') { newItem.freeText = ''; newItem.fontSize = 12; newItem.size = 'regular'; } else if (item.type === 'field' || item.type === 'text' || item.type === 'string' || item.type === 'number' || item.type === 'date' || item.type === 'date-time') { newItem.fontSize = 12; newItem.size = 'regular'; newItem.prefix = ''; newItem.suffix = ''; } else if (item.value === 'image') { newItem.size = 6; newItem.src = ''; } else if (item.value === 'space') { newItem.size = 4; } else if (item.value === 'html') { newItem.htmlContent = ''; } else if (item.value === 'list') { newItem.data = 'child'; newItem.columns = []; newItem.structureId = ''; } return { ...prev, [sectionId]: [...currentSection, newItem] }; }); };
  const removeComponentFromSection = (indexToRemove, sectionId) => { handleCloseMenu(); setComponents((prev) => ({ ...prev, [sectionId]: (prev[sectionId] || []).filter((_, i) => i !== indexToRemove) })); };
  const handleCloseMenu = () => { setAnchorEl(null); setMenuOpen(false); setTempPrefix(''); setTempSuffix(''); };
  const updateComponentProp = (index, propName, value) => { setComponents(prev => ({ ...prev, [sectionId]: (prev[sectionId] || []).map((comp, i) => i === index ? { ...comp, [propName]: value } : comp) })); setSelectedComponent(prev => ({ ...prev, [propName]: value })); };
  const handleSizeChange = (event, newValue) => { if (selectedIndex !== null) updateComponentProp(selectedIndex, 'fontSize', newValue); };
  const handleSizeImgChange = (event, newValue) => { if (selectedIndex !== null) updateComponentProp(selectedIndex, 'size', newValue); };
  const handleWeightChange = (event, newValue) => { if (selectedIndex !== null) { const weightMap = { 0: 'regular', 1: 'medium', 2: 'bold' }; updateComponentProp(selectedIndex, 'size', weightMap[newValue]); } };
  const handleOpenMenu = (event, index) => { const component = (components[sectionId] || [])[index]; if (!component) return; setAnchorEl(event.currentTarget); setSelectedComponent(component); setSelectedIndex(index); setTempPrefix(component.prefix || ''); setTempSuffix(component.suffix || ''); setMenuOpen(true); };
  const handleChangePrefix = (event) => { setTempPrefix(event.target.value); };
  const handleChangeSuffix = (event) => { setTempSuffix(event.target.value); };
  const handlePrefixBlur = () => { if (selectedIndex !== null) updateComponentProp(selectedIndex, 'prefix', tempPrefix); };
  const handleSuffixBlur = () => { if (selectedIndex !== null) updateComponentProp(selectedIndex, 'suffix', tempSuffix); };
  const handleFreeTextChange = (event, index) => { updateComponentProp(index, 'freeText', event.target.value); };
  const handleListTypeChange = (event, index) => { updateComponentProp(index, 'data', event.target.value); };
  const handleColumnSelection = (event, index) => { updateComponentProp(index, 'columns', event.target.value ?? []); };
  const handleListStructureChange = (event, index) => { updateComponentProp(index, 'structureId', event.target.value); updateComponentProp(index, 'columns', []); };
  const handleHtmlChange = (event, index) => { updateComponentProp(index, 'htmlContent', event.target.value); };
  const imageUploaded = (url, index) => { if (url?.fileUrl) updateComponentProp(index, 'src', url.fileUrl); };
  const getNestedValue = (obj, path) => { if (!obj || typeof path !== 'string' || path === '') return '-'; try { const value = path.split('.').reduce((acc, part) => acc && acc[part], obj); return value !== undefined && value !== null ? value : '-'; } catch (e) { console.error(`Error accessing path "${path}" in object:`, obj, e); return '-'; } };
  const formatFirestoreValue = (value) => { if (value && typeof value === 'object' && value._seconds !== undefined && value._nanoseconds !== undefined) { try { return moment.unix(value._seconds).format('YYYY-MM-DD HH:mm'); } catch (e) { return 'Invalid Date'; } } if (Array.isArray(value)) { return value.join(', '); } if (typeof value === 'boolean') { return value ? t('yes') : t('no'); } return value !== null && value !== undefined ? String(value) : '-'; };
  const contactStructureName = useMemo(() => structures?.find(s => s.collectionField === 'contacts')?.name || t('contacts'), [structures, t]);
  const profilesStructuresNames = useMemo(() => structures?.filter(s => s.collectionField === 'profiles').map(s => s.name) || [], [structures]);
  const sectionComponents = components[sectionId] || [];

  return (
    <div ref={drop} style={{ backgroundColor: !editMode ? 'transparent' : isOver ? '#e0e0e0' : '#f9f9f9', padding: '10px', minHeight: editMode ? '60px' : 'auto', height: '100%', borderRadius: '8px', border: editMode ? '1px dashed #ccc' : 'none', transition: 'background-color 0.3s ease', position: 'relative', display: 'flex', flexDirection: 'column', width: '100%' }}>
      {sectionComponents?.length === 0 && editMode && (<Typography variant="caption" color="textSecondary" sx={{ margin: 'auto', fontStyle: 'italic' }}> {sectionId === 'header' ? t('dropComponentsHereHeader') : t('dropComponentsHere')} </Typography>)}
      {sectionComponents?.map((comp, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '3px', padding: editMode ? '5px' : '0', backgroundColor: editMode ? '#ffffff' : 'transparent', borderRadius: '4px', border: editMode ? '1px solid #eee' : 'none' }}>
          <div className={editMode ? 'col-11' : 'col-12'} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {(comp?.type === 'field' || comp?.type === 'string' || comp?.type === 'geo' || comp?.type === 'text' || comp?.type === 'number') && (<div className='d-flex align-items-center'> {comp?.prefix && (<GeneralText text={`${comp.prefix} `} primary={false} color={sectionId === 'header' ? '#FFF' : '#000'} fontSize={`${comp?.fontSize || 12}px`} size={comp?.size || 'regular'} />)} <GeneralText text={(comp?.value === 'financesTotal' || comp?.value === 'financesSubtotal' || comp?.value === 'financesBalance') ? ((Number(getNestedValue(elementData, comp?.value)) / 10000)?.toFixed(2) + ' $') : getNestedValue(elementData, comp?.value)} primary={false} color={sectionId === 'header' ? '#FFF' : '#000'} fontSize={`${comp?.fontSize || 12}px`} size={comp?.size || 'regular'} type={comp?.type} /> {comp?.suffix && (<GeneralText text={` ${comp.suffix}`} primary={false} color={sectionId === 'header' ? '#FFF' : '#000'} fontSize={`${comp?.fontSize || 12}px`} size={comp?.size || 'regular'} />)} </div>)}
            {(comp?.value === 'note') && (<div className='d-flex align-items-center'> {comp?.prefix && (<GeneralText text={`${comp.prefix} `} primary={false} color={sectionId === 'header' ? '#FFF' : '#000'} fontSize={`${comp?.fontSize || 12}px`} size={comp?.size || 'regular'} />)} <GeneralText text={getNestedValue(elementData, comp?.value)} primary={false} markdown color={sectionId === 'header' ? '#FFF' : '#000'} fontSize={`${comp?.fontSize || 12}px`} size={comp?.size || 'regular'} type={comp?.type} /> {comp?.suffix && (<GeneralText text={` ${comp.suffix}`} primary={false} color={sectionId === 'header' ? '#FFF' : '#000'} fontSize={`${comp?.fontSize || 12}px`} size={comp?.size || 'regular'} />)} </div>)}
            {(comp?.type === 'date-time' || comp?.type === 'date') && (<div className='d-flex align-items-center'> {comp?.prefix && (<GeneralText text={`${comp.prefix} `} primary={false} color={sectionId === 'header' ? '#FFF' : '#000'} fontSize={`${comp?.fontSize || 12}px`} size={comp?.size || 'regular'} />)} <GeneralText text={getNestedValue(elementData, comp?.value)} primary={false} color={sectionId === 'header' ? '#FFF' : '#000'} fontSize={`${comp?.fontSize || 12}px`} size={comp?.size || 'regular'} type={comp?.type} /> {comp?.suffix && (<GeneralText text={` ${comp.suffix}`} primary={false} color={sectionId === 'header' ? '#FFF' : '#000'} fontSize={`${comp?.fontSize || 12}px`} size={comp?.size || 'regular'} />)} </div>)}
            {comp?.value === 'freeText' && (editMode ? (<TextField variant="standard" fullWidth multiline size="small" value={comp.freeText || ''} onChange={(e) => handleFreeTextChange(e, index)} placeholder={t('enterText')} InputProps={{ disableUnderline: true }} sx={{ border: 'none', padding: '2px 4px', '& .MuiInputBase-root': { fontSize: `${comp?.fontSize || 12}px`, fontWeight: comp?.size === 'bold' ? 'bold' : (comp?.size === 'medium' ? 500 : 'normal') } }} />) : (<div className='d-flex align-items-center'> {comp?.prefix && (<GeneralText text={`${comp.prefix} `} primary={false} color={sectionId === 'header' ? '#FFF' : '#000'} fontSize={`${comp?.fontSize || 12}px`} size={comp?.size || 'regular'} />)} <GeneralText text={comp.freeText} primary={false} color={sectionId === 'header' ? '#FFF' : '#000'} fontSize={`${comp?.fontSize || 12}px`} size={comp?.size || 'regular'} /> {comp?.suffix && (<GeneralText text={` ${comp.suffix}`} primary={false} color={sectionId === 'header' ? '#FFF' : '#000'} fontSize={`${comp?.fontSize || 12}px`} size={comp?.size || 'regular'} />)} </div>))}
            {(comp?.value === 'assignedToId') && (<div className='d-flex align-items-center'> {comp?.prefix && (<GeneralText text={`${comp.prefix} `} primary={false} color={sectionId === 'header' ? '#FFF' : '#000'} fontSize={`${comp?.fontSize || 12}px`} size={comp?.size || 'regular'} />)} <GeneralText text={elementData?.assignedToName || '-'} primary={false} color={sectionId === 'header' ? '#FFF' : '#000'} fontSize={`${comp?.fontSize || 12}px`} size={comp?.size || 'regular'} /> {comp?.suffix && (<GeneralText text={` ${comp.suffix}`} primary={false} color={sectionId === 'header' ? '#FFF' : '#000'} fontSize={`${comp?.fontSize || 12}px`} size={comp?.size || 'regular'} />)} </div>)}
            {(comp?.type === 'status') && (<div> <GeneralText text={getNestedValue(elementData, comp?.value)} structureId={comp?.structureId} primary={true} type={comp?.type} fontSize={`${comp?.fontSize || 10}px`} size={comp?.size || 'regular'} /> </div>)}
            {(comp?.value === 'space') && (<div style={{ height: `${(comp?.size || 4) * 4}px` }} />)}
            {comp?.value === 'businessDetails' && elementData?.businessData && (<BusinessInformationTag businessData={elementData.businessData} />)}
            {comp?.value === 'reportProfile' && elementData?.businessData && (<ReportProfile element={elementData} />)}
            {comp?.value === 'itemsTable' && (<CardItems items={elementData?.items || []} card={elementData} includeGroupsTotal={true} />)}
            {comp?.value === 'line' && (<Divider component={'div'} sx={{ width: '100%', borderColor: '#bdbdbd', marginY: '10px' }} />)}
            {comp?.value === 'qrCode' && elementData?.ownerId && elementData?.structureDetails?.name && (elementData?.documentIdentifiant || elementData?.id) && (<div style={{ padding: '10px 0' }}> <QrCodeGen url={`https://node.bluelink.cloud/redirect/${elementData.ownerId}/${elementData.structureDetails.name}/${elementData.structureDetails.id}/${elementData.documentIdentifiant || elementData.id}?accessCode=${elementData.accessCode || ''}&shared=true`} businessId={elementData.ownerId} size={100} /> </div>)}
            {comp?.value === 'html' && (editMode ? (<TextField variant="outlined" fullWidth multiline rows={4} size="small" value={comp?.htmlContent || ''} onChange={(e) => handleHtmlChange(e, index)} placeholder={t('enterHTML')} sx={{ fontFamily: 'monospace', fontSize: '12px' }} />) : (<div dangerouslySetInnerHTML={{ __html: comp?.htmlContent || '' }} style={{ width: '100%', overflowWrap: 'break-word' }} />))}
            {comp?.value === 'image' && (editMode ? (<div style={{ width: `${(comp?.size || 6) * 10}%`, padding: '10px', border: '1px dashed #ccc', textAlign: 'center' }}> {comp?.src ? (<img src={comp.src} alt={t('elementImage')} style={{ maxWidth: '100%', height: 'auto', display: 'block' }} />) : (<IconUploader onComplete={(url) => imageUploaded(url, index)} value={comp?.src} size='small' elementType='TEMPLATE-IMG' fieldType='media-single' fieldKey={`img-${index}`} />)} </div>) : (comp?.src ? <img src={comp.src} alt={t('elementImage')} style={{ width: `${(comp?.size || 6) * 10}%`, height: 'auto', display: 'block' }} /> : null))}
            {comp?.value === 'fixedHeader' && (<div style={{ width: '100%', background: '#eee', padding: '10px', marginBottom: '10px', borderBottom: '1px solid #ccc' }}> <div className='row'> <div className='col-8 align-left'> <GeneralText text={`${currentStructure?.name || ''} ${elementData?.data?.Pro_string477 || ''}`} primary={false} color={'#333'} fontSize={'16px'} size={'bold'} /> </div> <div className='col-4 align-right'> <GeneralText text={`${t('date')}: ${formatFirestoreValue(elementData?.timeStamp)}`} primary={false} color={'#555'} fontSize={'12px'} size={'regular'} /> <GeneralText text={`${t('expiration')}: ${formatFirestoreValue(elementData?.dueDate)}`} primary={false} color={'#555'} fontSize={'12px'} size={'regular'} /> </div> </div> </div>)}
            {comp?.value === "list" && (<>
              {editMode && (
                <div className='row'>
                  <div className='col-4 px-2'>
                    <FormControl fullWidth margin="normal" >
                      <InputLabel>{t("fromData")}</InputLabel>
                      <Select
                        value={comp?.data || ""}
                        onChange={(e) => handleListTypeChange(e, index, sectionId)}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: 200,
                              overflowY: 'auto',
                              borderRadius: '12px',
                            },
                          },
                        }}
                      >
                        <MenuItem value="child">{t("childrenData")}</MenuItem>
                        <MenuItem value="targetId">{contactStructureName || ''}</MenuItem>
                        {profilesStructuresNames?.length > 0 && (
                          <MenuItem value="targetProfileId">{profilesStructuresNames?.join(' - ') || ''}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </div>
                  <div className='col-4 px-2'>
                    <FormControl fullWidth margin="normal" >
                      <InputLabel>{t("structure")}</InputLabel>
                      <Select
                        value={comp?.structureId || ""}
                        onChange={(e) => handleListStructureChange(e, index, sectionId)}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: 200,
                              overflowY: 'auto',
                              borderRadius: '12px',
                            },
                          },
                        }}
                      >
                        {structures?.filter(
                          (structure) => comp?.data === 'targetId' ? structure?.collectionField !== 'contacts' : comp?.data === 'targetProfileId' ? structure?.collectionField !== 'profiles' : true
                        )?.map((structure) => (
                          <MenuItem key={structure?.id + '-' + structure?.collectionField} value={structure?.id + '-' + structure?.collectionField}>
                            {structure?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  {comp?.structureId && (
                    <div className='col-4 px-2'>
                      <FormControl fullWidth margin="normal" >
                        <InputLabel>{t("selectColumns")}</InputLabel>
                        <Select
                          multiple
                          value={comp?.columns ?? []}
                          onChange={(e) => handleColumnSelection(e, index, sectionId)}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: 200,
                                overflowY: 'auto',
                                borderRadius: '12px',
                              },
                            },
                          }}
                        >
                          <MenuItem value="name">{t("name")}</MenuItem>
                          <MenuItem value="attribute1">{t("attribute1")}</MenuItem>
                          <MenuItem value="attribute2">{t("attribute2")}</MenuItem>
                          <MenuItem value="attribute3">{t("attribute3")}</MenuItem>
                          <MenuItem value="attribute4">{t("attribute4")}</MenuItem>
                          <MenuItem value="status">{t("status")}</MenuItem>
                          <MenuItem value="note">{t("notes")}</MenuItem>
                          <MenuItem value="timeStamp">{t("timeStamp")}</MenuItem>
                          {(comp?.structureId?.split('-')[1] === 'items' || (comp?.structureId?.split('-')[1])?.toLowerCase() === 'cardsinvoiced' || (comp?.structureId?.split('-')[1])?.toLowerCase() === 'cardsuninvoiced') && (<MenuItem value="total">{t("total")}</MenuItem>)}
                          {(comp?.structureId?.split('-')[1] === 'items' || (comp?.structureId?.split('-')[1])?.toLowerCase() === 'cardsinvoiced' || (comp?.structureId?.split('-')[1])?.toLowerCase() === 'cardsuninvoiced') && (<MenuItem value="subtotal">{t("subtotal")}</MenuItem>)}
                          {comp?.structureId?.split('-')[1] === 'items' && (<MenuItem value="sku">{t("sku")}</MenuItem>)}
                          {comp?.structureId?.split('-')[1] === 'items' && (<MenuItem value="unitPrice">{t("unitPrice")}</MenuItem>)}
                          {comp?.structureId?.split('-')[1] === 'items' && (<MenuItem value="quantity">{t("quantity")}</MenuItem>)}
                          {comp?.structureId?.split('-')[1] === 'items' && (<MenuItem value="unity">{t("unity")}</MenuItem>)}
                          {(comp?.structureId?.split('-')[1] === 'nodies' || comp?.structureId?.split('-')[1] === 'tasks') && (<MenuItem value="sku">{t("targetDate")}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </div>)}
              {!editMode && (
                <div className='row'>
                  <List sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    padding: 0,
                    margin: 0
                  }}>
                    {elementData?.resolvedSections?.[sectionId]?.[index]?.resolvedValue?.map((item, i) => (
                      <ListItem
                        key={i}
                        divider
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '8px 16px'
                        }}
                      >
                        {elementData?.resolvedSections?.[sectionId]?.[index]?.columns?.map((column, indexCol) => (
                          <ListItemText
                            key={indexCol}
                            primaryTypographyProps={{ variant: 'body2' }}
                            primary={formatFirestoreValue(item[column])}
                            sx={{ flex: 1, textAlign: 'left' }}
                          />
                        ))}
                      </ListItem>
                    ))}
                  </List>
                </div>)}
            </>
            )}
          </div>
          {editMode && (<div style={{ position: 'absolute', right: 0 }} className="col-1 d-flex justify-content-end"> <IconButton size="small" onClick={(event) => handleOpenMenu(event, index)} sx={{ padding: '4px', marginLeft: '-40px' }}> <MoreVert fontSize="inherit" /> </IconButton> </div>)}
        </div>
      ))}
      <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleCloseMenu} PaperProps={{ sx: { borderRadius: '8px', boxShadow: 3, padding: '5px 0' } }} >
        {(selectedComponent?.value === 'freeText' || selectedComponent?.type === 'field' || selectedComponent?.value === 'assignedToId' || selectedComponent?.type === 'text' || selectedComponent?.type === 'string' || selectedComponent?.type === 'number' || selectedComponent?.type === 'date' || selectedComponent?.type === 'date-time') && (<MenuItem disableRipple divider sx={{ p: '8px 12px' }}> <div className="d-flex align-items-center" style={{ width: 180 }}> <FormatSizeOutlined sx={{ mr: 1.5, color: 'text.secondary' }} /> <Slider value={selectedComponent?.fontSize || 12} onChangeCommitted={handleSizeChange} step={1} min={8} max={24} marks={[{ value: 8 }, { value: 12 }, { value: 16 }, { value: 20 }, { value: 24 }]} size="small" valueLabelDisplay="auto" /> </div> </MenuItem>)}
        {(selectedComponent?.value === 'image' || selectedComponent?.value === 'space') && (<MenuItem disableRipple divider sx={{ p: '8px 12px' }}> <div className="d-flex align-items-center" style={{ width: 180 }}> <PhotoSizeSelectSmallOutlined sx={{ mr: 1.5, color: 'text.secondary' }} /> <Slider value={selectedComponent?.size || (selectedComponent?.value === 'image' ? 6 : 4)} onChangeCommitted={handleSizeImgChange} step={1} min={1} max={10} marks={[{ value: 2 }, { value: 4 }, { value: 6 }, { value: 8 }, { value: 10 }]} size="small" valueLabelFormat={(value) => selectedComponent?.value === 'image' ? `${value * 10}%` : `${value * 4}px`} valueLabelDisplay="auto" /> </div> </MenuItem>)}
        {(selectedComponent?.value === 'freeText' || selectedComponent?.type === 'field' || selectedComponent?.value === 'assignedToId' || selectedComponent?.type === 'text' || selectedComponent?.type === 'string' || selectedComponent?.type === 'number' || selectedComponent?.type === 'date' || selectedComponent?.type === 'date-time') && (<MenuItem disableRipple divider sx={{ p: '8px 12px' }}> <div className="d-flex align-items-center" style={{ width: 180 }}> <LineWeightOutlined sx={{ mr: 1.5, color: 'text.secondary' }} /> <Slider value={selectedComponent?.size === 'regular' ? 0 : (selectedComponent?.size === 'medium' ? 1 : 2)} onChangeCommitted={handleWeightChange} step={1} min={0} max={2} marks={[{ value: 0, label: '' }, { value: 1, label: '' }, { value: 2, label: '' }]} size="small" sx={{ '& .MuiSlider-markLabel': { fontSize: '10px' } }} /> </div> </MenuItem>)}
        {(selectedComponent?.type === 'field' || selectedComponent?.type === 'text' || selectedComponent?.value === 'assignedToId' || selectedComponent?.type === 'string' || selectedComponent?.type === 'number' || selectedComponent?.type === 'date' || selectedComponent?.type === 'date-time' || selectedComponent?.value === 'freeText') && (<MenuItem disableRipple sx={{ p: '4px 12px' }}> <TextField label={t('prefix')} variant="standard" size="small" value={tempPrefix} onChange={handleChangePrefix} onBlur={handlePrefixBlur} InputProps={{ startAdornment: <LabelOutlined sx={{ mr: 1, color: 'text.secondary' }} fontSize="small" /> }} sx={{ width: '100%' }} /> </MenuItem>)}
        {(selectedComponent?.type === 'field' || selectedComponent?.type === 'text' || selectedComponent?.value === 'assignedToId' || selectedComponent?.type === 'string' || selectedComponent?.type === 'number' || selectedComponent?.type === 'date' || selectedComponent?.type === 'date-time' || selectedComponent?.value === 'freeText') && (<MenuItem disableRipple divider sx={{ p: '4px 12px', pb: '8px' }}> <TextField label={t('suffix')} variant="standard" size="small" value={tempSuffix} onChange={handleChangeSuffix} onBlur={handleSuffixBlur} InputProps={{ startAdornment: <LabelOutlined sx={{ mr: 1, color: 'text.secondary' }} fontSize="small" /> }} sx={{ width: '100%' }} /> </MenuItem>)}
        <MenuItem onClick={() => removeComponentFromSection(selectedIndex, sectionId)} sx={{ p: '8px 12px', color: 'error.main' }}> <DeleteOutlined sx={{ mr: 1.5 }} /> <Typography variant="body2">{t('delete')}</Typography> </MenuItem>
      </Menu>
    </div>
  );
};


const PrintableElement = ({
  color, elementData, editMode, components, setComponents,
  sectionLayouts, setSectionLayouts, printAreaRef,
  onAddSection, onRemoveSection
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [sectionMenuAnchorEl, setSectionMenuAnchorEl] = useState(null);
  const [currentEditingSection, setCurrentEditingSection] = useState(null);

  const handleOpenSectionMenu = (event, sectionId) => { setCurrentEditingSection(sectionId); setSectionMenuAnchorEl(event.currentTarget); };
  const handleCloseSectionMenu = () => { setCurrentEditingSection(null); setSectionMenuAnchorEl(null); };
  const handleWidthChange = (event, newValue) => { if (currentEditingSection) setSectionLayouts(prev => ({ ...prev, [currentEditingSection]: newValue })); };

  const defaultOptions = { loop: true, autoplay: true, animationData: animationData, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } };

  const sectionIds = useMemo(() => Object.keys(components || {}), [components]);

  const headerId = sectionIds.find(id => id === 'header') || null;
  const footerId = sectionIds.find(id => id === 'footer') || null;
  const bodySectionIds = useMemo(() =>
    sectionIds
      .filter(id => id !== 'header' && id !== 'footer')
      .sort((a, b) => {
        const numA = parseInt(a.split('-')[1], 10);
        const numB = parseInt(b.split('-')[1], 10);
        if (!isNaN(numA) && !isNaN(numB)) return numA - numB;
        if (!isNaN(numA)) return -1;
        if (!isNaN(numB)) return 1;
        return a.localeCompare(b);
      })
    , [sectionIds]
  );

  const hasVisibleComponents = useMemo(() =>
    bodySectionIds.some(id => components[id] && components[id].length > 0) ||
    (headerId && components[headerId] && components[headerId].length > 0) ||
    (footerId && components[footerId] && components[footerId].length > 0)
    , [components, bodySectionIds, headerId, footerId]);


  const getSectionWidth = (id) => {
    if (id === 'header' || id === 'footer') return sectionLayouts[id] || 12;
    return sectionLayouts[id] ?? (id?.startsWith('section-') ? 6 : 12);
  };

  const renderSectionSettingsMenu = () => { const currentWidth = getSectionWidth(currentEditingSection); return (<Menu anchorEl={sectionMenuAnchorEl} open={Boolean(sectionMenuAnchorEl)} onClose={handleCloseSectionMenu} PaperProps={{ sx: { borderRadius: '8px', boxShadow: 3, padding: '10px 5px' } }} > <Typography variant="caption" sx={{ display: 'block', px: 2, mb: 1, color: 'text.secondary' }}> {t('sectionWidth')} ({currentEditingSection}) </Typography> <MenuItem disableRipple sx={{ p: '5px 15px' }}> <Box display="flex" alignItems="center" width={200}> <WidthFull sx={{ mr: 1.5, color: 'text.secondary' }} /> <Slider value={currentWidth} onChangeCommitted={handleWidthChange} step={1} min={1} max={12} marks={[{ value: 1 }, { value: 3 }, { value: 4 }, { value: 6 }, { value: 8 }, { value: 9 }, { value: 12 }]} size="small" valueLabelDisplay="auto" valueLabelFormat={(value) => `${value}/12`} /> </Box> </MenuItem> </Menu>); };

  return (
    <div className="invoice-content" id="print-area" ref={printAreaRef} style={{ backgroundColor: '#fff', padding: '15px', width: '100%', margin: '0 auto' }}>
      {!editMode && !hasVisibleComponents && (<div style={{ padding: '40px 20px', textAlign: 'center', minHeight: 200 }}> <Lottie options={defaultOptions} height={60} width={80} /> <Typography variant="h6" component="div" sx={{ mt: 2, mb: 1, fontSize: isTablet ? '13px' : '15px' }}> {t('emptyTemplate')} </Typography> <Typography variant="body2" component="div" color="textSecondary" sx={{ fontSize: isTablet ? '10px' : '11px' }}> {t('dragDropToBuild')} </Typography> </div>)}

      {(editMode || hasVisibleComponents) && (
        <>
          {headerId && (
            <Box sx={{ mb: 1.5, position: 'relative', backgroundColor: color, minHeight: '25px', borderRadius: '4px 4px 4px 4px', border: editMode ? '1px solid transparent' : 'none' }}>
              <DroppableSection sectionId={headerId} components={components} setComponents={setComponents} elementData={elementData} editMode={editMode} />
            </Box>
          )}


          <Box sx={{ display: 'flex', flexWrap: 'wrap', mx: -0.75, position: 'relative' }}>
            {bodySectionIds.map(sectionId => {
              const width = getSectionWidth(sectionId);
              const flexBasisValue = `${(width / 12) * 100}%`;
              const widthValue = width === 12 ? '100%' : flexBasisValue;

              return (
                <Box
                  key={sectionId}
                  sx={{
                    flexGrow: 0, flexShrink: 0, flexBasis: flexBasisValue,
                    width: widthValue, maxWidth: flexBasisValue,
                    px: 0.75, mb: 1.5, minHeight: editMode ? '60px' : 'auto',
                    position: 'relative', display: 'flex',
                    border: editMode ? `1px dashed ${theme.palette.divider}` : 'none',
                    '&:hover .section-controls': {
                      opacity: 1,
                    }
                  }}
                >
                  {editMode && (
                    <Box
                      className="section-controls"
                      sx={{
                        position: 'absolute', top: -10, right: 5, zIndex: 10,
                        display: 'flex', gap: 0.5,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        backdropFilter: 'blur(2px)',
                        borderRadius: '4px',
                        padding: '2px',
                        opacity: 0,
                        transition: 'opacity 0.2s ease-in-out',
                      }}
                    >
                      <Tooltip title={t('sectionWidthSettings')}>
                        <IconButton size="small" onClick={(e) => handleOpenSectionMenu(e, sectionId)} sx={{ color: 'action.active' }}>
                          <SettingsOutlined sx={{ fontSize: '1rem' }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('removeSection')}>
                        <IconButton size="small" onClick={() => onRemoveSection(sectionId)} sx={{ color: 'error.main' }}>
                          <DeleteForeverOutlined sx={{ fontSize: '1rem' }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}

                  <DroppableSection sectionId={sectionId} components={components} setComponents={setComponents} elementData={elementData} editMode={editMode} />
                </Box>
              );
            })}
          </Box>


          {editMode && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <Button
                variant="outlined"
                startIcon={<AddCircleOutline />}
                onClick={onAddSection}
                size="small"
              >
                {t('addSection')}
              </Button>
            </Box>
          )}


          {footerId && (
            <Box sx={{ mt: 1, position: 'relative', border: editMode ? '1px solid transparent' : 'none' }}>
              <DroppableSection sectionId={footerId} components={components} setComponents={setComponents} elementData={elementData} editMode={editMode} />
            </Box>
          )}


          <Divider component="div" sx={{ marginY: '20px' }} />
          <div className="d-flex align-items-center mb-2 mt-2"> <img src="/assets/v3/img/icon-node-grey.png" height={14} alt="Node" style={{ opacity: 0.6, marginRight: '8px' }} /> <div> <GeneralText primary={false} color="#555" fontSize="9px" text={'Intelligence Node Canada™'} /> <GeneralText primary={false} color="#777" fontSize="9px" text={`${t('allRightsReserved')} - ${new Date().getFullYear()}`} style={{ marginTop: '-4px' }} /> </div> </div>
          {renderSectionSettingsMenu()}
        </>
      )}
    </div>
  );
};

export default PrintableElement;