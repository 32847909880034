import React, { } from 'react';


const CTA = ({ translationCode }) => {



  return (
    <div
      style={{
        marginTop: '120px',
      }}
      className="container"
    ></div>
  );
};

export default CTA;
