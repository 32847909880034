import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import Typo from '../Typo';
import { useNavigate } from 'react-router-dom';

const PerfoCases = ({ translationCode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const naviate = useNavigate();

  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const secColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  const navigateOnboard = () => {
    naviate('/onboard');
  };

  return (
    <div
      style={{ marginTop: '150px', maxHeight: '500px' }}
      className="container"
    >
      <div className="row mt-5">
        <div className="col-md-6 col-12 align-c">
          <img src="/assets/website/v3/cards-perfo.png" height={300} />
        </div>
        <div className="col-md-6 col-12 py-5">
          <Typo
            text={t(translationCode + 'Title')}
            variant="h2"
            className="mt-5"
          />
          <Typo
            text={t(translationCode + 'Desc')}
            variant="subTitle"
            className="mt-4"
            sx={{ maxWidth: '490px' }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={navigateOnboard}
            disableElevation
            style={{
              marginTop: '40px',
              textTransform: 'none',
              borderRadius: '50px',
              padding: '8px 20px',
              backgroundColor: darkMainColor,
            }}
          >
            {t(translationCode + 'CTA')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PerfoCases;
