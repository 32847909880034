import React from 'react';
import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const Btn = ({ variant, text, onClick }) => {


  return (
    <Button
      disableElevation
      sx={{
        width: '300px',
        borderRadius: '50px',
        display: 'flex',
        fontSize: '14px',
        fontWeight: 500,
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
      }}
      variant="contained"
      color="primary"
      onClick={onClick}
    >
      {text}
      <ArrowForwardIcon sx={{ marginLeft: 1 }} />
    </Button>
  );
};

export default Btn;
