import React, { } from 'react';

import { useTranslation } from 'react-i18next';
import SiteLayout from '../SiteLayout';
import HeaderSection from '../components/growth/Header';
import StrategiesGrowth from '../components/growth/StrategiesGrowth';
import Services from '../components/growth/Services';
import Ecosystem from '../components/growth/Ecosystem';
import Intro from '../components/growth/Intro';

const HomeGrowth = () => {

  const { t, } = useTranslation();

  const pageCode = 'homeGrowth';

  return (
    <SiteLayout
      helmetData={{
        name: t(pageCode + 'SEO'),
        description: t(pageCode + 'SEODesc'),
      }}
    >
      <div>
        <div className="mt-5 mb-5">
          <HeaderSection translationCode={pageCode + 'Header'} />
        </div>
        <div className="mt-5 mb-5">
          <Intro translationCode={pageCode + 'Intro'} />
        </div>
        <div className="mt-5 mb-5">
          <StrategiesGrowth translationCode={pageCode + 'Strategies'} />
        </div>
        <div className="mt-5 mb-5">
          <Services translationCode={pageCode + 'Services'} />
        </div>

        <div className="mt-5 mb-5">
          <Ecosystem translationCode={pageCode + 'Services'} />
        </div>
        <div className="mt-5 mb-5">
          {/* <IndustrySection translationCode={pageCode + 'Insdus'} /> */}
        </div>
      </div>
    </SiteLayout>
  );
};

export default HomeGrowth;
