import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  CircularProgress,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import nodeAxiosFirebase from '../../../utils/nodeAxiosFirebase';
import Select from '../../../stories/general-components/Select';
import { toast } from 'react-toastify';

const StructureDup = ({ businessId, businesses }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [fromBusiness, setFromBusiness] = useState('');
  const [structures, setStructures] = useState([]);
  const [selectedStructure, setSelectedStructure] = useState('');

  const handleSelectBusiness = (value) => {
    setStructures([]);
    setSelectedStructure('');
    setFromBusiness(value);
  };

  const handleSelectStructure = (value) => {
    setSelectedStructure(value);
  };

  const handleGetData = async () => {
    setLoading(true);
    try {
      const nodeData = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/admin`,
        errorToast: t('errorLoadingDocument'),
        body: {
          type: 'structures',
          businessId: fromBusiness,
        },
      });
      const formattedStructures = nodeData?.data?.map((structure) => ({
        id: structure.id,
        value: structure.id,
        label: structure.name_fr,
      }));
      setStructures(formattedStructures);
    } catch (error) {
      console.error('Error getting node data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDuplicate = async () => {
    setLoading(true);
    try {
      const nodeData = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/admin`,
        errorToast: t('errorLoadingDocument'),
        body: {
          type: 'duplicate',
          businessId: fromBusiness,
          toBusinessId: businessId,
          structureId: selectedStructure,
        },
      });
      setSelectedStructure('');
      setStructures([]);
      setFromBusiness('');
      toast.success(t('structureDuplicated'));
    } catch (error) {
      console.error('Error data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fromBusiness) {
      handleGetData();
    }
  }, [fromBusiness]);

  return (
    <Box sx={{ p: 4 }}>
      {' '}
      <Select
        selections={businesses}
        onChange={(key, value) => handleSelectBusiness(value)}
        label={t('fromBusiness')}
      />
      <Divider component="div" sx={{ mt: 2 }} />
      {structures?.length > 0 && !loading && (
        <>
          <Select
            selections={structures}
            onChange={(key, value) => handleSelectStructure(value)}
            label={t('structureToDuplicate')}
          />
          <Button
            variant="contained"
            onClick={handleDuplicate}
            disabled={!selectedStructure || loading}
          >
            {loading ? <CircularProgress size={24} /> : t('duplicate')}
          </Button>
        </>
      )}
      {loading && <CircularProgress />}
    </Box>
  );
};

export default StructureDup;
