import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  CircularProgress,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import nodeAxiosFirebase from '../../../utils/nodeAxiosFirebase';
import Select from '../../../stories/general-components/Select';
import { toast } from 'react-toastify';

const ModuleDup = ({ businessId, businesses }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [fromBusiness, setFromBusiness] = useState('');
  const [modules, setModules] = useState([]);
  const [structures, setStructures] = useState([]);
  const [selectedModule, setSelectedModule] = useState('');
  const [selectedStructure, setSelectedStructure] = useState('');

  const handleSelectBusiness = (value) => {
    setModules([]);
    setSelectedModule('');
    setSelectedStructure('');
    setFromBusiness(value);
  };

  const handleSelectModule = (value) => {
    setSelectedModule(value);
  };

  const handleSelectStructure = (value) => {
    setSelectedStructure(value);
  };

  const handleGetData = async () => {
    setLoading(true);
    try {
      const nodeData = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/admin`,
        errorToast: t('errorLoadingDocument'),
        body: {
          type: 'modules',
          businessId: fromBusiness,
        },
      });
      const formattedModules = nodeData?.data?.map((module) => ({
        id: module.id,
        value: module.id,
        label: module.name_fr,
      }));
      setModules(formattedModules);
    } catch (error) {
      console.error('Error getting node data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetDataStructures = async () => {
    setLoading(true);
    try {
      const nodeData = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/admin`,
        errorToast: t('errorLoadingDocument'),
        body: {
          type: 'structure',
          businessId: businessId,
        },
      });
      const formattedStructures = nodeData?.data?.map((structure) => ({
        id: structure.id,
        value: structure.id,
        label: structure.name_fr,
      }));
      setStructures(formattedStructures);
    } catch (error) {
      console.error('Error getting node data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDuplicate = async () => {
    setLoading(true);
    try {
      const nodeData = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/admin`,
        errorToast: t('errorLoadingDocument'),
        body: {
          type: 'duplicate',
          businessId: fromBusiness,
          toBusinessId: businessId,
          hookedStructureId: selectedStructure,
          moduleId: selectedModule,
        },
      });
      setSelectedModule('');
      setModules([]);
      setFromBusiness('');
      toast.success(t('moduleDuplicated'));
    } catch (error) {
      console.error('Error data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fromBusiness) {
      handleGetData();
      handleGetDataStructures();
    }
  }, [fromBusiness]);

  return (
    <Box sx={{ p: 4 }}>
      {' '}
      <Select
        selections={businesses}
        onChange={(key, value) => handleSelectBusiness(value)}
        label={t('fromBusiness')}
      />
      <Divider component="div" sx={{ mt: 2 }} />
      {modules?.length > 0 && !loading && (
        <>
          <Select
            selections={modules}
            onChange={(key, value) => handleSelectModule(value)}
            label={t('moduleToDuplicate')}
          />
        </>
      )}
      {structures?.length > 0 && !loading && selectedModule && (
        <>
          <Select
            selections={structures}
            onChange={(key, value) => handleSelectStructure(value)}
            label={t('hookedStructure')}
          />
          <Button
            variant="contained"
            onClick={handleDuplicate}
            disabled={!selectedStructure || !selectedModule || loading}
          >
            {loading ? <CircularProgress size={24} /> : t('duplicate')}
          </Button>
        </>
      )}
      {loading && <CircularProgress />}
    </Box>
  );
};

export default ModuleDup;
