import * as React from 'react';
const IconsMenu = ({ mainColor, lightMainColor }) => (
  <svg
    width={206}
    height={38}
    viewBox="0 0 206 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={0.5}>
      <rect x={117} y={12} width={34} height={14} rx={7} fill={mainColor} />
    </g>
    <rect
      x={127}
      y={2}
      width={34}
      height={34}
      rx={17}
      fill={lightMainColor}
      fillOpacity={0.3}
    />
    <g filter="url(#filter0_ddd_129_229)">
      <circle cx={144} cy={19} r={10} fill={mainColor} />
    </g>
    <path
      d="M15.0501 10.4498C15.9784 11.3781 16.5 12.6372 16.5 13.95C16.5 15.2628 15.9784 16.5219 15.0501 17.4502C14.1218 18.3785 12.8628 18.9 11.55 18.9H4.94995V12.3C4.94995 10.4775 6.42741 9 8.24995 9H11.55C12.8628 9 14.1218 9.52152 15.0501 10.4498Z"
      stroke="#020202"
      strokeOpacity={0.4}
      strokeWidth={2.475}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6464 27.3501C17.6091 26.4218 18.15 25.1627 18.15 23.8499C18.15 22.5371 17.6091 21.278 16.6464 20.3497C15.6837 19.4214 14.3781 18.8999 13.0166 18.8999H4.94995V25.4999C4.94995 27.3224 6.42741 28.7999 8.24995 28.7999H13.0166C14.3781 28.7999 15.6837 28.2784 16.6464 27.3501Z"
      stroke="#020202"
      strokeOpacity={0.4}
      strokeWidth={2.475}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.65 9.09985H55.45"
      stroke="#020202"
      strokeOpacity={0.55}
      strokeWidth={2.475}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.9347 15.7H53.1655"
      stroke="#020202"
      strokeOpacity={0.55}
      strokeWidth={2.475}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.65 28.8999H55.45"
      stroke="#020202"
      strokeOpacity={0.55}
      strokeWidth={2.475}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.9347 22.2998H53.1655"
      stroke="#020202"
      strokeOpacity={0.55}
      strokeWidth={2.475}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.7106 9.2207V18.0806C76.7106 19.7938 77.3911 21.4368 78.6025 22.6482C79.8139 23.8596 81.457 24.5402 83.1701 24.5402C84.0184 24.5402 84.8584 24.3731 85.6421 24.0485C86.4258 23.7239 87.1379 23.2481 87.7377 22.6482C88.3376 22.0484 88.8134 21.3363 89.138 20.5526C89.4626 19.7689 89.6297 18.9289 89.6297 18.0806V9.2207"
      stroke="#020202"
      strokeWidth={2.475}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.1907 28.9792H91.9095"
      stroke="#020202"
      strokeWidth={2.475}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M183.65 19.9358V21.3878C183.65 23.2104 185.127 24.6878 186.95 24.6878H200.15C201.973 24.6878 203.45 23.2104 203.45 21.3878V19.9358C203.45 17.5598 202.658 15.1838 199.49 15.1838H187.61C184.442 15.1838 183.65 17.5598 183.65 19.9358Z"
      stroke="#020202"
      strokeOpacity={0.85}
      strokeWidth={2.475}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M187.214 10.4979V15.1839H199.886V10.4979C199.886 9.58663 199.147 8.8479 198.236 8.8479H188.864C187.953 8.8479 187.214 9.58663 187.214 10.4979Z"
      stroke="#020202"
      strokeOpacity={0.85}
      strokeWidth={2.475}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M198.132 22.7078C198.888 23.2902 199.242 24.9679 199.375 26.6036C199.449 27.5118 198.702 28.2518 197.791 28.2518H189.31C188.398 28.2518 187.651 27.5118 187.725 26.6036C187.858 24.9679 188.212 23.2902 188.969 22.7078H198.132Z"
      stroke="#020202"
      strokeOpacity={0.85}
      strokeWidth={2.475}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M198.482 19.0266L196.043 19.0266"
      stroke="#020202"
      strokeOpacity={0.85}
      strokeWidth={2.475}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <filter
        id="filter0_ddd_129_229"
        x={131}
        y={7}
        width={26}
        height={26}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={1}
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_129_229"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_129_229"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_129_229"
          result="effect2_dropShadow_129_229"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_129_229"
          result="effect3_dropShadow_129_229"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect3_dropShadow_129_229"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default IconsMenu;
