import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import animationData from '../../lotties/coins.json';
import Lottie from 'react-lottie';
import SiteLayout from '../SiteLayout';
import Typo from '../components/Typo';



const SelectPlan = () => {

    const { t, } = useTranslation();
    const theme = useTheme()
    const pageCode = 'startOnboard';
    const tableRef = useRef(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));



    const websiteData = useSelector((state) => state.website.data);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: false,
        },
    };

    useEffect(() => {
        if (!document.querySelector('script[src="https://js.stripe.com/v3/pricing-table.js"]')) {
            const script = document.createElement('script');
            script.src = 'https://js.stripe.com/v3/pricing-table.js';
            script.async = true;
            document.body.appendChild(script);
        }

        const timeout = setTimeout(() => {
            if (tableRef.current) {
                tableRef.current.innerHTML = `
              <stripe-pricing-table
                pricing-table-id="prctbl_1R5SKU039uUr8lBdi2dGQUo0"
                publishable-key="pk_live_51PpVVc039uUr8lBdyHrPXPgJbBDldnuBHEMqvbu3I0q05aWx5lQQrVpUEuo3qGEtWcSjx3TFkjdDHr0RYOKbn0fZ0029TBJ0u4">
              </stripe-pricing-table>
            `;
            }
        }, 500);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <SiteLayout
            helmetData={{
                name: t(pageCode + 'SEO'),
                description: t(pageCode + 'SEODesc'),
            }}
        >   <div className='container'>
                <div className="block-separator row">

                    <div className="mt-5 col-md-6 col-12">
                        <Typo
                            variant="h1"
                            text={t(pageCode + 'headerTitle')}
                        />
                        <Typo
                            variant="body1"
                            text={t(pageCode + 'headerSubTextTitle')}
                            className="mt-5"
                        />
                        <Typo
                            variant="body2"
                            className="mt-3"
                            text={t(pageCode + 'headerSubTextTitle2')}
                        />

                    </div>
                    <div className="col-md-6 col-12 align-c mb-4">
                        <div
                            style={{
                                zIndex: 0,
                                left: 0,
                                bottom: 0,

                            }}
                        >
                            <Lottie
                                options={defaultOptions}
                                height={isMobile ? 330 : 460}
                                width={isMobile ? 350 : 500}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#FFFFFF', paddingTop: '50px', paddingBottom: '50px' }} className="align-c px-5">
                <Typo
                    variant="h2"
                    className="mt-5 px-5"
                    text={websiteData?.pricing?.headerSec2Title || t('headerSec2Title')}
                />
                <Typo
                    className="mt-3"
                    variant="smallTitle"
                    text={
                        websiteData?.pricing?.headerSec2SubTitle ||
                        t('headerSec2SubTitle')
                    }
                />
            </div>

            <div style={{ paddingBottom: '120px' }} ref={tableRef} />
        </SiteLayout>

    );
};

export default SelectPlan;
