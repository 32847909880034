import React from 'react';
import { Box, Button, Card, CardContent, Grid } from '@mui/material';
import chroma from 'chroma-js';
import { useTheme } from '@mui/material/styles';
import PuzzleIcon from '@mui/icons-material/ExtensionOutlined';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutlineOutlined';
import SecurityIcon from '@mui/icons-material/SecurityOutlined';
import ShareIcon from '@mui/icons-material/ShareOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import Customization from './Customization';
import { useTranslation } from 'react-i18next';
import { ReceiptOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import IconsMenu from '../dynamicSVG/IconsMenu';
import Typo from '../Typo';

const FeatureCard = ({ feature, translation, t, isMobile }) => (
  <Card
    elevation={0}
    sx={{
      padding: '18px',
      borderRadius: '20px',
      border: '1px solid #1b1b1b14',
      overflow: 'hidden',
      height: '100%',
      position: 'relative',
    }}
  >
    {feature.img && feature.imgType !== 'component' && (
      <Box
        component="img"
        src={feature.img}
        alt={feature.title}
        sx={{
          position: 'absolute',
          zIndex: 0,
          objectFit: 'cover',
          ...(feature.imgPosition === 'right' && {
            top: 0,
            right: 0,
            paddingTop: '35px',
            height: '100%',
            maxWidth: '50%',
          }),
          ...(feature.imgPosition === 'bottom' && {
            bottom: isMobile ? -50 : 0,
            right: isMobile ? -200 : -50,
            width: '100%',
            maxHeight: isMobile ? '100%' : '50%',
          }),
        }}
      />
    )}
    {feature.img && feature.imgType === 'component' && (
      <Box
        sx={{
          position: 'absolute',
          zIndex: 0,
          ...(feature.imgPosition === 'right' && {
            top: 0,
            right: 0,
            paddingTop: '35px',
            height: '100%',
            maxWidth: '50%',
          }),
          ...(feature.imgPosition === 'bottom' && {
            bottom: 0,
            left: 0,
            width: '100%',
            maxHeight: '50%',
          }),
        }}
      >
        {feature.img}
      </Box>
    )}

    <CardContent sx={{ position: 'relative', zIndex: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 2,
        }}
      >
        {feature.icon}
      </Box>
      <Typo
        variant="subTitle"
        text={t(translation + 'Title')}
        className="mt-4"
        sx={{
          maxWidth: feature.img && isMobile ? '160px' : '100%',
        }}
        bold
      />
      <Typo
        variant="p"
        text={t(translation + 'Desc')}
        className="mt-3"
        sx={{
          maxWidth: feature.img && isMobile ? '170px' : '100%',
        }}
      />
    </CardContent>
  </Card>
);

const Features = ({ translationCode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const varColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const darkMainColor = chroma(mainColor).darken(1.2).hex();
  const lightMainColor = chroma(mainColor).brighten(1.2).hex();

  const navigateDemo = () => {
    navigate('/demo');
  };

  const features = [
    {
      icon: (
        <SecurityIcon
          sx={{
            fontSize: 35,
            color: '#FFFFFF',
            backgroundColor: mainColor,
            borderRadius: '50px',
            padding: '5px',
          }}
        />
      ),
      title: 'Sécuritaire selon les plus hauts standards',
      description:
        'Restriction IP, système de sécurité crypté et authentification chiffrée.',
    },
    {
      icon: (
        <ShareIcon
          sx={{
            fontSize: 35,
            color: '#FFFFFF',
            backgroundColor: mainColor,
            borderRadius: '50px',
            padding: '5px',
          }}
        />
      ),
      imgType: 'component',
      img: <IconsMenu mainColor={mainColor} lightMainColor={lightMainColor} />,
      imgPosition: 'right',
      title: 'Partagez l’important avec vos clients',
      description:
        'Créez des pages publiques à partir de n’importe quel élément.',
    },
    {
      icon: (
        <PeopleIcon
          sx={{
            fontSize: 35,
            color: '#FFFFFF',
            backgroundColor: mainColor,
            borderRadius: '50px',
            padding: '5px',
          }}
        />
      ),
      img: '/assets/website/v3/workspaces.png',
      imgPosition: 'bottom',
      title: 'Collaborez dans les espaces de travail',
      description: 'Sécuritaire selon les plus hauts standards.',
    },
    {
      icon: (
        <PuzzleIcon
          sx={{
            fontSize: 35,
            color: '#FFFFFF',
            backgroundColor: mainColor,
            borderRadius: '50px',
            padding: '5px',
          }}
        />
      ),
      imgType: 'component',
      img: <Customization mainColor={mainColor} />,
      imgPosition: 'right',
      title: 'Personnalisable comme bon vous semble',
      description:
        'Déterminez vos couleurs, placez vos blocs et ajoutez-y du contenu pour travailler efficacement.',
    },
    {
      icon: (
        <NotificationsIcon
          sx={{
            fontSize: 35,
            color: '#FFFFFF',
            backgroundColor: mainColor,
            borderRadius: '50px',
            padding: '5px',
          }}
        />
      ),
      title: 'Restez toujours informé',
      description:
        'La bonne notification, au bon moment. Collaborez avec votre équipe.',
    },
    {
      icon: (
        <ReceiptOutlined
          sx={{
            fontSize: 35,
            color: '#FFFFFF',
            backgroundColor: mainColor,
            borderRadius: '50px',
            padding: '5px',
          }}
        />
      ),
      title: 'Facturation complète',
      description: 'Sécuritaire selon les plus hauts standards.',
    },
    {
      icon: (
        <VisibilityIcon
          sx={{
            fontSize: 35,
            color: '#FFFFFF',
            backgroundColor: mainColor,
            borderRadius: '50px',
            padding: '5px',
          }}
        />
      ),
      title: 'Vos opérations à portée de main',
      description: 'Sécuritaire selon les plus hauts standards.',
    },
  ];

  return (
    <Box
      className="container"
      sx={{
        position: 'relative',
        width: '100%',
        marginTop: '200px',
        padding: 2,
      }}
    >
      <Button
        variant="contained"
        disableElevation
        onClick={navigateDemo}
        sx={{
          position: 'absolute',
          top: isMobile ? -20 : 30,
          right: !isMobile && 0,
          left: isMobile && 10,
          padding: '8px 20px',
          backgroundColor: darkMainColor,
          borderRadius: '30px',
          textTransform: 'none',
        }}
      >
        {t(translationCode + 'FeaturesCTA')}
      </Button>

      <Box sx={{ marginBottom: 5 }}>
        <Typo
          variant="h2"
          text={t(translationCode + 'Features')}
          className="mt-4"
          bold
        />
        <Typo
          variant="body2"
          text={t(translationCode + 'FeaturesDesc')}
          className="mt-4"
        />
      </Box>

      <Grid container spacing={2}>
        {/* First Row */}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <FeatureCard
                  feature={features[0]}
                  translation={translationCode + 'F0'}
                  t={t}
                  isMobile={isMobile}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <FeatureCard
                  feature={features[1]}
                  translation={translationCode + 'F1'}
                  t={t}
                  isMobile={isMobile}
                />
              </Grid>
              <Grid item xs={12}>
                <FeatureCard
                  feature={features[3]}
                  translation={translationCode + 'F2'}
                  t={t}
                  isMobile={isMobile}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <FeatureCard
              feature={features[2]}
              translation={translationCode + 'F3'}
              t={t}
              isMobile={isMobile}
            />
          </Grid>
        </Grid>

        {/* Second Row */}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={3}>
            <FeatureCard
              feature={features[4]}
              translation={translationCode + 'F4'}
              t={t}
              isMobile={isMobile}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FeatureCard
              feature={features[5]}
              translation={translationCode + 'F5'}
              t={t}
              isMobile={isMobile}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FeatureCard
              feature={features[6]}
              translation={translationCode + 'F6'}
              t={t}
              isMobile={isMobile}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Features;
