import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Typo from '../Typo';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';

const HeaderMapping = ({ translationCode }) => {
  const navigate = useNavigate();
  const { t, } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const localStorageColor = localStorage.getItem('mainColor');

  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';

  const handleContact = () => {
    navigate('/demo');
  };

  return (
    <div style={{ marginTop: '100px' }} className="container">
      <div className={isMobile ? 'row px-4' : 'row'}>
        <div className="col-md-5 col-12">
          {' '}
          <div className="mt-3">
            <Typo
              variant="h1"
              text={t(translationCode + 'Title')}
              sx={{ maxWidth: '420px' }}
            />
          </div>
          <div className="mt-4">
            <Typo variant="subTitle" text={t(translationCode + 'Sub')} />
          </div>
          <div className="mt-4">
            <Typo variant="body2" text={t(translationCode + 'Desc')} />
          </div>
          <div className="align-left mt-4">
            {' '}
            <Button
              variant="contained"
              disableElevation
              onClick={handleContact}
              sx={{
                backgroundColor: mainColor,
                borderRadius: '21px',
                textTransform: 'none',
                mt: 4,
              }}
            >
              {t(translationCode + 'CTA')}
            </Button>
          </div>
        </div>
        <div className="col-md-7 col-12 align-c px-5">
          <img src="/assets/website/v3/connect-bos-softwares.png" width={360} />
        </div>
      </div>
    </div>
  );
};

export default HeaderMapping;
