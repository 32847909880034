import React, { useEffect, useState } from 'react';

// utils
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

// components
import MainLayoutV2 from '../../layouts/MainLayoutV2';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import Blocks from '../../stories/layout-components/Block'; // Assuming Blocks is defined elsewhere
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from 'recharts';
import { Typography, Grid, Card, CardContent, Box, Chip } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { SentimentSatisfied } from '@mui/icons-material';

const EmployeePerformanceDashboard = () => {
  const { t } = useTranslation();
  const { structureId, boardId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeIndex = parseInt(searchParams.get('tab')) || 0;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [satisfactionData, setSatisfactionData] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const [employeePerformanceData, setEmployeePerformanceData] = useState([]);  // New data state
  const [hoursWorkedTrend, setHoursWorkedTrend] = useState([]);       // New data state
  const [absenteeismData, setAbsenteeismData] = useState([]);            // New data state

  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );
  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructures = businessStructure?.structures;

  const currentStructure = businessStructures?.find(
    (structure) => structure.id === structureId
  );

  const currentBoard = businessPreference?.menu?.find(
    (board) => board.id === boardId
  );

  const mainColor = businessPreference?.mainColor || '#1976d2'; // Default to a primary color
  const secColor = businessPreference?.secColor || '#90caf9'; // Default to a secondary color
  const COLORS = [mainColor, secColor, '#8884d8', '#82ca9d']; // Defined inside the component

  const handleDateSelected = (newDateRange, field) => {
    let start = newDateRange[0];
    let end = newDateRange[1];

    if (start) {
      start = start?.startOf('day').unix();
    }
    if (end) {
      end = end?.endOf('day').unix();
    }
    setStartDate(start);
    setEndDate(end);
  };

  const getBoardDetails = async () => {
    try {
      // Replace with your actual API call for employee performance data
      // const pageData = await nodeAxiosFirebase({
      //     t,
      //     method: 'POST',
      //     url: `yourEmployeePerformanceEndpoint`,
      //     body: {
      //         structureId: structureId,
      //         boardId: boardId,
      //         startDate: startDate || null,
      //         endDate: endDate || null,
      //     },
      // });

      const defaultData = getDefaultData();
      setEmployeePerformanceData(defaultData.employeePerformanceData);
      setHoursWorkedTrend(defaultData.hoursWorkedTrend);
      setAbsenteeismData(defaultData.absenteeismData);
      setTableData(defaultData.tableData);
      setSatisfactionData(defaultData.satisfactionData);
      setPerformanceData(defaultData.performanceData);
      setTrendData(defaultData.trendData);


    } catch (error) {
      console.error('Error fetching employee performance data', error);
      // Use default data in case of errors
      const defaultData = getDefaultData();
      setEmployeePerformanceData(defaultData.employeePerformanceData);
      setHoursWorkedTrend(defaultData.hoursWorkedTrend);
      setAbsenteeismData(defaultData.absenteeismData);
    }
  };

  useEffect(() => {
    getBoardDetails();
  }, [startDate, endDate, structureId, boardId]);

  const getStatus = (percentage) => {
    if (percentage >= 80) return 'green';
    if (percentage >= 60) return 'orange';
    return 'red';
  };

  const renderTableHeader = () => {
    return (
      <tr style={{ backgroundColor: mainColor, color: '#FFFFFF' }}>
        <th style={{ width: '100px', textAlign: 'left' }}>Directeur</th>
        <th style={{ width: '100px', textAlign: 'left' }}>Total requis</th>
        <th style={{ width: '100px', textAlign: 'left' }}>Complété</th>
        <th style={{ width: '100px', textAlign: 'left' }}>%</th>
      </tr>
    );
  };


  const renderTableRows = () => {
    if (!tableData || tableData.length === 0) {
      return (
        <tr>
          <td colSpan="4">Loading... or No Data</td>
        </tr>
      );
    }

    return tableData.map((row, index) => (
      <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#f9f9f9' }}>
        <td style={{ width: '100px' }}>{row.director || 'N/A'}</td>
        <td style={{ width: '100px' }}>{row.totalRequired || '0'}</td>
        <td style={{ width: '100px' }}>{row.completed || '0'}</td>
        <td style={{ width: '100px' }}>{row.percentage || '0'}</td>
      </tr>
    ));
  };




  const getDefaultData = () => {
    // Sample data.  Replace with your real data structure
    const employeePerformanceData = [
      { employee: 'John Doe', hoursWorked: 160, performanceRating: 4.5, absentDays: 2 },
      { employee: 'Jane Smith', hoursWorked: 150, performanceRating: 4.0, absentDays: 3 },
      { employee: 'Peter Jones', hoursWorked: 170, performanceRating: 4.8, absentDays: 1 },
      { employee: 'Alice Brown', hoursWorked: 140, performanceRating: 3.9, absentDays: 4 },
    ];

    const hoursWorkedTrend = [
      { name: 'Semaine 1', John: 35, Jane: 38, Peter: 40, Alice: 32 },
      { name: 'Semaine 2', John: 40, Jane: 35, Peter: 42, Alice: 30 },
      { name: 'Semaine 3', John: 38, Jane: 40, Peter: 38, Alice: 35 },
      { name: 'Semaine 4', John: 42, Jane: 37, Peter: 45, Alice: 33 },
    ];

    const absenteeismData = [
      { name: 'Jan', John: 2, Jane: 3, Peter: 1, Alice: 4 },
      { name: 'Fév', John: 1, Jane: 2, Peter: 2, Alice: 3 },
      { name: 'Mar', John: 0, Jane: 1, Peter: 0, Alice: 2 },
      { name: 'Avr', John: 2, Jane: 0, Peter: 1, Alice: 1 },
    ];

    const tableData = [
      { director: 'John Doe', totalRequired: 100, completed: 75, percentage: '75%' },
      { director: 'Jane Smith', totalRequired: 150, completed: 120, percentage: '80%' },
      { director: 'Peter Jones', totalRequired: 200, completed: 50, percentage: '25%' },
      { director: 'Alice Brown', totalRequired: 120, completed: 120, percentage: '100%' },
    ];

    const satisfactionData = [
      { name: 'Satisfaits', value: 65 },
      { name: 'Insatisfaits', value: 35 },
    ];

    const performanceData = [
      { name: 'Jan', Complétés: 400, Requis: 600 },
      { name: 'Fév', Complétés: 300, Requis: 500 },
      { name: 'Mar', Complétés: 500, Requis: 700 },
      { name: 'Avr', Complétés: 450, Requis: 650 },
    ];

    const trendData = [
      { name: 'Semaine 1', Complétés: 100 },
      { name: 'Semaine 2', Complétés: 120 },
      { name: 'Semaine 3', Complétés: 150 },
      { name: 'Semaine 4', Complétés: 130 },
    ];


    return { employeePerformanceData, hoursWorkedTrend, absenteeismData, tableData, satisfactionData, performanceData, trendData };
  };

  return (
    <MainLayoutV2
      icon={currentBoard?.icon}
      pageTitle={currentBoard?.name || ''}
      subTitle={currentStructure?.name || ''}
      actions={{
        action: handleDateSelected,
        dateField: 'timeStamp',
        rangeDates: 'multi',
      }}
    >
      <div style={{ height: '90vh', overflow: 'scroll' }}>
        {structureId === 'emvaFgPkdPDkSVGjD0Xr' ? (
          <Grid container spacing={1} mt={3}>



            <Grid item xs={12} md={6} style={{ paddingLeft: '25px' }}>
              <Card sx={{ backgroundColor: mainColor, color: 'white', padding: 1, borderRadius: '12px' }} elevation={0}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    {t('Total des heures travaillées')}
                  </Typography>
                  <Typography variant="h4" component="div">
                    {employeePerformanceData.reduce((sum, row) => sum + row.hoursWorked, 0)}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <TrendingUpIcon sx={{ mr: 1 }} />
                    <Typography variant="body2">
                      {t('En augmentation')}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card sx={{ backgroundColor: secColor, padding: 1, borderRadius: '12px' }} elevation={0}>
                <CardContent>
                  <Typography variant="h6" component="div" color='white'>
                    {t('Performance moyenne')}
                  </Typography>
                  <Typography variant="h4" component="div" color='white'>
                    {employeePerformanceData.length > 0 ? (employeePerformanceData.reduce((sum, row) => sum + row.performanceRating, 0) / employeePerformanceData.length).toFixed(1) : '0.0'}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    {getStatus(employeePerformanceData.length > 0 ? (employeePerformanceData.reduce((sum, row) => sum + row.performanceRating, 0) / employeePerformanceData.length) * 20 : 0) === 'green' && <TrendingUpIcon color='white' sx={{ mr: 1 }} />}
                    {getStatus(employeePerformanceData.length > 0 ? (employeePerformanceData.reduce((sum, row) => sum + row.performanceRating, 0) / employeePerformanceData.length) * 20 : 0) === 'orange' && <TrendingUpIcon color='white' sx={{ mr: 1 }} />}
                    {getStatus(employeePerformanceData.length > 0 ? (employeePerformanceData.reduce((sum, row) => sum + row.performanceRating, 0) / employeePerformanceData.length) * 20 : 0) === 'red' && <TrendingDownIcon color='white' sx={{ mr: 1 }} />}
                    <Typography variant="body2" color='white'>
                      {t('Par rapport à la période précédente')}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>


            {/* Employee Performance Table */}
            <Grid item xs={12}>
              <Blocks title={t('Performance des employés')}>
                <div>
                  <table style={{ tableLayout: 'fixed', width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr style={{ backgroundColor: mainColor, color: '#FFFFFF' }}>
                        <th style={{ textAlign: 'left', padding: '8px', width: '150px' }}>{t('Employé')}</th>
                        <th style={{ textAlign: 'center', padding: '8px', width: '100px' }}>{t('Heures travaillées')}</th>
                        <th style={{ textAlign: 'center', padding: '8px', width: '100px' }}>{t('Évaluations')}</th>
                        <th style={{ textAlign: 'center', padding: '8px', width: '100px' }}>{t('Absences')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employeePerformanceData.map((employee, index) => (
                        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#f9f9f9' }}>
                          <td style={{ padding: '8px', width: '150px' }}>{employee.employee || 'N/A'}</td>
                          <td style={{ textAlign: 'center', padding: '8px', width: '100px' }}>{employee.hoursWorked || 0}</td>
                          <td style={{ textAlign: 'center', padding: '8px', width: '100px' }}>{employee.performanceRating?.toFixed(1) || '0.0'}</td>
                          <td style={{ textAlign: 'center', padding: '8px', width: '100px' }}>{employee.absentDays || 0}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Blocks>
            </Grid>

            {/* Hours Worked Trend Chart */}
            {hoursWorkedTrend.length > 0 && ( // Conditional rendering
              <Grid item xs={12} md={6}>
                <Blocks title={t('Tendances des heures travaillées')}>
                  <ResponsiveContainer width="100%" height={250}>
                    <AreaChart
                      data={hoursWorkedTrend}
                      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      {Object.keys(hoursWorkedTrend[0]).filter(key => key !== 'name').map((key, index) => (
                        <Area key={key} type="monotone" dataKey={key} stackId="1" stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </AreaChart>
                  </ResponsiveContainer>
                </Blocks>
              </Grid>
            )}

            {/* Absenteeism Chart */}
            {absenteeismData.length > 0 && (  // Conditional rendering
              <Grid item xs={12} md={6}>
                <Blocks title={t('Tendances d\'absentéisme')}>
                  <ResponsiveContainer width="100%" height={250}>
                    <BarChart
                      data={absenteeismData}
                      margin={{ top: 10, right: 30, left: 20, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      {Object.keys(absenteeismData[0]).filter(key => key !== 'name').map((key, index) => (
                        <Bar key={key} dataKey={key} stackId="a" fill={COLORS[index % COLORS.length]} />
                      ))}
                    </BarChart>
                  </ResponsiveContainer>
                </Blocks>
              </Grid>
            )}


          </Grid>) : (<Grid container spacing={1} mt={3}>
            <Grid item xs={12} md={4} style={{ paddingLeft: '25px' }}>
              <Card sx={{ backgroundColor: mainColor, color: 'white', padding: 1, borderRadius: '12px' }} elevation={0}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    {t('Total contrôles')}
                  </Typography>
                  <Typography variant="h4" component="div">
                    {tableData.reduce((sum, row) => sum + row.totalRequired, 0)}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <TrendingUpIcon sx={{ mr: 1 }} />
                    <Typography variant="body2">
                      {t('En augmentation')}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ backgroundColor: secColor, color: 'white', padding: 1, borderRadius: '12px' }} elevation={0}>
                <CardContent>
                  <Typography variant="h6" component="div" color="white">
                    {t('Contrôles complétés')}
                  </Typography>
                  <Typography variant="h4" component="div" color="white">
                    {tableData.reduce((sum, row) => sum + row.completed, 0)}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <SentimentSatisfied color="white" sx={{ mr: 1 }} />
                    <Typography variant="body2" color="white">
                      {t('Satisfaction client')}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card elevation={0} sx={{ backgroundColor: '#FFFFFF', borderRadius: '12px', padding: 1 }}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    {t('Performance globale')}
                  </Typography>
                  <Typography variant="h4" component="div">
                    {tableData.length > 0 ? (tableData.reduce((sum, row) => sum + parseInt(row.percentage), 0) / tableData.length).toFixed(0) + '%' : '0%'}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    {getStatus(tableData.length > 0 ? tableData.reduce((sum, row) => sum + parseInt(row.percentage), 0) / tableData.length : 0) === 'green' && <TrendingUpIcon sx={{ mr: 1 }} />}
                    {getStatus(tableData.length > 0 ? tableData.reduce((sum, row) => sum + parseInt(row.percentage), 0) / tableData.length : 0) === 'orange' && <TrendingUpIcon sx={{ mr: 1 }} />}
                    {getStatus(tableData.length > 0 ? tableData.reduce((sum, row) => sum + parseInt(row.percentage), 0) / tableData.length : 0) === 'red' && <TrendingDownIcon sx={{ mr: 1 }} />}
                    <Typography variant="body2">
                      {t('Par rapport à la période précédente')}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            {/* Satisfaction Pie Chart */}
            <Grid item xs={12} md={6}>
              <Blocks title={t('Satisfaction client')}>
                <ResponsiveContainer width="100%" height={175}>
                  <PieChart>
                    <Pie
                      data={satisfactionData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {satisfactionData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />

                  </PieChart>
                </ResponsiveContainer>
              </Blocks>
            </Grid>

            {/* Performance Bar Chart */}
            <Grid item xs={12} md={6}>
              <Blocks title={t('Performance par mois')}>
                <ResponsiveContainer width="100%" height={175}>
                  <BarChart
                    data={performanceData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />

                    <Bar dataKey="Complétés" fill={mainColor} />
                    <Bar dataKey="Requis" fill={secColor} />
                  </BarChart>
                </ResponsiveContainer>
              </Blocks>
            </Grid>

            <Grid item xs={12}>
              <Blocks title={t('Performance des directeurs')}>
                <div>
                  <table style={{ tableLayout: 'fixed', width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      {renderTableHeader()}
                    </thead>
                    <tbody>
                      {renderTableRows()}
                    </tbody>
                  </table>
                </div>
              </Blocks>
            </Grid>

            {/*  Trend Line Chart */}
            <Grid item xs={12}>
              <Blocks title={t('Tendances des contrôles complétés')}>
                <ResponsiveContainer width="100%" height={200}>
                  <AreaChart
                    data={trendData}
                    margin={{
                      top: 5,
                      right: 20,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Area type="monotone" dataKey="Complétés" stroke={mainColor} fill={mainColor} />
                  </AreaChart>
                </ResponsiveContainer>
              </Blocks>
            </Grid>



          </Grid>)}

      </div>
    </MainLayoutV2>
  );
};

export default EmployeePerformanceDashboard;