import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// utilities
import { useTranslation } from 'react-i18next';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

// components
import List from '@mui/material/List';

import { Box, Typography, Divider, MenuItem, Menu } from '@mui/material';
import Blocks from '../../stories/layout-components/Block';
import ListSItem from './items/ListSItem';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import FieldsPopper from '../../components/@generalComponents/FieldsPopper';
import defaultFieldsResolver from '../../utils/defaultFieldsResolver';

const DraggableColumn = ({
  column,
  index,
  moveColumn,
  onClick,
  allowedProperties,
}) => {
  const ref = React.useRef(null);

  const [, drop] = useDrop({
    accept: 'COLUMN',
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveColumn(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'COLUMN',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Box
      ref={ref}
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        opacity: isDragging ? 0.5 : 1,
        marginRight: '12px',
        borderRadius: '10px',
      }}
    >
      <Typography
        variant="body2"
        fontSize="11.5px"
        sx={{
          cursor: allowedProperties?.includes(column?.value)
            ? 'pointer'
            : 'default',
        }}
        fontWeight={700}
      >
        {column.label}
      </Typography>
    </Box>
  );
};

const ListS = ({
  activeModule,
  list,
  isLoading,
  activeIndex,
  customizations,
  handleDisplaySide,
  displaySide,
  handleClick,
  refreshData,
}) => {
  const { t } = useTranslation();

  const resizeTimeoutRef = useRef(null);
  const [columns, setColumns] = useState([]);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [activeColumn, setActiveColumn] = useState(null);
  const [submenuAnchor, setSubmenuAnchor] = useState(null);
  const [fieldsSubmenu, setFieldsSubmenu] = useState([]);
  const [fields, setFields] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const { moduleName, structureId } = useParams();

  const currentUser = useSelector((state) => state.core.user);
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );
  const businessStructures = businessStructure?.structures;
  const structure = businessStructures?.find((s) => s.id === structureId);

  const order = searchParams.get('order') || 'desc';
  const orderBy = searchParams.get('orderBy') || 'lastUpdate';
  const facetKey = searchParams.get('facetKey') || null;
  const facet =
    facetKey === 'status'
      ? parseInt(searchParams.get('facet'))
      : searchParams.get('facet') || null;



  const allowedProperties = [
    'targetDate',
    'by',
    'status',
    'assignedToId',
    'decison-maker',
    'invoiceDate',
    'lastUpdate',
    'finances.balance',
    'finances.total',
    'financesTotal',
    'financesBalance',
    'financesAmount',
    'financesSubtotal',
    'bestcallback',
    'categoryName',
    'price',
    'locationId',
    'targetInvoicedBalance',
    'sku',
    'duration',
    'startDate',
    'timeStamp',
    'attribute1',
    'attribute2',
    'attribute3',
    'attribute4',
    'priority',
  ];

  const createSortHandler = (property) => () => {
    if (!allowedProperties?.includes(property)) {
      return;
    } else {
      const newSearchParams = new URLSearchParams(searchParams);
      const isAsc = orderBy === property && order === 'asc';
      newSearchParams.set('orderBy', property);
      newSearchParams.set('order', isAsc ? 'desc' : 'asc');
      newSearchParams.set('page', 1);
      setSearchParams(newSearchParams);
    }
  };

  const currentCollection = useSelector(
    (state) => state.list.currentCollection
  );

  useEffect(() => {
    if (structure) {
      const defaultFields = defaultFieldsResolver(structure.collectionField, t);
      const structureFields = structure?.fields || [];

      // Use a Set to keep track of unique field values
      const uniqueFieldMap = new Map();

      // Add structure fields first
      structureFields.forEach((field) => {
        if (!uniqueFieldMap.has(field.value)) {
          uniqueFieldMap.set(field.value, field);
        }
      });

      // Add default fields only if they are not already present
      defaultFields.forEach((field) => {
        if (!uniqueFieldMap.has(field.value)) {
          uniqueFieldMap.set(field.value, field);
        }
      });

      const fieldToCatch = Array.from(uniqueFieldMap.values());
      setFields(fieldToCatch);

      const filteredSubField = fieldToCatch.filter(
        (field) =>
          field?.allowSub !== false &&
          field?.typeData !== 'status' &&
          field?.typeData !== 'search' &&
          field?.typeData !== 'hook'
      );

      setFieldsSubmenu(filteredSubField);
    }
  }, [structure]);

  const DraggableListItem = React.memo(
    ({ id, children, handleMoveItem, element }) => {
      const [{ isDragging }, drag] = useDrag(
        () => ({
          type: 'item',
          item: { id, element },
          collect: (monitor) => ({
            isDragging: monitor.isDragging(),
          }),
          end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
              handleMoveItem(item.id, dropResult.id);
            }
          },
        }),
        [id]
      );

      return (
        <div ref={drag} style={{ opacity: isDragging ? 0 : 1 }}>
          {children}
        </div>
      );
    }
  );

  const handleContextMenu = (event, columnValue) => {
    event.preventDefault();
    if (activeColumn === columnValue) {
      // If clicking the same column, toggle close
      handleMenuClose();
    } else {
      setMenuAnchor(event.currentTarget);
      setActiveColumn(columnValue);
    }
  };

  // Handle closing of all menus
  const handleMenuClose = () => {
    setMenuAnchor(null);
    setActiveColumn(null);
    setSubmenuAnchor(null);
  };

  const handleMoveItem = useCallback(
    (dragIndex, dropIndex) => {
      const newList = [...list];
      const draggingItem = newList[dragIndex];
      newList.splice(dragIndex, 1);
      newList.splice(dropIndex, 0, draggingItem);
    },
    [list]
  );

  const header = displaySide
    ? currentCollection?.[moduleName]?.header?.slice(0, 4)
    : currentCollection?.[moduleName]?.header;

  const [columnWidths, setColumnWidths] = useState(
    header?.map((col) => col.width)
  );

  useEffect(() => {
    setColumnWidths(header?.map((col) => col.width));
  }, [header]);

  const moveColumn = useCallback(async (fromIndex, toIndex) => {
    setColumns((prevColumns) => {
      const updatedColumns = [...prevColumns];
      const [movedColumn] = updatedColumns.splice(fromIndex, 1);
      updatedColumns.splice(toIndex, 0, movedColumn);

      updateColumnOrder(updatedColumns);

      return updatedColumns;
    });
  }, []);

  const updateColumnOrder = async (updatedColumns) => {
    try {
      const formattedColumns = updatedColumns.map((col, idx) => ({
        value: col.value,
        width: col.width || 20,
      }));

      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/structure`,
        body: {
          moduleId: activeModule?.id,
          type: 'fields',
          data: {
            tabIndex: activeIndex || 0,
            fieldsData: formattedColumns,
          },
        },
      });
      refreshData();
    } catch (error) {
      console.error('Error updating column order:', error);
    }
  };

  useEffect(() => {
    setColumns(header);
  }, [header]);

  const handleMouseDown = (e, index) => {
    e.preventDefault();
    const startX = e.clientX;
    const startWidth = columnWidths[index];

    const handleMouseMove = (event) => {
      const diffX = event.clientX - startX;
      const tableWidth = document.querySelector('.list-container').offsetWidth;
      const diffPercentage = (diffX / tableWidth) * 100;
      let newWidths = [...columnWidths];

      let newWidth = Math.max(5, startWidth + diffPercentage);

      if (index < newWidths.length - 1) {
        // Normal resizing (adjust next column)
        const nextWidth = newWidths[index + 1] - diffPercentage;
        if (nextWidth >= 5) {
          newWidths[index] = newWidth;
          newWidths[index + 1] = nextWidth;
        }
      } else {
        // Adjust all previous columns to fit the new last column width
        const totalWidth = newWidths.reduce((acc, w) => acc + w, 0);
        const overflow = totalWidth + diffPercentage - 100;

        if (overflow > 0) {
          for (let i = 0; i < index; i++) {
            if (newWidths[i] > 5) {
              let reduceAmount = Math.min(overflow, newWidths[i] - 5);
              newWidths[i] -= reduceAmount;
              overflow -= reduceAmount;
              if (overflow <= 0) break;
            }
          }
        }
        newWidths[index] = newWidth;
      }

      setColumnWidths(newWidths);
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);

      if (resizeTimeoutRef.current) clearTimeout(resizeTimeoutRef.current);

      resizeTimeoutRef.current = setTimeout(() => {
        handleUpdateModuleWidth();
      }, 700);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleUpdateModuleWidth = async () => {
    try {
      const updatedColumns = header?.map((col, idx) => ({
        width: columnWidths[idx],
        value: col.value,
      }));

      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/structure`,
        body: {
          moduleId: activeModule?.id,
          type: 'fields',
          data: {
            tabIndex: activeIndex || 0,
            fieldsData: updatedColumns,
          },
        },
      });
    } catch (error) {
      console.error('Error updating column widths:', error);
    }
  };

  const handleUpdateFields = async (newField, action, subNewField) => {
    try {
      const currentColumns = header?.map((col, idx) => ({
        width: columnWidths[idx],
        value: col.value,
      }));

      let updatedColumns;

      if (action === 'add' && newField?.value) {
        updatedColumns = [
          ...currentColumns,
          {
            width: 20,
            structureValue:
              newField.value === 'targetId'
                ? 'targetName'
                : newField.value === 'targetProfileId'
                  ? 'targetProfileName'
                  : newField.value,
            type: newField?.typeData,
            action: 'none',
          },
        ];
      }

      if (action === 'delete') {
        updatedColumns = currentColumns.filter((col) => col.value !== newField);
      }

      if (action === 'add-sub') {
        const activeCol = currentColumns.find((col) => col.value === newField);

        if (activeCol) {
          updatedColumns = currentColumns.map((col) => {
            if (col?.value === newField) {
              return {
                ...col,
                sub: {
                  value: subNewField?.value,
                },
              };
            } else {
              return col;
            }
          });
        }
      }
      handleMenuClose();
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/structure`,
        body: {
          moduleId: activeModule?.id,
          type: 'fields',
          data: {
            tabIndex: activeIndex || 0,
            fieldsData: updatedColumns,
          },
        },
      });

      refreshData();
    } catch (error) {
      console.error('Error updating column widths:', error);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className="list-container"
        style={{ paddingRight: '10px ', overflow: 'hidden' }}
      >
        <Blocks
          height={
            activeModule?.list?.tabs?.[activeIndex]?.displayStatuses ||
              activeModule?.list?.tabs?.[activeIndex]?.displayTop === 'statuses'
              ? 2
              : 1
          }
          heightPercentage={
            activeModule?.list?.tabs?.[activeIndex]?.displayTop === 'statuses'
              ? 85
              : 95
          }
          noScroll
          noBorder
          isLoading={isLoading}
          blockType="list"
        >
          <List
            dense
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              pl: 2,
            }}
          >
            {columns?.map((col, idx) => {
              return (
                <Box
                  key={idx}
                  sx={{
                    width: `${columnWidths?.[idx]}%`,
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    marginRight: '12px',
                    borderRight:
                      idx !== header?.length - 1 ? '1px solid #ddd' : 'none',
                  }}
                >
                  <DraggableColumn
                    key={col?.value}
                    column={col}
                    index={idx}
                    moveColumn={moveColumn}
                    onClick={(event) => handleContextMenu(event, col?.value)}
                    allowedProperties={allowedProperties}
                  />
                  {orderBy === col?.value && (
                    <div>
                      {order === 'asc' ? (
                        <ArrowUpward
                          fontSize="10px"
                          sx={{ marginTop: '3px' }}
                        />
                      ) : (
                        <ArrowDownward
                          fontSize="10px"
                          sx={{ marginTop: '3px' }}
                        />
                      )}
                    </div>
                  )}

                  <div
                    onMouseDown={(e) => handleMouseDown(e, idx)}
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      height: '100%',
                      width: '5px',
                      cursor: 'col-resize',
                      backgroundColor: 'transparent',
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = '#ccc')
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = 'transparent')
                    }
                  />
                </Box>
              );
            })}
            <Menu
              elevation={2}
              open={Boolean(menuAnchor)}
              onClose={handleMenuClose}
              anchorEl={menuAnchor}
              sx={{
                boxShadow: '5px 5px 15px 0px rgba(0,0,0,0.15)',
                p: 0,
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 200,
                    overflowY: 'auto',
                    borderRadius: '12px',
                  },
                },
              }}
            >
              {activeColumn && allowedProperties.includes(activeColumn) && (
                <MenuItem onClick={() => createSortHandler(activeColumn)}>
                  {t('orderCol')}
                </MenuItem>
              )}
              <MenuItem
                onClick={(event) => {
                  setSubmenuAnchor(event.currentTarget);
                }}
              >
                {t('addSub')}
              </MenuItem>

              <MenuItem
                onClick={() => handleUpdateFields(activeColumn, 'delete')}
              >
                {t('delete')}
              </MenuItem>
            </Menu>
            {Boolean(submenuAnchor) && (
              <Menu
                elevation={2}
                open={Boolean(submenuAnchor)}
                onClose={() => setSubmenuAnchor(null)}
                anchorEl={submenuAnchor}
                sx={{ boxShadow: '5px 5px 15px 0px rgba(0,0,0,0.15)', p: 0 }}
              >
                {fieldsSubmenu?.length > 0 &&
                  fieldsSubmenu?.map((fieldSub) => {
                    return (
                      <MenuItem
                        onClick={() =>
                          handleUpdateFields(activeColumn, 'add-sub', fieldSub)
                        }
                      >
                        {fieldSub?.name}
                      </MenuItem>
                    );
                  })}
              </Menu>
            )}
            <div style={{ position: 'absolute', right: 0, zIndex: 9999 }}>
              {currentUser?.activeBusiness?.role !== 'EMPLOYEE' && currentUser?.activeBusiness?.role !== 'VIEWER' && (
                <FieldsPopper
                  onSelect={(fieldsData) => handleUpdateFields(fieldsData, 'add')}
                  fields={fields}
                />)}
            </div>
          </List>
          <Divider component="div" color="#f2f2f2" />

          <PerfectScrollbar>
            <List dense sx={{ width: '100%' }}>
              {list?.length > 0 &&
                list?.map((element, idx) => {
                  return (
                    <React.Fragment key={element?.id}>
                      <DraggableListItem
                        id={element?.documentPath}
                        key={element?.id}
                        handleMoveItem={handleMoveItem}
                        element={element}
                      >
                        <ListSItem
                          currentCollection={currentCollection?.[moduleName]}
                          element={element}
                          list={list}
                          activeModule={activeModule}
                          customizations={customizations}
                          handleDisplaySide={handleDisplaySide}
                          displaySide={displaySide}
                          handleClick={handleClick}
                          columnWidths={columnWidths}
                        />
                      </DraggableListItem>
                    </React.Fragment>
                  );
                })}
            </List>
          </PerfectScrollbar>
        </Blocks>
      </div>
    </DndProvider>
  );
};

export default ListS;
