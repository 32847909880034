import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import Typo from '../Typo';

const HeaderHome = ({ translationCode }) => {
  const { t, } = useTranslation();



  return (
    <div
      style={{
        marginTop: '120px',
      }}
      className="container align-c"
    >
      <div
        className="col-12"
        style={{
          position: 'relative',
          width: '100%',
          height: '50vh',
          overflow: 'hidden',
          borderRadius: '20px',
        }}
      >
        <img
          src="/assets/website/v3/header-roam.png"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
        <div
          className="align-left"
          style={{ position: 'absolute', bottom: 60, left: 60 }}
        >
          <Typo
            text={t(translationCode + 'Title1')}
            variant="h2"
            color={'white'}
          />
          <Typo
            text={t(translationCode + 'Title2')}
            variant="h2"
            color={'white'}
          />
          <Typo
            text={t(translationCode + 'Sub1')}
            variant="subTitle"
            color={'white'}
            className="mt-3"
          />
          <Typo
            text={t(translationCode + 'Sub2')}
            variant="subTitle"
            color={'white'}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderHome;
