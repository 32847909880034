import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { Helmet } from 'react-helmet';
import Footer from './components/Footer';
import { mainData } from '../redux/actions-v2/websiteAction';
import colorGenerator from '../utils/colorGenerator';
import { Container } from '@mui/material';
import Loading from '../stories/general-components/Loading';
import MenuNode from './components/MenuNode';

const SiteLayout = ({
  children,
  helmetData,
  themeLayout,
  noFooter,
  themePosition,
  backgroundPage,
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const scrollRef = useRef(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const theme = useTheme();
  const currentLangCode = i18n.language;
  const isDarkMode = theme.palette.mode === 'dark';
  const localStorageColor = localStorage.getItem('mainColor');

  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';

  const gradientBackground =
    backgroundPage === 'full'
      ? isDarkMode
        ? '000000'
        : '#FFFFFF'
      : `linear-gradient(170deg, ${colorGenerator(
        mainColor,
        0,
        0.06,
        1,
        isDarkMode
      )} 30%, ${isDarkMode ? '#0d0d0d' : '#FFF'} 68%)`;

  const childrenWithProps = React.Children.map(children, (child) =>
    React.isValidElement(child)
      ? React.cloneElement(child, { scrollContainer: scrollRef })
      : child
  );

  useEffect(() => {
    const documentId = 'website_' + currentLangCode;
    const documentRef = doc(db, 'public', documentId);

    const unsubscribe = onSnapshot(
      documentRef,
      (doc) => {
        if (doc.exists()) {
          dispatch(mainData({ ...doc.data() }));
          setIsLoading(false);
        } else {
          console.error('No such document found:', documentId);
        }
      },
      (error) => {
        console.error('Error fetching document:', error);
      }
    );

    return () => unsubscribe();
  }, [currentLangCode, dispatch]);

  return (
    <div
      ref={scrollRef}
      id="scrollContainer"
      style={{
        backgroundImage:
          backgroundPage === 'full' ? 'none' : gradientBackground,
        backgroundColor:
          backgroundPage !== 'full'
            ? 'transparent'
            : isDarkMode
              ? '#000000'
              : '#FFF',
        height: '100vh',
        overflowY: 'auto',
      }}
    >
      <Helmet>
        <title>{(helmetData?.name || '') + ' - ' + 'Node'}</title>
        <meta name="description" content={helmetData?.description || '-'} />

        <meta
          name="keywords"
          content={[
            t('businessManagement'),
            'Node',
            'usenode',
            'SaaS',
            t('software'),
            'ERP',
            'CRM',
          ]}
        />
        <link
          rel="icon"
          type="image/png"
          href={`https://storage.googleapis.com/business-logos-node/${'nodetechnologies'}.png`}
          sizes="16x16"
        />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charset="UTF-8" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:title" content={helmetData?.name + ' - ' + 'Node'} />
        <meta
          property="og:description"
          content={helmetData?.name + ': ' + helmetData?.description}
        />
        <meta
          name="twitter:title"
          content={helmetData?.name + ' - ' + 'Node'}
        />
        <link rel="canonical" href={'https://usenode.com'} />
        <meta property="og:url" content={'https://usenode.com'} />
        <meta name="geo.region" content={'CA-QC'} />

        <meta name="telephone" content={'1-888-343-0310'} />
        <meta name="email" content={'i@usenode.com'} />

        <meta
          property="og:image:alt"
          content={helmetData?.name + ' - ' + 'Node'}
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:locale" content={currentLangCode} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Node" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div style={{ position: 'sticky', top: 0 }}>
        <MenuNode themeLayout={themeLayout} themePosition={themePosition} />
      </div>
      <Container
        style={{
          overflowY: 'hidden',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          overflowX: 'hidden',
          padding: '1rem',
        }}
      >
        <div style={{ width: '100%' }}>
          {isLoading ? (
            <div>
              <Loading type="backdrop" />
            </div>
          ) : (
            <div style={{ textAlign: 'left' }}>{childrenWithProps}</div>
          )}
        </div>
      </Container>
      {!noFooter && <Footer />}
    </div>
  );
};

export default SiteLayout;
