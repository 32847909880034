import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import Typo from '../Typo';

const SplitEntreprise = ({ translationCode }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';
  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const secColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  return (
    <div
      style={{
        marginTop: '200px',
        backgroundColor: darkMainColor + '20',
        padding: isMobile ? '5px' : '30px',
      }}
    >
      <div className="container">
        <div className="row mt-4">
          <div style={{ padding: '40px' }} className="col-md-6 col-12">
            <div
              style={{
                backgroundColor: isDarkMode ? '#00000090' : '#FFFFFF',
                borderRadius: '15px',
                padding: '40px',
              }}
            >
              <Typo
                text={t(translationCode + 'ATitle')}
                variant="subTitle"
                className="mt-2"
              />
              <div
                className="mt-4 align-c"
                style={{
                  backgroundColor: secColor + '10',
                  borderRadius: '15px',
                  height: '180px',
                }}
              >
                {' '}
                <img
                  style={{ marginTop: '30px' }}
                  src="/assets/website/v3/UsersAccess.svg"
                  height={180}
                />
              </div>
              <Typo
                text={t(translationCode + 'APointT1')}
                variant="body1"
                bold
                className="mt-5"
              />
              <Typo
                text={t(translationCode + 'APointP1')}
                variant="p"
                className="mt-1"
              />
              <Typo
                text={t(translationCode + 'APointT2')}
                variant="body1"
                bold
                className="mt-4"
              />
              <Typo
                text={t(translationCode + 'APointP2')}
                variant="p"
                className="mt-1"
              />
              <Typo
                text={t(translationCode + 'APointT3')}
                variant="body1"
                bold
                className="mt-4"
              />
              <Typo
                text={t(translationCode + 'APointP3')}
                variant="p"
                className="mt-1"
              />
            </div>
          </div>
          <div style={{ padding: '40px' }} className="col-md-6 col-12">
            <div
              style={{
                backgroundColor: isDarkMode ? '#00000090' : '#FFFFFF',
                borderRadius: '15px',
                padding: '40px',
              }}
            >
              <Typo
                text={t(translationCode + 'BTitle')}
                variant="subTitle"
                className="mt-2"
              />
              <div
                className="mt-4 align-c"
                style={{
                  backgroundColor: mainColor + '20',
                  borderRadius: '15px',
                  height: '180px',
                }}
              >
                <img
                  style={{ marginTop: '20px' }}
                  src="/assets/website/v3/Charts.svg"
                  height={180}
                />
              </div>
              <Typo
                text={t(translationCode + 'BPointT1')}
                variant="body1"
                bold
                className="mt-5"
              />
              <Typo
                text={t(translationCode + 'BPointP1')}
                variant="p"
                className="mt-1"
              />
              <Typo
                text={t(translationCode + 'BPointT2')}
                variant="body1"
                bold
                className="mt-4"
              />
              <Typo
                text={t(translationCode + 'BPointP2')}
                variant="p"
                className="mt-1"
              />
              <Typo
                text={t(translationCode + 'BPointT3')}
                variant="body1"
                bold
                className="mt-4"
              />
              <Typo
                text={t(translationCode + 'BPointP3')}
                variant="p"
                className="mt-1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplitEntreprise;
