import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Typo from '../Typo';
import GrowthIcon from '../dynamicSVG/GrowthIcon';
import BosIcon from '../dynamicSVG/BosIcon';
import PulseIcon from '../dynamicSVG/PulseIcon';
import RoamIcon from '../dynamicSVG/RoamIcon';
import DeskIcon from '../dynamicSVG/DeskIcon';
import { useNavigate } from 'react-router-dom';

const Tem = ({ translationCode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const localStorageColor = localStorage.getItem('mainColor');

  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';



  const handleNavigate = () => {
    navigate('/products/growth/mappingNum');
  };

  const businsses = [
    {
      img: '/assets/website/v3/garage.jpeg',
      title: t('garageTitle'),
      name: 'Point S',
      products: ['bos', 'pulse'],
      ntk: t('garageNTK'),
      logo: '/assets/website/v3/point-s.png',
    },
    {
      img: '/assets/website/v3/manufacturer.jpeg',
      title: t('manuTitle'),
      name: 'Garex',
      products: ['bos', 'growth'],
      ntk: t('manuNTK'),
      logo: '/assets/website/v3/garex.png',
    },
    {
      img: '/assets/website/v3/construction.jpeg',
      title: t('constructionTitle'),
      name: 'Packmat',
      products: ['bos'],
      ntk: t('constructionNTK'),
      logo: '/assets/website/v3/packmat.png',
    },
  ];

  const [currentBusiness, setCurrentBusiness] = useState(businsses[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * businsses.length);
      setCurrentBusiness(businsses[randomIndex]);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      className="container"
      sx={{
        marginTop: '20vh',
        position: 'relative',
        width: '100%',
        maxWidth: '975px',
        marginBottom: '60px',
      }}
    >
      <div className={isMobile ? 'row px-4' : 'row'}>
        <div className="col-12">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
            px={2}
            py={1}
            width={'200px'}
            borderRadius="20px"
            border={isDarkMode ? '1px solid #FFFFFF80' : '1px solid #2222221a'}
          >
            <Typography
              variant="body2"
              fontWeight="medium"
              textAlign="center"
              color={isDarkMode ? '#FFFFFF' : '#222222'}
              sx={{ fontFamily: "'Montserrat', Helvetica" }}
            >
              {t(translationCode + 'Chip')}
            </Typography>
          </Box>
          <Typo
            text={t(translationCode + 'Top')}
            variant="h2"
            className="mt-4"
          />
          <Typo
            text={t(translationCode + 'Desc')}
            variant="body2"
            className="mt-4"
            sx={{ maxWidth: '500px' }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleNavigate}
            disableElevation
            style={{
              marginTop: '30px',
              marginRight: '25px',
              padding: '8px 20px',
              textTransform: 'none',
              borderRadius: '50px',
              backgroundColor: mainColor,
            }}
          >
            {t(translationCode + 'CTA')}
          </Button>
        </div>
      </div>
      <div className={isMobile ? 'row px-4 mt-5' : 'row mt-5'}>
        <div className="col-md-4 mt-3 col-12">
          <div className="mt-5">
            <img
              src={currentBusiness?.logo}
              alt={currentBusiness?.name + ' Logo'}
              width={'100px'}
              style={{ marginLeft: '-10px' }}
            />
            <Typo text={currentBusiness?.ntk} variant="h4" className={'mt-4'} />
            <Typo text={t('withNode')} variant="body2" className={'mt-1'} />
          </div>
          <div className="mt-5">
            <Typo
              text={t('productsUsed')}
              variant="subTitle"
              className={'mt-5'}
            />
            <div className="row mt-3">
              {currentBusiness?.products?.includes('bos') && (
                <div className="col-6 d-flex">
                  <div style={{ width: '20px' }}>
                    <BosIcon mainColor={mainColor} />
                  </div>
                  <Typo
                    text={t('BOS')}
                    variant="p"
                    className={'mt-1 mx-1'}
                    bold
                  />
                </div>
              )}
              {currentBusiness?.products?.includes('growth') && (
                <div className="col-6 d-flex">
                  <div style={{ width: '20px' }}>
                    <GrowthIcon mainColor={mainColor} />
                  </div>
                  <Typo
                    text={t('Growth')}
                    variant="p"
                    className={'mt-1 mx-1'}
                    bold
                  />
                </div>
              )}
              {currentBusiness?.products?.includes('pulse') && (
                <div className="col-6 d-flex">
                  <div style={{ width: '20px' }}>
                    <PulseIcon mainColor={mainColor} />
                  </div>
                  <Typo
                    text={t('Pulse')}
                    variant="p"
                    className={'mt-1 mx-1'}
                    bold
                  />
                </div>
              )}
              {currentBusiness?.products?.includes('roam') && (
                <div className="col-6 d-flex">
                  <div style={{ width: '20px' }}>
                    <RoamIcon mainColor={mainColor} />
                  </div>
                  <Typo
                    text={t('Roam')}
                    variant="p"
                    className={'mt-1 mx-1'}
                    bold
                  />
                </div>
              )}
              {currentBusiness?.products?.includes('desk') && (
                <div className="col-6 d-flex">
                  <div style={{ width: '20px' }}>
                    <DeskIcon mainColor={mainColor} />
                  </div>
                  <Typo
                    text={t('Desk')}
                    variant="p"
                    className={'mt-1 mx-1'}
                    bold
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-8 mt-3 col-12">
          <div style={{ position: 'relative', borderRadius: '15px' }}>
            <img
              src={currentBusiness?.img}
              alt={currentBusiness?.name}
              width={'100%'}
              height={'340px'}
              style={{ borderRadius: '15px', objectFit: 'cover' }}
            />
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                height: 190,
                width: '100%',
                background: `linear-gradient(to top, ${mainColor} 0%, transparent 100%)`,
                padding: '20px',
                borderRadius: '15px',
              }}
            >
              <div style={{ marginTop: '90px' }}>
                <Typo
                  text={currentBusiness?.title}
                  variant="h4"
                  color={'white'}
                />
                <div style={{ marginTop: '5px' }}>
                  <Typo
                    text={currentBusiness?.name + ' - Qc, Canada'}
                    variant="body2"
                    color={'white'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Tem;
