import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

import Checkbox from '../../stories/general-components/Checkbox';
import { FormControl, InputLabel } from '@mui/material';
import TextField from '../../stories/general-components/TextField';
import Select from '../../stories/general-components/Select';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import { toast } from 'react-toastify';
import { fetchBusinessData, setGeneralStatus } from '../../redux/actions-v2/coreAction';

const ModuleSettings = ({
  preferences,
  pageModel,
  formData,
  data,
  setFormData,
  statuses,
  collection,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const dispatch = useDispatch();

  const currentLangCode = i18n.language;
  const { moduleId, moduleName } = useParams();
  const [selectedModuleData, setSelectedModuleData] = useState({});

  const isDarkmode = theme.palette.mode === 'dark';
  const businessPreference = useSelector((state) => state.core.businessData);



  useEffect(() => {
    if (preferences) {
      setFormData({ ...preferences, ...preferences?.customizations });
    }
  }, [preferences]);

  const updateData = async (key, value) => {
    try {
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'listLayout-edit',
          type: 'pulse',
        })
      );
      if (moduleId) {
        await nodeAxiosFirebase({
          t,
          method: 'POST',
          url: `business/structure`,
          body: {
            moduleId: moduleId,
            lang: currentLangCode,
            type: 'editTab',
            data: {
              tabIndex: 0,
              name: selectedModuleData?.name,
              description: selectedModuleData?.description || '',
              listType: selectedModuleData?.listType || 'listA',
              sort: selectedModuleData?.sort || 'desc',
              sortField: selectedModuleData?.sortField || 'lastUpdate',
              displayTop: statuses
                ? selectedModuleData?.displayTop
                : 'none' || 'none',
              [key]: value,
            },
          },
        });
      }
      dispatch(fetchBusinessData(businessPreference?.id, t));
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'listLayout-edit',
          type: 'pulse',
        })
      );
      toast.success(t('success'));
    } catch (error) {
      console.error('Error update module data');
      dispatch(setGeneralStatus({ status: 'error', error: error }));
    }
  };


  const updateDataGen = async (key, value) => {
    try {
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'listLayout-edit',
          type: 'pulse',
        })
      );
      if (moduleId) {
        await nodeAxiosFirebase({
          t,
          method: 'POST',
          url: `business/structure`,
          body: {
            moduleId: moduleId,
            lang: currentLangCode,
            type: 'general',
            data: {
              limit: formData?.limit ?? 20,
              dateField: formData?.dateField || '',
              rangeDates: formData?.rangeDates || '',
              customizations: {
                backToToday: formData?.backToToday || false,
                limit: formData?.limit ?? 20,
                viewType: formData?.viewType || '',
                displayRefresh: formData?.displayRefresh || false,
                displayFilter: formData?.displayFilter || false,
                displayImport: formData?.displayImport || false,
                displayPrint: formData?.displayPrint || false,
                displayQuickView: formData?.displayQuickView || false,
                displayTotal: formData?.displayTotal || false,
                displaySearch: formData?.displaySearch || false,
                [key]: value,
              }
            },
          },
        });
      }
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'listLayout-edit',
          type: 'pulse',
        })
      );
      toast.success(t('success'));
    } catch (error) {
      console.error('Error update module data');
      dispatch(setGeneralStatus({ status: 'error', error: error }));
    }
  };



  useEffect(() => {
    setSelectedModuleData(data?.config);
  }, [moduleId]);

  return (
    <div style={{ width: '96%' }}>
      {moduleId && (
        <div>
          <TextField
            value={selectedModuleData?.name || ''}
            size="small"
            fullWidth
            onChange={(e, value) =>
              setSelectedModuleData({
                ...selectedModuleData,
                name: e.target.value,
              })
            }
            label={t('name')}
            onBlur={updateData}
          />
        </div>
      )}
      {moduleId && (
        <div>
          <Select
            value={selectedModuleData?.listType || 'listA'}
            size="small"
            noEmpty
            fullWidth
            onChange={(e, value) =>
              setSelectedModuleData({
                ...selectedModuleData,
                listType: value,
              })
            }
            label={t('type')}
            onBlur={updateData}
            selections={[
              {
                label: t('list'),
                id: 'listA',
                value: 'listA',
              },
              {
                label: t('listSimple'),
                id: 'listS',
                value: 'listS',
              },
              {
                label: t('listGroup'),
                id: 'listF',
                value: 'listF',
              },
              {
                label: t('grid'),
                id: 'grid',
                value: 'grid',
              },
              {
                label: t('table'),
                id: 'table',
                value: 'table',
              },

              {
                label: t('kanban'),
                id: 'kanban',
                value: 'kanban',
              },
              {
                label: t('calendar'),
                id: 'calendar',
                value: 'calendar',
              },
            ]}
          />
        </div>
      )}
      {moduleId && (
        <div>
          <Select
            value={selectedModuleData?.sort || 'desc'}
            size="small"
            fullWidth
            noEmpty
            onBlur={updateData}
            onChange={(e, value) =>
              setSelectedModuleData({
                ...selectedModuleData,
                sort: value,
              })
            }
            label={t('sort')}
            selections={[
              {
                label: t('descen'),
                id: 'desc',
                value: 'desc',
              },
              {
                label: t('asc'),
                id: 'asc',
                value: 'asc',
              },
            ]}
          />
        </div>
      )}
      {moduleId && (
        <div>
          <Select
            value={selectedModuleData?.sortField || 'lastUpdate'}
            size="small"
            fullWidth
            noEmpty
            onBlur={updateData}
            onChange={(e, value) =>
              setSelectedModuleData({
                ...selectedModuleData,
                sortField: value,
              })
            }
            label={t('sortField')}
            selections={[
              {
                label: t('status'),
                id: 'status',
                value: 'status',
              },
              {
                label: t('name'),
                id: 'name',
                value: 'name',
              },
              {
                label: moduleName === 'passes' ? t('startDate') : t('date'),
                id: moduleName === 'passes' ? 'startDate' : 'targetDate',
                value: moduleName === 'passes' ? 'startDate' : 'targetDate',
              },
              {
                label: t('lastUpdate'),
                id: 'lastUpdate',
                value: 'lastUpdate',
              },
              {
                label: t('lastRead'),
                id: 'lastRead',
                value: 'lastRead',
              },
              {
                label: t('timeStamp'),
                id: 'timeStamp',
                value: 'timeStamp',
              },
            ]}
          />
        </div>
      )}
      {moduleId && statuses && (
        <div>
          <Select
            value={selectedModuleData?.displayTop || 'none'}
            size="small"
            noEmpty
            onBlur={updateData}
            fullWidth
            onChange={(e, value) =>
              setSelectedModuleData({
                ...selectedModuleData,
                displayTop: value,
              })
            }
            label={t('display')}
            selections={[
              {
                label: t('statuses'),
                id: 'statuses',
                value: 'statuses',
              },
              {
                label: t('none'),
                id: 'none',
                value: 'none',
              },
            ]}
          />
        </div>
      )}

      {(pageModel === 'kanban' ||
        pageModel === 'listA' ||
        pageModel === 'listF' ||
        pageModel === 'list') && (
          <div>
            <Checkbox
              label={t('displayTotal')}
              value={formData?.displayTotal}
              onBlur={(e) => updateDataGen('displayTotal', e.target.checked)}
              onChange={(e) =>
                setFormData({ ...formData, displayTotal: e.target.checked })
              }

            />
          </div>
        )}
      {pageModel === 'kanban' && (
        <div>
          <div>
            <Checkbox
              label={t('displaySearch')}
              value={formData?.displaySearch}
              onChange={(e) =>
                setFormData({ ...formData, displaySearch: e.target.checked })
              }
              onBlur={(e) => updateDataGen('displaySearch', e.target.checked)}
            />
          </div>
        </div>
      )}

      <div>
        <Checkbox
          label={t('displayRefresh')}
          value={formData?.displayRefresh}
          onBlur={(e) => updateDataGen('displayRefresh', e.target.checked)}
          onChange={(e) =>
            setFormData({ ...formData, displayRefresh: e.target.checked })
          }

        />
      </div>
      <div>
        <Checkbox
          label={t('displayQuickView')}
          value={formData?.displayQuickView}
          onChange={(e) =>
            setFormData({ ...formData, displayQuickView: e.target.checked })
          }
          onBlur={(e) => updateDataGen('displayQuickView', e.target.checked)}
        />
      </div>
      {(pageModel === 'calendar' || pageModel === 'kanban') && (
        <div>
          <div>
            <Checkbox
              label={t('backToToday')}
              value={formData?.backToToday}
              onChange={(e) =>
                setFormData({ ...formData, backToToday: e.target.checked })
              }
              onBlur={(e) => updateDataGen('backToToday', e.target.checked)}
            />
          </div>
        </div>
      )}
      {(collection === 'contacts' ||
        collection === 'grids' ||
        collection === 'articles' ||
        collection === 'services') && (
          <div>
            <Checkbox
              label={t('displayImport')}
              value={formData?.displayImport}
              onChange={(e) =>
                setFormData({ ...formData, displayImport: e.target.checked })
              }
              onBlur={(e) => updateDataGen('displayImport', e.target.checked)}
            />
          </div>
        )}
      {collection === 'passes' && (
        <div>
          <Select
            select
            staticView
            noEmpty
            key={'viewType'}
            label={t('viewType')}
            value={formData?.viewType}
            selections={[
              {
                label: t('resources'),
                value: 'resources',
                id: 'resources',
              },
              {
                label: t('employees'),
                value: 'employees',
                id: 'employees',
              },
            ]}
            onChange={(e, value) =>
              setFormData({ ...formData, viewType: value })
            }
            onBlur={updateDataGen}
            fullWidth
          />
        </div>
      )}
      {(collection === 'passes' || collection === 'items' || collection === 'payments') && (
        <div>
          <Select
            select
            staticView
            noEmpty
            key={'rangeDates'}
            label={t('rangeDates')}
            value={formData?.rangeDates}
            selections={[
              {
                label: t('none'),
                value: 'none',
                id: 'none',
              },
              {
                label: t('singleRange'),
                value: 'single',
                id: 'single',
              },
              {
                label: t('weekRange'),
                value: 'week',
                id: 'week',
              },
              {
                label: t('multi'),
                value: 'multi',
                id: 'multi',
              },

            ]}
            onChange={(e, value) =>
              setFormData({ ...formData, rangeDates: value })
            }
            onBlur={updateDataGen}
            fullWidth
          />
        </div>
      )}
      {(collection === 'passes' || collection === 'items' || collection === 'payments') && (
        <div>
          <Select
            select
            staticView
            noEmpty
            key={'dateField'}
            label={t('dateField')}
            value={formData?.dateField}
            selections={collection === 'passes' ? [
              {
                label: t('startDate'),
                value: 'startDate',
                id: 'startDate',
              },
              {
                label: t('endDate'),
                value: 'endDate',
                id: 'endDate',
              },
              {
                label: t('lastUpdate'),
                value: 'lastUpdate',
                id: 'lastUpdate',
              },
              {
                label: t('timeStamp'),
                value: 'timeStamp',
                id: 'timeStamp',
              },

            ] : collection === 'items' ? [
              {
                label: t('billingDate'),
                value: 'billingDate',
                id: 'billingDate',
              },
              {
                label: t('timeStamp'),
                value: 'timeStamp',
                id: 'timeStamp',
              },

            ] : collection === 'payments' ? [
              {
                label: t('transactionDate'),
                value: 'transactionDate',
                id: 'transactionDate',
              },
              {
                label: t('timeStamp'),
                value: 'timeStamp',
                id: 'timeStamp',
              },

            ] : []}
            onChange={(e, value) =>
              setFormData({ ...formData, dateField: value })
            }
            onBlur={updateDataGen}
            fullWidth
          />
        </div>
      )}
      <div>
        <Checkbox
          label={t('displayFilter')}
          value={formData?.displayFilter}
          onChange={(e) =>
            setFormData({ ...formData, displayFilter: e.target.checked })
          }
          onBlur={(e) =>
            updateDataGen('displayFilter', e.target.checked)
          }
        />
      </div>
      <div>
        <TextField
          label={t('limitPagination')}
          fullWidth
          help={t('limitPaginationHelp')}
          type="number"
          value={formData?.limit}
          onChange={(e) => {
            setFormData({
              ...formData,
              limit: Number(e.target.value),
            });
          }}
          onBlur={updateDataGen}
        />
      </div>
      {statuses && (
        <div>
          <FormControl
            sx={{
              marginLeft: '1px',
              border: '1px solid lightgray',
              borderRadius: '10px',
              width: '99.8%',
              fontSize: '13px',
              minHeight: '42px',
              padding: '3px',
              marginTop: '12px',
              marginBottom: '10px',
            }}
            fullWidth
          >
            <InputLabel
              shrink={true}
              sx={{
                backgroundColor: isDarkmode ? 'rgb(51,51,51)' : '#FFF',
                padding: '2px 5px 2px 5px',
                borderRadius: '10px',
              }}
            >
              {t('statusesToHide')}
            </InputLabel>
            {statuses?.map((status, index) => (
              <div key={index}>
                <Checkbox
                  label={status?.['label_' + currentLangCode]}
                  value={formData?.statusesToHide?.[status?.value]}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      statusesToHide: {
                        ...formData?.statusesToHide,
                        [status?.value]: e.target.checked,
                      },
                    })
                  }
                  onBlur={(e) =>
                    updateDataGen('statusesToHide', {
                      ...formData?.statusesToHide,
                      [status?.value]: e.target.checked,
                    })
                  }
                />
              </div>
            ))}
          </FormControl>
        </div>
      )}
    </div>
  );
};

export default ModuleSettings;
