import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import Typo from '../Typo';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import DeveloperBoardOutlinedIcon from '@mui/icons-material/DeveloperBoardOutlined';

const Intro = ({ translationCode }) => {
  const { t, } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';





  return (
    <div className="container">
      <div
        style={{
          backgroundColor: isDarkMode ? '#f7f7f730' : '#f7f7f780',
          padding: '35px',
          borderRadius: '15px',
        }}
      >
        <div className="row">
          <div className="col-md-4 col-12  mt-4">
            <Typo
              text={t(translationCode + 'Title')}
              variant="h3"
              sx={{ maxWidth: isMobile ? '400px' : '300px' }}
            />
            <Typo
              text={t(translationCode + 'Desc')}
              variant="body2"
              className="mt-3"
              sx={{ maxWidth: isMobile ? '400px' : '300px' }}
            />
          </div>
          <div className="col-md-8 col-12">
            <div className="row">
              <div className="col-md-4 col-12 align-left mt-4">
                <Diversity1OutlinedIcon
                  color={isDarkMode ? 'white' : 'black'}
                  sx={{ fontSize: '26px' }}
                />
                <Typo
                  text={t(translationCode + 'Data1Title')}
                  variant="body2"
                  className="mt-3"
                  bold
                  sx={{ maxWidth: '200px' }}
                />
                <Typo
                  text={t(translationCode + 'Data1')}
                  variant="p"
                  className="mt-2"
                />
              </div>
              <div className="col-md-4 col-12 align-left mt-4">
                <DeveloperBoardOutlinedIcon
                  color={isDarkMode ? 'white' : 'black'}
                  sx={{ fontSize: '26px' }}
                />
                <Typo
                  text={t(translationCode + 'Data2Title')}
                  variant="body2"
                  className="mt-3"
                  bold
                  sx={{ maxWidth: '200px' }}
                />
                <Typo
                  text={t(translationCode + 'Data2')}
                  variant="p"
                  className="mt-2"
                />
              </div>
              <div className="col-md-4 col-12 align-left mt-4">
                <BalanceOutlinedIcon
                  color={isDarkMode ? 'white' : 'black'}
                  sx={{ fontSize: '26px' }}
                />
                <Typo
                  text={t(translationCode + 'Data3Title')}
                  variant="body2"
                  className="mt-3"
                  bold
                  sx={{ maxWidth: '200px' }}
                />
                <Typo
                  text={t(translationCode + 'Data3')}
                  variant="p"
                  className="mt-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
