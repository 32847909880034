import React, { } from 'react';
import SiteLayout from '../SiteLayout';
import Win from '../components/growth/Win';
import HeaderMapping from '../components/growth/HeaderMapping';
import MethoMapping from '../components/growth/MethoMapping';
import Understand from '../components/growth/Understand';
import AllInOne from '../components/growth/AllInOne';
import { useTranslation } from 'react-i18next';

const MappingGrowth = () => {
  const { t } = useTranslation();
  const pageCode = 'mappingGrowth';
  return (
    <div>
      <SiteLayout
        helmetData={{
          name: t(pageCode + 'SEO'),
          description: t(pageCode + 'SEODesc'),
        }}
      >
        <div className="mt-5 mb-5">
          <HeaderMapping translationCode={pageCode + 'Header'} />
        </div>
        <div className="mt-5 mb-5">
          <MethoMapping translationCode={pageCode + 'Metho'} />
        </div>
        <div className="mt-5 mb-5">
          <Understand translationCode={pageCode + 'Understand'} />
        </div>
        <div className="mt-5 mb-5">
          <AllInOne translationCode={pageCode + 'All'} />
        </div>
        <div className="mt-5 mb-5">
          <Win translationCode={pageCode + 'Win'} />
        </div>
      </SiteLayout>
    </div>
  );
};

export default MappingGrowth;
