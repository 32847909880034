// react
import React, { useEffect, useState } from 'react';

// redux
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Lottie from 'react-lottie';
import animationData from '../lotties/doneNode_fr.json';

// utils
import { useTranslation } from 'react-i18next';
import ModalLarge from './Base/ModalLarge';
import nodeAxiosFirebase from '../utils/nodeAxiosFirebase';


const ModalPulseCreation = ({
  isOpen,
  modalCloseHandler,
  structureId,
  type
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      progressiveLoad: false,
    },
  };

  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );

  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructures = businessStructure?.structures;
  const structure = businessStructures.find((s) => s.id === structureId);
  const currentLangCode = i18n.language;



  return (
    <ModalLarge
      isOpen={isOpen}
      subTitle={t('fillAndCreate')}
      modalCloseHandler={modalCloseHandler}
      icon={structure?.icon}
      title={t('create') + ' ' + (structure?.name || '')?.toLowerCase()}
      btnLabel={t('create')}
    >
      <div style={{ position: 'relative' }} className="d-flex">
        Cette fonctionnalités sera disponible dans une prochaine mise à jour.
      </div>
    </ModalLarge>
  );
};
export default ModalPulseCreation;
