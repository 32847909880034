import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material';
import MainLayoutV2 from '../../layouts/MainLayoutV2.jsx';
import Blocks from '../../stories/layout-components/Block.jsx';
import GeneralText from '../../stories/general-components/GeneralText.jsx';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase.js';
import Select from '../../stories/general-components/Select.jsx';
import DocData from './components/DocData.jsx';
import StructureDup from './components/StructureDup.jsx';
import ModuleDup from './components/ModuleDup.jsx';

const NodeToolsDev = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState({});
  const [selectedSection, setSelectedSection] = useState('');

  const getNodeData = async () => {
    try {
      const nodeData = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/admin`,
        errorToast: t('errorLoadingDocument'),
        body: {
          type: 'list',
        },
      });
      const formattedData = nodeData?.data?.map((business) => ({
        id: business.id,
        value: business.id,
        media_url: `https://storage.googleapis.com/business-logos-node/${business.id}.png`,
        label: business.name,
      }));
      setBusinesses(formattedData);
    } catch (error) {
      console.error('Error getting node data:', error);
    }
  };

  useEffect(() => {
    getNodeData();
  }, []);

  const handleSelectBusiness = (value) => {
    const businessDoc = businesses.find((business) => business.id === value);
    setSelectedBusiness(businessDoc);
  };

  const handleChangeSection = (section) => {
    setSelectedSection(section);
  };

  return (
    <MainLayoutV2 pageTitle={t('devTools')}>
      <div className="row">
        <div className="col-3">
          <Blocks heightPercentage={98}>
            <Select
              selections={businesses}
              onChange={(key, value) => handleSelectBusiness(value)}
            />
            <Divider component="div" sx={{ mt: 2 }} />
            {selectedBusiness.id && (
              <List dense>
                <ListItem
                  button='true'
                  divider
                  onClick={() => handleChangeSection('business')}
                >
                  <ListItemText
                    primary={t('accessBusinessData')}
                    secondary={t('businessesOnNodeDoc')}
                  />
                </ListItem>
                <ListItem
                  button='true'
                  divider
                  onClick={() => handleChangeSection('doc')}
                >
                  <ListItemText
                    primary={t('accessDoc')}
                    secondary={t('withPathDoc')}
                  />
                </ListItem>
                <ListItem
                  button='true'
                  divider
                  onClick={() => handleChangeSection('structure')}
                >
                  <ListItemText
                    primary={t('duplicateStructure')}
                    secondary={t('fromAnyAccountS')}
                  />
                </ListItem>
                <ListItem
                  button='true'
                  divider
                  onClick={() => handleChangeSection('module')}
                >
                  <ListItemText
                    primary={t('duplicateModule')}
                    secondary={t('fromAnyAccountM')}
                  />
                </ListItem>
              </List>
            )}
          </Blocks>
        </div>{' '}
        <div className="col-9">
          {' '}
          <Blocks heightPercentage={98}>
            {selectedSection === 'business' && (
              <div>
                <GeneralText text={t('businessData')} />

              </div>
            )}
            {selectedSection === 'doc' && (
              <div>
                <GeneralText text={t('docData')} />
                <DocData businessId={selectedBusiness.id} />
              </div>
            )}
            {selectedSection === 'structure' && (
              <div>
                <GeneralText text={t('structureData')} />
                <StructureDup
                  businessId={selectedBusiness.id}
                  businesses={businesses}
                />
              </div>
            )}
            {selectedSection === 'module' && (
              <div>
                <GeneralText text={t('moduleData')} />
                <ModuleDup
                  businessId={selectedBusiness.id}
                  businesses={businesses}
                />
              </div>
            )}
          </Blocks>
        </div>
      </div>
    </MainLayoutV2>
  );
};

export default NodeToolsDev;
