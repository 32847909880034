import React from 'react';
import animationData from '../../../lotties/eco-node.json';
import Lottie from 'react-lottie';

import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Typo from '../Typo';
import { useMediaQuery } from '@mui/material';

const Eco = ({ translationCode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      progressiveLoad: false,
    },
  };

  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const secColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  const navigateDragDop = () => {
    navigate('/onboard');
  };

  return (
    <div
      style={{ marginTop: '150px', maxHeight: '500px' }}
      className="container"
    >
      <div className="row mt-5">
        <div className="col-md-6 col-12 align-c">
          <div
            style={{
              zIndex: 0,
              left: 0,
              bottom: 0,
              marginLeft: '-180px',
              marginTop: isMobile ? '-280px' : '-300px',
            }}
          >
            <Lottie
              options={defaultOptions}
              height={isMobile ? 650 : 1000}
              width={isMobile ? 650 : 1000}
            />
          </div>
        </div>
        <div
          style={{ marginTop: isMobile ? '-150px' : '' }}
          className="col-md-6 col-12 py-5 px-4"
        >
          <Typo
            variant="h2"
            text={t(translationCode + 'Title')}
            className="mt-4"
            bold
          />

          <Typo
            variant="body2"
            text={t(translationCode + 'Desc')}
            className="mt-4"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={navigateDragDop}
            disableElevation
            style={{
              marginTop: '40px',
              textTransform: 'none',
              borderRadius: '50px',
              padding: '8px 20px',
              backgroundColor: darkMainColor,
            }}
          >
            {t(translationCode + 'CTA')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Eco;
