import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import moment from 'moment';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import Typo from '../../../websiteV3/components/Typo';
import GrowthIcon from '../dynamicSVG/GrowthIcon';
import BosIcon from '../dynamicSVG/BosIcon';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { XAxis, ResponsiveContainer, Area, AreaChart } from 'recharts';
import {
  ArrowForwardIos,
  CalendarMonth,
} from '@mui/icons-material';
import NodeAIPerfo from '../dynamicSVG/NodeAIPerfo';

const Header = ({ scrollContainer, translationCode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';
  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');

  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const varColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const initialOpacities = [0.15, 0.1, 0.15, 0.12];
  const [opacities, setOpacities] = useState(initialOpacities);

  const darkMainColor = chroma(mainColor).darken(1).hex();
  const darkReallyMainColor = chroma(mainColor).darken(1.9).hex();
  const lightMainColor = chroma(mainColor + '40')
    .brighten(1)
    .hex();

  const [offsets, setOffsets] = useState([
    { x: 0, y: 0 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
  ]);

  const navigateGrandV = () => {
    window.open('https://grandv.investquebec.com/', '_blank');
  };

  const navigateBOS = () => {
    navigate('/products/bos/overview');
  };

  const navigateGrowth = () => {
    navigate('/products/growth/strategies');
  };

  useEffect(() => {
    const scrollElem = scrollContainer?.current || window;

    const handleScroll = () => {
      const containerHeight = scrollContainer?.current
        ? scrollContainer.current.clientHeight
        : window.innerHeight;
      const threshold = containerHeight * 0.7;
      const scrollY = scrollContainer?.current
        ? scrollContainer.current.scrollTop
        : window.scrollY;
      const scrollFraction = Math.min(scrollY / threshold, 1);

      const newOpacities = initialOpacities.map(
        (op) => op * (1 - scrollFraction)
      );
      setOpacities(newOpacities);

      const maxOffsets = [
        { x: 150, y: 150 },
        { x: 130, y: 130 },
        { x: 140, y: -140 },
        { x: -120, y: 120 },
      ];
      const newOffsets = maxOffsets.map((max) => ({
        x: scrollFraction * max.x,
        y: scrollFraction * max.y,
      }));
      setOffsets(newOffsets);
    };

    scrollElem.addEventListener('scroll', handleScroll);
    return () => scrollElem.removeEventListener('scroll', handleScroll);
  }, [scrollContainer]);

  const data = [
    {
      name: moment().add(0, 'days').format('DD'),
      uv: 2424,
    },
    {
      name: moment().add(1, 'days').format('DD'),
      uv: 3003,
    },
    {
      name: moment().add(2, 'days').format('DD'),
      uv: 3823,
    },
    {
      name: moment().add(3, 'days').format('DD'),
      uv: 4155,
    },
    {
      name: moment().add(4, 'days').format('DD'),
      uv: 3986,
    },
    {
      name: moment().add(5, 'days').format('DD'),
      uv: 4235,
    },
    {
      name: moment().add(6, 'days').format('DD'),
      uv: 4589,
    },
    {
      name: moment().add(7, 'days').format('DD'),
      uv: 5032,
    },
    {
      name: moment().add(8, 'days').format('DD'),
      uv: 4814,
    },
    {
      name: moment().add(9, 'days').format('DD'),
      uv: 4457,
    },
    {
      name: moment().add(10, 'days').format('DD'),
      uv: 5255,
    },
    {
      name: moment().add(11, 'days').format('DD'),
      uv: 5230,
    },
    {
      name: moment().add(12, 'days').format('DD'),
      uv: 2142,
    },
    {
      name: moment().add(13, 'days').format('DD'),
      uv: 4813,
    },
    {
      name: moment().add(14, 'days').format('DD'),
      uv: 4951,
    },
    {
      name: moment().add(15, 'days').format('DD'),
      uv: 5597,
    },
    {
      name: moment().add(16, 'days').format('DD'),
      uv: 5572,
    },
    {
      name: moment().add(17, 'days').format('DD'),
      uv: 5079,
    },
    {
      name: moment().add(18, 'days').format('DD'),
      uv: 5242,
    },
    {
      name: moment().add(19, 'days').format('DD'),
      uv: 6252,
    },
    {
      name: moment().add(20, 'days').format('DD'),
      uv: 6174,
    },
    {
      name: moment().add(21, 'days').format('DD'),
      uv: 6166,
    },
    {
      name: moment().add(22, 'days').format('DD'),
      uv: 5839,
    },
    {
      name: moment().add(23, 'days').format('DD'),
      uv: 6700,
    },
    {
      name: moment().add(24, 'days').format('DD'),
      uv: 6136,
    },
    {
      name: moment().add(25, 'days').format('DD'),
      uv: 5928,
    },
    {
      name: moment().add(26, 'days').format('DD'),
      uv: 6828,
    },
    {
      name: moment().add(27, 'days').format('DD'),
      uv: 6434,
    },
    {
      name: moment().add(28, 'days').format('DD'),
      uv: 5452,
    },
    {
      name: moment().add(29, 'days').format('DD'),
      uv: 7832,
    },
  ];

  return (
    <div
      className="align-c mt-5"
      style={{ position: 'relative', overflow: 'hidden' }}
    >
      <Box
        sx={{
          width: { xs: 350, md: 700 },
          height: { xs: 350, md: 700 },
          background: `radial-gradient(circle, ${mainColor} 5%, transparent 60%)`,
          position: 'absolute',
          pointerEvents: 'none',
          bottom: { xs: -175, md: -350 },
          left: { xs: -110, md: -220 },
          opacity: opacities[0],
          transform: `translate(${offsets[0].x}px, ${offsets[0].y}px)`,
          transition: 'transform 0.1s ease-out, opacity 0.1s ease-out',
        }}
      />
      <Box
        sx={{
          width: { xs: 336, md: 673 },
          height: { xs: 336, md: 673 },
          background: `radial-gradient(circle, ${mainColor} 5%, transparent 60%)`,
          position: 'absolute',
          top: { xs: -70, md: -140 },
          left: { xs: '20%', md: '40%' },
          pointerEvents: 'none',
          opacity: opacities[1],
          transform: `translate(${offsets[1].x}px, ${offsets[1].y}px)`,
          transition: 'transform 0.1s ease-out, opacity 0.1s ease-out',
        }}
      />
      <Box
        sx={{
          width: { xs: 436, md: 873 },
          height: { xs: 436, md: 873 },
          background: `radial-gradient(circle, ${mainColor} 5%, transparent 60%)`,
          position: 'absolute',
          top: { xs: 140, md: 280 },
          right: { xs: -218, md: -370 },
          pointerEvents: 'none',
          opacity: opacities[2],
          transform: `translate(${offsets[2].x}px, ${offsets[2].y}px)`,
          transition: 'transform 0.8s ease-out, opacity 0.8s ease-out',
        }}
      />
      <Box
        sx={{
          width: { xs: 262, md: 523 },
          height: { xs: 262, md: 523 },
          background: `radial-gradient(circle, ${varColor} 5%, transparent 60%)`,
          position: 'absolute',
          top: { xs: 68, md: 135 },
          left: { xs: -110, md: -220 },
          pointerEvents: 'none',
          opacity: opacities[3],
          transform: `translate(${offsets[3].x}px, ${offsets[3].y}px)`,
          transition: 'transform 1.1s ease-out, opacity 1.1s ease-out',
        }}
      />

      <Box
        className="container"
        sx={{
          width: '100%',
          marginTop: '50px',
          height: { xs: '800px', md: '650px' },
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            flex: { xs: 'unset', md: '0 0 25%' },
            width: { xs: '100%', md: '25%' },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              height: { xs: '150px', md: '200px' },
              margin: isMobile ? '' : '15px',
              marginBottom: isMobile ? '15px' : '',
              width: '100%',
              borderRadius: '20px',
              border: '1px solid #FFF',
              display: 'flex',
              background: 'rgba(255, 255, 255, 0.40)',
              boxShadow: '0px 4px 14.9px 10px rgba(0, 0, 0, 0.02)',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              cursor: 'pointer',
            }}
          >
            <div className="row p-4" onClick={navigateGrowth}>
              <div>
                <div style={{ width: '25px' }}>
                  <GrowthIcon mainColor={mainColor} />
                </div>
                <div className="col-10 align-left">
                  <Typo text={t('planProduct')} variant="h4" mainColor />
                </div>
              </div>
              <div className="align-left">
                {' '}
                <Typo text={t('growthText')} variant="p" className="mb-2" />
              </div>
            </div>
          </Box>

          <Box
            sx={{
              height: { xs: '250px', md: '390px' },
              margin: isMobile ? '0px' : '15px',
              width: '100%',
              borderRadius: '20px',
              border: '1px solid #FFF',
              background: mainColor,
              boxShadow: '0px 4px 14.9px 10px rgba(0, 0, 0, 0.02)',
            }}
          >
            <div className="row p-5">
              <div className="col-10 align-left">
                <Typo
                  text={t('performance')}
                  variant="h4"
                  sx={{
                    color: darkReallyMainColor,
                  }}
                />
                <Typo
                  text={t('multiplied')}
                  variant="h2"
                  sx={{
                    color: darkReallyMainColor,
                  }}
                />
              </div>
              <div className="col-2 align-right">
                <div style={{ width: '25px' }}>
                  <BosIcon mainColor="#FFFFFF" />
                </div>
              </div>
              <div
                style={{ marginLeft: '-6px' }}
                className="col-12 align-left mt-4"
              >
                <Typo
                  text={t('BOStext')}
                  variant="p"
                  className="mx-2 mb-2"
                  color={'#FFFFFF'}
                />
                <Button
                  color="grey"
                  variant="text"
                  onClick={navigateBOS}
                  className="d-flex"
                  sx={{
                    textTransform: 'none',
                    color: '#FFF',
                  }}
                >
                  {t('discoverNode')}
                  <ArrowForwardIos sx={{ ml: 1, fontSize: '12px' }} />
                </Button>
              </div>
            </div>
          </Box>
          {!isMobile && (
            <Box
              sx={{
                height: { xs: '200px', md: '250px' },
                margin: isMobile ? '' : '15px',
                marginTop: isMobile ? '15px' : '',
                width: '100%',
                borderRadius: '20px',
                border: '1px solid #FFF',
                display: 'flex',
                background: 'rgba(255, 255, 255, 0.30)',
                boxShadow: '0px 4px 14.9px 10px rgba(0, 0, 0, 0.02)',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <div
                className="d-flex middle-content"
                style={{ position: 'absolute', top: 15, left: 20 }}
              >
                <CalendarMonth htmlColor={darkMainColor} />
                <Typo
                  text={moment().format('MMMM YYYY')}
                  variant="body1"
                  bold
                  sx={{
                    textTransform: 'capitalize',
                    color: darkMainColor,
                    fontSize: '16px',
                  }}
                  className="align-c mx-2"
                />
              </div>

              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={500}
                  height={400}
                  data={data}
                  margin={{
                    top: 30,
                    right: 20,
                    left: 20,
                    bottom: 0,
                  }}
                >
                  <defs>
                    <linearGradient
                      id="areaGradient"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="5%"
                        stopColor={darkMainColor + '90'}
                        stopOpacity={0.8}
                      />
                      <stop
                        offset="95%"
                        stopColor={darkMainColor + '90'}
                        stopOpacity={0}
                      />
                    </linearGradient>
                  </defs>

                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    tick={{
                      style: {
                        fontSize: '8px',
                        fontWeight: 300,
                        fill: darkMainColor,
                      },
                    }}
                  />

                  <Area
                    type="monotone"
                    dataKey="uv"
                    strokeWidth={2}
                    stroke={darkMainColor}
                    fill="url(#areaGradient)"
                    animationDuration={1200}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            flex: { xs: 'unset', md: '0 0 75%' },
            width: { xs: '100%', md: '75%' },
            display: 'flex',
            paddingLeft: { xs: 0, md: '50px' },
            alignItems: 'flex-start',
            justifyContent: 'center',
            position: 'relative',
            mt: { xs: 2, md: 0 },
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: { xs: -20, md: 0 },
              left: { xs: 0, md: '35px' },
              height: { xs: '65px', sm: '85px', md: '115px' },
              margin: '15px',
              width: { xs: '44%', md: '432px' },
              borderRadius: '20px',
              border: '1px solid #FFF',
              background: 'rgba(255, 255, 255, 0.90)',
              boxShadow: '0px 4px 4px 4px rgba(0, 0, 0, 0.01)',
            }}
          >
            <div
              className="align-left"
              style={{
                backgroundImage: "url('/assets/website/v3/nodeAI-header.png')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                borderRadius: '20px',
                padding: isMobile ? '5px' : '16px',
                height: '100%',
                position: 'relative',
              }}
            >
              <div className="d-flex">
                <div>
                  <NodeAIPerfo mainColor={mainColor} />
                </div>
                <div className="py-3">
                  <Typo
                    text={'Node AI'}
                    variant={isMobile ? 'body2' : 'h4'}
                    bold={isMobile}
                    sx={{ color: '#000000' }}
                  />
                  <Typo
                    text={t('releaseSoon')}
                    variant={isMobile ? 'p' : 'body2'}
                    bold={isMobile}
                    sx={{ color: '#000000' }}
                  />{' '}
                </div>
              </div>{' '}
              <div
                style={{
                  position: 'absolute',
                  width: '200px',
                  height: '102%',
                  top: -1,
                  left: 0,
                  borderRadius: '20px',
                  background: `linear-gradient(to right, ${lightMainColor} 0%, transparent 100%)`,
                }}
              />
            </div>
          </Box>
          <Box sx={{ width: '100%', mt: '15px', position: 'relative' }}>
            <img
              src="./assets/website/v3/HeaderNodeEntrepreneur.png"
              width="100%"
              style={{ display: 'block' }}
              alt={t('headerNodeEntrepreneur')}
            />
            <div
              className="align-right"
              style={{
                position: 'absolute',
                bottom: 30,
                left: isMobile ? 60 : 250,
              }}
            >
              <Typo
                text={'Rachid C.'}
                variant={isMobile ? 'body2' : 'h4'}
                color={'white'}
                bold={isMobile}
              />
              <Typo
                text={'Café Latour - Qc, Canada'}
                variant={isMobile ? 'p-small' : 'body2'}
                color={'white'}
              />
            </div>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: { xs: '-40px', md: 0 },
              right: { xs: '-15px', md: '-15px' },
              height: { xs: '95px', md: '180px' },
              margin: '15px',
              width: { xs: '44%', md: '410px' },
              borderRadius: '20px',
              border: '1px solid #FFF',
              display: 'flex',
              background: isDarkMode ? '#333' : 'rgba(255, 255, 255, 0.90)',
              boxShadow: '0px 4px 4px 4px rgba(0, 0, 0, 0.01)',
            }}
          >
            <div className="row align-left p-4">
              <div className="col-10">
                <Typo
                  text={t('newsTitle')}
                  variant={isMobile ? 'body2' : 'h4'}
                  bold={isMobile}
                />
              </div>
              <div className="col-2">
                <div style={{ width: '25px' }}>
                  <GrowthIcon mainColor={mainColor} />
                </div>
              </div>
              {!isMobile && (
                <div className="col-12">
                  <Typo
                    text={t('newsText')}
                    variant={isMobile ? 'p-small' : 'body1'}
                  />
                </div>
              )}
              {!isMobile && (
                <div className="col-12 align-right">
                  <Button
                    color="grey"
                    variant="text"
                    onClick={navigateGrandV}
                    sx={{
                      textTransform: 'none',
                      color: isDarkMode ? '#FFF' : '#000',
                    }}
                  >
                    {t('knowMore')}
                  </Button>
                </div>
              )}
            </div>
          </Box>
        </Box>
      </Box>

      <div
        style={{
          paddingLeft: '8%',
          paddingRight: '8%',
          paddingTop: isMobile ? '100px' : '30px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80px',
            gap: { xs: 2, sm: 4 },
          }}
        >
          <Box
            component="img"
            src="https://uploads-ssl.webflow.com/641cd341bb64d84ffad595f1/64eb9bc49f92b72fe49da039_GoogleStartUp.png"
            alt="google"
            sx={{ height: { xs: '20px', sm: '25px' }, maxWidth: '100%' }}
          />
          <Box
            component="img"
            src="/assets/website/v3/websummit.png"
            alt="Websummit"
            sx={{ height: { xs: '22px', sm: '27px' }, maxWidth: '100%' }}
          />
          <Box
            component="img"
            src="https://uploads-ssl.webflow.com/641cd341bb64d84ffad595f1/64eb9bc49f92b72fe49da035_makeNode.png"
            alt="make integromat"
            sx={{
              height: { xs: '20px', sm: '25px' },
              maxWidth: '100%',
              display: { xs: 'block', md: 'block' },
            }}
          />
          <Box
            component="img"
            src="https://uploads-ssl.webflow.com/641cd341bb64d84ffad595f1/64eb9bc49f92b72fe49da03e_ProductHuntNode.png"
            alt="product hunt"
            sx={{
              height: { xs: '22px', sm: '26px' },
              maxWidth: '100%',
              display: { xs: 'block', md: 'block' },
            }}
          />
          <Box
            component="img"
            src="/assets/website/v3/novascience.png"
            alt="Nova Science"
            sx={{
              height: { xs: '30px', sm: '38px' },
              maxWidth: '100%',
              marginTop: { xs: '-2px', sm: '-4px' },
              display: { xs: 'block', md: 'block' },
            }}
          />
        </Box>
      </div>
    </div>
  );
};

export default Header;
