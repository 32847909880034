import * as Actions from '../actions/sidebar-actions';
const initialState = {
  agendaSidebar: { show: false },
  entityChannelSidebar: { show: false },
  locationSidebar: { show: false },
  structureSidebar: { show: false },
};

export const sidebarReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.RESET_SIDEBAR_STATE:
      return initialState;
    case Actions.CLEAR_STATE:
      return initialState;
    case Actions.AGENDA_SIDEBAR:
      return { ...initialState, agendaSidebar: payload };
    case Actions.ENTITY_CHANNEL_SIDEBAR:
      return { ...initialState, entityChannelSidebar: payload };
    case Actions.LOCATION_SIDEBAR:
      return { ...initialState, locationSidebar: payload };
    case Actions.STRUCTURE_SIDEBAR:
      return { ...initialState, structureSidebar: payload };
    default:
      return state;
  }
};
