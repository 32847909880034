import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Paper,
  ListItem,
  Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Select, MenuItem, FormControl } from '@mui/material';
import { CheckCircleOutlined, ViewComfyOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import ActionBtn from '../../screens/mainPages/components/ActionBtn';
import CardDetails from '../../screens/mainPages/components/CardDetails';
import Files from '../../screens/mainPages/components/Files';
import LogFeed from '../../screens/mainPages/components/LogFeed';
import Content from '../../screens/mainPages/components/Content';
import Signature from '../../screens/mainPages/components/Signature';
import Map from '../../screens/mainPages/components/Map';
import MainCardItems from '../../screens/mainPages/components/MainCardItems';
import QuickNote from '../../screens/mainPages/components/QuickNote';
import SecCardItems from '../../screens/mainPages/components/SecCardItems';
import StatusesEvolution from '../../screens/mainPages/components/StatusesEvolution';
import Variants from '../../screens/mainPages/components/Variants';
import Summary from '../../screens/mainPages/components/Summary';
import GeneralText from '../../stories/general-components/GeneralText';
import Button from '../../stories/general-components/Button';
import { useParams } from 'react-router-dom';
import ListSVG from '../../screens/mainPages/components/ListSVG';

const ComponentPopper = ({ onSelect, iden, blockData, elementDetails }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { structureId } = useParams();
  const [selectedTypeAction, setSelectedTypeAction] = useState({
    type: '',
    value: '',
    match: '',
    structureId: null,
  });

  const handleStructureChange = (event) => {
    setSelectedTypeAction({
      ...selectedTypeAction,
      structureId: event.target.value,
    });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = () => {
    const updatedData = {
      ...blockData,
      contentType: selectedTypeAction?.type,
      type: selectedTypeAction?.value,
      match: selectedTypeAction?.match,
      structureId: selectedTypeAction?.structureId,
    };

    onSelect(updatedData, iden);
    setOpen(false);
  };

  useEffect(() => {
    setSelectedTypeAction({
      type: blockData?.contentType,
      value: blockData?.type,
      match: blockData?.match,
      structureId: blockData?.structureId,
    });
  }, [blockData]);


  const handleSelectComponent = (type) => {
    setSelectedTypeAction({
      ...selectedTypeAction,
      ...type,
    });
  };

  const handleSelectionMatch = (value) => {
    setSelectedTypeAction({
      ...selectedTypeAction,
      match: value,
    });
  };

  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );

  const businessStructures = businessStructure?.structures || [];
  const currentStructureDetails = businessStructures?.find(
    (structure) => structure?.id === selectedTypeAction?.structureId
  );

  const pageStructureDetails = businessStructures?.find(
    (structure) => structure?.id === structureId
  );

  const statusField = currentStructureDetails?.fields?.find(
    (field) => field?.value === 'status'
  );


  const connectResolver = () => {
    const allElements = [
      {
        label:
          t('infoOn') + ' <b>' + (blockData?.mainElement?.name || '') + '</b>',
        id: 'id:id',
        value: 'id:id',
        type: ['content', 'action'],
        conditions: [
          { a: currentStructureDetails?.id, operator: '==', b: structureId },
        ],
      },
      {
        label:
          '<b>' +
          currentStructureDetails?.name +
          '</b> ' +
          t('ofTargetBoth') +
          ' <b>' +
          (blockData?.mainElement?.name || '') +
          '</b>',
        id: 'targetId:targetId',
        value: 'targetId:targetId',
        type: ['list'],
        conditions: [
          { a: blockData?.mainElement?.targetId, operator: '!=', b: '' },
          {
            a: currentStructureDetails?.collectionField,
            operator: '!=',
            b: 'contacts',
          },
          { a: currentStructureDetails?.id, operator: '!=', b: structureId },
        ],
      },
      {
        label: t('dependencyBoth'),
        id: 'dependencyId:dependencyId',
        value: 'dependencyId:dependencyId',
        type: ['list'],
        conditions: [
          { a: blockData?.mainElement?.dependencyId, operator: '!=', b: '' },
          { a: currentStructureDetails?.id, operator: '!=', b: structureId },
        ],
      },
      {
        label: t('profileBoth'),
        id: 'targetProfileId:targetProfileId',
        value: 'targetProfileId:targetProfileId',
        type: ['list'],
        conditions: [
          { a: blockData?.mainElement?.targetProfileId, operator: '!=', b: '' },
          { a: currentStructureDetails?.id, operator: '!=', b: structureId },
        ],
      },
      {
        label: t('currentProfileDepElement'),
        id: 'targetProfileId:dependencyId',
        value: 'targetProfileId:dependencyId',
        type: ['list'],
        conditions: [
          { a: currentStructureDetails?.id, operator: '!=', b: structureId },
          { a: blockData?.mainElement?.targetProfileId, operator: '!=', b: '' },
        ],
      },
      {
        label: t('currentTargetDepElement'),
        id: 'targetId:dependencyId',
        value: 'targetId:dependencyId',
        type: ['list'],
        conditions: [
          { a: currentStructureDetails?.id, operator: '!=', b: structureId },
          { a: blockData?.mainElement?.targetId, operator: '!=', b: '' },
          {
            a: pageStructureDetails?.collectionField,
            operator: '!=',
            b: 'contacts',
          },
        ],
      },
      {
        label: t('dependencyTargetProfile'),
        id: 'dependencyId:targetProfileId',
        value: 'dependencyId:targetProfileId',
        type: ['list'],
        conditions: [
          { a: currentStructureDetails?.id, operator: '!=', b: structureId },
          { a: blockData?.mainElement?.targetProfileId, operator: '!=', b: '' },
          {
            a: pageStructureDetails?.collectionField,
            operator: '!=',
            b: 'contacts',
          },
        ],
      },
      {
        label: t('dependencyTarget'),
        id: 'dependencyId:targetId',
        value: 'dependencyId:targetId',
        type: ['list'],
        conditions: [
          { a: currentStructureDetails?.id, operator: '!=', b: structureId },
          { a: blockData?.mainElement?.targetId, operator: '!=', b: '' },
          {
            a: pageStructureDetails?.collectionField,
            operator: '!=',
            b: 'contacts',
          },
        ],
      },
      {
        label: t('currentIdDepElement'),
        id: 'id:dependencyId',
        value: 'id:dependencyId',
        type: ['content', 'list', 'action'],
        conditions: [
          // { a: currentStructureDetails?.id, operator: '==', b: structureId },
          {
            a: pageStructureDetails?.collectionField,
            operator: '!=',
            b: 'contacts',
          },
        ],
      },
      {
        label: t('currentIdTargetProfile'),
        id: 'id:targetProfileId',
        value: 'id:targetProfileId',
        type: ['content'],
        conditions: [
          { a: currentStructureDetails?.id, operator: '==', b: structureId },
          {
            a: currentStructureDetails?.collectionField,
            operator: '!=',
            b: 'profiles',
          },
          {
            a: blockData?.mainElement?.targetProfileId,
            operator: '!=',
            b: '',
          },
        ],
      },
      {
        label: t('currentIdTarget') + ' <b>' + blockData?.mainElement?.name + '</b>',
        id: 'id:targetId',
        value: 'id:targetId',
        type: ['content'],
        conditions: [
          {
            a: pageStructureDetails?.collectionField,
            operator: '!=',
            b: 'contacts',
          },
          {
            a: currentStructureDetails?.collectionField,
            operator: '==',
            b: 'contacts',
          },
          {
            a: blockData?.mainElement?.targetId,
            operator: '!=',
            b: '',
          },
        ],
      },
      {
        label: t('hooked'),
        id: 'id:hookedId',
        value: 'id:hookedId',
        type: ['list'],
        conditions: [
          { a: currentStructureDetails?.id, operator: '!=', b: structureId },
          {
            a: currentStructureDetails?.collectionField,
            operator: '==',
            b: 'articles',
          },
        ],
      },
      {
        label:
          t('last') +
          ' <b>' +
          currentStructureDetails?.name?.toLowerCase() +
          '</b> ' +
          t('ofTargetBoth') +
          ' <b>' +
          (blockData?.mainElement?.name || '') +
          '</b>',
        id: 'last:targetId',
        value: 'last:targetId',
        type: ['content'],
        conditions: [
          { a: currentStructureDetails?.id, operator: '!=', b: structureId },
          { a: blockData?.mainElement?.targetId, operator: '!=', b: '' },
        ],
      },
      {
        label: t('lastTargetProfileId'),
        id: 'last:targetProfileId',
        value: 'last:targetProfileId',
        type: ['content'],
        conditions: [
          { a: currentStructureDetails?.id, operator: '!=', b: structureId },
          { a: blockData?.mainElement?.targetProfileId, operator: '!=', b: '' },
        ],
      },
    ];

    return allElements.filter((element) => {
      if (!element?.type || !element.type.includes(selectedTypeAction?.type)) {
        return false;
      }

      if (element.conditions && element.conditions.length > 0) {
        for (const condition of element.conditions) {
          if (condition.a === undefined || condition.b === undefined) {
            continue;
          }

          let isValid = false;
          switch (condition.operator) {
            case '==':
              isValid = condition.a === condition.b;
              break;
            case '!=':
              isValid = condition.a !== condition.b;
              break;
            case '>':
              isValid = condition.a > condition.b;
              break;
            case '<':
              isValid = condition.a < condition.b;
              break;
            default:
              isValid = false;
          }

          if (!isValid) {
            return false;
          }
        }
      }

      return true;
    });
  };

  const componentResolver = (component) => {
    switch (component) {
      case 'actionBtn':
        return <ActionBtn color={businessPreference?.mainColor} />;
      case 'cardDetails':
        return <CardDetails color={businessPreference?.mainColor} />;
      case 'files':
        return <Files color={businessPreference?.mainColor} />;
      case 'logFeed':
        return <LogFeed color={businessPreference?.mainColor} />;
      case 'map':
        return <Map color={businessPreference?.mainColor} />;
      case 'mainCardItems':
        return <MainCardItems color={businessPreference?.mainColor} />;
      case 'quickNote':
        return <QuickNote color={businessPreference?.mainColor} />;
      case 'secCardItems':
        return <SecCardItems color={businessPreference?.mainColor} />;
      case 'statusesEvolution':
        return <StatusesEvolution color={businessPreference?.mainColor} />;
      case 'variants':
        return <Variants color={businessPreference?.mainColor} />;
      case 'summary':
        return <Summary color={businessPreference?.mainColor} />;
      case 'signature':
        return <Signature color={businessPreference?.mainColor} />;
      case 'mainInfo':
        return <Content color={businessPreference?.mainColor} />;
      case 'list':
        return <ListSVG color={businessPreference?.mainColor} />;
      default:
        return '';
    }
  };

  const components = [
    {
      label: t('content'),
      id: 'content',
      value: 'mainInfo',
      type: 'content',
      conditions: [],
    },
    {
      label: t('list'),
      id: 'list',
      value: 'list',
      type: 'list',
      conditions: [
        { a: currentStructureDetails?.id, operator: '!=', b: structureId },
      ],
    },
    {
      label: t('logFeed'),
      id: 'logFeed',
      value: 'logFeed',
      type: 'action',
      conditions: [
        { a: currentStructureDetails?.id, operator: '==', b: structureId },
      ],
    },
    {
      label: t('quickNote'),
      id: 'quickNote',
      value: 'quickNote',
      type: 'action',
      conditions: [
        { a: currentStructureDetails?.id, operator: '==', b: structureId },
      ],
    },
    {
      label: t('variants'),
      id: 'variants',
      value: 'variants',
      type: 'action',
      conditions: [
        { a: currentStructureDetails?.id, operator: '==', b: structureId },
        {
          a: currentStructureDetails?.collectionField,
          operator: '==',
          b: 'services',
        },
      ],
    },
    {
      label: t('variants'),
      id: 'variants',
      value: 'variants',
      type: 'action',
      conditions: [
        { a: currentStructureDetails?.id, operator: '==', b: structureId },
        {
          a: currentStructureDetails?.collectionField,
          operator: '==',
          b: 'articles',
        },
      ],
    },
    {
      label: t('itemsCard'),
      id: 'mainCardItems',
      value: 'mainCardItems',
      type: 'action',
      conditions: [
        { a: currentStructureDetails?.id, operator: '!=', b: structureId },
        {
          a: currentStructureDetails?.collectionField,
          operator: '==',
          b: 'items',
        },
      ],
    },
    {
      label: t('secItemsCard'),
      id: 'secCardItems',
      value: 'secCardItems',
      type: 'action',
      conditions: [
        { a: currentStructureDetails?.id, operator: '!=', b: structureId },
        {
          a: currentStructureDetails?.collectionField,
          operator: '==',
          b: 'items',
        },
      ],
    },

    {
      label: t('files'),
      id: 'files',
      value: 'files',
      type: 'action',
      conditions: [
        {
          a: currentStructureDetails?.id,
          operator: '==',
          b: structureId,
        },
      ],
    },
    {
      label: t('map'),
      id: 'map',
      value: 'map',
      type: 'action',
      conditions: [
        {
          a: currentStructureDetails?.id,
          operator: '==',
          b: structureId,
        },
      ],
    },
    {
      label: t('actionBtn'),
      id: 'actionBtn',
      value: 'actionBtn',
      type: 'action',
      conditions: [
        {
          a: currentStructureDetails?.id,
          operator: '==',
          b: structureId,
        },
      ],
    },
    {
      label: t('cardDetails'),
      id: 'cardDetails',
      value: 'cardDetails',
      type: 'action',
      conditions: [
        {
          a: currentStructureDetails?.collectionField,
          operator: 'includes',
          b: 'cards',
        },
        {
          a: currentStructureDetails?.id,
          operator: '==',
          b: structureId,
        },
      ],
    },
    {
      label: t('statusesEvolution'),
      id: 'statusesEvolution',
      value: 'statusesEvolution',
      type: 'action',
      conditions: [
        {
          a: currentStructureDetails?.id,
          operator: '==',
          b: structureId,
        },
        {
          a: statusField,
          operator: '!=',
          b: undefined,
        },
      ],
    },
    {
      label: t('summary'),
      id: 'summary',
      value: 'summary',
      type: 'action',
      conditions: [
        {
          a: currentStructureDetails?.id,
          operator: '==',
          b: structureId,
        },
      ],
    },
    {
      label: t('signature'),
      id: 'signature',
      value: 'signature',
      type: 'action',
      conditions: [
        {
          a: currentStructureDetails?.id,
          operator: '==',
          b: structureId,
        },
      ],
    },
  ];

  const filteredComponents = components?.filter((component) => {
    if (component.conditions && component.conditions.length > 0) {
      for (const condition of component.conditions) {
        if (condition.a === undefined || condition.b === undefined) {
          continue;
        }

        let isValid = false;
        switch (condition.operator) {
          case '==':
            isValid = condition.a === condition.b;
            break;
          case '!=':
            isValid = condition.a !== condition.b;
            break;
          case '>':
            isValid = condition.a > condition.b;
            break;
          case '<':
            isValid = condition.a < condition.b;
            break;
          case 'includes':
            isValid = condition.a?.includes(condition.b);
            break;
          default:
            isValid = false;
        }

        if (!isValid) {
          return false;
        }
      }
    }

    return true;
  });

  return (
    <>
      <ViewComfyOutlined
        htmlColor={isDarkMode ? '#F7f7f7' : '#696969'}
        onClick={handleToggle}
        sx={{ fontSize: '14px', cursor: 'pointer', ml: 1 }}
      />
      <div ref={anchorRef}>
        <Drawer anchor="right" open={open} onClose={handleClose}>
          <Paper
            elevation={0}
            sx={{ width: '320px', padding: 2, marginBottom: '180px' }}
          >
            <GeneralText
              primary={true}
              size="bold"
              fontSize="12px"
              text={t('selectDisplay')}
              classNameComponent="mb-2 px-4"
            />
            <FormControl fullWidth sx={{ paddingLeft: 2, paddingRight: 2 }}>
              <Select
                value={selectedTypeAction?.structureId}
                onChange={handleStructureChange}
                disablePortal
                MenuProps={{
                  PaperProps: {
                    sx: {
                      zIndex: 99999,
                    },
                  },
                }}
              >
                {businessStructures?.map((structure) => (
                  <MenuItem key={structure?.id} value={structure.id}>
                    {structure?.id === structureId
                      ? t('currentStruc') + ' ' + structure?.name
                      : structure?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Divider sx={{ mt: 2, mb: 3 }} component="div" />
            <GeneralText
              primary={true}
              size="bold"
              fontSize="12px"
              text={t('selectComponent')}
              classNameComponent="mb-2 px-4"
            />
            {selectedTypeAction?.structureId &&
              filteredComponents?.length > 0 &&
              filteredComponents?.map((component) => (
                <div
                  key={component?.id}
                  className="align-c px-4"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={
                    selectedTypeAction?.value !== component?.value
                      ? () => handleSelectComponent(component)
                      : null
                  }
                >
                  <div
                    style={{
                      border: '1px solid #69696950',
                      borderRadius: '6px',
                      cursor: 'pointer',
                      position: 'relative',
                      textAlign: 'center',
                      height:
                        selectedTypeAction?.value === component?.value
                          ? 'auto'
                          : '110px',
                      backgroundColor: businessPreference?.mainColor + '03',
                      overflow: 'hidden',
                      maxWidth: '250px',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    className="p-2 mb-3 align-c"
                  >
                    {selectedTypeAction?.value === component?.value && (
                      <div>
                        <CheckCircleOutlined
                          style={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            padding: '3px',
                            color: 'green',
                          }}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        height: '60px',
                        maxWidth: '240px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '14px',
                      }}
                    >
                      {componentResolver(component?.value)}
                    </div>
                    <div className="mt-2" style={{ height: '20px' }}>
                      <GeneralText
                        primary={true}
                        size="medium"
                        fontSize="11px"
                        text={component?.label}
                      />
                    </div>
                    {selectedTypeAction?.value === component?.value && (
                      <div>
                        {connectResolver()?.length === 0 && (
                          <div>{t('invalidActionZero')}</div>
                        )}
                        {connectResolver()?.map((item) => (
                          <div
                            key={item?.id}
                            className="mb-1"
                            style={{
                              backgroundColor:
                                selectedTypeAction?.match === item?.id
                                  ? businessPreference?.mainColor + '10'
                                  : 'transparent',
                            }}
                          >
                            <ListItem
                              dense
                              button='true'
                              divider
                              onClick={() => handleSelectionMatch(item?.id)}
                            >
                              <GeneralText
                                primary={true}
                                fontSize="10px"
                                text={item?.label}
                                size="regular"
                                markdown
                              />
                            </ListItem>
                          </div>
                        ))}
                        <div className="mt-3">
                          <Button
                            label={t('save')}
                            noShadow
                            size="small"
                            disabled={
                              connectResolver()?.length === 0 ||
                              !selectedTypeAction?.match
                            }
                            onClick={() => handleSelect()}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </Paper>
        </Drawer>
      </div>
    </>
  );
};

export default ComponentPopper;
