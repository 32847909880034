import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useWindowSize } from 'react-use';

// Components
import BlockLayout from '../../stories/layout-components/BlockLayout';

// Style imports for react-grid-layout (ensure these are correctly imported in your project)
import 'react-grid-layout/css/styles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

const ElementDetailsContent = ({
  layout: layoutFromProps,
  setLayout: setParentLayout,
  publicMode,
  editMode,
  activeIndex,
  refreshDoc,
  elementContext,
  fromList,
  componentData,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const currentLangCode = i18n.language;
  const { height } = useWindowSize();

  // --- State ---
  const [rowHeight, setRowHeight] = useState(30);
  const [isDraggingGrid, setIsDraggingGrid] = useState(false);

  // Internal layout state to manage grid operations without constantly prop-drilling
  const [internalLayout, setInternalLayout] = useState([]);
  const internalLayoutRef = useRef(internalLayout);

  // --- Sync internal layout with props ---
  useEffect(() => {
    const validatedLayout = Array.isArray(layoutFromProps)
      ? layoutFromProps
      : [];
    setInternalLayout(validatedLayout);
    internalLayoutRef.current = validatedLayout;
  }, [layoutFromProps]);

  // --- Calculate Row Height ---
  useEffect(() => {
    const availableHeight = height - 140;
    const desiredRows = 33;
    const calculatedHeight = Math.max(10, availableHeight / desiredRows);
    setRowHeight(calculatedHeight);
  }, [height]);

  // --- Callbacks for Grid Layout ---
  useEffect(() => {
    const validatedLayout = Array.isArray(layoutFromProps)
      ? layoutFromProps
      : [];
    if (
      JSON.stringify(validatedLayout) !==
      JSON.stringify(internalLayoutRef.current)
    ) {
      setInternalLayout(validatedLayout);
      internalLayoutRef.current = validatedLayout;
    }
  }, [layoutFromProps]);

  // Called by react-grid-layout on drag/resize stop
  const handleLayoutChange = useCallback(
    (updatedLayoutFromGrid) => {
      if (!editMode) return;

      setParentLayout((prevParentLayout) => {
        const currentFullLayout = Array.isArray(prevParentLayout)
          ? [...prevParentLayout]
          : [];

        // Create a map of the updates received for quick lookup (key: block.i)
        const updatesMap = new Map();
        updatedLayoutFromGrid.forEach((item) => {
          // Ensure item is valid before adding to map
          if (item && item.i !== undefined) {
            updatesMap.set(item.i, item);
          }
        });

        // If no updates were received (e.g., empty layout interaction), return the previous state
        if (updatesMap.size === 0) {
          return prevParentLayout;
        }

        // Create the new full layout by merging updates into the previous full layout
        const newFullLayout = currentFullLayout.map((block) => {
          // Check if the current block from the full layout exists in the updates map
          if (updatesMap.has(block.i)) {
            // If yes, it means this block was on the current tab and might have changed.
            // Merge its latest grid properties (x, y, w, h) from the update.
            const updatedGridProps = updatesMap.get(block.i);
            return {
              ...block, // Keep existing block data (type, content, header, index, etc.)
              x: updatedGridProps.x,
              y: updatedGridProps.y,
              w: updatedGridProps.w,
              h: updatedGridProps.h,
            };
          } else {
            // Otherwise, this block belongs to a different tab, keep it as it was.
            return block;
          }
        });

        return newFullLayout; // Return the updated full layout state for the parent
      });

      // --- REMOVE direct setInternalLayout calls here ---
      // The internal layout should ONLY be updated via the useEffect hook
      // when the layoutFromProps prop changes as a result of setParentLayout.
      // setInternalLayout(validLayout); // <- REMOVE THIS (was incorrect logic anyway)
    },
    [setParentLayout, editMode]
  );

  // --- Callbacks passed down to BlockLayout ---

  // Called from BlockLayout/Poppers to update specific block settings (like header name, color, etc.)
  // This updates the parent's state directly.
  const handleUpdateBlockSettings = useCallback(
    (updatedSettings, blockIndex, position) => {
      if (position === 'header') {
        setParentLayout((prevLayout) => {
          const newLayout = prevLayout.map((block) => {
            if (block.i === blockIndex) {
              return {
                ...block,
                header: {
                  ...block?.header,
                  ...updatedSettings,
                },
                ...updatedSettings,
              };
            }
            return block;
          });
          internalLayoutRef.current = newLayout;
          return newLayout;
        });
      } else {
        setParentLayout((prevLayout) => {
          const newLayout = prevLayout.map((block) => {
            if (block.i === blockIndex) {
              return {
                ...block,
                ...updatedSettings,
              };
            }
            return block;
          });
          internalLayoutRef.current = newLayout;
          return newLayout;
        });
      }
    },
    [setParentLayout]
  );

  // Called from BlockLayout to remove a block
  const handleRemoveBlock = useCallback(
    (blockIndexToRemove) => {
      setParentLayout((prevLayout) => {
        const newLayout = prevLayout.filter(
          (block) => block.i !== blockIndexToRemove
        );
        internalLayoutRef.current = newLayout;
        return newLayout;
      });
    },
    [setParentLayout]
  );

  // --- Dynamic Styles ---
  // Style for resize handle (minor optimization: use useMemo)
  const resizeHandleStyle = useMemo(
    () => `
      .react-resizable-handle.react-resizable-handle-se {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        /* background-color: ${editMode ? theme.palette.secondary.main + '80' : 'transparent'
      }; */
        position: absolute;
        right: 1px;
        bottom: 1px;
        cursor:${editMode ? 'se-resize' : 'default'} !important;
        z-index: 10; /* Ensure handle is clickable */
        /* Add visual indicator */
        /* border: ${editMode ? `1px dashed ${theme.palette.secondary.main}` : 'none'
      }; */
        opacity: ${editMode ? 0.7 : 0};
        transition: opacity 0.2s ease-in-out;
      }
      .react-grid-item:hover .react-resizable-handle.react-resizable-handle-se {
         opacity: ${editMode ? 0.9 : 0};
      }
    `,
    [editMode]
  );

  // Inject style tag dynamically
  useEffect(() => {
    const styleTagId = 'rgl-resize-handle-style';
    let styleElement = document.getElementById(styleTagId);
    if (!styleElement) {
      styleElement = document.createElement('style');
      styleElement.id = styleTagId;
      document.head.appendChild(styleElement);
    }
    styleElement.innerHTML = resizeHandleStyle;

    // Cleanup function
    return () => {
      const existingStyleElement = document.getElementById(styleTagId);
      if (existingStyleElement) {
        document.head.removeChild(existingStyleElement);
      }
    };
  }, [resizeHandleStyle]);

  // --- Render Logic ---
  const renderGridItems = (layoutToRender) => {
    if (!Array.isArray(layoutToRender)) {
      return null;
    }
    return layoutToRender
      .filter((blockLayout) => blockLayout && blockLayout.index === activeIndex)
      .map((blockLayout) => (
        <motion.div
          key={blockLayout.i}
          initial={{ opacity: 0.8 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          style={{ width: '100%', height: '100%' }}
          className="grid-item-wrapper"
          onPointerDown={(e) => e.stopPropagation()}
        >
          <BlockLayout
            key={`${blockLayout.i + activeIndex}`}
            childrenComponent={blockLayout?.contentType}
            editMode={editMode}
            componentData={componentData}
            publicMode={publicMode}
            fromList={fromList}
            setDrag={setIsDraggingGrid}
            activeIndex={activeIndex}
            handleUpdateBlock={handleUpdateBlockSettings}
            heightPercentage={
              blockLayout?.height || (blockLayout.h * rowHeight) / 12
            }
            handleRefresh={refreshDoc}
            handleRemoveBlock={handleRemoveBlock}
            layout={blockLayout}
            elementDetails={{
              mainElement: blockLayout?.mainElement,
              data: blockLayout?.data,
              header: blockLayout?.header,
              type: blockLayout?.type,
              elementData: elementContext,
              groups: blockLayout?.groups,
              i: blockLayout?.i,
              index: blockLayout?.index,
            }}
          />
        </motion.div>
      ));
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: 'calc(100% - 2px)',
        overflow: 'hidden',
      }}
    >
      <ResponsiveGridLayout
        className={`layout ${publicMode ? 'public' : 'business'}`}
        style={{
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
        layouts={{ lg: internalLayout }}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={rowHeight}
        margin={[10, 10]}
        containerPadding={[8, 8]}
        isDraggable={editMode && isDraggingGrid}
        isResizable={editMode}
        allowOverlap={false}
        onLayoutChange={handleLayoutChange}
        onDragStart={() => setIsDraggingGrid(true)}
        onDragStop={() => setIsDraggingGrid(false)}
      >
        {renderGridItems(internalLayout)}
      </ResponsiveGridLayout>
    </div>
  );
};

export default ElementDetailsContent;
