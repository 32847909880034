import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import OnboardLayout from './OnboardLayout';
import { setOnboardData } from '../../redux/actions-v2/coreAction';
import GeneralText from '../../stories/general-components/GeneralText';
import { CheckCircleOutlined, Inventory2Outlined, ListAltOutlined, ReceiptLongOutlined, TaskAltOutlined, ViewKanbanOutlined } from '@mui/icons-material';
import { Modal } from '@mui/material';

const Step6 = () => {
    const dispatch = useDispatch();
    const { t, } = useTranslation();
    const pageCode = 'step6Onboard';
    const onboardData = useSelector((state) => state.core.onboardData);

    const [selectedmoduleItems, setSelectedmoduleItems] = useState(onboardData?.selectedmoduleItems || []);
    const [hoveredmoduleItem, setHoveredmoduleItem] = useState('');
    const [openDetails, setOpenDetails] = useState(null);

    const handleSelectmoduleItem = (moduleItemId) => {
        const isSelected = selectedmoduleItems.includes(moduleItemId);

        const updatedSelectedModules = isSelected
            ? selectedmoduleItems.filter((item) => item !== moduleItemId)
            : [...selectedmoduleItems, moduleItemId];

        const allCollections = updatedSelectedModules
            .map((id) => modulesList.find((m) => m.id === id)?.collections || [])
            .flat();

        const uniqueCollections = Array.from(new Set(allCollections));

        setSelectedmoduleItems(updatedSelectedModules);


        dispatch(setOnboardData({ selectedmoduleItems: updatedSelectedModules, collectionsToCreate: uniqueCollections }));
    };

    const modulesList = [
        { id: 'crm', collections: ['contacts', 'grids'], name: t(pageCode + 'CRM'), desc: t(pageCode + 'crmDesc'), icon: <ViewKanbanOutlined /> },
        { id: 'inventory', collections: ['articles'], name: t(pageCode + 'inventory'), desc: t(pageCode + 'inventoryDesc'), icon: <Inventory2Outlined /> },
        { id: 'webisteForm', collections: ['grids'], name: t(pageCode + 'webisteForm'), desc: t(pageCode + 'webisteFormDesc'), icon: <ListAltOutlined /> },
        { id: 'invoicing', collections: ['cardsinvoiced', 'cardsuninvoiced', 'items', 'payments', 'contacts', 'services', 'articles'], name: t(pageCode + 'invoicing'), desc: t(pageCode + 'invoicingDesc'), icon: <ReceiptLongOutlined /> },
        { id: 'tasks', collections: ['nodies'], modules: ['list'], name: t(pageCode + 'tasksManagement'), desc: t(pageCode + 'tasksManagementDesc'), icon: <TaskAltOutlined /> },
    ];

    const previewText = (text) => {
        return text.length > 100 ? text.slice(0, 100) + '...' : text;
    }

    return (
        <OnboardLayout stepTitle={t(pageCode + 'Title')} stepDesc={t(pageCode + 'Desc')} restrictNext={false}>
            <Modal
                open={Boolean(openDetails)}
                onClose={() => setOpenDetails(null)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >

                <div className='align-left' style={{ position: 'absolute', backgroundColor: '#FFFFFF', padding: '40px', borderRadius: '10px', width: '780px', left: '50%', transform: 'translate(-50%, -50%)', top: '50%' }}>
                    <h2 className='mt-4' style={{ fontSize: '18px' }} id="simple-modal-title">{openDetails?.name}</h2>
                    <p id="simple-modal-description">
                        {openDetails?.desc}
                    </p>
                </div>
            </Modal>
            <div className='row' style={{ filter: openDetails ? 'blur(3px)' : 'none', transition: 'all 0.2s ease' }}>
                {modulesList?.map((moduleItem) => (
                    <div
                        onMouseEnter={() => setHoveredmoduleItem(moduleItem?.id)}
                        onMouseLeave={() => setHoveredmoduleItem('')}
                        className={(moduleItem?.id === 'other' && selectedmoduleItems?.includes('other')) ? 'col-12' : 'col-6'}
                        key={moduleItem.id}
                    >
                        <div
                            className='mt-3 mb-3'
                            style={{
                                backgroundColor: selectedmoduleItems?.includes(moduleItem?.id) ? onboardData?.secColor + '10' : 'transparent',
                                cursor: 'pointer',
                                border: '1px solid',
                                borderColor: selectedmoduleItems?.includes(moduleItem?.id) ? onboardData?.secColor : '#69696950',
                                boxShadow: hoveredmoduleItem === moduleItem?.id ? '5px 5px 15px 0px rgba(0,0,0,0.1)' : '5px 5px 15px 0px rgba(0,0,0,0.06)',
                                borderRadius: '6px',
                                padding: '10px',
                                minHeight: '80px',
                                transition: 'all 0.3s ease'
                            }}
                        >
                            <div className='d-flex' style={{ position: 'relative', width: '100%' }}>
                                <div
                                    onClick={() => handleSelectmoduleItem(moduleItem?.id)}
                                    style={{ position: 'absolute', right: '0', top: '0' }}
                                >
                                    {selectedmoduleItems?.includes(moduleItem.id) && (
                                        <CheckCircleOutlined htmlColor={onboardData?.secColor} />
                                    )}
                                </div>
                                <div
                                    style={{ width: moduleItem?.id === 'other' && selectedmoduleItems?.includes('other') ? '' : '100%' }}
                                    onClick={() => handleSelectmoduleItem(moduleItem?.id)}
                                    className={moduleItem?.id === 'other' && selectedmoduleItems?.includes('other') ? 'col-6' : ''}
                                >
                                    {moduleItem?.icon}
                                    <GeneralText
                                        text={moduleItem?.name}
                                        primary={true}
                                        fontSize={'12px'}
                                        size={selectedmoduleItems?.includes(moduleItem?.id) ? 'medium' : 'regular'}
                                    />
                                    <div style={{

                                        overflow: 'hidden',
                                        transition: 'max-height 1.3s ease'
                                    }}>
                                        <GeneralText
                                            text={previewText(moduleItem?.desc)}
                                            primary={true}
                                            fontSize={'10px'}
                                            size={'regular'}
                                            classNameComponent='greyText'
                                        />
                                        <GeneralText
                                            text={t('readMore') + "..."}
                                            primary={true}
                                            fontSize={'10px'}
                                            size={'medium'}
                                            classNameComponent='hover'
                                            onClick={() => setOpenDetails(moduleItem)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </OnboardLayout>
    );
};

export default Step6;