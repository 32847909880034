import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Grid,
  Button,
  TextField,
} from '@mui/material';
import Typo from '../Typo';
import ComponentsCases from '../dynamicSVG/ComponentsCases';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

const HeaderCases = ({ translationCode }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';
  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const secColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const darkMainColor = chroma(mainColor).darken(1.2).hex();
  const lightMainColor = chroma(mainColor + '40')
    .brighten(1.2)
    .hex();

  const navigateOnboard = () => {
    navigate('/onboard');
  };

  return (
    <div
      style={{
        marginTop: isMobile ? '100px' : '200px',
        position: 'relative',
        height: '80vh',
        maxHeight: '700px',
      }}
      className={isMobile ? 'px-4' : ''}
    >
      <Box
        sx={{
          width: '723px',
          height: '723px',
          background: `radial-gradient(circle, ${mainColor + '40'
            } 5%, transparent 60%)`,
          position: 'absolute',
          top: 135,
          left: '30%',
        }}
      />
      <Box
        sx={{
          width: '923px',
          height: '823px',
          background: `radial-gradient(circle, ${darkMainColor + '30'
            } 5%, transparent 60%)`,
          position: 'absolute',
          top: 15,
          left: '2%',
        }}
      />
      <div className="container">
        <div style={{ position: 'absolute', left: 10, top: 60, zIndex: 2 }}>
          <Typo
            text={t(translationCode + 'Top')}
            variant="h2"
            sx={{ maxWidth: '400px' }}
          />
          <Typo
            text={t(translationCode + 'Text')}
            variant="body2"
            className={'mt-5'}
            sx={{ maxWidth: '400px' }}
          />
          <div className="mt-5">
            <Box
              sx={{
                marginLeft: '-5px',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                height: '40px',
                maxWidth: '450px',
                backgroundColor: '#fff',
                borderRadius: '25px',
              }}
            >
              <TextField
                variant="standard"
                placeholder="Courriel"
                fullWidth
                sx={{
                  height: '40px',
                }}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    height: '50px',
                    padding: '6px 15px',
                    fontSize: '16px',
                    color: '#999',
                  },
                }}
              />

              <Button
                variant="contained"
                disableElevation
                onClick={navigateOnboard}
                sx={{
                  backgroundColor: mainColor,
                  color: '#fff',
                  borderRadius: '20px',
                  textTransform: 'none',
                  height: '40px',
                  padding: '8px 20px',
                  marginLeft: '5px',
                  '&:hover': {
                    backgroundColor: darkMainColor,
                  },
                }}
              >
                Démarrer
              </Button>
            </Box>
          </div>
        </div>
      </div>
      <div style={{ position: 'absolute', right: 0, bottom: 0, zIndex: 2 }}>
        <ComponentsCases
          mainColor={mainColor}
          secColor={secColor}
          darkMainColor={darkMainColor}
          lightMainColor={lightMainColor}
          isMobile={isMobile}
        />
      </div>
      <div style={{ position: 'absolute', left: 0, bottom: 0, zIndex: 1 }}>
        <img
          src="/assets/website/v3/grid-main.svg"
          width={'100%'}
          style={{ opacity: isDarkMode ? 0.2 : 0.5 }}
        />
      </div>
    </div>
  );
};

export default HeaderCases;
