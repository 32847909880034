import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import chroma from 'chroma-js';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typo from '../Typo';
import rawAnimationData from '../../../lotties/general-main.json';
import { useMediaQuery } from '@mui/material';
import Lottie from 'react-lottie';

const HeaderBos = ({ translationCode }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';

  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');

  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const varColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const initialOpacities = [0.18, 0.2, 0.15, 0.18, 0.2];
  const [opacities] = useState(initialOpacities);

  const lightMainColor = chroma(mainColor + '90')
    .brighten(2.2)
    .hex();

  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  const modifyColors = (animationData) => {
    const clonedData = JSON.parse(JSON.stringify(animationData));

    const targetColor = [
      0.3686274509803922, 0.7843137254901961, 0.8588235294117647,
    ];

    const targetBlack = [
      0.8666666666666667, 0.8666666666666667, 0.8666666666666667,
    ];

    const targetBlack2 = [0, 0, 0];

    const targetGrey = [
      0.8509803921568627, 0.8509803921568627, 0.8509803921568627,
    ];

    //transform hex color to normalized format with chroma
    const newColor = chroma(mainColor)
      .rgb()
      .map((c) => c / 255);

    const newBlackColor = chroma(darkMainColor)
      .rgb()
      .map((c) => c / 255);

    const newGreyColor = chroma(lightMainColor)
      .rgb()
      .map((c) => c / 255);

    const changeColor = (obj) => {
      if (obj.ty === 'fl' && obj.c && obj.c.k) {
        let colorValue = obj.c.k;

        if (Array.isArray(colorValue) && colorValue.length === 3) {
          if (
            Math.abs(colorValue[0] - targetColor[0]) < 0.01 &&
            Math.abs(colorValue[1] - targetColor[1]) < 0.01 &&
            Math.abs(colorValue[2] - targetColor[2]) < 0.01
          ) {
            obj.c.k = newColor;
          } else if (
            Math.abs(colorValue[0] - targetBlack[0]) < 0.01 &&
            Math.abs(colorValue[1] - targetBlack[1]) < 0.01 &&
            Math.abs(colorValue[2] - targetBlack[2]) < 0.01
          ) {
            obj.c.k = newGreyColor;
          } else if (
            Math.abs(colorValue[0] - targetBlack2[0]) < 0.01 &&
            Math.abs(colorValue[1] - targetBlack2[1]) < 0.01 &&
            Math.abs(colorValue[2] - targetBlack2[2]) < 0.01
          ) {
            obj.c.k = newBlackColor;
          } else if (
            Math.abs(colorValue[0] - targetGrey[0]) < 0.01 &&
            Math.abs(colorValue[1] - targetGrey[1]) < 0.01 &&
            Math.abs(colorValue[2] - targetGrey[2]) < 0.01
          ) {
            obj.c.k = newGreyColor;
          } else {
          }
        }
      }

      // Recursively check nested items
      if (obj.it && Array.isArray(obj.it)) {
        obj.it.forEach(changeColor);
      }
      if (obj.shapes && Array.isArray(obj.shapes)) {
        obj.shapes.forEach(changeColor);
      }
    };

    // Traverse `layers`
    if (clonedData.layers) {
      clonedData.layers.forEach((layer) => {
        if (layer.shapes) {
          layer.shapes.forEach(changeColor);
        }
      });
    }

    // Traverse `assets.layers`
    if (clonedData.assets) {
      clonedData.assets.forEach((asset) => {
        if (asset.layers) {
          asset.layers.forEach((layer) => {
            if (layer.shapes) {
              layer.shapes.forEach(changeColor);
            }
          });
        }
      });
    }

    return clonedData;
  };

  // Apply modified animation data
  const modifiedAnimationData = modifyColors(rawAnimationData);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: modifiedAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      progressiveLoad: false,
    },
  };


  const [offsets, setOffsets] = useState([
    { x: 0, y: 0 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
  ]);

  const navigateDragDop = () => {
    navigate('/onboard');
  };

  useEffect(() => {
    const updateOffsetsRandomly = () => {
      const rangeX = 350;
      const rangeY = 150;

      const newOffset = {
        x: Math.floor(Math.random() * (rangeX * 2)) - rangeX,
        y: Math.floor(Math.random() * (rangeY * 2)) - rangeY,
      };

      setOffsets((prev) => {
        const newOffsets = [...prev];
        newOffsets[3] = newOffset;
        return newOffsets;
      });
    };

    updateOffsetsRandomly();
    const interval = setInterval(updateOffsetsRandomly, 12000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      position="relative"
      width="100%"
      height="90vh"
      marginTop="4vh"
      overflow="hidden"
    >
      <div>
        <Box
          sx={{
            width: '723px',
            height: '723px',
            background: `radial-gradient(circle, ${mainColor} 5%, transparent 60%)`,
            position: 'absolute',
            top: 135,
            left: '50%',
            opacity: opacities[3],
            transform: `translate(${offsets[3].x}px, ${offsets[3].y}px)`,
            transition: 'transform 1.1s ease-out, opacity 1.1s ease-out',
          }}
        />
        <Box
          sx={{
            width: '823px',
            height: '823px',
            background: `radial-gradient(circle, ${varColor} 5%, transparent 60%)`,
            position: 'absolute',
            top: 15,
            left: '5%',
            opacity: opacities[3],
            transform: `translate(${offsets[3].x}px, ${offsets[3].y}px)`,
            transition: 'transform 1.1s ease-out, opacity 1.1s ease-out',
          }}
        />
        <Box
          sx={{
            width: '823px',
            height: '823px',
            background: `radial-gradient(circle, ${mainColor} 5%, transparent 60%)`,
            position: 'absolute',
            top: '-80px',
            left: '30%',
            opacity: opacities[4],
            transform: `translate(${offsets[3].x}px, ${offsets[3].y}px)`,
            transition: 'transform 1.1s ease-out, opacity 1.1s ease-out',
          }}
        />
        <Box
          position="absolute"
          top="6%"
          left="50%"
          sx={{ transform: 'translateX(-50%)' }}
          width={isMobile ? '80%' : '50%'}
          display="flex"
          flexDirection="column"
          alignItems="center"
          zIndex={10}
          gap={2}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
            px={2}
            py={1}
            borderRadius="20px"
            border={isDarkMode ? '1px solid #FFFFFF80' : '1px solid #2222221a'}
          >
            <Typography
              variant="body2"
              fontWeight="medium"
              textAlign="center"
              color={isDarkMode ? '#FFFFFF' : '#222222'}
              sx={{ fontFamily: "'Montserrat', Helvetica" }}
            >
              {t(translationCode + 'Chip')}
            </Typography>
          </Box>

          <Typo
            text={t(translationCode + 'Top')}
            variant="h2"
            className="align-c"
          />
        </Box>

        <Box
          component="img"
          src={'/assets/website/v3/grid.svg'}
          alt="Calque"
          sx={{ transform: 'translateX(-50%)', opacity: isDarkMode ? 0.3 : 1 }}
          position="absolute"
          top="-15%"
          left="50%"
          width="60%"
          height="100%"
          minWidth={700}
        />


        <Box
          width={isMobile ? '45%' : '70%'}
          maxWidth="800px"
          position="absolute"
          height="auto"
          top={isMobile ? '35%' : '27%'}
          left={isMobile ? '8%' : '25%'}
          zIndex={1}
          minWidth={400}
        >
          <Lottie options={defaultOptions} height={isMobile ? 200 : 420} width={isMobile ? 400 : 890} />
        </Box>
        <div
          style={{
            width: '110%',
            position: 'absolute',
            marginLeft: '-5%',
            bottom: 0,
            height: '450px',
            background: `linear-gradient(to top, ${isDarkMode ? '#000000' : '#FFFFFF'
              } 0%, transparent 60%)`,
          }}
        />
      </div>
      <Box
        className="align-c"
        bottom="50px"
        sx={{ transform: 'translateX(-50%)' }}
        left="50%"
        position="absolute"
        height="5vh"
      >
        <div style={{ position: 'relative' }}>
          <div>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={navigateDragDop}
              sx={{
                top: '80%',
                backgroundColor: darkMainColor,
                borderRadius: '21px',
                textTransform: 'none',
                padding: '8px 20px',
              }}
            >
              {t(translationCode + 'DiscoverVB')}
            </Button>
          </div>
          <div className="mt-5">
            <Box
              component="img"
              src={'/assets/website/v3/trio.svg'}
              alt="Image"
              width={isMobile ? '350px' : '450px'}
            />
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default HeaderBos;
