import React, { useEffect } from 'react';

const Docs = () => {
  useEffect(() => {
    window.location.href = 'https://docs.usenode.com';
  }, []);

  return null;
};

export default Docs;
