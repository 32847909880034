import React, { } from 'react';
import SiteLayout from '../SiteLayout';
import { useTranslation } from 'react-i18next';

const Blog = () => {
  const { t } = useTranslation();
  const pageCode = 'blog';
  return (
    <SiteLayout
      helmetData={{
        name: t(pageCode + 'SEO'),
        description: t(pageCode + 'SEODesc'),
      }}
    >
      <div></div>
    </SiteLayout>
  );
};

export default Blog;
