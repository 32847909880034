import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import SiteLayout from '../SiteLayout';
import HeaderCases from '../components/bos/HeaderCases';
import ConnectCases from '../components/bos/ConnectCases';
import WorkspaceCases from '../components/bos/WorkspaceCases';
import CardsCases from '../components/bos/CardsCases';
import AlignCases from '../components/bos/AlignCases';
import PerfoCases from '../components/bos/PerfoCases';

const CasesBos = () => {
  const { t, } = useTranslation();

  const pageCode = 'casesBOS';
  return (
    <SiteLayout
      helmetData={{
        name: t(pageCode + 'SEO'),
        description: t(pageCode + 'SEODesc'),
      }}
    >
      <div>
        <HeaderCases translationCode={pageCode + 'Header'} />
      </div>
      <div>
        <ConnectCases translationCode={pageCode + 'Connect'} />
      </div>
      <div>
        <WorkspaceCases translationCode={pageCode + 'Workspace'} />
      </div>
      <div>
        <CardsCases translationCode={pageCode + 'Tiles'} />
      </div>
      <div>
        <AlignCases translationCode={pageCode + 'Align'} />
      </div>
      <div>
        <PerfoCases translationCode={pageCode + 'Perfo'} />
      </div>
    </SiteLayout>
  );
};

export default CasesBos;
