import * as React from 'react';
const EcosystemSVG = ({ mainColor, lightMainColor, darkMainColor }) => (
  <svg
    width={230}
    height={230}
    viewBox="0 0 230 230"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx={115}
      cy={115}
      r={56.3721}
      stroke="url(#paint0_linear_267_178)"
      strokeWidth={6}
    />
    <circle
      cx={114.794}
      cy={115.301}
      r={84.1861}
      transform="rotate(85.3939 114.794 115.301)"
      stroke="url(#paint1_linear_267_178)"
      strokeWidth={6}
    />
    <circle
      cx={115}
      cy={115}
      r={112}
      stroke="url(#paint2_linear_267_178)"
      strokeWidth={6}
    />
    <circle cx={168.477} cy={96.5432} r={7.57202} fill={darkMainColor} />
    <circle cx={99.3826} cy={197.819} r={7.57202} fill={mainColor} />
    <circle cx={16.0906} cy={65.3086} r={7.57202} fill={lightMainColor} />
    <path
      d="M115 145C126.598 145 136 135.822 136 124.5C136 113.178 126.598 104 115 104C103.402 104 94 113.178 94 124.5C94 135.822 103.402 145 115 145Z"
      fill={mainColor}
    />
    <path
      d="M119 113C115.134 113 112 116.134 112 120C112 123.866 115.134 127 119 127C122.866 127 126 123.866 126 120C126 116.134 122.866 113 119 113Z"
      fill="white"
    />
    <path
      opacity={0.69}
      d="M105.259 125.691C108.458 124.199 111.661 122.703 114.859 121.211C116.325 120.527 118.01 121.587 118 123.181L117.948 133.837C117.937 135.56 115.997 136.583 114.541 135.642L104.993 129.466C103.559 128.535 103.705 126.416 105.259 125.691Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_267_178"
        x1={102.163}
        y1={114.465}
        x2={90.9303}
        y2={60.4419}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={darkMainColor} stopOpacity={0} />
        <stop offset={0.976403} stopColor={darkMainColor} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_267_178"
        x1={88.8794}
        y1={157.216}
        x2={79.4485}
        y2={35.1845}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={mainColor} stopOpacity={0} />
        <stop offset={1} stopColor={mainColor} />
      </linearGradient>
      <linearGradient
        id="paint2_linear_267_178"
        x1={43.8605}
        y1={50.2791}
        x2={60.4419}
        y2={217.163}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={lightMainColor} stopOpacity={0} />
        <stop offset={1} stopColor={lightMainColor} />
      </linearGradient>
    </defs>
  </svg>
);
export default EcosystemSVG;
