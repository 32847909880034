import React, { } from 'react';
import SiteLayout from '../SiteLayout';
import { useTranslation } from 'react-i18next';

const ReportsPulse = () => {
  const { t } = useTranslation();
  const pageCode = 'reportsPulse';
  return (
    <div>
      <SiteLayout
        helmetData={{
          name: t(pageCode + 'SEO'),
          description: t(pageCode + 'SEODesc'),
        }}
      >
        <div className="mt-5 mb-5"></div>
        <div className="mt-5 mb-5"></div>
      </SiteLayout>
    </div>
  );
};

export default ReportsPulse;
