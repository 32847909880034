import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import animationData from '../../../lotties/messages.json';
import Lottie from 'react-lottie';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import Typo from '../Typo';
import { useMediaQuery } from '@mui/material';
import Workspaces from '../dynamicSVG/Workspaces';
import { useNavigate } from 'react-router-dom';

const WorkspaceCases = ({ translationCode }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';
  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const secColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      progressiveLoad: false,
    },
  };

  const navigateOnboard = () => {
    navigate('/onboard');
  };

  return (
    <div style={{ marginTop: '60px' }} className="container">
      <div className={isMobile ? 'row mt-5 px-4' : 'row mt-5'}>
        <div className="col-md-7 col-12 py-5">
          <Typo text={t(translationCode + 'Title')} variant="h2" />
          <Typo
            text={t(translationCode + 'Desc')}
            variant="subTitle"
            className="mt-4"
          />
        </div>
        <div
          style={{ position: 'relative' }}
          className="col-md-5 col-12 align-c"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={navigateOnboard}
            disableElevation
            style={{
              marginTop: '40px',
              textTransform: 'none',
              borderRadius: '50px',
              padding: '8px 20px',
              backgroundColor: darkMainColor,
            }}
          >
            {t(translationCode + 'CTA')}
          </Button>
        </div>
      </div>
      <div className={isMobile ? 'row px-4' : 'row'}>
        <div
          style={{
            position: 'relative',
            marginTop: '-60px',
            paddingLeft: '80px',
          }}
          className="col-md-6 col-12"
        >
          <div
            style={{
              backgroundColor: darkMainColor + '20',
              borderRadius: '15px',
              width: '320px',
              height: '270px',
              position: 'absolute',
              top: 80,
              left: 50,
            }}
          />
          <div
            style={{
              marginTop: '40px',
              position: 'absolute',
              top: 60,
              marginLeft: isMobile ? '-60px' : '',
            }}
          >
            <Workspaces
              mainColor={mainColor}
              secColor={secColor}
              darkMainColor={darkMainColor}
            />
          </div>
          <div
            style={{
              marginTop: isMobile ? '110px' : '90px',
              marginLeft: isMobile ? '80px' : '40px',
            }}
          >
            <Lottie
              options={defaultOptions}
              height={isMobile ? 250 : 350}
              width={isMobile ? 210 : 280}
            />
          </div>
        </div>
        <div className="col-md-6 col-12 py-4">
          <Typo text={t(translationCode + 'Desc1')} variant="body2" />
          <div className="mt-5">
            <div className="d-flex">
              <CheckCircleOutlineOutlinedIcon />
              <Typo
                text={t(translationCode + 'Point1')}
                variant="body2"
                className="px-3"
              />
            </div>
            <div className="d-flex mt-4">
              <CheckCircleOutlineOutlinedIcon />
              <Typo
                text={t(translationCode + 'Point2')}
                variant="body2"
                className="px-3"
              />
            </div>
            <div className="d-flex mt-4">
              <CheckCircleOutlineOutlinedIcon />
              <Typo
                text={t(translationCode + 'Point3')}
                variant="body2"
                className="px-3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceCases;
