import React, { useState, useEffect, useCallback, useRef } from 'react';
import Avatar from '../general-components/Avatar';
import { useParams, useNavigate } from 'react-router-dom';
import ErrorBoundary from '../../components/@generalComponents/ErrorBoundary';
import * as drawerActions from '../../redux/actions-v2/drawer-actions';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  List,
  ListItem,
  ListItemText,
  Box,
  ListItemAvatar,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material';
import GeneralText from '../general-components/GeneralText';
import FieldsPopper from '../../components/@generalComponents/FieldsPopper';
import defaultFieldsResolver from '../../utils/defaultFieldsResolver';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';

const DraggableColumn = ({
  column,
  index,
  moveColumn,
  t,
  onClick,
  allowedProperties,
}) => {
  const ref = React.useRef(null);

  const [, drop] = useDrop({
    accept: 'COLUMN',
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveColumn(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'COLUMN',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  // Ensure column.value is defined before checking includes
  const isClickable = allowedProperties?.includes(column?.value);

  return (
    <Box
      ref={ref}
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        opacity: isDragging ? 0.5 : 1,
        marginRight: '12px', // Added space between column text and resizer
        borderRadius: '10px', // Applied to the Box containing text
        flexGrow: 1, // Allow text box to take available space
        overflow: 'hidden', // Hide overflow text
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      <Typography
        variant="body2"
        fontSize="10px"
        color="#696969"
        sx={{
          cursor: isClickable ? 'pointer' : 'default', // Use determined clickability
        }}
        fontWeight={400}
        noWrap // Prevent text wrapping
      >
        {column?.label || t('noLabel')}
      </Typography>
    </Box>
  );
};

const ListLayout = ({ elementDetails, layout, activeIndex, handleRefresh, editMode }) => {
  const { t, i18n } = useTranslation();
  const currentLangCode = i18n.language;
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resizeTimeoutRef = useRef(null);
  const listContainerRef = useRef(null);
  const { elementId, businessId, structureId } = useParams();
  const [list, setList] = useState([]);
  const [fields, setFields] = useState([]);
  const [columns, setColumns] = useState([]);
  const [fieldsSubmenu, setFieldsSubmenu] = useState([]);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [activeColumn, setActiveColumn] = useState(null);
  const [submenuAnchor, setSubmenuAnchor] = useState(null);
  const isDarkMode = theme.palette.mode === 'dark';
  const currentPath = window.location.pathname;

  const structure = elementDetails?.structure;

  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );

  const currentUser = useSelector((state) => state.core.user);

  const businessStructures = businessStructure?.structures || [];
  const currentStructureDetails = businessStructures?.find(
    (struc => struc?.id === layout?.structureId)
  );

  const header = layout?.header?.header || [];

  // Ensure initial widths sum to 100% or handle default case
  const getInitialWidths = useCallback(() => {
    let initialWidths = header?.map((col) => col?.width || 0);
    const totalWidth = initialWidths.reduce((sum, width) => sum + width, 0);

    if (initialWidths.length === 0) return [];

    if (Math.abs(totalWidth - 100) > 0.1 || totalWidth === 0) {
      // If total is not 100 or widths are missing, distribute equally
      const equalWidth = 100 / initialWidths.length;
      initialWidths = initialWidths.map(() => equalWidth);
    } else if (totalWidth > 100) {
      // If total exceeds 100, scale them down proportionally
      const scaleFactor = 100 / totalWidth;
      initialWidths = initialWidths.map(width => width * scaleFactor);
    } else if (totalWidth < 100) {
      // If total is less than 100, distribute the remainder proportionally (or add to last)
      const remainder = 100 - totalWidth;
      // Simple approach: add to the last column
      initialWidths[initialWidths.length - 1] += remainder;
      // // Proportional approach (more complex):
      // initialWidths = initialWidths.map(width => width + (width / totalWidth) * remainder);
    }


    // Ensure final sum is exactly 100 after adjustments
    const finalSum = initialWidths.reduce((sum, width) => sum + width, 0);
    if (Math.abs(finalSum - 100) > 0.01) { // Allow for small floating point errors
      const correction = 100 - finalSum;
      initialWidths[initialWidths.length - 1] += correction; // Adjust last column
    }

    return initialWidths;
  }, [header]);

  const [columnWidths, setColumnWidths] = useState(getInitialWidths());




  useEffect(() => {
    if (currentStructureDetails) {
      const defaultFields = defaultFieldsResolver(currentStructureDetails?.collectionField, t);
      const structureFields = currentStructureDetails?.fields || [];

      // Use a Set to keep track of unique field values
      const uniqueFieldMap = new Map();

      // Add structure fields first
      structureFields.forEach((field) => {
        if (!uniqueFieldMap.has(field.value)) {
          uniqueFieldMap.set(field.value, field);
        }
      });

      // Add default fields only if they are not already present
      defaultFields.forEach((field) => {
        if (!uniqueFieldMap.has(field.value)) {
          uniqueFieldMap.set(field.value, field);
        }
      });

      const fieldToCatch = Array.from(uniqueFieldMap.values());
      setFields(fieldToCatch);

      const filteredSubField = fieldToCatch.filter(
        (field) =>
          field?.allowSub !== false &&
          field?.typeData !== 'status' &&
          field?.typeData !== 'search' &&
          field?.typeData !== 'hook'
      );

      setFieldsSubmenu(filteredSubField);
    }
  }, [currentStructureDetails, t]);


  useEffect(() => {
    if (header?.length > 0) {
      setColumns(header);
      setColumnWidths(getInitialWidths());
    } else {
      setColumns([]);
      setColumnWidths([]);
    }
  }, [header]);


  const container = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemLine = {
    hidden: { x: -50, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
        ease: 'easeOut',
      },
    },
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setActiveColumn(null);
    setSubmenuAnchor(null);
  };

  const handleQuickview = (item, index) => {
    // Ensure item is an array and has elements before accessing
    if (!Array.isArray(item) || item.length === 0) return;

    const firstItemData = item[0];
    if (!firstItemData) return;

    const action = firstItemData.action;
    const primaryData = firstItemData.primaryData || {};

    const elementInfo = {
      id: primaryData.elementId,
      documentPath: primaryData.elementPath,
      name: primaryData.name,
      targetId: primaryData.targetId,
      lastUpdate: primaryData.lastUpdate,
    };

    if (action === 'navigate') {
      navigateItem(item, 'item');
    } else if (action === 'openParent') {
      navigateItem(item, 'parent');
    } else if (action === 'quickview' || action === 'edit') {
      dispatch(
        drawerActions.viewElement({
          isDrawerOpen: true,
          item: elementInfo,
          handleDrawerClose: handleClose,
          handleUpdate: (key, value) =>
            handleUpdateElementDrawer(key, value, index),
          handleDelete: () => handleDeleteElementDrawer(index),
          type: action, // 'quickview' or 'edit'
        })
      );
    } else if (action === 'none') {
      return;
    }
  };

  const handleUpdateListWidth = async (columnWidthsValues) => {
    // Ensure the final values sum exactly to 100% before saving
    let finalWidths = [...columnWidthsValues];
    const currentTotal = finalWidths.reduce((sum, w) => sum + w, 0);
    if (Math.abs(currentTotal - 100) > 0.01 && finalWidths.length > 0) {
      const correction = 100 - currentTotal;
      finalWidths[finalWidths.length - 1] += correction; // Adjust the last column
      // Ensure no column is negative after correction (edge case)
      finalWidths = finalWidths.map(w => Math.max(0, w)); // Prevent negative widths
      // Re-normalize if needed after preventing negative
      const correctedTotal = finalWidths.reduce((sum, w) => sum + w, 0);
      if (Math.abs(correctedTotal - 100) > 0.01 && correctedTotal > 0) {
        const finalCorrection = 100 - correctedTotal;
        finalWidths[finalWidths.length - 1] += finalCorrection;
      }
    }
    // Ensure minimum width constraint is still met after final normalization (rare case)
    const minWidth = 5; // Same as used in resizing
    let adjustmentsNeeded = false;
    finalWidths = finalWidths.map(w => {
      if (w < minWidth) {
        adjustmentsNeeded = true;
        return minWidth;
      }
      return w;
    });

    if (adjustmentsNeeded) {
      // If adjustments were made, re-normalize again to 100%
      let adjustedTotal = finalWidths.reduce((sum, w) => sum + w, 0);
      let excess = adjustedTotal - 100;

      // Reduce from columns > minWidth to compensate
      while (Math.abs(excess) > 0.01) {
        const reducibleCols = finalWidths.map((w, i) => w > minWidth ? i : -1).filter(i => i !== -1);
        if (reducibleCols.length === 0) break; // Cannot reduce further

        const reductionPerCol = excess / reducibleCols.length;
        let appliedExcess = 0;

        reducibleCols.forEach(colIndex => {
          const currentWidth = finalWidths[colIndex];
          const reduceBy = Math.min(reductionPerCol, currentWidth - minWidth); // Don't go below minWidth
          finalWidths[colIndex] -= reduceBy;
          appliedExcess += reduceBy;
        });

        if (appliedExcess < Math.abs(excess) * 0.99) { // Check if significant reduction happened
          break; // Avoid infinite loops if reduction is minimal
        }
        excess -= appliedExcess;
      }
      // Final tiny adjustment on last column if still needed
      const finalTotalCheck = finalWidths.reduce((sum, w) => sum + w, 0);
      if (Math.abs(finalTotalCheck - 100) > 0.01) {
        finalWidths[finalWidths.length - 1] += (100 - finalTotalCheck);
      }
    }

    try {
      // Ensure `columns` state is up-to-date before mapping
      const currentColumns = columns; // Use state directly
      if (!currentColumns || currentColumns.length !== finalWidths.length) {
        console.error("Column definition mismatch during width update.");
        return; // Prevent update if column definition changed unexpectedly
      }

      const updatedColumns = currentColumns.map((col, idx) => ({
        // Use col.value if available, fallback to structureValue if needed (ensure consistency)
        structureValue: col.value || col.structureValue,
        label: col.label, // Include other necessary properties from col if needed
        type: col.type,
        action: col.action,
        sub: col.sub,
        // --- End of standard properties
        width: parseFloat(finalWidths[idx].toFixed(4)), // Save with precision
      }));


      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/structure`,
        body: {
          structureId: structureId,
          type: 'components',
          data: {
            tabIndex: activeIndex || 0,
            blockIdentifiant: layout?.i,
            componentsData: updatedColumns,
            from: 'business',
          },
        },
      });
      handleRefresh(); // Refresh data after successful update
    } catch (error) {
      console.error('Error updating column widths:', error);
    }
  };


  const handleUpdateFields = async (newField, action, subNewField) => {
    try {
      // Get current widths accurately before modification
      const currentWidths = [...columnWidths];
      const currentColumnsDefinition = [...columns]; // Use the state

      if (currentColumnsDefinition.length !== currentWidths.length) {
        console.error("Column/width mismatch before field update.");
        // Option: try to reset widths based on new column count, or abort.
        // For now, let's abort to prevent inconsistent state.
        return;
      }


      const currentColumnsWithWidth = currentColumnsDefinition.map((col, idx) => ({
        ...col,
        width: currentWidths[idx],
        // Ensure structureValue is correctly sourced
        structureValue: col.value || col.structureValue,
      }));

      let updatedColumnsRaw; // Will hold definitions without width initially
      let needsWidthRecalculation = false;

      if (action === 'add') {
        needsWidthRecalculation = true;
        updatedColumnsRaw = [
          ...currentColumnsWithWidth, // Keep existing ones with their current width for now
          {
            // Default width needs to be handled during recalc
            structureValue:
              newField.value === 'targetId'
                ? 'targetName'
                : newField.value === 'targetProfileId'
                  ? 'targetProfileName'
                  : newField.value,
            label: newField.name, // Use the name as label
            type: newField?.typeData,
            action: 'none', // Default action
            // width: 15, // Assign a temporary default or calculate later
          },
        ];
      } else if (action === 'delete') {
        needsWidthRecalculation = true;
        updatedColumnsRaw = currentColumnsWithWidth.filter((col) => col.structureValue !== newField);
      } else if (action === 'add-sub') {
        // This action modifies an existing column, widths don't need full recalc
        updatedColumnsRaw = currentColumnsWithWidth.map((col) => {
          if (col?.structureValue === newField) { // newField here is the target column's value
            return {
              ...col,
              sub: {
                value: subNewField?.value,
                // Potentially add label/type here if needed for display
                // label: subNewField?.name,
                // type: subNewField?.typeData,
              },
            };
          } else {
            return col;
          }
        });
      } else {
        updatedColumnsRaw = [...currentColumnsWithWidth]; // No change if action is unknown
      }


      let finalColumnsWithWidths = updatedColumnsRaw;

      if (needsWidthRecalculation && finalColumnsWithWidths.length > 0) {
        const oldTotalWidth = currentWidths.reduce((s, w) => s + w, 0);
        const newColumnCount = finalColumnsWithWidths.length;
        const defaultNewColWidth = 15;
        let newWidths;

        if (action === 'add') {
          // Reduce existing columns proportionally to make space for the new one
          const spaceNeeded = defaultNewColWidth;
          const totalWidthToReduceFrom = oldTotalWidth;
          const reductionFactor = (totalWidthToReduceFrom - spaceNeeded) / totalWidthToReduceFrom;

          newWidths = currentWidths.map(w => Math.max(5, w * reductionFactor));
          newWidths.push(defaultNewColWidth);
        } else if (action === 'delete') {
          // Find the width of the deleted column and distribute it proportionally
          const deletedColumn = currentColumnsWithWidth.find(col => col.structureValue === newField);
          const widthToDistribute = deletedColumn ? deletedColumn.width : 0;
          const remainingWidths = finalColumnsWithWidths.map((col, idx) => col.width);
          const remainingTotalWidth = remainingWidths.reduce((s, w) => s + w, 0);

          if (remainingTotalWidth > 0 && widthToDistribute > 0) {
            newWidths = remainingWidths.map(w => w + (w / remainingTotalWidth) * widthToDistribute);
          } else if (remainingTotalWidth === 0 && finalColumnsWithWidths.length > 0) {
            // Edge case: all remaining columns had 0 width? Distribute equally.
            const equalWidth = 100 / finalColumnsWithWidths.length;
            newWidths = finalColumnsWithWidths.map(() => equalWidth);
          } else {
            newWidths = remainingWidths;
          }
        }

        // Normalize the calculated newWidths to sum exactly 100%
        let currentTotal = newWidths.reduce((sum, w) => sum + w, 0);
        if (Math.abs(currentTotal - 100) > 0.01) {
          const correction = 100 - currentTotal;
          newWidths[newWidths.length - 1] += correction;
          // Ensure minimum width after normalization
          newWidths = newWidths.map(w => Math.max(5, w));
          // Re-normalize if minimum width adjustment changed the total
          currentTotal = newWidths.reduce((sum, w) => sum + w, 0);
          if (Math.abs(currentTotal - 100) > 0.01) {
            const finalCorrection = 100 - currentTotal;
            newWidths[newWidths.length - 1] += finalCorrection;
          }
        }

        // Assign calculated widths back to the column definitions
        finalColumnsWithWidths = finalColumnsWithWidths.map((col, idx) => ({
          ...col,
          width: parseFloat(newWidths[idx].toFixed(4)),
        }));

      } else if (finalColumnsWithWidths.length === 0) {
        finalColumnsWithWidths = [];
      }


      handleMenuClose();

      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/structure`,
        body: {
          structureId: structureId,
          type: 'components',
          lang: currentLangCode,
          data: {
            from: 'business',
            tabIndex: activeIndex,
            blockIdentifiant: layout?.i,
            componentsData: finalColumnsWithWidths // Send definitions with updated widths
          },
        },
      });
      handleRefresh(); // Refresh to get the latest structure including new columns/widths
    } catch (error) {
      console.error(error);
    }
  }

  const handleUpdateElementDrawer = (key, value, index) => {
    const newList = list?.map((item, i) => {
      if (i === index) {
        const elementIndexToUpdate = item?.findIndex(
          (entry) => entry.structureValue === key
        );

        if (elementIndexToUpdate === -1) return item;

        return item?.map((element, j) => {
          if (j === elementIndexToUpdate) {
            return {
              ...element,
              value:
                element?.typeValue === 'date' ||
                  element?.typeValue === 'date-time'
                  ? { _seconds: value?.unix(), seconds: value?.unix() }
                  : value,
              transformedValue:
                element?.typeValue === 'status'
                  ? element?.transformedValue // Status might have specific transform logic elsewhere
                  : element?.typeValue === 'date' ||
                    element?.typeValue === 'date-time'
                    ? { _seconds: value?.unix(), seconds: value?.unix() } // Store time obj for dates
                    : value, // Use raw value otherwise
            };
          }
          return element;
        });
      }
      return item;
    });

    setList(newList);
    setTimeout(() => {
      handleRefresh()
    }, 800);
  };

  const navigateItem = (item, type) => {
    const primaryData = item?.[0]?.primaryData;
    const dependencyDetails = primaryData?.dependencyDetails;
    const collectionField = item?.[0]?.collectionField;
    const structureIdItem = item?.[0]?.structureId; // Renamed to avoid conflict with hook var

    if (!primaryData || !collectionField || !structureIdItem) {
      console.warn("Missing data for navigation", item);
      return; // Exit if essential data is missing
    }


    if (type === 'item') {
      const targetUrl = currentPath?.startsWith('/doc')
        ? `/doc/${businessId}/${collectionField}/${structureIdItem}/${primaryData.elementId}?accessCode=${primaryData.accessCode || ''}`
        : `/app/element/${collectionField}/${structureIdItem}/${primaryData.elementId}?tab=0`;
      navigate(targetUrl);
    } else if (type === 'parent') {
      if (!dependencyDetails || !dependencyDetails.collectionField || !dependencyDetails.structureIdentifiant || !dependencyDetails.id) {
        console.warn("Missing dependency details for parent navigation", item);
        return; // Exit if dependency data is missing
      }
      navigate(
        `/app/element/${dependencyDetails.collectionField}/${dependencyDetails.structureIdentifiant}/${dependencyDetails.id}?tab=0`
      );
    }
    handleClose();
  };

  const handleDeleteElementDrawer = (index) => {
    const newList = list?.filter((item, i) => i !== index);
    setList(newList);
    handleClose();
  };


  useEffect(() => {
    // Make sure elementDetails and its nested properties exist
    if (elementDetails?.elementData?.id === elementId && elementDetails?.data) {
      let resultData = Array.isArray(elementDetails.data)
        ? elementDetails.data
        : [];

      // Further check: ensure inner elements are arrays if expected by render logic
      // resultData = resultData.map(item => Array.isArray(item) ? item : []); // Uncomment if rows must be arrays

      setList(resultData);
    } else if (elementDetails?.elementData?.id === elementId) {
      // Handle case where data is explicitly null or empty
      setList([]);
    }
  }, [elementDetails?.data, elementDetails?.elementData?.id, elementId]);



  const handleClose = () => {
    dispatch(drawerActions.viewElement({ isDrawerOpen: false }));
  };

  const moveColumn = useCallback(async (fromIndex, toIndex) => {
    // --- Reorder columns definition ---
    const updatedColumns = [...columns];
    const [movedColumn] = updatedColumns.splice(fromIndex, 1);
    updatedColumns.splice(toIndex, 0, movedColumn);
    setColumns(updatedColumns); // Update local state immediately for visual feedback

    // --- Reorder corresponding widths ---
    const updatedWidths = [...columnWidths];
    const [movedWidth] = updatedWidths.splice(fromIndex, 1);
    updatedWidths.splice(toIndex, 0, movedWidth);
    setColumnWidths(updatedWidths); // Update local width state

    // --- Persist the new order and widths ---
    await updateColumnOrderAndWidths(updatedColumns, updatedWidths);

  }, [columns, columnWidths, t, structureId, activeIndex, layout?.i, handleRefresh, currentLangCode]); // Added dependencies


  // Combined function to update order and widths
  const updateColumnOrderAndWidths = async (updatedColumns, updatedWidths) => {
    try {
      // Ensure lengths match before proceeding
      if (updatedColumns.length !== updatedWidths.length) {
        console.error("Mismatch between columns and widths on reorder.");
        // Optional: attempt recovery or revert state changes
        handleRefresh(); // Or revert local state if possible
        return;
      }

      const formattedColumns = updatedColumns.map((col, idx) => ({
        // Ensure structureValue exists, fallback if needed
        structureValue: col.value || col.structureValue,
        label: col.label,
        type: col.type,
        action: col.action,
        sub: col.sub,
        // --- End standard properties
        width: parseFloat(updatedWidths[idx].toFixed(4)), // Use the reordered width, ensure precision
      }));

      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/structure`,
        body: {
          structureId: structureId,
          type: 'components',
          lang: currentLangCode,
          data: {
            from: 'business',
            tabIndex: activeIndex,
            blockIdentifiant: layout?.i,
            componentsData: formattedColumns // Send the reordered columns with their corresponding widths
          },
        },
      });
      handleRefresh(); // Refresh data after successful update
    } catch (error) {
      console.error('Error updating column order and widths:', error);
      // Optional: Consider reverting local state on error
      handleRefresh(); // Trigger refresh to fetch potentially correct server state
    }
  };

  const handleContextMenu = (event, columnValue) => {
    event.preventDefault();
    if (!editMode && currentUser?.activeBusiness?.role !== 'EMPLOYEE' && currentUser?.activeBusiness?.role !== 'VIEWER') {
      if (activeColumn === columnValue) {
        handleMenuClose();
      } else {
        setMenuAnchor(event.currentTarget);
        // Find the full column object to pass context if needed, using .value
        const colDefinition = columns.find(c => c.value === columnValue);
        setActiveColumn(colDefinition); // Store the whole column object if needed later
        // setActiveColumn(columnValue); // Or just the value if that's enough
      }
    }
  };


  const handleMouseDown = (e, index) => {
    e.preventDefault();
    const startX = e.clientX;
    // Make sure columnWidths is accessed correctly
    if (!columnWidths || columnWidths.length <= index) return; // Guard against invalid state

    const startWidths = [...columnWidths]; // Capture initial widths
    const minWidth = 5; // Minimum width percentage for a column
    let finalWidthsToSave = [...startWidths]; // To store the final state for saving

    const handleMouseMove = (event) => {
      const currentX = event.clientX;
      const diffX = currentX - startX;
      // Ensure listContainerRef.current exists before accessing offsetWidth
      const tableWidth = listContainerRef.current?.offsetWidth;
      if (!tableWidth || tableWidth === 0) return; // Avoid division by zero or incorrect calculations

      const diffPercentage = (diffX / tableWidth) * 100;

      let newWidths = [...startWidths]; // Start from original widths for each move event
      let proposedNewWidth = Math.max(minWidth, newWidths[index] + diffPercentage);

      const delta = proposedNewWidth - newWidths[index]; // Change requested for the dragged column

      if (Math.abs(delta) < 0.01) return; // Ignore tiny movements

      // Calculate total width available to be taken from other columns
      let availableReduction = 0;
      for (let i = 0; i < newWidths.length; i++) {
        if (i !== index) {
          availableReduction += Math.max(0, newWidths[i] - minWidth);
        }
      }

      let actualDelta = delta;

      if (delta > 0) { // Increasing width
        if (delta > availableReduction) {
          // Cannot increase as much as requested, cap the increase
          actualDelta = availableReduction;
          proposedNewWidth = newWidths[index] + actualDelta;
        }
      }
      // No capping needed for decreasing width (delta < 0)

      // Apply the actual change to the dragged column
      newWidths[index] = proposedNewWidth;

      // Distribute the counter-change (-actualDelta) to other columns
      const changeToDistribute = -actualDelta;
      let distributedChange = 0;

      if (Math.abs(changeToDistribute) > 0.01) {
        if (changeToDistribute > 0) { // Distributing added space (when decreasing dragged column)
          const totalOriginalWidthOthers = startWidths.reduce((sum, w, i) => sum + (i !== index ? w : 0), 0);
          if (totalOriginalWidthOthers > 0) {
            for (let i = 0; i < newWidths.length; i++) {
              if (i !== index) {
                const proportion = startWidths[i] / totalOriginalWidthOthers;
                const share = changeToDistribute * proportion;
                newWidths[i] += share;
                distributedChange += share;
              }
            }
          } else {
            // If other columns had zero width, distribute equally
            const otherColsCount = newWidths.length - 1;
            if (otherColsCount > 0) {
              const share = changeToDistribute / otherColsCount;
              for (let i = 0; i < newWidths.length; i++) {
                if (i !== index) {
                  newWidths[i] += share;
                  distributedChange += share;
                }
              }
            }
          }

        } else { // Distributing removed space (when increasing dragged column)
          const totalReducibleWidth = availableReduction; // Use already calculated value
          if (totalReducibleWidth > 0) {
            for (let i = 0; i < newWidths.length; i++) {
              if (i !== index) {
                const reducibleAmount = Math.max(0, startWidths[i] - minWidth);
                const proportion = reducibleAmount / totalReducibleWidth;
                const share = changeToDistribute * proportion; // changeToDistribute is negative
                newWidths[i] = Math.max(minWidth, newWidths[i] + share); // Apply change but ensure minWidth
                distributedChange += share; // Track the actual distributed change
              }
            }
          }
          // If totalReducibleWidth is 0, no change can be distributed. actualDelta was already capped.
        }
      }


      // --- Normalization Step ---
      let currentTotal = newWidths.reduce((sum, w) => sum + w, 0);
      let deviation = 100 - currentTotal;

      // Apply correction, usually to the dragged column or the last one, avoiding going below minWidth
      if (Math.abs(deviation) > 0.01) { // Only correct if deviation is significant
        const targetIndexForCorrection = index; // Or newWidths.length - 1;
        const correctedWidth = newWidths[targetIndexForCorrection] + deviation;

        if (correctedWidth >= minWidth) {
          newWidths[targetIndexForCorrection] = correctedWidth;
        } else {
          // If correcting the target makes it too small, distribute correction elsewhere
          newWidths[targetIndexForCorrection] = minWidth; // Set target to min
          let remainingDeviation = deviation - (minWidth - newWidths[targetIndexForCorrection]); // How much deviation is left

          // Try distributing remaining deviation to other columns > minWidth
          let distributedCorrection = 0;
          const distributableCols = newWidths.map((w, i) => (i !== targetIndexForCorrection && w > minWidth) ? i : -1).filter(i => i !== -1);
          if (distributableCols.length > 0) {
            const correctionPerCol = remainingDeviation / distributableCols.length;
            distributableCols.forEach(colIdx => {
              const colCorrectedWidth = newWidths[colIdx] + correctionPerCol;
              if (colCorrectedWidth >= minWidth) {
                newWidths[colIdx] = colCorrectedWidth;
                distributedCorrection += correctionPerCol;
              } else {
                // If even correction makes it too small, cap at minWidth
                const appliedCorrection = minWidth - newWidths[colIdx];
                newWidths[colIdx] = minWidth;
                distributedCorrection += appliedCorrection;
              }
            });
            remainingDeviation -= distributedCorrection;
          }
          // If deviation still remains (all other cols hit minimum), it can't be fully corrected.
          // This usually means the initial request was impossible within constraints.
          // The current state is the best possible approximation.
        }

        // Final check on total sum after correction attempts
        currentTotal = newWidths.reduce((sum, w) => sum + w, 0);
        deviation = 100 - currentTotal;
        if (Math.abs(deviation) > 0.01) {
          // As a last resort, apply tiny remaining deviation to the last column
          newWidths[newWidths.length - 1] += deviation;
          newWidths[newWidths.length - 1] = Math.max(minWidth, newWidths[newWidths.length - 1]); // Ensure minWidth
          // Might need one last re-normalization pass if this caused issues, but often minor enough
        }
      }


      // Ensure no width is negative (safety check)
      newWidths = newWidths.map(w => Math.max(0, w));


      setColumnWidths(newWidths);
      finalWidthsToSave = newWidths; // Store the latest valid state for saving on mouse up
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);

      if (resizeTimeoutRef.current) clearTimeout(resizeTimeoutRef.current);

      resizeTimeoutRef.current = setTimeout(() => {
        // Use the state captured just before mouse up for saving
        handleUpdateListWidth(finalWidthsToSave);
      }, 600); // Debounce API call
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };


  function resolveComponent() {
    // Added safety checks for list and columnWidths
    if (!columns || columns.length === 0 || !list || list.length === 0 || !columnWidths || columnWidths.length === 0 || columns.length !== columnWidths.length) {
      // Optionally render a placeholder or loading state or message
      // return <Typography sx={{ p: 2 }}>No data or columns configured correctly.</Typography>;
      return null; // Or return null/empty fragment if nothing should show
    }

    return (
      <motion.ul
        variants={container}
        initial="hidden"
        animate="visible"
        style={{ listStyleType: 'none', padding: 0, margin: 0 }}
      >
        <List dense sx={{
          width: '100%',
          alignItems: 'center', m: 0, p: 0
        }}>
          {list?.map((itemElement, index) => {
            // Ensure itemElement is an array before mapping over it
            if (!Array.isArray(itemElement)) {
              console.warn(`List item at index ${index} is not an array:`, itemElement);
              return null; // Skip rendering this row or render an error indicator
            }

            return (
              <motion.li
                key={`${layout?.i}-${index}`} // Use a more stable key if possible
                style={{ margin: 0, padding: 0, width: '100%' }}
                variants={itemLine}
              >
                <ListItem
                  sx={{
                    overflow: 'hidden',
                    cursor: 'pointer',
                    height: '46px',
                    m: 0,
                    p: 0,
                    display: 'flex', // Use flex for row layout
                    justifyContent: 'flex-start',
                    borderBottom:
                      index !== list?.length - 1
                        ? isDarkMode
                          ? '0.3px solid #69696990'
                          : '0.3px solid #00000040'
                        : '',
                  }}
                  button='true'
                  className="middle-content"
                  dense
                  alignItems="flex-start" // Align items at the start of the cross axis
                  key={index} // React prefers key on the iterator element
                >
                  {itemElement?.map((col, idx) => {
                    // Crucial check: Ensure columnWidths has an entry for this index
                    if (idx >= columnWidths.length) {
                      console.warn(`Mismatch: More data columns (${idx + 1}) than defined header widths (${columnWidths.length}) for row ${index}.`);
                      return null; // Skip rendering this cell
                    }

                    return (
                      <Box
                        sx={{
                          width: `${columnWidths[idx]}%`,
                          minWidth: `${columnWidths[idx]}%`, // Prevent shrinking below calculated width
                          flexBasis: `${columnWidths[idx]}%`, // Flex basis is often better for flex items
                          flexGrow: 0, // Do not allow growing
                          flexShrink: 0, // Do not allow shrinking
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          padding: '4px 8px', // Add some horizontal padding
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          boxSizing: 'border-box',
                          // Add right border for visual separation, except for the last item
                          borderRight: idx < itemElement.length - 1 ? '1px solid #eee' : 'none', // Lighter border for content
                        }}
                        onClick={() => handleQuickview(itemElement, index)} // Apply quickview to whole cell click for simplicity
                        key={`${col?.structureValue || idx}-${layout?.i}-${index}`} // More specific key
                      >


                        {(col?.typeValue === 'text' ||
                          col?.typeValue === 'string' ||
                          col?.typeValue === 'number' ||
                          col?.typeValue === 'dropdown' ||
                          col?.typeValue === 'money' ||
                          col?.typeValue === 'node' ||
                          col?.typeValue === 'tags' ||
                          col?.typeValue === 'date' ||
                          col?.typeValue === 'date-time' ||
                          col?.typeValue === 'status' ||
                          col?.typeValue === 'selection' ||
                          col?.typeValue === 'boolean' ||
                          col?.typeValue === 'assignedTo') && (
                            <ListItemText

                              primary={
                                <GeneralText
                                  layout={layout}
                                  primary={col?.valueColor === 'primary'}
                                  size={idx === 0 ? 'bold' : col?.weight || 'medium'}
                                  fontSize="11px"
                                  lineHeight={'1.2em'} // Adjusted line height
                                  structureId={col?.structureId}
                                  text={col?.transformedValue ?? '-'}
                                  keyStructure={col?.structureValue}
                                  type={col?.typeValue || 'string'}
                                  classNameComponent="clickable" // Consider removing if click handled by Box/ListItem
                                  color={col?.valueColor}
                                  sx={{ display: 'block', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }} // Ensure text itself handles overflow
                                />
                              }
                              secondary={
                                col?.sub?.value && ( // Check if sub value exists before rendering
                                  <div style={{ marginTop: '0px', lineHeight: '1em' }}>
                                    <GeneralText
                                      // primary={true} // Secondary text usually isn't primary style
                                      size="regular"
                                      fontSize="10px"
                                      classNameComponent="clickable grey-text" // Consider click handling
                                      structureId={structure?.id} // Use element's structure or col's?
                                      text={col.sub?.transformedValue ?? ''}
                                      keyStructure={col?.sub?.structureValue}
                                      type={col.sub?.typeValue ?? 'text'}
                                      sx={{ display: 'block', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    />
                                  </div>
                                )
                              }
                              sx={{ my: 0, p: 0 }} // Remove default padding/margin from ListItemText
                            />
                          )}

                        {(col?.typeValue === 'avatar' ||
                          col?.typeValue === 'media-single') && (
                            <ListItemAvatar
                              // Removed onClick if Box handles it
                              sx={{ minWidth: 'auto', marginRight: '8px', my: 0, p: 0 }} // Adjust spacing
                            >
                              <Avatar
                                img={col?.value ?? ''}
                                name={col?.value ?? ''} // Use transformedValue or specific name field if available
                                alt={col?.value ?? ''}
                                sx={{
                                  width: '32px', // Consistent size
                                  height: '32px',
                                  borderRadius: '6px !important',
                                  // padding: '4px', // Padding might not be needed if Avatar component handles it
                                }}
                              />
                            </ListItemAvatar>
                          )}
                        {/* Handle other col.typeValues if necessary */}
                      </Box>
                    );
                  })}
                </ListItem>
              </motion.li>
            );
          })}
        </List>
      </motion.ul>
    );
  }


  return (
    <ErrorBoundary>
      <DndProvider backend={HTML5Backend}>
        {/* Add ref to the main container */}
        <div ref={listContainerRef} className="list-container" style={{ width: '100%', overflowX: 'hidden', marginTop: '-10px' }}>

          {/* Header Row */}
          <List
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'stretch', // Stretch items to fill height
              height: '30px', // Increased height for better drag target
              borderBottom: isDarkMode
                ? '0.3px solid #69696970'
                : '0.3px solid #00000030',
              p: 0, // Remove padding from List itself
              boxSizing: 'border-box',
              position: 'relative',
            }}
          >
            {/* Check if columns and widths are valid before mapping */}
            {columns?.length > 0 && columns.length === columnWidths?.length && columns?.map((col, idx) => {
              // Defensive check for width value
              const widthPercent = columnWidths[idx] || (100 / columns.length); // Fallback width

              return (
                <Box
                  key={`${col.value || idx}-header`} // Use value if available, otherwise index
                  sx={{
                    // Use width and flex properties for sizing
                    width: `${widthPercent}%`,
                    minWidth: `${widthPercent}%`,
                    flexBasis: `${widthPercent}%`,
                    flexGrow: 0,
                    flexShrink: 0, // Prevent shrinking below basis
                    display: 'flex',
                    alignItems: 'center', // Vertically center content
                    justifyContent: 'space-between', // Space between content and resizer
                    position: 'relative',
                    overflow: 'hidden', // Hide overflow
                    // height: '100%', // Take full height of parent List
                    boxSizing: 'border-box',
                    // Border on the right of the Box, not the resizer div
                    borderRight: idx < columns.length - 1 ? '1px solid #ddd' : 'none',
                  }}
                >
                  {/* Draggable Column Text */}
                  <DraggableColumn
                    column={col}
                    index={idx}
                    t={t}
                    moveColumn={moveColumn}
                    // Context menu on the text area itself
                    onClick={(event) => handleContextMenu(event, col?.value)}
                    allowedProperties={[]} // Pass allowed properties if needed for styling/cursor
                  />

                  {/* Resizer Handle - Placed within the Box, absolutely positioned to the right */}
                  {/* Render resizer only if it's not the last column */}
                  {idx < columns.length - 1 && (
                    <div
                      onMouseDown={(e) => handleMouseDown(e, idx)}
                      style={{
                        position: 'absolute',
                        right: -2, // Position slightly overlapping the border for easier grabbing
                        top: 0,
                        bottom: 0,
                        width: '5px', // Make handle wider
                        cursor: 'col-resize',
                        backgroundColor: 'transparent', // Initially transparent
                        zIndex: 1, // Ensure it's above content
                      }}
                      // Visual feedback on hover
                      onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#ccc')}
                      onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                    />
                  )}
                </Box>
              );
            })}
            {/* Popper and Add Field Button Container */}
            <Box sx={{ position: 'absolute', right: 2, top: 8, zIndex: 1000 }}>
              {!editMode && currentUser?.activeBusiness?.role !== 'EMPLOYEE' && currentUser?.activeBusiness?.role !== 'VIEWER' && (
                <FieldsPopper
                  fields={fields || []}
                  onSelect={(fieldsData) => handleUpdateFields(fieldsData, 'add')}
                  existingFields={columns.map(c => c.value || c.structureValue)}
                />
              )}
            </Box>
          </List>

          {/* Context Menu for Columns */}
          {Boolean(menuAnchor) && activeColumn && ( // Ensure activeColumn is set
            <Menu
              elevation={2}
              open={Boolean(menuAnchor)}
              onClose={handleMenuClose}
              anchorEl={menuAnchor}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              sx={{
                boxShadow: '5px 5px 15px 0px rgba(0,0,0,0.15)',
                p: 0,
                mt: 1, // Margin top for spacing
              }}
            >
              {/* Check if column type allows adding sub-fields */}
              {fieldsSubmenu.length > 0 && !activeColumn.sub && ( // Only show if no sub yet and sub-fields exist
                <MenuItem
                  onClick={(event) => {
                    // Keep main menu open, open submenu
                    setSubmenuAnchor(event.currentTarget);
                  }}
                >
                  {t('addSub')}
                </MenuItem>
              )}
              {/* Option to remove sub-field if it exists */}
              {activeColumn.sub && (
                <MenuItem onClick={() => handleUpdateFields(activeColumn.value, 'remove-sub')}>
                  {t('removeSub')} {/* Add translation for 'removeSub' */}
                </MenuItem>
              )}

              <MenuItem
                onClick={() => handleUpdateFields(activeColumn.value, 'delete')} // Pass column value
              >
                {t('delete')}
              </MenuItem>
            </Menu>
          )}

          {/* Submenu for adding sub-fields */}
          {Boolean(submenuAnchor) && activeColumn && (
            <Menu
              elevation={2}
              open={Boolean(submenuAnchor)}
              onClose={() => setSubmenuAnchor(null)} // Close only submenu
              anchorEl={submenuAnchor}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position relative to "Add Sub" item
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              sx={{ boxShadow: '5px 5px 15px 0px rgba(0,0,0,0.15)', p: 0, ml: 1 }} // Margin left
            >
              {fieldsSubmenu?.length > 0 ? (
                fieldsSubmenu.map((fieldSub) => (
                  <MenuItem
                    key={fieldSub.value}
                    onClick={() =>
                      handleUpdateFields(activeColumn.value, 'add-sub', fieldSub) // Pass parent column value and sub-field data
                    }
                  >
                    {fieldSub?.name || fieldSub.value}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>{t('noSubFieldsAvailable')}</MenuItem> /* Add translation */
              )}
            </Menu>
          )}


          {/* Render the actual list content */}
          {resolveComponent()}
        </div>
      </DndProvider>
    </ErrorBoundary>
  );
};

export default ListLayout;