import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import OnboardLayout from './OnboardLayout';
import { TextField, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { setOnboardData } from '../../redux/actions-v2/coreAction';


const Step7 = () => {
    const dispatch = useDispatch();
    const { t, } = useTranslation();
    const pageCode = 'step7Onboard';
    const [formattedModules, setFormattedModules] = useState([])


    const onboardData = useSelector((state) => state.core.onboardData);
    const modules = onboardData?.collectionsToCreate || []

    useEffect(() => {
        const formattedModules = modules?.map((module) => {
            return { collectionField: module, name: t(module) }
        })
        const collectionsFormatted = onboardData?.collectionsFormatted || []
        if (collectionsFormatted?.length === 0) {
            setFormattedModules(formattedModules)
            dispatch(setOnboardData({ ...onboardData, collectionsFormatted: formattedModules }))
        } else {
            setFormattedModules(collectionsFormatted)
        }

    }, [modules])

    return (

        <OnboardLayout stepTitle={t(pageCode + 'Title')} stepDesc={t(pageCode + 'Desc')} restrictNext={false}>

            <div className='mt-3 mb-3'>
                {formattedModules?.filter(
                    (module) => module?.collectionField !== 'contacts'
                )?.map((module, index) => (
                    <Accordion elevation={0} key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{module?.name || `Module ${index + 1}`}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {/* <Typography variant='caption'>{t(module?.collectionField + 'DescOnboard') || `Module ${index + 1}`}</Typography> */}
                            <div className='mt-4'>
                                <TextField
                                    label={t('moduleName')}
                                    variant="outlined"
                                    fullWidth
                                    value={module?.name}
                                    onChange={(e) => {
                                        const updatedModules = [...formattedModules]
                                        updatedModules[index] = { ...updatedModules[index], name: e.target.value }
                                        setFormattedModules(updatedModules)
                                        dispatch(setOnboardData({ ...onboardData, collectionsFormatted: updatedModules }))
                                    }}
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>


        </OnboardLayout>

    );
};

export default Step7;
