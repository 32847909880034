import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Box,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Avatar,
} from '@mui/material';
import GeneralText from '../../../stories/general-components/GeneralText';
import Chip from '../../../stories/general-components/Chip';
import { BoltOutlined } from '@mui/icons-material';
import { toast } from 'react-toastify';

const ListAItem = ({
  columnWidths,
  activeModule,
  element,
  customizations,
  handleDisplaySide,
  isDeleted,
  displaySide,
  handleClick,
}) => {
  const { t } = useTranslation();
  const theme = useTheme()
  const { structureId } = useParams();
  const businessPreference = useSelector((state) => state.core.businessData);
  const mainColor = businessPreference?.mainColor || '#000000';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setMenuAnchor({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
  };
  const handleMenuClose = () => setMenuAnchor(null);

  const getStatusColor = (count) => chroma.mix('grey', mainColor, count / 10).hex();
  const columns = displaySide?.structureId ? element?.values.slice(0, 4) : element?.values;

  return (
    <>
      <ListItem
        dense
        disableRipple
        divider
        button='true'
        onContextMenu={handleContextMenu}
        sx={{
          display: 'flex',
          flexWrap: isMobile ? 'wrap' : 'nowrap',
          alignItems: 'center',
          cursor: 'pointer',
          height: isMobile ? 'auto' : '48px',
          p: '4px',
          filter: isDeleted ? 'blur(3px)' : 'none',
          opacity: isDeleted ? 0.5 : 1,
        }}
      >
        {columns?.map((col, idx) => (
          <Box
            key={idx}
            sx={{
              width: isMobile ? '100%' : `${columnWidths?.[idx]}%`,
              padding: isMobile ? '0px' : '4px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: isMobile ? 'normal' : 'nowrap',
            }}
          >
            {(col?.typeValue === 'text' ||
              col?.typeValue === 'string' ||
              col?.typeValue === 'money' ||
              col?.typeValue === 'number' ||
              col?.typeValue === 'date' ||
              col?.typeValue === 'date-time' ||
              col?.typeValue === 'tags' ||
              col?.typeValue === 'geo' ||
              col?.typeValue === 'status' ||
              col?.typeValue === 'dropdown' ||
              col?.typeValue === 'selection' ||
              col?.typeValue === 'slider' ||
              col?.typeValue === 'boolean' ||
              col?.typeValue === 'assignedTo' ||
              col?.typeValue === 'assignedToId') && (
                <>
                  {col?.fieldType === 'chip' ? (
                    <div style={{ paddingRight: '40px' }}>
                      <Chip
                        color={col?.valueColor || 'primary'}
                        label={col?.transformedValue ?? col?.value ?? ''}
                        size="small"
                        fontWeight={600}
                        sx={{
                          fontWeight: 600,
                          width: '100%',
                        }}
                        onClick={() => handleClick(element)}
                      />
                    </div>
                  ) : (
                    <ListItemText
                      primary={
                        <GeneralText
                          primary={
                            element?.isActive === false
                              ? false
                              : col?.valueColor === 'primary'
                                ? true
                                : false
                          }
                          size={idx === 0 ? 'bold' : col?.weight || 'regular'}
                          fontSize="11px"
                          keyStructure={col?.structureValue}
                          maxCharacters={54}
                          lineHeight={'1em'}
                          fromMain
                          color={
                            element?.isActive === false
                              ? '#a8a8a8'
                              : col?.valueColor ?? 'primary'
                          }
                          restrictContent
                          text={col?.transformedValue ?? col?.value ?? ''}
                          type={col?.typeValue || 'string'}
                        />
                      }
                      secondary={
                        <>
                          {col?.sub?.value !== undefined &&
                            col?.sub?.value !== null && (
                              <GeneralText
                                primary={
                                  col?.sub?.valueColor === 'primary'
                                    ? true
                                    : false
                                }
                                size="regular"
                                fontSize="10px"
                                keyStructure={col?.sub?.structureValue}
                                structureId={col}
                                key={col?.structureValue}
                                maxCharacters={100}
                                color={col?.sub?.valueColor ?? 'primary'}
                                text={
                                  typeof (
                                    col?.sub?.transformedValue ??
                                    col?.sub?.value ??
                                    ''
                                  ) === 'string'
                                    ? String(
                                      col?.sub?.transformedValue ||
                                      col?.sub?.value ||
                                      '-'
                                    ).substring(0, 150)
                                    : col?.sub?.transformedValue ||
                                    col?.sub?.value ||
                                    '-'
                                }
                                type={col?.sub?.typeValue ?? 'text'}
                              />
                            )}
                        </>
                      }
                      onClick={() => handleClick(element)}
                    />
                  )}
                </>
              )}
            {col?.typeValue === 'custom:statuses' && (
              <div className="d-flex-3d">
                {[0, 1, 2, 3].map((status) => {
                  const statusCount = col?.value?.[`status${status}`] ?? 0;
                  return (
                    <div
                      key={status}
                      style={{
                        backgroundColor: getStatusColor(statusCount),
                        width: '25px',
                      }}
                      className="status-square"
                    >
                      {statusCount}
                    </div>
                  );
                })}
              </div>
            )}

            {(col?.typeValue === 'avatar' ||
              col?.typeValue === 'media' ||
              col?.typeValue === 'media-single') && (
                <ListItemAvatar>
                  <Avatar
                    onClick={() => handleClick(element)}
                    img={
                      col?.value ||
                      `https://storage.googleapis.com/business-logos-node/${element?.businessId}.png` ||
                      ''
                    }
                    name={element?.name ?? ''}
                    alt={col?.value ?? ''}
                    sx={{
                      maxWidth: `${'36px !important'}`,
                      maxHeight: `${'36px !important'}`,
                      borderRadius: '6px !important',
                      padding: '3px',
                    }}
                  />
                </ListItemAvatar>
              )}
          </Box>
        ))}
        {customizations?.displayQuickView && !displaySide && (
          <Box sx={{ position: 'absolute', right: 10 }}>
            <IconButton onClick={() => handleDisplaySide(element)}>
              <BoltOutlined sx={{ fontSize: isMobile ? '16px' : '20px', '&:hover': { color: mainColor } }} />
            </IconButton>
          </Box>
        )}
      </ListItem>
      <Menu
        open={menuAnchor !== null}
        onClose={handleMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={menuAnchor ? { top: menuAnchor.mouseY, left: menuAnchor.mouseX } : undefined}
        MenuProps={{ PaperProps: { sx: { maxHeight: 200, borderRadius: '12px' } } }}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            navigator.clipboard.writeText(element?.id).then(() => toast.info(t('copied')));
          }}
        >
          {t('copyId')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            navigator.clipboard.writeText(element?.name).then(() => toast.info(t('copied')));
          }}
        >
          {t('copyName')}
        </MenuItem>
        <MenuItem onClick={() => { handleMenuClose(); handleDisplaySide(element); }}>{t('quickView')}</MenuItem>
        <MenuItem onClick={() => { handleMenuClose(); window.open(`/app/element/${activeModule?.collectionField}/${structureId}/${element?.id}`, '_blank'); }}>{t('openNewTab')}</MenuItem>
        <MenuItem onClick={() => { handleMenuClose(); handleClick(element, false, true, false); }}>{t('editElement')}</MenuItem>
        <MenuItem onClick={() => { handleMenuClose(); handleClick(element, false, false, true); }}>{t('deleteElement')}</MenuItem>
      </Menu>
    </>
  );
};

export default ListAItem;
