import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import SiteLayout from '../SiteLayout';
import Typo from '../components/Typo';
import { useTranslation } from 'react-i18next';
import { Box, Button, TextField } from '@mui/material';
import RandomDotsBackground from './RandomDotsBackground.jsx';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase.js';
import { toast } from 'react-toastify';

const HomePulse = () => {
  const { t, } = useTranslation();
  const theme = useTheme();
  const isMobile = window.innerWidth <= 768;

  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');

  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const varColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const darkColor = chroma(mainColor).darken(2).hex();
  const pageCode = 'homePulse';

  const [formData, setFormData] = useState({ page: 'pulse' });

  const handleFieldChange = (e, fieldName) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [fieldName]: value });
  };

  const handleSendForm = async () => {
    try {
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `https://hook.us1.make.com/z0wn6qfhf6jihsbq82e2jgl2at763gxg`,
        body: formData,
      });
    } catch (error) {
      console.error('Error', error);
    }
    setFormData({ page: 'pulse', email: '' });
    toast.success(t('sent'));
  };

  return (
    <SiteLayout
      helmetData={{
        name: t(pageCode + 'SEO'),
        description: t(pageCode + 'SEODesc'),
      }}
    >
      <Box
        sx={{
          borderRadius: '20px',
          position: 'relative',
          backgroundColor: darkColor,
          height: '90vh',
          margin: isMobile ? '7vh 2vh' : '7vh',
          overflow: 'hidden',
        }}
      >
        <RandomDotsBackground />

        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            pt: '8vh',
            textAlign: 'center',
            px: 4,
          }}
        >
          <img
            src="/assets/website/v3/header_top_features 1.png"
            alt="Pulse"
            width={300}
            style={{ marginBottom: '50px' }}
          />
          <Typo
            text={t('pulseSoon')}
            variant={'h1'}
            color={'white'}
            className="align-c"
          />
          <Typo
            text={t('pulseSoonText')}
            variant={'subTitle'}
            color={'white'}
            className={'align-c'}
            sx={{ padding: '30px' }}
          />
          <Typo
            text={t('joinWaitList')}
            variant={'h4'}
            color={'white'}
            className="mt-2"
          />
          <Box sx={{ maxWidth: '500px', mx: 'auto', padding: '14px' }}>
            <TextField
              label={t('email')}
              variant="outlined"
              className="mt-4"
              key={'email'}
              value={formData?.email}
              onChange={(e) => handleFieldChange(e, 'email')}
              InputProps={{
                style: {
                  color: 'white',
                },
              }}
              InputLabelProps={{
                style: {
                  color: 'white',
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  backdropFilter: 'blur(10px)',
                  borderRadius: '10px',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.3)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.8)',
                  },
                },
                '& input': {
                  color: 'white',
                  '&::placeholder': {
                    color: 'white',
                    opacity: 1,
                  },
                },
              }}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            className={'mt-4'}
            size="large"
            disableElevation
            onClick={handleSendForm}
            sx={{
              borderRadius: '20px',
              padding: '8px 20px',
              textTransform: 'none',
              backgroundColor: mainColor,
              color: 'white',
              '&:hover': {
                backgroundColor: varColor,
              },
            }}
          >
            {t('submit')}
          </Button>
        </Box>
      </Box>
    </SiteLayout>
  );
};

export default HomePulse;
