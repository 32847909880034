import React, { } from 'react';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import Typo from '../Typo';
import EcosystemSVG from '../dynamicSVG/EcosystemSVG';

const Ecosystem = ({ translationCode }) => {
  const { t, } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const localStorageColor = localStorage.getItem('mainColor');

  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';


  const lightMainColor = chroma(mainColor + '90')
    .brighten(2.2)
    .hex();

  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  return (
    <div className="container align-c">
      <div className={isMobile ? 'row mt-5 px-4' : 'row mt-5'}>
        <div className="col-md-6 col-12 align-left py-5">
          <Typo
            text={t(translationCode + 'Title')}
            variant="h2"
            className="mt-4"
            sx={{ maxWidth: '600px' }}
          />
          <Typo
            text={t(translationCode + 'Sub')}
            variant="subTitle"
            className="mt-3"
            sx={{ maxWidth: '490px' }}
          />
          {/* <Button
            variant="contained"
            color="primary"
            disableElevation
            style={{
              marginTop: '40px',
              textTransform: 'none',
              borderRadius: '50px',
              padding: '8px 20px',
              backgroundColor: darkMainColor,
            }}
          >
            {t(translationCode + 'CTA')}
          </Button> */}
        </div>
        <div className="col-md-6 col-12 align-c">
          <EcosystemSVG
            mainColor={mainColor}
            lightMainColor={lightMainColor}
            darkMainColor={darkMainColor}
          />
        </div>
      </div>
    </div>
  );
};

export default Ecosystem;
