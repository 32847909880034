import React, { } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';
import {
  Button,
} from '@mui/material';
import Typo from '../Typo';
import {
  SearchOutlined,
  AutoAwesomeOutlined,
  ColorLensOutlined,
  AccountTreeOutlined,
  MobileFriendlyOutlined,
  VerifiedUserOutlined,
  CodeOutlined,
  AssessmentOutlined,
} from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ModulesLayout = ({ translationCode }) => {
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';
  const localStorageColor = localStorage.getItem('mainColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';


  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  const features = [
    {
      icon: <SearchOutlined color={isDarkMode ? 'white' : 'black'} />,
      title: 'F1',
    },
    {
      icon: <ColorLensOutlined color={isDarkMode ? 'white' : 'black'} />,
      title: 'F2',
    },
    {
      icon: <AccountTreeOutlined color={isDarkMode ? 'white' : 'black'} />,
      title: 'F3',
    },
    {
      icon: <AutoAwesomeOutlined color={isDarkMode ? 'white' : 'black'} />,
      title: 'F4',
    },
    {
      icon: <MobileFriendlyOutlined color={isDarkMode ? 'white' : 'black'} />,
      title: 'F5',
    },
    {
      icon: <VerifiedUserOutlined color={isDarkMode ? 'white' : 'black'} />,
      title: 'F6',
    },
    {
      icon: <CodeOutlined color={isDarkMode ? 'white' : 'black'} />,
      title: 'F7',
    },
    {
      icon: <AssessmentOutlined color={isDarkMode ? 'white' : 'black'} />,
      title: 'F8',
    },
  ];

  const navigateOnboard = () => {
    navigate('/onboard');
  };

  return (
    <div style={{ marginTop: '150px' }} className="container">
      <div className={isMobile ? 'px-4' : ''}>
        <Typo
          text={t(translationCode + 'Title')}
          variant="h2"
          className="mt-5 align-c"
        />
        <div className="row mt-5 px-5">
          <div className="col-md-6 col-12">
            <div className="row">
              {features?.map((feature, index) => (
                <div key={index} className="col-md-3 col-6 align-c mt-3">
                  <div
                    style={{
                      backgroundColor: isDarkMode ? '#f7f7f740' : '#f7f7f790',
                      padding: '10px',
                      borderRadius: '20px',
                    }}
                  >
                    {feature?.icon}
                    <div className="mt-1">
                      <Typo
                        text={t(translationCode + feature.title)}
                        variant="p-small"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-6 col-12 mt-4">
            <Typo
              text={t(translationCode + 'Desc1')}
              variant="body2"
              className="align-left"
              sx={{ maxWidth: '450px' }}
            />
            <Typo
              text={t(translationCode + 'Desc2')}
              variant="body2"
              className="align-left mt-4"
              sx={{ maxWidth: '450px' }}
            />
          </div>
        </div>
      </div>
      <div className="mt-5 align-c">
        <img
          src="/assets/website/v3/computer-modules.png"
          height={isMobile ? 280 : 400}
          style={{ marginTop: '60px' }}
          alt="Modules"
        />
        <div>
          <Button
            variant="contained"
            disableElevation
            onClick={navigateOnboard}
            sx={{
              backgroundColor: darkMainColor,
              borderRadius: '21px',
              textTransform: 'none',
              fontWeight: 'bold',
            }}
          >
            {t(translationCode + 'CTA')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModulesLayout;
