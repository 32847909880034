import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import OnboardLayout from './OnboardLayout';
import { TextField, InputAdornment, IconButton, Button, FormControlLabel, Checkbox } from '@mui/material';
import { auth, db, signIn } from '../../firebase';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import GeneralText from '../../stories/general-components/GeneralText';


const Step8 = () => {

    const { t, } = useTranslation();
    const navigate = useNavigate()
    const pageCode = 'step8Onboard';
    const [password, setPassword] = useState('');

    const [passwordError, setPasswordError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [registerError, setRegisterError] = useState('');
    const [terms, setTerms] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    const onboardData = useSelector((state) => state.core.onboardData);

    const signInUser = async (email, password) => {
        try {
            await signIn(auth, email, password);
            localStorage.removeItem('businessId');
            sessionStorage.removeItem('businessToken');
            navigate('/select-business');
        } catch (error) {
            console.error('Error signing in: ', error?.code);
            setErrorMessage(t(error?.code));
        }
    };

    const handleRegister = async () => {
        setIsLoading(true);
        setRegisterError('');
        try {
            const docRef = doc(db, 'onboard', onboardData?.id);
            const data = {
                ...onboardData,
                onboardCompleted: true
            };

            try {
                await setDoc(docRef, data, { merge: true });
            } catch (error) {
                console.error('Error updating document:', error);
            }
            if (!onboardData?.userId) {
                try {
                    if (onboardData?.email && password) {
                        try {
                            const response = await nodeAxiosFirebase({
                                t,
                                method: 'POST',
                                url: 'users/register',
                                noAuth: true,
                                body: {
                                    email: onboardData?.email,
                                    firstName: '',
                                    password: password,
                                    lastName: '',
                                    tokenInvite: onboardData?.token,
                                },
                            });

                            if (response?.message === 'User created') {
                                signInUser(onboardData?.email, password);

                            }
                            setIsLoading(false);
                        } catch (error) {
                            setIsLoading(false);
                            console.error(error);
                        }
                    }
                } catch (error) {
                    setIsLoading(false);
                }
            } else if (onboardData?.userId) {
                signInUser(onboardData?.email, password);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const passwordCriteria =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (password && !passwordCriteria.test(password)) {
            setPasswordError(t('passwordMsg'));

        } else {
            setPasswordError('');
        }

    }, [password]);


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };



    return (

        <OnboardLayout stepTitle={onboardData?.userId ? t('userIdFound') : t(pageCode + 'Title')} stepDesc={onboardData?.userId ? t('userIdFoundDesc') : t(pageCode + 'Desc')} restrictNext={false}>

            <div className='mt-3 mb-3'>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={terms}
                            onChange={(e) => setTerms(e.target.checked)}
                        />
                    }
                    label={
                        <>
                            {t('termsAndConditions')}{' '}
                            <a
                                href="/informations/mentions-legales"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'underline' }}
                            >
                                {t('readHere')}
                            </a>
                        </>
                    }
                />
                <TextField
                    error={!onboardData?.userId && !!passwordError}
                    helperText={!onboardData?.userId && passwordError}
                    id="outlined-basic"
                    placeholder={t('password')}
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    sx={{ marginTop: '10px' }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={toggleShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div>
                {isLoading && <GeneralText variant='caption' text={t('loading')} />}
                {registerError && <GeneralText variant='caption' text={registerError} />}
                {errorMessage && <GeneralText variant='caption' text={errorMessage} />}
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disableElevation
                    sx={{
                        textTransform: 'none',
                        marginTop: '20px',
                        backgroundColor: onboardData?.mainColor,
                        borderRadius: '10px',
                        border: 'none',
                        width: '100%',
                    }}
                    onClick={handleRegister}
                    disabled={!password || passwordError || isLoading || !terms}
                >
                    {onboardData?.userId ? t('signin') : t('accessAccount')}
                </Button>
            </div>
        </OnboardLayout>

    );
};

export default Step8;
