import React, { } from 'react';


const Screens = ({ translationCode }) => {




  return (
    <div
      style={{
        marginTop: '200px',
      }}
      className="container align-c"
    >
      <img src="/assets/website/v3/screens.svg" alt='Screens' />
    </div>
  );
};

export default Screens;
