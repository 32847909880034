import React, { useState, useEffect, useMemo } from 'react';
import chroma from 'chroma-js';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { AppBar, Button, Divider, Modal, Toolbar, useMediaQuery } from '@mui/material';
import { Box, Grid } from '@mui/material';
import GeneralText from '../../stories/general-components/GeneralText';
import animationData from '../../lotties/inbox.json';
import animationDataSuccess from '../../lotties/success.json';
import Lottie from 'react-lottie';
import { doc, onSnapshot, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { setOnboardData } from '../../redux/actions-v2/coreAction';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowForwardIos, CheckBoxOutlined, TranslateOutlined } from '@mui/icons-material';
import ComponentsCases from '../components/dynamicSVG/ComponentsCases';

const OnboardLayout = ({ children, stepTitle, stepDesc, restrictNext = false }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const pageCode = 'onboard';
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const localStorageColor = localStorage.getItem('mainColor') || '#000000';
    const [searchParams, setSearchParams] = useSearchParams();
    const lightMainColor = chroma(localStorageColor).brighten(1.2).hex();
    const darkMainColor = chroma(localStorageColor).darken(1.2).hex();

    const onboardData = useSelector((state) => state.core.onboardData);
    const currentPath = window.location.pathname;
    const lastCaracter = currentPath[currentPath.length - 1];
    const currentStep = parseInt(lastCaracter);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenCompleted, setModalOpenCompleted] = useState(false);
    const [playAnimation, setPlayAnimation] = useState(false);

    const handleOpen = () => setModalOpen(true);

    const handleOpenCompleted = () => setModalOpenCompleted(true);


    const onboardId = searchParams.get('onboardId')
    const plan = searchParams.get('plan')
    const navigateToHome = () => {
        navigate('/');
    }

    const navigateToLogin = () => {
        navigate('/signin');
    }


    const steps = [
        {
            title: t(pageCode + 'BusinessName'),
            description: '',
            section: 'business',
        },
        {
            title: t(pageCode + 'PrevSoftwares'),
            description: '',
            section: 'business',
        },
        {
            title: t(pageCode + 'MoreAboutBusiness1'),
            description: '',
            section: 'business',
        },
        {
            title: t(pageCode + 'MoreAboutBusiness2'),
            description: '',
            section: 'business',
        },
        {
            title: t(pageCode + 'YourselfHelp'),
            description: '',
            section: 'business',
        },
        {
            title: t(pageCode + 'WhyNode'),
            description: '',
            section: 'modules',
        },
        {
            title: t(pageCode + 'ConfigModules'),
            description: '',
            section: 'modules',
        },
        {
            title: t(pageCode + 'ConfigStructures'),
            description: '',
            section: 'structures',
        },
        {
            title: t(pageCode + 'ConfigFirstLayout'),
            description: '',
            section: 'structures',
        },
    ]

    const stepPercentage = useMemo(() => ((currentStep + 1) * 100) / steps.length, [currentStep, steps.length]);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: false,
        },
    };

    const defaultOptionsSuccess = {
        loop: false,
        autoplay: true,
        animationData: animationDataSuccess,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: false,
        },
    };


    useEffect(() => {
        if (onboardId) {
            const docRef = doc(db, 'onboard', onboardId);

            const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const dataOnboard = docSnapshot.data();
                    dispatch(setOnboardData(dataOnboard));
                    navigate('/onboard/step' + (dataOnboard?.currentStep) + '?onboardId=' + onboardId + '&plan=' + dataOnboard?.plan);
                } else {
                    console.error('No such document!');
                }
            }, (error) => {
                console.error('Error getting document:', error);
            });

            return () => unsubscribe();
        }
    }, [onboardId]);

    const handleNext = async () => {
        const res = await axios.get('https://api.ipify.org/?format=json');
        const ip = res.data.ip;
        if (currentStep + 1 === 2 && !playAnimation) {
            setPlayAnimation(true)
            return;
        }

        const docRef = doc(db, 'onboard', onboardId);
        const data = {
            ...onboardData,
            ip: ip || '',
            agent: navigator?.userAgent || '',
            currentStep: currentStep + 1,
            plan: plan || 'trial'
        };

        try {
            await setDoc(docRef, data, { merge: true });


        } catch (error) {
            console.error('Error updating document:', error);
        }
    }


    const handlePrev = async () => {
        const docRef = doc(db, 'onboard', onboardId);
        const data = {
            ...onboardData,
            currentStep: currentStep === 1 ? currentStep : currentStep - 1
        };

        try {
            await setDoc(docRef, data, { merge: true });
        } catch (error) {
            console.error('Error updating document:', error);
        }
    }

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };

    useEffect(() => {
        if (!onboardId) {
            handleOpen();
        }
    }, [currentStep])

    useEffect(() => {
        if (onboardData?.onboardCompleted) {
            handleOpenCompleted();
        }
    }, [onboardData])


    return (
        <Grid container sx={{ minHeight: '100vh' }}>
            <Modal
                open={modalOpen}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className='align-left' style={{ position: 'absolute', backgroundColor: '#FFFFFF', padding: '40px', borderRadius: '10px', width: '780px', left: '50%', transform: 'translate(-50%, -50%)', top: '50%' }}>
                    <div style={{ height: '80px', width: '80px' }}>
                        <Lottie
                            options={defaultOptions}
                            height={60}
                            width={60}
                        />
                    </div>
                    <h2 className='mt-4' style={{ fontSize: '18px' }} id="simple-modal-title">{t('lookEmail')}</h2>
                    <p id="simple-modal-description">
                        {t('lookEmailDesc')}
                    </p>
                </div>
            </Modal>
            <Modal
                open={modalOpenCompleted}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className='align-left' style={{ position: 'absolute', backgroundColor: '#FFFFFF', padding: '40px', borderRadius: '10px', width: '780px', left: '50%', transform: 'translate(-50%, -50%)', top: '50%' }}>
                    <div style={{ height: '80px', width: '80px' }}>
                        <Lottie
                            options={defaultOptionsSuccess}
                            height={60}
                            width={60}
                        />
                    </div>
                    <h2 className='mt-4' style={{ fontSize: '18px' }} id="simple-modal-title">{t('completedTitle')}</h2>
                    <p id="simple-modal-description">
                        {t('completedDesc')}
                    </p>
                    <Button variant="contained" color="primary" disableElevation sx={{ textTransform: 'none', marginTop: '20px' }} onClick={navigateToLogin}>
                        {t('signin')} <ArrowForwardIos sx={{ fontSize: '12px', ml: '5px' }} />
                    </Button>
                </div>
            </Modal>
            <AppBar elevation={0} position="fixed" sx={{ backgroundColor: isDarkMode ? '#000' : '#fff', borderBottom: '1px solid #e3e3e3', m: 0, p: 0, filter: modalOpen ? 'blur(5px)' : 'none' }}>
                <Toolbar sx={{ backgroundColor: isDarkMode ? '#000' : '#fff', m: 0, p: 0, position: 'relative' }}>

                    <div className='row'>
                        <div onClick={navigateToHome} className='col-2 hover'><img
                            src="/assets/website/softwares/node-logo.png"
                            height={14}
                            alt="Node"
                        /></div>
                        <div className='col-3' id='business'>
                            <div className='d-flex middle-content'>
                                {onboardData?.currentStep > 3 && (
                                    <CheckBoxOutlined sx={{ mr: 2 }} htmlColor='#000000' />
                                )}
                                <GeneralText
                                    text={t(pageCode + 'businessDetails')}
                                    primary={true}
                                    fontSize={'12px'}
                                    size='medium'
                                    classNameComponent='mt-1'
                                /></div>

                        </div>
                        <div className='col-3 ' id='modules'>
                            <div className='d-flex middle-content'>
                                {onboardData?.currentStep > 6 && (
                                    <CheckBoxOutlined sx={{ mr: 2 }} htmlColor='#000000' />
                                )}
                                <GeneralText
                                    text={t(pageCode + 'modulesConfig')}
                                    primary={true}
                                    fontSize={'12px'}
                                    size='medium'
                                    classNameComponent='mt-1'
                                />
                            </div>
                        </div>
                        <div className='col-2' id='structures'>
                            <div className='d-flex middle-content'>
                                {onboardData?.currentStep > 8 && (
                                    <CheckBoxOutlined sx={{ mr: 2 }} htmlColor='#000000' />
                                )}
                                <GeneralText
                                    text={t(pageCode + 'structuresConfig')}
                                    primary={true}
                                    fontSize={'12px'}
                                    size='medium'
                                    classNameComponent='mt-1'
                                />
                            </div>
                        </div>
                        <div className='col-1 align-right hover' onClick={() =>
                            changeLanguage(i18n.language === 'en' ? 'fr' : 'en')
                        }>
                            <TranslateOutlined htmlColor='#000000' />
                        </div>
                    </div>
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            width: '100%',
                        }}
                    >
                        <Box sx={{ overflow: 'hidden', height: '6px', borderRadius: '50px' }}>
                            <motion.div
                                key={'progress'}
                                animate={{ width: stepPercentage + '%' }}
                                transition={{ duration: 0.5, ease: 'easeInOut' }}
                                style={{
                                    width: stepPercentage - 10 + '%',
                                    height: '6px',
                                    backgroundColor: lightMainColor + '20',
                                    borderRadius: '50px',
                                }}
                            />
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <motion.div
                animate={{ width: playAnimation ? '100%' : currentStep === 1 ? '0%' : '28.6%' }}
                transition={{ duration: currentStep === 1 && !playAnimation ? 0 : 0.8, ease: 'easeInOut' }}
                style={{ display: 'flex', filter: modalOpen ? 'blur(5px)' : 'none' }}
            >
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        backgroundColor: localStorageColor,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        padding: 0,
                        width: '100%',
                    }}
                >
                    <Box style={{ overflow: 'hidden' }}>
                        {onboardData?.logo && (
                            <div style={{ paddingLeft: playAnimation ? '' : '10%' }}>
                                <div style={{ borderRadius: '50%', backgroundColor: 'white', width: '45px', height: '45px' }}>
                                    <img src={onboardData?.logo} alt='logo' width={'40px'} style={{ borderRadius: '50%', marginLeft: '2.5px', marginTop: '2.5px' }} />
                                </div>
                            </div>
                        )}
                        <div style={{ paddingLeft: playAnimation ? '' : '10%' }} className='d-flex middle-content'>
                            <GeneralText
                                text={playAnimation ? t(pageCode + 'Start') : t(pageCode + 'Welcome')}
                                primary={false}
                                color='#FFFFFF'
                                fontSize={'42px'}
                                size='bold'
                                classNameComponent={onboardData?.logo ? 'mt-3' : 'mt-5'}
                                style={{ maxWidth: playAnimation ? '100%' : '260px' }}
                            />
                            {playAnimation && (
                                <GeneralText
                                    text={onboardData?.businessName}
                                    primary={false}
                                    color='#FFFFFF'
                                    fontSize={'42px'}
                                    size='bold'
                                    classNameComponent={onboardData?.logo ? 'mt-3' : 'mt-5'}
                                />)}

                        </div>
                        <GeneralText
                            text={playAnimation ? t(pageCode + 'StartDesc') : t(pageCode + 'WelcomeDesc')}
                            primary={false}
                            color='#FFFFFF'
                            fontSize={'18px'}
                            size='medium'
                            classNameComponent='mt-3'
                            style={{ paddingLeft: playAnimation ? '' : '10%', }}
                        />
                        {!playAnimation && (
                            <div style={{ paddingLeft: '10%', }}>

                                <GeneralText
                                    text={t('businessName') + ': ' + onboardData?.businessName}
                                    primary={false}
                                    color='#FFFFFF'
                                    fontSize={'12px'}
                                    size='medium'
                                    classNameComponent='mt-5'
                                />
                                <GeneralText
                                    text={t('businessId') + ': #' + onboardData?.id}
                                    primary={false}
                                    color='#FFFFFF'
                                    fontSize={'11px'}
                                    size='regular'
                                    classNameComponent='mt-2'
                                />
                                <GeneralText
                                    text={t('ownerEmail') + ': ' + onboardData?.email}
                                    primary={false}
                                    color='#FFFFFF'
                                    fontSize={'11px'}
                                    size='regular'
                                    classNameComponent='mt-2'
                                />
                                <GeneralText
                                    text={t('BOSplan') + ': ' + (onboardData?.plan || 'trial')}
                                    primary={false}
                                    color='#FFFFFF'
                                    fontSize={'11px'}
                                    size='regular'
                                    classNameComponent='mt-2'
                                />
                            </div>)}
                        {!playAnimation && currentStep !== 1 && (
                            <div className='align-right' style={{ width: '550px', marginTop: '18%', marginLeft: '7px', }}>
                                <ComponentsCases
                                    mainColor={localStorageColor}
                                    secColor={onboardData?.secColor || '#000000'}
                                    darkMainColor={darkMainColor}
                                    lightMainColor={lightMainColor}
                                    isMobile={true}

                                />
                            </div>)}

                        {playAnimation && (
                            <Button variant="contained" color="white" disableElevation sx={{ textTransform: 'none', marginTop: '40px' }} onClick={handleNext}>
                                {t('letsGo')} <ArrowForwardIos sx={{ fontSize: '12px', ml: '5px' }} />
                            </Button>)}
                    </Box>
                </Grid>
            </motion.div >
            {!playAnimation && (
                <Grid
                    item
                    xs={12}
                    md={playAnimation ? 0 : currentStep === 1 ? 12 : 8}
                    sx={{
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 4,
                        filter: modalOpen ? 'blur(5px)' : 'none'
                    }}
                >
                    <Box sx={{ width: '100%', maxWidth: '800px', minWidth: '600px' }}>
                        {stepTitle && (
                            <div>
                                <GeneralText
                                    text={stepTitle}
                                    primary={true}
                                    fontSize={'24px'}
                                    size='bold'
                                />
                                <GeneralText
                                    text={stepDesc}
                                    primary={true}
                                    fontSize={'12px'}
                                    size='regluar'
                                    classNameComponent='greyText'
                                />
                            </div>)}
                        {children}
                        <div className='mt-5 mb-3'>
                            <Divider component='div' sx={{ borderColor: '#69696950' }} />
                        </div>
                        <div className='row'>

                            <div className='col-2'>
                                {currentStep !== 1 && currentStep !== steps?.length - 1 && (
                                    <Button
                                        variant="text"
                                        color="primary"
                                        disableElevation
                                        sx={{ textTransform: 'none', color: '#696969' }}
                                        onClick={handlePrev}
                                        disabled={currentStep === 0}
                                    >
                                        {t('back')}
                                    </Button>
                                )}
                            </div>
                            <div className='col-8'></div>
                            <div className='col-2 align-right'>
                                {currentStep !== steps?.length - 1 && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        sx={{ textTransform: 'none', backgroundColor: localStorageColor, color: 'white', borderRadius: '20px' }}
                                        onClick={handleNext}
                                        disabled={currentStep === steps.length - 1 || restrictNext}
                                    >
                                        {currentStep === 1 ? t('start') : t('next')}<ArrowForwardIos sx={{ fontSize: '12px', ml: '5px' }} />
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Box>
                </Grid>)}
        </Grid >
    );
};

export default OnboardLayout;