import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as sidebarActions from '../../redux/actions/sidebar-actions';
import MainLayoutV2 from '../../layouts/MainLayoutV2';
import Blocks from '../../stories/layout-components/Block';
import { Box, ListItem } from '@mui/material';
import GeneralText from '../../stories/general-components/GeneralText';
import { AccountBalanceOutlined, AccountBoxOutlined, AddOutlined, CreateOutlined, DeleteOutlined, LoopOutlined, Print, PrintOutlined } from '@mui/icons-material';

const OverviewFlows = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );

  const structures = businessStructure?.structures;

  const handleSettingsStructure = (structureId) => {
    if (structureId) {
      dispatch(
        sidebarActions.structureSidebar({
          show: true,
          structureId: structureId,
          closeSidebar: handleCloseStructureSidebar,
          isDrawerOpen: true,
        })
      );
    }
  };

  const handleCloseStructureSidebar = () => {
    dispatch(
      sidebarActions.structureSidebar({
        show: false,
        structureId: null,
        closeSidebar: null,
        isDrawerOpen: false,
      })
    );
  };

  return (
    <MainLayoutV2
      icon="AccountTreeOutlined"
      pageTitle="Structures"
      subTitle={t('yourAccountOverview')}
    >
      <Blocks heightPercentage={98} height={1} noScroll>
        <ListItem sx={{ borderBottom: '1px solid #ccc' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '30%' }}>
            <GeneralText text={t('name')} size="medium" fontSize="12px" primary={true} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '15%' }}>
            <GeneralText text={t('collectionField')} size="medium" fontSize="12px" primary={true} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '12%' }}>
            <GeneralText text={t('fields')} size="medium" fontSize="12px" primary={true} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '20%', gap: 1 }}>
            <GeneralText text={t('actions')} size="medium" fontSize="12px" primary={true} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '10%', gap: 1 }}>
            <GeneralText text={t('attributes')} size="medium" fontSize="12px" primary={true} />
          </Box>
        </ListItem>

        {structures?.map((structure) => (
          <ListItem button='true' divider key={structure?.id} onClick={() => handleSettingsStructure(structure?.id)}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '30%' }}>
              <GeneralText
                text={structure?.name}
                size="medium"
                fontSize="13px"
                primary={true}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '15%' }}>
              <GeneralText
                text={t(structure?.collectionField)}
                size="regular"
                fontSize="12px"
                primary={false}
                color='#69696999'
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '12%' }}>
              <GeneralText
                text={structure?.fields?.length + ' ' + t('fields')}
                size="regular"
                fontSize="12px"
                primary={false}
                color='#69696999'
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '20%', gap: 1 }}>
              <div style={{ width: '30px' }}>
                {structure?.element?.preferences?.print && (
                  <PrintOutlined sx={{ cursor: 'pointer', fontSize: '14px', color: '#69696999' }} />
                )}
              </div>
              <div style={{ width: '30px' }}>
                {structure?.element?.preferences?.creation && (
                  <AddOutlined sx={{ cursor: 'pointer', fontSize: '14px', color: '#69696999' }} />
                )}
              </div>
              <div style={{ width: '30px' }}>
                {structure?.element?.preferences?.edit && (
                  <CreateOutlined sx={{ cursor: 'pointer', fontSize: '14px', color: '#69696999' }} />
                )}
              </div>
              <div style={{ width: '30px' }}>
                {structure?.element?.preferences?.delete && (
                  <DeleteOutlined sx={{ cursor: 'pointer', fontSize: '14px', color: '#69696999' }} />
                )}
              </div>
              <div style={{ width: '30px' }}>
                {structure?.element?.preferences?.convert && (
                  <LoopOutlined sx={{ cursor: 'pointer', fontSize: '14px', color: '#69696999' }} />
                )}
              </div>
              <div style={{ width: '30px' }}>
                {structure?.element?.isPublic && (
                  <AccountBoxOutlined sx={{ cursor: 'pointer', fontSize: '14px', color: '#69696999' }} />
                )}
              </div>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '10%', gap: 1 }}>
              <div style={{ width: '20px' }}>

                <GeneralText
                  text={'A1'}
                  size="regular"
                  fontSize="12px"
                  primary={false}
                  color={structure?.attribute1 ? '#69696999' : '#c27272'}
                />

              </div>
              <div style={{ width: '20px' }}>
                <GeneralText
                  text={'A2'}
                  size="regular"
                  fontSize="12px"
                  primary={false}
                  color={structure?.attribute2 ? '#69696999' : '#c27272'}
                />
              </div>
              <div style={{ width: '20px' }}>
                <GeneralText
                  text={'A3'}
                  size="regular"
                  fontSize="12px"
                  primary={false}
                  color={structure?.attribute3 ? '#69696999' : '#c27272'}
                />
              </div>
              <div style={{ width: '20px' }}>
                <GeneralText
                  text={'A4'}
                  size="regular"
                  fontSize="12px"
                  primary={false}
                  color={structure?.attribute4 ? '#69696999' : '#c27272'}
                />
              </div>
            </Box>
          </ListItem>))}

      </Blocks>
    </MainLayoutV2>
  );
};

export default OverviewFlows;