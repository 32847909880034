import React, { } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Button, useMediaQuery } from '@mui/material';
import Typo from '../Typo';
import { CalendarMonth, CheckBoxOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Tiles = ({ translationCode }) => {
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const localStorageColor = localStorage.getItem('mainColor');

  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';


  const handleContact = () => {
    navigate('/contact');
  };

  return (
    <div
      style={{
        marginTop: '70px',
      }}
      className="container"
    >
      <div className={isMobile ? 'row px-4' : 'row'}>
        <div className="col-md-3 col-12"></div>
        <div className="col-md-4 col-12">
          <Typo text={t(translationCode + 'Title')} variant="subTitle" />
        </div>
        <div className="col-md-5 col-12">
          <Typo text={t(translationCode + 'Desc')} variant="body2" />
        </div>
      </div>
      <div
        style={{ marginTop: '100px', display: 'flex', alignItems: 'flex-end' }}
        className={isMobile ? 'row px-4' : 'row'}
      >
        <div className="col-md-3 col-12 mt-2">
          <img
            src="/assets/website/v3/locale-roam.png"
            alt="Entrepreneur Store"
            width="100%"
            style={{ borderRadius: '30px' }}
          />
        </div>
        <div className="col-md-3 col-12 mt-2">
          <div
            style={{
              backgroundColor: '#696969',
              padding: '20px',
              height: '370px',
              width: '100%',
              borderRadius: '30px',
            }}
          >
            <CalendarMonth
              color="white"
              sx={{ fontSize: '30px', marginTop: '30px' }}
            />
            <div>
              <Typo
                text={t(translationCode + 'Tile2')}
                variant="h3"
                className="mt-3"
                color="#FFFFFF"
              />
              <Typo
                text={t(translationCode + 'TileDesc2')}
                variant="body2"
                className="mt-4"
                color="#FFFFFF"
              />
            </div>
          </div>
        </div>
        <div className="col-md-3 col-12 mt-2">
          <img
            src="/assets/website/v3/city-roam.png"
            width="100%"
            style={{ borderRadius: '30px' }}
          />
        </div>
        <div className="col-md-3 col-12 mt-2">
          <div
            style={{
              backgroundColor: '#F7f7f7',
              padding: '20px',
              height: '420px',
              width: '100%',
              borderRadius: '30px',
            }}
          >
            <div className="row mt-4">
              <div className="col-md-1 col-12">
                <CheckBoxOutlined />
              </div>
              <div className="col-md-11 col-12">
                <Typo
                  text={t(translationCode + 'Tile3T1')}
                  variant="subTitle"
                />
                <Typo
                  text={t(translationCode + 'Tile3DescT1')}
                  variant="body2"
                  className="mt-2"
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-1 col-12">
                <CheckBoxOutlined />
              </div>
              <div className="col-md-11 col-12">
                <Typo
                  text={t(translationCode + 'Tile3T2')}
                  variant="subTitle"
                />
                <Typo
                  text={t(translationCode + 'Tile3DescT2')}
                  variant="body2"
                  className="mt-2"
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-1 col-12">
                <CheckBoxOutlined />
              </div>
              <div className="col-md-11 col-12">
                <Typo
                  text={t(translationCode + 'Tile3T3')}
                  variant="subTitle"
                />
                <Typo
                  text={t(translationCode + 'Tile3DescT3')}
                  variant="body2"
                  className="mt-2"
                />
              </div>
            </div>
            <div className="mt-3 align-c">
              <Button
                variant="contained"
                disableElevation
                onClick={handleContact}
                style={{
                  backgroundColor: mainColor,
                  color: '#FFFFFF',
                  textTransform: 'none',
                  borderRadius: '20px',
                  marginTop: '30px',
                }}
              >
                {t(translationCode + 'Tile3Button')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tiles;
