import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Typo from '../Typo';
import NodeBOS3D from './NodeBOS3D';
import NodeAIPerfo from '../dynamicSVG/NodeAIPerfo';
import ActiveSearchPerfo from '../dynamicSVG/ActiveSearchPerfo';
import VisualBuilderPerfo from '../dynamicSVG/VisualBuilderPerfo';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';

const Perfo = ({ translationCode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');

  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const varColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: isMobile ? '164vh' : '88vh',
        bgcolor: '#000000',
        marginTop: '-95px',
        paddingLeft: isMobile ? '15px' : '',
      }}
    >
      <div className="container py-5">
        <div style={{ minHeight: '400px' }} className="row mt-5 py-4">
          <div className="col-md-4 col-12 mt-5">
            <Typo
              variant="h1"
              text={t(translationCode + 'Top1')}
              color={'white'}
              className={'mt-5'}
              sx={{ maxWidth: '300px' }}
            />

            <Typo
              variant="h4"
              text={t(translationCode + 'TopDesc')}
              color={'white'}
              className={'mt-5'}
            />
          </div>
          <div className={'col-md-8 col-12 align-c'}>
            <NodeBOS3D
              mainColor={mainColor}
              secColor={varColor}
              isMobile={isMobile}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4 col-12 px-4 mt-5">
            <Box>
              <div className="row">
                <div className="col-3">
                  <div
                    style={{
                      marginTop: '0px',
                      border: '3px solid #FFFFFF',
                      borderRadius: '100px',
                      width: '62px',
                      height: '62px',
                    }}
                  >
                    <NodeAIPerfo mainColor={mainColor} />
                  </div>
                </div>
                <div className="col-9">
                  <Typography
                    variant="h3"
                    sx={{
                      fontFamily: 'Montserrat, Helvetica',
                      fontWeight: 'bold',
                      fontSize: '1.5rem',
                      lineHeight: '2rem',
                      color: '#FFFFFF',
                    }}
                  >
                    {t(translationCode + 'AI')}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: 'Montserrat, Helvetica',
                      fontWeight: '500',
                      fontSize: '1.25rem',
                      lineHeight: '2rem',
                      color: '#FFFFFF',
                    }}
                  >
                    {t(translationCode + 'AISub')}
                  </Typography>
                </div>
              </div>
            </Box>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Montserrat, Helvetica',
                fontWeight: '500',
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                color: '#FFFFFF',
                marginTop: '20px',
              }}
            >
              {t(translationCode + 'AIDesc')}
            </Typography>
          </div>
          <div className="col-md-4 col-12 px-4 mt-5">
            {' '}
            <Box>
              <div className="row">
                <div className="col-3">
                  <div
                    style={{
                      marginTop: '0px',
                      border: '3px solid #FFFFFF',
                      borderRadius: '100px',
                      width: '62px',
                      height: '62px',
                    }}
                  >
                    <VisualBuilderPerfo mainColor={mainColor} />
                  </div>
                </div>
                <div className="col-9">
                  <Typography
                    variant="h3"
                    sx={{
                      fontFamily: 'Montserrat, Helvetica',
                      fontWeight: 'bold',
                      fontSize: '1.5rem',
                      lineHeight: '2rem',
                      color: '#FFFFFF',
                    }}
                  >
                    {t(translationCode + 'VB')}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: 'Montserrat, Helvetica',
                      fontWeight: '500',
                      fontSize: '1.25rem',
                      lineHeight: '2rem',
                      color: '#FFFFFF',
                    }}
                  >
                    {t(translationCode + 'VBSub')}
                  </Typography>
                </div>
              </div>
            </Box>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Montserrat, Helvetica',
                fontWeight: '500',
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                color: '#FFFFFF',
                marginTop: '20px',
              }}
            >
              {t(translationCode + 'VBDesc')}
            </Typography>
          </div>
          <div className="col-md-4 col-12 px-4 mt-5">
            {' '}
            <Box>
              <div className="row">
                <div className="col-3">
                  <div
                    style={{
                      marginTop: '0px',
                      border: '3px solid #FFFFFF',
                      borderRadius: '100px',
                      width: '62px',
                      height: '62px',
                    }}
                  >
                    <ActiveSearchPerfo mainColor={mainColor} />
                  </div>
                </div>
                <div className="col-9">
                  <Typography
                    variant="h3"
                    sx={{
                      fontFamily: 'Montserrat, Helvetica',
                      fontWeight: 'bold',
                      fontSize: '1.5rem',
                      lineHeight: '2rem',
                      color: '#FFFFFF',
                    }}
                  >
                    {t(translationCode + 'AS')}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: 'Montserrat, Helvetica',
                      fontWeight: '500',
                      fontSize: '1.25rem',
                      lineHeight: '2rem',
                      color: '#FFFFFF',
                    }}
                  >
                    {t(translationCode + 'ASSub')}
                  </Typography>
                </div>
              </div>
            </Box>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Montserrat, Helvetica',
                fontWeight: '500',
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                color: '#FFFFFF',
                marginTop: '20px',
              }}
            >
              {t(translationCode + 'ASDesc')}
            </Typography>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Perfo;
