// Libraries
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import { Box, ListItem } from '@mui/material'; // Import Box for wrapper
import {
  PreviewOutlined,
  ForwardToInboxOutlined,
  CheckCircleOutlineOutlined,
  MessageOutlined,
  EngineeringOutlined,
  PhoneAndroidOutlined,
  TaskAlt,
  ChatBubbleOutline,
} from '@mui/icons-material';

// Components
import GeneralText from '../../stories/general-components/GeneralText'; // Adjusted path assuming stories structure
import moment from 'moment';

const HistoryMessage = ({
  // Renamed 'text' prop to 'text' to match previous correction and intent
  text,
  isLastMessage,
  timestamp,
  userId,
  type,
  description, // This seems redundant if text or label is used? Keep for now.
  name,
  badge, // Unused in current rendering
  others, // Unused in current rendering
  otherUser, // Used for styling current user message
  size,
}) => {
  const { t, i18n } = useTranslation();
  const currentLangCode = i18n.language; // Unused in current rendering
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const isMobile = window.innerWidth <= 768;
  const [isClicked, setIsClicked] = useState(false);

  const currentUser = useSelector((state) => state.core.user);
  const businessPreference = useSelector((state) => state.core.businessData);

  const secColor = businessPreference?.secColor || '#000000';
  // Corrected chroma scale usage - need 2 colors for scale, then pick one.
  // Or generate a lighter version directly. Let's lighten directly.
  const lightGreyScale = chroma('#696969').brighten(2.5).hex();

  // Determine if the message is from the current user
  const isCurrentUser = userId === currentUser?.uid;

  const getIconOfLog = (type) => {
    // Using || 'note' as a fallback if type is undefined/null
    const effectiveType = type || 'note';

    switch (effectiveType) {
      case 'cards:confirmed':
      case 'card:action:confirmed': // Combined cases
        return {
          icon: (
            <CheckCircleOutlineOutlined
              sx={{ mr: '4px' }}
              fontSize="inherit"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('statusConfimed'),
          status: 99,
          type: t('action'),
        };
      case 'cards:email':
      case 'card:email':
      case 'grids:email': // Combined cases
        return {
          icon: (
            <ForwardToInboxOutlined
              sx={{ mr: '4px' }}
              fontSize="inherit"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('emailSent'),
          status: 99,
          type: t('action'),
        };
      case 'delivered:email':
        return {
          icon: (
            <ForwardToInboxOutlined
              sx={{ mr: '4px' }}
              fontSize="inherit"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('emailDelivered'),
          status: 6,
          type: t('email'),
        };
      case 'card:view':
      case 'cards:view': // Combined cases
        return {
          icon: (
            <PreviewOutlined
              sx={{ mr: '4px' }}
              fontSize="inherit"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('cardOpened'),
          status: 1,
          type: t('public'),
        };
      case 'grids:view':
        return {
          icon: (
            <PreviewOutlined
              sx={{ mr: '4px' }}
              fontSize="inherit"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('gridOpened'),
          type: t('public'),
          status: 1,
        };
      case 'cards:message':
        return {
          icon: (
            <MessageOutlined
              sx={{ mr: '4px' }}
              fontSize="inherit"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('message'),
          status: 0,
          type: t('manual'),
        };
      case 'cards:messagePublic':
        return {
          icon: (
            <MessageOutlined
              sx={{ mr: '4px' }}
              fontSize="inherit"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('messageContact'),
          status: 1,
          type: t('public'),
        };
      case 'opened:email':
        return {
          icon: (
            <MessageOutlined
              sx={{ mr: '4px' }}
              fontSize="inherit"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('openedEmail'),
          status: 6,
          type: t('email'),
        };
      case 'clicked:email':
        return {
          icon: (
            <MessageOutlined
              sx={{ mr: '4px' }}
              fontSize="inherit"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('clickedEmail'),
          status: 6,
          type: t('email'),
        };
      case 'profile:maintenance':
        return {
          icon: (
            <EngineeringOutlined
              sx={{ mr: '4px' }}
              fontSize="inherit"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: '', // No default label?
          status: 0,
          type: t('manual'),
        };
      case 'action:call':
        return {
          icon: (
            <PhoneAndroidOutlined
              sx={{ mr: '4px' }}
              fontSize="inherit"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('call'),
          status: 99,
          type: t('action'),
        };
      case 'nodies:created':
        return {
          icon: (
            <TaskAlt
              sx={{ mr: '4px' }}
              fontSize="inherit"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('newTask'),
          status: 99,
          type: t('action'),
        };
      case 'note': // Explicitly handle 'note' type
      default: // Default case includes undefined/null 'type'
        return {
          icon: (
            <ChatBubbleOutline
              sx={{ mr: '4px' }}
              fontSize="inherit"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('note'),
          type: t('manual'),
          status: 0,
        };
    }
  };

  const logInfo = getIconOfLog(type); // Get log info once
  const contentToDisplay = text || logInfo.label; // Use passed text or the default label

  // Determine background and text color based on sender
  const bubbleBgColor =
    isCurrentUser || otherUser
      ? lightGreyScale // Use calculated light color for sender
      : isDarkMode
      ? '#2a2a2a' // Darker background for others in dark mode
      : size === 'small'
      ? '#f0f0f0' // Slightly off-white for others in light mode (small)
      : '#e9e9eb'; // Slightly off-white for others in light mode (normal)

  const bubbleTextColor =
    isCurrentUser || otherUser
      ? chroma.contrast(bubbleBgColor, '#000000') > 4.5
        ? '#000000'
        : '#ffffff' // Auto contrast for sender text
      : theme.palette.text.primary; // Use theme text color for others

  return (
    // ListItem acts as the full-width container
    <ListItem
      sx={{
        paddingX: '8px', // Reduced horizontal padding on the ListItem
        paddingY: '2px', // Reduced vertical padding
        display: 'flex', // Use flex to align the bubble
        justifyContent: isCurrentUser || otherUser ? 'flex-end' : 'flex-start', // Align bubble right/left
        width: '100%', // Ensure ListItem takes full width
      }}
    >
      {/* This Box is the message bubble */}
      <Box
        sx={{
          width: 'fit-content', // SIZES TO CONTENT HORIZONTALLY!
          maxWidth: '75%', // Max width to prevent taking full screen width
          bgcolor: 'transparent',
          borderRadius:
            isCurrentUser || otherUser
              ? '14px 14px 0px 14px' // Sender corners
              : '14px 14px 14px 0px', // Receiver corners
          padding: size === 'small' ? '4px 8px' : '8px 12px', // Adjusted padding
          display: 'flex', // Use flex for internal layout
          flexDirection: 'column', // Stack elements vertically
        }}
      >
        {/* Header row (Icon and Name/Timestamp) */}
        {size !== 'small' && ( // Only show header for normal size
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '4px',
              fontSize: '14px' /* Base size for icons */,
            }}
          >
            {/* Left part: Icon and Name */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexShrink: 1,
                overflow: 'hidden',
              }}
            >
              {logInfo.icon}
              <GeneralText
                text={name || '...'} // Show name or placeholder
                fontSize="11px" // Smaller font for name
                size="medium" // Medium weight
                primary={false} // Use bubbleTextColor
                color={bubbleTextColor} // Explicitly set color
                sx={{
                  marginLeft: '4px',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              />
            </Box>
            {/* Right part: Timestamp */}
            {/* {timestamp && (
              <GeneralText
                text={timestamp}
                fontSize="9px" // Smaller font for timestamp
                size="regular"
                primary={true} // Use bubbleTextColor (primary=true might be misleading here)
                color={chroma(bubbleTextColor).alpha(0.7).css()} // Slightly transparent timestamp
                sx={{ marginLeft: '8px', whiteSpace: 'nowrap', flexShrink: 0 }}
              />
            )} */}
          </Box>
        )}

        <Box
          onClick={() => setIsClicked(!isClicked)}
          sx={{
            marginTop: size === 'small' ? 0 : '-2px',
            bgcolor: isCurrentUser
              ? businessPreference?.mainColor
              : lightGreyScale + '90',
            padding: '4px 12px',
            borderRadius: isCurrentUser
              ? '14px 14px 0px 14px'
              : '14px 14px 14px 0px',
          }}
        >
          <GeneralText
            text={text ? text : getIconOfLog(type)?.label + ' ' + timestamp}
            fontSize={size === 'small' ? (isMobile ? '8px' : '10px') : '11px'}
            size="regular"
            primary={false}
            color={isCurrentUser ? '#FFFFFF' : '#000000'}
            markdown={size !== 'small'}
            style={{
              wordBreak: 'break-word',
              whiteSpace: 'pre-wrap',
            }}
          />
        </Box>
        {(isClicked || isLastMessage) && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '3px',
              paddingLeft: '5px',
              paddingRight: '5px',
            }}
          >
            {' '}
            <GeneralText
              text={timestamp}
              fontSize={'8px'}
              size="regular"
              primary={true}
              style={{
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap',
              }}
            />
          </Box>
        )}
      </Box>
    </ListItem>
  );
};

export default HistoryMessage;
