import React from 'react';

const NodeAIPerfo = ({ mainColor = '#3498db' }) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.9999 16.4L17.3999 30L20.7999 43.6L30.9999 36.8L41.1999 43.6L44.5999 30L30.9999 16.4Z"
        fill="url(#paint0_linear_140_231)"
      />
      <path
        d="M22.5 21.5L31 30M31 30L39.5 21.5M31 30V38.5"
        stroke="white"
        stroke-width="4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_140_231"
          x1="17.3999"
          y1="16.4"
          x2="2737.4"
          y2="2736.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3" stop-color={mainColor} />
          <stop offset="0.55" stop-color={mainColor} stop-opacity="0.4" />
          <stop offset="0.8" stop-color={mainColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NodeAIPerfo;
