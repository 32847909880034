import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

// Components
import { useDrag, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import GeneralText from '../stories/general-components/GeneralText';
import { useParams, useLocation } from 'react-router-dom';
import nodeAxiosFirebase from '../utils/nodeAxiosFirebase';
import ModalLarge from './Base/ModalLarge';
import { BusinessOutlined, CodeOutlined, FontDownloadOutlined, HorizontalRuleOutlined, ListAltOutlined, PictureInPictureOutlined, QrCode2Outlined, ReceiptLongOutlined, SplitscreenOutlined, SummarizeOutlined, TagOutlined, TextFieldsOutlined } from '@mui/icons-material';
import defaultFieldsResolver from '../utils/defaultFieldsResolver';
import PrintableElement from '../stories/layout-components/templates/PrintableElement'; // Ensure correct path

const DraggableComponent = ({ name, type, value, structureId, icon }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "component",
    item: { name, type, value, structureId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const businessPreference = useSelector((state) => state.core.businessData);

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1, padding: '8px', border: '0.75px solid', borderColor: type === 'preset' ? businessPreference?.mainColor : businessPreference?.secColor, backgroundColor: type === 'preset' ? businessPreference?.mainColor + '10' : businessPreference?.secColor + '10', borderRadius: '8px', margin: '12px', cursor: 'grab' }}>
      <div className='d-flex middle-content'> <div>{icon ? icon : <TextFieldsOutlined />}</div> <GeneralText text={name} primary={true} fontSize='13px' size='medium' classNameComponent='px-3' /> </div>
    </div>
  );
};


const ModalPDF = ({ isOpen, modalCloseHandler }) => {
  const { t, i18n } = useTranslation();
  const langCode = i18n.language;
  const dispatch = useDispatch();
  const { search } = useLocation();
  const printRef = useRef(null);

  const [reload, setReload] = useState(true);
  // Define initial sections structure
  const initialSectionsStructure = ['header', ...Array.from({ length: 1 }, (_, i) => `section-${i + 1}`), 'footer'];
  const initialSections = initialSectionsStructure.reduce((acc, sectionId) => {
    acc[sectionId] = [];
    return acc;
  }, {});

  // State for components and layout
  const [components, setComponents] = useState(initialSections);
  const [sectionLayouts, setSectionLayouts] = useState({});
  const [elementData, setElementData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [isCopiedElement, setIsCopiedElement] = useState(false);


  const { elementId } = useParams();
  const accessCode = new URLSearchParams(search).get('accessCode');


  const singleDetails = useSelector(
    (state) => state.element.singleElementDetails
  );

  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );
  const structures = businessStructure?.structures;

  const currentStructure = structures?.find(
    (structure) =>
      structure?.collectionField === singleDetails?.structureDetails?.name
  );


  const getDetails = async () => {
    try {
      const element = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `public/single-element`,
        noAuth: true,
        body: {
          elementId: elementId || singleDetails?.id,
          documentPath: singleDetails?.documentPath,
          fromBusiness: true,
          accessCode: singleDetails?.accessCode || accessCode,
          lang: langCode,
        },
        reduxDispatcher: dispatch,
        loadingMessage: `${t('gettingCard')}`,
      });
      setElementData(element);
      setReload(false);

      if (element?.template) {
        const loadedComponents = element?.template?.components || {};
        const loadedLayout = element?.template?.layout || {};

        const mergedComponents = { ...initialSections };
        Object.keys(loadedComponents).forEach(key => {
          if (mergedComponents.hasOwnProperty(key)) {
            mergedComponents[key] = loadedComponents[key];
          } else {
            console.warn(`Loaded template contains unexpected section: ${key}`);
            mergedComponents[key] = loadedComponents[key];
          }
        });
        Object.keys(initialSections).forEach(key => {
          if (!mergedComponents.hasOwnProperty(key)) {
            mergedComponents[key] = [];
          }
        });

        setComponents(mergedComponents);
        setSectionLayouts(loadedLayout);

      } else {
        setComponents(initialSections);
        setSectionLayouts({});
      }
    } catch (error) {
      console.error("Error fetching details:", error);
      setReload(false);
      setComponents(initialSections);
      setSectionLayouts({});
    }
  };

  useEffect(() => {
    if (isOpen) {
      setReload(true);
    } else {
      setReload(false);
      // Optionally reset when closing - Keep current state if user might reopen quickly
      // setComponents(initialSections);
      // setSectionLayouts({});
      // setElementData({});
    }
  }, [isOpen]);


  useEffect(() => {
    if (reload && isOpen && (elementId || singleDetails?.id)) {
      getDetails();
    }
  }, [reload, isOpen, elementId, singleDetails?.id]);


  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `@page { size: auto; margin: 10mm 10mm 10mm 10mm; } @media print { body { -webkit-print-color-adjust: exact; print-color-adjust: exact; } }`, // Basic print styling
    onBeforeGetContent: () => Promise.resolve(),
    onAfterPrint: () => { },
  });

  const formattedFields = currentStructure?.fields?.flatMap((field) => {
    if (field?.value === 'targetId') {
      return [
        { name: `${field?.name} ${t('name')}`, value: 'targetDetails.name', type: 'text', },
        { name: `${field?.name} ${t('email')}`, value: 'targetDetails.email', type: 'text', },
        { name: `${field?.name} ${t('phone')}`, value: 'targetDetails.phone', type: 'text', },
        { name: `${field?.name} ${t('address')}`, value: 'targetDetails.address', type: 'text', },
        { name: `${field?.name} ${t('attribute1')}`, value: 'targetDetails.attribute1', type: 'text', },
        { name: `${field?.name} ${t('attribute2')}`, value: 'targetDetails.attribute2', type: 'text', },
        { name: `${field?.name} ${t('attribute3')}`, value: 'targetDetails.attribute3', type: 'text', },
        { name: `${field?.name} ${t('attribute4')}`, value: 'targetDetails.attribute4', type: 'text', },
      ];
    } else if (field?.value === 'targetProfileId') {
      return [
        { name: `${field?.name} ${t('name')}`, value: 'targetProfileDetails.name', type: 'text' },
        { name: `${field?.name} ${t('attribute1')}`, value: 'targetProfileDetails.attribute1', type: 'text' },
        { name: `${field?.name} ${t('attribute2')}`, value: 'targetProfileDetails.attribute2', type: 'text' },
        { name: `${field?.name} ${t('attribute3')}`, value: 'targetProfileDetails.attribute3', type: 'text' },
        { name: `${field?.name} ${t('attribute4')}`, value: 'targetProfileDetails.attribute4', type: 'text' },
        { name: `${field?.name} ${t('metric')}`, value: 'targetProfileDetails.metric', type: 'text' },
      ];
    } else if (field?.type === 'data') {
      return { name: field?.name, value: 'data.' + field?.value, type: field?.typeData || 'text', structureId: currentStructure?.id };
    } else {
      return { name: field?.name, value: field?.value, type: field?.typeData || 'text', structureId: currentStructure?.id };
    }
  }) || [];

  const defaultFields = defaultFieldsResolver(currentStructure?.collectionField, t)
  const defaultFieldsFormatted = defaultFields?.map((field) => {
    return {
      name: field?.name,
      value: field?.value,
      type: field?.typeData || 'text',
      icon: field?.icon || <TextFieldsOutlined />,
    }
  }) || [];

  const componentsList = [
    // (Keep existing componentsList logic - unchanged)
    { name: t('businessDetails'), value: 'businessDetails', type: 'preset', icon: <BusinessOutlined /> },
    { name: t('itemsTable'), value: 'itemsTable', type: 'preset', icon: <ReceiptLongOutlined /> },
    { name: t('statementsTable'), value: 'statementsTable', type: 'preset', icon: <SummarizeOutlined /> },
    { name: t('whiteSpace'), value: 'space', type: 'preset', icon: <SplitscreenOutlined /> },
    { name: t('line'), value: 'line', type: 'preset', icon: <HorizontalRuleOutlined /> },
    { name: t('qrCode'), value: 'qrCode', type: 'preset', icon: <QrCode2Outlined /> },
    { name: t('html'), value: 'html', type: 'preset', icon: <CodeOutlined /> },
    { name: t('image'), value: 'image', type: 'preset', icon: <PictureInPictureOutlined /> },
    { name: t('freeText'), value: 'freeText', type: 'preset', icon: <FontDownloadOutlined /> },
    { name: t('freeList'), value: 'list', type: 'preset', icon: <ListAltOutlined /> },
    ...formattedFields,
    { name: t('id'), value: 'documentIdentifiant', type: 'field', icon: <TagOutlined /> },
    { name: t('shortId'), value: 'searchId', type: 'field', icon: <TagOutlined /> },
    ...defaultFieldsFormatted,
  ]

  const handleSave = async () => {
    try {
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/templates`,
        body: {

          template: {
            components: components,
            layout: sectionLayouts,
          },
          lang: langCode,
          structureId: currentStructure?.id,
        },
        reduxDispatcher: dispatch,
        loadingMessage: `${t('savingTemplate')}`,
      });
      toast.success(t('templateSaved'));
      setEditMode(false);
      setReload(true);
    } catch (error) {
      console.error("Error saving template:", error);
      toast.error(t('errorSavingTemplate'));
    }
  }

  const handleEditMode = () => {
    setEditMode(!editMode);
  }


  const color = elementData?.businessData?.mainColor

  const handleCopyTemplate = () => {
    try {
      const templateToCopy = {
        components: components,
        layout: sectionLayouts
      };
      localStorage.setItem('template-copy', JSON.stringify(templateToCopy));
      toast.success(t('templateCopied'));
      setIsCopiedElement(true);
    } catch (error) {
      console.error("Error copying template to localStorage:", error);
      toast.error(t('errorCopyingTemplate'));
    }
  }


  const handlePasteTemplate = () => {
    try {
      const templateString = localStorage.getItem('template-copy');
      if (templateString) {
        const templateData = JSON.parse(templateString);
        if (typeof templateData === 'object' && templateData !== null && typeof templateData.components === 'object') {
          const pastedComponents = templateData.components;
          const pastedLayout = templateData.layout || {};

          setComponents(pastedComponents);
          setSectionLayouts(pastedLayout);
          toast.success(t('templatePasted'));
        } else {
          toast.error(t('invalidTemplateFormat'));
        }

      } else {
        toast.info(t('nothingToPaste'));
      }
    } catch (error) {
      console.error("Error pasting template from localStorage:", error);
      toast.error(t('errorPastingTemplate'));
    }
  }

  useEffect(() => {
    const templateExists = localStorage.getItem('template-copy');
    setIsCopiedElement(!!templateExists);
  }, []);


  const handleRemoveSection = (sectionId) => {
    setComponents((prevComponents) => {
      const updatedComponents = { ...prevComponents };
      if (updatedComponents[sectionId]) {
        delete updatedComponents[sectionId];
      }
      return updatedComponents;
    });
    setSectionLayouts((prevLayouts) => {
      const updatedLayouts = { ...prevLayouts };
      if (updatedLayouts[sectionId]) {
        delete updatedLayouts[sectionId];
      }
      return updatedLayouts;
    });
  }


  const handleAddSection = () => {
    let newSectionId = `section-${Object.keys(components)?.length + 1}`;

    if (components[newSectionId]) {
      let counter = 1;
      while (components[newSectionId]) {
        newSectionId = `section-${Object.keys(components)?.length + counter}`;
        counter++;
      }
    }

    setComponents((prevComponents) => ({
      ...prevComponents,
      [newSectionId]: [],
    }));
    setSectionLayouts((prevLayouts) => ({
      ...prevLayouts,
      [newSectionId]: 12,
    }));

  }


  return (
    <DndProvider backend={HTML5Backend} >
      <ModalLarge
        handlePrim={editMode ? handleSave : handlePrint}
        labels={{ primary: editMode ? t('save') : t('print'), secondary: t('copyTemplate') }}
        handleSec={handleCopyTemplate}
        title={t('preview')}
        subTitle={t('documentPreview')}
        isOpen={isOpen}
        displaySettings={handleEditMode}
        maxWidth={'xl'}
        fullHeight
        fullWidth
        modalCloseHandler={modalCloseHandler}
      >
        <Helmet>
          <title>
            {`${currentStructure?.name || t('document')} - #${singleDetails?.searchId || elementData?.searchId || ''}`}
          </title>
        </Helmet>

        <div className="row" style={{ height: 'calc(100vh - 150px)', overflow: 'hidden' }}>
          {editMode && (
            <div style={{ height: '100%', overflowY: 'auto', borderRight: '1px solid #e0e0e0' }} className='col-3'>
              <div className='align-left p-2'>
                <GeneralText text={t('componentsToDrag')} primary={true} fontSize='14px' size='bold' classNameComponent='px-3 mt-2' />
                <GeneralText text={t('componentsToDragText')} primary={false} color="#666" fontSize='11px' size='regular' classNameComponent='px-3 mb-2 mt-1' />
                {isCopiedElement && (
                  <div onClick={handlePasteTemplate} className='mt-2 mb-3' style={{ marginLeft: '10px', maxWidth: '210px', cursor: 'pointer', fontSize: '11px', padding: '6px 10px', borderRadius: 8, border: '1px solid #ccc', color: '#555', backgroundColor: '#f9f9f9' }}>
                    {t('pasteTemplate')}
                  </div>
                )}
              </div>
              {componentsList
                ?.filter(comp => {
                  const isInvoice = currentStructure?.collectionField?.includes('cards');
                  const isContact = currentStructure?.collectionField?.includes('contacts');
                  if (comp?.value === 'itemsTable') return isInvoice;
                  if (comp?.value === 'statementsTable') return isContact;
                  return true;
                })
                ?.map((comp) => (
                  <DraggableComponent key={`${comp?.value}-${comp?.type}`} name={comp?.name} type={comp.type} value={comp.value} structureId={comp?.structureId} icon={comp?.icon} />
                ))}
            </div>
          )}
          <div style={{ height: '100%', overflowY: 'auto' }} className={editMode ? 'col-9' : 'col-12'}>
            <div style={{ maxWidth: '1100px', margin: '0 auto', padding: '20px' }}>
              <PrintableElement
                printAreaRef={printRef}
                elementData={elementData}
                editMode={editMode}
                color={color}
                components={components}
                setComponents={setComponents}
                sectionLayouts={sectionLayouts}
                onRemoveSection={handleRemoveSection}
                setSectionLayouts={setSectionLayouts}
                onAddSection={handleAddSection}
              />
            </div>
          </div>
        </div>
      </ModalLarge>
    </DndProvider >
  );
};

export default ModalPDF;