import React, { } from 'react';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import Typo from '../Typo';
import {
  CodeOutlined,
  SchoolOutlined,
  SupportOutlined,
  TuneOutlined,
} from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';

const BlocsEntreprise = ({ translationCode }) => {
  const { t, } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';
  const localStorageColor = localStorage.getItem('mainColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';

  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  return (
    <div
      style={{
        marginTop: isMobile ? '400px' : '200px',
      }}
      className="container"
    >
      <div className={isMobile ? 'row px-4' : 'row'}>
        <div className="col-md-6 col-12 mt-4">
          <div
            style={{
              backgroundColor: '#F3F3F390',
              padding: '30px',
              borderRadius: '20px',
            }}
            className="d-flex"
          >
            <div className="col-md-1 col-2">
              <TuneOutlined
                sx={{ fontSize: '28px' }}
                color={isDarkMode ? 'white' : 'black'}
              />
            </div>
            <div className="col-md-11 col-10">
              <Typo text={t('entrepriseBloc1')} variant="smallTitle" />
              <Typo
                text={t(translationCode + 'Bloc1Desc')}
                variant="p"
                className={'mt-3'}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 mt-4">
          <div
            style={{
              backgroundColor: darkMainColor,
              padding: '30px',
              borderRadius: '20px',
            }}
            className="d-flex"
          >
            <div className="col-md-1 col-2">
              {' '}
              <CodeOutlined sx={{ fontSize: '28px' }} color={'white'} />
            </div>
            <div className="col-md-11 col-10">
              <Typo
                text={t(translationCode + 'Bloc2')}
                variant="smallTitle"
                color="white"
              />
              <Typo
                text={t(translationCode + 'Bloc2Desc')}
                variant="p"
                className={'mt-3'}
                color="white"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 mt-4">
          <div
            style={{
              backgroundColor: '#E7E7E790',
              padding: '30px',
              borderRadius: '20px',
            }}
            className="d-flex"
          >
            <div className="col-md-1 col-2">
              {' '}
              <SchoolOutlined
                sx={{ fontSize: '28px' }}
                color={isDarkMode ? 'white' : 'black'}
              />
            </div>
            <div className="col-md-11 col-10">
              <Typo text={t('entrepriseBloc3')} variant="smallTitle" />
              <Typo
                text={t(translationCode + 'Bloc3Desc')}
                variant="p"
                className={'mt-3'}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 mt-4">
          <div
            style={{
              backgroundColor: '#F3F3F390',
              padding: '30px',
              borderRadius: '20px',
            }}
            className="d-flex"
          >
            <div className="col-md-1 col-2">
              {' '}
              <SupportOutlined
                sx={{ fontSize: '28px' }}
                color={isDarkMode ? 'white' : 'black'}
              />
            </div>
            <div className="col-md-11 col-10">
              <Typo text={t(translationCode + 'Bloc4')} variant="smallTitle" />
              <Typo
                text={t(translationCode + 'Bloc4Desc')}
                variant="p"
                className={'mt-3'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlocsEntreprise;
