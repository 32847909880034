// Libraries
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
} from 'recharts';

// Components
import GeneralText from '../../stories/general-components/GeneralText';
import { useSelector } from 'react-redux';

const ReportProfile = ({ element }) => {
  const barChartData = [
    {
      name: 'Espaces communs',
      "Bleu": 9.82,
      "Rouge": 3.86,
      "Vert": 20.00,
    },
    {
      name: 'Bureaux',
      "Bleu": 12.5,
      "Rouge": 6.1,
      "Vert": 18.2,
    },
    {
      name: 'Technique',
      "Bleu": 7.3,
      "Rouge": 5.5,
      "Vert": 22.1,
    },
  ];
  const lineChartData = [
    { date: '15/01/2023', value: 93.45 },
    { date: '10/02/2023', value: 91.31 },
    { date: '19/02/2023', value: 88.60 },
    { date: '04/03/2023', value: 80.45 },
    { date: '13/03/2023', value: 94.32 },
  ];

  const businessPreference = useSelector((state) => state.core.businessData);

  const colors = {
    Bleu: '#ADD8E6',
    Rouge: '#F08080',
    Vert: '#90EE90',
  };

  return (
    <div className="row align-left">
      <div className="mt-4 mb-3">
        <GeneralText
          text={'Résultat global'}
          primary={true}
          size='bold'
          fontSize='14px'
        />
      </div>

      <GeneralText tag="p" text="Le résultat global obtenu pour ce contrôle est de" />

      <div className='mb-3 mx-3' style={{ display: 'inline-block', backgroundColor: '#000000', color: 'white', padding: '5px 10px', borderRadius: '5px', fontWeight: 'bold' }}>
        94,32%
      </div>

      <GeneralText primary={true} fontSize={'11px'} size={'regular'} text="Le résultat minimum requis étant de 85,00%, le contrôle est donc conforme" />
      <GeneralText primary={true} fontSize={'11px'} size={'regular'} text="Le rapport de contrôle des lots ne concerne que l'échantillonnage sélectionné" />

      <div className="mt-4 mb-3">
        <GeneralText
          text={'Résultat par niveau de qualité'}
          primary={true}
          size='bold'
          fontSize='14px'
        />
      </div>
      <div className='row'>
        <div className='col-6'>
          {/* Table */}
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr style={{ backgroundColor: '#f2f2f2' }}>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Niveau</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>% minimum requis</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>% Obtenu</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Ecart</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>(**)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: '1px solid black', padding: '8px' }}>Rouge</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>90,00%</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>93,86%</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>3,86%</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>C</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: '8px' }}>Bleu</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>85,00%</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>94,82%</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>9,82%</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>C</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: '8px' }}>Vert</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>80,00%</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>100,00%</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>20,00%</td>
                <td style={{ border: '1px solid black', padding: '8px' }}>C</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='col-6'>


          <ResponsiveContainer width="100%" height={170}>
            <BarChart
              data={barChartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              {Object.keys(colors).map((colorKey, index) => (
                <Bar
                  key={`bar-${colorKey}`}
                  dataKey={colorKey}
                  fill={colors[colorKey]}
                  radius={[5, 5, 0, 0]}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>

          <GeneralText text="(**) NC = Non Conforme pour le niveau de qualité" primary={true} fontSize='11px' size='regular' />
          <GeneralText text="C = Conforme pour le niveau de qualité." primary={true} fontSize='11px' size='regular' />
        </div>
      </div>

      <div className="mt-4 mb-3">
        <GeneralText
          text={'Évolution de la qualité globale'}
          primary={true}
          size='bold'
          fontSize='14px'
        />
      </div>

      <ResponsiveContainer width="100%" className='align-left' height={300}>
        <LineChart
          data={lineChartData}
          margin={{
            top: 5,
            right: 30,
            left: -25,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="value" stroke={businessPreference?.mainColor} activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ReportProfile;