import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Image, Circle, Text } from 'react-konva';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import nodeAxiosFirebase from '../../../utils/nodeAxiosFirebase';
import { List, ListItem, ListItemText } from '@mui/material';
import GeneralText from '../../general-components/GeneralText';
import { useNavigate } from 'react-router-dom';

const MappingImagePin = ({ elementDetails, handleRefresh, handleNew, layout }) => {
  const { t } = useTranslation();
  const [imageSrc, setImageSrc] = useState('');
  const [image, setImage] = useState(null);
  const stageRef = useRef(null);
  const navigate = useNavigate()

  const [pins, setPins] = useState([]);

  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );

  const businessStructures = businessStructure?.structures;

  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPinCoordinates, setNewPinCoordinates] = useState({ x: 0, y: 0 });
  const [newPinLabel, setNewPinLabel] = useState('');
  const [selectedPin, setSelectedPin] = useState(null);
  const [selectedType, setSelectedType] = useState(null);


  const structure = businessStructures?.find(
    (s) => s.id === layout?.structureId
  );


  useEffect(() => {
    if (imageSrc) {
      const img = new window.Image();
      img.onload = () => {
        setImage(img);
        setImageDimensions({ width: img.width, height: img.height });
      };
      img.src = imageSrc;
    }
  }, [imageSrc]);


  useEffect(() => {
    if (elementDetails?.data?.length > 0) {
      const formattedPins = elementDetails.data.map((pin) => ({
        x: pin?.elementData?.[pin?.settings?.x] || 5,
        y: pin?.elementData?.[pin?.settings?.y] || 5,
        label: pin?.elementData?.name,
        id: pin?.elementData?.id,
        path: pin?.elementData?.dependencyPath,
        keyX: pin?.settings?.x,
        keyY: pin?.settings?.y,
        type: pin?.elementData?.type,
      }));
      const filteredPins = formattedPins?.filter(pin => parseInt(pin.type) === parseInt(selectedType?.value));
      setPins(filteredPins);
    }
  }, [elementDetails?.data, selectedType?.value]);


  const handlePinClick = (pin) => {
    setSelectedPin(pin);
    navigate('/app/element/profiles/Q5LTQFM9UytrjehU0oVT/' + pin?.id)
  };

  const updatePin = async (event, type, pinId, path) => {
    const newValue = parseFloat(event?.target?.value || event || 0)

    await nodeAxiosFirebase({
      t,
      method: 'PATCH',
      url: `coreSeqV3`,
      body: {
        documentId: pinId,
        elementPath: path + '/profiles',
        key: type,
        value: newValue,
      },
    });
    setTimeout(() => {
      handleRefresh();
    }, 1200);
  };

  const handleDragEnd = (index, event) => {
    const newX = event.target.x();
    const newY = event.target.y();
    setPins(prevPins => {
      const newPins = [...prevPins];
      newPins[index] = { ...newPins[index], x: newX, y: newY };
      return newPins;
    });

    const pin = pins[index];
    const pinId = pin.id;
    const pinPath = pin.path;
    const keyX = pin.keyX;
    const keyY = pin.keyY;
    const pinX = event.target.x();
    const pinY = event.target.y();
    updatePin(pinX, keyX, pinId, pinPath);
    updatePin(pinY, keyY, pinId, pinPath);
  };

  const handleStageClick = (event) => {
    const stage = stageRef.current.getStage();
    const point = stage.getPointerPosition();
    const x = point.x;
    const y = point.y;

    setNewPinCoordinates({ x, y });
    setNewPinLabel('');
    handleNew([{ value: parseInt(x), key: 'Nod_number355' }, { value: parseInt(y), key: 'Nod_number872' }]);
  };


  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalSave = () => {
    if (newPinLabel.trim() !== '' && newPinCoordinates.x !== 0 && newPinCoordinates.y !== 0) {
      setPins(prevPins => [...prevPins, { x: newPinCoordinates.x, y: newPinCoordinates.y, label: newPinLabel }]);
    }
    setIsModalOpen(false);
  };

  const typeField = structure?.fields?.find((field) => field?.value === 'type');


  return (
    <div style={{ padding: '20px' }}>
      <div className='row'>
        <div style={{ borderRadius: '8px', paddingRight: '20px' }} className='col-10'>
          {imageSrc && (
            <Stage
              width={imageDimensions?.width}
              height={imageDimensions?.height}
              ref={stageRef}
              onClick={handleStageClick}
            >
              <Layer>
                {image && (
                  <Image
                    image={image}
                    width={imageDimensions?.width}
                    height={imageDimensions?.height}
                    opacity={0.8}
                  />
                )}
                {image && pins?.map((pin, index) => (
                  <React.Fragment key={index}>
                    <Text
                      x={pin.x - 15}
                      y={pin.y - 20}
                      text={pin?.label}
                      fontSize={10}
                      fill={businessPreference?.mainColor || 'black'}
                      shadowBlur={0}
                    />
                    <Circle
                      x={pin.x}
                      y={pin.y}
                      radius={5}
                      fill={businessPreference?.mainColor || 'black'}
                      draggable
                      onDragEnd={(event) => handleDragEnd(index, event)}
                      onClick={(event) => {
                        event.cancelBubble = true;
                        handlePinClick(pin);
                      }}
                      stroke={selectedPin === pin ? (businessPreference?.secColor || 'black') : (businessPreference?.mainColor || 'black')}
                      strokeWidth={selectedPin === pin ? 3 : 2}
                    />
                  </React.Fragment>
                ))}
              </Layer>
            </Stage>)}
        </div>
        <div style={{ backgroundColor: '#FFF', borderRadius: '8px' }} className='col-2'>
          <div className="mb-2 mt-4 px-3">
            <GeneralText
              text={typeField?.name || 'Type'}
              primary={true}
              fontSize='14px'
              size='medium'
            />
          </div>
          <List>
            {typeField?.selections?.map((type) => (
              <ListItem
                key={type?.id}
                button='true'
                onClick={() => {
                  setSelectedType(type);
                  setImageSrc(type?.media_url || '');
                }}
                sx={{ backgroundColor: selectedType?.id === type?.id ? (businessPreference?.mainColor || 'black') : 'transparent', color: selectedType?.id === type?.id ? '#FFF' : '#000', borderRadius: '8px', marginBottom: '5px' }}
              >
                <ListItemText primary={type?.label_fr} />
              </ListItem>
            ))}
          </List>
        </div>
      </div>

      {/* Modal pour ajouter un nouveau pin */}
      <Dialog open={isModalOpen} onClose={handleModalClose}>
        <DialogTitle>{t('addPin')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="pinLabel"
            label={t('pin_label')}
            type="text"
            fullWidth
            variant="standard"
            value={newPinLabel}
            onChange={(e) => setNewPinLabel(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="secondary">
            {t('cancel')}
          </Button>
          <Button onClick={handleModalSave} color="primary">
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MappingImagePin;