import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  useParams,
  useSearchParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { doc, onSnapshot } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Checkbox, Divider, Drawer, FormControlLabel, Paper, Skeleton } from '@mui/material';

// Actions & Redux
import * as drawerActions from '../../redux/actions-v2/drawer-actions';
import * as modalActions from '../../redux/actions/modal-actions';
import { setSingleElementDetails } from '../../redux/actions-v2/elementAction';
import { setGeneralStatus } from '../../redux/actions-v2/coreAction';

// Utilities & DB
import { db } from '../../firebase';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';

// Components
import MainLayoutV2 from '../../layouts/MainLayoutV2';
import ElementDetailsContent from './ElementDetailsContent';
import TextField from '../../stories/general-components/TextField';
import Loading from '../../stories/general-components/Loading';
import GeneralText from '../../stories/general-components/GeneralText';
import Button from '../../stories/general-components/Button';

// Constants for public/business modes
const MODE = {
  BUSINESS: 'business',
  PUBLIC: 'public',
};


const ElementDetails = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();

  const { structureId, elementId, moduleName } = useParams();
  const [searchParams] = useSearchParams();
  const activeIndex = parseInt(searchParams.get('tab')) || 0;

  // --- State ---
  const [isLoading, setIsLoading] = useState(true);
  const [errorDoc, setErrorDoc] = useState(false);
  const [elementData, setElementData] = useState(null); // Initialize as null
  const [accTabs, setAccTabs] = useState([]);
  const [accTabsPublic, setAccTabsPublic] = useState([]);
  const [isPublicMode, setIsPublicMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [tabDetailsOpen, setTabDetailsOpen] = useState(false);
  const [newIndexToCreate, setNewIndexToCreate] = useState(false); // For business tabs
  const [newIndexToCreatePublic, setNewIndexToCreatePublic] = useState(false);

  // State for editing/adding tab details
  const [tabData, setTabData] = useState({
    groups: [],
    name_en: 'Details',
    name_fr: 'Détails',
  });
  const [tabDataPublic, setTabDataPublic] = useState({
    name_en: 'Details',
    name_fr: 'Détails',
  });

  // State to hold the *current* layout being edited (synced from accTabs)
  const [layout, setLayout] = useState([]);
  const [layoutPublic, setLayoutPublic] = useState([]);

  // --- Selectors ---
  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructure = useSelector((state) => state.core.businessStructure);
  const currentStatus = useSelector((state) => state.core.status);
  const currentCollection = useSelector((state) => state.list.currentCollection);
  const currentUser = useSelector((state) => state.core.user);

  // --- Derived State ---
  const currentlangCode = i18n.language;
  const businessStructures = businessStructure?.structures;
  const structure = useMemo(() => businessStructures?.find((s) => s.id === structureId), [businessStructures, structureId]);
  const groupsList = useMemo(() => businessPreference?.groups?.map((group) => group?.identifiant) || [], [businessPreference?.groups]);



  // --- Helper Functions ---

  const getElementPath = useCallback(() => {
    if (!elementId || !moduleName) return null;

    if (moduleName === 'contacts') {
      const targetId = elementId?.split(businessPreference?.id)[0];
      if (!targetId) return null;
      return `users/${targetId}/connections/${elementId}`;
    } else if (
      moduleName === 'cardsinvoiced' ||
      moduleName === 'cardsuninvoiced' ||
      moduleName === 'cardsexpense'
    ) {
      return `cards/${elementId}`;
    } else {
      return `${moduleName}/${elementId}`;
    }
  }, [elementId, moduleName, businessPreference?.id]);

  const updateTabIndexInURL = useCallback((index) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('tab', index.toString());
    navigate(`${location.pathname}?${newSearchParams.toString()}`, { replace: true }); // Use replace to avoid history bloat for tab changes
  }, [navigate, location.pathname, searchParams]);


  // --- Data Fetching & Updates ---




  const getDocument = useCallback(async (fetchMode = null) => {
    const mode = fetchMode || (isPublicMode ? MODE.PUBLIC : MODE.BUSINESS);
    const elementPath = getElementPath();

    // Check essential parameters *before* making the call if possible
    // Note: elementData.id check moved to the calling useEffect
    if (!elementPath || !structureId) {
      setIsLoading(false); // Stop loading if essential params missing
      return;
    }

    // --- Remove layout length check from here ---
    // Decide on loading state based on whether accTabs is empty BEFORE this call,
    // or based on the result AFTER this call.
    // For simplicity, let's assume initial loading is handled elsewhere or
    // just use setIsLoading(true) unconditionally before the async call.
    // Consider only setting loading if accTabs is currently empty:
    if (mode === MODE.PUBLIC ? !accTabsPublic.length : !accTabs.length) {
      setIsLoading(true);
    }


    try {

      const pageData = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `coreMultiV2/single`,
        errorToast: (mode === MODE.PUBLIC ? !accTabsPublic.length : !accTabs.length) ? t('errorLoadingDocument') : undefined,
        body: {
          type: mode,
          documentPath: elementPath,
          structureId: structureId,
          lang: currentlangCode,
          document: elementData || null,
          height: window.innerHeight,
          device: 'desktop',
          eventData: {
            queryID: currentCollection?.[moduleName]?.queryID || null,
            type: 'click',
            objectIDs: currentCollection?.[moduleName]?.queryID
              ? currentCollection?.[moduleName]?.objectIDs
              : null,
          },
        },
      });

      if (mode === MODE.PUBLIC) {
        setAccTabsPublic(pageData || []);
      } else {
        setAccTabs(pageData || []);
      }

      setErrorDoc(false); // Clear previous error on success
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'edit-block' + layout?.i,
          type: 'skeleton',
        })
      );
    } catch (error) {
      console.error("Error in getDocument:", error);
      setErrorDoc(true);
    } finally {
      // Set loading false unconditionally after attempt
      setIsLoading(false);
    }
  }, [
    // REMOVED: elementData, layout, layoutPublic
    isPublicMode,
    getElementPath, // This callback itself has stable deps hopefully
    structureId,
    t,
    currentlangCode,
    currentCollection,
    moduleName,
    dispatch,
    accTabs.length,
    accTabsPublic.length
  ]);

  const handleRefreshDoc = useCallback(() => {
    setTimeout(() => {
      getDocument();
    }, 1000);

  }, [getDocument]);



  useEffect(() => {
    const currentAccTabs = isPublicMode ? accTabsPublic : accTabs;
    const newLayout = currentAccTabs[activeIndex]?.blocks || [];
    if (isPublicMode) {
      setLayoutPublic(newLayout);
    } else {

      setLayout(newLayout);
    }
    setNewIndexToCreate(false);
    setNewIndexToCreatePublic(false);
  }, [accTabs, accTabsPublic, activeIndex, isPublicMode]);


  // Update data (Layout changes, Tab settings)
  const updateStructureData = useCallback(async (mode, type, data, statusPosition = 'updateStructure') => {
    setEditMode(false);
    dispatch(setGeneralStatus({ status: 'loading', position: statusPosition, type: 'pulse' }));

    try {
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/structure`,
        body: {
          structureId: structureId,
          type: type,
          lang: currentlangCode,
          data: {
            from: mode,
            tabIndex: activeIndex,
            ...data,
          },
        },
      });

      dispatch(setGeneralStatus({ status: 'success', position: statusPosition, type: 'pulse' }));

      // Reset temporary states after successful save
      if (type === 'addTab' || type === 'tab') {
        setTabData({ groups: [], name_en: 'Details', name_fr: 'Détails' });
        setTabDataPublic({ name_en: 'Details', name_fr: 'Détails' });
        setNewIndexToCreate(false);
        setNewIndexToCreatePublic(false);
        setTabDetailsOpen(false);
      }

      // Refresh the document data after structure change
      await getDocument(mode);

    } catch (error) {
      console.error(`Error updating structure (${type})`, error);
      dispatch(setGeneralStatus({ status: 'error', position: statusPosition, type: 'pulse', error: error }));
      // Toast is likely handled by nodeAxiosFirebase, otherwise add: toast.error(t('errorSavingChanges'));
    }
  }, [dispatch, t, structureId, currentlangCode, activeIndex,]);


  // --- Firebase Realtime Listener ---
  useEffect(() => {
    const elementPath = getElementPath();
    if (!elementPath || !structureId || !businessPreference?.id) {
      // If essential IDs are missing, set loading to false and potentially show an error/empty state.
      if (!elementId || !structureId || !businessPreference?.id) {
        setIsLoading(false);
        //setErrorDoc(true); // Or handle missing IDs differently
      }
      return;
    }


    if (elementData?.id !== elementId) {
      setElementData(null);
      setAccTabs([]);
      setAccTabsPublic([]);

      setLayout([]);
      setLayoutPublic([]);
      setIsLoading(true);
    }


    const elementRef = doc(db, elementPath);
    const unsubscribe = onSnapshot(
      elementRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const rawData = docSnapshot.data();
          const docId = docSnapshot.id;
          const docPath = rawData?.documentPath || docSnapshot?.ref?.path;


          const transformedData = {
            id: docId,
            documentPath: docPath,
            documentId: docId,
            ...(rawData?.data || {}),
            ...rawData,
            categoryId: rawData?.categoryId?.id || rawData?.categoryId || null,
            ownerId: rawData?.ownerId?.id || rawData?.ownerId || null, // Handle both reference and string
            targetProfileId: rawData?.targetProfileId?.id || null,
            targetProfilePath: rawData?.targetProfileId?.path || null,
            targetId: rawData?.targetId?.id || null,
            targetPath: rawData?.targetId?.path || null,
            assignedToId: rawData?.assignedToId?.id || null,
            dependencyId: rawData?.dependencyId?.id || null,
            dependencyPath: rawData?.dependencyId?.path || null,
            structureId: rawData?.structureId?.id || null,
            structurePath: rawData?.structureId?.path || null,
            locationId: rawData?.locationId?.id || null,
            finances: {
              total: rawData?.finances?.total || null,
              subtotal: rawData?.finances?.subtotal || null,
              amount: rawData?.finances?.amount || null,
              balance: rawData?.finances?.balance || null,
              incomeLine: rawData?.finances?.incomeLine?.path || null,
              expenseLine: rawData?.finances?.expenseLine?.path || null,
            },
            targetProfileDetails: rawData?.targetProfileDetails || {},
            targetProfileName: rawData?.targetProfileDetails?.name || rawData?.targetProfileName || null,
            targetName: rawData?.targetDetails?.name || rawData?.targetName || null,
            targetPhone: rawData?.targetDetails?.phone || rawData?.targetPhone || null,
            targetEmail: rawData?.targetDetails?.email || rawData?.targetEmail || null,
            targetAddress: rawData?.targetDetails?.address || rawData?.targetAddress || null,
            dependencyName: rawData?.dependencyDetails?.name || rawData?.dependencyName || null,
            assignedToName: rawData?.assignedToDetails?.name || rawData?.assignedToName || null,
            searchId: rawData?.searchId || null,
            targetReference: rawData?.targetReference || null,
            isInvoiced: rawData?.isInvoiced ?? null,
            userARef: null,
            userBRef: null,
          };

          delete transformedData.data;
          delete transformedData.targetDetails;
          delete transformedData.dependencyDetails;
          delete transformedData.assignedToDetails;


          setElementData(transformedData);
          dispatch(setSingleElementDetails({
            ...transformedData,
            geo: {
              lat: transformedData?.geo?._lat || null,
              long: transformedData?.geo?._long || null,
            },
            businessId: businessPreference?.id,
            finances: transformedData.finances,
          }));
          setErrorDoc(false);
          // Consider delaying setIsLoading(false) until getDocument also finishes its first run
          // setIsLoading(false); // Moved potential loading end to getDocument
        } else {
          setErrorDoc(true);
          setIsLoading(false);
          setElementData(null);
          dispatch(setSingleElementDetails(null));
        }
      },
      (error) => {
        console.error('Error in real-time data fetching:', error);
        setErrorDoc(true);
        setIsLoading(false);
        dispatch(setGeneralStatus({ status: 'error', error: error }));
      }
    );

    return () => unsubscribe();

  }, [
    elementId,
    structureId,
    moduleName,
    businessPreference?.id,
    dispatch,
    getElementPath,
    elementData?.id
  ]);


  // Initial document fetch trigger after elementData is populated by listener
  useEffect(() => {
    // Only fetch if elementData is loaded and matches the current elementId
    if (elementData?.id === elementId) {
      getDocument();
    }
  }, [elementData?.id, elementId, getDocument]); // Depends on elementData.id and getDocument


  // --- History Management ---
  useEffect(() => {
    if (elementData?.id === elementId && structure?.name) {
      const currentHistoryToAdd = {
        pathname: location.pathname,
        url: location.pathname + location.search,
        name: elementData?.name || elementData?.targetName || `Element ${elementId.substring(0, 5)}`,
        moduleName: structure.name,
        structureId: structureId,
        businessId: businessPreference?.id,
        timeStamp: moment().format('YYYY-MM-DD HH:mm:ss'),
      };

      try {
        let existingHistory = JSON.parse(localStorage.getItem('history') || '[]');
        if (!Array.isArray(existingHistory)) {
          existingHistory = [];
        }

        // Remove previous entries for the *same specific element page*
        existingHistory = existingHistory.filter(
          (entry) => !(entry.pathname === location.pathname && entry.structureId === structureId && entry.businessId === businessPreference?.id)
        );

        const newHistory = [...existingHistory, currentHistoryToAdd];
        // Limit history size
        if (newHistory.length > 15) {
          newHistory.shift();
        }

        localStorage.setItem('history', JSON.stringify(newHistory));
      } catch (error) {
        console.error("Failed to update navigation history:", error);
      }
    }
  }, [elementData, elementId, structureId, structure?.name, businessPreference?.id, location.pathname, location.search]); // Rerun when these change


  // --- Event Handlers ---

  const handleGroupToggle = useCallback((group) => {
    setTabData((prev) => ({
      ...prev,
      groups: prev.groups.includes(group)
        ? prev.groups.filter((g) => g !== group)
        : [...prev.groups, group],
    }));
  }, []);

  // Add Block (Updates transient layout state)
  const handleAddBlock = useCallback((mode) => {
    const isPublic = mode === MODE.PUBLIC;
    const currentLayout = isPublic ? layoutPublic : layout;
    const setLayoutFn = isPublic ? setLayoutPublic : setLayout;
    let newIndex = currentLayout.length;

    //validate if newIndex is not already in use with i
    const isIndexInUse = currentLayout.some((block) => block.i === newIndex.toString())
    if (isIndexInUse) {
      const highestIndex = Math.max(...currentLayout.map((block) => parseInt(block.i)));
      newIndex = highestIndex + 1;
    }

    const newBlock = {
      i: newIndex.toString(),
      x: 0,
      y: 0,
      w: 4,
      h: 4,
      contentType: 'content',
      color: 'white',
      type: 'mainInfo',
      isRestricted: false,
      height: 200,
      index: activeIndex,
      header: { name: t('newBlock') },
      createBtn: false,
      editBtn: false,
      data: [],
    };


    setLayoutFn((prevLayout) => [...prevLayout, newBlock]);
    if (!editMode) setEditMode(true);
  }, [layout, layoutPublic, activeIndex, t, editMode]);

  // Add Tab (Updates accTabs and navigates)
  const handleAddTab = useCallback((mode) => {
    const isPublic = mode === MODE.PUBLIC;
    const currentAccTabs = isPublic ? accTabsPublic : accTabs;
    const setAccTabsFn = isPublic ? setAccTabsPublic : setAccTabs;
    const setNewIndexFn = isPublic ? setNewIndexToCreatePublic : setNewIndexToCreate;
    const baseTabData = { name_en: 'Details', name_fr: 'Détails' };
    if (!isPublic) baseTabData.groups = ['ADMIN']; // Add default group for business

    if (isPublic) {
      setTabDataPublic(baseTabData);
    } else {
      setTabData(baseTabData);
    }

    const newTabIndex = currentAccTabs.length;
    const newTab = {
      name: baseTabData[`name_${currentlangCode}`] || (isPublic ? 'Details' : t('new')),
      groups: isPublic ? [] : ['ADMIN'], // Public tabs don't have groups here?
      tabIndex: newTabIndex,
      blocks: [ // Add a default block
        {
          i: '0', x: 0, y: 0, w: 8, h: 8,
          contentType: 'content', height: 200, structureId: structureId,
          type: 'mainInfo', match: 'id:id', header: { name: t('defaultBlockName') },
          index: newTabIndex, // Ensure block index matches tab index
          data: [] // Initialize data
        },
      ],
      // Add name_en and name_fr for consistency
      name_en: baseTabData.name_en,
      name_fr: baseTabData.name_fr,
    };

    setAccTabsFn((prevTabs) => [...prevTabs, newTab]);
    setNewIndexFn(true); // Flag that this is a new tab creation
    updateTabIndexInURL(newTabIndex); // Navigate to the new tab
    setTabDetailsOpen(true); // Open settings drawer
    setEditMode(true); // Enter edit mode
  }, [accTabs, accTabsPublic, updateTabIndexInURL, currentlangCode, t, structureId]);


  // Save Tab (Add or Update)
  const handleSaveTab = useCallback(() => {
    const mode = isPublicMode ? MODE.PUBLIC : MODE.BUSINESS;
    const isNew = isPublicMode ? newIndexToCreatePublic : newIndexToCreate;
    const currentTabData = isPublicMode ? tabDataPublic : tabData;
    const currentLayout = isPublicMode ? layoutPublic : layout;

    const tabDetails = {
      ['name_' + currentlangCode]: currentTabData[`name_${currentlangCode}`],
      // Include both language names if available
      name_en: currentTabData.name_en,
      name_fr: currentTabData.name_fr,
    };
    if (!isPublicMode) {
      tabDetails.groups = currentTabData.groups?.length > 0 ? currentTabData.groups : ['ADMIN']; // Ensure groups is array
    }

    const dataPayload = {
      tabData: tabDetails,
      tabBlocks: currentLayout,
    };

    updateStructureData(
      mode,
      isNew ? 'addTab' : 'tab', // API endpoint type
      dataPayload,
      'updateLayout'
    );
  }, [
    isPublicMode, newIndexToCreate, newIndexToCreatePublic, tabData, tabDataPublic,
    layout, layoutPublic, currentlangCode, updateStructureData
  ]);


  // Delete Tab
  const handleDeleteTab = useCallback(() => {
    const mode = isPublicMode ? MODE.PUBLIC : MODE.BUSINESS;
    updateStructureData(mode, 'deleteTab', {}, 'deleteTab').then(() => {
      // After successful deletion via API (which calls getDocument), navigate to first tab
      updateTabIndexInURL(0);
      setTabDetailsOpen(false); // Close drawer if open
      // Edit mode is turned off by updateStructureData
    });
  }, [isPublicMode, updateStructureData, updateTabIndexInURL]);

  // Save Layout (Grid Changes)
  const handleSaveLayout = useCallback(() => {
    const mode = isPublicMode ? MODE.PUBLIC : MODE.BUSINESS;
    const currentLayout = isPublicMode ? layoutPublic : layout;
    updateStructureData(
      mode,
      'blocks-position',
      { blocksData: currentLayout },
      'updateBlock-Info'
    );
  }, [isPublicMode, layout, layoutPublic, updateStructureData]);



  // Toggle Edit Mode & Save/Cancel Logic
  const handleToggleEditMode = useCallback((blockAdded) => {
    if (editMode) {
      const isTabSettingsOpen = tabDetailsOpen;
      const isNewTab = isPublicMode ? newIndexToCreatePublic : newIndexToCreate;
      if ((isTabSettingsOpen || isNewTab) && !blockAdded) {
        handleSaveTab();
      } else {
        handleSaveLayout();
      }
    } else {
      setEditMode(true);
      const currentAccTabs = isPublicMode ? accTabsPublic : accTabs;
      const currentTab = currentAccTabs[activeIndex];
      if (currentTab) {
        if (isPublicMode) {
          setTabDataPublic({
            name_en: currentTab.name_en || '',
            name_fr: currentTab.name_fr || '',
          });
        } else {
          setTabData({
            groups: currentTab.groups || [],
            name_en: currentTab.name_en || '',
            name_fr: currentTab.name_fr || '',
          });
        }
      } else {
        // Reset if somehow the tab doesn't exist (shouldn't happen)
        if (isPublicMode) setTabDataPublic({ name_en: 'Details', name_fr: 'Détails' });
        else setTabData({ groups: ['ADMIN'], name_en: 'Details', name_fr: 'Détails' });
      }
    }
  }, [editMode, isPublicMode, tabDetailsOpen, newIndexToCreate, newIndexToCreatePublic, accTabs, accTabsPublic, activeIndex, handleSaveTab, handleSaveLayout]);

  const handleCancelEdit = useCallback(() => {
    setEditMode(false);
    setTabDetailsOpen(false);
    // Reset transient layout state to match the last saved state (from accTabs)
    const currentAccTabs = isPublicMode ? accTabsPublic : accTabs;
    const savedLayout = currentAccTabs[activeIndex]?.blocks || [];
    if (isPublicMode) {
      setLayoutPublic(savedLayout);
      setNewIndexToCreatePublic(false); // Reset creation flag
    } else {

      setLayout(savedLayout);
      setNewIndexToCreate(false); // Reset creation flag
    }
    // If it was a new tab being created, navigate back or handle appropriately
    if (newIndexToCreate || newIndexToCreatePublic) {
      updateTabIndexInURL(Math.max(0, activeIndex - 1)); // Go back one tab, or to 0
      getDocument();
    }

  }, [isPublicMode, accTabs, accTabsPublic, activeIndex, newIndexToCreate, newIndexToCreatePublic, getDocument, updateTabIndexInURL]);


  const handleSettingsTab = useCallback(() => {
    if (!editMode) setEditMode(true); // Ensure edit mode is on when opening settings
    setTabDetailsOpen((prev) => !prev);
  }, [editMode]); // Added editMode dependency


  const handlePublicAccessToggle = useCallback(async () => {
    const goingToPublic = !isPublicMode;
    setIsPublicMode(goingToPublic);
    setIsLoading(true);
    setEditMode(false);
  }, [isPublicMode]);

  useEffect(() => {
    if (isPublicMode) {
      getDocument();
    }
  }, [isPublicMode, getDocument]);


  // Element Actions (Delete, Edit Drawer, etc.)
  const handleConfirmDelete = useCallback(() => {
    dispatch(
      modalActions.modalConfirmation({
        isOpen: true,
        title: t('deleteElementConfirmationTitle', { elementName: elementData?.name || 'Element' }),
        message: t('deleteElementConfirmationMessage'), // Add a confirmation message key
        confirmLabel: t('delete'),
        subConfirmLabel: (moduleName === 'articles' || moduleName === 'services' || moduleName === 'grids' || moduleName === 'profiles' || moduleName === 'passes' || moduleName === 'contacts') ? t('desactivate') : undefined, // Use undefined instead of null
        handleConfirm: () => handleElementDelete('delete'),
        handleConfirmSub: (moduleName === 'articles' || moduleName === 'services' || moduleName === 'grids' || moduleName === 'profiles' || moduleName === 'passes' || moduleName === 'contacts') ? () => handleElementDelete('desactivate') : undefined,
      })
    );
  }, [dispatch, t, elementData?.name, moduleName]); // Added elementData?.name dependency

  const navigateBack = useCallback(() => {
    try {
      const historyRaw = localStorage.getItem('history');
      const history = historyRaw ? JSON.parse(historyRaw) : [];
      // Find the last entry that is *not* the current page
      const previousPage = history.reverse().find(entry => entry.url !== (location.pathname + location.search));

      if (previousPage?.url) {
        navigate(previousPage.url);
      } else {
        // Fallback if no suitable history found (e.g., go to module list)
        navigate(`/app/list/${moduleName}/${structureId}`); // Adjust fallback as needed
      }
    } catch (error) {
      console.error("Error navigating back:", error);
      navigate(`/app/list/${moduleName}/${structureId}`); // Fallback on error
    }
  }, [navigate, location.pathname, location.search, moduleName, structureId]);


  const handleElementDelete = useCallback(async (type) => {
    const pathToDelete = elementData?.documentPath;
    if (!pathToDelete) {
      toast.error(t('cannotDeleteElementNoPath'));
      return;
    }

    dispatch(modalActions.modalConfirmation({ isOpen: false }));
    dispatch(setGeneralStatus({ status: 'loading', position: 'deletesub-element', type: 'pulse' }));

    try {
      if (moduleName === 'contacts') {
        const targetId = elementId?.split(businessPreference?.id)[0];
        await nodeAxiosFirebase({
          t, method: 'POST', url: 'deleteUser',
          errorToast: t('makesureUserDelete'),
          body: { type: type, userId: targetId },
        });
      } else {
        const parts = pathToDelete.split('/');
        const elementIdFromPath = parts.pop();
        const collectionPath = parts.join('/');

        if (elementIdFromPath !== elementId) {
          console.warn("Element ID mismatch in delete path", elementId, elementIdFromPath);
        }

        await nodeAxiosFirebase({
          t, method: 'DELETE', url: 'coreSeqV3',
          errorToast: t('makesureElementsDelete'),
          body: {
            type: type,
            elementPath: collectionPath,
            elementId: elementId,
          },
        });
      }


      setTimeout(() => {
        dispatch(setGeneralStatus({ status: 'success', position: 'deletesub-element', type: 'pulse' }));
        toast.success(t(type === 'delete' ? 'elementDeletedSuccess' : 'elementDesactivatedSuccess'));
        navigateBack();

        try {
          const deletedElements = JSON.parse(sessionStorage.getItem('deletedElements') || '[]');
          if (deletedElements.length >= 10) deletedElements.shift();
          deletedElements.push(elementId);
          sessionStorage.setItem('deletedElements', JSON.stringify(deletedElements));
        } catch (e) { console.error("Failed to update deleted elements in session storage:", e); }
      }, 700);

    } catch (error) {
      console.error('Error deleting element:', error);
      dispatch(setGeneralStatus({ status: 'error', position: 'deletesub-element', type: 'pulse', error: error }));
      // Toast handled by nodeAxiosFirebase or show a generic one here
    }
  }, [dispatch, t, elementData?.documentPath, elementId, moduleName, businessPreference?.id, navigateBack]);

  const handleOpenEditDrawer = useCallback(() => {
    if (!elementData) return;
    dispatch(
      drawerActions.viewElement({
        isDrawerOpen: true,
        item: elementData, // Pass the full elementData
        handleDrawerClose: () => dispatch(drawerActions.viewElement({ isDrawerOpen: false })), // Simple close action
        type: 'edit',
        // handleUpdate prop might not be needed if drawer uses Redux for updates
      })
    );
  }, [dispatch, elementData]);

  const openEmailModal = useCallback(() => {
    dispatch(modalActions.modalEmail({ isOpen: true, type: pathname?.startsWith('/app/element/card') ? 'card' : '' }));
  }, [dispatch, pathname]);

  const openModalPrint = useCallback(() => {
    if (!elementData) return; // Don't open if no data
    if (moduleName === 'contacts') {
      dispatch(modalActions.modalContact({ isOpen: true, contactData: elementData })); // Pass data if needed
    } else {
      dispatch(modalActions.modalInvoice({ isOpen: true, invoiceData: elementData })); // Pass data if needed
    }
  }, [dispatch, moduleName, elementData]);

  const copyLink = useCallback(() => {
    if (!elementData?.accessCode) {
      toast.info(t('noAccessCodeAvailable'));
      return;
    }
    const link = `${window.location.origin}/redirect/${businessPreference?.id}/${moduleName}/${structureId}/${elementId}?accessCode=${elementData.accessCode}&shared=true`;
    navigator.clipboard.writeText(link)
      .then(() => toast.success(t('linkCopiedSuccess')))
      .catch(err => {
        console.error('Failed to copy link:', err);
        toast.error(t('linkCopiedError'));
      });
  }, [elementData?.accessCode, businessPreference?.id, moduleName, structureId, elementId, t]);


  const handleChangeDate = useCallback(async (event) => {
    const date = event.target.value;
    if (!date || !elementData?.documentPath) return;

    const keyToUpdate = elementData?.invoiceDate === null ? 'targetDate' : 'invoiceDate'; // Assuming invoiceDate being explicitly null means use targetDate

    dispatch(setGeneralStatus({ status: 'loading', position: 'updateDate', type: 'pulse' }));
    try {
      const elementPathParts = elementData.documentPath.split('/');
      elementPathParts.pop(); // Remove ID
      const collectionPath = elementPathParts.join('/');

      await nodeAxiosFirebase({
        t,
        method: 'PATCH',
        url: `coreSeqV3`, // Use generic update endpoint
        body: {
          documentId: elementId,
          elementPath: collectionPath,
          key: keyToUpdate,
          value: date,
        },
      });
      dispatch(setGeneralStatus({ status: 'success', position: 'updateDate', type: 'pulse' }));
      toast.success(t('dateUpdatedSuccess'));
    } catch (error) {
      console.error("Error updating date:", error);
      dispatch(setGeneralStatus({ status: 'error', position: 'updateDate', type: 'pulse', error: error }));
      // Toast likely handled by nodeAxiosFirebase
    }
  }, [dispatch, t, elementId, elementData?.documentPath, elementData?.invoiceDate]);


  // Conversion Modal Handlers
  const handleConvert = useCallback(() => {
    if (!elementData || !structure) return;
    dispatch(
      modalActions.modalConvert({
        isOpen: true,
        title: t('convertElement'),
        structure: structure,
        elementData: elementData,
        // Pass handlers directly
        handleTransform: (convertStructureId, matchedFields) =>
          handleConvertElement(convertStructureId, matchedFields, 'transform'),
        handleDuplicate: (convertStructureId, matchedFields) =>
          handleConvertElement(convertStructureId, matchedFields, 'duplicate'),
      })
    );
  }, [dispatch, t, elementData, structure]); // Added structure


  const handleConvertElement = useCallback(async (convertStructureId, matchedFields, action) => {
    if (!elementData?.documentPath) {
      toast.error(t('cannotConvertElementNoPath'));
      return;
    }

    dispatch(modalActions.modalConvert({ isOpen: false })); // Close modal
    // setIsLoading(true); // Use general status instead
    dispatch(setGeneralStatus({ status: 'loading', position: 'updateElement', type: 'backdrop' })); // Use backdrop for conversion

    try {
      const formattedPathWithOutId = elementData.documentPath.split('/').slice(0, -1).join('/');

      const newData = await nodeAxiosFirebase({
        t,
        method: 'PATCH', // Assuming PATCH or POST for conversion
        url: `coreSeqV3/convert`,
        // showLoading: true, // Handled by setGeneralStatus
        body: {
          action,
          elementPath: formattedPathWithOutId,
          matchedFields,
          elementId,
          convertStructureId,
        },
      });

      dispatch(setGeneralStatus({ status: 'success', position: 'updateElement', type: 'backdrop' }));
      toast.success(t(action === 'transform' ? 'elementConvertedSuccess' : 'elementDuplicatedSuccess'));

      // Navigate to the new/updated element
      if (newData?.collection && newData?.elementId) {
        navigate(
          `/app/element/${newData.collection}/${convertStructureId}/${newData.elementId}`
        );
      }
      else {
        // Fallback or refresh current page if navigation data is missing
        console.warn("Conversion API did not return navigation data, refreshing.");
        getDocument(); // Refresh current view
      }
    } catch (error) {
      console.error(`Error during element ${action}:`, error);
      dispatch(setGeneralStatus({ status: 'error', position: 'updateElement', type: 'backdrop', error: error }));
      // Toast likely handled by nodeAxiosFirebase
    } finally {
      // setIsLoading(false); // Handled by setGeneralStatus
    }
  }, [dispatch, t, elementId, elementData?.documentPath, navigate, getDocument]);


  // --- Memos for Props ---

  const tabsConfig = useMemo(() => (
    (accTabs?.length > 0 ? accTabs : []).map((tab, index) => ({
      label: tab?.name || `${t('tab')} ${index + 1}`, // Default label
      value: index,
    }))
  ), [accTabs, t]);

  const tabsPublicConfig = useMemo(() => (
    (accTabsPublic?.length > 0 ? accTabsPublic : []).map((tab, index) => ({
      label: tab?.name || `${t('tab')} ${index + 1}`, // Default label
      value: index,
    }))
  ), [accTabsPublic, t]);

  const effectiveDate = useMemo(() => {
    const dateSeconds = elementData?.invoiceDate?.seconds || elementData?.invoiceDate?._seconds || elementData?.targetDate?.seconds || elementData?.targetDate?._seconds;
    return dateSeconds ? moment.unix(dateSeconds).format('YYYY-MM-DD') : ''; // Return empty string if no date
  }, [elementData?.invoiceDate, elementData?.targetDate]);


  const mainLayoutActions = useMemo(() => ({
    email: structure?.element?.preferences?.email ? openEmailModal : null,
    // cells prop seems unused in MainLayoutV2 based on provided context, remove if confirmed
    // cells: layout?.[0]?.mainElement?.cellData?.length > 0 ? layout?.[0]?.mainElement?.cellData : null,
    tags: structure?.element?.preferences?.tags ? (elementData?.tags || []) : null,
    share: structure?.element?.preferences?.share ? copyLink : null,
    onChangeDate: (structure?.element?.preferences?.date) ? handleChangeDate : null, // Only allow change if pref is enabled
    print: structure?.element?.preferences?.print ? openModalPrint : null,
    date: (structure?.element?.preferences?.date) ? effectiveDate : null, // Only show date if pref is enabled
    deleteItem: (structure?.element?.preferences?.delete && elementData?.documentPath) ? handleConfirmDelete : null,
    editLayout: {
      action: (structure) ? () => handleToggleEditMode() : null, // Main toggle/save button
      display: editMode, // Controls visibility/state of edit buttons
      addBlock: (editMode && !isPublicMode && tabsConfig.length > 0) ? () => handleAddBlock(MODE.BUSINESS) : null,
      displaySettings: (editMode && (isPublicMode ? tabsPublicConfig.length > 0 : tabsConfig.length > 0)) ? handleSettingsTab : null, // Only show if tabs exist
      addTab: (!isPublicMode) ? () => handleAddTab(MODE.BUSINESS) : null,
      actionPublic: (editMode && isPublicMode && tabsPublicConfig.length > 0) ? () => handleToggleEditMode(MODE.PUBLIC) : null, // Assuming this is addBlockPublic
      actionCancel: editMode ? handleCancelEdit : null, // Show cancel only in edit mode
      displayPublic: editMode && isPublicMode, // Controls visibility of public-specific edit actions
      publicAccess: isPublicMode, // State of the public access toggle
      addBlockPublic: (editMode && isPublicMode && tabsPublicConfig.length > 0) ? () => handleAddBlock(MODE.PUBLIC) : null,
      addTabPublic: (editMode && isPublicMode) ? () => handleAddTab(MODE.PUBLIC) : null,
      publicAccessAction: structure?.element?.isPublic ? handlePublicAccessToggle : null,
    },
    edit: structure?.element?.preferences?.edit ? handleOpenEditDrawer : null,
    convert: (structure?.element?.preferences?.convert && elementData?.documentPath?.split('/')?.length === 2)
      ? {
        action: (!elementData?.isInvoiced && elementData?.finances) ? handleConvert : null,
        tooltip: t('convertToInvoice'),
      }
      : null,
  }), [
    structure?.element?.preferences, elementData, effectiveDate, editMode, isPublicMode,
    tabsConfig.length, tabsPublicConfig.length,
    openEmailModal, copyLink, handleChangeDate, openModalPrint, handleConfirmDelete,
    handleToggleEditMode, handleAddBlock, handleSettingsTab, handleAddTab, handleCancelEdit,
    handlePublicAccessToggle, handleOpenEditDrawer, handleConvert, t
  ]);

  const pageTitle = elementData?.name || elementData?.targetName || '';
  const sectionTitle = elementData?.isInvoiced
    ? ((elementData?.finances?.balance ?? 0) / 10000)?.toFixed(2) + ' $'
    : elementData?.searchId?.toUpperCase() || elementData?.targetReference || '';

  // --- Render ---
  if (errorDoc && !isLoading && !elementData) {
    // Show a specific error state if loading finished, there's an error, and no data
    return (
      <MainLayoutV2 error404={true} pageTitle={t('error')} elementId={elementId}>
        <Paper sx={{ p: 3, m: 2, textAlign: 'center' }}>
          <GeneralText text={t('errorLoadingDocument')} variant="h6" />
          <GeneralText text={t('errorLoadingDocumentSuggestion')} />
          <Button label={t('goBack')} onClick={navigateBack} sx={{ mt: 2 }} />
        </Paper>
      </MainLayoutV2>
    );
  }


  return (
    <MainLayoutV2
      actions={mainLayoutActions}
      error404={errorDoc && !elementData} // Show 404 if error and no data exists
      pageTitle={pageTitle}
      elementId={elementId}
      formatedPath={elementData?.documentPath?.substring(0, elementData.documentPath.lastIndexOf('/')) || ''}
      sectionTitle={sectionTitle}
      selectedTab={activeIndex} // Pass selectedTab directly
      tabs={isPublicMode ? tabsPublicConfig : tabsConfig}
      manualIndex={activeIndex} // Keep if MainLayoutV2 specifically needs manualIndex
    >

      {currentStatus?.position === 'deletesub-element' && currentStatus?.status === 'loading' && <Loading type="backdrop" />}

      {/* Use isLoading OR check if elementData hasn't loaded yet for the skeleton */}
      {isLoading || !elementData || elementData.id !== elementId ? (
        <div className="d-flex p-3">
          {/* Skeleton Layout - Consider simplifying or using a dedicated Skeleton component */}
          <div className="col-4"><Skeleton variant="rectangular" width="100%" height={'85vh'} sx={{ borderRadius: '12px' }} /></div>
          <div className="col-5" style={{ paddingLeft: '10px' }}><Skeleton variant="rectangular" width="100%" height={'85vh'} sx={{ borderRadius: '12px' }} /></div>
          <div className="col-3" style={{ paddingLeft: '10px' }}><Skeleton variant="rectangular" width="100%" height={'85vh'} sx={{ borderRadius: '12px' }} /></div>
        </div>
      ) : (
        <div style={{ width: '100%', height: '100%' /* Ensure container takes height */ }}>
          <ElementDetailsContent
            elementId={elementId}
            layout={isPublicMode ? layoutPublic : layout}
            setLayout={isPublicMode ? setLayoutPublic : setLayout}
            publicMode={false}
            editMode={editMode}
            fromList={false}
            activeIndex={activeIndex}
            refreshDoc={handleRefreshDoc}
            elementContext={{
              ...elementData,
              id: elementData.id,
              documentPath: elementData.documentPath,
              name: elementData.name,
              targetId: elementData.targetId,
              targetProfileId: elementData.targetProfileId,
              dependencyId: elementData.dependencyId,
            }}
            structureId={structureId}
            moduleName={moduleName}
          />

          {/* Tab Settings Drawer */}
          <Drawer
            open={tabDetailsOpen && editMode} // Only open in edit mode
            onClose={() => setTabDetailsOpen(false)}
            anchor='right'
          >
            <Paper elevation={0} sx={{ width: '320px', padding: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
              <GeneralText primary={true} size="bold" fontSize="14px" text={t('tabSettings')} classNameComponent="mb-3 px-2 text-center" />
              <Divider component="div" sx={{ mb: 2 }} />

              <div style={{ flexGrow: 1, overflowY: 'auto', padding: '0 16px' }}>
                <TextField
                  label={t('tabName')}
                  fullWidth
                  value={isPublicMode ? tabDataPublic[`name_${currentlangCode}`] : tabData[`name_${currentlangCode}`]}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (isPublicMode) {
                      setTabDataPublic(prev => ({ ...prev, [`name_${currentlangCode}`]: value }));
                    } else {
                      setTabData(prev => ({ ...prev, [`name_${currentlangCode}`]: value }));
                    }
                  }}
                  sx={{ mb: 2 }}
                />
                {/* Optionally add inputs for other languages (name_en, name_fr) */}

                {!isPublicMode && (
                  <>
                    <GeneralText primary={true} size="bold" fontSize="10px" text={t('groups')} classNameComponent="mb-1 mt-3" />
                    <Divider component="div" sx={{ mb: 1 }} />
                    {groupsList?.length > 0 ? groupsList.map((group) => (
                      <FormControlLabel
                        key={group}
                        control={
                          <Checkbox
                            checked={tabData?.groups?.includes(group)}
                            onChange={() => handleGroupToggle(group)}
                            size="small"
                          />
                        }
                        label={businessPreference?.groups?.find((g) => g?.identifiant === group)?.name || group}
                        sx={{ display: 'block', mb: -1 }} // Stack checkboxes vertically
                      />
                    )) : <GeneralText text={t('noGroupsDefined')} fontSize="12px" />}
                  </>
                )}
              </div>

              <Divider component="div" sx={{ mt: 2, mb: 2 }} />

              <div className='px-2'>
                <Button
                  label={t('saveChanges')} // Use a more specific label
                  onClick={handleSaveTab} // Use specific save handler
                  fullWidth
                  disabled={currentStatus?.position === 'updateLayout' && currentStatus?.status === 'loading'} // Disable while saving
                  loading={currentStatus?.position === 'updateLayout' && currentStatus?.status === 'loading'}
                />
              </div>
              {/* Show delete button only for existing tabs */}
              {!(isPublicMode ? newIndexToCreatePublic : newIndexToCreate) && (
                <div className='px-3 mt-3'>
                  <Button
                    size='small'
                    variant='text'
                    noShadow
                    label={t('deleteTab')}
                    color='error' // Use theme error color
                    onClick={handleDeleteTab}
                    fullWidth
                    disabled={currentStatus?.position === 'deleteTab' && currentStatus?.status === 'loading'}
                    loading={currentStatus?.position === 'deleteTab' && currentStatus?.status === 'loading'}
                  />
                </div>
              )}
            </Paper>
          </Drawer>
        </div>
      )}
    </MainLayoutV2>
  );
};

export default ElementDetails;