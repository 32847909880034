import React, { useState } from 'react';
import chroma from 'chroma-js';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import Typo from '../Typo';
import Kanban from '../dynamicSVG/Kanban';
import Liste from '../dynamicSVG/Liste';
import Statuts from '../dynamicSVG/Statuts';
import Grille from '../dynamicSVG/Grille';
import Calendar from '../dynamicSVG/Calendar';
import { useMediaQuery } from '@mui/material';

const HeaderModules = ({ translationCode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';

  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const secColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const lightMainColor = chroma(mainColor + '30')
    .brighten(2.2)
    .hex();
  const darkMainColor = chroma(secColor).darken(2.2).hex();

  const [activeTab, setActiveTab] = useState(0);

  const tabContent = [
    {
      label: 'list',
      img: (
        <Liste
          mainColor={mainColor}
          secColor={secColor}
          lightMainColor={lightMainColor}
          darkMainColor={darkMainColor}
          isMobile={isMobile}
        />
      ),
    },
    {
      label: 'kanban',
      img: (
        <Kanban
          mainColor={mainColor}
          secColor={secColor}
          lightMainColor={lightMainColor}
          darkMainColor={darkMainColor}
          isMobile={isMobile}
        />
      ),
    },
    {
      label: 'calendar',
      img: (
        <Calendar
          mainColor={mainColor}
          secColor={secColor}
          lightMainColor={lightMainColor}
          darkMainColor={darkMainColor}
          isMobile={isMobile}
        />
      ),
    },
    {
      label: 'grid',
      img: (
        <Grille
          mainColor={mainColor}
          secColor={secColor}
          lightMainColor={lightMainColor}
          darkMainColor={darkMainColor}
          isMobile={isMobile}
        />
      ),
    },
    {
      label: 'status',
      img: (
        <Statuts
          mainColor={mainColor}
          secColor={secColor}
          lightMainColor={lightMainColor}
          darkMainColor={darkMainColor}
          isMobile={isMobile}
        />
      ),
    },
  ];

  return (
    <div style={{ marginTop: '80px' }} className="container">
      <div
        style={{
          backgroundColor: isDarkMode ? '#33333380' : '#FFFFFF',
          borderRadius: '20px',
          padding: '20px',
          height: isMobile ? '400px' : '800px',
        }}
        className="py-4"
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            gap={1}
            px={2}
            py={1}
            borderRadius="20px"
            border={isDarkMode ? '1px solid #FFFFFF80' : '1px solid #2222221a'}
          >
            <Typography
              variant="body2"
              fontWeight="medium"
              textAlign="center"
              color={isDarkMode ? '#FFFFFF' : '#222222'}
              sx={{ fontFamily: "'Montserrat', Helvetica" }}
            >
              {t(translationCode + 'Chip')}
            </Typography>
          </Box>

          <Typo
            text={t(translationCode + 'Top')}
            variant="h2"
            className="align-c mt-3"
          />
        </Box>

        <div className="mt-5 px-5">
          <Box display="flex" justifyContent="center" mt={5}>
            <Tabs
              value={activeTab}
              onChange={(event, newValue) => setActiveTab(newValue)}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              sx={{
                borderBottom: 'none',
                minHeight: '38px',
                maxHeight: '38px',
                height: '38px',
                justifyContent: 'flex-start',
                '.MuiTabs-flexContainer': {
                  justifyContent: 'flex-start',
                },
                '.MuiTabs-indicator': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {['list', 'kanban', 'calendar', 'grid', 'status'].map(
                (tab, index) => (
                  <Tab
                    key={tab}
                    label={t(tab)}
                    disableRipple
                    sx={{
                      fontSize: { xs: '12px', sm: '14px', md: '16px' },
                      padding: { xs: '4px 8px', sm: '5px 5px' },
                      minWidth: { xs: '60px', sm: '200px' },
                      fontWeight: activeTab === index ? 'bold' : 'normal',
                      color: activeTab === index ? mainColor : 'black',
                      textTransform: 'none',
                      display: 'flex',
                      minHeight: '30px',
                      maxHeight: '30px',
                      height: '30px',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      position: 'relative',
                      '&.Mui-selected': {
                        color: mainColor,
                        borderBottom: '2px solid',
                        width: '175px',
                      },
                      '&::after':
                        activeTab === index
                          ? {
                              content: '""',
                              position: 'absolute',
                              bottom: '0px',
                              left: '0',
                              width: '50%',
                              height: '3px',
                              borderRadius: '2px',
                            }
                          : {},
                    }}
                  />
                )
              )}
            </Tabs>
          </Box>

          <Box
            mt={isMobile ? 0 : 3}
            sx={{ marginLeft: isMobile ? '-40px' : '' }}
            textAlign="center"
          >
            {tabContent[activeTab]?.img}
          </Box>
        </div>
      </div>
    </div>
  );
};

export default HeaderModules;
