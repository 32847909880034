import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@emotion/react';

// Create a keyframes animation that moves the background from bottom to top
const moveDots = keyframes`
  from {
    background-position: 0 100%;
  }
  to {
    background-position: 0 -100%;
  }
`;

const RandomDotsBackground = ({
  dotColor = 'rgba(255,255,255,0.2)',
  dotSize = 1.4,
  tileSize = 1000,
  density = 0.003,
  animationDuration = '30s',
}) => {
  // Generate a data URL for the pattern only once using useMemo
  const patternDataUrl = useMemo(() => {
    const canvas = document.createElement('canvas');
    canvas.width = tileSize;
    canvas.height = tileSize;
    const ctx = canvas.getContext('2d');

    // Clear canvas (transparent background)
    ctx.clearRect(0, 0, tileSize, tileSize);

    // Calculate number of dots based on tile area and density
    const numberOfDots = Math.floor(tileSize * tileSize * density);

    for (let i = 0; i < numberOfDots; i++) {
      const x = Math.random() * tileSize;
      const y = Math.random() * tileSize;
      ctx.beginPath();
      ctx.arc(x, y, dotSize, 0, 2 * Math.PI);
      ctx.fillStyle = dotColor;
      ctx.fill();
    }
    return canvas.toDataURL();
  }, [dotColor, dotSize, tileSize, density]);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${patternDataUrl})`,
        backgroundRepeat: 'repeat',
        backgroundSize: `${tileSize}px ${tileSize}px`,
        animation: `${moveDots} ${animationDuration} linear infinite`,
        zIndex: 0,
        pointerEvents: 'none',
      }}
    />
  );
};

export default RandomDotsBackground;
