// Utilities
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import * as drawerActions from '../../redux/actions-v2/drawer-actions';
// Components
import Block from '../../stories/layout-components/Block';
import { Typography } from '@mui/material';
import FinancesTransactionsGrid from '../../components/Grids/FinancesTransactionsGrid';
import FieldsPopper from '../../components/@generalComponents/FieldsPopper';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import defaultFieldsResolver from '../../utils/defaultFieldsResolver';

const Grid = ({
  activeIndex,
  selectedFacet,
  setSelectedFacet,
  startTimestamp,
  endTimestamp,
  handleDisplaySide,
  displaySide,
  activeModule,
  list,
  tab,
  refreshData
}) => {
  // Initialize hooks and states
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const dispatch = useDispatch();

  const [itemsPerStatus, setItemsPerStatus] = useState({});
  const [value, setValue] = useState(0);
  const [fields, setFields] = useState([]);
  const [columns, setColumns] = useState([]);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [activeColumn, setActiveColumn] = useState(null);
  const [submenuAnchor, setSubmenuAnchor] = useState(null);
  const [fieldsSubmenu, setFieldsSubmenu] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { moduleName, structureId } = useParams();

  const facetKey = searchParams.get('facetKey') || null;
  const facet =
    facetKey === 'status'
      ? parseInt(searchParams.get('facet'))
      : searchParams.get('facet') === 'true'
        ? true
        : searchParams.get('facet') === 'false'
          ? false
          : searchParams.get('facet') || null;

  const handleCardClick = (card) => {
    handleQuickview(card);
  };

  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );

  const structure = businessStructure?.structures?.find(
    (s) => s.id === structureId
  );


  const businessPreferences = useSelector((state) => state.core.businessData);

  const currentCollection = useSelector(
    (state) => state.list.currentCollection
  );

  const nameStatus0 = businessStructure?.structures
    ?.find((s) => s.id === structureId)
    ?.fields?.find((f) => f.typeData === 'status')
    ?.selections?.find((s) => s.value === 0)?.['label_' + currentLang];

  const nameStatus1 = businessStructure?.structures
    ?.find((s) => s.id === structureId)
    ?.fields?.find((f) => f.typeData === 'status')
    ?.selections?.find((s) => s.value === 1)?.['label_' + currentLang];

  const nameStatus2 = businessStructure?.structures
    ?.find((s) => s.id === structureId)
    ?.fields?.find((f) => f.typeData === 'status')
    ?.selections?.find((s) => s.value === 2)?.['label_' + currentLang];

  const colorStatus0 = businessStructure?.structures
    ?.find((s) => s.id === structureId)
    ?.fields?.find((f) => f.typeData === 'status')
    ?.selections?.find((s) => s.value === 0)?.color;

  const colorStatus1 = businessStructure?.structures
    ?.find((s) => s.id === structureId)
    ?.fields?.find((f) => f.typeData === 'status')
    ?.selections?.find((s) => s.value === 1)?.color;

  const colorStatus2 = businessStructure?.structures
    ?.find((s) => s.id === structureId)
    ?.fields?.find((f) => f.typeData === 'status')
    ?.selections?.find((s) => s.value === 2)?.color;

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setActiveColumn(null);
    setSubmenuAnchor(null);
  };

  const handleUpdateFields = async (newField, action, subNewField) => {
    try {
      const currentColumns = header?.map((col, idx) => ({
        width: columnWidths[idx],
        value: col.value,
      }));

      let updatedColumns;

      if (action === 'add' && newField?.value) {
        updatedColumns = [
          ...currentColumns,
          {
            width: 20,
            structureValue:
              newField.value === 'targetId'
                ? 'targetName'
                : newField.value === 'targetProfileId'
                  ? 'targetProfileName'
                  : newField.value,
            type: newField?.typeData,
            action: 'none',
          },
        ];
      }

      if (action === 'delete') {
        updatedColumns = currentColumns.filter((col) => col.value !== newField);
      }

      if (action === 'add-sub') {
        const activeCol = currentColumns.find((col) => col.value === newField);

        if (activeCol) {
          updatedColumns = currentColumns.map((col) => {
            if (col?.value === newField) {
              return {
                ...col,
                sub: {
                  value: subNewField?.value,
                },
              };
            } else {
              return col;
            }
          });
        }
      }
      handleMenuClose();
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/structure`,
        body: {
          moduleId: activeModule?.id,
          type: 'fields',
          data: {
            tabIndex: activeIndex || 0,
            fieldsData: updatedColumns,
          },
        },
      });

      refreshData();
    } catch (error) {
      console.error('Error updating column widths:', error);
    }
  };

  const header = displaySide
    ? currentCollection?.[moduleName]?.header?.slice(0, 4)
    : currentCollection?.[moduleName]?.header;

  const [columnWidths, setColumnWidths] = useState(
    header?.map((col) => col.width)
  );

  useEffect(() => {
    setColumns(header);
  }, [header]);

  useEffect(() => {
    setColumnWidths(header?.map((col) => col.width));
  }, [header]);

  useEffect(() => {
    if (structure) {
      const defaultFields = defaultFieldsResolver(structure.collectionField, t);
      const structureFields = structure?.fields || [];
      const uniqueFieldMap = new Map();

      structureFields.forEach((field) => {
        if (!uniqueFieldMap.has(field.value)) {
          uniqueFieldMap.set(field.value, field);
        }
      });

      defaultFields.forEach((field) => {
        if (!uniqueFieldMap.has(field.value)) {
          uniqueFieldMap.set(field.value, field);
        }
      });

      const fieldToCatch = Array.from(uniqueFieldMap.values());
      setFields(fieldToCatch);

      const filteredSubField = fieldToCatch.filter(
        (field) =>
          field?.allowSub !== false &&
          field?.typeData !== 'status' &&
          field?.typeData !== 'search' &&
          field?.typeData !== 'hook'
      );

      setFieldsSubmenu(filteredSubField);
    }
  }, [structure]);


  useEffect(() => {
    if (list?.length > 0) {
      const groupedData = list.reduce((acc, item) => {
        const transactionType = Number(item?.type || item?.status) || 0;
        const groupKey = `status${transactionType}`;

        if (!acc[groupKey]) {
          acc[groupKey] = {
            name: groupKey,
            count: 0,
            total: 0,
          };
        }

        acc[groupKey].count += 1;
        acc[groupKey].total += Number(
          (item?.finances?.amount ||
            item?.amount ||
            item?.subtotal ||
            item?.finances?.subtotal ||
            item?.finances?.unitPrice ||
            item?.unitPrice ||
            item?.total ||
            item?.finances?.total ||
            0) / 10000
        );

        return acc;
      }, {});

      // Adding total length and grouping information to the state
      setItemsPerStatus({ totalLength: list.length, ...groupedData });
    }
  }, [list, moduleName]);

  useEffect(() => {
    if (list?.length > 0) {
      const totalValue = list?.reduce((acc, item) => {
        return (
          acc +
          Number(
            (item?.finances?.amount ||
              item?.amount ||
              item?.subtotal ||
              item?.finances?.subtotal ||
              item?.finances?.unitPrice ||
              item?.unitPrice ||
              item?.total ||
              item?.finances?.total ||
              0) / 10000
          )
        );
      }, 0);

      setValue(totalValue);
    }
  }, [list]);

  const currentUser = useSelector((state) => state.core.user);

  const applyStatusFilter = (value) => {
    const key = activeModule?.list?.tabs?.[activeIndex]?.filterTop || 'status';

    setSelectedFacet({ key, value });
  };

  const formatCanadianDollar = (amount) => {
    return new Intl.NumberFormat('fr-CA', {
      style: 'currency',
      currency: 'CAD',
    })?.format(amount);
  };

  const handleClose = () => {
    dispatch(drawerActions.viewElement({ isDrawerOpen: false }));
  };

  const handleQuickview = (item) => {
    dispatch(
      drawerActions.viewElement({
        isDrawerOpen: true,
        item: item,
        handleDrawerClose: handleClose,
        type: 'view',
      })
    );
  };

  return (
    <div style={{ paddingRight: '10px' }} className={'col-12'}>
      <div className="col-12 d-flex">
        <div className="col-2 hover" onClick={() => applyStatusFilter(0)}>
          <div
            style={{
              borderBottom: facet === 0 ? `3px solid ${colorStatus0}` : '',
            }}
          >
            <Block noScroll height={2} heightPercentage={9}>
              {' '}
              <Typography variant="h3" fontSize="24px" fontWeight={600}>
                {formatCanadianDollar(itemsPerStatus?.status0?.total || 0)}
              </Typography>
              <Typography variant="body2" fontSize="12px" fontWeight={500}>
                {nameStatus0}
              </Typography>
            </Block>
          </div>
        </div>
        <div className="col-2 hover" onClick={() => applyStatusFilter(1)}>
          <div
            style={{
              borderBottom: facet === 1 ? `3px solid ${colorStatus1}` : '',
            }}
          >
            <Block noScroll height={2} heightPercentage={9}>
              <Typography variant="h3" fontSize="24px" fontWeight={600}>
                {formatCanadianDollar(itemsPerStatus?.status1?.total || 0)}
              </Typography>
              <Typography variant="body2" fontSize="12px" fontWeight={500}>
                {nameStatus1}
              </Typography>
            </Block>
          </div>
        </div>
        <div className="col-2 hover" onClick={() => applyStatusFilter(2)}>
          <div
            style={{
              borderBottom: facet === 2 ? `3px solid ${colorStatus2}` : '',
            }}
          >
            <Block noScroll height={2} heightPercentage={9}>
              {' '}
              <Typography variant="h3" fontSize="24px" fontWeight={600}>
                {formatCanadianDollar(itemsPerStatus?.status2?.total || 0)}
              </Typography>
              <Typography variant="body2" fontSize="12px" fontWeight={500}>
                {nameStatus2}
              </Typography>
            </Block>
          </div>
        </div>
        <div className="col-3 hover" onClick={() => setSelectedFacet(null)}>
          <Block noScroll height={2} heightPercentage={9}>
            {' '}
            <Typography variant="h3" fontSize="24px" fontWeight={600}>
              {itemsPerStatus?.totalLength || 0}
            </Typography>
            <Typography variant="body2" fontSize="12px" fontWeight={500}>
              {t('number')}
            </Typography>
          </Block>
        </div>

        <div className="col-3">
          <Block noScroll height={2} heightPercentage={9}>
            {' '}
            <Typography
              variant="h3"
              fontSize="24px"
              fontWeight={600}
              color={businessPreferences?.secColor}
            >
              {formatCanadianDollar(value || 0)}
            </Typography>
            <Typography
              variant="body2"
              fontSize="12px"
              fontWeight={500}
              color={businessPreferences?.secColor}
            >
              {t('total')}
            </Typography>
          </Block>
        </div>
      </div>
      <div>
        <Block height={2} heightPercentage={82} className="col-12">
          <div style={{ position: 'absolute', right: 0, zIndex: 9999 }}>
            {currentUser?.activeBusiness?.role !== 'EMPLOYEE' && currentUser?.activeBusiness?.role !== 'VIEWER' && (
              <FieldsPopper
                onSelect={(fieldsData) => handleUpdateFields(fieldsData, 'add')}
                fields={fields}
              />)}
          </div>
          <FinancesTransactionsGrid
            list={list}
            onRowSelected={handleCardClick}
            activeModule={moduleName}
          />
        </Block>
      </div>
    </div>
  );
};

export default Grid;
