import * as React from 'react';
const Content = ({ color }) => (
  <svg
    id="Calque_2-9887327"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 6774.03 7487.5"
  >
    <defs>
      <style>
        {'.cls-1{fill:#c1c1c1;}.cls-2{fill:#e5e5e5;}.cls-3{fill:#676767;}'}
      </style>
    </defs>
    <g id="Calque_1-2-2345">
      <rect
        className="cls-2"
        x={0}
        width={6774.03}
        height={7487.5}
        rx={293.09}
        ry={293.09}
      />
      <rect
        fill={color}
        x={618.5}
        y={809.94}
        width={4853.53}
        height={839.39}
        rx={218.04}
        ry={218.04}
      />
      <rect
        className="cls-1"
        x={618.5}
        y={1855.5}
        width={3159.05}
        height={513.75}
        rx={218.04}
        ry={218.04}
      />
      <rect
        fill={color}
        x={618.5}
        y={2949.2}
        width={5345.59}
        height={839.39}
        rx={218.04}
        ry={218.04}
      />
      <rect
        className="cls-1"
        x={618.5}
        y={3994.76}
        width={1442.24}
        height={513.75}
        rx={218.04}
        ry={218.04}
      />
      <rect
        fill={color}
        x={544.87}
        y={5088.47}
        width={4045.2}
        height={839.39}
        rx={218.04}
        ry={218.04}
      />
      <rect
        className="cls-1"
        x={544.87}
        y={6134.03}
        width={2444.54}
        height={513.75}
        rx={218.04}
        ry={218.04}
      />
    </g>
  </svg>
);
export default Content;
