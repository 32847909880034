import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  collection,
  query,
  onSnapshot,
  addDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { db, storage } from '../../firebase.js';
import debounce from 'lodash.debounce';
import MainLayoutV2 from '../../layouts/MainLayoutV2.jsx';
import { Typography, Button, TextField, Select, MenuItem, FormControl, InputLabel, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControlLabel, Tabs, Tab, Grid, ListItem, ListItemText, ListItemButton, Box, Divider } from '@mui/material';
import Editor from '@monaco-editor/react'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { CloudUpload as CloudUploadIcon, StarOutlined } from '@mui/icons-material';
import Blocks from '../../stories/layout-components/Block.jsx';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import FieldEditor from '../../components/@generalComponents/FieldEditor.jsx';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase.js';



const MarketPlaceNode = () => {
  const { t } = useTranslation();
  const [listing, setListing] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [structures, setStructures] = useState([]);
  const [modules, setModules] = useState([]);
  const [selectedStructures, setSelectedStructures] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [nodeScriptCode, setNodeScriptCode] = useState('');
  const [scriptSyntaxError, setScriptSyntaxError] = useState('');
  const [isScriptValid, setIsScriptValid] = useState(true);
  const editorRef = useRef(null); // Référence à l'instance de l'éditeur Monaco
  const monacoRef = useRef(null);

  const [formValues, setFormValues] = useState({
    title: '',
    description: '',
    type: '',
    isActive: false,
    logoUrl: null,
    bestSeller: false,
    author: '',
    votes: 0,
    rating: 0,
    ownerId: '',
    requiredParams: [],
    collectionFields: [],
    moduleColor: '#000000',
    passwordRequired: false,
    permissions: [],
    basePrice: 0,
    monthlyFee: 0,
    feePerElement: false,
    releaseDate: null,
    fields: {},
    triggers: {
      manual: false,
      onCreate: false,
      onUpdate: false,
      onDelete: false,
      inboundCatch: false,
    },
  });
  const [imageUpload, setImageUpload] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [logoPreview, setLogoPreview] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const [availableParams, setAvailableParams] = useState([
    { id: 'apiKey', name: 'Api Key' },
    { id: 'moduleId', name: 'Module ID' },
    { id: 'structureId', name: 'Structure ID' },
  ]);
  const [selectedParams, setSelectedParams] = useState([]);


  const [permissionOptions, setPermissionOptions] = useState([
    { value: 'readStructure', label: t('readStructure') },
    { value: 'readModule', label: t('readModule') },
    { value: 'readEmployees', label: t('readEmployees') },
    { value: 'readUsage', label: t('readUsage') },
    { value: 'businessData', label: t('businessData') },
    { value: 'createElement', label: t('createElement') },
    { value: 'updateElement', label: t('updateElement') },
    { value: 'deleteElement', label: t('deleteElement') },
    { value: 'readElement', label: t('readElement') },
    { value: 'createEmail', label: t('createEmail') },
    { value: 'updateEmail', label: t('updateEmail') },
    { value: 'createLog', label: t('createLog') },
    { value: 'createPDF', label: t('createPDF') },
    { value: 'updatePDF', label: t('updatePDF') },
  ]);

  const validateScriptAPI = useCallback(
    debounce(async (code) => {

      setScriptSyntaxError(null);
      setIsScriptValid(true);
      monacoRef.current?.editor.setModelMarkers(editorRef.current.getModel(), 'nodescript-syntax', []); // Effacer les marqueurs

      if (!code || code.trim() === '') {
        return;
      }
      try {
        const response = await nodeAxiosFirebase({
          t,
          method: 'POST',
          url: 'business/validatecode',
          body: { script: code },

        });

        if (response.isValid) {

        } else {
          const errorData = response
          const errorMessage = errorData?.error || 'Invalid Syntax';
          setScriptSyntaxError(errorMessage);
          setIsScriptValid(false);

          if (monacoRef.current && editorRef.current && errorData?.line) {
            const model = editorRef.current.getModel();
            if (model) {
              monacoRef.current.editor.setModelMarkers(model, 'nodescript-syntax', [
                {
                  startLineNumber: errorData.line,
                  startColumn: errorData.column || 1,
                  endLineNumber: errorData.line,
                  endColumn: (errorData.column || 1) + (errorData.text?.length || 10),
                  message: errorMessage,
                  severity: monacoRef.current.MarkerSeverity.Error
                }
              ]);
            }
          }
        }
      } catch (error) {
        console.error("Validation API call failed:", error);

        setScriptSyntaxError('Validation service unavailable.');
        setIsScriptValid(false);
      }
    }, 750),
    [t]);

  // --- Fonction appelée quand l'éditeur Monaco est prêt ---
  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
    monacoRef.current = monaco;
    if (nodeScriptCode) {
      validateScriptAPI(nodeScriptCode);
    }
  }


  const handleParamMove = (param, direction) => {
    if (direction === 'add') {
      setSelectedParams(prev => [...prev, param]);
      setAvailableParams(prev => prev.filter(p => p.id !== param.id));
    } else if (direction === 'remove') {
      setAvailableParams(prev => [...prev, param]);
      setSelectedParams(prev => prev.filter(p => p.id !== param.id));
    }
  };


  const moduleTypes = [
    { value: 'feature', label: t('feature'), desc: 'Module, tableau de bord, rapport de Node' },
    { value: 'integration', label: t('integration'), desc: 'Données gérées par logiciel externe' },
    { value: 'plugin', label: t('plugin'), desc: 'Automatisme ou exécution automatique' },
    { value: 'account', label: t('account'), desc: 'Communication inter-comptes' },
    { value: 'component', label: t('component'), desc: 'Bloc, champ ou menu personnalisé' },
    { value: 'template', label: t('template'), desc: 'Courriel, PDF ou liste personnalisé' },
  ];

  const collectionFieldOptions = [
    { value: 'contacts', label: t('contacts') },
    { value: 'cardsinvoiced', label: t('cardsinvoiced') },
    { value: 'cardsuninvoiced', label: t('cardsuninvoiced') },
    { value: 'passes', label: t('passes') },
    { value: 'services', label: t('services') },
    { value: 'grids', label: t('grids') },
    { value: 'articles', label: t('articles') },
    { value: 'profiles', label: t('profiles') },
  ];

  const handleGetData = async (ownerId) => {
    try {
      const nodeStructureData = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/admin`,
        errorToast: t('errorLoadingDocument'),
        body: {
          type: 'structures',
          businessId: ownerId || formValues?.ownerId,
        },
      });
      const formattedStructures = nodeStructureData?.data?.map((structure) => ({
        id: structure.id,
        value: structure.id,
        label: structure.name_fr,
        ownerId: ownerId || formValues?.ownerId,
        ...structure
      }));
      setStructures(formattedStructures);


      const nodeModuleData = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/admin`,
        errorToast: t('errorLoadingDocument'),
        body: {
          type: 'modules',
          businessId: ownerId || formValues?.ownerId,
        },
      });

      const formattedModules = nodeModuleData?.data?.map((module) => ({
        id: module.id,
        value: module.id,
        label: module.name_fr,
        ownerId: ownerId || formValues?.ownerId,
        ...module
      }));
      setModules(formattedModules);
    } catch (error) {
      console.error('Error getting node data:', error);
    }
  };

  useEffect(() => {
    const q = query(collection(db, 'marketplace'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const moduleList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setListing(moduleList);
    });
    return () => unsubscribe();
  }, []);

  const handleScriptChange = (newValue) => {
    setNodeScriptCode(newValue);

    validateScriptAPI(newValue);
  };


  // Handle Form Changes
  const handleChange = (event) => {
    const { name, value, checked } = event.target;

    if (name.startsWith('trigger_')) {
      const triggerName = name.replace('trigger_', '');
      setFormValues({
        ...formValues,
        triggers: {
          ...formValues.triggers,
          [triggerName]: checked,
        },
      });
    }
    else {
      setFormValues({
        ...formValues,
        [name]: (name === 'isActive' || name === 'bestSeller' || name === 'passwordRequired' || name === 'feePerElement') ? checked : value,
      });
    }
  };

  // Handle Image Upload
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];
      setImageUpload(img);
      setLogoPreview(URL.createObjectURL(img));
    }
  };


  // Handle Release Date Change
  const handleReleaseDateChange = (date) => {
    setFormValues({ ...formValues, releaseDate: date ? date.toDate() : null });
  };



  // Handle Add/Edit Dialog Open
  const handleOpenDialog = (module = null) => {
    setSelectedModule(module);
    if (module) {
      setFormValues({
        title: module.title,
        description: module.description,
        type: module.type,
        isActive: module.isActive || false,
        logoUrl: module.logoUrl,
        bestSeller: module?.bestSeller || false,
        author: module.author,
        votes: module.votes,
        rating: module.rating,
        ownerId: module?.ownerId?.id || '',
        requiredParams: module.requiredParams || [],
        collectionFields: module.collectionFields || [],
        moduleColor: module.moduleColor || '#000000',
        passwordRequired: module.passwordRequired || false,
        permissions: module.permissions || [],
        basePrice: module.basePrice || 0,
        monthlyFee: module.monthlyFee || 0,
        feePerElement: module.feePerElement || false,
        releaseDate: module.releaseDate ? new Date(module.releaseDate.seconds * 1000) : null,
        fields: module.fields || {},
        triggers: module.triggers || {
          manual: false,
          onCreate: false,
          onUpdate: false,
          onDelete: false,
          inboundCatch: false,
        },

      });
      setNodeScriptCode(module.nodeScriptCode || '');
      setScriptSyntaxError(null); // Reset
      monacoRef.current?.editor.setModelMarkers(editorRef.current?.getModel(), 'nodescript-syntax', []);
      setIsScriptValid(true); // Reset
      setLogoPreview(module.logoUrl);
      setSelectedParams(module.requiredParams || []);
      setAvailableParams(prev => prev.filter(p => !(module.requiredParams || []).some(sp => sp.id === p.id)));
      setSelectedStructures(module.structures || []);
      setSelectedModules(module.modules || []);

      if (module?.ownerId?.id) {
        handleGetData(module?.ownerId?.id);
      }
    } else {
      setFormValues({
        title: '',
        description: '',
        type: '',
        isActive: false,
        logoUrl: null,
        bestSeller: false,
        author: '',
        votes: 0,
        rating: 0,
        ownerId: '',
        requiredParams: [],
        collectionFields: [],
        moduleColor: '#000000',
        passwordRequired: false,
        permissions: [],
        basePrice: 0,
        monthlyFee: 0,
        feePerElement: false,
        releaseDate: null,
        fields: {},
        triggers: {
          manual: false,
          onCreate: false,
          onUpdate: false,
          onDelete: false,
          inboundCatch: false,
        },
      });
      setLogoPreview(null);
      setSelectedParams([]);
      setAvailableParams([
        { id: 'key', name: 'API key' },
        { id: 'moduleId', name: 'Module ID' },
        { id: 'structureId', name: 'Structure ID' },
      ]);
      setSelectedStructures([]);
      setSelectedModules([]);
      setNodeScriptCode('');
      setScriptSyntaxError(null); // Reset
      monacoRef.current?.editor.setModelMarkers(editorRef.current?.getModel(), 'nodescript-syntax', []);
      setIsScriptValid(true); // Reset
    }
    setActiveTab(0);
    setOpenDialog(true);
  };

  // Handle Dialog Close
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedModule(null);
    setFormValues({
      title: '',
      description: '',
      type: '',
      isActive: false,
      logoUrl: null,
      bestSeller: false,
      author: '',
      votes: 0,
      rating: 0,
      ownerId: '',
      requiredParams: [],
      collectionFields: [],
      moduleColor: '#000000',
      passwordRequired: false,
      permissions: [],
      basePrice: 0,
      monthlyFee: 0,
      feePerElement: false,
      releaseDate: null,
      fields: {},
      triggers: {
        manual: false,
        onCreate: false,
        onUpdate: false,
        onDelete: false,
        inboundCatch: false,
      },
    });
    setImageUpload(null);
    setLogoPreview(null);
    setSelectedParams([]);
    setNodeScriptCode('');
    setScriptSyntaxError('');
    setAvailableParams([
      { id: 'apiKey', name: 'apiKey' },
      { id: 'moduleId', name: 'moduleId' },
    ]);
    monacoRef.current?.editor.setModelMarkers(editorRef.current?.getModel(), 'nodescript-syntax', []);
    setSelectedStructures([]);
    setSelectedModules([]);
  };

  // Handle Add/Edit Module
  const handleSaveModule = async () => {
    setIsLoading(true);
    let isValid = true;

    if (!isScriptValid && nodeScriptCode.trim() !== '') {
      alert(t('fixNodeScriptSyntaxError'));
      setIsLoading(false);
      setActiveTab(5);
      return;
    }
    try {
      let logoUrl = formValues.logoUrl;
      if (imageUpload) {
        const imageRef = ref(storage, `marketplace_logos/${imageUpload.name}`);
        const uploadResult = await uploadBytes(imageRef, imageUpload);
        logoUrl = await getDownloadURL(uploadResult.ref);
      }

      let ownerRef = null;


      if (formValues?.ownerId) {
        ownerRef = doc(db, 'businessesOnNode', formValues?.ownerId);
      }

      if (selectedModule) {
        await updateDoc(doc(db, 'marketplace', selectedModule.id), {
          ...formValues,
          votes: parseInt(formValues?.votes || 0),
          rating: parseFloat(formValues?.rating || 0),
          ownerId: ownerRef,
          logoUrl: logoUrl,
          passwordRequired: formValues?.passwordRequired || false,
          requiredParams: selectedParams,
          updatedAt: serverTimestamp(),
          releaseDate: formValues?.releaseDate ? formValues.releaseDate : null,
          fields: formValues?.fields,
          triggers: formValues?.triggers,
          structures: selectedStructures,
          modules: selectedModules,
          nodeScriptCode: nodeScriptCode,
        });
      } else {
        await addDoc(collection(db, 'marketplace'), {
          ...formValues,
          votes: parseInt(formValues?.votes || 0),
          rating: parseFloat(formValues?.rating || 0),
          ownerId: ownerRef,
          logoUrl: logoUrl,
          requiredParams: selectedParams,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          releaseDate: formValues.releaseDate ? formValues.releaseDate : null,
          fields: formValues.fields,
          triggers: formValues.triggers,
          structures: selectedStructures, // Save selected structures
          modules: selectedModules, // Save selected modules
        });
      }
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving module: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle Delete Module
  const handleDeleteModule = async (moduleId) => {
    if (window.confirm(t('confirmDeleteModule'))) {
      try {
        await deleteDoc(doc(db, 'marketplace', moduleId));
      } catch (error) {
        console.error("Error deleting module: ", error);
      }
    }
  };

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  //format on change
  useEffect(() => {
    if (formValues?.type !== 'account') {
      handleChange({ target: { name: 'ownerId', value: '' } });
      handleChange({ target: { name: 'passwordRequired', value: false } });
    }


  }, [formValues?.type]);

  const handleSelectStructure = (value) => {
    setSelectedStructures(value);
  };

  const handleSelectModule = (value) => {
    setSelectedModules(value);
  }

  // Function to update fields based on structure selection
  const handleStructureFieldsChange = (structureId, newFields) => {
    setFormValues(prevFormValues => {
      const newFieldsObject = { ...prevFormValues.fields };
      newFieldsObject[structureId] = newFields;
      return { ...prevFormValues, fields: newFieldsObject };
    });
  };

  return (
    <MainLayoutV2 pageTitle={t('marketplace')} subTitle={'Modules & Plugins'} actions={{ add: () => handleOpenDialog() }} icon='StorefrontOutlined'>
      <Blocks heightPercentage={98}>
        {listing?.map((module) => (
          <div key={module.id} style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '10px', margin: '10px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="row">
              <div className="col-6">
                <img src={module.logoUrl} alt={module.title} style={{ width: '30px', height: '30px', marginRight: '10px', objectFit: 'contain' }} />
                <Typography variant="h6" fontSize='14px' fontWeight='medium'>{module?.title}</Typography>
                <Typography lineHeight='1.1' variant="body2">{module.description}</Typography>
                <Divider component="div" sx={{ width: '100%', height: '1px', marginTop: '15px' }} />

                <Box display="flex" alignItems="center" gap={1} className='middle-content' mt={0.3}>

                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    <StarOutlined sx={{ fontSize: '12px', paddingTop: '3px' }} /> {module?.rating || '0'} ({module?.votes || '0'})
                  </Typography>
                  <Typography variant="body2" color="text.secondary" fontSize="0.75rem">
                    {t('by')} {module?.author || '-'}
                  </Typography>
                </Box>
              </div>
              <div className="col-2 py-3 px-4">
                <Typography variant="body2">{t('type')}: {t(module.type)}</Typography>
                <Typography variant="body2">{t('active')}: {module.isActive ? t('yes') : t('no')}</Typography>
                <Typography variant="body2">{t('status')}: {t(module.status || '-')}</Typography>
                <Typography variant="body2">{t('structures')}: {module.structures?.length || '0'}</Typography>
                <Typography variant="body2">{t('modules')}: {module.modules?.length || '0'}</Typography>
                <Typography variant="body2">{t('collections')}: {module.collectionFields?.length || '0'}</Typography>
              </div>
              <div className="col-3 py-3 px-4">
                <Typography variant="body2">{t('basePrice')}: {module.basePrice + ' $'}</Typography>
                <Typography variant="body2">{t('monthlyFee')}: {module.monthlyFee + ' $'}</Typography>
                <Typography variant="body2">{t('feePerElement')}: {module.feePerElement ? t('yes') : t('no')}</Typography>
              </div>
            </div>
            <div className='align-c'>
              <Button onClick={() => handleOpenDialog(module)} disableElevation variant="contained" color="primary" style={{ marginBottom: '10px' }}>
                {t('edit')}
              </Button>
              <Button onClick={() => handleDeleteModule(module.id)} variant="text" color="error" >
                {t('delete')}
              </Button>
            </div>
          </div>
        ))}
      </Blocks>


      {/* Dialog for Add/Edit */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>{selectedModule ? t('editModule') : t('addModule')}</DialogTitle>
        <DialogContent>

          <div style={{ position: 'sticky', top: 0, zIndex: 10, backgroundColor: 'white', paddingBottom: '8px' }}>
            <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
              <Tab label={t('general')} sx={{ textTransform: 'none' }} />
              <Tab label={t('parameters')} disabled={!formValues?.type || !formValues?.title} sx={{ textTransform: 'none' }} />
              <Tab label={t('structures')} disabled={!formValues?.type || !formValues?.title} sx={{ textTransform: 'none' }} />
              <Tab label={t('permissions')} disabled={!formValues?.type || !formValues?.title} sx={{ textTransform: 'none' }} />
              <Tab label={t('pricing')} disabled={!formValues?.type || !formValues?.title} sx={{ textTransform: 'none' }} />
              <Tab label="NodeScript" disabled={!formValues?.type || !formValues?.title} sx={{ textTransform: 'none' }} />
            </Tabs>
          </div>

          {activeTab === 0 && (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('title')}
                    name="title"
                    value={formValues.title}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('description')}
                    name="description"
                    value={formValues.description}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={3}
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal" required>
                    <InputLabel>{t('type')}</InputLabel>
                    <Select
                      label={t('type')}
                      name="type"
                      value={formValues.type}
                      onChange={handleChange}
                    >
                      {moduleTypes.map((type) => (
                        <MenuItem key={type.value} value={type.value}><div><Typography>{type.label}</Typography><Typography variant="caption" color="#69696999">{type.desc}</Typography></div></MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} >
                  <div style={{ paddingTop: '20px' }}>
                    <FormControlLabel
                      control={<Checkbox checked={formValues.isActive} onChange={handleChange} name="isActive" />}
                      label={t('active')}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={formValues.bestSeller} onChange={handleChange} name="bestSeller" />}
                      label={t('bestSeller')}
                    />  <FormControlLabel
                      control={<Checkbox checked={formValues.passwordRequired} onChange={handleChange} name="passwordRequired" />}
                      label={t('passwordRequired')}
                    />

                  </div>
                </Grid> <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label={t('releaseDate')}
                      value={formValues.releaseDate ? moment(formValues.releaseDate) : null}
                      onChange={handleReleaseDateChange}
                      renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                      sx={{ marginTop: '16px' }}
                    />
                  </LocalizationProvider>  </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('author')}
                    name="author"
                    value={formValues.author}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('votes')}
                    name="votes"
                    value={formValues.votes}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('rating')}
                    name="rating"
                    value={formValues.rating}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                {formValues?.type === 'account' && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('ownerId')}
                      name="ownerId"
                      value={formValues.ownerId}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" fontSize="12px" fontWeight="bold">{t('moduleColor')}</Typography>
                  <input
                    type="color"
                    value={formValues.moduleColor}
                    onChange={(e) => {
                      setFormValues({ ...formValues, moduleColor: e.target.value });
                    }}
                    style={{
                      width: '100%',
                      height: '40px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      padding: 0,
                      cursor: 'pointer',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                    <label htmlFor="logo-upload" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                      <CloudUploadIcon style={{ marginRight: '5px' }} />
                      <Typography variant="body1">{t('uploadLogo')}</Typography>
                    </label>
                    <input
                      id="logo-upload"
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      style={{ display: 'none' }}
                    />
                    {logoPreview && (
                      <img src={logoPreview} alt="Logo Preview" style={{ width: '50px', height: '50px', marginLeft: '10px', objectFit: 'contain' }} />
                    )}
                  </div>
                </Grid>
              </Grid>
            </>

          )}
          {activeTab === 1 && (
            <>
              <Typography variant="subtitle1" fontSize="12px" fontWeight="bold" gutterBottom>{t('requiredParams')}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={5} sx={{ marginTop: '10px' }}>
                  <Typography variant="subtitle1" fontSize="10px" fontWeight="medium">{t('availableParams')}</Typography>
                  {availableParams.map(param => (
                    <div key={param.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '5px' }}>
                      <span>{param.name}</span>
                      <Button size="small" onClick={() => handleParamMove(param, 'add')}>{t('add')}</Button>
                    </div>
                  ))}
                </Grid>
                <Grid item xs={2} sx={{ marginTop: '10px' }}>
                </Grid>
                <Grid item xs={5} sx={{ marginTop: '10px' }}>
                  <Typography variant="subtitle1" fontSize="10px" fontWeight="medium">{t('selectedParams')}</Typography>
                  {selectedParams.map(param => (
                    <div key={param.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '5px' }}>
                      <span>{param.name}</span>
                      <Button size="small" onClick={() => handleParamMove(param, 'remove')}>{t('remove')}</Button>
                    </div>
                  ))}
                </Grid>
                <Grid item xs={12} sx={{ marginTop: '20px' }}>
                  <>
                    <Typography variant="subtitle1" fontSize="12px" fontWeight="bold" gutterBottom>{t('triggers')}</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={2}>
                        <FormControlLabel
                          control={<Checkbox checked={formValues.triggers.manual} onChange={handleChange} name="trigger_manual" />}
                          label={t('manual')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <FormControlLabel
                          control={<Checkbox checked={formValues.triggers.onCreate} onChange={handleChange} name="trigger_onCreate" />}
                          label={t('onCreate')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <FormControlLabel
                          control={<Checkbox checked={formValues.triggers.onUpdate} onChange={handleChange} name="trigger_onUpdate" />}
                          label={t('onUpdate')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <FormControlLabel
                          control={<Checkbox checked={formValues.triggers.onDelete} onChange={handleChange} name="trigger_onDelete" />}
                          label={t('onDelete')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <FormControlLabel
                          control={<Checkbox checked={formValues.triggers.inboundCatch} onChange={handleChange} name="trigger_inboundCatch" />}
                          label={t('inboundCatch')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <FormControlLabel
                          control={<Checkbox checked={formValues.triggers.onScheduled} onChange={handleChange} name="trigger_onScheduled" />}
                          label={t('onScheduled')}
                        />
                      </Grid>

                    </Grid>
                  </>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: '20px' }}>
                  <Typography variant="subtitle1" fontSize="12px" fontWeight="bold" gutterBottom>{t('collectionFields')}</Typography>
                  <Grid container spacing={1}>
                    {collectionFieldOptions.map(option => (
                      <Grid item xs={2} key={option.value}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formValues.collectionFields.includes(option.value)}
                              onChange={(event) => {
                                const { checked } = event.target;
                                setFormValues(prev => ({
                                  ...prev,
                                  collectionFields: checked
                                    ? [...prev.collectionFields, option.value]
                                    : prev.collectionFields.filter(field => field !== option.value)
                                }));
                              }}
                              name="collectionFields"
                            />
                          }
                          label={option.label}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                {formValues?.type === 'account' && (
                  <Grid item xs={12} sx={{ marginTop: '20px' }}>
                    <>
                      <Typography variant="h6" gutterBottom>{t('structuresAndModules')}</Typography>
                      <Grid container spacing={2}>
                        {/* Structures Selection */}
                        <Grid item xs={12} sm={6}>
                          <Typography variant="subtitle1" fontSize="12px" fontWeight="bold">{t('structures')}</Typography>
                          <FormControl fullWidth margin="normal">
                            <InputLabel>{t('selectStructures')}</InputLabel>
                            <Select
                              multiple
                              value={selectedStructures}
                              onChange={(e) => handleSelectStructure(e.target.value)}
                              renderValue={(selected) => {
                                return selected.map(s => {
                                  const structure = structures.find(st => st.id === s);
                                  return structure ? structure.label : '';
                                }).join(', ');
                              }}
                            >
                              {structures.map((structure) => (
                                <MenuItem key={structure.id} value={structure.id}>
                                  {structure.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        {/* Modules Selection */}
                        <Grid item xs={12} sm={6}>
                          <Typography variant="subtitle1" fontSize="12px" fontWeight="bold">{t('modules')}</Typography>
                          <FormControl fullWidth margin="normal">
                            <InputLabel>{t('selectModules')}</InputLabel>
                            <Select
                              multiple
                              value={selectedModules}
                              onChange={(e) => handleSelectModule(e.target.value)}
                              renderValue={(selected) => {
                                return selected.map(m => {
                                  const module = modules.find(mo => mo.id === m);
                                  return module ? module.label : '';
                                }).join(', ');
                              }}
                            >
                              {modules.map((module) => (
                                <MenuItem key={module.id} value={module.id}>
                                  {module.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  </Grid>)}
              </Grid>
            </>
          )}
          {activeTab === 2 && (
            <>
              <Typography variant="h6" gutterBottom>{t('fields')}</Typography>
              <FieldEditor fields={formValues?.fields?.['custom'] || []} setFields={(newFields) => setFormValues({ ...formValues, fields: { ...formValues.fields, custom: newFields } })} fromExtensions={formValues?.collectionFields} isExternal={formValues?.type === 'integration'} />
              {formValues?.type === 'account' && (
                <>
                  <Typography variant="h6" gutterBottom sx={{ marginTop: '20px' }}>{t('fieldsStructures')}</Typography>
                  {selectedStructures?.map((structureId) => {
                    const structure = structures.find((s) => s.id === structureId);
                    const structureFields = formValues.fields[structureId] || []; // Get existing fields for this structure
                    return (
                      <div key={structureId} style={{ marginBottom: '20px' }}>
                        <Typography variant="subtitle1" fontSize="12px" fontWeight="bold" >{structure?.name_fr || ''}</Typography>
                        {structure?.fields?.map((field) => {
                          const isSelected = structureFields.some(f => f.id === field.id && f.selected);
                          return (
                            <div key={field?.id}>
                              <ListItem dense divider>
                                <ListItemText primary={field?.name_fr} sx={{ width: '30%', textAlign: 'left' }} />
                                <ListItemText primary={field?.value} sx={{ width: '15%', textAlign: 'left' }} />
                                <ListItemText primary={t(field?.typeData)} sx={{ width: '15%', textAlign: 'left' }} />
                                <ListItemText primary={(field?.selections?.length || 0)?.toString() + ' options'} sx={{ width: '10%', textAlign: 'left' }} />
                                <ListItemButton
                                  onClick={() => {
                                    const updatedFields = structureFields.map(f => f.id === field.id ? { ...f, selected: !isSelected } : f);
                                    handleStructureFieldsChange(structureId, updatedFields);
                                  }}
                                  sx={{ width: '10%', textAlign: 'left' }}
                                >
                                  {isSelected ? t('deselect') : t('select')}
                                </ListItemButton>
                              </ListItem>
                            </div>
                          )
                        })}
                      </div>
                    );
                  })}
                </>)}
            </>
          )}
          {activeTab === 3 && (
            <>
              <Typography variant="h6" gutterBottom>{t('permissions')}</Typography>
              <Grid container spacing={1}>
                {permissionOptions.map(permission => (
                  <Grid item xs={6} key={permission.value}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formValues.permissions.includes(permission.value)}
                          onChange={(event) => {
                            const { checked } = event.target;
                            setFormValues(prev => ({
                              ...prev,
                              permissions: checked
                                ? [...prev.permissions, permission.value]
                                : prev.permissions.filter(p => p !== permission.value)
                            }));
                          }}
                          name="permissions"
                        />
                      }
                      label={permission?.label}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {activeTab === 4 && (
            <>
              <Typography variant="h6" gutterBottom>{t('pricing')}</Typography>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('basePrice')}
                    name="basePrice"
                    value={formValues.basePrice}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('monthlyFee')}
                    name="monthlyFee"
                    value={formValues.monthlyFee}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={formValues.feePerElement} onChange={handleChange} name="feePerElement" />}
                    label={t('feePerElement')}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {activeTab === 5 && (
            <Box sx={{ pt: 2 }}>

              <Editor
                height="420px"
                width="100%"
                language="javascript"
                theme="vs-dark"
                value={nodeScriptCode}
                onChange={handleScriptChange}
                onMount={handleEditorDidMount}
                options={{
                  minimap: { enabled: false },
                  scrollBeyondLastLine: false,
                  fontSize: 13,
                  tabSize: 2,
                  wordWrap: 'on',
                }}
              />

              {/* Afficher le message d'erreur de syntaxe */}
              {scriptSyntaxError && (
                <Typography
                  component="pre"
                  color="error"
                  sx={{
                    mt: 1,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    fontFamily: 'monospace',
                    fontSize: '0.8rem',
                    p: 1,
                    bgcolor: 'rgba(255, 0, 0, 0.05)',
                    borderRadius: '4px'
                  }}
                >
                  {scriptSyntaxError}
                </Typography>
              )}
            </Box>
          )}




        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary" disabled={isLoading}>
            {t('cancel')}
          </Button>
          <Button onClick={handleSaveModule} color="primary" variant="contained" disabled={isLoading}>
            {isLoading ? t('saving') + '...' : t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </MainLayoutV2>
  );
};

export default MarketPlaceNode;