import React, { useRef, useMemo } from 'react'; // Import React, useRef, useMemo
import { useDrag, useDrop } from 'react-dnd';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Menu, MenuItem, Slider, Switch,
} from '@mui/material';
import { DeleteOutlined, EditOutlined, FormatSizeOutlined, LabelOutlined, LineWeightOutlined, MoreVertOutlined } from '@mui/icons-material';
import FieldComponent from '../../components/@generalComponents/FieldComponent'; // Adjust path if needed
import GeneralText from '../general-components/GeneralText'; // Adjust path if needed
import Avatar from '../general-components/Avatar'; // Adjust path if needed
import { useTranslation } from 'react-i18next';

// Define an Item Type (used to identify draggable items)
const ItemTypes = {
  ELEMENT: 'element',
};

// Define the component function
const DraggableElementComponent = ({
  element,
  index,
  moveElement, // Function to handle reordering
  updateField,
  handleFieldChange,
  handleSave,
  currentMetric,
  handleFieldChangeMetric,
  layout,
  blockWith,
  fontColor,
  hoveredIndex,
  setHoveredIndex,
  handleOpenMenu,
  anchorEl,
  menuOpen,
  handleCloseMenu,
  currentMenuItem,
  handleLabelChange,
  handleTypeChange,
  handleSizeChange,
  handleWeightChange,
  handleDeleteItem,
  menuItemIndex
}) => {
  const { t } = useTranslation();
  const ref = useRef(null); // Ref for the draggable and droppable node

  // --- useDrop Hook ---
  const [, drop] = useDrop({
    accept: ItemTypes.ELEMENT,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveElement(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });


  // --- useDrag Hook ---
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ELEMENT,
    item: () => ({ index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // --- Combine Refs ---
  // Attach both drag and drop refs to the same DOM node
  drag(drop(ref));

  // --- Memoize Style for Dragging/Hover ---
  const memoizedStyle = useMemo(() => ({
    opacity: isDragging ? 0.3 : 1,
    cursor: 'move',
    transition: 'opacity 0.2s ease-in-out, background-color 0.2s ease-in-out',
  }), [isDragging]);

  // --- Memoize Main Content based on element and relevant props/handlers ---
  const memoizedContent = useMemo(() => {
    return (
      <div className="col-11">
        {element?.fieldType === 'field' ? (
          <>
            {element?.structureValue === 'metric' ? (
              <FieldComponent
                key={'metric' + element?.structureValue}
                field={{
                  typeData: element?.typeValue, label: element?.label, name: element?.label,
                  selections: element?.selections, value: element?.structureValue,
                }}
                defaultValue={element?.structureValue} value={currentMetric ?? 0}
                onChange={handleFieldChangeMetric} handleSave={handleSave}
              />
            ) : (
              <FieldComponent
                key={element?.structureValue}
                field={{
                  typeData: element?.typeValue, label: element?.label, name: element?.label,
                  selections: element?.selections, value: element?.structureValue,
                }}
                defaultValue={element?.structureValue} value={element?.value ?? ''}
                onChange={(field, value) => handleFieldChange(field, value, index)}
                handleSave={handleSave}
              />
            )}
          </>
        ) : (
          <>
            {(element?.typeValue === 'avatar' || element?.typeValue === 'media' ||
              element?.typeValue === 'media-single' || element?.typeValue === 'media-single-private') && (
                <Avatar
                  key={`avatar-${element?.structureValue}`}
                  img={element?.value ?? ''} name={element?.name ?? ''} alt={element?.value ?? ''}
                  type={element?.typeValue} label={element?.label} blockWithLabel={blockWith}
                  size={'large'}
                  sx={{
                    maxWidth: `${'50px !important'}`, maxHeight: `${'50px !important'}`,
                    borderRadius: '6px !important', padding: '4px',
                  }}
                />
              )}
            {element?.typeValue !== 'custom:statuses' && element?.typeValue !== 'avatar' &&
              element?.typeValue !== 'media' && element?.typeValue !== 'media-single' &&
              element?.typeValue !== 'media-single-private' && (
                <GeneralText
                  key={`text-${element?.structureValue}`}
                  primary={element?.valueColor === 'primary'} layout={layout}
                  size={element?.weight || 'regular'}
                  fontSize={
                    layout?.data?.length > 1
                      ? element?.size + 'px' || '12px'
                      : (element?.transformedValue || element?.value)?.length < 10
                        ? '18px'
                        : element?.size + 'px' || '12px'
                  }
                  label={layout?.header?.labels ? element?.showLabel && element?.label : '' || ''}
                  structureId={element?.structureId}
                  color={layout?.data?.length > 1 ? element?.valueColor : fontColor || element?.valueColor}
                  text={element?.transformedValue || element?.value || '-'}
                  type={element?.typeValue}
                  classNameComponent={element?.action === 'none' ? '' : 'hover'}
                  keyStructure={element?.structureValue}
                  action={element?.action}
                  onClick={() => updateField(
                    element?.structureValue, element?.value, element?.label,
                    element?.typeValue, element?.selections, element?.action,
                    [{
                      collectionField: element?.collectionField, structureId: element?.structureId,
                      primaryData: element?.primaryData,
                    }]
                  )}
                />
              )}
          </>
        )}
      </div>
    );
  }, [
    element,
    index,
    currentMetric,
    handleFieldChangeMetric,
    handleSave,
    handleFieldChange,
    updateField,
    blockWith,
    layout,
    fontColor
  ]);

  // --- Memoize Menu Button and Menu Section ---
  const memoizedMenuSection = useMemo(() => {
    const showButton = hoveredIndex === index && !isDragging;
    const showMenu = showButton && currentMenuItem && Boolean(anchorEl) && menuItemIndex === index && menuOpen;

    return (
      <>
        {showButton && (
          <div className="col-1">
            <IconButton
              sx={{ marginTop: '-5px' }}
              onClick={(event) => handleOpenMenu(event, index)}
              aria-label="options"
            >
              <MoreVertOutlined sx={{ fontSize: '14px', color: 'grey' }} />
            </IconButton>

            {/* Conditionally render the Menu based on the derived showMenu state */}
            {showMenu && Boolean(anchorEl) && (
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                PaperProps={{
                  sx: {
                    borderRadius: '12px',
                    overflow: 'visible',
                    padding: '5px',
                    marginTop: '5px'
                  }
                }}
              >
                <div className="px-3 mt-2">
                  <GeneralText text={currentMenuItem?.label || t('editItem')} fontSize="12px" primary={true} size="bold" classNameComponent="mb-2" />
                  <Divider component={'div'} />
                </div>
                <MenuItem disableRipple divider sx={{ p: 0.8, m: 0 }}>
                  <div className="d-flex middle-content" style={{ width: 150, padding: '2px' }}>
                    <EditOutlined sx={{ mr: 1.2 }} />
                    <Switch
                      sx={{ marginTop: '-2px' }} size="small"
                      checked={!!currentMenuItem?.fieldType}
                      onChange={(e) => handleTypeChange(e, menuItemIndex)}
                      aria-labelledby={`label-switch-label-${index}`}
                    />
                    <GeneralText id={`label-switch-type-${index}`} text={currentMenuItem?.fieldType === 'field' ? t('editable') : t('static')} fontSize="11px" color="grey" classNameComponent="px-2" />
                  </div>
                </MenuItem>
                <MenuItem disableRipple divider sx={{ p: 0.8, m: 0 }}>
                  <div className="d-flex middle-content" style={{ width: 150, padding: '2px' }}>
                    <LabelOutlined sx={{ mr: 1.2 }} />
                    <Switch
                      sx={{ marginTop: '-2px' }} size="small"
                      checked={!!currentMenuItem?.showLabel}
                      onChange={(e) => handleLabelChange(e, menuItemIndex)}
                      aria-labelledby={`label-switch-label-${index}`}
                    />
                    <GeneralText id={`label-switch-label-${index}`} text={currentMenuItem?.showLabel ? t('showLabel') : t('hideLabel')} fontSize="11px" color="grey" classNameComponent="px-2" />
                  </div>
                </MenuItem>
                <MenuItem disableRipple divider sx={{ p: 0.8, m: 0 }}>
                  <div className="d-flex" style={{ width: 150, padding: '2px' }}>
                    <FormatSizeOutlined sx={{ mr: 1.2, mt: 0.5 }} />
                    <Slider
                      value={currentMenuItem?.size || 12}
                      onChange={(event, newValue) => handleSizeChange(event, newValue, menuItemIndex)}
                      step={1} min={8} max={18}
                      marks={[{ value: 8 }, { value: 10 }, { value: 12 }, { value: 14 }, { value: 16 }, { value: 18 }]}
                      size="small"
                      valueLabelDisplay="auto"
                      aria-label={t('fontSize')}
                    />
                  </div>
                </MenuItem>
                <MenuItem disableRipple divider sx={{ p: 0.8, m: 0 }}>
                  <div className="d-flex" style={{ width: 150, padding: '2px' }}>
                    <LineWeightOutlined sx={{ mr: 1.2, mt: 0.5 }} />
                    <Slider
                      value={currentMenuItem?.weight === 'regular' ? 0 : currentMenuItem?.weight === 'medium' ? 1 : 2}
                      onChange={(event, newValue) => handleWeightChange(event, newValue, menuItemIndex)} // Assumed stable
                      step={1} min={0} max={2}
                      marks={[{ value: 0, label: 'R' }, { value: 1, label: 'M' }, { value: 2, label: 'B' }]}
                      size="small"
                      aria-label={t('fontWeight')}
                    />
                  </div>
                </MenuItem>
                <MenuItem sx={{ p: 0.8, m: 0 }} onClick={() => handleDeleteItem(menuItemIndex)}> {/* Assumed stable */}
                  <div className="d-flex middle-content" style={{ width: 150, padding: '2px', color: 'red' }}>
                    <DeleteOutlined sx={{ mr: 1.2 }} />
                    <GeneralText text={t('delete')} fontSize="12px" classNameComponent="mt-1" primary={true} />
                  </div>
                </MenuItem>
              </Menu>
            )}
          </div>
        )}
      </>
    );
  }, [
    index,
    hoveredIndex,
    isDragging,
    menuItemIndex,
    menuOpen,
    currentMenuItem, // Data for the menu items
    handleOpenMenu,  // Assumed stable via useCallback
    handleCloseMenu, // Assumed stable via useCallback
    handleLabelChange, // Assumed stable via useCallback
    handleSizeChange,  // Assumed stable via useCallback
    handleWeightChange,// Assumed stable via useCallback
    handleDeleteItem,  // Assumed stable via useCallback
    t // from useTranslation hook
  ]);

  // --- Render the Component ---
  return (
    <div
      ref={ref} // Combined drag/drop ref
      style={memoizedStyle} // Use memoized style
      onMouseEnter={() => {
        // Updates parent state, potentially triggering parent re-render
        if (hoveredIndex !== index) setHoveredIndex(index);
      }}
      onMouseLeave={() => {
        // Updates parent state, potentially triggering parent re-render
        setHoveredIndex(null);
      }}
      className="mt-3 row"
      data-testid={`draggable-element-${index}`} // For testing/debugging
    >
      {memoizedContent} {/* Render memoized main content */}
      {memoizedMenuSection} {/* Render memoized menu button/section */}
    </div>
  );
};

// Wrap the component with React.memo for shallow prop comparison optimization
const DraggableElement = React.memo(DraggableElementComponent);

export default DraggableElement;