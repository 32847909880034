// Libraries
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Divider,
  Dialog,
  Tooltip,
  IconButton,
  ButtonBase,
  Box,
  Menu,
  MenuItem,
} from '@mui/material';
import { LocalOffer, SellOutlined } from '@mui/icons-material';
import Confetti from 'react-confetti';

// Utilities
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import { setGeneralStatus } from '../../redux/actions-v2/coreAction';

// Components
import ButtonCircle from '../../stories/general-components/ButtonCircle';
import GeneralText from '../../stories/general-components/GeneralText';
import TagSelector from '../../stories/general-components/TagSelector';
import Loading from '../../stories/general-components/Loading';
import Blocks from '../../stories/layout-components/Block';
import FieldsPopper from './FieldsPopper';

const Card = ({
  item,
  activeModule,
  cardWidth,
  color,
  showConfetti,
  activeIndex,
  handleCards,
  refreshData,
  fields,
  fieldsSubmenu,
  columns,
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { structureId, moduleName } = useParams();
  const theme = useTheme();
  const [employee, setEmployee] = useState(
    item?.assignedToDetails?.name || item?.assignedToName || ''
  );
  const [tag, setTag] = useState(item?.tags);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [activeColumn, setActiveColumn] = useState(null);
  const [submenuAnchor, setSubmenuAnchor] = useState(null);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isDarkMode = theme.palette.mode === 'dark';
  const deletedElements = sessionStorage.getItem('deletedElements');
  const isDeleted =
    deletedElements?.includes(item?.id || item?.dependencyDetails?.id) || false;

  const cardModel = activeModule?.list?.preferences?.cardModel;
  const currentCollection = useSelector(
    (state) => state.list.currentCollection
  );
  const currentUser = useSelector((state) => state.core.user);

  const [showTags, setShowTags] = useState(false);
  const timeDiffInMinutes = moment
    .unix(item?.endDate?._seconds || item?.endDate?.seconds)
    .diff(
      moment.unix(item?.startDate?._seconds || item?.startDate?.seconds),
      'minutes'
    );
  const hours = Math.floor(timeDiffInMinutes / 60);
  const minutes = timeDiffInMinutes % 60;
  const timeText = `${hours || '- '}h${minutes !== 0 ? minutes || '- ' : ''}`;
  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setActiveColumn(null);
    setSubmenuAnchor(null);
  };

  const badgeTimeResolver = (selection) => {
    const fieldMapping = {
      relativeTargetDate: item?.targetDate,
      relativeTimeStamp: item?.timeStamp,
      relativeLastUpdate: item?.lastUpdate,
      relativeStartDate: item?.startDate,
      relativeEndDate: item?.endDate,
      targetDate: item?.targetDate,
      timeStamp: item?.timeStamp,
      lastUpdate: item?.lastUpdate,
      startDate: item?.startDate,
      endDate: item?.endDate,
      startDateTime: item?.startDate,
      endDateTime: item?.endDate,
      targetTime: item?.targetTime,
      updatedTime: item?.lastUpdate,
      startTime: item?.realStartDate,
      endTime: item?.realEndDate,
      duration: moment
        .unix(item?.endDate?._seconds || item?.endDate?.seconds)
        .diff(
          moment.unix(item?.startDate?._seconds || item?.startDate?.seconds),
          'minutes'
        ),
      durationReal: moment
        .unix(item?.realEndDate?._seconds || item?.realEndDate?.seconds)
        .diff(
          moment.unix(
            item?.realStartDate?._seconds || item?.realStartDate?.seconds
          ),
          'minutes'
        ),
    };

    const selectedField = fieldMapping[selection];

    if (!selectedField) {
      return null;
    }

    const timestamp = selectedField._seconds || selectedField.seconds;

    if (!timestamp) {
      return null;
    }

    switch (selection) {
      case 'relativeTargetDate':
      case 'relativeTimeStamp':
      case 'relativeLastUpdate':
      case 'relativeStartDate':
      case 'relativeEndDate':
        return moment.unix(timestamp).fromNow();

      case 'targetDate':
      case 'timeStamp':
      case 'lastUpdate':
      case 'startDate':
      case 'endDate':
        return moment.unix(timestamp).format('DD MMM YYYY');
      case 'targetTime':
      case 'updatedTime':
      case 'realStartTime':
      case 'realEndTime':
      case 'startDateTime':
      case 'endDateTime':
        return moment.unix(timestamp).format('HH:mm');

      case 'duration':
      case 'durationReal':
        return selectedField;

      default:
        return null;
    }
  };

  const currentStatus = useSelector((state) => state.core.status);

  const activeStructure = businessStructure?.structures?.find(
    (structure) => structure.id === structureId
  );

  const tagsField = activeStructure?.fields?.find(
    (field) => field.value === 'tags'
  );

  const employees = businessPreference?.employees;

  useEffect(() => {
    setTag(item?.tags);
  }, [item]);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleChangeTags = async (event, chips) => {
    setTag(chips);
    let formatedPath = item?.documentPath.split('/');
    formatedPath = formatedPath.filter((part) => part !== item?.id).join('/');
    try {
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'tags-kanban',
          type: 'pulse',
        })
      );
      await nodeAxiosFirebase({
        t,
        method: 'PATCH',
        url: `coreSeqV3`,
        body: {
          documentId: item?.id,
          elementPath: formatedPath,
          key: 'tags',
          value: chips,
        },
      });
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'tags-kanban',
          type: 'pulse',
        })
      );
    } catch (error) {
      console.error(error);
    }
  };


  const handleNavElement = () => {
    const dependencyStructure = businessStructure?.structures?.find(
      (structure) =>
        structure.id === item?.dependencyDetails?.structureIdentifiant
    );

    navigate(
      `/app/element/${dependencyStructure?.collectionField || activeStructure?.collectionField
      }/${item?.dependencyDetails?.structureIdentifiant || item?.structureId}/${item?.dependencyDetails?.id || item?.id
      }?tab=0`
    );
  };

  const header = currentCollection?.[moduleName]?.header;

  const handleUpdateFields = async (newField, action, subNewField) => {
    try {
      const currentColumns = header?.map((col, idx) => ({
        ...col,
        width: 100,
        value: col?.value,
      }));

      let updatedColumns;

      if (action === 'add' && newField?.value) {
        updatedColumns = [
          ...currentColumns,
          {
            width: 100,
            structureValue:
              newField.value === 'targetId'
                ? 'targetName'
                : newField.value === 'targetProfileId'
                  ? 'targetProfileName'
                  : newField.value,
            type: newField?.typeData,
            action: 'none',
          },
        ];
      }

      if (action === 'delete') {
        updatedColumns = currentColumns.filter((col) => col.value !== newField);
      }

      if (action === 'add-sub') {
        const activeCol = currentColumns.find((col) => col.value === newField);

        if (activeCol) {
          updatedColumns = currentColumns.map((col) => {
            if (col?.value === newField) {
              return {
                ...col,
                sub: {
                  value: subNewField?.value,
                },
              };
            } else {
              return col;
            }
          });
        }
      }
      handleMenuClose();
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/structure`,
        body: {
          moduleId: activeModule?.id,
          type: 'fields',
          data: {
            tabIndex: activeIndex || 0,
            fieldsData: updatedColumns,
          },
        },
      });
      refreshData();
    } catch (error) {
      console.error('Error updating column widths:', error);
    }
  };

  const handleContextMenu = (event, columnValue) => {
    event.preventDefault();
    if (activeColumn === columnValue) {
      // If clicking the same column, toggle close
      handleMenuClose();
    } else {
      setMenuAnchor(event.currentTarget);
      setActiveColumn(columnValue);
    }
  };

  const handleEmployeeSelection = async (name, uid) => {
    let formatedPath = item?.documentPath.split('/');
    formatedPath = formatedPath.filter((part) => part !== item?.id).join('/');
    try {
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'employee-kanban',
          type: 'pulse',
        })
      );
      closeDialog();
      setEmployee(name);
      await nodeAxiosFirebase({
        t,
        method: 'PATCH',
        url: `coreSeqV3`,
        body: {
          documentId: item?.id,
          elementPath: formatedPath,
          key: 'assignedToId',
          value: 'users/' + uid,
        },
      });
      handleCards(item?.id, 'assignedToId', name);
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'employee-kanban',
          type: 'pulse',
        })
      );
    } catch (error) {
      console.error('Error updating employee');
      dispatch(setGeneralStatus({ status: 'error', error: error }));
    }
  };

  if (item?.dummy === 'dummy')
    return (
      <div className="cards border move-here align-c">{t('moveHere')}</div>
    );
  return (
    <div
      style={{
        width: `${cardWidth - 10}px`,
        padding: '4px',
        position: 'relative',
        height: 'auto',
        marginTop: '-20px',
        overflow: 'visible',
      }}
      key={item?.id}
    >
      {isDeleted && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            textAlign: 'center',
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            color: '#000000',
            fontWeight: 600,
            fontSize: '12px',
            pointerEvents: 'none',
          }}
        >
          {t('deletionInProgress') + '...'}
        </Box>
      )}
      {showConfetti === item?.id && (
        <div style={{ position: 'absolute' }}>
          <Confetti
            run={true}
            recycle={false}
            colors={[
              businessPreference?.mainColor,
              businessPreference?.secColor,
            ]}
            gravity={0.3}
          />
        </div>
      )}
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <div style={{ width: '390px', padding: '15px' }}>
          {employees?.map((employee) => (
            <Button
              key={employee?.uid}
              variant="outlined"
              color="primary"
              sx={{ width: '370px', marginBottom: '10px' }}
              onClick={() =>
                handleEmployeeSelection(
                  employee?.publicDisplay?.name || employee?.displayName,
                  employee?.uid,
                  employee?.avatar
                )
              }
            >
              <div className="row middle-content">
                <div className="col-2 align-left">
                  <img src={employee?.avatar} width={25} />{' '}
                </div>
                <div className="col-10 align-right">
                  {employee?.publicDisplay?.name || employee?.displayName}
                  <div style={{ fontSize: '9px', fontWeight: 400 }}>
                    {t(employee?.role)}
                  </div>
                </div>
              </div>
            </Button>
          ))}
        </div>
      </Dialog>



      <Blocks noScroll heightPercentage="auto">
        <div style={{ position: 'relative' }}>
          <div>
            <div className="d-flex justify-content-between">
              <div className="col-8">
                {item?.values?.length > 0 && item?.values?.map((item, index) => {
                  return (
                    <div key={index} className="mb-3" onClick={(event) => handleContextMenu(event, item?.structureValue)}>
                      <GeneralText
                        fontSize="12px"
                        size="medium"
                        type={item?.typeValue || 'text'}
                        text={
                          item?.transformedValue ||
                          item?.value ||
                          '-'
                        }
                        primary={true}
                        keyStructure={item?.structureValue}

                      />
                      <div

                        style={{
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                          lineHeight: '0.85em',
                          maxHeight: '1.7em',
                        }}
                      >
                        <GeneralText
                          fontSize="10px"
                          size="regular"
                          type={item?.sub?.typeValue || 'text'}
                          text={
                            item?.sub?.transformedValue ||
                            item?.sub?.value ||
                            '-'
                          }
                          primary={true}
                          keyStructure={item?.sub?.structureValue}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="col-4">
                <div className="col-12 align-right">
                  {tag?.length > 0 ? (
                    <Tooltip title={tag?.join(', ')}>
                      <span>
                        <IconButton
                          size="sm"
                          onClick={() => setShowTags((prevState) => !prevState)}
                        >
                          <LocalOffer htmlColor={color} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  ) : (
                    <IconButton
                      size="sm"
                      onClick={() => setShowTags((prevState) => !prevState)}
                    >
                      <SellOutlined htmlColor={color} />
                    </IconButton>
                  )}
                </div>
                <div className="col-12 align-right mt-3">
                  <ButtonBase
                    onClick={handleNavElement}
                    style={{
                      borderRadius: '10px',
                      height: '30px',
                      width: '100%',
                      color: '#fff',
                      fontSize: '11px',
                      fontWeight: 600,
                    }}
                    sx={{
                      backgroundColor: color,
                      '&:hover': {
                        backgroundColor: color + '80',
                      },
                    }}
                  >
                    <Tooltip
                      title={t(
                        activeModule?.list?.tabs?.[activeIndex]?.statuses?.[
                        item?.status
                        ]
                      )}
                    >
                      <div className="align-c hover">
                        <GeneralText
                          fontSize="10px"
                          size="bold"
                          text={
                            badgeTimeResolver(
                              activeModule?.list?.tabs?.[activeIndex]?.statuses?.[
                              item?.status
                              ]
                            ) || t('open')
                          }
                          primary={false}
                          classNameComponent="hover"
                        />
                      </div>
                    </Tooltip>
                  </ButtonBase>
                </div>
              </div>
            </div>


            <Menu
              elevation={2}
              open={Boolean(menuAnchor)}
              onClose={handleMenuClose}
              anchorEl={menuAnchor}
              sx={{
                boxShadow: '5px 5px 15px 0px rgba(0,0,0,0.15)',
                p: 0,
              }}
            >

              <MenuItem
                onClick={(event) => {
                  setSubmenuAnchor(event.currentTarget);
                }}
              >
                {t('addSub')}
              </MenuItem>

              <MenuItem
                onClick={() => handleUpdateFields(activeColumn, 'delete')}
              >
                {t('delete')}
              </MenuItem>
            </Menu>
            {Boolean(submenuAnchor) && (
              <Menu
                elevation={2}
                open={Boolean(submenuAnchor)}
                onClose={() => setSubmenuAnchor(null)}
                anchorEl={submenuAnchor}
                sx={{ boxShadow: '5px 5px 15px 0px rgba(0,0,0,0.15)', p: 0 }}
              >
                {fieldsSubmenu?.length > 0 &&
                  fieldsSubmenu?.map((fieldSub) => {
                    return (
                      <MenuItem
                        onClick={() =>
                          handleUpdateFields(activeColumn, 'add-sub', fieldSub)
                        }
                      >
                        {fieldSub?.name}
                      </MenuItem>
                    );
                  })}
              </Menu>
            )}
            <div style={{ position: 'absolute', right: 0, zIndex: 9999 }}>
              {currentUser?.activeBusiness?.role !== 'EMPLOYEE' && currentUser?.activeBusiness?.role !== 'VIEWER' && (
                <FieldsPopper
                  onSelect={(fieldsData) => handleUpdateFields(fieldsData, 'add')}
                  fields={fields}
                />)}
            </div>
          </div>

          <div className="mt-2">
            {cardModel === 'extend' && (
              <>
                <Divider component="div" />
                <div className="justify-content-between d-flex mt-2 ">
                  <div
                    onClick={openDialog}
                    className="col-5 d-flex middle-content hover align-left"
                    style={{ marginLeft: '-6px' }}
                  >
                    {item?.assignedToDetails?.avatar ? (
                      <img
                        style={{
                          marginLeft: '4px',
                        }}
                        src={item?.assignedToDetails?.avatar}
                        width={22}
                        height={22}
                        alt={item?.assignedToDetails?.name}
                      />
                    ) : (
                      <ButtonCircle
                        primary={false}
                        size="small"
                        icon="AssignmentIndOutlined"
                        color={isDarkMode ? 'white' : 'black'}
                      />
                    )}
                    {currentStatus?.status === 'loading' &&
                      currentStatus?.position === 'employee-kanban' ? (
                      <div>
                        <Loading type="skeleton" size="small" />
                      </div>
                    ) : (
                      <div className="px-1">
                        <GeneralText
                          fontSize="10px"
                          size="medium"
                          text={employee || t('toBeAssigned')}
                          primary={true}
                        />
                      </div>
                    )}
                  </div>{' '}
                  {item?.startDate && (
                    <div className="col-2 d-flex middle-content align-left">
                      <ButtonCircle
                        primary={false}
                        size="small"
                        icon="TimerOutlined"
                        color={isDarkMode ? 'white' : 'black'}
                      />
                      <GeneralText
                        fontSize="10px"
                        size="regular"
                        text={timeText}
                        primary={true}
                      />
                    </div>
                  )}
                  {item?.locationName && (
                    <div className="col-4 d-flex middle-content">
                      <ButtonCircle
                        primary={false}
                        size="small"
                        icon="LocationSearching"
                        color={isDarkMode ? 'white' : 'black'}
                      />
                      <GeneralText
                        fontSize="10px"
                        size="regular"
                        text={item?.locationName}
                        primary={true}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        {showTags && (
          <div className="mt-2">
            <TagSelector
              fullWidth
              label={t('tags')}
              primary={true}
              onChange={handleChangeTags}
              field={tagsField}
              value={tag}
            />
          </div>
        )}
      </Blocks>
    </div>
  );
};

export default Card;