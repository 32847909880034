import * as React from 'react';
const RoamIcon = ({ mainColor }) => (
  <svg
    id="Calque_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40.22 40.57"
  >
    <g id="Calque_1-2">
      <rect
        fill={mainColor}
        opacity={0.69}
        y={12.81}
        width={27.76}
        height={27.76}
        rx={5.34}
        ry={5.34}
      />
      <rect
        fill={mainColor}
        x={12.46}
        width={27.76}
        height={27.76}
        rx={5.34}
        ry={5.34}
      />
    </g>
  </svg>
);
export default RoamIcon;
