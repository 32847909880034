import * as React from 'react';
import chroma from 'chroma-js';

const Bos = ({ mainColor, secColor }) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 351.21 351.21"
      height={200}
      width={200}
    >
      <defs>
        <mask id="maskRounded">
          <rect width="100%" height="100%" rx="20" ry="20" fill="white" />
        </mask>
        <radialGradient
          id="Gradient1"
          cx="301.71"
          cy="309.46"
          r="174.24"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={mainColor} stopOpacity="0.7" />
          <stop offset="0.8" stopColor={mainColor + '00'} stopOpacity="0" />
          <stop offset="1" stopColor={'#fff'} stopOpacity="0" />
        </radialGradient>

        <radialGradient
          id="Gradient2"
          cx="124.2"
          cy="371.39"
          r="184.24"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={secColor} stopOpacity="0.7" />
          <stop offset="0.8" stopColor={secColor + '00'} stopOpacity="0" />
          <stop offset="1" stopColor={'#fff'} stopOpacity="0" />
        </radialGradient>
      </defs>
      <g mask="url(#maskRounded)">
        <g>
          <rect fill="#f7f7f7" x={-34.88} y={-31.44} width={418} height={418} />
          <circle cx={301.71} cy={309.46} r={154.24} fill="url(#Gradient1)" />
          <rect
            fill="#FFF"
            x={29.47}
            y={29.02}
            width={226.09}
            height={175.36}
            rx={13.33}
            ry={13.33}
          />
          <path
            fill="#eaeaea"
            d="M29.47,52.43h65.22v151.95h-51.91c-7.34,0-13.31-5.96-13.31-13.31V52.43h0Z"
          />
          <circle className="cls-5" cx={41.68} cy={41.52} r={2.54} />
          <circle className="cls-8" cx={50.66} cy={41.52} r={2.54} />
          <circle className="cls-10" cx={59.54} cy={41.52} r={2.54} />
          <circle fill="#FFFFFF00" cx={124.2} cy={371.39} r={154.24} />
        </g>
        <circle cx={124.2} cy={371.39} r={154.24} fill="url(#Gradient2)" />
        <circle fill={mainColor} cx={255.69} cy={253.95} r={39.24} />
        <rect
          fill="#fff"
          x={235.16}
          y={246}
          width={27.76}
          opacity={0.69}
          height={27.76}
          rx={5.34}
          ry={5.34}
        />
        <rect
          fill="#fff"
          x={247.61}
          y={233.19}
          width={27.76}
          height={27.76}
          rx={5.34}
          ry={5.34}
        />
      </g>
    </svg>
  );
};
export default Bos;
