import React, { useRef, useMemo } from 'react'; // Import React, useRef, useMemo
import { useDrag, useDrop } from 'react-dnd';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Menu, MenuItem, Slider, Switch,
} from '@mui/material';
import { DeleteOutlined, FormatSizeOutlined, LabelOutlined, LineWeightOutlined, MoreVertOutlined } from '@mui/icons-material';
import FieldComponent from '../../components/@generalComponents/FieldComponent'; // Adjust path if needed
import GeneralText from '../general-components/GeneralText'; // Adjust path if needed
import Avatar from '../general-components/Avatar'; // Adjust path if needed
import { useTranslation } from 'react-i18next';

const ItemTypes = {
  ELEMENT: 'element',
};

// Define the component function
const StaticElementComponent = ({
  element,
  index,
  moveElement, // Function to handle reordering
  updateField,
  handleFieldChange,
  handleSave,
  currentMetric,
  handleFieldChangeMetric,
  layout,
  blockWith,
  fontColor,
  hoveredIndex,
  setHoveredIndex,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null); // Ref for the draggable and droppable node


  // --- Memoize Style for Dragging/Hover ---
  const memoizedStyle = {
    cursor: 'move',
    transition: 'opacity 0.2s ease-in-out, background-color 0.2s ease-in-out',
  }

  // --- Memoize Main Content based on element and relevant props/handlers ---
  const memoizedContent = useMemo(() => {
    return (
      <div className="col-11">

        <>
          {(element?.typeValue === 'avatar' || element?.typeValue === 'media' ||
            element?.typeValue === 'media-single' || element?.typeValue === 'media-single-private') && (
              <Avatar
                key={`avatar-${element?.structureValue}`}
                img={element?.value ?? ''} name={element?.name ?? ''} alt={element?.value ?? ''}
                type={element?.typeValue} label={element?.label} blockWithLabel={blockWith}
                size={'large'}
                sx={{
                  maxWidth: `${'50px !important'}`, maxHeight: `${'50px !important'}`,
                  borderRadius: '6px !important', padding: '4px',
                }}
              />
            )}
          {element?.typeValue !== 'custom:statuses' && element?.typeValue !== 'avatar' &&
            element?.typeValue !== 'media' && element?.typeValue !== 'media-single' &&
            element?.typeValue !== 'media-single-private' && (
              <GeneralText
                key={`text-${element?.structureValue}`}
                primary={element?.valueColor === 'primary'} layout={layout}
                size={element?.weight || 'regular'}
                fontSize={
                  layout?.data?.length > 1
                    ? element?.size + 'px' || '12px'
                    : (element?.transformedValue || element?.value)?.length < 10
                      ? '18px'
                      : element?.size + 'px' || '12px'
                }
                label={layout?.header?.labels ? element?.showLabel && element?.label : '' || ''}
                structureId={element?.structureId}
                color={layout?.data?.length > 1 ? element?.valueColor : fontColor || element?.valueColor}
                text={element?.transformedValue || element?.value || '-'}
                type={element?.typeValue}
                classNameComponent={element?.action === 'none' ? '' : 'hover'}
                keyStructure={element?.structureValue}
                action={element?.action}
                onClick={() => updateField(
                  element?.structureValue, element?.value, element?.label,
                  element?.typeValue, element?.selections, element?.action,
                  [{
                    collectionField: element?.collectionField, structureId: element?.structureId,
                    primaryData: element?.primaryData,
                  }]
                )}
              />
            )}
        </>

      </div>
    );
  }, [
    element,
    index,
    currentMetric,
    handleFieldChangeMetric,
    handleSave,
    handleFieldChange,
    updateField,
    blockWith,
    layout,
    fontColor
  ]);



  // --- Render the Component ---
  return (
    <div
      ref={ref} // Combined drag/drop ref
      style={memoizedStyle} // Use memoized style
      onMouseEnter={() => {
        // Updates parent state, potentially triggering parent re-render
        if (hoveredIndex !== index) setHoveredIndex(index);
      }}
      onMouseLeave={() => {
        // Updates parent state, potentially triggering parent re-render
        setHoveredIndex(null);
      }}
      className="mt-3 row"
      data-testid={`draggable-element-${index}`} // For testing/debugging
    >
      {memoizedContent} {/* Render memoized main content */}
    </div>
  );
};

// Wrap the component with React.memo for shallow prop comparison optimization
const StaticElement = React.memo(StaticElementComponent);

export default StaticElement;