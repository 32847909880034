import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { setGeneralStatus } from '../../redux/actions-v2/coreAction';

// utilities
import { useTranslation } from 'react-i18next';

import { Box, Divider, List, Menu, MenuItem, Typography } from '@mui/material';
import Blocks from '../../stories/layout-components/Block';

import StatusDropZone from './items/StatusDropZone';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import FieldsPopper from '../../components/@generalComponents/FieldsPopper';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

const ListF = ({
  activeModule,
  isLoading,
  list,
  handleClick,
  activeIndex,
  handleDisplaySide,
  displaySide,
  refreshData,
}) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const dispatch = useDispatch();

  const resizeTimeoutRef = useRef(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [activeColumn, setActiveColumn] = useState(null);
  const [submenuAnchor, setSubmenuAnchor] = useState(null);
  const [fieldsSubmenu, setFieldsSubmenu] = useState([]);
  const [fields, setFields] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [itemsByStatus, setItemsByStatus] = useState({});
  const [openStatus, setOpenStatus] = useState('all');
  const [updatedList, setUpdatedList] = useState([]);
  const { structureId, moduleName } = useParams();

  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );
  const currentUser = useSelector((state) => state.core.user);

  const currentCollection = useSelector(
    (state) => state.list.currentCollection
  );

  const businessStructures = businessStructure?.structures;

  const structure = businessStructures?.find((s) => s.id === structureId);
  const structureFields = structure?.fields;
  const statusField = structureFields?.find(
    (field) => field.typeData === 'status'
  );

  const createSortHandler = (property) => {
    if (!allowedProperties?.includes(property)) {
      return;
    }

    const newSearchParams = new URLSearchParams(searchParams);
    const isAsc = orderBy === property && order === 'asc';

    newSearchParams.set('orderBy', property);
    newSearchParams.set('order', isAsc ? 'desc' : 'asc');
    newSearchParams.set('page', 1);

    setSearchParams(newSearchParams);
    handleMenuClose();
  };

  const defaultFieldsResolver = (collection) => {
    switch (collection) {
      case 'contacts':
        return [
          {
            name: t('attribute1'),
            value: 'attribute1',
            allowSub: false,
          },
          {
            name: t('attribute2'),
            value: 'attribute2',
            allowSub: false,
          },
          {
            name: t('attribute3'),
            value: 'attribute3',
            allowSub: false,
          },
          {
            name: t('attribute4'),
            value: 'attribute4',
            allowSub: false,
          },
          {
            name: t('lastUpdate'),
            value: 'lastUpdate',
          },
          {
            name: t('timeStamp'),
            value: 'timeStamp',
          },
          {
            name: 'Notes',
            value: 'note',
            allowSub: false,
          },
        ];
      case 'cardsinvoiced':
      case 'cardsuninvoiced':
      case 'cardsexpense':
        return [
          {
            name: t('attribute1'),
            value: 'attribute1',
            allowSub: false,
          },
          {
            name: t('attribute2'),
            value: 'attribute2',
            allowSub: false,
          },
          {
            name: t('attribute3'),
            value: 'attribute3',
            allowSub: false,
          },
          {
            name: t('attribute4'),
            value: 'attribute4',
            allowSub: false,
          },
          {
            name: t('lastUpdate'),
            value: 'lastUpdate',
          },
          {
            name: t('timeStamp'),
            value: 'timeStamp',
          },
          {
            name: 'Notes',
            value: 'note',
            allowSub: false,
          },
          {
            name: t('subtotal'),
            value: 'financesSubtotal',
          },
          {
            name: t('total'),
            value: 'financesTotal',
          },
          {
            name: t('balance'),
            value: 'financesBalance',
          },
        ];
      case 'profiles':
        return [
          {
            name: t('attribute1'),
            value: 'attribute1',
            allowSub: false,
          },
          {
            name: t('attribute2'),
            value: 'attribute2',
            allowSub: false,
          },
          {
            name: t('attribute3'),
            value: 'attribute3',
            allowSub: false,
          },
          {
            name: t('attribute4'),
            value: 'attribute4',
            allowSub: false,
          },
          {
            name: t('lastUpdate'),
            value: 'lastUpdate',
          },
          {
            name: t('timeStamp'),
            value: 'timeStamp',
          },
          {
            name: 'Notes',
            value: 'note',
            allowSub: false,
          },
          {
            name: 'Type',
            value: 'type',
          },
          {
            name: t('attributes'),
            value: 'targetProfileAttributes',
          },
        ];
      case 'services':
        return [
          {
            name: t('attribute1'),
            value: 'attribute1',
            allowSub: false,
          },
          {
            name: t('attribute2'),
            value: 'attribute2',
            allowSub: false,
          },
          {
            name: t('attribute3'),
            value: 'attribute3',
            allowSub: false,
          },
          {
            name: t('attribute4'),
            value: 'attribute4',
            allowSub: false,
          },
          {
            name: t('lastUpdate'),
            value: 'lastUpdate',
          },
          {
            name: t('timeStamp'),
            value: 'timeStamp',
          },
          {
            name: 'Notes',
            value: 'note',
            allowSub: false,
          },
        ];
      case 'articles':
        return [
          {
            name: t('attribute1'),
            value: 'attribute1',
            allowSub: false,
          },
          {
            name: t('attribute2'),
            value: 'attribute2',
            allowSub: false,
          },
          {
            name: t('attribute3'),
            value: 'attribute3',
            allowSub: false,
          },
          {
            name: t('attribute4'),
            value: 'attribute4',
            allowSub: false,
          },
          {
            name: t('lastUpdate'),
            value: 'lastUpdate',
          },
          {
            name: t('timeStamp'),
            value: 'timeStamp',
          },
          {
            name: 'Notes',
            value: 'note',
            allowSub: false,
          },
        ];
      case 'nodies':
        return [
          {
            name: t('attribute1'),
            value: 'attribute1',
            allowSub: false,
          },
          {
            name: t('attribute2'),
            value: 'attribute2',
            allowSub: false,
          },
          {
            name: t('attribute3'),
            value: 'attribute3',
            allowSub: false,
          },
          {
            name: t('attribute4'),
            value: 'attribute4',
            allowSub: false,
          },
          {
            name: t('lastUpdate'),
            value: 'lastUpdate',
          },
          {
            name: t('timeStamp'),
            value: 'timeStamp',
          },
          {
            name: 'Notes',
            value: 'note',
            allowSub: false,
          },
        ];
      case 'grids':
        return [
          {
            name: t('attribute1'),
            value: 'attribute1',
            allowSub: false,
          },
          {
            name: t('attribute2'),
            value: 'attribute2',
            allowSub: false,
          },
          {
            name: t('attribute3'),
            value: 'attribute3',
            allowSub: false,
          },
          {
            name: t('attribute4'),
            value: 'attribute4',
            allowSub: false,
          },
          {
            name: t('lastUpdate'),
            value: 'lastUpdate',
          },
          {
            name: t('timeStamp'),
            value: 'timeStamp',
          },
        ];
      case 'tasks':
        return [
          {
            name: t('attribute1'),
            value: 'attribute1',
            allowSub: false,
          },
          {
            name: t('attribute2'),
            value: 'attribute2',
            allowSub: false,
          },
          {
            name: t('attribute3'),
            value: 'attribute3',
            allowSub: false,
          },
          {
            name: t('attribute4'),
            value: 'attribute4',
            allowSub: false,
          },
          {
            name: t('lastUpdate'),
            value: 'lastUpdate',
          },
          {
            name: t('timeStamp'),
            value: 'timeStamp',
          },
          {
            name: 'Notes',
            value: 'note',
            allowSub: false,
          },
        ];
      case 'storages':
        return [
          {
            name: t('attribute1'),
            value: 'attribute1',
            allowSub: false,
          },
          {
            name: t('attribute2'),
            value: 'attribute2',
            allowSub: false,
          },
          {
            name: t('attribute3'),
            value: 'attribute3',
            allowSub: false,
          },
          {
            name: t('attribute4'),
            value: 'attribute4',
            allowSub: false,
          },
          {
            name: t('lastUpdate'),
            value: 'lastUpdate',
          },
          {
            name: t('timeStamp'),
            value: 'timeStamp',
          },
          {
            name: 'Notes',
            value: 'note',
            allowSub: false,
          },
        ];
      case 'passes':
        return [
          {
            name: t('attribute1'),
            value: 'attribute1',
            allowSub: false,
          },
          {
            name: t('attribute2'),
            value: 'attribute2',
            allowSub: false,
          },
          {
            name: t('attribute3'),
            value: 'attribute3',
            allowSub: false,
          },
          {
            name: t('attribute4'),
            value: 'attribute4',
            allowSub: false,
          },
          {
            name: t('lastUpdate'),
            value: 'lastUpdate',
          },
          {
            name: t('timeStamp'),
            value: 'timeStamp',
          },
          {
            name: 'Notes',
            value: 'note',
            allowSub: false,
          },
        ];
      default:
        return [];
    }
  };

  const order =
    searchParams.get('order') ||
    activeModule?.list?.tabs?.[activeIndex]?.sort ||
    'desc';

  const orderBy =
    searchParams.get('orderBy') ||
    activeModule?.list?.tabs?.[activeIndex]?.sortField;

  const allowedProperties = [
    'targetDate',
    'by',
    'status',
    'assignedToId',
    'decison-maker',
    'invoiceDate',
    'lastUpdate',
    'finances.balance',
    'finances.total',
    'financesTotal',
    'financesAmount',
    'financesBalance',
    'financesSubtotal',
    'bestcallback',
    'categoryName',
    'price',
    'locationId',
    'targetInvoicedBalance',
    'sku',
    'duration',
    'startDate',
    'timeStamp',
    'attribute1',
    'attribute2',
    'attribute3',
    'attribute4',
    'priority',
  ];

  useEffect(() => {
    if (structure) {
      const defaultFields = defaultFieldsResolver(structure.collectionField);
      const structureFields = structure?.fields || [];

      // Use a Set to keep track of unique field values
      const uniqueFieldMap = new Map();

      // Add structure fields first
      structureFields.forEach((field) => {
        if (!uniqueFieldMap.has(field.value)) {
          uniqueFieldMap.set(field.value, field);
        }
      });

      // Add default fields only if they are not already present
      defaultFields.forEach((field) => {
        if (!uniqueFieldMap.has(field.value)) {
          uniqueFieldMap.set(field.value, field);
        }
      });

      const fieldToCatch = Array.from(uniqueFieldMap.values());
      setFields(fieldToCatch);

      const filteredSubField = fieldToCatch.filter(
        (field) =>
          field?.allowSub !== false &&
          field?.typeData !== 'status' &&
          field?.typeData !== 'search' &&
          field?.typeData !== 'hook'
      );

      setFieldsSubmenu(filteredSubField);
    }
  }, [structure]);

  function getStatusFromPreferences(statusLabel, structureIden) {
    const status = statusField?.selections?.find(
      (selection) => selection?.value === statusLabel
    );

    return {
      label: status?.['label_' + currentLang] || t('notAvailable'),
      color: status?.color,
    };
  }

  // Format list items by status
  const formatListByStatus = (listItem) => {
    const formattedList = listItem?.map((item) => {
      const statusLabel = getStatusFromPreferences(
        item?.status,
        item?.structureIdentifiant || item?.structureId
      )?.label;
      const statusColor = getStatusFromPreferences(
        item?.status,
        item?.structureIdentifiant || item?.structureId
      )?.color;

      return {
        ...item,
        statusLabel: statusLabel,
        statusColor: statusColor,
      };
    });

    return formattedList;
  };

  const handleContextMenu = (event, columnValue) => {
    event.preventDefault();
    if (activeColumn === columnValue) {
      // If clicking the same column, toggle close
      handleMenuClose();
    } else {
      setMenuAnchor(event.currentTarget);
      setActiveColumn(columnValue);
    }
  };

  // Handle closing of all menus
  const handleMenuClose = () => {
    setMenuAnchor(null);
    setActiveColumn(null);
    setSubmenuAnchor(null);
  };

  const handleMoveToSetItems = async (listData, item) => {
    const formattedList = formatListByStatus(listData);
    const subListsByStatus = formattedList?.reduce((acc, item) => {
      const status = item.status;
      if (!acc[status]) {
        acc[status] = [];
      }
      acc[status].push(item);
      return acc;
    }, {});

    const statuses = statusField?.selections?.map((status) => status.value);
    statuses?.forEach((status) => {
      if (!subListsByStatus[status]) {
        subListsByStatus[status] = [
          {
            id: 'empty',
            status: status,
            statusLabel: getStatusFromPreferences(status, structureId)?.label,
            statusColor: getStatusFromPreferences(status, structureId)?.color,
          },
        ];
      }
    });
    setItemsByStatus(subListsByStatus);
    setUpdatedList(listData);
    if (item) {
      try {
        const documentPathFormatted = item?.documentPath.replace(item?.id, '');
        dispatch(
          setGeneralStatus({
            status: 'loading',
            position: 'taskStatus-list',
            type: 'pulse',
          })
        );
        await nodeAxiosFirebase({
          t,
          method: 'PATCH',
          url: `coreSeqV3`,
          body: {
            documentId: item.id,
            elementPath: documentPathFormatted,
            key: 'status',
            value: parseInt(item?.status),
          },
        });
        dispatch(
          setGeneralStatus({
            status: 'success',
            position: 'taskStatus-list',
            type: 'pulse',
          })
        );
      } catch (error) {
        console.error('Error updating status:', error);
      }
    }
  };

  useEffect(() => {
    if (list?.length > 0) {
      handleMoveToSetItems(list, null);
    }
  }, [activeModule, list]);

  const header = displaySide
    ? currentCollection?.[moduleName]?.header?.slice(0, 4)
    : currentCollection?.[moduleName]?.header;

  const [columnWidths, setColumnWidths] = useState(
    header?.map((col) => col.width)
  );

  useEffect(() => {
    setColumnWidths(header?.map((col) => col.width));
  }, [header]);

  const handleMouseDown = (e, index) => {
    e.preventDefault();
    const startX = e.clientX;
    const startWidth = columnWidths[index];

    const handleMouseMove = (event) => {
      const diffX = event.clientX - startX;
      const tableWidth = document.querySelector('.list-container').offsetWidth;
      const diffPercentage = (diffX / tableWidth) * 100;
      let newWidths = [...columnWidths];

      let newWidth = Math.max(5, startWidth + diffPercentage);

      if (index < newWidths.length - 1) {
        // Normal resizing (adjust next column)
        const nextWidth = newWidths[index + 1] - diffPercentage;
        if (nextWidth >= 5) {
          newWidths[index] = newWidth;
          newWidths[index + 1] = nextWidth;
        }
      } else {
        // Adjust all previous columns to fit the new last column width
        const totalWidth = newWidths.reduce((acc, w) => acc + w, 0);
        const overflow = totalWidth + diffPercentage - 100;

        if (overflow > 0) {
          for (let i = 0; i < index; i++) {
            if (newWidths[i] > 5) {
              let reduceAmount = Math.min(overflow, newWidths[i] - 5);
              newWidths[i] -= reduceAmount;
              overflow -= reduceAmount;
              if (overflow <= 0) break;
            }
          }
        }
        newWidths[index] = newWidth;
      }

      setColumnWidths(newWidths);
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);

      if (resizeTimeoutRef.current) clearTimeout(resizeTimeoutRef.current);

      resizeTimeoutRef.current = setTimeout(() => {
        handleUpdateModuleWidth();
      }, 700);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleUpdateModuleWidth = async () => {
    try {
      const updatedColumns = header?.map((col, idx) => ({
        width: columnWidths[idx],
        value: col.value,
      }));

      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/structure`,
        body: {
          moduleId: activeModule?.id,
          type: 'fields',
          data: {
            tabIndex: activeIndex || 0,
            fieldsData: updatedColumns,
          },
        },
      });
    } catch (error) {
      console.error('Error updating column widths:', error);
    }
  };

  const handleUpdateFields = async (newField, action, subNewField) => {
    try {
      const currentColumns = header?.map((col, idx) => ({
        width: columnWidths[idx],
        value: col.value,
      }));

      let updatedColumns;

      if (action === 'add' && newField?.value) {
        updatedColumns = [
          ...currentColumns,
          {
            width: 20,
            structureValue:
              newField.value === 'targetId'
                ? 'targetName'
                : newField.value === 'targetProfileId'
                  ? 'targetProfileName'
                  : newField.value,
            type: newField?.typeData,
            action: 'none',
          },
        ];
      }

      if (action === 'delete') {
        updatedColumns = currentColumns.filter((col) => col.value !== newField);
      }

      if (action === 'add-sub') {
        const activeCol = currentColumns.find((col) => col.value === newField);

        if (activeCol) {
          updatedColumns = currentColumns.map((col) => {
            if (col?.value === newField) {
              return {
                ...col,
                sub: {
                  value: subNewField?.value,
                },
              };
            } else {
              return col;
            }
          });
        }
      }
      handleMenuClose();
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/structure`,
        body: {
          moduleId: activeModule?.id,
          type: 'fields',
          data: {
            tabIndex: activeIndex || 0,
            fieldsData: updatedColumns,
          },
        },
      });

      refreshData();
    } catch (error) {
      console.error('Error updating column widths:', error);
    }
  };

  return (
    <div className="list-container">
      <Blocks
        height={1}
        isLoading={isLoading}
        heightPercentage={
          activeModule?.list?.tabs?.[activeIndex]?.displayTop === 'statuses'
            ? 89
            : 98
        }
        noBorder
        className="mt-1"
        empty={list?.length === 0}
        emptyType="empty"
      >
        <List
          dense
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            pl: 2,
          }}
        >
          {header?.map((col, idx) => {
            return (
              <Box
                key={idx}
                sx={{
                  width: `${columnWidths?.[idx]}%`,
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  marginRight: '12px',
                  borderRight:
                    idx !== header?.length - 1 ? '1px solid #ddd' : 'none',
                }}
              >
                <Typography
                  onClick={(event) => handleContextMenu(event, col?.value)}
                  variant="body2"
                  fontSize="11.5px"
                  sx={{
                    cursor: allowedProperties?.includes(col?.value)
                      ? 'pointer'
                      : 'default',
                  }}
                  fontWeight={700}
                >
                  {col?.label === 'Avatar' ? '-' : col.label ?? ''}
                </Typography>
                {orderBy === col?.value && (
                  <div>
                    {order === 'asc' ? (
                      <ArrowUpward fontSize="10px" sx={{ marginTop: '3px' }} />
                    ) : (
                      <ArrowDownward
                        fontSize="10px"
                        sx={{ marginTop: '3px' }}
                      />
                    )}
                  </div>
                )}

                <div
                  onMouseDown={(e) => handleMouseDown(e, idx)}
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    height: '100%',
                    width: '5px',
                    cursor: 'col-resize',
                    backgroundColor: 'transparent',
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = '#ccc')
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = 'transparent')
                  }
                />
              </Box>
            );
          })}
          <Menu
            elevation={2}
            open={Boolean(menuAnchor)}
            onClose={handleMenuClose}
            anchorEl={menuAnchor}
            sx={{
              boxShadow: '5px 5px 15px 0px rgba(0,0,0,0.15)',
              p: 0,
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 200,
                  overflowY: 'auto',
                  borderRadius: '12px',
                },
              },
            }}
          >
            {activeColumn && allowedProperties.includes(activeColumn) && (
              <MenuItem onClick={() => createSortHandler(activeColumn)}>
                {t('orderCol')}
              </MenuItem>
            )}
            <MenuItem
              onClick={(event) => {
                setSubmenuAnchor(event.currentTarget);
              }}
            >
              {t('addSub')}
            </MenuItem>

            <MenuItem
              onClick={() => handleUpdateFields(activeColumn, 'delete')}
            >
              {t('delete')}
            </MenuItem>
          </Menu>
          {Boolean(submenuAnchor) && (
            <Menu
              elevation={2}
              open={Boolean(submenuAnchor)}
              onClose={() => setSubmenuAnchor(null)}
              anchorEl={submenuAnchor}
              sx={{ boxShadow: '5px 5px 15px 0px rgba(0,0,0,0.15)', p: 0 }}
            >
              {fieldsSubmenu?.length > 0 &&
                fieldsSubmenu?.map((fieldSub) => {
                  return (
                    <MenuItem
                      onClick={() =>
                        handleUpdateFields(activeColumn, 'add-sub', fieldSub)
                      }
                    >
                      {fieldSub?.name}
                    </MenuItem>
                  );
                })}
            </Menu>
          )}
          <div style={{ position: 'absolute', right: 0, zIndex: 9999 }}>
            {currentUser?.activeBusiness?.role !== 'EMPLOYEE' && currentUser?.activeBusiness?.role !== 'VIEWER' && (
              <FieldsPopper
                onSelect={(fieldsData) => handleUpdateFields(fieldsData, 'add')}
                fields={fields}
              />)}
          </div>
        </List>
        <Divider component="div" color="#f2f2f2" />
        <PerfectScrollbar>
          {list?.length > 0 && (
            <List dense>
              {Object.keys(itemsByStatus).map((status) => (
                <StatusDropZone
                  columnWidths={columnWidths}
                  key={status}
                  status={status}
                  structureId={structureId}
                  itemsByStatus={itemsByStatus}
                  openStatus={openStatus}
                  currentCollection={currentCollection?.[moduleName]}
                  setOpenStatus={setOpenStatus}
                  list={updatedList || list}
                  moduleName={moduleName}
                  activeModule={activeModule}
                  handleMoveToSetItems={handleMoveToSetItems}
                  handleClick={handleClick}
                />
              ))}
            </List>
          )}
        </PerfectScrollbar>
      </Blocks>
    </div>
  );
};

export default ListF;
