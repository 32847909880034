import React, { useState, useEffect, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Popper,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  MenuItem,
  Menu,
  Portal,
} from '@mui/material';
import {
  Menu as MenuIcon,
  ColorLensOutlined,
  DarkModeOutlined,
  LightModeOutlined,
  ArrowDropDownOutlined,
  GridViewOutlined,
  LoginOutlined,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useThemeCont } from '../../contexts/ThemeContext';
import { SketchPicker } from 'react-color';
import chroma from 'chroma-js';
import { fetchBusinessData } from '../../redux/actions-v2/coreAction';
import BosIcon from './dynamicSVG/BosIcon';
import GrowthIcon from './dynamicSVG/GrowthIcon';
import PulseIcon from './dynamicSVG/PulseIcon';
import RoamIcon from './dynamicSVG/RoamIcon';

const MenuNode = ({ themeLayout, themePosition }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const currentLangCode = i18n.language;
  const theme = useTheme();
  const { toggleTheme } = useThemeCont();
  const isDarkMode = theme.palette.mode === 'dark';
  const storedcolor = localStorage.getItem('mainColor') || '#12B3D8';
  const businessPreference = useSelector((state) => state.core.businessData);

  const [anchorElProducts, setAnchorElProducts] = useState(null);
  const [anchorElSec, setAnchorElSec] = useState(null);
  const [primaryColor, setPrimaryColor] = useState(
    businessPreference?.mainColor
  );
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [defaultTheme, setDefaultTheme] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const themePositionPx = (themePosition / 100) * window.innerHeight;

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({
        x: (event.clientX / window.innerWidth) * 2 - 1,
        y: -(event.clientY / window.innerHeight) * 2 + 1,
      });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const scrollContainer = document.getElementById('scrollContainer');
    const handleScroll = () => {
      setIsScrolled(scrollContainer?.scrollTop > 50);
    };

    if (scrollContainer?.scrollTop > themePositionPx) {
      setDefaultTheme(true);
    } else {
      setDefaultTheme(false);
    }

    scrollContainer.addEventListener('scroll', handleScroll);
    return () => scrollContainer.removeEventListener('scroll', handleScroll);
  }, [mousePosition]);

  const generateVarColor = (color) => {
    const [h, s, l] = chroma(color).hsl();
    const newHue = (h + 70) % 360;
    return chroma.hsl(newHue, s, l).hex().toUpperCase();
  };

  const handleColorChange = (color) => {
    const selectedColor = color.hex;
    const luminance = chroma(selectedColor).luminance();
    const [hue, saturation, lightness] = chroma(selectedColor).hsl();

    if (luminance < 0.8) {
      setPrimaryColor(selectedColor);
      const newVariantColor = generateVarColor(selectedColor);
      dispatch(
        fetchBusinessData(null, t, currentLangCode, {
          mainColor: selectedColor,
          secColor: newVariantColor || '#000000',
          name: '',
          devMode: false,
        })
      );
    } else {
      alert(t('selectDarkerColor'));
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleColorChange({ hex: storedcolor || businessPreference?.mainColor });
    }, 1000);
  }, [currentLangCode]);

  const handleColorPickerToggle = (event) => {
    setAnchorEl(event.currentTarget);
    setShowColorPicker((prev) => !prev);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        anchorEl &&
        !anchorEl.contains(event.target) &&
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target)
      ) {
        setShowColorPicker(false);
      }
    }

    if (showColorPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showColorPicker, anchorEl]);

  const colorPickerRef = useRef(null);

  const handleNavigateLogin = () => {
    navigate('/signin');
  };

  const handleMenuOpen = (event) => {
    setAnchorElProducts(event.currentTarget);
  };

  const handleMenuSecOpen = (event) => {
    setAnchorElSec(event.currentTarget);
  };

  const iconResolver = (iconComponent) => {
    let icon = iconComponent;

    if (
      window.location.pathname?.startsWith('/products/pulse/') &&
      !iconComponent
    ) {
      icon = 'pulse';
    } else if (
      window.location.pathname?.startsWith('/products/bos/') &&
      !iconComponent
    ) {
      icon = 'bos';
    } else if (
      window.location.pathname?.startsWith('/products/growth/') &&
      !iconComponent
    ) {
      icon = 'growth';
    } else if (
      window.location.pathname?.startsWith('/products/roam/') &&
      !iconComponent
    ) {
      icon = 'roam';
    } else if (!iconComponent) {
      icon = 'home';
    }

    switch (icon) {
      case 'bos':
        return {
          icon: <BosIcon mainColor={businessPreference?.mainColor} />,
          name: t('nodeBOS'),
          key: 'bos',
          menu: ['overview', 'perform', 'modules', 'cases', 'business'],
        };
      case 'growth':
        return {
          icon: <GrowthIcon mainColor={businessPreference?.mainColor} />,
          key: 'growth',
          name: t('nodeGrowth'),
          menu: ['strategies', 'mappingNum'],
        };

      case 'pulse':
        return {
          icon: <PulseIcon mainColor={businessPreference?.mainColor} />,
          key: 'pulse',
          name: t('nodePulse'),
          menu: ['dashboards', 'integrations'],
        };
      case 'roam':
        return {
          icon: <RoamIcon mainColor={businessPreference?.mainColor} />,
          key: 'roam',
          name: t('nodeRoam'),
          menu: ['directory'],
        };
      case 'home':
        return {
          icon: <GridViewOutlined sx={{ mt: 0.4 }} />,
          name: t('selectProduct'),
          menu: [],
        };
    }
  };

  const menuItems = [
    {
      key: 'bos',
      label: t('nodeBOS'),
      sub: t('nodeBOSsub'),
      path: '/products/bos/overview',
    },
    {
      key: 'growth',
      label: t('growthStudio'),
      sub: t('growthStudiosub'),
      path: '/products/growth/strategies',
    },
    {
      key: 'pulse',
      label: t('nodePulse'),
      sub: t('nodePulsesub'),
      path: '/products/pulse/dashboards',
    },
    {
      key: 'roam',
      label: t('nodeRoam'),
      sub: t('nodeRoamsub'),
      path: '/products/roam/directory',
    },
  ];

  const menuItemsSec = [
    // {
    //   key: 'blog',
    //   label: t('blog'),
    //   sub: t('blogSub'),
    //   path: '/blog',
    // },
    {
      key: 'docs',
      label: t('docs'),
      sub: t('docsSub'),
      path: '/docs',
    },
    // {
    //   key: 'nodeAI',
    //   label: t('nodeAI'),
    //   sub: t('nodeAISub'),
    //   path: '/node-ai',
    // },
    // {
    //   key: 'partnerProgram',
    //   label: t('partnerProgram'),
    //   sub: t('partnerProgramSub'),
    //   path: '/partners',
    // },
    // {
    //   key: 'casesStudy',
    //   label: t('casesStudy'),
    //   sub: t('casesStudySub'),
    //   path: '/casesStudy',
    // },
  ];

  return (
    <Portal>
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: isScrolled
            ? isDarkMode
              ? '#1a1a1a'
              : themeLayout === 'dark'
                ? defaultTheme
                  ? '#ffffff'
                  : '#000000'
                : '#ffffff'
            : 'transparent',
          transition: 'background-color 0.3s ease',
          zIndex: 9999,
        }}
        position="fixed"
      >
        <Toolbar
          sx={{
            backgroundColor: isScrolled
              ? isDarkMode
                ? '#1a1a1a'
                : themeLayout === 'dark'
                  ? defaultTheme
                    ? '#ffffff'
                    : '#000000'
                  : '#ffffff'
              : 'transparent',
          }}
        >
          <Box
            sx={{ flexGrow: 0.5, cursor: 'pointer' }}
            onClick={() => navigate('/')}
          >
            <img
              src="/assets/website/v3/node-logo-square.svg"
              height={34}
              alt="Node Logo"
            />
          </Box>

          <IconButton
            edge="start"
            color="inherit"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            <MenuIcon
              sx={{
                fontSize: '2rem',
                mt: 0.7,
                mr: 1,
                color: isDarkMode
                  ? 'white !important'
                  : themeLayout === 'dark'
                    ? 'white !important'
                    : 'black !important',
              }}
            />
          </IconButton>

          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
            <div style={{ width: '400px', overflow: 'scroll', height: '95vh' }}>
              <Typography
                variant="h5"
                fontWeight={700}
                sx={{ marginTop: '100px', mb: 2, textAlign: 'left', pl: 2 }}
              >
                {' '}
                MENU{' '}
              </Typography>
              <List>
                {menuItems?.map((item) => (
                  <ListItem key={item.key}>
                    <div>
                      <div className="d-flex middle-content">
                        <Box
                          sx={{
                            marginRight: '10px',
                            width: '25px',
                            height: '25px',
                          }}
                        >
                          {iconResolver(item?.key)?.icon}
                        </Box>
                        <ListItemText
                          primaryTypographyProps={{
                            fontWeight: 600,
                          }}
                          primary={item?.label}
                          onClick={() => {
                            navigate(item.path);
                            setDrawerOpen(false);
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'left',
                        }}
                      >
                        {iconResolver(item?.key)?.menu?.map((page) => {
                          const expectedPath =
                            page === 'home'
                              ? '/'
                              : `/products/${iconResolver(item?.key)?.key
                              }/${page}`;

                          return (
                            <Button
                              key={page}
                              sx={{
                                fontSize: '1.05rem',
                                textAlign: 'left',
                                textTransform: 'none',
                                fontWeight:
                                  window.location.pathname === expectedPath
                                    ? 600
                                    : 400,
                                color: isDarkMode
                                  ? 'white !important'
                                  : 'black !important',
                              }}
                              onClick={() => {
                                navigate(
                                  `/${page === 'home'
                                    ? ''
                                    : 'products/' +
                                    iconResolver(item?.key)?.key +
                                    '/' +
                                    page
                                  }`
                                );
                                setDrawerOpen(false);
                              }}
                            >
                              <div
                                style={{ textAlign: 'left', width: '150px' }}
                              >
                                {t(page)}
                              </div>
                            </Button>
                          );
                        })}
                      </div>
                    </div>
                  </ListItem>
                ))}
                <Divider sx={{ mt: 2, mb: 2 }} />
                {menuItemsSec.map((item) => (
                  <ListItem
                    button='true'
                    key={item.key}
                    onClick={() => {
                      navigate(item.path);
                      setDrawerOpen(false);
                    }}
                  >
                    <ListItemText primary={item.label} />
                  </ListItem>
                ))}
              </List>
            </div>
          </Drawer>

          <Box sx={{ flexGrow: 5, display: { xs: 'none', md: 'flex' } }}>
            <Button
              sx={{
                fontSize: '1.05rem',
                marginRight: '10px',
                fontWeight: 500,
                color: isDarkMode
                  ? 'white !important'
                  : themeLayout === 'dark'
                    ? defaultTheme
                      ? 'black !important'
                      : 'white !important'
                    : 'black !important',
              }}
              className="d-flex"
              onClick={handleMenuOpen} // Directly pass the function
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    marginRight: '10px',
                    width: '25px',
                    height: '25px',
                  }}
                >
                  {iconResolver()?.icon}
                </Box>
                <div>
                  <div>{iconResolver()?.name}</div>
                </div>
              </Box>
              <ArrowDropDownOutlined />
            </Button>
            <Menu
              anchorEl={anchorElProducts}
              open={Boolean(anchorElProducts)}
              onClose={() => setAnchorElProducts(null)}
              PaperProps={{
                sx: {
                  borderRadius: '12px',
                  overflow: 'hidden',
                },
              }}
            >
              {menuItems?.map((item) => (
                <MenuItem
                  key={item.key}
                  onClick={() => {
                    navigate(item.path);
                    setAnchorElProducts(null);
                  }}
                  sx={{
                    fontWeight:
                      window.location.pathname === item.path ? 600 : 500,
                    color: isDarkMode
                      ? 'white !important'
                      : themeLayout === 'dark'
                        ? defaultTheme
                          ? 'black !important'
                          : 'white !important'
                        : 'black !important',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      sx={{
                        marginRight: '10px',
                        width: '25px',
                        height: '25px',
                        padding: '5px',
                      }}
                    >
                      {iconResolver(item.key)?.icon}
                    </Box>
                    <div>
                      <div
                        style={{
                          fontWeight: 600,
                          color: isDarkMode ? '#FFF' : '#000',
                        }}
                      >
                        {item.label}
                      </div>
                      <div
                        style={{
                          fontSize: '9px',
                          color: '#999999',
                          marginTop: '-3px',
                        }}
                      >
                        {item.sub}
                      </div>
                    </div>
                  </Box>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 5, display: { xs: 'none', md: 'flex' } }}>
            {iconResolver()?.menu?.map((page) => {
              const expectedPath =
                page === 'home'
                  ? '/'
                  : `/products/${iconResolver()?.key}/${page}`;

              return (
                <Button
                  key={page}
                  sx={{
                    fontSize: '1.05rem',
                    marginRight: '10px',
                    textTransform: 'none',
                    fontWeight:
                      window.location.pathname === expectedPath ? 600 : 400,
                    color: isDarkMode
                      ? 'white !important'
                      : themeLayout === 'dark'
                        ? defaultTheme
                          ? 'black !important'
                          : 'white !important'
                        : 'black !important',
                  }}
                  onClick={() =>
                    navigate(
                      `/${page === 'home'
                        ? ''
                        : 'products/' + iconResolver()?.key + '/' + page
                      }`
                    )
                  }
                >
                  {t(page)}
                </Button>
              );
            })}
          </Box>

          <Box
            flexGrow={1}
            sx={{
              display: 'flex',
              alignContent: 'right',
              justifyContent: 'right',
            }}
          >

            <IconButton
              onClick={toggleTheme}
              color={
                isDarkMode
                  ? 'white'
                  : themeLayout === 'dark'
                    ? defaultTheme
                      ? 'black'
                      : 'white'
                    : 'black'
              }
            >
              {isDarkMode ? <LightModeOutlined /> : <DarkModeOutlined />}
            </IconButton>
            <IconButton
              onClick={handleColorPickerToggle}
              color={
                isDarkMode
                  ? 'white'
                  : themeLayout === 'dark'
                    ? defaultTheme
                      ? 'black'
                      : 'white'
                    : 'black'
              }
            >
              <ColorLensOutlined />
            </IconButton>
            <IconButton
              onClick={handleNavigateLogin}
              color={
                isDarkMode
                  ? 'white'
                  : themeLayout === 'dark'
                    ? defaultTheme
                      ? 'black'
                      : 'white'
                    : 'black'
              }
            >
              <LoginOutlined />
            </IconButton>
            <Popper
              open={showColorPicker}
              anchorEl={anchorEl}
              placement="bottom"
              style={{ zIndex: 1000 }}
            >
              <Box
                ref={colorPickerRef}
                sx={{
                  p: 2,
                  backgroundColor: isDarkMode ? '#1a1a1a' : '#FFF',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                }}
              >
                <SketchPicker
                  color={primaryColor}
                  onChangeComplete={handleColorChange}
                  styles={{
                    default: {
                      picker: { boxShadow: 'none' },
                      saturation: { borderRadius: '8px' },
                      hue: { borderRadius: '8px' },
                    },
                  }}
                />
              </Box>
            </Popper>
          </Box>
          <Box sx={{ flexGrow: 0.5, display: { xs: 'none', md: 'flex' } }}>
            <Button
              sx={{
                fontSize: '1.05rem',
                marginRight: '10px',
                fontWeight: 500,
                textTransform: 'none',
                color: isDarkMode
                  ? 'white !important'
                  : themeLayout === 'dark'
                    ? defaultTheme
                      ? 'black !important'
                      : 'white !important'
                    : 'black !important',
              }}
              className="d-flex"
              onClick={handleMenuSecOpen}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  <div>{t('secMenu')}</div>
                </div>
              </Box>
              <ArrowDropDownOutlined />
            </Button>
            <Menu
              anchorEl={anchorElSec}
              open={Boolean(anchorElSec)}
              onClose={() => setAnchorElSec(null)}
              PaperProps={{
                sx: {
                  borderRadius: '12px',
                  overflow: 'hidden',
                },
              }}
            >
              {menuItemsSec?.map((item) => (
                <MenuItem
                  key={item.key}
                  onClick={() => {
                    navigate(item.path);
                    setAnchorElSec(null);
                  }}
                  sx={{
                    fontWeight:
                      window.location.pathname === item.path ? 600 : 500,
                    color: isDarkMode
                      ? 'white !important'
                      : themeLayout === 'dark'
                        ? defaultTheme
                          ? 'black !important'
                          : 'white !important'
                        : 'black !important',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <div
                        style={{
                          fontWeight: 600,
                          color: isDarkMode ? '#FFF' : '#000',
                        }}
                      >
                        {item.label}
                      </div>
                      <div
                        style={{
                          fontSize: '9px',
                          color: '#999999',
                          marginTop: '-3px',
                        }}
                      >
                        {item.sub}
                      </div>
                    </div>
                  </Box>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            flexGrow={1}
            sx={{
              display: 'flex',
              alignContent: 'right',
              justifyContent: 'right',
            }}
          >
            <Button
              sx={{
                fontSize: '1.05rem',
                marginRight: '20px',
                color: isDarkMode
                  ? 'white !important'
                  : themeLayout === 'dark'
                    ? defaultTheme
                      ? 'black !important'
                      : 'white !important'
                    : 'black !important',
              }}
              onClick={() =>
                changeLanguage(currentLangCode === 'en' ? 'fr' : 'en')
              }
            >
              {currentLangCode === 'en' ? 'FR' : 'EN'}
            </Button>
            <Button
              sx={{
                fontSize: '1.05rem',
                marginRight: '20px',
                textTransform: 'none',
                display: { xs: 'none', md: 'block' },
                color: isDarkMode
                  ? 'white !important'
                  : themeLayout === 'dark'
                    ? defaultTheme
                      ? 'black !important'
                      : 'white !important'
                    : 'black !important',
              }}
              onClick={() => navigate('/signin')}
            >
              {t('login')}
            </Button>

            <Button
              onClick={() => navigate('/onboard')}
              variant="contained"
              disableElevation
              sx={{
                fontSize: '1.05rem',
                marginRight: '20px',
                textTransform: 'none',
                display: { xs: 'none', md: 'block' },
                paddingLeft: '30px',
                paddingRight: '30px',
                borderRadius: '50px',
                color: isDarkMode ? 'black' : 'white',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              {t('signupPlans')}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </Portal>
  );
};

export default MenuNode;
