import * as React from 'react';
const BosIcon = ({ mainColor }) => (
  <svg
    id="Calque_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39.16 39.61"
  >
    <g id="Calque_1-2">
      <rect
        fill={mainColor}
        x={11.4}
        y={11.85}
        width={27.76}
        height={27.76}
        opacity={0.69}
        rx={5.34}
        ry={5.34}
      />
      <path
        fill={mainColor}
        d="M6.51.63c5.78,3.56,11.56,7.11,17.34,10.67,2.64,1.63,2.68,5.46.07,7.14L6.5,29.66C3.69,31.48-.01,29.45,0,26.1L.08,4.21C.09.92,3.7-1.09,6.51.63Z"
      />
    </g>
  </svg>
);
export default BosIcon;
