import React, { } from 'react';
import { Typography, } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const Typo = ({
  variant,
  color,
  text,
  className,
  bold,
  mainColor,
  sx,
  navigation,
}) => {
  const navigate = useNavigate();


  const theme = useTheme();
  const isMobile = window.innerWidth <= 768;
  const isDarkMode = theme.palette.mode === 'dark';
  const main = theme.palette.primary.main;

  const handleOnClick = () => {
    if (navigation) {
      navigate(navigation);
    }
  };

  const typoVariantResolver = (variant) => {
    switch (variant) {
      case 'h1':
        return {
          variant: 'h1',
          fontSize: isMobile ? 38 : 42,
          lineHeight: 1.1,
          fontWeight: 700,
          color: mainColor ? main : color || isDarkMode ? '#FFF' : '#000',
          ...sx,
        };
      case 'h2':
        return {
          variant: 'h2',
          fontSize: isMobile ? 32 : 36,
          lineHeight: 1.05,
          fontWeight: 700,
          color: mainColor ? main : color || isDarkMode ? '#FFF' : '#000',
          ...sx,
        };
      case 'h3':
        return {
          variant: 'h3',
          fontSize: isMobile ? 22 : 24,
          fontWeight: 600,
          lineHeight: 1,
          color: mainColor ? main : color || isDarkMode ? '#FFF' : '#000',
          ...sx,
        };
      case 'h3-light':
        return {
          variant: 'h3',
          fontSize: isMobile ? 18 : 20,
          fontWeight: 600,
          lineHeight: 1.05,
          color: mainColor ? main : color || isDarkMode ? '#FFF' : '#000',
          ...sx,
        };
      case 'h4':
        return {
          variant: 'h4',
          fontSize: isMobile ? 16 : 18,
          fontWeight: 600,
          lineHeight: 1.05,
          color: mainColor ? main : color || isDarkMode ? '#FFF' : '#000',
          ...sx,
        };
      case 'subTitle':
        return {
          variant: 'subtitle1',
          fontSize: isMobile ? 14 : 16,
          fontWeight: bold ? 600 : 500,
          lineHeight: 1.1,
          color: mainColor ? main : color || isDarkMode ? '#FFF' : '#000',
          ...sx,
        };
      case 'smallTitle':
        return {
          variant: 'subtitle2',
          fontSize: isMobile ? 13 : 14,
          fontWeight: 600,
          lineHeight: 1.05,
          color: mainColor ? main : color || isDarkMode ? '#FFF' : '#000',
          ...sx,
        };
      case 'p':
        return {
          variant: 'body1',
          fontSize: 12,
          fontWeight: bold ? 500 : 400,
          lineHeight: 1.1,
          color: mainColor ? main : color || isDarkMode ? '#FFF' : '#000',
          ...sx,
        };
      case 'p-small':
        return {
          variant: 'body1',
          fontSize: 9,
          fontWeight: bold ? 500 : 400,
          lineHeight: 1.1,
          color: mainColor ? main : color || isDarkMode ? '#FFF' : '#000',
          cursor: navigation ? 'pointer' : 'default',
          ...sx,
        };
      case 'p-light':
        return {
          variant: 'body2',
          fontSize: 13,
          fontWeight: bold ? 500 : 400,
          lineHeight: 1.1,
          color: mainColor ? main : color || isDarkMode ? '#FFF' : '#000',
          cursor: navigation ? 'pointer' : 'default',
          ...sx,
        };
      case 'body1':
        return {
          variant: 'body1',
          fontSize: isMobile ? 13 : 14,
          fontWeight: bold ? 600 : 400,
          lineHeight: 1.1,
          color: color || isDarkMode ? '#FFF' : '#000',
          cursor: navigation ? 'pointer' : 'default',
          ...sx,
        };
      case 'body2':
        return {
          variant: 'body2',
          fontSize: 14,
          fontWeight: bold ? 600 : 400,
          lineHeight: 1.1,
          color: color || isDarkMode ? '#FFF' : '#000',
          cursor: navigation ? 'pointer' : 'default',
          ...sx,
        };
      default:
        return {
          variant: 'body1',
          fontSize: isMobile ? 13 : 14,
          fontWeight: 400,
          lineHeight: 1.05,
          color: mainColor ? main : color || isDarkMode ? '#FFF' : '#000',
          cursor: navigation ? 'pointer' : 'default',
          ...sx,
        };
    }
  };

  return (
    <Typography
      className={className || ''}
      sx={typoVariantResolver(variant)}
      onClick={handleOnClick}
    >
      {text}
    </Typography>
  );
};

export default Typo;
