import React, { useState, useEffect } from 'react';
import { Box, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';
import Typo from '../Typo';
import { useMediaQuery } from '@mui/material';

const Build = ({ translationCode }) => {
  const chips = [
    '✌️ Contacts',
    '📋 Formulaires',
    'CRM',
    '🚀 Tunnel de ventes',
    '🏢 Immeubles',
    '📦 Inventaire interne',
    '💰 Facturation',
    '💼 ERP',
    '📄 Contrats',
    '🗂️ Bons de travail',
    '🔧 Projets',
    '📝 Devis',
    '💳 Paiements',
    '📊 Rapports',
    '📈 Analytique',
    '📦 Stocks',
    '🚚 Livraisons',
    '✅ Suivi des tâches',
    '📅 Planning',
    '💸 Budget',
    '👥 Ressources',
    '🤝 Collaboration',
    '🚛 Fournisseurs',
    '🛠️ Support',
    '🔧 Maintenance',
  ];

  const { t } = useTranslation();
  const theme = useTheme();
  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';

  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const varColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  // Définir des variations de couleurs
  const lightMainColor = chroma(mainColor).brighten(2.2).hex();
  const lightVarColor = chroma(varColor).brighten(2.2).hex();
  const oppositeColor =
    chroma(mainColor).luminance() > 0.5 ? '#000000' : '#FFFFFF';

  // Couleurs des chips actives (avec transparence)
  const colors = [
    lightMainColor + '20',
    lightVarColor + '20',
    oppositeColor + '20',
  ];
  const textColors = [mainColor, varColor, mainColor];

  // État pour les chips actives.
  const [activeChips, setActiveChips] = useState([]);

  // Sélectionne aléatoirement 3 indices uniques et leur assigne un index de couleur distinct.
  const getRandomActiveChips = () => {
    const indices = Array.from({ length: chips.length }, (_, i) => i);
    indices.sort(() => Math.random() - 0.5);
    const selectedIndices = indices.slice(0, 3);
    const colorIndices = [0, 1, 2].sort(() => Math.random() - 0.5);
    return selectedIndices.map((chipIndex, idx) => ({
      chipIndex,
      colorIndex: colorIndices[idx],
    }));
  };

  useEffect(() => {
    let timerId;
    const updateActiveChips = () => {
      setActiveChips(getRandomActiveChips());
      const nextInterval = Math.floor(Math.random() * 1000) + 2000;
      timerId = setTimeout(updateActiveChips, nextInterval);
    };

    timerId = setTimeout(updateActiveChips, 2500);
    return () => clearTimeout(timerId);
  }, []);

  // Rendu d'une ligne glissante. Les chips sont dupliquées pour un défilement fluide.
  const renderSlidingRow = (direction, rowKey) => (
    <Box key={rowKey} className="chip-row-container">
      <Box className={`chip-row-inner-${rowKey} ${direction}`}>
        {chips.map((chip, index) => {
          const activeChip = activeChips.find((ac) => ac.chipIndex === index);
          return (
            <Chip
              key={`row-${rowKey}-${index}`}
              label={chip}
              sx={{
                backgroundColor: activeChip
                  ? colors[activeChip.colorIndex]
                  : '#F9F9F9',
                color: activeChip
                  ? textColors[activeChip.colorIndex]
                  : '#000000',
                border: '1px solid #E0E0E0',
                borderRadius: '20px',
                padding: '20px 30px',
                fontSize: '11px',
                lineHeight: '16px',
                letterSpacing: '0.02em',
                fontWeight: '500',
                margin: '2px 10px',
                boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.05)',
                transition: 'background-color 1s ease, color 1s ease',
              }}
            />
          );
        })}

        {chips?.map((chip, index) => {
          const activeChip = activeChips.find((ac) => ac.chipIndex === index);
          return (
            <Chip
              key={`row-${rowKey}-dup-${index}`}
              label={chip}
              sx={{
                backgroundColor: activeChip
                  ? colors[activeChip.colorIndex]
                  : '#F9F9F9',
                color: activeChip
                  ? textColors[activeChip.colorIndex]
                  : '#000000',
                border: '1px solid #E0E0E0',
                borderRadius: '20px',
                padding: '20px 30px',
                fontSize: '11px',
                lineHeight: '16px',
                letterSpacing: '0.02em',
                fontWeight: '500',
                margin: '1px 10px',
                boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.05)',
                transition: 'background-color 1s ease, color 1s ease',
              }}
            />
          );
        })}
      </Box>
    </Box>
  );

  return (
    <Box
      position="relative"
      sx={{
        paddingTop: '20vh',
        backgroundColor: isDarkMode ? '#000000' : '#FFFFFF',
        marginBottom: isMobile ? '' : '15vh',
      }}
      width="100%"
      height="465px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      textAlign="center"
    >
      <div
        style={{
          position: 'absolute',
          bottom: -120,
          left: 0,
          width: '500px',
          height: '200px',
          marginLeft: '-50px',
          zIndex: 2,
          pointerEvents: 'none',
          background: isMobile
            ? ''
            : `linear-gradient(to right, ${
                isDarkMode ? '#000000' : '#FFFFFF'
              }, transparent)`,
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: -120,
          right: 0,
          width: '500px',
          marginRight: '-50px',
          height: '200px',
          zIndex: 2,
          pointerEvents: 'none',
          background: isMobile
            ? ''
            : `linear-gradient(to left, ${
                isDarkMode ? '#000000' : '#FFFFFF'
              }, transparent)`,
        }}
      />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={5}
        sx={{ maxWidth: '700px' }}
      >
        <Typo
          text={t(translationCode + 'Build')}
          variant="h2"
          className="px-4"
        />
        <Typo
          text={t(translationCode + 'BuildDesc')}
          variant="subTitle"
          className="px-4"
        />
      </Box>
      <Box sx={{ mt: 5, position: 'relative' }}>
        <Box
          className="chip-slider mt-4"
          sx={{ position: 'relative', zIndex: 1 }}
        >
          {renderSlidingRow('slide-left', 0)}
          {renderSlidingRow('slide-right', 1)}
          {renderSlidingRow('slide-left', 2)}
        </Box>
      </Box>
    </Box>
  );
};

export default Build;
