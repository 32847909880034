import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ErrorBoundary from '../../components/@generalComponents/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MessagesFeed from '../../components/@generalComponents/MessagesFeed';
import CardMainPanel from '../layout-components/templates/CardMainPanel';
import QuickNote from '../../components/@generalComponents/QuickNote';
import { useParams } from 'react-router-dom';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import { toast } from 'react-toastify';
import CardItemsList from '../layout-components/templates/CardItemsList';
import ActionBtn from '../layout-components/templates/ActionBtn';
import ActionBtnDone from '../layout-components/templates/ActionBtnDone';
import ScheduleLocations from '../layout-components/templates/ScheduleLocations';
import Calendar from '../layout-components/templates/Calendar';
import Files from '../layout-components/templates/Files';
import { setGeneralStatus } from '../../redux/actions-v2/coreAction';
import NodePackages from '../layout-components/templates/NodePackages';
import ItemsVariants from '../layout-components/templates/ItemsVariants';
import PCOActions from '../layout-components/templates/PCOActions';
import VlnOrder from '../layout-components/templates/VlnOrder';
import MapGeo from '../layout-components/templates/MapGeo';
import PublicInvoiceComponent from '../../screens/public/PublicInvoiceComponent';
import StatusesEvolution from '../layout-components/templates/StatusesEvolution';
import Summary from '../layout-components/templates/Summary';
import SignaturePad from '../layout-components/templates/SignaturePad';
import MappingImagePin from '../layout-components/templates/MappingImagePin';

const ActionLayout = ({
  elementDetails,
  heightPercentage,
  handleRefresh,
  handleAddItem,
  structureData,
  fromList,
  handleCreateNew,
  layout,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { moduleName } = useParams();

  const [data, setData] = useState(
    elementDetails?.elementData || elementDetails?.data || {}
  );

  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );

  const businessStructures = businessStructure?.structures;
  // Structure specific to the element being displayed, not necessarily the block's config structure
  const structure = useMemo(
    () =>
      businessStructures?.find(
        (s) => s.id === elementDetails?.elementData?.structureId
      ),
    [businessStructures, elementDetails?.elementData?.structureId]
  );

  const structureFields = structureData?.fields;

  const elementData = elementDetails?.elementData;

  useEffect(() => {
    // Update state if the primary data source changes
    setData(elementDetails?.elementData || elementDetails?.data || {});
  }, [elementDetails?.elementData, elementDetails?.data]); // Simplified dependency


  const handleSave = async (key, value) => {
    if (elementData?.documentPath && elementData?.id && key) {
      let formatedPath = elementData.documentPath.split('/');
      formatedPath = formatedPath
        .filter((part) => part !== elementData.id)
        .join('/');

      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: `save-${elementData.id}-${key}`,
        })
      );
      try {
        await nodeAxiosFirebase({
          t,
          method: 'PATCH',
          url: `coreSeqV3`,
          body: {
            documentId: elementData.id,
            elementPath: formatedPath,
            key: key,
            value: value,
          },
        });
        dispatch(
          setGeneralStatus({
            status: 'success',
            position: `save-${elementData.id}-${key}`,
          })
        );
      } catch (error) {
        console.error('Error updating field: ', error);
        dispatch(
          setGeneralStatus({
            status: 'error',
            position: `save-${elementData.id}-${key}`,
            error: error,
          })
        );
      }
    } else {
      toast.error(t('errorTryToRefreshThePage'));
    }
  };

  const cleanedFields = useMemo(
    () =>
      structureFields?.filter(
        (field) =>
          field?.value !== 'targetId' &&
          field?.value !== 'dependencyId' &&
          field?.value !== 'targetProfileId' &&
          field?.value !== 'name'
      ),
    [structureFields]
  );

  function componentResolver(component) {
    const containerVh = Math.max(Number(heightPercentage) || 0, 20);

    switch (component) {
      case 'logFeed':
        return (
          <MessagesFeed
            elementId={elementData?.id}
            elementType={moduleName || 'cards'}
            userId={elementData?.targetId}
            elementPath={elementData?.documentPath}
            fromList={fromList}
          />
        );
      case 'quickNote':
        return (
          <QuickNote
            elementPath={
              elementDetails?.mainElement?.documentPath ||
              elementData?.documentPath
            }
            elementDetails={elementData?.note}
            handleSave={handleSave}
            fromList={fromList}
          />
        );
      case 'variants':
        return (
          <ItemsVariants
            elementDetails={elementDetails}
            elementData={elementData}
            handleSave={handleSave}
          />
        );
      case 'pcoActions':
        return (
          <PCOActions elementDetails={elementDetails} structure={structure} />
        );
      case 'signature':
        return (
          <SignaturePad elementDetails={elementDetails} structure={structure} />
        );
      case 'vlnOrder':
        return (
          <VlnOrder elementDetails={elementDetails} structure={structure} />
        );
      case 'summary':
        return (
          <Summary elementDetails={elementDetails} structure={structure} />
        );
      case 'mainCardItems':
        return (
          <CardMainPanel
            isTablet={isTablet}
            cardIden={elementData?.id}
            handleAddItem={handleAddItem}
            handleRefresh={handleRefresh}
          />
        );
      case 'nodePackages':
        return <NodePackages elementDetails={elementDetails} />;
      case 'secCardItems':
        return (
          <CardItemsList
            isTablet={isTablet}
            cardIden={elementData?.id}
            layout={layout}
            handleAddItem={handleAddItem}
            handleRefresh={handleRefresh}
          />
        );

      case 'actionBtn':
        return (
          <ActionBtn
            elementDetails={elementDetails}
            structure={structure}
            fromList={fromList}
            componentData={layout?.data}
          />
        );
      case 'map':
        return (
          <MapGeo
            heightPercentage={containerVh}
            elementDetails={elementDetails}
            structure={structure}
            fromList={fromList}
          />
        );
      case 'mappingImagePin':
        return (
          <MappingImagePin
            heightPercentage={containerVh}
            elementDetails={elementDetails}
            fromList={fromList}
            layout={layout}
            handleRefresh={handleRefresh}
            handleNew={handleCreateNew}
          />
        );
      case 'files':
        return <Files elementDetails={elementDetails} structure={structure} />;
      case 'scheduleLocation':
        return (
          <ScheduleLocations
            elementDetails={elementDetails}
            structure={structure}
          />
        );

      case 'statusesEvolution':
        return (
          <StatusesEvolution
            elementDetails={elementDetails}
            structure={structure}
            componentData={layout?.data}
          />
        );

      case 'calendarActions':
        return (
          <Calendar elementDetails={elementDetails} structure={structure} />
        );
      case 'actionBtnDone':
        return (
          <ActionBtnDone
            elementDetails={elementDetails}
            structure={structure}
            componentData={layout?.data}
          />
        );
      case 'cardDetails':
        return (
          <PublicInvoiceComponent
            elementDetails={elementDetails}
            structure={structure} x
            documentPath={
              elementDetails?.mainElement?.documentPath ||
              elementData?.documentPath
            }
          />
        );
      default:
        return null;
    }
  }

  return (
    <ErrorBoundary>
      {/* Removed overflowY style, added height 100% */}
      <div style={{ height: '100%', width: '100%' }}>
        <div style={{ height: '100%' }}>
          {componentResolver(elementDetails?.type || layout?.type)}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ActionLayout;
