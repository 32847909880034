import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask';
import { useTheme } from '@mui/material/styles';
import MUITextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';
import Visibility from '@mui/icons-material/Visibility';
import {
  CheckCircle,
  ColorLens,
  Comment,
  CreditCard,
  Email,
  InfoOutlined,
  Link,
  Map,
  Phone,
  Search,
  Send,
  VisibilityOff,
  VpnKeyOutlined,
} from '@mui/icons-material';
import { CircularProgress, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import { setNodeAssistant } from '../../redux/actions-v2/coreAction';

const TextMaskCustom = (props) => {
  const { inputRef, mask, ...other } = props;
  const handleClick = (event) => {
    event.target.setSelectionRange(0, 0);
  };
  const emailMaskDefault = [
    /[a-zA-Z0-9._%+-]/,
    /[a-zA-Z0-9._%+-]/,
    /[a-zA-Z0-9._%+-]/,
    /[a-zA-Z0-9._%+-]/,
    /[a-zA-Z0-9._%+-]/,
    /[a-zA-Z0-9._%+-]/,
    /[a-zA-Z0-9._%+-]/,
    /[a-zA-Z0-9._%+-]/,
    /[a-zA-Z0-9._%+-]/,
    /[a-zA-Z0-9._%+-]/,
    '@',
    /[a-zA-Z0-9.-]/,
    /[a-zA-Z0-9.-]/,
    /[a-zA-Z0-9.-]/,
    '.',
    /[a-zA-Z]/,
    /[a-zA-Z]/,
  ];

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        if (typeof inputRef === 'function') {
          inputRef(ref ? ref.inputElement : null);
        }
      }}
      mask={mask === emailMaskDefault ? emailMask : mask}
      onClick={handleClick}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

export const TextField = ({
  variant = 'outlined',
  onChange,
  onBlur,
  type,
  multiline,
  mask,
  value,
  valueSer,
  size,
  keyStructure,
  help,
  validation,
  toReset,
  isLoading,
  transform,
  dontAllowSlash,
  staticField,
  onChangeServer,
  fieldType,
  handleKeyPress,
  ref,
  ...props
}) => {
  const businessPreference = useSelector((state) => state.core.businessData);

  const theme = useTheme();
  const dispatch = useDispatch();
  const isDarkmode = theme.palette.mode === 'dark';

  const { t } = useTranslation();
  let maskArray = mask;

  // Only transform if mask is a string
  if (typeof mask === 'string') {
    let maskString = mask || '';
    maskArray = maskString?.slice(1, -1)?.split(', ');

    maskArray = maskArray?.map((item) => {
      if (item?.startsWith('/') && item?.endsWith('/')) {
        return new RegExp(item?.slice(1, -1));
      }
      if (item?.startsWith("'") && item?.endsWith("'")) {
        return item.slice(1, -1);
      }
      return item;
    });
  }

  // Initialize the input value
  const currentPath = window.location.pathname;

  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isValidated, setIsValidate] = useState(false);

  const handleCopy = () => {
    let finalHelp = help?.startsWith('ID') ? help?.split('ID: ')[1] : help;
    navigator.clipboard.writeText(finalHelp);
    toast.success(t('copied'));
  };

  const onChangeField = (event) => {
    let newEvent = event;

    if (
      event?.target?.value?.includes('/') &&
      dontAllowSlash &&
      (fieldType === 'text' || fieldType === 'string')
    ) {
      newEvent = {
        target: {
          value: event.target.value.replace(/\//g, ''),
        },
      };
      setError('/');
    } else {
      setError('');
    }

    // Trigger onChange event
    onChange(newEvent, fieldType, null, null);
  };

  const checkData = async (keyTrans, collection) => {
    try {
      if (
        businessPreference?.formula?.toUpperCase() === 'RISE' ||
        businessPreference?.formula?.toUpperCase() === 'TRIAL'
      ) {
        const response = await nodeAxiosFirebase({
          t,
          method: 'POST',
          url: `business/check`,
          body: {
            key: keyTrans,
            collection: collection,
            value: value,
          },
        });

        if (response?.validation === true) {
          dispatch(
            setNodeAssistant({
              text: t('ohMayAlreadyExist' + keyTrans),
              color: '#9b111e',
            })
          );
        } else {
          dispatch(setNodeAssistant({ text: '', color: null }));
        }
      }
    } catch (error) {
      console.error('Error getting data', error);
    }
  };

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(event, fieldType);
    }
    if (keyStructure === 'targetEmail' && value) {
      checkData('targetEmail', 'connections');
    } else if (keyStructure === 'sku' && value) {
      checkData('sku', 'articles');
    } else {
      if (keyStructure === 'targetPhone' && value)
        checkData('targetPhone', 'connections');
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const transformValue = (value) => {
    if (transform === 'uppercase') {
      return value.toUpperCase();
    } else if (transform === 'lowercase') {
      return value.toLowerCase();
    } else if (transform === 'capitalize') {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  };

  useEffect(() => {
    if (validation !== 'none' && validation !== null) {
      try {
        // Ensure validation is a valid regex string
        const regex = new RegExp(validation);
        const isValid = regex.test(value);
        setIsValidate(isValid);
      } catch (e) {
        console.error('Invalid regex:', validation, e);
        setIsValidate(false);
      }
    }
    if (transform && value) {
      const transformedValue = transformValue(value);
      onChange({ target: { value: transformedValue } }, fieldType, null);
    }
  }, [value]);

  let transformedText = '';

  if (validation) {
    transformedText = validation
      .replace(/\\d\{(\d+)\}/g, (_, n) => '#'.repeat(n))
      .replace(/[\^$\\]/g, '');
  }

  return (
    <div>
      {type === 'cc' ? (
        <MUITextField
          variant={variant}
          {...props}
          InputLabelProps={{
            shrink: true,
          }}
          ref={ref}
          onChange={(event) => onChangeField(event, fieldType)}
          onBlur={onBlur && ((event) => onBlur(event, fieldType))}
          margin="normal"
          mask={maskArray}
          helperText={
            <span className="fs-10" onClick={handleCopy}>
              {help}
            </span>
          }
          type={type}
          error={props.error}
          onKeyDown={(event) =>
            handleKeyPress && handleKeyPress(event, fieldType)
          }
          value={value || ''}
          InputProps={{
            inputComponent: TextMaskCustom,
            inputProps: { mask: maskArray },
            endAdornment: (
              <InputAdornment sx={{ marginRight: '-4px' }} position="end">
                <CreditCard />
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiFormLabel-root': {
              backgroundColor: isDarkmode && 'rgb(51,51,51)',
              marginTop: size === 'small' ? '18px' : '',
            },
            '& .MuiOutlinedInput-root': {
              marginTop: size === 'small' ? '18px' : '',
              height: '25px',
              maxHeight: '25px',
              minHeight: '25px',
              '&.Mui-focused fieldset': {
                borderColor: businessPreference?.mainColor || '#000',
                boxShadow: `0 0 0 0.2rem ${businessPreference?.mainColor + '20'
                  }`,
              },
            },

          }}
        />
      ) : type === 'password' ? (
        <MUITextField
          variant={variant}
          {...props}
          ref={ref}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => onChangeField(event, fieldType)}
          onBlur={onBlur && ((event) => onBlur(event, fieldType))}
          margin="normal"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleKeyPress && handleKeyPress(e);
            }
          }}
          type={showPassword ? 'text' : 'password'}
          error={props.error}
          helperText={
            <span className="fs-10" onClick={handleCopy}>
              {help}
            </span>
          }
          value={staticField ? '' : value}
          InputProps={{
            endAdornment: (
              <InputAdornment sx={{ marginRight: '-4px' }} position="end">
                {showPassword ? (
                  <VisibilityOff
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  />
                ) : (
                  <Visibility
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  />
                )}
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiFormLabel-root': {
              backgroundColor: isDarkmode && 'rgb(51,51,51)',
              marginTop: size === 'small' ? '18px' : '',
            },
            '& .MuiOutlinedInput-root': {
              marginTop: size === 'small' ? '18px' : '',
              height: '25px',
              maxHeight: '25px',
              minHeight: '25px',
              '&.Mui-focused fieldset': {
                borderColor: businessPreference?.mainColor || '#000',
                boxShadow: `0 0 0 0.2rem ${businessPreference?.mainColor + '20'
                  }`,
              },
            },

          }}
        />
      ) : type === 'codeAccess' ? (
        <MUITextField
          variant={variant}
          {...props}
          ref={ref}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => onChangeField(event, fieldType)}
          onBlur={onBlur && ((event) => onBlur(event, fieldType))}
          margin="normal"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleKeyPress && handleKeyPress(e);
            }
          }}
          type={'text'}
          error={props.error}
          helperText={
            <span className="fs-10" onClick={handleCopy}>
              {help}
            </span>
          }
          value={staticField ? '' : value}
          InputProps={{
            endAdornment: (
              <InputAdornment sx={{ marginRight: '-4px' }} position="end">
                <VpnKeyOutlined />
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiFormLabel-root': {
              backgroundColor: isDarkmode && 'rgb(51,51,51)',
              marginTop: size === 'small' ? '18px' : '',
            },
            '& .MuiOutlinedInput-root': {
              marginTop: size === 'small' ? '18px' : '',
              height: '25px',
              maxHeight: '25px',
              minHeight: '25px',
              '&.Mui-focused fieldset': {
                borderColor: businessPreference?.mainColor || '#000',
                boxShadow: `0 0 0 0.2rem ${businessPreference?.mainColor + '20'
                  }`,
              },
            },

          }}
        />
      ) : validation !== null &&
        validation !== 'none' &&
        validation !== '' &&
        (fieldType === 'text' || fieldType === 'string') ? (
        <MUITextField
          variant={variant}
          {...props}
          ref={ref}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => onChangeField(event, fieldType)}
          onBlur={(event) => handleBlur(event, fieldType)}
          margin="normal"
          type={'text'}
          multiline={multiline || false}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleKeyPress && handleKeyPress(e);
            }
          }}
          error={props?.error || error === '/'}
          helperText={help || error === '/' ? t('barNotAllow') : ''}
          value={staticField ? '' : value}
          InputProps={{
            inputComponent: mask && TextMaskCustom,
            inputProps: { mask: mask && maskArray },
            endAdornment: (
              <InputAdornment sx={{ marginRight: '-4px' }} position="end">
                {isValidated ? (
                  <CheckCircle color="success" />
                ) : (
                  <Tooltip title={transformedText}>
                    <InfoOutlined color="error" />
                  </Tooltip>
                )}
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiFormLabel-root': {
              backgroundColor: isDarkmode && 'rgb(51,51,51)',
              marginTop: size === 'small' ? '18px' : '',
            },
            '& .MuiOutlinedInput-root': {
              marginTop: size === 'small' ? '18px' : '',
              height: '25px',
              maxHeight: '25px',
              minHeight: '25px',
              '&.Mui-focused fieldset': {
                borderColor: businessPreference?.mainColor || '#000',
                boxShadow: `0 0 0 0.2rem ${businessPreference?.mainColor + '20'
                  }`,
              },
            },

          }}
        />
      ) : (
        <MUITextField
          variant={variant}
          {...props}
          ref={ref}
          helperText={
            <span className="fs-10" onClick={handleCopy}>
              {help}
            </span>
          }
          InputLabelProps={{
            shrink: true,
          }}

          onChange={(event) => onChangeField(event, fieldType)}
          onBlur={(event) => handleBlur(event, fieldType)}
          margin="normal"
          type={type}
          error={props.error}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleKeyPress(e);
            }
          }}
          value={staticField ? '' : value}
          InputProps={{
            inputComponent: mask && TextMaskCustom,
            inputProps: { mask: mask && maskArray, },
            endAdornment: (
              <InputAdornment sx={{ marginRight: '-4px' }} position="end">
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : type === 'number' ? (
                  <NumbersOutlinedIcon />
                ) : type === 'search' ? (
                  <Search />
                ) : type === 'geo' ? (
                  <Map />
                ) : type === 'color' ? (
                  <ColorLens />
                ) : type === 'url' ? (
                  <Link />
                ) : type === 'phone' ? (
                  <Phone />
                ) : type === 'email' ? (
                  <Email />
                ) : type === 'comment' ? (
                  <Comment />
                ) : type === 'message' ? (
                  <Send />
                ) : (
                  <TextFieldsOutlinedIcon />
                )}
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiFormLabel-root': {
              backgroundColor: isDarkmode && 'rgb(51,51,51)',
              marginTop: size === 'small' ? '18px' : '',
            },
            '& .MuiOutlinedInput-root': {
              marginTop: size === 'small' ? '18px' : '',
              height: '25px',
              maxHeight: '25px',
              minHeight: '25px',
              '&.Mui-focused fieldset': {
                borderColor: businessPreference?.mainColor || '#000',
                boxShadow: `0 0 0 0.2rem ${businessPreference?.mainColor + '20'
                  }`,
              },
            },

          }}
        />
      )}
    </div>
  );
};

export default TextField;
