import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import OnboardLayout from './OnboardLayout';
import { TextField, Typography, Autocomplete } from '@mui/material';
import { setOnboardData } from '../../redux/actions-v2/coreAction';


const Step3 = () => {
    const dispatch = useDispatch();
    const { t, } = useTranslation();
    const pageCode = 'step3Onboard';


    const onboardData = useSelector((state) => state.core.onboardData);


    const businessTypes = [
        {
            value: 'accounting',
            label: t('accounting'),
            id: 'accounting',
        },
        {
            value: 'advertising',
            label: t('advertising'),
            id: 'advertising',
        },
        {
            value: 'agriculture',
            label: t('agriculture'),
            id: 'agriculture',
        },
        {
            value: 'automotive',
            label: t('automotive'),
            id: 'automotive',
        },
        {
            value: 'beauty',
            label: t('beauty'),
            id: 'beauty',
        },
        {
            value: 'businessServices',
            label: t('businessServices'),
            id: 'businessServices',
        },
        {
            value: 'construction',
            label: t('construction'),
            id: 'construction',
        },
        {
            value: 'consulting',
            label: t('consulting'),
            id: 'consulting',
        },
        {
            value: 'education',
            label: t('education'),
            id: 'education',
        },
        {
            value: 'energy',
            label: t('energy'),
            id: 'energy',
        },
        {
            value: 'engineering',
            label: t('engineering'),
            id: 'engineering',
        },
        {
            value: 'entertainment',
            label: t('entertainment'),
            id: 'entertainment',
        },
        {
            value: 'fashion',
            label: t('fashion'),
            id: 'fashion',
        },
        {
            value: 'finance',
            label: t('finance'),
            id: 'finance',
        },
        {
            value: 'food',
            label: t('food'),
            id: 'food',
        },
        {
            value: 'government',
            label: t('government'),
            id: 'government',
        },
        {
            value: 'healthcare',
            label: t('healthcare'),
            id: 'healthcare',
        },
        {
            value: 'hospitality',
            label: t('hospitality'),
            id: 'hospitality',
        },
        {
            value: 'humanResources',
            label: t('humanResources'),
            id: 'humanResources',
        },
        {
            value: 'informationTechnology',
            label: t('informationTechnology'),
            id: 'informationTechnology',
        },
        {
            value: 'insurance',
            label: t('insurance'),
            id: 'insurance',
        },
        {
            value: 'legal',
            label: t('legal'),
            id: 'legal',
        },
        {
            value: 'logistics',
            label: t('logistics'),
            id: 'logistics',
        },
        {
            value: 'manufacturing',
            label: t('manufacturing'),
            id: 'manufacturing',
        },
        {
            value: 'marketing',
            label: t('marketing'),
            id: 'marketing',
        },
        {
            value: 'media',
            label: t('media'),
            id: 'media',
        },
        {
            value: 'nonProfit',
            label: t('nonProfit'),
            id: 'nonProfit',
        },
        {
            value: 'other',
            label: t('other'),
            id: 'other',
        },
        {
            value: 'personalServices',
            label: t('personalServices'),
            id: 'personalServices',
        },
        {
            value: 'professionalServices',
            label: t('professionalServices'),
            id: 'professionalServices',
        },
        {
            value: 'realEstate',
            label: t('realEstate'),
            id: 'realEstate',
        },
        {
            value: 'retail',
            label: t('retail'),
            id: 'retail',
        },
        {
            value: 'science',
            label: t('science'),
            id: 'science',
        },
        {
            value: 'sports',
            label: t('sports'),
            id: 'sports',
        },
        {
            value: 'technology',
            label: t('technology'),
            id: 'technology',
        },
        {
            value: 'telecommunications',
            label: t('telecommunications'),
            id: 'telecommunications',
        },
        {
            value: 'transportation',
            label: t('transportation'),
            id: 'transportation',
        },
        {
            value: 'travel',
            label: t('travel'),
            id: 'travel',
        },
        {
            value: 'utilities',
            label: t('utilities'),
            id: 'utilities',
        },
        {
            value: 'wholesale',
            label: t('wholesale'),
            id: 'wholesale',
        },
    ];

    const numberEmployeesList = [
        {
            value: '0',
            label: t('onlyMe'),
            id: '0',
        },
        {
            value: '1-10',
            label: t('1-10'),
            id: '1-10',
        },
        {
            value: '11-50',
            label: t('11-50'),
            id: '11-50',
        },
        {
            value: '51-100',
            label: t('51-100'),
            id: '51-100',
        },
        {
            value: '101',
            label: t('100+'),
            id: '101',
        },
    ]

    return (

        <OnboardLayout stepTitle={t(pageCode + 'Title')} stepDesc={t(pageCode + 'Desc')}>

            <div className='mt-3 mb-3'>
                <Typography variant="caption" color="textSecondary" >
                    {t(pageCode + 'InputTitle')}
                </Typography>
                <Autocomplete
                    options={businessTypes}
                    getOptionLabel={(option) => option.label}
                    value={businessTypes.find(option => option.value === onboardData?.businessType) || null}
                    onChange={(event, newValue) =>
                        dispatch(setOnboardData({ ...onboardData, businessType: newValue?.value || '' }))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            sx={{ marginTop: '6px' }}
                        />
                    )}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                />

            </div>
            <div className='mt-3 mb-3'>
                <Typography variant="caption" color="textSecondary" >
                    {t(pageCode + 'InputTitleSec')}
                </Typography>
                <Autocomplete
                    options={numberEmployeesList}
                    getOptionLabel={(option) => option.label}
                    value={numberEmployeesList?.find(option => option.value === onboardData?.businessEmployees) || null}
                    onChange={(event, newValue) =>
                        dispatch(setOnboardData({ ...onboardData, businessEmployees: newValue?.value || '' }))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            sx={{ marginTop: '6px' }}
                        />
                    )}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                />
            </div>

        </OnboardLayout>

    );
};

export default Step3;
