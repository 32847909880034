import React, { } from 'react';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import {
  Button,
} from '@mui/material';
import Lottie from 'react-lottie';
import animationData from '../../../lotties/centralize.json';
import Typo from '../Typo';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

const ConnectCases = ({ translationCode }) => {
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const localStorageColor = localStorage.getItem('mainColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';

  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      progressiveLoad: false,
    },
  };

  const navigateDragDop = () => {
    navigate('/docs');
  };

  return (
    <div style={{ marginTop: '120px' }} className="container">
      <div className={isMobile ? 'row mt-5 px-4' : 'row mt-5'}>
        <div className="col-md-7 col-12 py-5">
          <Typo
            text={t(translationCode + 'Title')}
            variant="h2"
            className="mt-5"
          />
          <Typo
            text={t(translationCode + 'Desc')}
            variant="subTitle"
            className="mt-4"
            sx={{ maxWidth: '490px' }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={navigateDragDop}
            disableElevation
            style={{
              marginTop: '40px',
              textTransform: 'none',
              borderRadius: '50px',
              padding: '8px 20px',
              backgroundColor: darkMainColor,
            }}
          >
            {t(translationCode + 'CTA')}
          </Button>
        </div>
        <div
          style={{ position: 'relative' }}
          className="col-md-5 col-12 align-c"
        >
          <div
            style={{
              backgroundColor: darkMainColor + '20',
              borderRadius: '15px',
              width: '320px',
              height: '270px',
              position: 'absolute',
              top: 80,
              left: 50,
            }}
          />
          <div>
            <Lottie options={defaultOptions} height={500} width={600} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectCases;
