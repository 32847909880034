import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Typo from '../Typo';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import MissionSection from './Mission';

const Win = ({ translationCode }) => {
  const { t, } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const websiteData = useSelector((state) => state.website.data);

  return (
    <div
      style={{
        marginTop: '150px',
      }}
      className="container"
    >
      <div
        style={{
          maxWidth: isMobile ? '' : '820px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <div className="align-c mt-5 mb-3">
          <Typo variant="h2" text={websiteData?.home?.titleSec7} />
        </div>
        <div className="align-c mb-5">
          {' '}
          <Typo
            variant="body1"
            className="px-5"
            text={websiteData?.home?.subTitleSec2 || t('subTitleSec2')}
          />{' '}
        </div>
      </div>
      <div className={isMobile ? 'row mt-4 px-4' : 'row mt-4'}>
        <div
          className="col-md-6 col-12 d-flex flex-column "
          style={{ minHeight: '250px' }}
        >
          <div className="mt-3">
            <div>
              {' '}
              <Typo
                variant="smallTitle"
                text={websiteData?.home?.content1Title || t('content1Title')}
              />{' '}
            </div>
            <div>
              <Typo
                variant="p"
                text={websiteData?.home?.content1Text || t('content1Text')}
              />
            </div>
          </div>
          <div className="mt-4">
            <div>
              {' '}
              <Typo
                variant="smallTitle"
                text={websiteData?.home?.content2Title || t('content2Title')}
              />{' '}
            </div>
            <div>
              {' '}
              <Typo
                variant="p"
                text={websiteData?.home?.content2Text || t('content2Text')}
              />{' '}
            </div>
          </div>
          <div className="mt-4">
            <div>
              {' '}
              <Typo
                variant="smallTitle"
                text={websiteData?.home?.content3Title || t('content3Title')}
              />{' '}
            </div>
            <div>
              {' '}
              <Typo
                variant="p"
                text={websiteData?.home?.content3Text || t('content3Text')}
              />{' '}
            </div>
          </div>
        </div>

        <div className="col-md-6 col-12 mb-5 align-c">
          <MissionSection />
        </div>
      </div>
    </div>
  );
};

export default Win;
