import * as React from 'react';
const GrowthIcon = ({ mainColor }) => (
  <svg
    id="Calque_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 41.98 42.68"
  >
    <g id="Calque_1-2">
      <rect
        fill={mainColor}
        opacity={0.69}
        x={14.82}
        y={0}
        width={27.76}
        height={27.76}
        rx={13.88}
        ry={13.88}
        transform="translate(14.82 42.58) rotate(-90)"
      />
      <path
        fill={mainColor}
        d="M2.41,23.63c6.13-2.9,12.27-5.81,18.4-8.71,2.81-1.33,6.04.73,6.02,3.83l-.1,20.72c-.02,3.35-3.74,5.34-6.53,3.51L1.9,30.97c-2.75-1.81-2.47-5.93.51-7.34Z"
      />
    </g>
  </svg>
);
export default GrowthIcon;
