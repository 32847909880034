import React from 'react';

const VisualBuilderPerfo = ({ mainColor = '#3498db', light, large }) => {
  return (
    <svg
      width={large ? '86' : '56'}
      height={large ? '86' : '56'}
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 20.65C19 19.7387 19.7387 19 20.65 19H26.35C27.2613 19 28 19.7387 28 20.65V26.35C28 27.2613 27.2613 28 26.35 28H20.65C19.7387 28 19 27.2613 19 26.35V20.65Z"
        fill={mainColor}
        fill-opacity="0.78"
        stroke={light ? mainColor + '20' : 'white'}
        stroke-width="2.475"
      />
      <path
        d="M19 35.65C19 34.7387 19.7387 34 20.65 34H26.35C27.2613 34 28 34.7387 28 35.65V41.35C28 42.2613 27.2613 43 26.35 43H20.65C19.7387 43 19 42.2613 19 41.35V35.65Z"
        fill={mainColor}
        fill-opacity="0.78"
        stroke={light ? mainColor + '20' : 'white'}
        stroke-width="2.475"
      />
      <path
        d="M34 20.65C34 19.7387 34.7387 19 35.65 19H41.35C42.2613 19 43 19.7387 43 20.65V26.35C43 27.2613 42.2613 28 41.35 28H35.65C34.7387 28 34 27.2613 34 26.35V20.65Z"
        fill={mainColor}
        fill-opacity="0.78"
        stroke={light ? mainColor + '20' : 'white'}
        stroke-width="2.475"
      />
      <path
        d="M34 35.65C34 34.7387 34.7387 34 35.65 34H41.35C42.2613 34 43 34.7387 43 35.65V41.35C43 42.2613 42.2613 43 41.35 43H35.65C34.7387 43 34 42.2613 34 41.35V35.65Z"
        fill={mainColor}
        fill-opacity="0.78"
        stroke={light ? mainColor + '20' : 'white'}
        stroke-width="2.475"
      />
    </svg>
  );
};

export default VisualBuilderPerfo;
