// Libraries
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Dialog,
  DialogContent,
  Popper,
  Divider,
} from '@mui/material';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  collection,
  doc,
  query,
  where,
  onSnapshot,
  orderBy,
} from 'firebase/firestore';

// Utilities
import { db } from '../../firebase';

// Components
import HistoryMessage from './HistoryMessage';
import { setGeneralStatus } from '../../redux/actions-v2/coreAction';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import MainSearch from '../../components/@generalComponents/layout/MainSearch/MainSearch';

const MessagesFeed = ({
  userId,
  elementId,
  elementType,
  fromList,
  elementPath,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [logMessage, setLogMessage] = useState('');
  const [valueServer, setValueServer] = useState('');
  const [groupedLogs, setGroupedLogs] = useState({});
  const [mentionSuggestions, setMentionSuggestions] = useState([]);
  const { structureId } = useParams();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const logsContainerRef = useRef(null);
  const quillRef = useRef(null);
  const currentPath = window.location.pathname;
  const currentPageTitle = document.title;

  // State for mention functionality
  const [openMentionDialog, setOpenMentionDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openPopper, setOpenPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [clicked, setClicked] = useState(false);

  // Redux selectors
  const [logs, setLogs] = useState([]);
  const currentUser = useSelector((state) => state.core.user);
  const businessStructures = useSelector(
    (state) => state.core.businessStructure
  )?.structures;
  const businessPreference = useSelector((state) => state.core.businessData);
  const businessFirebaseID =
    businessPreference?.id || localStorage.getItem('businessId');

  // --- Firestore Listener ---
  useEffect(() => {
    let unsubscribe;
    if (elementPath && businessFirebaseID) {
      const getLogs = async () => {
        const businessRef = doc(db, 'businessesOnNode', businessFirebaseID);
        const logsCollectionPath = `${elementPath}/logs`;
        try {
          const q = query(
            collection(db, logsCollectionPath),
            where('isDone', '==', false),
            where('ownerId', '==', businessRef),
            orderBy('timeStamp', 'asc')
          );
          unsubscribe = onSnapshot(
            q,
            (querySnapshot) => {
              const fetchedLogs = [];
              querySnapshot.forEach((logDoc) => {
                fetchedLogs.push({ ...logDoc.data(), id: logDoc.id });
              });
              setLogs(fetchedLogs);
            },
            (error) => {
              console.error('Error fetching logs snapshot: ', error);
            }
          );
        } catch (error) {
          console.error('Error setting up logs query:', error);
          dispatch(setGeneralStatus({ status: 'error', error: error }));
        }
      };
      getLogs();
      return () => {
        if (unsubscribe) unsubscribe();
      };
    } else {
      setLogs([]);
      console.warn('MessagesFeed: Missing elementPath or businessFirebaseID.');
    }
  }, [elementPath, businessFirebaseID, dispatch]);

  // --- Grouping Logs by Date ---
  const groupLogsByDate = (logsToGroup) => {
    // ... (grouping logic remains the same) ...
    const grouped = {};
    logsToGroup.forEach((log) => {
      const dateKey = moment
        .unix(
          log?.timeStamp?.seconds ||
          log?.timeStamp?._seconds ||
          Date.now() / 1000
        )
        .format('YYYY-MM-DD');
      if (!grouped[dateKey]) grouped[dateKey] = [];
      grouped[dateKey].push({
        ...log,
        sortTimestamp: log?.timeStamp?.seconds || 0,
      });
    });
    Object.keys(grouped).forEach((dateKey) =>
      grouped[dateKey].sort((a, b) => a.sortTimestamp - b.sortTimestamp)
    );
    const sortedDates = Object.keys(grouped).sort((a, b) =>
      moment(a, 'YYYY-MM-DD').diff(moment(b, 'YYYY-MM-DD'))
    );
    const sortedGroupedLogs = {};
    sortedDates.forEach((date) => {
      sortedGroupedLogs[date] = grouped[date];
    });
    return sortedGroupedLogs;
  };

  useEffect(() => {
    setGroupedLogs(groupLogsByDate(logs));
  }, [logs]);

  // --- Quill Change Handler ---
  const handleChange = (value) => {
    let updatedValue = value;

    const lastWord = value.split(/\s+/).pop();
    const isMentionTriggered =
      lastWord.startsWith('@') || lastWord.startsWith('<p>@');

    if (isMentionTriggered) {
      setAnchorEl(quillRef.current?.editor?.container);
      setOpenPopper(true);
    } else {
      setOpenPopper(false);
    }

    // Mention Replacement
    let updatedServerValue = updatedValue;
    const storedMentions = [];

    for (const mention of mentionSuggestions) {
      const {
        name,
        startIndex,
        path,
        structureId,
        documentId,
        collectionField,
      } = mention;

      // Add mention to the storage
      storedMentions.push({ name, startIndex, path });

      // Replace in the serverValue
      updatedServerValue = updatedServerValue.replace(
        `@${name}`,
        `<a id=${documentId} path=${path} structure=${structureId} from=${currentPath} collection=${collectionField} title=${currentPageTitle}> @${name} </a>`
      );
    }

    setValueServer(updatedServerValue);
    setLogMessage(value);
  };

  // --- Toolbar Options ---
  const toolbarOptions = useMemo(
    () => [
      ['bold', 'italic', 'underline'],
      ['link'],
      // ['link', 'image', 'video', 'formula'],
      [{ size: ['small', 'medium'] }],
      [{ color: [] }],
      [{ align: [] }],
    ],
    []
  );

  // --- Log Creation ---
  const handleLogCreation = useCallback(async () => {
    // ... (handleLogCreation logic remains the same) ...
    const editor = quillRef.current?.getEditor();
    const plainText = editor ? editor.getText().trim() : '';
    if (!plainText || !elementPath || !elementId) {
      toast.warn(t('messageCannotBeEmpty'));
      return;
    }
    let elementFinalType = elementType || 'unknown';
    if (
      ['cardsuninvoiced', 'cardsinvoiced', 'cardsexpense'].includes(elementType)
    ) {
      elementFinalType = 'cards';
    }
    const structureForPath = structureId || elementPath.split('/')[3];
    if (!structureForPath) {
      toast.error(t('cannotDetermineStructure'));
      return;
    }

    const positionId = `log-creation-${elementId}`;
    dispatch(setGeneralStatus({ status: 'loading', position: positionId }));
    try {
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/log`,
        body: {
          elementId: elementId,
          elementPath: elementPath,
          description: valueServer, // Send formatted HTML
          elementFinalType: `${elementFinalType}:message`,
          documentPath: `/app/element/${elementFinalType}/${structureForPath}/${elementId}`,
          name: currentUser?.displayName || 'User',
          userContactId: userId || null,
        },
      });
      setLogMessage('');
      setValueServer('');
      if (editor) {
        editor.setContents([{ insert: '\n' }]);
      }
      dispatch(setGeneralStatus({ status: 'success', position: positionId }));
    } catch (error) {
      console.error('Error creating log:', error);
      dispatch(
        setGeneralStatus({ status: 'error', position: positionId, error })
      );
    }
  }, [
    valueServer,
    elementPath,
    elementId,
    elementType,
    structureId,
    currentUser?.displayName,
    userId,
    dispatch,
    t,
  ]);

  // --- Keyboard Shortcut ---
  useEffect(() => {
    // ... (keyboard shortcut logic remains the same) ...
    const handleGlobalKeyDown = (event) => {
      const isQuillFocused = quillRef.current?.editor?.hasFocus();
      if (
        isQuillFocused &&
        (event.metaKey || event.ctrlKey) &&
        event.key === 'Enter'
      ) {
        event.preventDefault();
        handleLogCreation();
      }
    };
    document.addEventListener('keydown', handleGlobalKeyDown);
    return () => document.removeEventListener('keydown', handleGlobalKeyDown);
  }, [handleLogCreation]);

  // --- Auto Scroll --- NEW/IMPROVED LOGIC ---
  useEffect(() => {
    const scrollToBottom = () => {
      const scrollContainer = logsContainerRef.current;
      if (scrollContainer) {
        // Use setTimeout to ensure DOM update and layout calculation is complete
        setTimeout(() => {
          if (scrollContainer) {
            // Check ref again inside timeout
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
          }
        }, 50); // Small delay (adjust if needed, 0 might work sometimes)
      }
    };
    // Scroll whenever the logs array changes
    scrollToBottom();
  }, [logs]); // Dependency remains on the raw logs array

  // --- Mention Handling ---

  const handleMentionDialogClose = () => setOpenMentionDialog(false);
  const handleStructureSelect = (elementCollection, structId) => {
    setSelectedCategory({ id: structId, collection: elementCollection });
    setOpenMentionDialog(true);
    setOpenPopper(false);
  };

  const handleElementSelect = (elementPath, elementname) => {
    const updatedValue = logMessage?.replace(
      /@[^\s]*\s?$/,
      ` @${elementname} `
    );
    handleChange(updatedValue);

    setValueServer(
      valueServer +
      `<a id=${elementPath
        .split('/')
        .pop()} path=${elementPath} from=${currentPath} structure=${selectedCategory?.id
      } collection=${selectedCategory?.collection} > @${elementname} </a>`
    );

    //add to mentions suggestions
    const newMention = {
      name: elementname,
      path: elementPath,
      structureId: selectedCategory?.id,
      documentId: elementPath.split('/').pop(),
      collectionField: selectedCategory?.collection,
    };
    setMentionSuggestions((prevSuggestions) => [
      ...prevSuggestions,
      newMention,
    ]);

    handleClose();
    setTimeout(() => {
      const quill = quillRef.current?.getEditor();
      if (quill) {
        const contentLength = quill.getLength();
        quill.setSelection(contentLength - 1, 0);
      }
    }, 500);
  };
  const handleClose = () => setOpenMentionDialog(false);
  // --- Render ---
  return (
    <>
      <Dialog
        sx={{ zIndex: 1301 }}
        open={openMentionDialog}
        onClose={handleMentionDialogClose}
      >
        <DialogContent sx={{ padding: 0, width: '450px', height: '450px' }}>
          <MainSearch
            fromExternal
            setClicked={setClicked}
            clicked={clicked}
            structureIden={selectedCategory?.id}
            onSelectReturn={handleElementSelect}
            structureCollection={selectedCategory?.collection}
          />
        </DialogContent>
      </Dialog>
      <Popper
        open={openPopper}
        anchorEl={anchorEl}
        placement="top-start"
        sx={{
          zIndex: 1300,
          bgcolor: 'background.paper',
          boxShadow: 3,
          borderRadius: 1,
          mt: 1,
        }}
        modifiers={[{ name: 'flip', enabled: false }]}
      >
        <List
          dense
          disablePadding
          sx={{ maxHeight: '200px', overflowY: 'auto' }}
        >
          {businessStructures?.map((category) => (
            <ListItem divider key={category.id} disablePadding>
              <ListItemButton
                onClick={() =>
                  handleStructureSelect(category?.collectionField, category?.id)
                }
              >
                <ListItemText primary={category?.name} />
              </ListItemButton>
            </ListItem>
          ))}
          {businessPreference?.id && (
            <ListItem divider key={'employees'} disablePadding>
              <ListItemButton
                onClick={() =>
                  handleStructureSelect('employees', businessPreference.id)
                }
              >
                <ListItemText primary={t('teamMembers')} />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Popper>

      {/* Main Layout */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          overflow: 'hidden',
          backgroundColor: isDarkMode ? '#1e1e1e' : '#f9fafb',
        }}
      >
        {/* Message List Area */}
        <div
          style={{
            flexGrow: 1, // Takes available space
            overflowY: 'auto', // The designated scroll container
            overflowX: 'hidden',
            padding: '10px 16px',
            scrollBehavior: 'smooth', // Optional: smooth scroll visual
          }}
          ref={logsContainerRef} // Attach ref here
        >
          {Object.keys(groupedLogs)?.length === 0 && (
            <div
              style={{
                textAlign: 'center',
                color: theme.palette.text.secondary,
                marginTop: '20px',
                fontSize: '0.9rem',
              }}
            >
              {' '}
              {t('noMessagesYet')}{' '}
            </div>
          )}
          {Object.keys(groupedLogs).map((date) => (
            <React.Fragment key={date}>
              <Divider
                sx={{
                  marginY: 2,
                  fontSize: '0.75rem',
                  color: 'text.secondary',
                  fontWeight: 'medium',
                }}
              >
                <div style={{ fontSize: '9px' }}>
                  {moment(date, 'YYYY-MM-DD').calendar(null, {
                    sameDay: `[${t('today')}]`,
                    lastDay: `[${t('yesterday')}]`,
                    lastWeek: 'dddd',
                    sameElse: 'DD MMM YYYY',
                  })}
                </div>
              </Divider>
              {groupedLogs[date].map((log, index, arr) => {
                const isLastMessage = index === arr?.length - 1;
                return (
                  <HistoryMessage
                    key={log.id}
                    userId={log?.assignedToId}
                    type={log?.type}
                    text={log?.description || ''}
                    name={log?.name}
                    timestamp={moment
                      .unix(
                        log?.timeStamp?.seconds ||
                        log?.timeStamp?._seconds ||
                        Date.now() / 1000
                      )
                      .fromNow()}
                    isLastMessage={isLastMessage}
                  />
                );
              })}
            </React.Fragment>
          ))}
        </div>
        {!fromList && (
          <div
            style={{
              flexShrink: 0,
              padding: '10px 15px',
              borderTop: `1px solid ${theme.palette.divider}`,
              backgroundColor: isDarkMode ? '#2c2c2c' : '#ffffff',
            }}
          >
            <div style={{ position: 'relative' }}>
              <ReactQuill
                ref={quillRef}
                value={logMessage}
                key={elementId + 'messages-quill'}
                onChange={handleChange}
                theme="snow"
                placeholder={t('enterTextType')}
                style={{
                  maxHeight: '250px',
                  overflow: 'auto',
                  backgroundColor: isDarkMode ? '#383838' : '#fff',
                }}
                modules={{ toolbar: toolbarOptions }}
              />
              <div
                style={{
                  position: 'absolute',
                  bottom: 8,
                  right: 8,
                  zIndex: 10,
                }}
              >
                <IconButton
                  size="small"
                  style={{
                    backgroundColor:
                      businessPreference?.secColor ||
                      theme.palette.secondary.main,
                    color: theme.palette.getContrastText(
                      businessPreference?.secColor ||
                      theme.palette.secondary.main
                    ),
                    boxShadow: theme.shadows[2],
                  }}
                  onClick={handleLogCreation}
                  disabled={!quillRef.current?.getEditor().getText().trim()}
                  title={t('send') + ' (Cmd+Enter)'}
                >
                  <ArrowUpwardOutlinedIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MessagesFeed;
