import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import Typo from '../Typo';
import { useMediaQuery } from '@mui/material';
import TextField from '../../../stories/general-components/TextField';
import { useNavigate } from 'react-router-dom';
import nodeAxiosFirebase from '../../../utils/nodeAxiosFirebase';
import { toast } from 'react-toastify';

const HeaderEntreprise = ({ translationCode }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';
  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const secColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  const [formData, setFormData] = useState({ page: 'entreprise' });

  const handleFieldChange = (e, fieldName) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [fieldName]: value });
  };

  const handleSendForm = async () => {
    try {
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `https://hook.us1.make.com/z0wn6qfhf6jihsbq82e2jgl2at763gxg`,
        body: formData,
      });
    } catch (error) {
      console.error('Error', error);
    }
    toast.success(t('sent'));
    setFormData({
      page: 'entreprise',
      name: '',
      business: '',
      email: '',
      phone: '',
      message: '',
    });
  };

  const navigateContact = () => {
    navigate('/contact');
  };

  const navigateOnboard = () => {
    navigate('/onboard');
  };

  return (
    <>
      <div
        style={{
          marginTop: isMobile ? '120px' : '200px',
          position: 'relative',
          minHeight: '70vh',
        }}
        className="container"
      >
        <div className="row" style={{ position: 'absolute', zIndex: 10 }}>
          <div
            className={
              isMobile ? 'col-md-7 col-12 py-5 px-5' : 'col-md-7 col-12 py-5'
            }
          >
            <Typo
              text={t(translationCode + 'Top')}
              variant="h2"
              sx={{ maxWidth: '400px' }}
            />
            <Typo
              text={t(translationCode + 'Text')}
              variant="body2"
              className={'mt-5'}
              sx={{ maxWidth: '400px' }}
            />
            <div className="d-flex mt-4">
              {' '}
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={navigateContact}
                style={{
                  marginTop: '10px',
                  marginRight: '25px',
                  textTransform: 'none',
                  borderRadius: '50px',
                  backgroundColor: mainColor,
                  padding: '8px 20px',
                }}
              >
                {t(translationCode + 'CTA1')}
              </Button>{' '}
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={navigateOnboard}
                style={{
                  marginTop: '10px',
                  textTransform: 'none',
                  padding: '8px 20px',
                  borderRadius: '50px',
                  backgroundColor: darkMainColor,
                }}
              >
                {t(translationCode + 'CTA2')}
              </Button>
            </div>
          </div>
          <div className="col-md-5 col-12">
            <div
              style={{
                backgroundColor: isDarkMode ? '#00000090' : '#FFFFFF',
                borderRadius: '15px',
                padding: isMobile ? '27px' : '35px',
              }}
            >
              <TextField
                value={formData?.name}
                label={t('name')}
                keyStructure={'name'}
                onChange={(e) => handleFieldChange(e, 'name')}
              />
              <TextField
                value={formData?.business}
                label={t('business')}
                keyStructure={'business'}
                onChange={(e) => handleFieldChange(e, 'business')}
              />
              <TextField
                value={formData?.email}
                label={t('email')}
                type="email"
                keyStructure={'email'}
                onChange={(e) => handleFieldChange(e, 'email')}
              />
              <TextField
                value={formData?.phone}
                label={t('phone')}
                type="phone"
                keyStructure={'phone'}
                onChange={(e) => handleFieldChange(e, 'phone')}
              />
              <TextField
                value={formData?.message}
                type="text"
                keyStructure={'message'}
                label={t('message')}
                onChange={(e) => handleFieldChange(e, 'message')}
                multiline
              />
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleSendForm}
                style={{
                  marginTop: '10px',
                  textTransform: 'none',
                  borderRadius: '50px',
                  padding: '8px 20px',
                  backgroundColor: darkMainColor,
                }}
              >
                {t('send')}
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{ position: 'absolute', right: -150, top: -190, zIndex: 1 }}
        >
          <img
            src="/assets/website/v3/grid-main.svg"
            width={'100%'}
            alt="Grid"
            style={{ opacity: isDarkMode ? 0.2 : 0.5 }}
          />
        </div>
      </div>
      <div>
        <Box
          sx={{
            marginTop: '-50px',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '70px',
            gap: { xs: 2, sm: 6 },
          }}
        >
          <Box
            component="img"
            src="/assets/website/v3/garex.png"
            alt="Garex Logo"
            sx={{ height: { xs: '30px', sm: '35px' }, maxWidth: '100%' }}
          />
          <Box
            component="img"
            src="/assets/website/v3/point-s.png"
            alt="Point S Logo"
            sx={{ height: { xs: '30px', sm: '35px' }, maxWidth: '100%' }}
          />
          <Box
            component="img"
            src="/assets/website/v3/packmat.png"
            alt="Packmat Logo"
            sx={{
              height: { xs: '20px', sm: '25px' },
              maxWidth: '100%',
              display: { xs: 'block', md: 'block' },
            }}
          />
          <Box
            component="img"
            src="/assets/website/v3/trivel.png"
            alt="Trivel Logo"
            sx={{
              height: { xs: '30px', sm: '35px' },
              maxWidth: '100%',
              display: { xs: 'block', md: 'block' },
            }}
          />
          <Box
            component="img"
            src="/assets/website/v3/automod.png"
            alt="Automod Logo"
            sx={{
              height: { xs: '30px', sm: '35px' },
              maxWidth: '100%',
              marginTop: { xs: '-2px', sm: '-4px' },
              display: { xs: 'block', md: 'block' },
            }}
          />
        </Box>
      </div>
    </>
  );
};

export default HeaderEntreprise;
