import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import OnboardLayout from './OnboardLayout';
import { useTheme } from '@mui/material/styles';
import { TextField, Typography, Autocomplete, CircularProgress, Box, FormControl, InputLabel } from '@mui/material';
import { setOnboardData } from '../../redux/actions-v2/coreAction';
import { Upload } from '@mui/icons-material';


const Step4 = () => {
    const dispatch = useDispatch();
    const { t, } = useTranslation();
    const pageCode = 'step4Onboard';
    const theme = useTheme();
    const isDarkmode = theme.palette.mode === 'dark';
    const [uploading, setUploading] = useState(false)

    const onboardData = useSelector((state) => state.core.onboardData);


    const langs = [
        { id: 'en', label: 'English', value: 'en' },
        { id: 'fr', label: 'Français', value: 'fr' },
    ]

    const currencies = [
        { id: 'CAD', label: 'CAD', value: 'cad' },
        { id: 'USD', label: 'USD', value: 'usd' },
        { id: 'EUR', label: 'EUR', value: 'eur' },
    ]

    function createUploadMetadata(metadata) {
        return Object.entries(metadata)
            .map(([key, value]) => {
                const base64Value = btoa(unescape(encodeURIComponent(String(value))));
                return `${key} ${base64Value}`;
            })
            .join(',');
    }

    async function formDataUpload(params) {
        const baseUrl = 'https://api.bytescale.com';
        const path = `/v2/accounts/${params.accountId}/uploads/form_data`;
        const entries = (obj) =>
            Object.entries(obj).filter(([, val]) => (val ?? null) !== null);
        const query = entries(params.querystring ?? {})
            .flatMap(([k, v]) => (Array.isArray(v) ? v.map((v2) => [k, v2]) : [[k, v]]))
            .map((kv) => kv.join('='))
            .join('&');
        const formData = new FormData();
        formData.append('file', params.requestBody, params.originalFileName);

        // Encode metadata
        const uploadMetadata = createUploadMetadata(params.metadata);

        const response = await fetch(
            `${baseUrl}${path}${query.length > 0 ? '?' : ''}${query}`,
            {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${params.apiKey}`,
                    'Upload-Metadata': uploadMetadata,
                },
            }
        );
        const result = await response.json();
        if (Math.floor(response.status / 100) !== 2) {
            console.error(`Bytescale API Error: ${JSON.stringify(result)}`);
            throw new Error(`Bytescale API Error: ${JSON.stringify(result)}`);
        }
        return result;
    }

    const handleUpload = useCallback(async (files) => {
        setUploading(true);
        try {
            const file = files[0];

            if (file.type !== 'image/png') {
                alert('Only PNG files are allowed.');
                setUploading(false);
                return;
            }

            const onboardId = onboardData?.id;
            if (!onboardId) {
                alert('onboardId is missing.');
                setUploading(false);
                return;
            }

            const result = await formDataUpload({
                accountId: 'kW15buA',
                apiKey: 'public_kW15buA4B6U6YzcBgoEsrFxYm4BE',
                requestBody: file,
                originalFileName: file.name,
                folderPathVariablesEnabled: true,
                metadata: {
                    elementType: String('logo'),
                    ownerId: String(onboardId),
                },
                querystring: {
                    folderPath: '/public/' + (onboardId)
                },
            });

            const fileUrl =
                result.fileUrl ||
                (result.files && result.files[0] && result.files[0].fileUrl);

            await fetch(`https://northamerica-northeast1-node-canada.cloudfunctions.net/business/logo/${onboardId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ logoUrl: fileUrl, onboardId }),
            });



        } catch (error) {
            console.error('Upload failed', error);
            alert('An error occurred during upload.');
        }
        setUploading(false);
    }, [dispatch, onboardData]);

    const handleRemoveFile = () => {

    }

    const onDrop = useCallback(
        (acceptedFiles) => {
            handleUpload(acceptedFiles);
        },
        [handleUpload]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': []
        },
    });

    return (

        <OnboardLayout stepTitle={t(pageCode + 'Title')} stepDesc={t(pageCode + 'Desc')} restrictNext={false}>
            <div className='mt-3'>
                <FormControl
                    fullWidth
                    margin="normal"
                    sx={{
                        borderRadius: '10px',
                    }}
                >
                    <InputLabel
                        shrink={true}
                        sx={{
                            backgroundColor: isDarkmode
                                ? 'rgb(51,51,51)'
                                : '#FFFFFF !important',
                            padding: '2px 10px 2px 10px',
                            borderRadius: '10px',
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: isDarkmode
                                    ? 'rgb(51,51,51)'
                                    : '#FFFFFF !important',
                            },
                        }}
                    >
                        {t(pageCode + 'Label')}
                    </InputLabel>
                    <Box
                        sx={{
                            border: '2px dashed #cccccc',
                            borderRadius: '10px',
                            padding: '20px',
                            textAlign: 'center',
                            backgroundColor: isDragActive ? '#f0f0f0' : 'background.paper',
                            cursor: 'pointer',
                        }}
                    >
                        {onboardData?.logo && (
                            <img
                                src={onboardData?.logo}
                                alt="Uploaded Logo"
                                style={{ width: '100px', height: '100px', marginBottom: '10px' }}
                            />
                        )}
                        <Box {...getRootProps()}>
                            <input {...getInputProps()} />
                            {uploading ? (
                                <CircularProgress />
                            ) : (
                                <>
                                    {!onboardData?.logo ? (
                                        <>
                                            <Upload sx={{ fontSize: 25, color: '#999999' }} />
                                            <Typography variant="body1" color="textSecondary">
                                                {t('dragFropOrClick')}
                                            </Typography>
                                        </>
                                    ) : (
                                        <div className="d-flex middle-content mt-3 align-right">
                                            <Upload
                                                sx={{
                                                    fontSize: 25,
                                                    color: '#999999',
                                                    marginRight: '10px',
                                                }}
                                            />
                                            <Typography variant="body1" color="textSecondary">
                                                {t('add') + ' ' + t('new')}
                                            </Typography>
                                        </div>
                                    )}
                                </>
                            )}
                        </Box>
                    </Box>
                </FormControl>
            </div>
            <div className='row mt-3'>
                <div className='col-7'>
                    <Typography variant="caption" color="textSecondary" >
                        {t(pageCode + 'InputTitle')}
                    </Typography>
                    <Autocomplete
                        multiple
                        options={langs}
                        getOptionLabel={(option) => option.label}
                        value={onboardData?.lang?.map(langVal => langs.find(l => l.value === langVal)).filter(Boolean) || []}
                        onChange={(event, newValue) =>
                            dispatch(setOnboardData({ ...onboardData, lang: newValue.map(v => v.value) }))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                                sx={{ marginTop: '6px', height: 'auto' }}
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                </div>
                <div className='col-5'>
                    <Typography variant="caption" color="textSecondary" >
                        {t(pageCode + 'InputTitleSec')}
                    </Typography>
                    <Autocomplete
                        options={currencies}
                        getOptionLabel={(option) => option.label}
                        value={currencies?.find(option => option.value === onboardData?.currency) || null}
                        onChange={(event, newValue) =>
                            dispatch(setOnboardData({ ...onboardData, currency: newValue?.value || '' }))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                                sx={{ marginTop: '6px' }}
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                </div>
            </div>


        </OnboardLayout>

    );
};

export default Step4;
