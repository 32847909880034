import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import HeaderSection from '../components/pulse/integrationsSections/Header';
import ListSection from '../components/pulse/integrationsSections/List';
import SiteLayout from '../../websiteV3/SiteLayout';

const Integrations = () => {
  const { t, } = useTranslation();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [inputSearchValue, setInputSearchValue] = useState('');


  const handleSearch = (e) => {
    setInputSearchValue(e.target.value);
  };
  const pageCode = 'integrations';

  return (
    <SiteLayout
      helmetData={{
        name: t(pageCode + 'SEO'),
        description: t(pageCode + 'SEODesc'),
      }}
    >
      <div className="container">
        <HeaderSection
          isDarkMode={isDarkMode}
          inputSearchValue={inputSearchValue}
          handleSearch={handleSearch}
        />
        <ListSection
          isDarkMode={isDarkMode}
          handleSearch={handleSearch}
          inputSearchValue={inputSearchValue}
        />
      </div>
    </SiteLayout>
  );
};

export default Integrations;
