import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';
import { AccordionSummary, Accordion, AccordionDetails } from '@mui/material';
import Typo from '../Typo';

const ModulesFAQ = ({ translationCode }) => {
  const { t, i18n } = useTranslation();
  const isMobile = window.innerWidth <= 768;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const secColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const lightMainColor = chroma(mainColor + '30')
    .brighten(2.2)
    .hex();
  const lightVarColor = chroma(secColor).brighten(2.2).hex();

  return (
    <div className="container">
      <div className={'block-separator'} style={{ minHeight: '500px' }}>
        <div className="row">
          <div className="col-md-5 col-12 align-c">
            <img src={'/assets/website/v3/logos-softwares.png'} height={110} />
          </div>
          <div className="col-md-7 col-12 px-5">
            <Typo
              variant="h2"
              text={t(translationCode + 'Integration')}
              className="mb-3 mt-5"
            />
            <Typo
              variant="body2"
              text={t(translationCode + 'IntegrationSub')}
              className="mb-5"
              sx={{ maxWidth: '550px' }}
            />
          </div>
        </div>
        <div className={isMobile ? 'px-4' : ''}>
          <div className="align-left block-separator px-3">
            <Typo
              variant="h2"
              text={t(translationCode + 'Title')}
              className="mb-3 mt-5"
            />
            <Typo
              variant="body2"
              text={t(translationCode + 'Sub')}
              className="mb-5"
              sx={{ maxWidth: '650px' }}
            />
          </div>
          <Accordion elevation={0} sx={{ backgroundColor: 'transparent' }}>
            <AccordionSummary>
              <Typo variant="smallTitle" text={t(translationCode + 'FAQ1')} />
            </AccordionSummary>
            <AccordionDetails>
              <Typo variant="p" text={t(translationCode + 'FAQ1Desc')} />
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} sx={{ backgroundColor: 'transparent' }}>
            <AccordionSummary>
              <Typo variant="smallTitle" text={t(translationCode + 'FAQ2')} />
            </AccordionSummary>
            <AccordionDetails>
              <Typo variant="p" text={t(translationCode + 'FAQ2Desc')} />
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} sx={{ backgroundColor: 'transparent' }}>
            <AccordionSummary>
              <Typo variant="smallTitle" text={t(translationCode + 'FAQ3')} />
            </AccordionSummary>
            <AccordionDetails>
              <Typo variant="p" text={t(translationCode + 'FAQ3Desc')} />
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} sx={{ backgroundColor: 'transparent' }}>
            <AccordionSummary>
              <Typo variant="smallTitle" text={t(translationCode + 'FAQ4')} />
            </AccordionSummary>
            <AccordionDetails>
              <Typo variant="p" text={t(translationCode + 'FAQ4Desc')} />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default ModulesFAQ;
