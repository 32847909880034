export const MODAL_CONFIRMATION = 'MODAL_CONFIRMATION';
export const MODAL_APPOINTMENT_CONFIRMATION = 'MODAL_APPOINTMENT_CONFIRMATION';
export const MODAL_GEMINI = 'MODAL_GEMINI';
export const MODAL_OPTIONS = 'MODAL_OPTIONS';
export const MODAL_HANDLE_OPTIONS = 'MODAL_HANDLE_OPTIONS';
export const MODAL_ADD_ITEM = 'MODAL_ADD_ITEM';
export const MODAL_ELEMENT_CREATION = 'MODAL_ELEMENT_CREATION';
export const MODAL_PULSE_CREATION = 'MODAL_PULSE_CREATION';
export const MODAL_SCAN = 'MODAL_SCAN';
export const MODAL_INVOICE = 'MODAL_INVOICE';
export const MODAL_CONTACT = 'MODAL_CONTACT';
export const MODAL_CREATE_VARIANT = 'MODAL_CREATE_VARIANT';
export const MODAL_EMAIL = 'MODAL_EMAIL';
export const MODAL_QR_CODE = 'MODAL_QR_CODE';
export const MODAL_CONNECTORS = 'MODAL_CONNECTORS';
export const MODAL_SIGN_IN = 'MODAL_SIGN_IN';
export const MODAL_SCRIPT = 'MODAL_SCRIPT';
export const MODAL_STATEMENT = 'MODAL_STATEMENT';
export const MODAL_LOG_PRINT = 'MODAL_LOG_PRINT';
export const MODAL_EMPLOYEE = 'MODAL_EMPLOYEE';
export const MODAL_CONVERT = 'MODAL_CONVERT';
export const MODAL_ADD_ROOM = 'MODAL_ADD_ROOM';
export const MODAL_CONNECTOR = 'MODAL_CONNECTOR';

export const modalAppointmentConfirmation = (payload) => ({
  type: MODAL_APPOINTMENT_CONFIRMATION,
  payload,
});

export const modalGemini = (payload) => ({
  type: MODAL_GEMINI,
  payload,
});

export const modalConfirmation = (payload) => ({
  type: MODAL_CONFIRMATION,
  payload,
});

export const modalConvert = (payload) => ({
  type: MODAL_CONVERT,
  payload,
});

export const modalOptions = (payload) => ({
  type: MODAL_OPTIONS,
  payload,
});

export const modalHandleOptions = (payload) => ({
  type: MODAL_HANDLE_OPTIONS,
  payload,
});

export const modalAddItem = (payload) => ({
  type: MODAL_ADD_ITEM,
  payload,
});

export const modalConnector = (payload) => ({
  type: MODAL_CONNECTOR,
  payload,
});

export const modalElementCreation = (payload) => ({
  type: MODAL_ELEMENT_CREATION,
  payload,
});

export const modalPulseCreation = (payload) => ({
  type: MODAL_PULSE_CREATION,
  payload,
});

export const modalScan = (payload) => ({
  type: MODAL_SCAN,
  payload,
});

export const modalInvoice = (payload) => ({
  type: MODAL_INVOICE,
  payload,
});

export const modalContact = (payload) => ({
  type: MODAL_CONTACT,
  payload,
});

export const modalCreateVariant = (payload) => ({
  type: MODAL_CREATE_VARIANT,
  payload,
});

export const modalEmail = (payload) => ({
  type: MODAL_EMAIL,
  payload,
});

export const modalQRCode = (payload) => ({
  type: MODAL_QR_CODE,
  payload,
});

export const modalConnectors = (payload) => ({
  type: MODAL_CONNECTORS,
  payload,
});

export const modalSignIn = (payload) => ({
  type: MODAL_SIGN_IN,
  payload,
});

export const modalScript = (payload) => ({
  type: MODAL_SCRIPT,
  payload,
});

export const modalStatement = (payload) => ({
  type: MODAL_STATEMENT,
  payload,
});

export const modalLogPrint = (payload) => ({
  type: MODAL_LOG_PRINT,
  payload,
});

export const modalEmployee = (payload) => ({
  type: MODAL_EMPLOYEE,
  payload,
});

export const modalAddRoom = (payload) => ({
  type: MODAL_ADD_ROOM,
  payload,
});
