import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextComponent = ({
  tags = [],
  comp,
  col,
  value,
  handleDataChange,
  layoutId,
  layoutIndex,
  selectedSettings,
}) => {
  const { t } = useTranslation();
  const [text, setText] = useState(value);
  const [toolbarOptions, setToolbarOptions] = useState([]);
  const [editorKey, setEditorKey] = useState(`${comp}-${layoutId}-${col}`);

  const quillRef = useRef(null);

  const handleChange = (value) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const delta = quill.getContents();
      setText(delta);
      handleDataChange(col, comp, layoutIndex, delta);
    }
  };

  useEffect(() => {
    if (selectedSettings?.index === comp && selectedSettings?.id === layoutId) {
      setToolbarOptions([
        ['bold', 'italic', 'underline', 'strike'],
        ['link', 'image', 'video', 'formula'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ['clean'],
      ]);
    } else {
      setToolbarOptions([]);
    }

    setEditorKey(`${comp}-${layoutId}-${col}-${Date.now()}`);
  }, [selectedSettings?.index, selectedSettings?.id]);

  const handleInsertTag = (tag) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();

      if (range) {
        quill.insertText(range.index, `${tag}`);
        quill.setSelection(range.index + tag.length + 4);
      }
    }
  };

  return (
    <div
      style={{
        borderRadius: '4px',
        height: '100%',
      }}
    >
      {selectedSettings?.index === comp &&
        selectedSettings?.id === layoutId && (
          <div style={{ marginBottom: '8px' }}>
            <label htmlFor="tags">{t('insertTag')}: </label>
            <select
              id="tags"
              onChange={(e) => {
                if (e.target.value) {
                  handleInsertTag(e.target.value);
                  e.target.value = '';
                }
              }}
              style={{
                padding: '4px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
            >
              <option value="">{t('selectAtag')}</option>
              {tags.map((tag, index) => (
                <option key={index} value={tag?.value}>
                  {tag?.label}
                </option>
              ))}
            </select>
          </div>
        )}

      <ReactQuill
        ref={quillRef}
        value={text}
        key={editorKey}
        onChange={handleChange}
        theme="snow"
        placeholder={t('enterTextType')}
        modules={{
          toolbar: toolbarOptions,
        }}
      />
    </div>
  );
};

export default TextComponent;
