import React, { } from 'react';
import { useTranslation } from 'react-i18next';

import Typo from '../Typo';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import rawAnimationData from '../../../lotties/understandOrganizeAutomate.json';
import Lottie from 'react-lottie';
import { useMediaQuery } from '@mui/material';

const Understand = ({ translationCode }) => {

  const { t, } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const localStorageColor = localStorage.getItem('mainColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';

  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  const lightMainColor = chroma(mainColor + '90')
    .brighten(2.2)
    .hex();

  // Convert RGB(94,200,219) to normalized format
  const modifyColors = (animationData) => {
    const clonedData = JSON.parse(JSON.stringify(animationData));

    const targetColor = [
      0.3686274509803922, 0.7843137254901961, 0.8588235294117647,
    ];

    const targetBlack = [
      0.8666666666666667, 0.8666666666666667, 0.8666666666666667,
    ];

    const targetBlack2 = [0, 0, 0];

    const targetGrey = [
      0.8509803921568627, 0.8509803921568627, 0.8509803921568627,
    ];

    //transform hex color to normalized format with chroma
    const newColor = chroma(mainColor)
      .rgb()
      .map((c) => c / 255);

    const newBlackColor = chroma(darkMainColor)
      .rgb()
      .map((c) => c / 255);

    const newGreyColor = chroma(lightMainColor)
      .rgb()
      .map((c) => c / 255);

    const changeColor = (obj) => {
      if (obj.ty === 'fl' && obj.c && obj.c.k) {
        let colorValue = obj.c.k;

        if (Array.isArray(colorValue) && colorValue.length === 3) {
          if (
            Math.abs(colorValue[0] - targetColor[0]) < 0.01 &&
            Math.abs(colorValue[1] - targetColor[1]) < 0.01 &&
            Math.abs(colorValue[2] - targetColor[2]) < 0.01
          ) {
            obj.c.k = newColor;
          } else if (
            Math.abs(colorValue[0] - targetBlack[0]) < 0.01 &&
            Math.abs(colorValue[1] - targetBlack[1]) < 0.01 &&
            Math.abs(colorValue[2] - targetBlack[2]) < 0.01
          ) {
            obj.c.k = newGreyColor;
          } else if (
            Math.abs(colorValue[0] - targetBlack2[0]) < 0.01 &&
            Math.abs(colorValue[1] - targetBlack2[1]) < 0.01 &&
            Math.abs(colorValue[2] - targetBlack2[2]) < 0.01
          ) {
            obj.c.k = newBlackColor;
          } else if (
            Math.abs(colorValue[0] - targetGrey[0]) < 0.01 &&
            Math.abs(colorValue[1] - targetGrey[1]) < 0.01 &&
            Math.abs(colorValue[2] - targetGrey[2]) < 0.01
          ) {
            obj.c.k = newGreyColor;
          } else {
          }
        }
      }

      // Recursively check nested items
      if (obj.it && Array.isArray(obj.it)) {
        obj.it.forEach(changeColor);
      }
      if (obj.shapes && Array.isArray(obj.shapes)) {
        obj.shapes.forEach(changeColor);
      }
    };

    // Traverse `layers`
    if (clonedData.layers) {
      clonedData.layers.forEach((layer) => {
        if (layer.shapes) {
          layer.shapes.forEach(changeColor);
        }
      });
    }

    // Traverse `assets.layers`
    if (clonedData.assets) {
      clonedData.assets.forEach((asset) => {
        if (asset.layers) {
          asset.layers.forEach((layer) => {
            if (layer.shapes) {
              layer.shapes.forEach(changeColor);
            }
          });
        }
      });
    }

    return clonedData;
  };

  // Apply modified animation data
  const modifiedAnimationData = modifyColors(rawAnimationData);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: modifiedAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      progressiveLoad: false,
    },
  };

  return (
    <div style={{ marginTop: '150px' }} className="container">
      <div className="row">
        <div className="col-md-6 col-12">
          <Lottie
            options={defaultOptions}
            height={isMobile ? 300 : 500}
            width={isMobile ? 420 : 600}
          />
        </div>
        <div className={isMobile ? 'col-md-6 col-12 px-5' : 'col-md-6 col-12'}>
          <Typo
            text={t(translationCode + 'Title')}
            variant="h2"
            className="mt-4"
          />
          <Typo
            text={t(translationCode + 'Title2')}
            variant="h2"
            className=""
          />
          <div className="mt-2">
            <Typo
              text={t(translationCode + 'Item1Title')}
              variant="body2"
              bold
              className="mt-5"
            />
            <Typo
              text={t(translationCode + 'Item1')}
              variant="body2"
              className="mt-1"
            />
          </div>
          <div className="mt-2">
            <Typo
              text={t(translationCode + 'Item2Title')}
              variant="body2"
              bold
              className="mt-5"
            />
            <Typo
              text={t(translationCode + 'Item2')}
              variant="body2"
              className="mt-1"
            />
          </div>
          <div className="mt-2">
            <Typo
              text={t(translationCode + 'Item3Title')}
              variant="body2"
              bold
              className="mt-5"
            />
            <Typo
              text={t(translationCode + 'Item3')}
              variant="body2"
              className="mt-1"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Understand;
