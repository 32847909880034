import React, { useState, } from 'react';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import colorGenerator from '../../../utils/colorGenerator';
import {
  Box,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import Typo from '../Typo';

const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const Services = ({ translationCode }) => {
  const { t, i18n } = useTranslation();

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const secColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const gradientBackground = `linear-gradient(190deg, ${colorGenerator(
    mainColor,
    isDarkMode ? 4 : 0,
    isDarkMode ? 1 : 0.15,
    isDarkMode ? 0 : 2,
    false
  )} 20%, ${isDarkMode ? '#0d0d0d' : '#FFF'} 70%)`;

  return (
    <div
      style={{
        marginTop: '120px',
      }}
      className="container align-c"
    >
      <div className="align-c mt-5">
        <Typo variant="h2" text={t(translationCode + 'Title')} />
        <Typo
          variant="body2"
          text={t(translationCode + 'Sub')}
          className="mt-3"
        />
      </div>
      <Box sx={{ width: '100%', mt: 4 }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="Problem Fixing Tabs"
          centered
        >
          <Tab
            sx={{ textTransform: 'none', fontSize: 14 }}
            label={t(translationCode + 'Tab1')}
          />
          <Tab
            sx={{ textTransform: 'none', fontSize: 14 }}
            label={t(translationCode + 'Tab2')}
          />
          <Tab
            sx={{ textTransform: 'none', fontSize: 14 }}
            label={t(translationCode + 'Tab3')}
          />
          <Tab
            sx={{ textTransform: 'none', fontSize: 14 }}
            label={t(translationCode + 'Tab4')}
          />
          <Tab
            sx={{ textTransform: 'none', fontSize: 14 }}
            label={t(translationCode + 'Tab5')}
          />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <div className="row align-c">
            <div
              className="col-md-3 col-12 mx-3 align-left"
              style={{
                background: gradientBackground,
                height: '190px',
                borderRadius: '10px',
                padding: '30px',
              }}
            >
              <Typo
                variant="smallTitle"
                mainColor
                text={t(translationCode + 'Tab1CT1')}
              />
              <Typo
                variant="p-light"
                className="mt-2"
                text={t(translationCode + 'Tab1C1')}
              />
            </div>
            <div
              className="col-md-3 col-12 mx-3 align-left"
              style={{
                background: gradientBackground,
                height: '190px',
                borderRadius: '10px',
                padding: '30px',
              }}
            >
              <Typo
                variant="smallTitle"
                mainColor
                text={t(translationCode + 'Tab1CT2')}
              />
              <Typo
                variant="p-light"
                className="mt-2"
                text={t(translationCode + 'Tab1C2')}
              />
            </div>
            <div
              className="col-md-3 col-12 mx-3 align-left"
              style={{
                background: gradientBackground,
                height: '190px',
                borderRadius: '10px',
                padding: '30px',
              }}
            >
              <Typo
                variant="smallTitle"
                mainColor
                text={t(translationCode + 'Tab1CT3')}
              />
              <Typo
                variant="p-light"
                className="mt-2"
                text={t(translationCode + 'Tab1C3')}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <div className="row align-c">
            <div
              className="col-md-3 col-12 mx-3 align-left"
              style={{
                background: gradientBackground,
                height: '190px',
                borderRadius: '10px',
                padding: '30px',
              }}
            >
              <Typo
                variant="smallTitle"
                mainColor
                text={t(translationCode + 'Tab2CT1')}
              />
              <Typo
                variant="p-light"
                className="mt-2"
                text={t(translationCode + 'Tab2C1')}
              />
            </div>
            <div
              className="col-md-3 col-12 mx-3 align-left"
              style={{
                background: gradientBackground,
                height: '190px',
                borderRadius: '10px',
                padding: '30px',
              }}
            >
              <Typo
                variant="smallTitle"
                mainColor
                text={t(translationCode + 'Tab2CT2')}
              />
              <Typo
                variant="p-light"
                className="mt-2"
                text={t(translationCode + 'Tab2C2')}
              />
            </div>
            <div
              className="col-md-3 col-12 mx-3 align-left"
              style={{
                background: gradientBackground,
                height: '190px',
                borderRadius: '10px',
                padding: '30px',
              }}
            >
              <Typo
                variant="smallTitle"
                mainColor
                text={t(translationCode + 'Tab2CT3')}
              />
              <Typo
                variant="p-light"
                className="mt-2"
                text={t(translationCode + 'Tab2C3')}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <div className="row align-c">
            <div
              className="col-md-3 col-12 mx-3 align-left"
              style={{
                background: gradientBackground,
                height: '190px',
                borderRadius: '10px',
                padding: '30px',
              }}
            >
              <Typo
                variant="smallTitle"
                mainColor
                text={t(translationCode + 'Tab3CT1')}
              />
              <Typo
                variant="p-light"
                className="mt-2"
                text={t(translationCode + 'Tab3C1')}
              />
            </div>
            <div
              className="col-md-3 col-12 mx-3 align-left"
              style={{
                background: gradientBackground,
                height: '190px',
                borderRadius: '10px',
                padding: '30px',
              }}
            >
              <Typo
                variant="smallTitle"
                mainColor
                text={t(translationCode + 'Tab3CT2')}
              />
              <Typo
                variant="p-light"
                className="mt-2"
                text={t(translationCode + 'Tab3C2')}
              />
            </div>
            <div
              className="col-md-3 col-12 mx-3 align-left"
              style={{
                background: gradientBackground,
                height: '190px',
                borderRadius: '10px',
                padding: '30px',
              }}
            >
              <Typo
                variant="smallTitle"
                mainColor
                text={t(translationCode + 'Tab3CT3')}
              />
              <Typo
                variant="p-light"
                className="mt-2"
                text={t(translationCode + 'Tab3C3')}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabIndex} index={3}>
          <div className="row align-c">
            <div
              className="col-md-3 col-12 mx-3 align-left"
              style={{
                background: gradientBackground,
                height: '190px',
                borderRadius: '10px',
                padding: '30px',
              }}
            >
              <Typo
                variant="smallTitle"
                mainColor
                text={t(translationCode + 'Tab4CT1')}
              />
              <Typo
                variant="p-light"
                className="mt-2"
                text={t(translationCode + 'Tab4C1')}
              />
            </div>
            <div
              className="col-md-3 col-12 mx-3 align-left"
              style={{
                background: gradientBackground,
                height: '190px',
                borderRadius: '10px',
                padding: '30px',
              }}
            >
              <Typo
                variant="smallTitle"
                mainColor
                text={t(translationCode + 'Tab4CT2')}
              />
              <Typo
                variant="p-light"
                className="mt-2"
                text={t(translationCode + 'Tab4C2')}
              />
            </div>
            <div
              className="col-md-3 col-12 mx-3 align-left"
              style={{
                background: gradientBackground,
                height: '190px',
                borderRadius: '10px',
                padding: '30px',
              }}
            >
              <Typo
                variant="smallTitle"
                mainColor
                text={t(translationCode + 'Tab4CT3')}
              />
              <Typo
                variant="p-light"
                className="mt-2"
                text={t(translationCode + 'Tab4C3')}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabIndex} index={4}>
          <div className="row align-c">
            <div
              className="col-md-3 col-12 mx-3 align-left"
              style={{
                background: gradientBackground,
                height: '190px',
                borderRadius: '10px',
                padding: '30px',
              }}
            >
              <Typo
                variant="smallTitle"
                mainColor
                text={t(translationCode + 'Tab5CT1')}
              />
              <Typo
                variant="p-light"
                className="mt-2"
                text={t(translationCode + 'Tab5C1')}
              />
            </div>
            <div
              className="col-md-3 col-12 mx-3 align-left"
              style={{
                background: gradientBackground,
                height: '190px',
                borderRadius: '10px',
                padding: '30px',
              }}
            >
              <Typo
                variant="smallTitle"
                mainColor
                text={t(translationCode + 'Tab5CT2')}
              />
              <Typo
                variant="p-light"
                className="mt-2"
                text={t(translationCode + 'Tab5C2')}
              />
            </div>
            <div
              className="col-md-3 col-12 mx-3 align-left"
              style={{
                background: gradientBackground,
                height: '190px',
                borderRadius: '10px',
                padding: '30px',
              }}
            >
              <Typo
                variant="smallTitle"
                mainColor
                text={t(translationCode + 'Tab5CT3')}
              />
              <Typo
                variant="p-light"
                className="mt-2"
                text={t(translationCode + 'Tab5C3')}
              />
            </div>
          </div>
        </TabPanel>
      </Box>
    </div>
  );
};

export default Services;
