import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import ErrorBoundary from '../../components/@generalComponents/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import chroma from 'chroma-js';
import update from 'immutability-helper';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, IconButton,
} from '@mui/material';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import Button from '../general-components/Button';
import FieldComponent from '../../components/@generalComponents/FieldComponent';
import { toast } from 'react-toastify';
import { setGeneralStatus } from '../../redux/actions-v2/coreAction';
import { useDispatch } from 'react-redux';

import { SaveOutlined } from '@mui/icons-material';


import DraggableElement from './DraggableElement';
import StaticElement from './StaticElement';

const ContentLayout = ({
  elementDetails,
  blockWith,
  layout,
  publicMode,
  fontColor,
  activeIndex,
  handleDelete,
  handleRefresh,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentLangCode = i18n.language;

  const navigate = useNavigate();
  const { structureId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [key, setKey] = useState(null);
  const [value, setValue] = useState(null);
  const [type, setType] = useState(null);
  const [label, setLabel] = useState('');
  const [selections, setSelections] = useState([]);
  const [elementData, setElementData] = useState(null);
  const [currentMetric, setCurrentMetric] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuItemIndex, setMenuItemIndex] = useState(null);
  const [lastEditedIndex, setLastEditedIndex] = useState(null);
  const [orderChanged, setOrderChanged] = useState(false);

  // Ref to store the previous value of elementDetails.data
  const prevElementDetailsDataRef = useRef();

  // --- DND: Function to move elements in the state ---
  const moveElement = useCallback((dragIndex, hoverIndex) => {
    setElementData((prevData) => {
      if (!prevData) {
        console.error("moveElement: prevData is null, cannot reorder.");
        return null;
      }
      if (dragIndex < 0 || dragIndex >= prevData.length || hoverIndex < 0 || hoverIndex >= prevData.length) {
        console.error(`moveElement: Invalid indices - dragIndex: ${dragIndex}, hoverIndex: ${hoverIndex}, length: ${prevData.length}`);
        return prevData;
      }

      const itemToMove = prevData[dragIndex];
      if (!itemToMove) {
        console.error(`moveElement: Item at dragIndex ${dragIndex} not found.`);
        return prevData;
      }

      try {

        const newData = update(prevData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, itemToMove],
          ],
        });

        return newData;
      } catch (error) {
        console.error("Error during immutability update ($splice):", error);
        return prevData;
      }
    });
    setOrderChanged(true);
    setHoveredIndex(null);
    setAnchorEl(null);
    setMenuItemIndex(null);
  }, []);


  useEffect(() => {
    const currentDetailsData = elementDetails?.data;
    const previousDetailsData = prevElementDetailsDataRef.current;

    if (elementData === null || !_.isEqual(previousDetailsData, currentDetailsData)) {
      setElementData(currentDetailsData || []);
      setOrderChanged(false);
      setLastEditedIndex(null);
    }

    prevElementDetailsDataRef.current = currentDetailsData;

    const singleElement = elementDetails?.elementData;
    if (singleElement?.targetProfileDetails) {
      setCurrentMetric(singleElement.targetProfileDetails.metric);
    }

  }, [elementDetails?.data, elementData === null]);


  const handleWeightChange = (event, newValue, index) => {
    let updatedValue = null;
    if (newValue === 0) updatedValue = 'regular';
    else if (newValue === 1) updatedValue = 'medium';
    else if (newValue === 2) updatedValue = 'bold';

    setElementData((prevData) =>
      update(prevData, {
        [index]: { weight: { $set: updatedValue } },
      })
    );
    setLastEditedIndex(index);
    setOrderChanged(true);
  };

  const handleLabelChange = (event, index) => {
    setElementData((prevData) =>
      update(prevData, {
        [index]: { showLabel: { $set: event.target.checked } },
      })
    );
    setLastEditedIndex(index);
    setOrderChanged(true);
  };

  const handleTypeChange = (event, index) => {
    setElementData((prevData) =>
      update(prevData, {
        [index]: { fieldType: { $set: event.target.checked ? 'field' : 'text' } },
      })
    );
    setLastEditedIndex(index);
    setOrderChanged(true);
  };

  const handleSizeChange = (event, newValue, index) => {
    setElementData((prevData) =>
      update(prevData, {
        [index]: { size: { $set: newValue } },
      })
    );
    setLastEditedIndex(index);
    setOrderChanged(true);
  };

  const handleDeleteItem = (indexToDelete) => {
    if (!elementData || indexToDelete < 0 || indexToDelete >= elementData.length) {
      console.error("handleDeleteItem: Invalid index or elementData is null.");
      return;
    }
    const itemLabel = elementData[indexToDelete]?.label || `Item ${indexToDelete + 1}`;

    setElementData((prevData) => update(prevData, { $splice: [[indexToDelete, 1]] }));
    setOrderChanged(true);
    setAnchorEl(null);
    setMenuItemIndex(null);
    toast.info(t('elementDeleted', { label: itemLabel }));

  };


  const businessPreference = useSelector((state) => state.core.businessData);
  const mainColor = businessPreference?.mainColor || '#000000';

  const handleOpenMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuItemIndex(index);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuItemIndex(null);
    // Potentially trigger save here based on flags if desired, but explicit save is usually better
    // if (orderChanged || lastEditedIndex !== null) { saveLayout(); }
  };

  const saveLayout = async () => {
    if (!orderChanged && lastEditedIndex === null) {
      return;
    }
    if (!elementData) {
      console.error("saveLayout: elementData is null, cannot save.");
      toast.error(t('errorSavingLayout'));
      return;
    }


    try {
      dispatch(setGeneralStatus({ status: 'loading' }));
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/structure`,
        body: {
          structureId: structureId,
          type: 'components',
          lang: currentLangCode,
          data: {
            from: 'business',
            tabIndex: activeIndex,
            blockIdentifiant: layout?.i,
            componentsData: elementData,
          },
        },
      });
      dispatch(setGeneralStatus({ status: 'success' }));
      toast.success(t('layoutSaved'));
      setOrderChanged(false);
      setLastEditedIndex(null);
      if (handleRefresh) {
        handleRefresh();
      }
    } catch (error) {
      console.error("Error saving layout:", error);
      dispatch(setGeneralStatus({ status: 'error', error: error }));
      toast.error(t('errorSavingLayout'));
    }
  };


  const updateField = (fieldKey, value, label, type, selections, action, item) => {
    if (action === 'edit') {
      if (
        fieldKey === 'targetIdentifiant' || fieldKey === 'targetId' || fieldKey === 'id' ||
        fieldKey === 'documentPath' || fieldKey === 'documentId' || fieldKey === 'structureId' ||
        fieldKey === 'structureIdentifiant' || fieldKey === 'values' || fieldKey === 'assignedToId' ||
        fieldKey === 'locationId' || fieldKey === 'nodes' || fieldKey === 'financesAmount' ||
        fieldKey === 'height' || fieldKey === 'label' || fieldKey === 'width' ||
        fieldKey === 'finances.total' || fieldKey === 'valueColor' || fieldKey === 'value' ||
        fieldKey === 'isRequired' || fieldKey === 'finances.subtotal' || fieldKey === 'finances.tax1' ||
        fieldKey === 'finances.tax2' || fieldKey === 'financesTotal' || fieldKey === 'financesSubtotal' ||
        fieldKey === 'financesTax1' || fieldKey === 'financesTax2' || fieldKey === 'by' ||
        fieldKey === 'financesTax3' || fieldKey === 'targetName' || fieldKey === 'targetProfileName' ||
        fieldKey === 'dependencyName' || fieldKey === 'targetAddress' || fieldKey === 'timeStamp' ||
        fieldKey === 'children' || fieldKey === 'targetProfileId' || fieldKey === 'lastUpdate' ||
        fieldKey === 'attribute1' || fieldKey === 'attribute2' || fieldKey === 'attribute3'
      ) { return; }
      setKey(fieldKey);
      setValue(value);
      setLabel(label);
      setType(type);
      setSelections(selections);
      setIsOpen(true);
    } else if (action === 'navigate') {
      const collectionField = item?.[0]?.collectionField;
      const targetStructureId = item?.[0]?.structureId;
      const targetDetails = item?.[0]?.primaryData?.targetDetails;
      const primaryData = item?.[0]?.primaryData;

      if (collectionField === 'contacts' && targetDetails?.structureIdentifiant && primaryData?.targetId && businessPreference?.id) {
        navigate(`/app/element/${collectionField}/${targetDetails.structureIdentifiant}/${primaryData.targetId}${businessPreference.id}`);
      } else if (collectionField && targetStructureId && (primaryData?.elementId || primaryData?.id)) {
        navigate(`/app/element/${collectionField}/${targetStructureId}/${primaryData.elementId || primaryData.id}`);
      } else {
        console.warn("Navigation failed: Missing required data", { item, collectionField, targetStructureId, targetDetails, primaryData });
        toast.error(t('cannotNavigateMissingData'));
      }
    } else if (action === 'copy') {
      navigator.clipboard.writeText(value);
      toast.success(t('copied'));
    } else if (action === 'none') {
      return;
    }
  };


  const handleFieldChange = useCallback((fieldKey, value, fieldIndex) => {
    const index = fieldIndex;

    if (elementData && index >= 0 && index < elementData.length) {
      setElementData((prevState) =>
        update(prevState, {
          [index]: { value: { $set: value } }
        })
      );
      setValue(value);
      setKey(fieldKey);
      // setLastEditedIndex(index);
      // setOrderChanged(true);
    } else {
      console.warn(`handleFieldChange: Could not update element at index: ${index} for key: ${fieldKey}. Data might be null or index out of bounds.`);
    }
  }, [elementData]);


  // Save from the popup dialog
  const handleSave = async (providedKey, providedValue) => {
    setIsOpen(false);
    const currentKey = key || providedKey;
    const currentValue = value ?? providedValue;


    // Find index based on the key stored when dialog opened
    const indexToUpdate = elementData?.findIndex(
      (element) => element?.structureValue === currentKey
    );

    if (indexToUpdate === -1 || !elementData) {
      console.error("handleSave (Dialog): Original element not found for key", currentKey, "or elementData is null.");
      toast.error(t('errorSavingField'));
      setKey(null); // Reset dialog state
      setValue(null);
      return;
    }

    // --- Update local state first (using immutability-helper) ---
    setElementData((prevData) =>
      update(prevData, {
        [indexToUpdate]: { value: { $set: currentValue } },
      })
    );
    // setOrderChanged(true);
    setLastEditedIndex(indexToUpdate);

    // --- Perform backend PATCH request (existing logic) ---
    let formattedPath = elementDetails?.elementData?.documentPath?.split('/');
    formattedPath = formattedPath?.filter((part) => part !== elementDetails?.elementData?.id).join('/') || '';

    let formattedKey = currentKey;
    if (formattedKey?.startsWith('data.')) {
      formattedKey = formattedKey.replace('data.', '');
    }

    try {
      dispatch(setGeneralStatus({ status: 'loading' }));
      if (formattedKey === 'metric' || formattedKey === 'odometer' || formattedKey === 'data.odometer') {
        let profilePath = elementDetails?.elementData?.targetProfilePath?.split('/');
        profilePath = profilePath?.filter((part) => part !== elementDetails?.elementData?.targetProfileId).join('/') || '';

        if (elementDetails?.elementData?.targetProfileId && profilePath) {
          await nodeAxiosFirebase({
            t, method: 'PATCH', url: `coreSeqV3`,
            body: {
              documentId: elementDetails.elementData.targetProfileId, elementPath: profilePath,
              key: formattedKey, value: currentValue,
            },
          });
        }
        if (elementDetails?.elementData?.id && formattedPath) {
          await nodeAxiosFirebase({
            t, method: 'PATCH', url: `coreSeqV3`,
            body: {
              documentId: elementDetails.elementData.id, elementPath: formattedPath,
              key: formattedKey, value: currentValue,
            },
          });
        }
      } else {
        if (elementDetails?.elementData?.id && formattedPath) {
          await nodeAxiosFirebase({
            t, method: 'PATCH', url: `coreSeqV3`,
            body: {
              documentId: elementDetails.elementData.id, elementPath: formattedPath,
              key: formattedKey, value: currentValue,
            },
          });
        }
      }
      dispatch(setGeneralStatus({ status: 'success' }));
      toast.success(t('fieldUpdated'));
    } catch (error) {
      console.error('Error updating field (Dialog): ', error);
      dispatch(setGeneralStatus({ status: 'error', error: error }));
      toast.error(t('errorUpdatingField'));
    } finally {
      setKey(null);
      setValue(null);
    }
  };


  const handleFieldChangeMetric = (fieldId, fieldValue) => {
    setCurrentMetric(fieldValue);

    // Find the metric element in the data array and update it too
    const metricIndex = elementData?.findIndex(el => el?.structureValue === 'metric');
    if (metricIndex !== -1 && elementData) {
      setElementData(prevData => update(prevData, {
        [metricIndex]: { value: { $set: fieldValue } }
      }));
      setLastEditedIndex(metricIndex);
      // setOrderChanged(true);
      // Decide if immediate PATCH is needed for metric changes, or rely on Save Layout
      // handleSave('metric', fieldValue); // Call the specific field PATCH logic if needed immediately
    }
  };


  const getStatusColor = (count) => {
    const intensity = count / 10;
    return chroma.mix('grey', mainColor, intensity).hex();
  };


  // --- Currently selected item for the Menu ---
  const currentMenuItem = menuItemIndex !== null && elementData && menuItemIndex >= 0 && menuItemIndex < elementData.length
    ? elementData[menuItemIndex]
    : null;


  return (
    <ErrorBoundary>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{t('updateField')}</DialogTitle>
        <DialogContent>
          <div style={{ minWidth: '400px' }}>

            <FieldComponent
              key={key || 'edit-dialog-field'}
              field={{
                typeData: type || 'string',
                label: label,
                name: label,
                value: key?.startsWith('data.') ? key?.replace('data.', '') : key,
              }}
              value={value}
              onChange={(fieldKey, newValue) => setValue(newValue)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="align-c p-2">
            <Button label={t('save')} onClick={handleSave} primary={true} fullWidth size="small" />{' '}
          </div>
        </DialogActions>
      </Dialog>

      {publicMode ? (<div
        className={elementData?.length === 1 ? 'middle-content' : ''}
        style={{ overflowX: 'hidden', marginTop: '-10px', }}
      >


        {elementData && elementData.map((element, index) => (
          <StaticElement
            key={element?.structureValue || `element-${index}-${element?.label}`}
            index={index}
            element={element}
            moveElement={moveElement}
            updateField={updateField}
            handleFieldChange={handleFieldChange}
            currentMetric={currentMetric}
            handleFieldChangeMetric={handleFieldChangeMetric}
            layout={layout}
            blockWith={blockWith}
            fontColor={fontColor}
            hoveredIndex={hoveredIndex}
            setHoveredIndex={setHoveredIndex}
            handleOpenMenu={handleOpenMenu}
            anchorEl={anchorEl}
            menuOpen={menuItemIndex === index && Boolean(anchorEl)}
            handleCloseMenu={handleCloseMenu}
            handleLabelChange={handleLabelChange}
            handleSizeChange={handleSizeChange}
            handleWeightChange={handleWeightChange}
            handleDeleteItem={handleDeleteItem}
            menuItemIndex={menuItemIndex}
            currentMenuItem={currentMenuItem}
          />
        ))}
        {!elementData && (
          <div>{t('loading')}...</div>
        )}
      </div>) : (
        <div
          className={elementData?.length === 1 ? 'middle-content' : ''}
          style={{ overflowX: 'hidden', marginTop: '-10px', }}
        >

          {orderChanged && (
            <div style={{ position: 'absolute', right: '10px', top: '10px', zIndex: 1000 }}>
              <IconButton onClick={saveLayout} sx={{ backgroundColor: businessPreference?.secColor }}>
                <SaveOutlined fontSize="small" htmlColor='#FFFFFF' />
              </IconButton>

            </div>
          )}

          {elementData && elementData.map((element, index) => (
            <DraggableElement
              key={element?.structureValue || `element-${index}-${element?.label}`}
              index={index}
              element={element}
              moveElement={moveElement}
              updateField={updateField}
              handleFieldChange={handleFieldChange}
              handleSave={handleSave}
              currentMetric={currentMetric}
              handleFieldChangeMetric={handleFieldChangeMetric}
              layout={layout}
              blockWith={blockWith}
              fontColor={fontColor}
              hoveredIndex={hoveredIndex}
              setHoveredIndex={setHoveredIndex}
              handleOpenMenu={handleOpenMenu}
              anchorEl={anchorEl}
              menuOpen={menuItemIndex === index && Boolean(anchorEl)}
              handleCloseMenu={handleCloseMenu}
              handleLabelChange={handleLabelChange}
              handleTypeChange={handleTypeChange}
              handleSizeChange={handleSizeChange}
              handleWeightChange={handleWeightChange}
              handleDeleteItem={handleDeleteItem}
              menuItemIndex={menuItemIndex}
              currentMenuItem={currentMenuItem}
            />
          ))}
          {!elementData && (
            <div>{t('loading')}...</div>
          )}
        </div>)}
    </ErrorBoundary>
  );
};

export default ContentLayout;