import React from 'react';
import SiteLayout from '../SiteLayout';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Typo from '../components/Typo';
import { keyframes } from '@mui/system';

const NodeAI = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isDarkMode = theme.palette.mode === 'dark';

  const isMobile = window.innerWidth <= 768;
  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');

  const pageCode = 'nodeAI';

  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const varColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  // 🔹 Keyframe animation for smooth motion
  const pulseAnimation = keyframes`
    0% { transform: scale(0.8); opacity: 0.7; }
    30% { transform: scale(1.1); opacity: 0.6; }
    50% { transform: scale(1.3); opacity: 0.3; }
    70% { transform: scale(1); opacity: 0.5; }
    100% { transform: scale(0.8); opacity: 0.7; }
  `;

  return (
    <SiteLayout
      helmetData={{
        name: t(pageCode + 'SEO'),
        description: t(pageCode + 'SEODesc'),
      }}
      backgroundPage="full"
    >
      <div style={{ position: 'relative' }}>
        <Box
          sx={{
            width: isMobile ? '300px' : '823px',
            height: isMobile ? '300px' : '823px',
            background: `radial-gradient(circle, ${varColor} 5%, transparent 60%)`,
            position: 'absolute',
            top: 65,
            left: isMobile ? '20%' : '23%',
            opacity: 0.5,
            animation: `${pulseAnimation} 5s infinite ease-in-out`,
          }}
        />
        <Box
          sx={{
            width: isMobile ? '300px' : '823px',
            height: isMobile ? '300px' : '823px',
            background: `radial-gradient(circle, ${mainColor} 5%, transparent 60%)`,
            position: 'absolute',
            top: '-10px',
            left: isMobile ? '30%' : '33%',
            opacity: 0.6,
            animation: `${pulseAnimation} 8s infinite ease-in-out`,
          }}
        />

        <div
          className="align-c"
          style={{ position: 'absolute', zIndex: 99, top: '29%', left: '49%' }}
        >
          <Typo text={'AI'} color="white" variant="h1" />
          <Typo text={'Node'} color="white" variant="h3" />
        </div>

        <img
          src={
            isDarkMode
              ? '/assets/website/v3/nodeai-header-dark.png'
              : '/assets/website/v3/nodeAI-header.png'
          }
          width={'100%'}
          alt="Node AI"
        />
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-8 col-12">
            <Typo text={t(pageCode + 'Title')} variant="h2" />
            <Typo
              text={t(pageCode + 'Desc')}
              variant="body2"
              className="mt-5"
            />
          </div>
          <div className="col-md-4 col-12"></div>
        </div>
      </div>
    </SiteLayout>
  );
};

export default NodeAI;
