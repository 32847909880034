import React from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@mui/system';

const blowIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50%) scale(0.3);
  }
  60% {
    opacity: 1;
    transform: translateX(-50%) scale(1.1);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
`;

const NodeBOS3DStack = ({
  mainColor = '#00d4ff',
  secColor = '#00ffae',
  isMobile,
}) => {
  const layerCount = 6;
  const xyOffsetPerLayer = 0;
  const zOffsetPerLayer = 2;

  const layers = Array.from({ length: layerCount }, (_, i) => i);

  return (
    <Box
      sx={{
        paddingLeft: isMobile ? '45%' : '30%',
        width: '100%',
        height: '100%',
        minHeight: isMobile ? '330px' : '350px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#000',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          perspective: '1200px',
          perspectiveOrigin: '70% 80%',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            transformStyle: 'preserve-3d',
            transform: 'rotateX(65deg) rotateY(5deg) rotateZ(-45deg)',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: '-55px',
              left: '50%',
              width: isMobile ? '320px' : '390px',
              height: isMobile ? '320px' : '390px',
              transform: 'translateX(-50%) scale(0.3)',
              background: `radial-gradient(
                ellipse at 30% 0%,
                ${secColor} 10%,
                ${mainColor} 60%,
                transparent 85%
              )`,
              filter: 'blur(40px)',
              zIndex: -1,
              opacity: 0,
              animation: `${blowIn} 2.5s ease-out forwards`,
            }}
          />

          {layers?.map((layerIndex) => {
            const isTopLayer = layerIndex === 0;
            const xOffset = layerIndex * xyOffsetPerLayer;
            const yOffset = layerIndex * xyOffsetPerLayer;
            const zOffset = layerIndex * zOffsetPerLayer;

            return (
              <Box
                key={layerIndex}
                sx={{
                  position: 'absolute',
                  marginLeft: isMobile ? '-160px' : '-190px',
                  marginTop: '-300px',
                  width: isMobile ? '320px' : '360px',
                  height: isMobile ? '320px' : '360px',
                  background: '#111',
                  borderRadius: '8px',
                  boxShadow: '0 10px 40px rgba(0, 0, 0, 0.5)',
                  zIndex: layerCount - layerIndex,
                  transform: `
                    translate3d(${xOffset}px, ${yOffset}px, -${zOffset}px)
                  `,
                }}
              >
                {isTopLayer && (
                  <Box
                    sx={{
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '3rem',
                      fontWeight: 600,
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    Node BOS™
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default NodeBOS3DStack;
