import React, { } from 'react';
import SiteLayout from '../SiteLayout';
import HeaderHome from '../components/roam/HeaderHome';
import CTA from '../components/roam/CTA';
import Screens from '../components/roam/Screens';
import Tiles from '../components/roam/Tiles';
import { useTranslation } from 'react-i18next';

const HomeRoam = () => {
  const { t } = useTranslation();
  const pageCode = 'homeRoam';
  return (
    <SiteLayout
      helmetData={{
        name: t(pageCode + 'SEO'),
        description: t(pageCode + 'SEODesc'),
      }}
    >
      <div className="mt-5 mb-5">
        <HeaderHome translationCode={pageCode + 'Header'} />
      </div>
      <div className="mt-5 mb-5">
        <Tiles translationCode={pageCode + 'Tiles'} />
      </div>
      <div className="mt-5 mb-5">
        <Screens translationCode={pageCode + 'Screens'} />
      </div>
      <div className="mt-5 mb-5">
        <CTA translationCode={pageCode + 'Bottom'} />
      </div>
    </SiteLayout>
  );
};

export default HomeRoam;
