import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { useTheme } from '@mui/material/styles';
import { useThemeCont } from '../../../contexts/ThemeContext';

//utilities
import { useTranslation } from 'react-i18next';
import { Avatar } from '@mui/material';
import {
  DarkModeOutlined,
  LightModeOutlined,
  LogoutOutlined,
  SettingsOutlined,
  TranslateOutlined,
} from '@mui/icons-material';
import {
  fetchBusinessData,
  setCurrentSection,
  setCurrentUser,
} from '../../../redux/actions-v2/coreAction';

const UserSecMenu = ({ isMobile }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mode, toggleTheme } = useThemeCont();
  const theme = useTheme();

  const isDarkMode = theme.palette.mode === 'dark';

  const currentUser = useSelector((state) => state.core.user);

  const businessPreference = useSelector((state) => state.core.businessData);
  const handleMenuNav = () => {
    navigate('/app/me');
  };

  const handleUpdateLang = () => {
    localStorage.setItem('i18nextLng', i18n.language === 'fr' ? 'en' : 'fr');
    i18n.changeLanguage(i18n.language === 'fr' ? 'en' : 'fr');
    dispatch(fetchBusinessData(businessPreference.id, t));
  };

  const handleNavigateSettings = useCallback((section) => {
    dispatch(setCurrentSection('SETTINGS'));
    navigate('/app/settings');
  }, []);

  const handleLogout = async () => {
    try {
      dispatch(setCurrentUser(null, ''));
      sessionStorage.removeItem('businesses');
      sessionStorage.removeItem('businessToken');
      sessionStorage.removeItem('unseenMessages');
      const auth = getAuth();
      await signOut(auth);
      navigate('/signin');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="hover">
        <div className={isMobile ? 'align-c d-flex middle-content' : "align-c "}>
          <div className="align-left px-2" onClick={toggleTheme}>
            {isDarkMode ? (
              <div>
                <LightModeOutlined
                  color={isDarkMode ? 'white' : 'black'}
                  style={{ fontSize: 22 }}
                />
              </div>
            ) : (
              <div>
                <DarkModeOutlined
                  color={isDarkMode ? 'white' : 'black'}
                  style={{ fontSize: 22 }}
                />
              </div>
            )}
          </div>
          <div className="mt-3 mb-3 align-left px-2" onClick={handleUpdateLang}>
            {i18n.language === 'fr' ? (
              <div>
                <TranslateOutlined
                  color={isDarkMode ? 'white' : 'black'}
                  style={{ fontSize: 22 }}
                />
              </div>
            ) : (
              <div>
                <TranslateOutlined
                  color={isDarkMode ? 'white' : 'black'}
                  style={{ fontSize: 22 }}
                />
              </div>
            )}
          </div>
          {(currentUser?.activeBusiness?.role === 'ADMIN' ||
            currentUser?.activeBusiness?.role === 'SUPER-ADMIN') && (
              <div
                className="mt-3 mb-3 align-left px-2"
                onClick={handleNavigateSettings}
              >
                <SettingsOutlined
                  color={isDarkMode ? 'white' : 'black'}
                  style={{ fontSize: 22 }}
                />
              </div>
            )}
          <div className="mt-3 mb-3 align-left px-2" onClick={handleLogout}>
            <LogoutOutlined
              color={isDarkMode ? 'white' : 'black'}
              style={{ fontSize: 22 }}
            />
          </div>
          {!isMobile && (
            <div
              style={{
                borderColor: isDarkMode ? 'white' : 'black',
                borderRadius: '50%',
                backgroundColor: isDarkMode ? '#1a1a1a' : '#FFF',
                width: 40,
                height: 40,
                border: '0.5px solid',
                paddingTop: '2.5px',
              }}
              className="mt-4 align-left px-1"
              onClick={handleMenuNav}
            >
              <Avatar
                alt="User"
                src={currentUser?.photoURL}
                sx={{ width: 34, height: 34, marginLeft: '-1px' }}
              />
            </div>)}
        </div>
      </div>
    </>
  );
};

export default UserSecMenu;
