import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';

//components
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import Loading from '../../stories/general-components/Loading';
import PrintableElement from '../../stories/layout-components/templates/PrintableElement';
import { DndProvider } from 'react-dnd';

const PublicInvoiceComponent = ({
  printRef,
  reload,
  setReload,
  documentPath
}) => {
  const { elementId } = useParams();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const langCode = i18n.language || 'fr';
  const accessCode = new URLSearchParams(search).get('accessCode');

  const initialSectionsStructure = ['header', ...Array.from({ length: 1 }, (_, i) => `section-${i + 1}`), 'footer'];
  const initialSections = initialSectionsStructure.reduce((acc, sectionId) => {
    acc[sectionId] = [];
    return acc;
  }, {});

  const [elementData, setElementData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [components, setComponents] = useState([]);
  const [sectionLayouts, setSectionLayouts] = useState({})


  const singleCardDetails = useSelector(
    (state) => state.element.singleElementDetails
  );



  const getCardDetails = async () => {
    if (documentPath) {
      setIsLoading(true);
      try {
        const element = await nodeAxiosFirebase({
          t,
          method: 'POST',
          url: `public/single-element`,
          noAuth: true,
          body: {
            elementId: elementId || singleCardDetails?.id,
            documentPath: documentPath,
            fromBusiness: false,
            accessCode: singleCardDetails?.accessCode || accessCode,
            lang: langCode,
          },
          reduxDispatcher: dispatch,
          loadingMessage: `${t('gettingCard')}`,
        });
        setElementData(element);
        if (element?.template) {
          const loadedComponents = element?.template?.components || {};
          const loadedLayout = element?.template?.layout || {};

          const mergedComponents = { ...initialSections };
          Object.keys(loadedComponents).forEach(key => {
            if (mergedComponents.hasOwnProperty(key)) {
              mergedComponents[key] = loadedComponents[key];
            } else {
              console.warn(`Loaded template contains unexpected section: ${key}`);
              mergedComponents[key] = loadedComponents[key];
            }
          });
          Object.keys(initialSections).forEach(key => {
            if (!mergedComponents.hasOwnProperty(key)) {
              mergedComponents[key] = [];
            }
          });

          setComponents(mergedComponents);
          setSectionLayouts(loadedLayout);

        } else {
          setComponents(initialSections);
          setSectionLayouts({});
        }
        setIsLoading(false);
        setReload(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }
  };



  useEffect(() => {
    getCardDetails();
  }, [singleCardDetails?.id, documentPath, reload]);


  return (
    <div ref={printRef}>
      {isLoading && <Loading />}

      <div
        style={{ overflow: 'scroll', height: '90vh', paddingBottom: '40px', }}
        className="mt-4 mb-3 justify-content-between align-left"
      >
        {elementData?.id === elementId && (
          <div>
            {isLoading ? (
              <div>
                <Loading size="small" type="logo" />
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: '#FFF',
                  color: '#000000',
                  maxWidth: '100%',
                }}

              >
                {elementData?.template?.components && (
                  <DndProvider backend={HTML5Backend} >
                    <PrintableElement
                      printAreaRef={printRef}
                      elementData={elementData}
                      editMode={false}
                      color={elementData?.businessData?.mainColor}
                      components={components}
                      sectionLayouts={sectionLayouts}
                    />
                  </DndProvider>
                )}
              </div>
            )}
          </div>
        )}
      </div>

    </div>
  );
};

export default PublicInvoiceComponent;
