import { CalculateOutlined, CalendarMonthOutlined } from '@mui/icons-material';

const defaultFieldsResolver = (collection, t) => {
  switch (collection) {
    case 'contacts':
      return [
        {
          name: t('attribute1'),
          value: 'attribute1',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute2'),
          value: 'attribute2',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute3'),
          value: 'attribute3',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute4'),
          value: 'attribute4',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('lastUpdate'),
          value: 'lastUpdate',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: t('timeStamp'),
          value: 'timeStamp',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: t('tags'),
          value: 'tags',
          allowSub: false,
          typeData: 'tags',
        },
        {
          name: 'Notes',
          value: 'note',
          allowSub: false,
          typeData: 'html',
        },
      ];
    case 'cardsinvoiced':
    case 'cardsuninvoiced':
    case 'cardsexpense':
      return [
        {
          name: t('attribute1'),
          value: 'attribute1',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute2'),
          value: 'attribute2',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute3'),
          value: 'attribute3',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute4'),
          value: 'attribute4',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('invoiceDate'),
          value: 'invoiceDate',
          typeData: 'date',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: t('dueDate'),
          value: 'dueDate',
          typeData: 'date',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: t('lastUpdate'),
          value: 'lastUpdate',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: t('timeStamp'),
          value: 'timeStamp',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: 'Notes',
          value: 'note',
          allowSub: false,
          typeData: 'html',
        },
        {
          name: t('subtotal'),
          value: 'financesSubtotal',
          typeData: 'number',
          icon: <CalculateOutlined />,
        },
        {
          name: t('total'),
          value: 'financesTotal',
          typeData: 'number',
          icon: <CalculateOutlined />,
        },
        {
          name: t('balance'),
          value: 'financesBalance',
          typeData: 'number',
          icon: <CalculateOutlined />,
        },
        {
          name: t('tags'),
          value: 'tags',
          allowSub: false,
          typeData: 'tags',
        },
      ];
    case 'profiles':
      return [
        {
          name: t('attribute1'),
          value: 'attribute1',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute2'),
          value: 'attribute2',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute3'),
          value: 'attribute3',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute4'),
          value: 'attribute4',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('lastUpdate'),
          value: 'lastUpdate',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: t('timeStamp'),
          value: 'timeStamp',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: 'Notes',
          value: 'note',
          allowSub: false,
          typeData: 'html',
        },
        {
          name: 'Type',
          value: 'type',
          typeData: 'text',
        },
        {
          name: t('attributes'),
          value: 'targetProfileAttributes',
          typeData: 'text',
        },
        {
          name: t('tags'),
          value: 'tags',
          allowSub: false,
          typeData: 'tags',
        },
      ];
    case 'services':
      return [
        {
          name: t('attribute1'),
          value: 'attribute1',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute2'),
          value: 'attribute2',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute3'),
          value: 'attribute3',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute4'),
          value: 'attribute4',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('lastUpdate'),
          value: 'lastUpdate',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: t('timeStamp'),
          value: 'timeStamp',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: 'Notes',
          value: 'note',
          allowSub: false,
          typeData: 'html',
        },
        {
          name: t('tags'),
          value: 'tags',
          allowSub: false,
          typeData: 'tags',
        },
      ];
    case 'articles':
      return [
        {
          name: t('attribute1'),
          value: 'attribute1',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute2'),
          value: 'attribute2',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute3'),
          value: 'attribute3',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute4'),
          value: 'attribute4',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('lastUpdate'),
          value: 'lastUpdate',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: t('timeStamp'),
          value: 'timeStamp',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: 'Notes',
          value: 'note',
          allowSub: false,
          typeData: 'html',
        },
        {
          name: t('tags'),
          value: 'tags',
          allowSub: false,
          typeData: 'tags',
        },
      ];
    case 'nodies':
      return [
        {
          name: t('attribute1'),
          value: 'attribute1',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute2'),
          value: 'attribute2',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute3'),
          value: 'attribute3',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute4'),
          value: 'attribute4',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('lastUpdate'),
          value: 'lastUpdate',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: t('timeStamp'),
          value: 'timeStamp',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: 'Notes',
          value: 'note',
          allowSub: false,
          typeData: 'html',
        },

        {
          name: t('tags'),
          value: 'tags',
          allowSub: false,
          typeData: 'tags',
        },
      ];
    case 'grids':
      return [
        {
          name: t('attribute1'),
          value: 'attribute1',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute2'),
          value: 'attribute2',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute3'),
          value: 'attribute3',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute4'),
          value: 'attribute4',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('lastUpdate'),
          value: 'lastUpdate',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: t('timeStamp'),
          value: 'timeStamp',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: t('tags'),
          value: 'tags',
          allowSub: false,
          typeData: 'tags',
        },
      ];
    case 'tasks':
      return [
        {
          name: t('attribute1'),
          value: 'attribute1',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute2'),
          value: 'attribute2',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute3'),
          value: 'attribute3',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute4'),
          value: 'attribute4',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('lastUpdate'),
          value: 'lastUpdate',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: t('timeStamp'),
          value: 'timeStamp',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: 'Notes',
          value: 'note',
          allowSub: false,
          typeData: 'html',
        },
        {
          name: t('tags'),
          value: 'tags',
          allowSub: false,
          typeData: 'tags',
        },
      ];
    case 'storages':
      return [
        {
          name: t('attribute1'),
          value: 'attribute1',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute2'),
          value: 'attribute2',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute3'),
          value: 'attribute3',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute4'),
          value: 'attribute4',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('lastUpdate'),
          value: 'lastUpdate',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: t('timeStamp'),
          value: 'timeStamp',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: 'Notes',
          value: 'note',
          allowSub: false,
          typeData: 'html',
        },
        {
          name: t('tags'),
          value: 'tags',
          allowSub: false,
          typeData: 'tags',
        },
      ];
    case 'passes':
      return [
        {
          name: t('attribute1'),
          value: 'attribute1',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute2'),
          value: 'attribute2',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute3'),
          value: 'attribute3',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('attribute4'),
          value: 'attribute4',
          allowSub: false,
          typeData: 'text',
        },
        {
          name: t('lastUpdate'),
          value: 'lastUpdate',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: t('timeStamp'),
          value: 'timeStamp',
          typeData: 'date-time',
          icon: <CalendarMonthOutlined />,
        },
        {
          name: 'Notes',
          value: 'note',
          allowSub: false,
          typeData: 'html',
        },
        {
          name: t('tags'),
          value: 'tags',
          allowSub: false,
          typeData: 'tags',
        },
      ];
    default:
      return [];
  }
};

export default defaultFieldsResolver;
