import React, { } from 'react';
import SiteLayout from '../SiteLayout';
import HeaderBos from '../components/bos/HeaderBos';
import Build from '../components/bos/Build';
import TryBos from '../components/bos/TryBos';
import Features from '../components/bos/Features';
import Eco from '../components/bos/Eco';
import { useTranslation } from 'react-i18next';

const HomeBos = () => {
  const { t } = useTranslation();
  const pageCode = 'homeBOS';

  return (
    <SiteLayout
      helmetData={{
        name: t(pageCode + 'SEO'),
        description: t(pageCode + 'SEODesc'),
      }}
    >
      <div>
        <HeaderBos translationCode={pageCode + 'Header'} />
        <div className="mb-5">
          <Build translationCode={pageCode + 'Build'} />
        </div>
        <div className="mt-5 mb-5">
          <TryBos translationCode={pageCode + 'Try'} />
        </div>
        <div className="mt-5 mb-5">
          <Features translationCode={pageCode + 'Features'} />
        </div>
        <div className="mt-5">
          <Eco translationCode={pageCode + 'Eco'} />
        </div>
      </div>
    </SiteLayout>
  );
};

export default HomeBos;
