import React, { useState, useEffect, useCallback } from 'react';
import chroma from 'chroma-js';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { I18nextProvider } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Lottie from 'react-lottie';
import animationData from '../lotties/empty-box';
import { DndProvider } from 'react-dnd';
import {
  setCurrentUser,
  setGeneralStatus,
} from '../redux/actions-v2/coreAction.js';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet } from 'react-helmet';
import {
  AppBar,
  Box,
  CircularProgress,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  ListItemText,
  Toolbar,
  Tooltip,
} from '@mui/material';
import AllModalsRoot from '../modals/index.jsx';
import AllSidebarsRoot from '../sidebars/index.jsx';
import AllDrawersRoot from '../drawers/index.jsx';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material';
import PagesNav from '../components/@generalComponents/layout/PagesNav.jsx';

import '../components/@generalComponents/layout/layout.css';
import {
  fetchBusinessData,
  pageLoaded,
  setCurrentSection,
} from '../redux/actions-v2/coreAction.js';
import { clearAllLists } from '../redux/actions-v2/listAction.js';
import DialogWindow from '../stories/general-components/DialogWindow.jsx';
import LogRocket, { log } from 'logrocket';
import TopNavBar from './components/TopNavBar.jsx';
import {
  LayersOutlined,
  MenuOutlined,
  SettingsOutlined,
} from '@mui/icons-material';
import UserSecMenu from '../components/@generalComponents/layout/UserSecMenu.jsx';
import Blocks from '../stories/layout-components/Block.jsx';
import Button from '../stories/general-components/Button.jsx';
import NodeBOS from '../components/NodeBOS.jsx';
import NodeAI from '../components/NodeAI.jsx';
import nodeAxiosFirebase from '../utils/nodeAxiosFirebase.js';
import NodeInsights from '../components/NodeInsights.jsx';
import GeneralText from '../stories/general-components/GeneralText.jsx';

const MainLayoutV2 = ({
  children,
  pageTitle,
  tabs,
  actions,
  sectionTitle,
  formatedPath,
  elementId,
  searchActivated,
  icon,
  manualIndex,
  usersInRoom,
  error404,
  subTitle,
  hideMenu,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const currentLocation = useLocation();
  const currentPath = currentLocation.pathname;

  const { moduleId, structureId } = useParams();

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerMenuOpen, setDrawerMenuOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hoveredLogo, setHoveredLogo] = useState(null);
  const businessesStorage = sessionStorage.getItem('businesses');
  const businesses = JSON.parse(businessesStorage);

  const [taskOpen, setTaskOpen] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [animationKey, setAnimationKey] = useState(0);
  const [currentStatusMain, setCurrentStatusMain] = useState({
    status: '',
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const isDarkMode = theme.palette.mode === 'dark';

  const softwareVersion = process.env.REACT_APP_VERSION;

  const presences = useSelector((state) => state.core.onCall);
  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );
  const currentUser = useSelector((state) => state.core.user);
  const businessModules = businessStructure?.modules;
  const currentStatus = useSelector((state) => state.core.status);

  const mainColor = businessPreference?.mainColor || '#000000';
  const secColor = businessPreference?.secColor || '#000000';
  const lightGreyScale = chroma
    .scale([mainColor, '#f2f2f2'])
    .mode('lab')
    .colors(10)
    .map((color) => chroma(color).brighten(1.4));

  const lightGreyScaleSec = chroma
    .scale([secColor, '#f2f2f2'])
    .mode('lab')
    .colors(10)
    .map((color) => chroma(color).brighten(1.4));

  useEffect(() => {
    if (currentStatus?.status === 'loading') {
      setCurrentStatusMain('loading');
      setTimeout(() => {
        setCurrentStatusMain('');
      }, 2200);
    } else if (currentStatus?.status === 'error') {
      setCurrentStatusMain('error');
      setTimeout(() => {
        setCurrentStatusMain('');
      }, 1000);
    } else if (currentStatus?.status === 'success') {
      setCurrentStatusMain('success');
      setTimeout(() => {
        setCurrentStatusMain('');
      }, 2800);
    } else {
      setCurrentStatusMain('');
    }
  }, [currentStatus?.status]);

  const handleNavigateDash = useCallback((section) => {
    dispatch(setCurrentSection('OPERATIONS'));
    const menu = businessPreference?.menu?.find(
      (item) => item?.section === 'OPERATIONS' && item?.order === 0
    );
    const finalUrl = '/app' + (menu?.url)
    navigate(finalUrl);
  }, []);

  const handleNavigateDev = () => {
    dispatch(setCurrentSection('DEV'));
    navigate('/node/resource');
  };

  const handleNavigateAI = () => {
    dispatch(setCurrentSection('AI'));
    navigate('/ai/chat');
  };

  const handleNavigateInsights = () => {
    dispatch(setCurrentSection('PULSE'));
    navigate('/pulse');
  };


  if (
    window.location.hostname !== 'localhost' &&
    window.location.port !== '3000'
  ) {
    LogRocket.init('hpp7xp/node');

    if (currentUser) {
      LogRocket.identify(currentUser?.uid, {
        name: currentUser?.displayName,
        email: currentUser?.email,
        subscriptionType: businessPreference?.formula,
      });
    }
  }

  const boxVariants = {
    normal: {
      left: '0%',
      transform: 'translateX(0%)',
      height: isMobile ? '80vh' : '90vh',
      width: '100%',
      borderRadius: '0px',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      zIndex: 1,
    },
    expanded: {
      left: '0%%',
      transform: 'translateX(13%)',
      height: '84vh',
      width: '100%',
      display: 'block',
      zIndex: 5,
      position: 'relative',
      borderRadius: '10px',
      backgroundColor: isDarkMode ? '#00000092' : '#FFFFFF92',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    },
  };

  const updatePosition = async () => {
    dispatch(
      setGeneralStatus({
        status: 'loading',
        position: 'position',
        type: 'pulse',
      })
    );

    try {
      const users = Object.entries(presences).reduce(
        (acc, [userId, dataId]) => {
          if (dataId === businessPreference?.id) {
            acc[userId] = dataId;
          }
          return acc;
        },
        {}
      );

      if (users[currentUser?.uid] === businessPreference?.id) {
        return;
      }

      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/position`,
        body: {
          pathId: businessPreference?.id,
          action: 'add',
        },
      });

      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'position',
          type: 'pulse',
        })
      );
    } catch (error) {
      console.error('Error position:', error);
    }
  };

  useEffect(() => {
    if (moduleId) {
      updatePosition();
    }
  }, [moduleId]);

  const handleBusinessSelect = (business) => {
    setLoading(true)
    localStorage.setItem('section', 'OPERATIONS');
    dispatch(setCurrentSection('OPERATIONS'));
    dispatch(clearAllLists());
    const userData = {
      uid: currentUser?.uid,
      email: currentUser?.email || '',
      displayName: currentUser?.displayName || '',
      photoURL: currentUser?.photoURL || '',
    };
    dispatch(setCurrentUser(userData, business?.businessId));
    sessionStorage.setItem('businessToken', business?.token);
    localStorage.setItem('businessId', business?.businessId);
    localStorage.setItem('businessName', business?.name);
    dispatch(fetchBusinessData(business?.businessId, t, currentLanguage));
  };

  useEffect(() => {
    if (businessPreference?.id) {
      const currentModule = businessStructure?.structures?.find(
        (structure) => structure.id === structureId
      );
      setLoading(false)
      setDialogOpen(false);

      const currentSection = localStorage.getItem('section');


      if (!currentModule && (currentSection === 'OPERATIONS' || currentSection === 'FINANCES' || currentSection === 'MARKETING') && !currentPath?.startsWith('/app/nodes')) {
        const menu = businessPreference?.menu?.find(
          (item) => item?.section === 'OPERATIONS' && item?.order === 0
        );
        const finalUrl = '/app' + (menu?.url)
        navigate(finalUrl);
      }
    }
  }, [businessPreference?.id, businessPreference?.menu, moduleId, structureId, currentUser]);



  const handleNavigateBack = () => {
    navigate(-1);
  };

  const handleChangeBusiness = () => {
    setDialogOpen(true);
  };

  const openSideTasksAgenda = () => {
    setTaskOpen(!taskOpen);
  };

  const getModuleName = () => {
    const module = businessModules?.find((m) => m.id === moduleId);

    if (pageTitle) {
      return pageTitle || '';
    }

    return module?.name || '';
  };

  useEffect(() => {
    if (businessModules) {
      setTimeout(() => {
        dispatch(pageLoaded(true));
      }, 3000);
    }
  }, [businessModules]);

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <DialogWindow
          title={t('switchAccount')}
          open={dialogOpen}
          size={'medium'}
          width={'medium'}
          onClose={() => setDialogOpen(false)}
        >
          <div>
            <div>
              <CircularProgress
                style={{
                  display: loading ? 'block' : 'none',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            </div>
            <div className="row">
              {businesses &&
                businesses?.map((business, idx) => (
                  <div
                    key={idx}
                    className="col-3 hover align-c p-1"
                    onClick={() => handleBusinessSelect(business)}
                  >
                    <div>
                      <img
                        style={{ width: '30px', height: '30px' }}
                        src={`https://storage.googleapis.com/business-logos-node/${business?.businessId}.png`}
                        alt='Business Logo'
                      />
                    </div>
                    <ListItemText
                      primary={business?.name}
                      primaryTypographyProps={{
                        fontWeight: 500,
                        color: isDarkMode ? '#FFF' : '#000',
                        fontSize: '10px',
                      }}
                      secondary={business?.city || '-'}
                      secondaryTypographyProps={{
                        variant: 'caption',
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        </DialogWindow>

        <I18nextProvider i18n={i18n}>
          <Helmet>
            <title>
              {`Node -  ${getModuleName(pageTitle, currentLanguage)}`}
            </title>
            <meta name="robots" content="noindex" />
          </Helmet>
          <AllModalsRoot />
          {businessPreference?.id && (
            <div
              style={{
                backgroundColor: isDarkMode
                  ? 'rgba(0, 0, 0, 0.82)'
                  : 'rgba(255, 255, 255, 0.57)',
                height: '100vh',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <div
                style={{
                  width: '900px',
                  height: '400px',
                  overflow: 'hidden',
                  backgroundColor: lightGreyScale[0] + '60',
                  borderRadius: '50%',
                  filter: 'blur(120px)',
                  position: 'absolute',
                  zIndex: 1,
                  top: '220px',
                  left: '-420px',
                }}
              />
              <div
                style={{
                  width: '1400px',
                  height: '900px',
                  overflow: 'hidden',
                  backgroundColor: lightGreyScaleSec[2] + '50',
                  borderRadius: '50%',
                  filter: 'blur(120px)',
                  position: 'absolute',
                  zIndex: 1,
                  top: '-120px',
                  right: '-310px',
                }}
              />
              <div
                style={{
                  width: '500px',
                  height: '600px',
                  overflow: 'hidden',
                  backgroundColor: isDarkMode ? '#00000040' : '#FFFFFF',
                  rotate: '325deg',
                  borderRadius: '80px',
                  filter: 'blur(120px)',
                  position: 'absolute',
                  zIndex: 1,
                  bottom: '-190px',
                  left: '-220px',
                }}
              />
              <div
                style={{
                  width: '1200px',
                  height: '1200px',
                  overflow: 'hidden',
                  backgroundColor: lightGreyScale[0] + '60',
                  borderRadius: '50%',
                  filter: 'blur(120px)',
                  position: 'absolute',
                  zIndex: 0,
                  bottom: '-80px',
                  left: '-80px',
                }}
              />
              <div
                style={{
                  width: '900px',
                  height: '900px',
                  overflow: 'hidden',
                  borderRadius: '50%',
                  filter: 'blur(120px)',
                  position: 'absolute',
                  zIndex: 0,
                  top: '-220px',
                  left: '-120px',
                }}
              />
              <div
                style={{
                  backgroundImage: actions?.editLayout?.display
                    ? 'url("/assets/website/v3/grid-main.svg")'
                    : 'none',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  width: '100%',
                  height: '100vh',
                  position: 'absolute',
                  zIndex: 0,
                  top: 0,
                  left: 0,
                }}
              />
              <Grid style={{ height: '100vh' }} container>
                {isMobile && (
                  <>
                    <Drawer sx={{ zIndex: 9999 }} open={drawerMenuOpen} onClose={() => setDrawerMenuOpen(false)} anchor='left'>
                      <Paper elevation={0} sx={{ width: '320px', padding: 2, marginBottom: '180px' }}>
                        <PagesNav />
                        <div>
                          <UserSecMenu isMobile={isMobile} />
                        </div>
                      </Paper>
                    </Drawer>
                    <AppBar position="fixed" sx={{ borderRadius: '0px 0px 8px 8px', backgroundColor: mainColor + '99', height: '55px', p: 0, transition: 'background-color 0.3s ease', }}>
                      <Toolbar
                        sx={{
                          backgroundColor: mainColor + '99',
                          borderRadius: '0px 0px 8px 8px',
                        }}
                      >
                        <div style={{ marginTop: '5px', display: 'flex', justifyContent: 'space-between', width: '100%', }}>
                          <Box
                            sx={{ flexGrow: 0.5, cursor: 'pointer' }}

                          >
                            <img
                              src={`https://storage.googleapis.com/business-logos-node/${businessPreference?.id}.png`}
                              height={isTablet ? '35px' : '45px'}
                              width={isTablet ? '35px' : '45px'}
                              alt={businessPreference?.name + ' Logo'}
                              style={{
                                borderRadius: '50%',
                              }}
                              className="hover"
                              onClick={handleChangeBusiness}
                            />
                          </Box>
                          <Box
                            sx={{ flexGrow: 1, cursor: 'pointer' }}
                          >
                            <GeneralText text={pageTitle || ''} size="bold" fontSize={'14px'} primary={false} color={'#FFFFFF'} classNameComponent="px-2 mt-1" />
                            <GeneralText text={businessPreference?.name || ''} size="regular" fontSize={'10px'} primary={false} color={'#FFFFFF'} classNameComponent="px-2" />
                          </Box>
                          <Box
                            sx={{ flexGrow: 1, cursor: 'pointer', textAlign: 'right' }}
                          >
                            <IconButton onClick={() => setDrawerMenuOpen(!drawerMenuOpen)}><MenuOutlined color='white' /></IconButton>
                          </Box>
                        </div>
                      </Toolbar>
                    </AppBar>
                  </>
                )}
                <Grid
                  sx={{
                    overflow: 'hidden',
                    marginTop: isMobile ? '60px' : null
                  }}
                  container
                >
                  {!actions?.editLayout?.display && !isMobile && (
                    <Grid
                      xs={0.4}
                      sm={0.4}
                      md={0.4}
                      lg={0.4}
                      xl={0.4}
                      item
                      id="side-content"
                      sx={{
                        height: '100vh',
                        maxHeight: '100vh',
                        position: 'relative',
                        zIndex: 10,
                      }}
                    >
                      <div
                        style={{ height: '50vh', position: 'relative' }}
                        className="hover"
                      >
                        <div>
                          <div
                            className="mb-4 p-3 mt-2"
                            style={{
                              height: '60px',
                              zIndex: 1000,
                              minHeight: '55px',
                              position: 'relative',
                            }}
                          >
                            <div
                              className="middle-content"
                              style={{
                                position: 'relative',
                                height: isTablet
                                  ? currentStatusMain === 'loading' ||
                                    currentStatusMain === 'success' ||
                                    currentStatusMain === 'error'
                                    ? '40px'
                                    : '35px'
                                  : currentStatusMain === 'loading' ||
                                    currentStatusMain === 'success' ||
                                    currentStatusMain === 'error'
                                    ? '50px'
                                    : '45px',
                                width: isTablet
                                  ? currentStatusMain === 'loading' ||
                                    currentStatusMain === 'success' ||
                                    currentStatusMain === 'error'
                                    ? '40px'
                                    : '35px'
                                  : currentStatusMain === 'loading' ||
                                    currentStatusMain === 'success' ||
                                    currentStatusMain === 'error'
                                    ? '50px'
                                    : '45px',
                              }}
                            >
                              <img
                                src={`https://storage.googleapis.com/business-logos-node/${businessPreference?.id}.png`}
                                height={isTablet ? '35px' : '45px'}
                                width={isTablet ? '35px' : '45px'}
                                alt={businessPreference?.name + ' Logo'}
                                style={{
                                  borderRadius: '50%',
                                  position: 'absolute',
                                  zIndex: 100,
                                  marginLeft: '2.8px',
                                  marginTop: '2.8px',
                                }}
                                className="hover"
                                onClick={handleChangeBusiness}
                              />
                              <div
                                style={{
                                  borderRadius: '50%',
                                  position: 'absolute',
                                  zIndex: 90,
                                  backgroundColor:
                                    currentStatusMain === 'loading'
                                      ? businessPreference?.mainColor + '70'
                                      : currentStatusMain === 'error'
                                        ? '#e3292970'
                                        : currentStatusMain === 'success'
                                          ? '#c2de2370'
                                          : 'transparent',
                                  height: isTablet
                                    ? currentStatusMain === 'loading' ||
                                      currentStatusMain === 'success' ||
                                      currentStatusMain === 'error'
                                      ? '40px'
                                      : '35px'
                                    : currentStatusMain === 'loading' ||
                                      currentStatusMain === 'success' ||
                                      currentStatusMain === 'error'
                                      ? '50px'
                                      : '45px',
                                  width: isTablet
                                    ? currentStatusMain === 'loading' ||
                                      currentStatusMain === 'success' ||
                                      currentStatusMain === 'error'
                                      ? '40px'
                                      : '35px'
                                    : currentStatusMain === 'loading' ||
                                      currentStatusMain === 'success' ||
                                      currentStatusMain === 'error'
                                      ? '50px'
                                      : '45px',
                                }}
                                className={
                                  currentStatusMain === 'loading'
                                    ? 'pulse-loading'
                                    : ''
                                }
                              ></div>
                            </div>
                          </div>

                          <Divider
                            component="div"
                            sx={{ marginLeft: '10px' }}
                          />
                        </div>
                        <div
                          style={{ position: 'relative', marginRight: '45px' }}
                          className="mt-3 align-right"
                        >
                          {businessPreference?.formula &&
                            currentUser?.activeBusiness?.modules?.bos !==
                            false && (
                              <div
                                style={{ position: 'relative', height: '65px' }}
                                className="d-flex"
                              >
                                <div style={{ position: 'absolute' }}>
                                  <img
                                    style={{
                                      opacity:
                                        currentPath?.startsWith(
                                          '/app/operations'
                                        ) ||
                                          currentPath?.startsWith(
                                            '/app/dashboard'
                                          ) ||
                                          currentPath?.startsWith(
                                            '/app/marketing'
                                          ) ||
                                          currentPath?.startsWith('/app/nodes') ||
                                          currentPath?.startsWith('/app/inbox') ||
                                          currentPath?.startsWith(
                                            '/app/element'
                                          ) ||
                                          currentPath?.startsWith('/app/finances')
                                          ? 1
                                          : 0.65,
                                    }}
                                    src={`/assets/v3/components/menu${isDarkMode ? '-dark' : ''
                                      }.svg`}
                                    width={'65px'}
                                    height={'65px'}
                                    alt="Menu"
                                  />
                                </div>
                                <Tooltip title={t('nodeBOS')}>
                                  <div
                                    onClick={handleNavigateDash}
                                    style={{
                                      position: 'absolute',
                                      width: '45px',
                                      height: '45px',
                                      borderRadius: '50% 0% 0% 50%',
                                      marginBottom: '15px',
                                      padding: '5px',
                                    }}
                                  >
                                    <div
                                      onMouseEnter={() => setHoveredLogo('BOS')}
                                      onMouseLeave={() => setHoveredLogo(null)}
                                      style={{
                                        marginTop: '14px',
                                        marginLeft: '6px',
                                        width: '28px',
                                        height: '28px',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <NodeBOS
                                        primaryColor={
                                          hoveredLogo === 'BOS' ||
                                            currentPath?.startsWith('/app/')
                                            ? businessPreference?.mainColor
                                            : businessPreference?.mainColor +
                                            '99'
                                        }
                                        secondaryColor={
                                          businessPreference?.secColor
                                        }
                                      />
                                    </div>
                                  </div>
                                </Tooltip>
                              </div>
                            )}

                          {businessPreference?.modules?.includes('pulse') &&
                            currentUser?.activeBusiness?.modules?.insights !==
                            false && (
                              <div
                                style={{ position: 'relative', height: '65px' }}
                                className="d-flex"
                              >
                                <div style={{ position: 'absolute' }}>
                                  <img
                                    src={`/assets/v3/components/menu${isDarkMode ? '-dark' : ''
                                      }.svg`}
                                    style={{
                                      opacity: currentPath?.startsWith(
                                        '/pulse'
                                      )
                                        ? 1
                                        : 0.65,
                                    }}
                                    height={'65px'}
                                    alt="Menu"
                                  />
                                </div>
                                <Tooltip title={t('nodeInsights')}>
                                  <div
                                    onClick={handleNavigateInsights}
                                    style={{
                                      position: 'absolute',
                                      width: '45px',
                                      height: '45px',
                                      borderRadius: '50% 0% 0% 50%',
                                      marginBottom: '15px',
                                      padding: '5px',
                                    }}
                                  >
                                    <div
                                      onMouseEnter={() =>
                                        setHoveredLogo('PULSE')
                                      }
                                      onMouseLeave={() => setHoveredLogo(null)}
                                      style={{
                                        marginTop: '17px',
                                        marginLeft: '11px',
                                        width: '22px',
                                        height: '22px',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <NodeInsights
                                        primaryColor={
                                          hoveredLogo === 'PULSE' ||
                                            currentPath?.startsWith('/pulse')
                                            ? businessPreference?.mainColor
                                            : businessPreference?.mainColor +
                                            '99'
                                        }
                                        secondaryColor={
                                          businessPreference?.secColor
                                        }
                                      />
                                    </div>
                                  </div>
                                </Tooltip>
                              </div>
                            )}
                          {/* {businessPreference?.formula?.toUpperCase() ===
                            'RISE' &&
                            currentUser?.activeBusiness?.modules?.connect !==
                            false && (
                              <div
                                style={{ position: 'relative', height: '65px' }}
                                className="d-flex"
                              >
                                <div style={{ position: 'absolute' }}>
                                  <img
                                    src={`/assets/v3/components/menu${isDarkMode ? '-dark' : ''
                                      }.svg`}
                                    width={'65px'}
                                    style={{
                                      opacity: currentPath?.startsWith(
                                        '/connect/'
                                      )
                                        ? 1
                                        : 0.65,
                                    }}
                                    height={'65px'}
                                    alt="Menu"
                                  />
                                </div>
                                <Tooltip title={t('nodeConnect')}>
                                  <div
                                    onClick={handleNavigateConnect}
                                    style={{
                                      position: 'absolute',
                                      width: '45px',
                                      height: '45px',
                                      borderRadius: '50% 0% 0% 50%',
                                      marginBottom: '15px',
                                      padding: '5px',
                                    }}
                                  >
                                    {' '}
                                    <div
                                      onMouseEnter={() =>
                                        setHoveredLogo('CONNECT')
                                      }
                                      onMouseLeave={() => setHoveredLogo(null)}
                                      style={{
                                        marginTop: '17px',
                                        marginLeft: '10px',
                                        width: '31px',
                                        height: '31px',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <NodeConnect
                                        primaryColor={
                                          hoveredLogo === 'CONNECT' ||
                                            currentPath?.startsWith('/connect/')
                                            ? businessPreference?.mainColor
                                            : businessPreference?.mainColor +
                                            '99'
                                        }
                                        secondaryColor={
                                          businessPreference?.secColor
                                        }
                                      />
                                    </div>
                                  </div>
                                </Tooltip>
                              </div>
                            )} */}
                          {businessPreference?.formula?.toUpperCase() ===
                            'RISE' &&
                            currentUser?.activeBusiness?.modules?.ai !==
                            false && (
                              <div
                                style={{ position: 'relative', height: '65px' }}
                                className="d-flex"
                              >
                                <div style={{ position: 'absolute' }}>
                                  <img
                                    src={`/assets/v3/components/menu${isDarkMode ? '-dark' : ''
                                      }.svg`}
                                    width={'65px'}
                                    style={{
                                      opacity: currentPath?.startsWith('/ai/')
                                        ? 1
                                        : 0.65,
                                    }}
                                    height={'65px'}
                                    alt="Menu"
                                  />
                                </div>
                                <Tooltip title={t('nodeAI')}>
                                  <div
                                    onClick={handleNavigateAI}
                                    style={{
                                      position: 'absolute',
                                      width: '45px',
                                      height: '45px',
                                      borderRadius: '50% 0% 0% 50%',
                                      marginBottom: '15px',
                                      padding: '5px',
                                    }}
                                  >
                                    <div
                                      onMouseEnter={() => setHoveredLogo('AI')}
                                      onMouseLeave={() => setHoveredLogo(null)}
                                      style={{
                                        marginTop: '15px',
                                        marginLeft: '10px',
                                        width: '25px',
                                        height: '25px',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <NodeAI
                                        strokeColor={
                                          isDarkMode ? '#000' : '#FFF'
                                        }
                                        primaryColor={
                                          hoveredLogo === 'AI' ||
                                            currentPath?.startsWith('/ai/')
                                            ? businessPreference?.mainColor
                                            : businessPreference?.mainColor +
                                            '99'
                                        }
                                        secondaryColor={
                                          businessPreference?.secColor
                                        }
                                      />
                                    </div>
                                  </div>
                                </Tooltip>
                              </div>
                            )}
                          {businessPreference?.id === '6jyRhYMJ45SObIlg89EK' &&
                            currentUser?.activeBusiness?.modules?.bos !==
                            false && (currentUser?.uid === 'kIb0dHh5Mtdg5U5Eda7NFHk2cL83' || currentUser?.uid === 'v3yIfTXpDgYRUaMn3u27SthdozW2') && (
                              <div
                                style={{ position: 'relative', height: '65px' }}
                                className="d-flex"
                              >
                                <div style={{ position: 'absolute' }}>
                                  <img
                                    style={{
                                      opacity:

                                        currentPath?.startsWith('/node')
                                          ? 1
                                          : 0.65,
                                    }}
                                    src={`/assets/v3/components/menu${isDarkMode ? '-dark' : ''
                                      }.svg`}
                                    width={'65px'}
                                    height={'65px'}
                                    alt="Menu"
                                  />
                                </div>
                                <Tooltip title={t('nodeDev')}>
                                  <div
                                    onClick={handleNavigateDev}
                                    style={{
                                      position: 'absolute',
                                      width: '45px',
                                      height: '45px',
                                      borderRadius: '50% 0% 0% 50%',
                                      marginBottom: '15px',
                                      padding: '5px',
                                    }}
                                  >
                                    <div
                                      onMouseEnter={() => setHoveredLogo('DEV')}
                                      onMouseLeave={() => setHoveredLogo(null)}
                                      style={{
                                        marginTop: '14px',
                                        marginLeft: '6px',
                                        width: '28px',
                                        height: '28px',
                                        cursor: 'pointer',
                                        fontSize: '24px'
                                      }}
                                    >
                                      🔪
                                    </div>
                                  </div>
                                </Tooltip>
                              </div>
                            )}
                        </div>
                      </div>
                      <div style={{ height: isTablet ? '10vh' : '20vh' }}></div>
                      <div
                        style={{
                          height: isTablet ? '25vh' : '15vh',
                          display: 'flex',
                          flexDirection: 'column',
                          padding: isTablet ? '13px' : '20px',
                        }}
                      >
                        <UserSecMenu />
                      </div>
                    </Grid>
                  )}
                  {!actions?.editLayout?.display && !isMobile && (
                    <Grid
                      sx={{
                        padding: '2vh 0vh 2vh 2vh',
                        height: '96vh',
                        maxHeight: '96vh',
                        position: 'relative',
                        zIndex: 10,
                      }}
                      xs={2.15}
                      sm={0.95}
                      md={2.15}
                      lg={2.15}
                      xl={2.15}
                      item
                    >
                      <Paper
                        className="glassmorphism"
                        sx={{
                          borderRadius: '12px 0px 0px 12px',
                          border: '1.5px solid',
                          borderColor: isDarkMode ? '#000000' : '#FFFFFF',
                          backgroundColor: isDarkMode
                            ? 'rgba(0, 0, 0, 0.55)'
                            : 'rgba(255, 255, 255, 0.55)',
                          padding: '10px',
                        }}
                        elevation={0}
                      >
                        <div
                          style={{
                            borderRadius: '12px',
                            backgroundColor: isDarkMode
                              ? 'rgba(0, 0, 0, 0.55)'
                              : businessPreference?.mainColor + '30' ||
                              'rgba(255, 255, 255, 0.70)',
                          }}
                        >
                          <div
                            style={{
                              height: '88vh',
                              overflowY: 'scroll',
                              overflowX: 'hidden',
                              position: 'relative',
                              marginRight: '-5px',
                              zIndex: 1000,
                            }}
                          >
                            <PagesNav
                              isTablet={isTablet}
                              tasksOpen={taskOpen}
                              openSideTasksAgenda={openSideTasksAgenda}
                              drawerOpen={drawerOpen}
                              setDrawerOpen={setDrawerOpen}
                            />
                          </div>
                          <div
                            style={{
                              position: 'relative',
                              height: '6vh',
                              paddingLeft: '20px',
                              overflow: 'hidden',
                              paddingTop: '20px',
                            }}
                          >
                            <Typography
                              variant="caption"
                              fontSize={isTablet ? '8px' : '9px'}
                              color={isDarkMode ? '#FFFFFF85' : '#00000085'}
                              lineHeight="1"
                              display="block"
                              fontWeight={600}
                              gutterBottom
                            >
                              {isTablet ? 'Node™' : 'Intelligence Node Canada™'}
                            </Typography>
                            <Typography
                              variant="caption"
                              fontSize={isTablet ? '6.5px' : '6px'}
                              color={isDarkMode ? '#FFFFFF85' : '#00000085'}
                              lineHeight="1"
                              display="block"
                              gutterBottom
                            >
                              Version {softwareVersion} - Tous droits réservés.
                            </Typography>
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                  )}
                  <Grid
                    xs={12}
                    sm={10.6}
                    md={9.4}
                    lg={9.4}
                    xl={9.4}
                    item
                    id="main-content"
                    sx={{
                      padding: isMobile ? '2vh' : '2vh 0vh 2vh 0vh',
                      height: isMobile ? '85vh' : '96vh',
                      maxHeight: isMobile ? '85vh' : '96vh',
                      position: 'relative',
                      zIndex: 10,
                    }}
                  >
                    <Paper
                      className="glassmorphism"
                      sx={{
                        border: actions?.editLayout?.display
                          ? 'none'
                          : '1.5px solid',
                        borderColor: isDarkMode ? '#000000' : '#FFFFFF',
                        marginLeft: '-2px',
                        borderRadius: isMobile ? '12px' : '0px 12px 12px 0px',
                        backgroundColor: actions?.editLayout?.display
                          ? 'transparent'
                          : isDarkMode
                            ? 'rgba(0, 0, 0, 0.55)'
                            : 'rgba(255, 255, 255, 0.55)',
                      }}
                      elevation={0}
                    >
                      <div>
                        {!actions?.editLayout?.display && (
                          <TopNavBar
                            isTablet={isTablet}
                            isMobile={isMobile}
                            pageTitle={pageTitle}
                            searchActivated={searchActivated}
                            actions={actions}
                            iconMain={icon}
                            elementId={elementId}
                            formatedPath={formatedPath}
                            usersInRoom={usersInRoom}
                            manualIndex={manualIndex}
                            tabs={tabs}
                            sectionTitle={sectionTitle}
                            subTitle={subTitle}
                          />
                        )}

                        <AllSidebarsRoot />
                        <AllDrawersRoot />

                        <div>
                          {!error404 ? (
                            <>
                              {actions?.editLayout?.display ? (
                                <motion.div
                                  animate={
                                    actions?.editLayout?.display ? 'expanded' : 'normal'
                                  }
                                  variants={boxVariants}
                                >
                                  <div
                                    style={{
                                      height: actions?.editLayout?.display
                                        ? '85vh'
                                        : isMobile ? '80vh' : '90vh',
                                    }}
                                  >
                                    {children}
                                  </div>

                                </motion.div>
                              ) : (
                                <div>
                                  <div
                                    style={{
                                      height: actions?.editLayout?.display
                                        ? '85vh'
                                        : isMobile ? '80vh' : '90vh',
                                    }}
                                  >
                                    {children}
                                  </div>
                                </div>)}
                            </>
                          ) : (
                            <div>
                              <Blocks heightPercentage={isMobile ? 90 : 98}>
                                <div className="middle-content align-c mt-5">
                                  <div className="mt-5">
                                    <Lottie
                                      options={defaultOptions}
                                      height={80}
                                      width={100}
                                    />
                                    <div className="middle-content mt-3">
                                      <Typography
                                        variant="h5"
                                        fontSize={'24px'}
                                        lineHeight="1.2"
                                        display="block"
                                        fontWeight={600}
                                        gutterBottom
                                      >
                                        {t('error404')}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        fontSize={'14px'}
                                        lineHeight="1.2"
                                        display="block"
                                        fontWeight={400}
                                        gutterBottom
                                      >
                                        {t('error404Desc')}
                                      </Typography>
                                    </div>
                                  </div>
                                  <div className="mt-4">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      label={t('navigateBack')}
                                      onClick={handleNavigateBack}
                                    />
                                  </div>
                                </div>
                              </Blocks>
                            </div>
                          )}
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <motion.div
                initial={{
                  bottom: '0',
                  left: '0',
                  width: '90%',
                  height: '90px',
                }}
                animate={{
                  bottom: '0',
                  left: '0',
                  width: '90%',
                  height: '90px',
                }}
                transition={{
                  type: 'spring',
                  stiffness: 200,
                  damping: 20,
                }}
                style={{
                  position: 'fixed',
                  margin: '2% 5%',
                  borderRadius: '10px',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                  backgroundColor: isDarkMode ? '#1a1a1a' : 'white',
                  zIndex: 1000,
                  padding: '15px',
                  display: actions?.editLayout?.display ? 'block' : 'none',
                }}
              >
                <div>
                  <DialogTitle
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ display: 'flex', right: 0 }}>
                      <LayersOutlined
                        htmlColor={businessPreference?.mainColor}
                        style={{ marginTop: '-1px' }}
                      />

                      <div>
                        <GeneralText
                          text={t('editLayout')}
                          size="bold"
                          fontSize={isTablet ? '14px' : '17px'}
                          primary={false}
                          color={businessPreference?.mainColor}
                          classNameComponent="px-2"
                        />
                        <div
                          style={{
                            marginTop: isTablet ? '-6px' : '-8px',
                          }}
                        >
                          <GeneralText
                            text={subTitle}
                            size="regular"
                            fontSize={isTablet ? '9px' : '10px'}
                            primary={false}
                            color={'#69696970'}
                            classNameComponent="px-2"
                          />
                        </div>
                      </div>
                      <div
                        className="mx-3"
                        style={{
                          display: 'flex',
                          right: 0,
                          marginTop: '-5px',
                        }}
                      >
                        {actions?.editLayout?.displaySettings &&
                          (currentUser?.activeBusiness?.role ===
                            'SUPER-ADMIN' ||
                            currentUser?.activeBusiness?.role === 'ADMIN') && (
                            <IconButton
                              size="medium"
                              onClick={actions?.editLayout?.displaySettings}
                            >
                              <SettingsOutlined />
                            </IconButton>
                          )}
                      </div>
                    </div>
                    {(businessPreference?.formula?.toUpperCase() === 'RISE' ||
                      businessPreference?.formula?.toUpperCase() ===
                      'TRIAL') && (
                        <div
                          style={{
                            marginTop: '-7px',
                          }}
                          className="d-flex align-c middle-content"
                        >
                          <div
                            className="mx-3"
                            style={{
                              width: 17,
                              margin: 1,
                              transform: 'rotate(45deg)',
                            }}
                          >
                            <motion.div
                              animate={{ rotate: [0, 360, 0] }}
                              key={animationKey}
                              transition={{
                                duration: 2,
                                ease: 'easeInOut',
                              }}
                              style={{
                                display: 'inline-block',
                              }}
                            >
                              <NodeAI
                                primaryColor={
                                  alertMsg?.color
                                    ? alertMsg?.color
                                    : businessPreference?.mainColor
                                }
                                secondaryColor={
                                  alertMsg?.color
                                    ? alertMsg?.color + '50'
                                    : businessPreference?.secColor
                                }
                              />
                            </motion.div>
                          </div>
                          <motion.div
                            key={animationKey}
                            style={{
                              backgroundColor: alertMsg?.color
                                ? alertMsg?.color + '10'
                                : businessPreference?.mainColor + '10',
                              borderRadius: '10px 10px 10px 0px',
                              display: 'inline-flex',
                              color: alertMsg?.color
                                ? alertMsg?.color
                                : businessPreference?.mainColor || '#000',
                              fontSize: '13px',
                              whiteSpace: 'pre-wrap',
                              overflow: 'hidden',
                            }}
                            initial={{ width: 50 }}
                            animate={{ width: 'auto' }}
                            transition={{
                              duration: 3,
                              ease: 'easeInOut',
                            }}
                          >
                            <div
                              style={{
                                margin: '4px 20px',
                              }}
                            >
                              {alertMsg?.text?.split('').map((char, index) => (
                                <motion.span
                                  key={`${animationKey}-${index}`}
                                  custom={index}
                                  initial="hidden"
                                  animate="visible"
                                >
                                  {char === ' ' ? '\u00A0' : char}
                                </motion.span>
                              ))}
                            </div>
                          </motion.div>
                        </div>
                      )}
                    <div style={{ marginTop: '-3px' }} className="d-flex">
                      <Button
                        variant="text"
                        onClick={
                          actions?.editLayout?.actionCancel
                        }
                        label={t('cancel')}
                        fullWidth
                        noShadow
                        color={'#696969'}
                        buttonSx={{
                          minWidth: '150px',
                          marginLeft: 2,
                          lineHeight: 1,
                        }}
                      />
                      <Button
                        variant="text"
                        onClick={
                          actions?.editLayout?.displayPublic
                            ? actions?.editLayout?.addBlockPublic
                            : actions?.editLayout?.addBlock
                        }
                        label={t('addBlock')}
                        fullWidth
                        startIcon={'AddOutlined'}
                        noShadow
                        buttonSx={{
                          minWidth: '150px',
                          marginLeft: 2,
                          lineHeight: 1,
                        }}
                      />

                      <Button
                        onClick={
                          actions?.editLayout?.displayPublic
                            ? actions?.editLayout?.actionPublic
                            : actions?.editLayout?.action
                        }
                        label={t('save')}
                        fullWidth
                        startIcon={'SaveOutlined'}
                        buttonSx={{
                          minWidth: '200px',
                          marginLeft: 2,
                        }}
                      />
                    </div>
                  </DialogTitle>
                </div>
              </motion.div>
            </div>
          )}
        </I18nextProvider>
      </DndProvider>
    </div>
  );
};

export default MainLayoutV2;
