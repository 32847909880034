import React, { useState, useEffect, useRef } from 'react';
import chroma from 'chroma-js';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Typo from '../Typo';
import {
  AccountCircleOutlined,
  ChecklistOutlined,
  GroupsOutlined,
  HardwareOutlined,
  Inventory2Outlined,
  ReceiptLongOutlined,
  SupportAgentOutlined,
  ViewKanbanOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

const ModulesList = ({ translationCode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';
  const localStorageColor = localStorage.getItem('mainColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';

  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  const modulesList = [
    {
      title: 'M1',
      content: 'Cont1',
      short: 'Short1',
      img: './assets/website/v3/crm.png',
      icon: <Inventory2Outlined sx={{ fontSize: '30px' }} />,
    },
    {
      title: 'M2',
      content: 'Cont2',
      short: 'Short2',
      img: './assets/website/v3/crm.png',
      icon: <ReceiptLongOutlined sx={{ fontSize: '30px' }} />,
    },
    {
      title: 'M3',
      content: 'Cont3',
      short: 'Short3',
      img: './assets/website/v3/crm.png',
      icon: <AccountCircleOutlined sx={{ fontSize: '30px' }} />,
    },
    {
      title: 'M4',
      content: 'Cont4',
      short: 'Short4',
      img: './assets/website/v3/crm.png',
      icon: <ViewKanbanOutlined sx={{ fontSize: '30px' }} />,
    },
    {
      title: 'M5',
      content: 'Cont5',
      short: 'Short5',
      img: './assets/website/v3/crm.png',
      icon: <ChecklistOutlined sx={{ fontSize: '30px' }} />,
    },
    {
      title: 'M6',
      content: 'Cont6',
      short: 'Short6',
      img: './assets/website/v3/crm.png',
      icon: <SupportAgentOutlined sx={{ fontSize: '30px' }} />,
    },
    {
      title: 'M7',
      content: 'Cont7',
      short: 'Short7',
      img: './assets/website/v3/crm.png',
      icon: <HardwareOutlined sx={{ fontSize: '30px' }} />,
    },
    {
      title: 'M8',
      content: 'Cont8',
      short: 'Short8',
      img: './assets/website/v3/crm.png',
      icon: <GroupsOutlined sx={{ fontSize: '30px' }} />,
    },
  ];

  const infiniteModules = [...modulesList, ...modulesList];
  const [activeIndex, setActiveIndex] = useState(0);
  const carouselRef = useRef(null);

  const nextModule = () => {
    setActiveIndex((prevIndex) => prevIndex + 1);
  };

  const navigateFeatures = () => {
    navigate('/products/bos/perform');
  };

  useEffect(() => {
    const interval = setInterval(nextModule, 4000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box mt={10} sx={{ px: { xs: 2, sm: 4, md: 8 } }}>
      <Box className="container">
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
        >
          <Box
            width={{ xs: '100%', md: '50%' }}
            textAlign={{ xs: 'center', md: 'left' }}
          >
            <Typo
              text={t(translationCode + 'Title')}
              variant="h2"
              className="mt-1"
            />
            <Typo
              text={t(translationCode + 'Description')}
              variant="body2"
              className="mt-2"
            />
            <Button
              variant="contained"
              disableElevation
              onClick={navigateFeatures}
              sx={{
                backgroundColor: darkMainColor,
                borderRadius: '21px',
                textTransform: 'none',
                fontWeight: 'bold',
                mt: 4,
              }}
            >
              {t(translationCode + 'CTA')}
            </Button>
          </Box>
          <Box
            width={{ xs: '100%', md: '40%' }}
            textAlign="left"
            mt={{ xs: 4, md: 0 }}
          >
            <Typo
              text={t(translationCode + infiniteModules[activeIndex]?.title)}
              variant="body2"
              bold
              className="mt-5"
            />
            <Typo
              text={t(translationCode + infiniteModules[activeIndex]?.content)}
              className="mt-2"
            />
            <Box mt={3} display="flex" justifyContent="center">
              <IconButton
                onClick={() => setActiveIndex((prevIndex) => prevIndex - 1)}
              >
                <ArrowBackOutlinedIcon />
              </IconButton>
              <Box width="15px" />
              <IconButton onClick={nextModule}>
                <ArrowForwardOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        overflow="hidden"
        width="100%"
        justifyContent="center"
        mt={5}
      >
        <Box
          ref={carouselRef}
          display="flex"
          gap={2}
          sx={{
            transform: `translateX(-${(activeIndex % modulesList.length) * 230
              }px)`,
            transition: 'transform 0.6s ease-in-out',
            whiteSpace: 'nowrap',
          }}
        >
          {infiniteModules.map((module, index) => (
            <Box
              key={index}
              width="210px"
              minWidth="210px"
              borderRadius="15px"
              border="1px solid #e0e0e0"
              padding="20px"
              textAlign="center"
              sx={{
                backgroundColor:
                  activeIndex % modulesList.length ===
                    index % modulesList.length
                    ? mainColor + '10'
                    : isDarkMode
                      ? '#000'
                      : '#fff',
                transition: 'background-color 0.3s ease',
              }}
            >
              {module?.icon}

              <Typo
                text={t(translationCode + module.title)}
                variant="body1"
                bold
                className="mt-4"
                sx={{ fontSize: '12px' }}
              />
              <Typo
                text={t(translationCode + module.short)}
                className="mt-2"
                variant="p"
                sx={{
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'normal',
                  display: 'block',
                  maxWidth: '100%',
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ModulesList;
