import { Box, Divider } from '@mui/material';
import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Typo from './Typo';

const Footer = () => {
  const navigate = useNavigate();
  const { t, } = useTranslation();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <>
      <Divider component="div" />
      <div
        className="row align-c"
        style={{
          minHeight: '200px',
          padding: '30px',
          backgroundColor: isDarkMode ? '#000000' : '#ffffff',
        }}
      >
        <div className="row">
          <div className="col-md-5 col-12 align-left">
            {' '}
            <Box sx={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
              <img
                src="/assets/website/softwares/node-logo.png"
                height={22}
                alt="Node"
              />
              <div>
                <Typo
                  text={'Intelligence Node Canada  ©' + moment().format('YYYY')}
                  variant="p"
                  className="mt-3"
                  bold
                />
                <Typo
                  text="1-888-343-0310"
                  variant="p-small"
                  className="mt-2"
                />
                <Typo
                  text="support@usenode.com"
                  variant="p-small"
                  className="mt-1"
                />
              </div>
            </Box>
          </div>
          <div className="col-md-6 col-12 align-left d-flex">
            <div className="row">
              <div className="col-md-4 col-12 mt-3">
                <Typo text={t('products')} variant="p" bold />{' '}
                <Typo
                  text="BOS™"
                  variant="p-small"
                  className="mt-3"
                  navigation={'/products/bos/overview'}
                />
                <Typo
                  text="Growth™"
                  variant="p-small"
                  className="mt-2"
                  navigation={'/products/growth/strategies'}
                />
                <Typo
                  text="Roam™"
                  variant="p-small"
                  className="mt-2"
                  navigation={'/products/roam/directory'}
                />
                <Typo
                  text="Pulse™"
                  variant="p-small"
                  className="mt-2"
                  navigation={'/products/pulse/dashboards'}
                />
                <Typo text="Desk™" variant="p-small" className="mt-2" />
              </div>
              <div className="col-md-4 col-12 mt-3">
                {' '}
                <Typo text={t('links')} variant="p" bold />
                {/* <Typo
                  text={t('blog')}
                  variant="p-small"
                  className="mt-3"
                  navigation={'/blog'}
                /> */}
                <Typo
                  text={t('demo')}
                  variant="p-small"
                  className="mt-2"
                  navigation={'/demo'}
                />
                <Typo
                  text={t('pricing')}
                  variant="p-small"
                  className="mt-2"
                  navigation={'/pricing'}
                />
                <Typo
                  text={t('nodeAI')}
                  variant="p-small"
                  className="mt-2"
                  navigation={'/node-ai'}
                />{' '}
                <Typo
                  text={t('docs')}
                  variant="p-small"
                  className="mt-2"
                  navigation={'/docs'}
                />
                {/* <Typo
                  text={t('partnerProgram')}
                  variant="p-small"
                  className="mt-2"
                  navigation={'/partners'}
                /> */}
              </div>
              <div className="col-md-4 col-12 mt-3">
                {' '}
                <Typo text={t('legal')} variant="p" bold />
                <Typo
                  text={t('privacyPolicy')}
                  variant="p-small"
                  className="mt-3"
                  navigation={'/informations/mentions-legales'}
                />
                <Typo
                  text={t('contact')}
                  variant="p-small"
                  className="mt-2"
                  navigation={'/contact'}
                />
              </div>
            </div>
          </div>
          <div className="col-md-1 col-12 align-left"></div>
        </div>
      </div>
    </>
  );
};

export default Footer;
