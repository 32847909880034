import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';
import { Button } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import Typo from '../Typo';
import { useNavigate } from 'react-router-dom';

const ModulesIndustries = ({ translationCode }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const secColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const lightMainColor = chroma(mainColor + '30')
    .brighten(2.2)
    .hex();
  const lightVarColor = chroma(secColor).brighten(2.2).hex();
  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  const navigateDemo = () => {
    navigate('/demo');
  };

  return (
    <div style={{ marginTop: '130px' }} className="container">
      <div className="row">
        <div
          className={
            isMobile ? 'col-md-6 col-12 mt-5 px-5' : 'col-md-6 col-12 mt-5'
          }
        >
          {' '}
          <Typo
            text={t(translationCode + 'Title')}
            variant="h2"
            className="mt-1"
            sx={{ maxWidth: '300px' }}
          />
          <Typo
            text={t(translationCode + 'Title2')}
            variant="h2"
            className="mt-1"
            sx={{ maxWidth: '300px' }}
          />
          <Typo
            text={t(translationCode + 'Description')}
            variant="body2"
            className="mt-4"
            sx={{ maxWidth: '600px' }}
          />
          <Button
            variant="contained"
            disableElevation
            onClick={navigateDemo}
            sx={{
              backgroundColor: mainColor,
              borderRadius: '21px',
              textTransform: 'none',
              fontWeight: 'bold',
              mt: 4,
            }}
          >
            {t(translationCode + 'CTA')}
          </Button>
        </div>
        <div className="col-md-6 col-12 align-c mt-5">
          <img
            src="/assets/website/v3/industry1.jpg"
            height={250}
            width={430}
            alt="Entrepreneur"
            style={{ objectFit: 'cover', borderRadius: '15px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default ModulesIndustries;
