import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { Box } from '@mui/material';
import Typo from '../Typo';
import Content from '../dynamicSVG/Content';
import Component from '../dynamicSVG/Component';
import Sync from '../dynamicSVG/Sync';
import Logs from '../dynamicSVG/Logs';
import List from '../dynamicSVG/List';

const Display = ({ translationCode }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';
  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const secColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  const [currentSection, setCurrentSection] = useState('Sync');

  return (
    <div
      style={{
        marginTop: '200px',
        paddingLeft: isMobile ? '15px' : '',
      }}
      className="container"
    >
      <div className="row">
        <div className="col-md-8 col-12 align-left">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="left"
            gap={4}
            sx={{ mt: 2, mb: 3 }}
          >
            <Typo text={t(translationCode + 'Title')} variant="h3" />
            <div style={{ marginTop: '-15px' }}>
              <Typo
                text={t(translationCode + 'Desc')}
                variant="body1"
                sx={{ maxWidth: '750px' }}
              />
            </div>
          </Box>
          <div
            style={{
              backgroundColor: '#f7f7f7',
              borderRadius: '15px',
              padding: '30px',
            }}
          >
            <div className="row">
              <div
                className="col-3"
                onClick={() => setCurrentSection('Content')}
              >
                <div
                  style={{
                    height: isMobile ? '100px' : '140px',
                    padding: '10px',
                    backgroundColor:
                      currentSection === 'Content'
                        ? mainColor + '10'
                        : '#FFFFFF',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    border: '2px solid',
                    borderColor:
                      currentSection === 'Content' ? mainColor : '#FFFFFF',
                  }}
                >
                  <Content mainColor={mainColor} isMobile={isMobile} />
                </div>
              </div>
              <div className="col-9" onClick={() => setCurrentSection('List')}>
                <div
                  style={{
                    height: isMobile ? '100px' : '140px',
                    padding: '10px',
                    backgroundColor:
                      currentSection === 'List' ? mainColor + '10' : '#FFFFFF',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    border: '2px solid',
                    borderColor:
                      currentSection === 'List' ? mainColor : '#FFFFFF',
                  }}
                >
                  <List mainColor={mainColor} isMobile={isMobile} />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-4" onClick={() => setCurrentSection('Logs')}>
                <div
                  style={{
                    height: isMobile ? '100px' : '140px',
                    padding: '10px',
                    backgroundColor:
                      currentSection === 'Logs' ? mainColor + '10' : '#FFFFFF',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    border: '2px solid',
                    borderColor:
                      currentSection === 'Logs' ? mainColor : '#FFFFFF',
                  }}
                >
                  <Logs mainColor={mainColor} isMobile={isMobile} />
                </div>
              </div>
              <div
                className="col-4"
                onClick={() => setCurrentSection('Component')}
              >
                <div
                  style={{
                    height: isMobile ? '100px' : '140px',
                    padding: '10px',
                    backgroundColor:
                      currentSection === 'Component'
                        ? mainColor + '10'
                        : '#FFFFFF',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    border: '2px solid',
                    borderColor:
                      currentSection === 'Component' ? mainColor : '#FFFFFF',
                  }}
                >
                  <Component mainColor={mainColor} isMobile={isMobile} />
                </div>
              </div>
              <div className="col-4" onClick={() => setCurrentSection('Sync')}>
                <div
                  style={{
                    height: isMobile ? '100px' : '140px',
                    padding: '14px',
                    backgroundColor:
                      currentSection === 'Sync' ? mainColor + '10' : '#FFFFFF',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    border: '2px solid',
                    borderColor:
                      currentSection === 'Sync' ? mainColor : '#FFFFFF',
                  }}
                >
                  <Sync mainColor={mainColor} isMobile={isMobile} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12 align-c mt-4">
          <div
            style={{
              backgroundColor: darkMainColor,
              padding: '35px',
              height: '100%',
              borderRadius: '15px',
            }}
          >
            <div style={{ backgroundColor: '#FFFFFF', borderRadius: '15px' }}>
              <img
                src="/assets/website/v3/logos-softcon.png"
                height={30}
                alt="Softwares Logos"
              />
            </div>
            <div className="align-left" style={{ marginTop: '60px' }}>
              <Typo
                text={t(translationCode + currentSection)}
                variant="h3"
                color="white"
              />
              <Typo
                text={t(translationCode + currentSection + 'Desc')}
                variant="body2"
                color="white"
                className="mt-5"
              />
              <Typo
                text={t(translationCode + currentSection + 'Desc2')}
                variant="body2"
                color="white"
                className="mt-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Display;
