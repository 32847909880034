import React from 'react';

const ActiveSearchPerfo = ({ mainColor = '#3498db' }) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0148 38.4074C19.2785 40.7835 21.1804 42.6846 23.5553 42.958C25.9675 43.2357 28.456 43.5 31 43.5C33.544 43.5 36.0325 43.2357 38.4447 42.958C40.8196 42.6846 42.7215 40.7835 42.9852 38.4074C43.2516 36.0072 43.5 33.5311 43.5 31C43.5 28.4689 43.2516 25.9928 42.9852 23.5926C42.7215 21.2165 40.8196 19.3154 38.4447 19.042C36.0325 18.7643 33.544 18.5 31 18.5C28.456 18.5 25.9675 18.7643 23.5553 19.042C21.1804 19.3154 19.2785 21.2165 19.0148 23.5926C18.7484 25.9928 18.5 28.4689 18.5 31C18.5 33.5311 18.7484 36.0072 19.0148 38.4074Z"
        fill="black"
      />
      <path
        d="M28.5706 43.4268C26.868 43.3291 25.1941 43.1467 23.5554 42.958C21.1804 42.6846 19.2785 40.7835 19.0148 38.4074C18.7484 36.0072 18.5 33.5311 18.5 31C18.5 28.4689 18.7484 25.9928 19.0148 23.5926C19.2785 21.2165 21.1804 19.3154 23.5553 19.042C25.9675 18.7643 28.456 18.5 31 18.5C33.544 18.5 36.0325 18.7643 38.4447 19.042C40.8196 19.3154 42.7215 21.2165 42.9852 23.5926C43.1714 25.2697 43.3487 26.9839 43.4387 28.728"
        stroke="white"
        stroke-width="2.475"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.1368 25L18.8633 25C18.8818 24.8206 18.9007 24.6416 18.9198 24.4629C19.228 21.5816 21.5454 19.2642 24.4253 18.9432C26.5644 18.7048 28.7608 18.5 31 18.5C33.2393 18.5 35.4356 18.7048 37.5747 18.9432C40.4546 19.2642 42.772 21.5816 43.0803 24.4629C43.0994 24.6416 43.1183 24.8206 43.1368 25Z"
        fill={mainColor}
        stroke="white"
        stroke-width="2.475"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37 42.5C40.0376 42.5 42.5 40.0376 42.5 37C42.5 33.9624 40.0376 31.5 37 31.5C33.9624 31.5 31.5 33.9624 31.5 37C31.5 40.0376 33.9624 42.5 37 42.5Z"
        fill={mainColor}
        stroke="white"
        stroke-width="2.475"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.5001 43.5002L40.8857 40.8857"
        stroke="white"
        stroke-width="2.475"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ActiveSearchPerfo;
