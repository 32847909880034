import React, { } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import animationData from '../../../lotties/growthagency.json';
import Lottie from 'react-lottie';
import chroma from 'chroma-js';
import {
  Button,
} from '@mui/material';
import Typo from '../Typo';
import { CheckBox } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const StrategiesGrowth = ({ translationCode }) => {
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const secColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const lightMainColor = chroma(mainColor + '30')
    .brighten(2.2)
    .hex();
  const lightVarColor = chroma(secColor).brighten(2.2).hex();
  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  const navigateToDemo = () => {
    navigate('/demo');
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      progressiveLoad: false,
    },
  };

  return (
    <div
      style={{
        marginTop: '150px',
        paddingTop: '60px',
        paddingBottom: '60px',
        backgroundColor: lightMainColor,
      }}
    >
      <div className="container">
        <div className={isMobile ? 'row px-4' : 'row'}>
          <div className="col-md-6 col-12">
            {' '}
            <Typo
              text={t(translationCode + 'Title')}
              variant="h2"
              className="mt-5"
              sx={{ maxWidth: '500px' }}
            />
            <div className="row mt-5">
              <div className="col-md-1 col-2">
                <CheckBox htmlColor={mainColor} />
              </div>
              <div style={{ marginLeft: '-15px' }} className="col-md-11 col-10">
                <Typo
                  text={t(translationCode + 'P1Title')}
                  variant="subTitle"
                  bold
                />
                <Typo
                  text={t(translationCode + 'P1Desc')}
                  variant="body2"
                  className="mt-2"
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-1 col-2">
                <CheckBox htmlColor={mainColor} />
              </div>
              <div style={{ marginLeft: '-15px' }} className="col-md-11 col-10">
                <Typo
                  text={t(translationCode + 'P2Title')}
                  variant="subTitle"
                  bold
                />
                <Typo
                  text={t(translationCode + 'P2Desc')}
                  variant="body2"
                  className="mt-2"
                />
              </div>
            </div>
            <div className="row mt-5 mb-5">
              <div className="col-md-1 col-2">
                <CheckBox htmlColor={mainColor} />
              </div>
              <div style={{ marginLeft: '-15px' }} className="col-md-11 col-10">
                <Typo
                  text={t(translationCode + 'P3Title')}
                  variant="subTitle"
                  bold
                />
                <Typo
                  text={t(translationCode + 'P3Desc')}
                  variant="body2"
                  className="mt-2"
                />
              </div>
            </div>
            <Button
              variant="contained"
              disableElevation
              onClick={navigateToDemo}
              sx={{
                backgroundColor: darkMainColor,
                borderRadius: '21px',
                textTransform: 'none',
                fontWeight: 'bold',
                mt: 4,
              }}
            >
              {t(translationCode + 'CTA')}
            </Button>
          </div>
          <div className="col-md-6 col-12 align-c mt-4">
            <Lottie
              options={defaultOptions}
              height={isMobile ? 450 : 500}
              width={isMobile ? 390 : 420}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrategiesGrowth;
