import React, { } from 'react';
import { useTranslation } from 'react-i18next';

import Typo from '../Typo';
import { useTheme } from '@mui/material/styles';
import colorGenerator from '../../../utils/colorGenerator';
import { Box } from '@mui/material';
import Automate from '../dynamicSVG/Automate';
import Connect from '../dynamicSVG/Connect';
import Analyze from '../dynamicSVG/Analyze';
import { useMediaQuery } from '@mui/material';

const MethoMapping = ({ translationCode }) => {

  const { t, } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';
  const localStorageColor = localStorage.getItem('mainColor');

  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';

  const darkColor = colorGenerator(mainColor, 2, 1, 1, false);


  return (
    <div
      style={{
        marginTop: '150px',
      }}
      className="container"
    >
      <div className={isMobile ? 'mt-4 px-4' : 'mt-4'}>
        <Typo
          text={t(translationCode + 'Title')}
          variant="h3"
          bold
          className="mt-4 align-c"
        />
        <Typo
          text={t(translationCode + 'Title2')}
          variant="h2"
          bold
          className="mt-1 align-c"
        />
      </div>
      <div className="row px-5 mt-5">
        <div className="col-md-4 col-12 d-flex justify-content-center align-items-center mt-4">
          <Box
            width={isMobile ? '350px' : '210px'}
            minWidth="210px"
            borderRadius="15px"
            border="1px solid #e0e0e0"
            padding="20px"
            textAlign="center"
            sx={{
              backgroundColor: isDarkMode ? '#000' : '#fff',
              transition: 'background-color 0.3s ease',
            }}
          >
            <div style={{ height: '100px' }}>
              <Analyze mainColor={mainColor} darkMainColor={darkColor} />
            </div>
            <Typo
              text={t(translationCode + 'M1')}
              variant="body1"
              bold
              className="mt-4"
            />
            <Typo
              text={t(translationCode + 'M1Desc')}
              className="mt-2"
              variant="p"
              sx={{
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                whiteSpace: 'normal',
                display: 'block',
                maxWidth: '100%',
              }}
            />
          </Box>
        </div>
        <div className="col-md-4 col-12 d-flex justify-content-center align-items-center mt-4">
          <Box
            width={isMobile ? '350px' : '210px'}
            minWidth="210px"
            borderRadius="15px"
            border="1px solid #e0e0e0"
            padding="20px"
            textAlign="center"
            sx={{
              backgroundColor: isDarkMode ? '#000' : '#fff',
              transition: 'background-color 0.3s ease',
            }}
          >
            <div style={{ height: '100px' }}>
              <Connect mainColor={mainColor} darkMainColor={darkColor} />
            </div>
            <Typo
              text={t(translationCode + 'M2')}
              variant="body1"
              bold
              className="mt-4"
            />
            <Typo
              text={t(translationCode + 'M2Desc')}
              className="mt-2"
              variant="p"
              sx={{
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                whiteSpace: 'normal',
                display: 'block',
                maxWidth: '100%',
              }}
            />
          </Box>
        </div>
        <div className="col-md-4 col-12 d-flex justify-content-center align-items-center mt-4">
          <Box
            width={isMobile ? '350px' : '210px'}
            minWidth="210px"
            borderRadius="15px"
            border="1px solid #e0e0e0"
            padding="20px"
            textAlign="center"
            sx={{
              backgroundColor: isDarkMode ? '#000' : '#fff',
              transition: 'background-color 0.3s ease',
            }}
          >
            <div style={{ height: '100px' }}>
              <Automate mainColor={mainColor} darkMainColor={darkColor} />
            </div>
            <Typo
              text={t(translationCode + 'M3')}
              variant="body1"
              bold
              className="mt-4"
            />
            <Typo
              text={t(translationCode + 'M3Desc')}
              className="mt-2"
              variant="p"
              sx={{
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                whiteSpace: 'normal',
                display: 'block',
                maxWidth: '100%',
              }}
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default MethoMapping;
