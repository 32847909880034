import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  collection,
  query,
  onSnapshot,
  addDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  updateDoc,
  orderBy,
} from 'firebase/firestore';
import { db } from '../../firebase';
import MainLayoutV2 from '../../layouts/MainLayoutV2.jsx';
import { Typography, Button, TextField, Select, MenuItem, FormControl, InputLabel, Dialog, DialogTitle, DialogContent, DialogActions, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import { styled, keyframes, fontSize, height, } from '@mui/system';
import ImageIcon from '@mui/icons-material/Image';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import DeleteIcon from '@mui/icons-material/Delete';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import FavoriteIcon from '@mui/icons-material/Favorite';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'; // Import AdapterMoment
import moment from 'moment'; // Import moment
import { useSearchParams } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Blocks from '../../stories/layout-components/Block.jsx';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase.js';

// Styles and animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledElement = styled('div')`
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 10px;
  animation: ${fadeIn} 0.5s ease-in-out;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
  cursor: grab;
  &:hover .actions {
    opacity: 1;
  }
`;

const StyledImage = styled('img')`
  width: 240px;
  height: 200px;
  object-fit: cover;  
  border-radius: 8px;
  display: block;
  margin-bottom: 10px;
`;

const StyledText = styled(Typography)`
  font-size: 1.8rem;
  font-weight: bold;
  word-break: break-word;
`;

const StyledTitle = styled(Typography)`
  font-size: 1.1rem;
  font-weight: bold;
  word-break: break-word;
`;

const ActionButton = styled(Button)`
  margin: 0 5px;
`;



const TimelineContainer = styled('div')`
  display: flex;
  height: 100%;
  position: relative; /* For absolute positioning of the timeline bar */
`;

const TimelineContent = styled('div')`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
`;

const TimelineBar = styled('div')`
  position: absolute;
  left: 50px; /* Adjust as needed to align with content */
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #00000099;
  z-index: 1; /* Ensure it's behind the content */
`;

const TimelineDot = styled('div')`
  position: absolute;
  left: -54px; /* Adjust to center the dot on the bar */
  top: calc(50% - 0px); /* Vertically center the dot */
  width: 12px;
  height: 12px;
  background-color:rgb(6, 6, 6); /* Or your desired color */
  border-radius: 50%;
  z-index: 2; /* Above the bar */
`;

const TimelineItemContainer = styled('div')`
  position: relative;
  margin-bottom: 30px; /* Space between timeline items */
  margin-left: 80px; /* Space for the timeline bar */
  background-color: #ffffff;  
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.5s ease-in-out;
  transition: transform 0.2s ease-in-out;
`;

const TimelineDate = styled(Typography)`
  position: absolute;
  left: -88px; /* Adjust to position the date */
  top: calc(50% - 15px); /* Vertically center the date */
  width: 80px; /* Adjust the width of the date container*/
  text-align: center;
  font-weight: bold;
  font-size: 0.7rem;
  z-index: 3;
  color: #000;
  background-color: #ffffff;
  border-radius: 4px; 
`;

const ResourceNode = () => {
  const { t } = useTranslation();
  const [elements, setElements] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [elementType, setElementType] = useState('text');
  const [textValue, setTextValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState(''); // New state for description
  const [externalLinkValue, setExternalLinkValue] = useState(''); // New state for external link
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [hoveredElement, setHoveredElement] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [viewMode, setViewMode] = useState('random');
  const [term, setTerm] = useState('');
  const [debouncedTerm, setDebouncedTerm] = useState('');

  const [searchParams] = useSearchParams();
  const activeIndex = parseInt(searchParams.get('tab')) || 0;

  const currentUser = useSelector((state) => state.core.user);
  const businessPreference = useSelector((state) => state.core.businessData);


  const formatDate = (input) => {
    if (!input) return '';

    let dateObj;

    // Si c’est un Timestamp Firestore
    if (typeof input.toDate === 'function') {
      dateObj = input.toDate();
    }
    // Si c’est un timestamp en millisecondes (comme depuis Algolia)
    else if (typeof input === 'number') {
      dateObj = new Date(input);
    }
    // Si c’est un objet avec une propriété `value` (ex. { value: 1743422591963 })
    else if (typeof input === 'object' && input.value) {
      dateObj = new Date(input.value);
    }
    else {
      return '';
    }

    return moment(dateObj).format('DD MMM YYYY');
  };

  // Function to handle date change
  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };


  // Function to add an element
  const handleAdd = async () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setTextValue('');
    setDescriptionValue(''); // Reset the description
    setExternalLinkValue(''); // Reset the external link
    setImageFile(null);
    setImageUrl('');
    setSelectedDate(moment()); // Reset the date when closing the dialog
  };

  const handleConfirmAdd = async () => {
    if (!currentUser?.uid) return;

    try {
      let data = {
        type: elementType,
        createdAt: serverTimestamp(),
        createdBy: currentUser.uid,
        likes: 0,
        laughs: 0,
        date: selectedDate.toDate(),
        description: descriptionValue, // Include the description
      };

      if (elementType === 'text') {
        if (textValue.trim() === '') {
          alert('Veuillez entrer du texte.');
          return;
        }
        data.text = textValue.trim();
      } else if (elementType === 'photo') {
        if (!imageFile) {
          alert('Veuillez sélectionner une image.');
          return;
        }
        const storageRef = ref(storage, `users/${currentUser.uid}/${imageFile.name}`);
        const uploadResult = await uploadBytes(storageRef, imageFile);
        const downloadURL = await getDownloadURL(uploadResult.ref);
        data.imageUrl = downloadURL;
      } else if (elementType === 'story') {
        if (textValue.trim() === '') {
          alert('Veuillez entrer du texte pour l\'histoire.');
          return;
        }
        data.text = textValue.trim();
        data.externalLink = externalLinkValue; // Store external link
        if (imageFile) { // Handle story image upload
          const storageRef = ref(storage, `users/${currentUser.uid}/${imageFile.name}`);
          const uploadResult = await uploadBytes(storageRef, imageFile);
          const downloadURL = await getDownloadURL(uploadResult.ref);
          data.imageUrl = downloadURL;
        }

      }



      await addDoc(collection(db, 'resources'), data);
      handleCloseAddDialog();
    } catch (error) {
      console.error('Erreur lors de l\'ajout de l\'élément:', error);
      alert('Erreur lors de l\'ajout. Veuillez réessayer.');
    }
  };

  // Function to delete an element
  const handleDelete = async (elementId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet élément ?')) {
      try {
        await deleteDoc(doc(db, 'resources', elementId));
      } catch (error) {
        console.error('Erreur lors de la suppression de l\'élément:', error);
        alert('Erreur lors de la suppression.');
      }
    }
  };

  useEffect(() => {
    setDebouncedTerm('')
    setTerm('')
  }, [activeIndex]);


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };


  const handleGetData = useCallback(async () => {
    if (!debouncedTerm || debouncedTerm.trim() === '') {
      return;
    }
    try {
      const response = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `private/content`,
        body: {
          query: debouncedTerm,
        },
      });

      setElements(response || []);
    } catch (error) {
      console.error(error);
    }
  }, [debouncedTerm, t]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedTerm(term);
    }, 300);


    return () => {
      clearTimeout(timeoutId);
    };
  }, [term]);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  // Fetch elements from Firestore
  useEffect(() => {
    if (currentUser?.uid && debouncedTerm.trim() === '') {
      let q;

      q = query(collection(db, 'resources'), orderBy('date', 'desc'));


      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const newElements = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          newElements.push({
            id: doc.id,
            ...data,
            likes: data.likes || 0,
            laughs: data.laughs || 0,
          });
        });
        setElements(newElements);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [currentUser?.uid, viewMode, debouncedTerm]);







  // Handle likes
  const handleLike = async (elementId) => {
    try {
      await updateDoc(doc(db, 'resources', elementId), {
        likes: (elements.find(el => el.id === elementId)?.likes || 0) + 1,
      });
    } catch (error) {
      console.error("Erreur lors de la mise à jour des likes:", error);
      alert("Erreur lors de la mise à jour des likes.");
    }
  };

  // Handle laughs
  const handleLaugh = async (elementId) => {
    try {
      await updateDoc(doc(db, 'resources', elementId), {
        laughs: (elements.find(el => el.id === elementId)?.laughs || 0) + 1,
      });
    } catch (error) {
      console.error("Erreur lors de la mise à jour des rires:", error);
      alert("Erreur lors de la mise à jour des rires.");
    }
  };

  const handleOpenLink = (link) => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  useEffect(() => {
    if (activeIndex === 0) {
      setViewMode('random');
    } else if (activeIndex === 1) {
      setViewMode('timeline');
    } else if (activeIndex === 2) {
      setViewMode('search');
    }
  }, [activeIndex]);


  return (
    <MainLayoutV2 manualIndex={activeIndex} pageTitle={t('nodeGrigos')} actions={{ add: handleAdd }} icon='RocketLaunchOutlined' subTitle='Content generation - Story board' tabs={[{ value: 0, label: 'Board' }, { value: 1, label: 'Timeline' }]}>
      <Blocks heightPercentage={98}>

        <div className="p-3">
          <TextField
            fullWidth
            margin="normal"
            label={t('search')}
            variant="outlined"
            value={term}
            onChange={(e) => setTerm(e.target.value)}
          />
        </div>
        {viewMode === 'timeline' ? (
          <TimelineContainer>
            <TimelineContent>
              <TimelineBar />
              {elements.map((element) => (
                <TimelineItemContainer key={element.id} id={`timeline-item-${element.id}`}>
                  <TimelineDot />
                  <TimelineDate variant="caption">{formatDate(element.date)}</TimelineDate>

                  {element.type === 'text' && (
                    <Typography variant="body1" fontSize={18} fontWeight={600}>{element.text}</Typography>
                  )}
                  {element.type === 'photo' && (
                    <StyledImage src={element.imageUrl} alt="Photo" />
                  )}
                  {element.type === 'story' && (
                    <>
                      <Typography variant="body1">{element.text}</Typography>
                      {element.imageUrl && <StyledImage src={element.imageUrl} alt="Story Image" />}

                    </>
                  )}
                  {element.description && (
                    <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
                      {element.description}
                    </Typography>
                  )}
                  <div className="actions" style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                    <Button size="small" onClick={() => handleLike(element.id)}>
                      ❤️ {element.likes || 0}
                    </Button>
                    <Button size="small" onClick={() => handleLaugh(element.id)}>
                      😂 {element.laughs || 0}
                    </Button>
                    {element.type === 'story' && element.externalLink && (
                      <ActionButton
                        size="small"
                        onClick={() => handleOpenLink(element.externalLink)}
                      >
                        <OpenInNewIcon htmlColor='black' sx={{ fontSize: 13 }} />
                      </ActionButton>
                    )}
                    <ActionButton
                      size="small"
                      color="secondary"
                      onClick={() => handleDelete(element.id)}
                    >
                      <DeleteIcon htmlColor='red' sx={{ fontSize: 13 }} />
                    </ActionButton>
                  </div>
                </TimelineItemContainer>
              ))}
            </TimelineContent>
          </TimelineContainer>
        ) : viewMode === 'random' ? (
          <div className="row" style={{ paddingLeft: '20px' }}>
            {elements.map((element) => (
              <div className="col-3 p-2" key={element.id} id={`random-item-${element.id}`}>
                <StyledElement
                  key={element.id}
                  onMouseEnter={() => setHoveredElement(element.id)}
                  onMouseLeave={() => setHoveredElement(null)}
                  style={{
                    justifyContent: element.type === 'text' ? 'center' : 'flex-start',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  {element.type === 'text' && (
                    <>
                      <StyledText variant="body1">{element.text}</StyledText>
                    </>
                  )}
                  {element.type === 'photo' && (
                    <StyledImage src={element.imageUrl} alt="Photo" />
                  )}
                  {element.type === 'story' && (
                    <>

                      {element.imageUrl && <StyledImage src={element.imageUrl} alt="Story Image" />}
                      <StyledTitle variant="body1">{element.text}</StyledTitle>

                    </>
                  )}
                  <Typography variant="caption" color="textSecondary" style={{ marginTop: '4px', marginBottom: '4px' }}>
                    {formatDate(element.date)}
                  </Typography>

                  {hoveredElement === element.id && (
                    <div className="actions" style={{ marginTop: '2px', display: 'flex', alignItems: 'center', opacity: hoveredElement === element.id ? 1 : 0, transition: 'opacity 0.2s' }}>
                      <Button size="small" onClick={() => handleLike(element.id)}>
                        <FavoriteIcon fontSize="small" style={{ marginRight: '4px' }} /> {element.likes || 0}
                      </Button>
                      <Button size="small" onClick={() => handleLaugh(element.id)}>
                        <SentimentSatisfiedAltIcon fontSize="small" style={{ marginRight: '4px' }} /> {element.laughs || 0}
                      </Button>
                      {element.type === 'story' && element.externalLink && (
                        <ActionButton
                          size="small"
                          onClick={() => handleOpenLink(element.externalLink)}
                        >
                          <OpenInNewIcon />
                        </ActionButton>
                      )}
                      <ActionButton
                        size="small"
                        color="secondary"
                        onClick={() => handleDelete(element.id)}
                      >
                        <DeleteIcon />
                      </ActionButton>
                    </div>)}
                </StyledElement>
              </div>
            ))}
          </div>
        ) : (null)}
      </Blocks>

      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>{t('addResource')}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>{t('elementType')}</InputLabel>
            <Select
              value={elementType}
              onChange={(e) => setElementType(e.target.value)}
            >
              <MenuItem value="text">
                <TextFieldsIcon style={{ marginRight: '8px' }} /> {t('text')}
              </MenuItem>
              <MenuItem value="photo">
                <ImageIcon style={{ marginRight: '8px' }} /> {t('photo')}
              </MenuItem>
              <MenuItem value="story">
                {t('story')}
              </MenuItem>
            </Select>
          </FormControl>
          {elementType === 'text' && (
            <TextField
              fullWidth
              margin="normal"
              label={t('title')}
              variant="outlined"
              value={textValue}
              onChange={(e) => setTextValue(e.target.value)}
            />
          )}
          {elementType === 'story' && (
            <>
              <TextField
                fullWidth
                margin="normal"
                label={t('title')}
                variant="outlined"
                value={textValue}
                onChange={(e) => setTextValue(e.target.value)}
              />
              <TextField
                fullWidth
                margin="normal"
                label={t('description')}
                variant="outlined"
                value={descriptionValue}
                onChange={(e) => setDescriptionValue(e.target.value)}
              />
              <TextField
                fullWidth
                margin="normal"
                label={t('externalLink')}
                variant="outlined"
                value={externalLinkValue}
                onChange={(e) => setExternalLinkValue(e.target.value)}
              />
              <div className="mt-3 mb-3">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
                {imageUrl && (
                  <img src={imageUrl} alt="Aperçu" style={{ maxWidth: '100px', marginTop: '10px' }} />
                )}</div>
            </>
          )}

          {elementType === 'photo' && (
            <>
              <TextField
                fullWidth
                margin="normal"
                label={t('title')}
                variant="outlined"
                value={textValue}
                onChange={(e) => setTextValue(e.target.value)}
              />
              <div className="mt-3 mb-3">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
                {imageUrl && (
                  <img src={imageUrl} alt="Aperçu" style={{ maxWidth: '100px', marginTop: '10px' }} />
                )}
              </div>
            </>
          )}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label={t('dateAndTime')}
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
              sx={{ marginTop: 2, marginBottom: 2 }}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleConfirmAdd} color="primary" variant="contained">
            {t('add')}
          </Button>
        </DialogActions>
      </Dialog>
    </MainLayoutV2>
  );
};

export default ResourceNode;