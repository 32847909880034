import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Typo from '../Typo';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Bos from '../dynamicSVG/Bos';
import Growth from '../dynamicSVG/Growth';
import Desk from '../dynamicSVG/Desk';
import Pulse from '../dynamicSVG/Pulse';
import Roam from '../dynamicSVG/Roam';

const Products = ({ translationCode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = useTheme();

  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const secColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const cardsData = [
    {
      img: <Bos mainColor={mainColor} secColor={secColor} />,
      title: 'Bos',
      path: '/products/bos/overview',
      description:
        'Flexible et personnalisable, elle centralise vos opérations.',
    },
    {
      img: <Growth mainColor={mainColor} secColor={secColor} />,
      title: 'Growth',
      path: '/products/growth/strategies',
      description:
        'Accompagnement stratégique: outils et solutions personnalisées.',
    },
    {
      img: <Desk mainColor={mainColor} secColor={secColor} />,
      title: 'Desk',
      description: 'Outils pratiques pour entrepreneurs de demain.',
    },
    {
      img: <Pulse mainColor={mainColor} secColor={secColor} />,
      title: 'Pulse',
      path: '/products/pulse/dashboards',
      description:
        'Visualisez, analysez vos données et créez des tableaux interactifs.',
    },
    {
      img: <Roam mainColor={mainColor} secColor={secColor} />,
      title: 'Roam',
      path: '/products/roam/directory',
      description: 'Prêt pour les entrepreneurs endurants et nomades.',
    },
  ];

  return (
    <Box
      className="container"
      sx={{
        position: 'relative',
        width: '100%',
        marginTop: '10vh',
        maxWidth: 1474,
        padding: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          marginTop: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '5px 30px 5px 30px',
            borderRadius: 20,
            border: '1px solid #2222221a',
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: 'medium', color: 'black' }}
          >
            {t(translationCode + 'Top')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '700px',
            marginBottom: '30px',
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: '38px',
              textAlign: 'center',
              background:
                'linear-gradient(180deg, rgb(0,0,0) 0%, rgb(0,18.58,84.48) 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textFillColor: 'transparent',
              fontWeight: 'bold',
            }}
          >
            {t(translationCode + 'Agile')}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: '16px',
              textAlign: 'center',
              marginTop: '20px',
            }}
          >
            {t(translationCode + 'AgileDesc')}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          maxWidth: 1117,
          margin: '0 auto',
        }}
      >
        <Grid container spacing={2} sx={{ position: 'relative', marginTop: 4 }}>
          {cardsData?.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                onClick={() => navigate(card.path)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: 3,
                  borderRadius: 2,
                  boxShadow: 'none',
                  backgroundColor: 'transparent',
                  border: '1px solid #1b1b1b14',
                }}
              >
                <CardContent sx={{ textAlign: 'center' }}>
                  <div
                    style={{
                      width: '160px',
                      height: '160px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: '0 auto',
                    }}
                    className="mb-5"
                  >
                    {card?.img}
                  </div>
                  <Typo
                    text={
                      t(translationCode + card.title) +
                      (card.title === 'Desk' ? ' - ' + t('soon') : '')
                    }
                    variant="h4"
                    className="mb-3 mt-1"
                  />
                  <Typo
                    text={t(translationCode + card.title + 'Desc')}
                    variant="body2"
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Products;
