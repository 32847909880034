import React from 'react';
import SiteLayout from '../SiteLayout';
import Header from '../components/home/Header';
import Products from '../components/home/Products';
import Tem from '../components/home/Tem';
import { useTranslation } from 'react-i18next';
import BlueLink from '../components/dynamicSVG/BlueLink';
import Typo from '../components/Typo';

const HomeNode = () => {
  const { t } = useTranslation();
  const pageCode = 'homeNode';
  const currentUrl = window.location.href;

  return (
    <div>
      {currentUrl?.includes('bluelink') ? (
        <div className="row">
          <div className="align-c mt-5">
            <BlueLink mainColor={'#5CBFD6'} secColor={'#5CBFD6'} />
          </div>
          <Typo
            text={t(pageCode + 'BlueLinkTitle')}
            variant="h2"
            className="mt-4 align-c"
          />
        </div>
      ) : (
        <SiteLayout
          helmetData={{
            name: t(pageCode + 'SEO'),
            description: t(pageCode + 'SEODesc'),
          }}
        >
          <Header translationCode={pageCode + 'Header'} />
          <div className="mb-5">
            <Products translationCode={pageCode + 'Products'} />
          </div>
          <div className="mb-5">
            <Tem translationCode={pageCode + 'Tem'} />
          </div>
        </SiteLayout>
      )}
    </div>
  );
};

export default HomeNode;
