import React, { } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';
import { Box } from '@mui/material';
import Typo from '../Typo';
import { useMediaQuery } from '@mui/material';

const CreateElement = ({ translationCode }) => {
  const { t, } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const localStorageColor = localStorage.getItem('mainColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';

  const lightMainColor = chroma(mainColor + '30')
    .brighten(2.2)
    .hex();

  return (
    <div className="container">
      <div
        style={{
          marginTop: '200px',
          backgroundColor: lightMainColor,
          borderRadius: '20px',
          padding: '25px',
        }}
      >
        <div className="align-c">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={4}
            sx={{ mt: 2, mb: 3 }}
          >
            <Typo text={t(translationCode + 'Title')} variant="h2" />
            <Typo
              text={t(translationCode + 'Desc')}
              variant="subTitle"
              sx={{ maxWidth: '700px' }}
            />
          </Box>
        </div>
        <div className="row">
          <div className="col-md-7 col-12 px-5">
            <div
              style={{
                // backgroundColor: '#FFFFFF',
                padding: '25px',
                borderRadius: '15px',
              }}
              className="align-c"
            >
              <img
                height={isMobile ? '155px' : '350px'}
                src="/assets/website/v3/resize.gif"
                alt="Node Platform"
                style={{
                  borderRadius: '10px',
                  marginLeft: isMobile ? '-20px' : '',
                }}
              />
            </div>
          </div>

          <div className="col-md-5 col-12 mt-5">
            <div>
              <Typo
                text={t(translationCode + 'ContentTitle1')}
                variant="smallTitle"
              />
              <Typo
                text={t(translationCode + 'ContentDesc1')}
                variant="body2"
                className="mt-2"
              />
            </div>
            <div className="mt-5 mb-5">
              <Typo
                text={t(translationCode + 'ContentTitle2')}
                variant="smallTitle"
              />
              <Typo
                text={t(translationCode + 'ContentDesc2')}
                variant="body1"
                className="mt-2"
              />
            </div>
            <div className="mt-5 mb-5">
              <Typo
                text={t(translationCode + 'ContentTitle3')}
                variant="smallTitle"
              />
              <Typo
                text={t(translationCode + 'ContentDesc3')}
                variant="body1"
                className="mt-2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateElement;
