import { Route, Routes } from 'react-router-dom';
import NotFound from './screens/UX/NotFound';
import LegalTerms from './screens/website/LegalTerms';
import PlanDemo from './screens/website/PlanDemo';
import PublicInvoice from './screens/public/PublicInvoice';
import PublicElement from './screens/public/PublicElement';
import PublicElementRedirect from './screens/public/PublicElementRedirect';
import PublicProject from './screens/public/PublicProject';
import Welcome from './screens/public/Welcome';
import BusinessPage from './screens/website/BusinessPage';
import PublicProfile from './screens/website/PublicProfile';
import SelectPlan from './websiteV3/onboard/SelectPlan'
import StructurePublic from './screens/public/StructurePublic';
import PriceSimulator from './websiteV3/components/PriceSimulator';
import Event from './screens/website/Event';
import PublicNPS from './screens/public/PublicNPS';
import Home from './websiteV3/screens/Home';
import Integrations from './websiteV3/screens/Integrations';
import Pricing from './websiteV3/screens/Pricing';
import Contact from './websiteV3/screens/Contact';
import Docs from './websiteV3/screens/Docs';
import HomeBos from './websiteV3/screens/HomeBos';
import PerformBos from './websiteV3/screens/PerformBos';
import ExternalBos from './websiteV3/screens/ExternalBos';
import EntrepriseBos from './websiteV3/screens/EntrepriseBos';
import CasesBos from './websiteV3/screens/CasesBos';
import HomeGrowth from './websiteV3/screens/HomeGrowth';
import MappingGrowth from './websiteV3/screens/MappingGrowth';
import HomePulse from './websiteV3/screens/HomePulse';
import ReportsPulse from './websiteV3/screens/ReportsPulse';
import HomeRoam from './websiteV3/screens/HomeRoam';
import NodeAI from './websiteV3/screens/NodeAI';
import Partners from './websiteV3/screens/Partners';
import Blog from './websiteV3/screens/Blog';
import Demo from './websiteV3/screens/Demo';
import Step1 from './websiteV3/onboard/Step1';
import Step2 from './websiteV3/onboard/Step2';
import Step3 from './websiteV3/onboard/Step3';
import Step4 from './websiteV3/onboard/Step4';
import Step5 from './websiteV3/onboard/Step5';
import Step6 from './websiteV3/onboard/Step6';
import Step7 from './websiteV3/onboard/Step7';
import Step8 from './websiteV3/onboard/Step8';

const PublicRoutes = () => {
  return (
    <>
      {' '}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/integrations" element={<Integrations />} />
        <Route path="/products/bos/overview" element={<HomeBos />} />
        <Route path="/products/bos/perform" element={<PerformBos />} />
        <Route path="/products/bos/modules" element={<ExternalBos />} />
        <Route path="/products/bos/business" element={<EntrepriseBos />} />
        <Route path="/products/bos/cases" element={<CasesBos />} />
        <Route path="/products/growth/strategies" element={<HomeGrowth />} />
        <Route path="/products/growth/mappingNum" element={<MappingGrowth />} />
        <Route path="/products/pulse/dashboards" element={<HomePulse />} />
        <Route path="/products/pulse/reports" element={<ReportsPulse />} />
        <Route path="/products/pulse/integrations" element={<Integrations />} />
        <Route path="/products/roam/directory" element={<HomeRoam />} />
        <Route path="/informations/mentions-legales" element={<LegalTerms />} />
        <Route path="/card/:domain/:elementId" element={<PublicInvoice />} />
        <Route
          path="/public/:structureId/:elementId"
          element={<PublicElement />}
        />
        <Route
          path="/doc/:businessId/:moduleName/:structureId/:elementId"
          element={<PublicElement />}
        />
        <Route
          path="/redirect/:businessId/:moduleName/:structureId/:elementId"
          element={<PublicElementRedirect />}
        />
        <Route
          path="/redirect/element/:elementId"
          element={<PublicProject />}
        />
        <Route path="/nps/:elementType/:elementId" element={<PublicNPS />} />
        <Route path="/welcome/:businessId" element={<Welcome />} />
        <Route
          path="/card-project/:domain/:elementId"
          element={<PublicProject />}
        />
        <Route path="/page/:businessId" element={<BusinessPage />} />
        <Route
          path="/profile/:userId/:businessId"
          element={<PublicProfile />}
        />
        <Route path="/onboard" element={<SelectPlan />} />
        <Route path="/onboard/step1" element={<Step1 />} />
        <Route path="/onboard/step2" element={<Step2 />} />
        <Route path="/onboard/step3" element={<Step3 />} />
        <Route path="/onboard/step4" element={<Step4 />} />
        <Route path="/onboard/step5" element={<Step5 />} />
        <Route path="/onboard/step6" element={<Step6 />} />
        <Route path="/onboard/step7" element={<Step7 />} />
        <Route path="/onboard/step8" element={<Step8 />} />
        <Route path="/docs" element={<Docs />} />
        <Route path="/node-ai" element={<NodeAI />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/event" element={<Event />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/pricing/simulator" element={<PriceSimulator />} />
        <Route path="/meet-node" element={<PlanDemo />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/structure-public" element={<StructurePublic />} />
      </Routes>
    </>
  );
};

export default PublicRoutes;
