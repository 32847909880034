import React from 'react';
import { Button, } from '@mui/material';
import BlueLink from '../dynamicSVG/BlueLink';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Typo from '../Typo';

const BlueLinkCTA = ({ translationCode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');

  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';

  const navigateBL = () => {
    window.location.href = 'https://node.bluelink.cloud/';
  };

  return (
    <div
      style={{ marginTop: '150px', maxHeight: '500px' }}
      className="container"
    >
      <div className="row mt-5">
        <div className="col-md-6 col-12 align-c">
          <div className="align-c mt-5">
            <BlueLink
              mainColor={localStorageColor}
              secColor={localStorageSecColor}
            />
          </div>
        </div>
        <div className="col-md-6 col-12 py-5">
          <Typo
            variant="h2"
            text={t(translationCode + 'Title')}
            className="mt-4"
            bold
          />

          <Typo
            variant="body2"
            text={t(translationCode + 'Sub')}
            className="mt-4"
          />
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={navigateBL}
            style={{
              marginTop: '40px',
              borderRadius: '50px',
              padding: '8px 20px',
              backgroundColor: mainColor,
              textTransform: 'none',
            }}
          >
            {t(translationCode + 'CTA')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BlueLinkCTA;
