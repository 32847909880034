import { Typography } from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';

const QrCodeGen = ({
  url, businessId
}) => {
  const qrCodeValue = url || `https://node.bluelink.cloud`;

  return (
    <div
      className="align-c"
    >
      <div style={{ position: 'relative' }}>
        <img
          src="/assets/website/2.0/square-node.png"
          width={30}
          height={30}
          alt="logo-node"
          style={{
            position: 'absolute',
            borderRadius: '50%',
            marginTop: 50,
            marginLeft: 50,
          }}
        />
        <QRCodeCanvas value={qrCodeValue} size={128} />{' '}
      </div>
    </div>
  );
};

export default QrCodeGen;
