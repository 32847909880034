import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import OnboardLayout from './OnboardLayout';
import { TextField, Typography } from '@mui/material';
import { fetchBusinessData, setOnboardData } from '../../redux/actions-v2/coreAction';


const Step1 = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;
    const pageCode = 'step1Onboard';


    const onboardData = useSelector((state) => state.core.onboardData);

    const handlePartnerCode = () => {
        dispatch(setOnboardData({ ...onboardData, partnerCode: onboardData?.partnerCode === undefined ? '' : undefined }))
    }

    const handleChangeColor = (color, key) => {
        dispatch(setOnboardData({ ...onboardData, [key]: color }))
        localStorage.setItem(key, color);
        dispatch(
            fetchBusinessData(null, t, currentLang, {
                mainColor: key === 'mainColor' ? color : (onboardData?.mainColor || ''),
                secColor: key === 'secColor' ? color : (onboardData?.secColor || ''),
                name: onboardData?.businessName || '',
                devMode: false,
            })
        );
    };



    return (

        <OnboardLayout stepTitle={t(pageCode + 'Title')} stepDesc={t(pageCode + 'Desc')} restrictNext={onboardData?.businessName === '' || onboardData?.mainColor === '' || onboardData?.secColor === ''}>
            <div className='mt-3 mb-3'>
                <Typography variant="caption" color="textSecondary" >
                    {t(pageCode + 'InputTitle')}
                </Typography>
                <TextField
                    id="outlined-basic"
                    placeholder={t('businessName')}
                    variant="outlined"
                    sx={{ marginTop: '6px' }}
                    value={onboardData?.businessName}
                    onChange={(e) => dispatch(setOnboardData({ ...onboardData, businessName: e.target.value }))}
                />
            </div>
            <div className='row mt-3 mb-3'>
                <div className='col-4'>
                    <Typography variant="caption" color="textSecondary" >
                        {t(pageCode + 'InputColor')}
                    </Typography>
                    <div style={{ position: 'relative', height: '40px', width: '75px', borderRadius: '6px', overflow: 'hidden', marginTop: '3px' }}>
                        <input
                            id="outlined-basic"
                            type='color'
                            fullWidth
                            style={{ border: 'none', height: '60px', width: '90px', position: 'absolute', borderRadius: '10px', top: -6, left: -5, right: 0, bottom: 0, backgroundColor: 'transparent' }}
                            placeholder={t('businessColor')}
                            variant="outlined"
                            value={onboardData?.mainColor}
                            onChange={(e) => handleChangeColor(e.target.value, 'mainColor')}
                        />
                    </div>
                </div>
                <div className='col-4'>
                    <Typography variant="caption" color="textSecondary" >
                        {t(pageCode + 'InputColorSec')}
                    </Typography>
                    <div style={{ position: 'relative', height: '40px', width: '75px', borderRadius: '6px', overflow: 'hidden', marginTop: '3px' }}>
                        <input
                            id="outlined-basic"
                            type='color'
                            fullWidth
                            style={{ border: 'none', height: '60px', width: '90px', position: 'absolute', borderRadius: '10px', top: -6, left: -5, right: 0, bottom: 0, backgroundColor: 'transparent' }}
                            placeholder={t('businessColor')}
                            variant="outlined"
                            value={onboardData?.secColor}
                            onChange={(e) => handleChangeColor(e.target.value, 'secColor')}
                        />
                    </div>
                </div>
                <div onClick={handlePartnerCode} className='mt-4'><Typography className='hover' color='#69696980' fontSize={10}>{t('partnerCodeText')}</Typography></div>
                {onboardData?.partnerCode !== undefined && (<div>
                    <TextField
                        id="outlined-basic"
                        placeholder={t('partnerCode')}
                        variant="outlined"
                        sx={{ marginTop: '6px' }}
                        value={onboardData?.partnerCode}
                        onChange={(e) => dispatch(setOnboardData({ ...onboardData, partnerCode: e.target.value }))} />
                </div>)}
            </div>
        </OnboardLayout>

    );
};

export default Step1;
