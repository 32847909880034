import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Grid,
  Button,
} from '@mui/material';
import chroma from 'chroma-js';
import { motion, AnimatePresence } from 'framer-motion';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  AccountCircleOutlined,
  AddOutlined,
  ArrowDropDown,
  CalendarMonthOutlined,
  FlipToBackOutlined,
  Inventory2Outlined,
  MoreVertOutlined,
  NotificationAddOutlined,
  RoomServiceOutlined,
  ViewKanbanOutlined,
  WebAssetOutlined,
} from '@mui/icons-material';
import ButtonCircle from '../../../stories/general-components/ButtonCircle';
import TicketIcon from '../../../components/@generalComponents/layout/Icons/TicketIcon';
import FormsIcon from '../../../components/@generalComponents/layout/Icons/FormsIcon';
import ArticleIcon from '../../../components/@generalComponents/layout/Icons/ArticleIcon';
import Typo from '../Typo';
import HistoryMessage from '../../../components/@generalComponents/HistoryMessage';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

const DropZone = ({
  onDrop,
  children,
  zoneId,
  customStyle = {},
  displayAdd,
}) => {
  const { t } = useTranslation();

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'COMPONENT',
    canDrop: (item) => {
      return item?.zones?.includes(zoneId);
    },
    drop: (item) => {
      if (item?.zones?.includes(zoneId)) {
        onDrop(item, zoneId);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const zoneTitles = {
    zone1: t('secondaryDetails'),
    zone2: t('primaryDetails'),
    zone3: t('contacts'),
    zone4: t('history'),
    zone5: t('invoices'),
  };

  const baseStyle = {
    border: '1px solid',
    borderColor: isOver ? (canDrop ? 'primary.main' : 'red') : '#69696940',
    borderRadius: 2,
    backgroundColor: isOver
      ? canDrop
        ? 'grey.100'
        : '#FFCCCC'
      : 'common.white',
    padding: 1,
    boxSizing: 'border-box',
    height: '100%',
  };

  const style = { ...baseStyle, ...customStyle };

  return (
    <Paper ref={drop} sx={style} variant="outlined" elevation={0}>
      <div
        style={{
          borderBottom: '1px solid',
          borderColor: '#69696945',
        }}
        className="d-flex justify-content-between"
      >
        <Typography
          sx={{ p: 0 }}
          variant="body1"
          fontSize={10.5}
          fontWeight={600}
          gutterBottom
        >
          {zoneTitles[zoneId] || zoneId}
        </Typography>
        <div className="d-flex">
          {displayAdd && <AddOutlined sx={{ fontSize: '12px' }} />}
          <MoreVertOutlined sx={{ fontSize: '12px' }} />
        </div>
      </div>
      {children}
    </Paper>
  );
};

const DraggableItem = ({
  type,
  label,
  componentSize,
  data,
  zones,
  droppedItems,
}) => {
  const isAlreadyDropped = droppedItems.includes(label);

  const [{ isDragging }, drag] = useDrag({
    type: type,
    item: { type, label, size: componentSize, data, zones },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !isAlreadyDropped,
  });

  const style = {
    opacity: isAlreadyDropped ? 0.3 : isDragging ? 0.5 : 1,
    padding: 0.6,
    border: '0.5px solid #69696940',
    cursor: isAlreadyDropped ? 'not-allowed' : 'move',
    borderRadius: 1.5,
  };

  return (
    <motion.div
      ref={!isAlreadyDropped ? drag : null}
      initial={{ y: 0 }}
      animate={{ y: [0, -3, 0] }} // Floating effect
      transition={{ duration: 2, repeat: Infinity, repeatType: 'mirror' }}
    >
      <Paper ref={!isAlreadyDropped ? drag : null} sx={style} elevation={0}>
        <Typography
          variant="body1"
          fontSize={Array.isArray(data) ? 10 : 11}
          fontWeight={500}
        >
          {label}
        </Typography>
        <div className="d-flex">
          {Array.isArray(data) ? (
            data.map((item, index) => (
              <Typography
                sx={{
                  minWidth: '40px',
                  width: '100%',
                  borderRight:
                    index === data?.length - 1 ? 'none' : '1px solid #69696950',
                  marginRight: '6px',
                }}
                variant="body1"
                fontSize={8}
                key={index}
              >
                {item}
              </Typography>
            ))
          ) : (
            <Typography variant="body1" fontSize={7}>
              {data}
            </Typography>
          )}
        </div>
      </Paper>
    </motion.div>
  );
};

const DragDropBos = ({ translationCode }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';
  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const secColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const lightGreyScale = chroma
    .scale([mainColor, '#f2f2f2'])
    .mode('lab')
    .colors(10)
    .map((color) => chroma(color).brighten(1.4));

  const lightGreyScaleSec = chroma
    .scale([secColor, '#f2f2f2'])
    .mode('lab')
    .colors(10)
    .map((color) => chroma(color).brighten(1.4));

  const darkColor = () => {
    let finalColor = mainColor || '#000000';

    return chroma(finalColor).darken(0.9).hex();
  };

  const messages = [
    { text: t('didAFollowUp'), name: 'Judith M.', userId: '-' },
    { text: t('noted'), name: 'Martin H.', otherUser: true },
  ];

  const [currentMessage, setCurrentMessage] = useState(0);

  const navigteOnboard = () => {
    navigate('/onboard');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage((prev) => (prev + 1) % messages.length);
    }, 4500);

    return () => clearInterval(interval);
  }, []);

  const resolveMenuIcon = (menuName) => {
    switch (menuName) {
      case 'OPERATIONS':
        return <TicketIcon />;
      case 'FINANCES':
        return <FormsIcon />;
      // case 'MARKETING':
      //   return <ArticleIcon />;
      default:
        return;
    }
  };

  const zoneCapacities = {
    zone1: 1,
    zone2: 2,
    zone3: 1,
    zone4: 2,
    zone5: 1.5,
  };

  // State for each drop zone.
  const [zones, setZones] = useState({
    zone1: [],
    zone2: [],
    zone3: [],
    zone4: [],
    zone5: [],
  });

  // Handle drop by checking capacity based on item sizes.
  const [droppedItems, setDroppedItems] = useState([]);

  const handleDrop = (item, zoneId) => {
    setZones((prevZones) => {
      const currentLoad = prevZones[zoneId].reduce(
        (total, currentItem) => total + currentItem.size,
        0
      );

      if (currentLoad + item.size <= zoneCapacities[zoneId]) {
        setDroppedItems((prev) => [...prev, item.label]);
        return {
          ...prevZones,
          [zoneId]: [...prevZones[zoneId], item],
        };
      }

      return prevZones;
    });
  };

  // Remove an item from a specific zone.
  const handleRemove = (zoneId, indexToRemove) => {
    setZones((prevZones) => {
      const updatedItems = prevZones[zoneId].filter(
        (item, index) => index !== indexToRemove
      );

      const removedItem = prevZones[zoneId][indexToRemove];
      setDroppedItems((prev) =>
        prev.filter((label) => label !== removedItem.label)
      );

      return {
        ...prevZones,
        [zoneId]: updatedItems,
      };
    });
  };

  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  const gridContainerStyle = {
    display: 'grid',
    gridTemplateAreas: `
      "zone2 zone1 zone1"
      "zone2 zone3 zone4"
      "zone5 zone5 zone4"
    `,
    gridTemplateColumns: '2fr 2fr 1.5fr',
    gridTemplateRows: '120px 150px 150px',
    gap: 1,
    width: '100%',
    height: '440px',
  };

  const zoneStyles = {
    zone1: {
      gridArea: 'zone1',
      backgroundColor: '#FFFFFF99',
    },
    zone2: {
      gridArea: 'zone2',
      backgroundColor: '#FFFFFF99',
    },
    zone3: {
      gridArea: 'zone3',
      backgroundColor: '#FFFFFF99',
    },
    zone4: {
      gridArea: 'zone4',
      backgroundColor: '#FFFFFF99',
    },
    zone5: {
      gridArea: 'zone5',
      backgroundColor: '#FFFFFF99',
    },
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="container">
        <div style={{ marginTop: '200px' }} className="align-c">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={4}
            sx={{ mt: 2, mb: 3 }}
          >
            <Typo text={t(translationCode + 'TitleTop')} variant="h3" />
            <div style={{ marginTop: '-22px' }}>
              <Typo
                text={t(translationCode + 'Title')}
                variant="h2"
                className="remove-margin"
              />
            </div>
            <Typo
              text={t(translationCode + 'Desc')}
              variant="subTitle"
              sx={{ maxWidth: '700px' }}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={navigteOnboard}
            sx={{
              top: '80%',
              backgroundColor: darkMainColor,
              borderRadius: '21px',
              padding: '8px 20px',
              textTransform: 'none',
            }}
          >
            {t(translationCode + 'CTA')}
          </Button>
        </div>

        <div className="px-4 row mt-5 mb-5">
          <div
            id="platform"
            className="col-md-8 col-12 align-left mt-2"
            style={{
              position: 'relative',
              overflow: 'hidden',
              padding: '15px',
              borderRadius: '20px',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                zIndex: -1,
              }}
            >
              <div
                style={{
                  width: '500px',
                  height: '300px',
                  overflow: 'hidden',
                  backgroundColor: lightGreyScale[0] + '60',
                  borderRadius: '50%',
                  filter: 'blur(120px)',
                  position: 'absolute',
                  top: '220px',
                  left: '-420px',
                }}
              />
              <div
                style={{
                  width: '800px',
                  height: '500px',
                  overflow: 'hidden',
                  backgroundColor: lightGreyScaleSec[2] + '50',
                  borderRadius: '50%',
                  filter: 'blur(120px)',
                  position: 'absolute',
                  top: '-120px',
                  right: '-310px',
                }}
              />
              <div
                style={{
                  width: '300px',
                  height: '200px',
                  overflow: 'hidden',
                  backgroundColor: isDarkMode ? '#00000040' : '#FFFFFF',
                  rotate: '325deg',
                  borderRadius: '80px',
                  filter: 'blur(120px)',
                  position: 'absolute',
                  bottom: '-190px',
                  left: '-220px',
                }}
              />
              <div
                style={{
                  width: '400px',
                  height: '400px',
                  overflow: 'hidden',
                  backgroundColor: lightGreyScale[0] + '60',
                  borderRadius: '50%',
                  filter: 'blur(120px)',
                  position: 'absolute',
                  bottom: '-80px',
                  left: '-80px',
                }}
              />
            </Box>
            <div
              className="row"
              style={{
                backgroundColor: '#FFFFFF70',
                border: '1px solid #FFFFFF',
                borderRadius: '10px',
                padding: '12px',
              }}
            >
              {!isMobile && (
                <div
                  className="col-2 py-4"
                  style={{
                    backgroundColor: mainColor + 20,
                    borderRadius: '10px',
                  }}
                >
                  <Button
                    className="middle-content"
                    fullWidth={true}
                    sx={{
                      zIndex: 100,
                      width: '112px',
                      maxWidth: '112px !important',
                      minWidth: '112px !important',
                      height: '24px',
                      background: `linear-gradient(145deg, ${mainColor || '#FF6F91'
                        }, ${darkColor()})`,
                      borderRadius: '5px',
                      padding: '0.4rem',
                      boxShadow: `0px 4px 10px rgba(0, 0, 0, 0.1),
                          0px -2px 4px rgba(255, 255, 255, 0.2) inset`,
                      color: '#FFFFFF',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        background: `linear-gradient(145deg,${darkColor()}, ${mainColor || '#FF6F91'
                          })`,
                        boxShadow: `0px 6px 15px rgba(0, 0, 0, 0.2),
                            0px -3px 6px rgba(255, 255, 255, 0.3) inset`,
                        transform: 'scale(1.04)',
                        cursor: 'pointer',
                      },
                      '&:active': {
                        transform: 'scale(1)',
                        boxShadow: `0px 3px 8px rgba(0, 0, 0, 0.2),
                            0px -2px 3px rgba(255, 255, 255, 0.3) inset`,
                      },
                    }}
                  >
                    <AddOutlined
                      fontSize="medium"
                      htmlColor={'#FFF'}
                      sx={{
                        marginRight: '5px',
                        marginTop: '-1px',
                        fontSize: '11px',
                      }}
                    />

                    <Typography
                      fontWeight={600}
                      fontSize="9px"
                      color={'#FFF'}
                      variant="body2"
                    >
                      {t('new')}
                    </Typography>
                  </Button>
                  <div className="mt-3 px-1">
                    <div className={'d-flex align-left mb-2'}>
                      <div
                        style={{
                          width: '13px',
                          height: '13px',
                          marginLeft: '0px',
                        }}
                      >
                        {resolveMenuIcon('OPERATIONS')}
                      </div>{' '}
                      <div
                        className="hover"
                        style={{ marginLeft: '5px', marginTop: '0px' }}
                      >
                        <Typography
                          fontWeight={600}
                          fontSize="10.5px"
                          color={darkColor()}
                          variant="body2"
                        >
                          {t('OPERATIONS')}
                        </Typography>
                      </div>
                      <div style={{ marginTop: '0px' }}>
                        <ArrowDropDown
                          sx={{ color: darkColor(), fontSize: '14px' }}
                        />
                      </div>
                    </div>
                    <div className="d-flex middle-content mt-1">
                      <AccountCircleOutlined
                        sx={{ fontSize: 11, marginRight: '4px' }}
                      />
                      <Typography fontSize={10} fontWeight={600}>
                        Contacts
                      </Typography>
                    </div>
                    <div className="d-flex middle-content mt-2">
                      <Inventory2Outlined
                        sx={{ fontSize: 11, marginRight: '4px' }}
                      />
                      <Typography fontSize={10} fontWeight={500}>
                        {t('inventory')}
                      </Typography>
                    </div>
                    <div className="d-flex middle-content mt-2">
                      <RoomServiceOutlined
                        sx={{ fontSize: 11, marginRight: '4px' }}
                      />
                      <Typography fontSize={10} fontWeight={500}>
                        {t('services')}
                      </Typography>
                    </div>
                    <div className="d-flex middle-content mt-2">
                      <ViewKanbanOutlined
                        sx={{ fontSize: 11, marginRight: '4px' }}
                      />
                      <Typography fontSize={10} fontWeight={500}>
                        {t('appointments')}
                      </Typography>
                    </div>
                    <div className="d-flex middle-content mt-2">
                      <WebAssetOutlined
                        sx={{ fontSize: 11, marginRight: '4px' }}
                      />
                      <Typography fontSize={10} fontWeight={500}>
                        {t('webRequests')}
                      </Typography>
                    </div>
                    <div className="d-flex middle-content mt-2">
                      <NotificationAddOutlined
                        sx={{ fontSize: 11, marginRight: '4px' }}
                      />
                      <Typography fontSize={10} fontWeight={500}>
                        {t('reminders')}
                      </Typography>
                    </div>
                    <div className="d-flex middle-content mt-2">
                      <CalendarMonthOutlined
                        sx={{ fontSize: 11, marginRight: '4px' }}
                      />
                      <Typography fontSize={10} fontWeight={500}>
                        {t('calendar')}
                      </Typography>
                    </div>
                  </div>
                </div>
              )}
              <div style={{ paddingLeft: '20px' }} className="col-md-10 col-12">
                <Grid item xs={12} md={6}>
                  <div className="row mb-2 m-1">
                    <div className="col-2">
                      <div>
                        <Typography fontSize={11} fontWeight={600}>
                          {'André Dumais'}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          fontSize={7}
                          fontWeight={400}
                          className="greyText"
                          sx={{ marginTop: '-2px' }}
                        >
                          {'Contacts'}
                        </Typography>
                      </div>
                    </div>
                    <div className="col-4 d-flex" style={{ gap: 3 }}>
                      <ButtonCircle
                        icon={'InsertLinkOutlined'}
                        primary={false}
                        size={'small'}
                        color={'black'}
                        backgroundColor="#FFFFFF"
                      />
                      <ButtonCircle
                        icon={'EditOutlined'}
                        primary={false}
                        size={'small'}
                        color={'black'}
                        backgroundColor="#FFFFFF"
                      />
                      <ButtonCircle
                        icon={'DeleteOutlined'}
                        primary={false}
                        size={'small'}
                        color={'black'}
                        backgroundColor="#FFFFFF"
                      />

                      <ButtonCircle
                        icon={'PrintOutlined'}
                        primary={false}
                        size={'small'}
                        color={'black'}
                        backgroundColor="#FFFFFF"
                      />
                      <ButtonCircle
                        icon={'EmailOutlined'}
                        primary={false}
                        size={'small'}
                        color={'black'}
                        backgroundColor="#FFFFFF"
                      />
                    </div>
                  </div>
                  <Box sx={gridContainerStyle}>
                    {Object.keys(zones).map((zoneId) => (
                      <DropZone
                        key={zoneId}
                        zoneId={zoneId}
                        onDrop={handleDrop}
                        customStyle={zoneStyles[zoneId]}
                        displayAdd={zoneId === 'zone3' || zoneId === 'zone5'}
                      >
                        {zones[zoneId]?.length === 0 && zoneId !== 'zone4' ? (
                          <div className="align-c mt-4">
                            <div>
                              <FlipToBackOutlined sx={{ fontSize: '16px' }} />
                            </div>
                            <div className="mt-1">
                              <Typography fontSize={9} fontWeight={500}>
                                {t('dragCompo')}
                              </Typography>
                              <Typography fontSize={7}>
                                {t('dragCompoDesc')}
                              </Typography>
                            </div>
                          </div>
                        ) : zoneId === 'zone4' ? (
                          <div
                            className="align-c"
                            style={{
                              paddingLeft: '10px',
                              overflow: 'hidden',
                            }}
                          >
                            <motion.div
                              key={currentMessage + 'A'}
                              initial={{ opacity: 0, y: 10 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -10 }}
                              transition={{ duration: 0.8 }}
                            >
                              <HistoryMessage
                                text={messages[0].text}
                                name={messages[0].name}
                                size="small"
                                userId={messages[0].userId}
                                otherUser={messages[0].otherUser}
                              />
                            </motion.div>
                            <motion.div
                              key={currentMessage + 'B'}
                              style={{ marginTop: '-20px' }}
                              initial={{ opacity: 0, y: 20 }}
                              animate={{ opacity: 1, y: 10 }}
                              exit={{ opacity: 0, y: -20 }}
                              transition={{ duration: 0.8, delay: 0.6 }}
                            >
                              <HistoryMessage
                                text={messages[1].text}
                                name={messages[1].name}
                                size="small"
                                userId={messages[1].userId}
                                otherUser={messages[1].otherUser}
                              />
                            </motion.div>
                          </div>
                        ) : (
                          zones[zoneId].map((item, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mt: 0.5,
                                mb: -1,
                                p: 0.5,
                              }}
                            >
                              <div>
                                <Typography
                                  fontWeight={500}
                                  variant="body2"
                                  fontSize={Array.isArray(item?.data) ? 9 : 10}
                                >
                                  {item?.label}
                                </Typography>
                                <div className="d-flex">
                                  {Array.isArray(item?.data) ? (
                                    item?.data.map((itemD, index) => (
                                      <Typography
                                        sx={{
                                          minWidth: '70px',
                                          width: '100%',
                                          borderRight:
                                            index === item?.data?.length - 1
                                              ? 'none'
                                              : '1px solid #69696950',
                                          marginRight: '6px',
                                        }}
                                        variant="body1"
                                        fontSize={8}
                                        key={index}
                                      >
                                        {itemD}
                                      </Typography>
                                    ))
                                  ) : (
                                    <Typography variant="body1" fontSize={7}>
                                      {item?.data}
                                    </Typography>
                                  )}
                                </div>
                              </div>

                              <IconButton
                                size="small"
                                onClick={() => handleRemove(zoneId, index)}
                              >
                                <DeleteIcon
                                  fontSize="small"
                                  color="error"
                                  sx={{ fontSize: '12px' }}
                                />
                              </IconButton>
                            </Box>
                          ))
                        )}
                      </DropZone>
                    ))}
                  </Box>
                </Grid>
              </div>
            </div>
          </div>
          <div id="components" className="col-md-4 col-12">
            <div className="mb-4 px-4">
              <Typography
                fontSize={14}
                fontWeight={600}
                sx={{ marginLeft: '5px' }}
              >
                {t('elementsToDrag')}
              </Typography>
              <Typography
                fontSize={11}
                fontWeight={400}
                sx={{ marginLeft: '5px' }}
              >
                {t('elementsToDragDesc')}
              </Typography>
            </div>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  px: 3,
                }}
              >
                <Typography sx={{ px: 0.4, fontWeight: 600, fontSize: 11 }}>
                  {t('content')}
                </Typography>
                <DraggableItem
                  type="COMPONENT"
                  label="André Dumais"
                  data={t('name')}
                  componentSize={0.5}
                  fit="content"
                  zones={['zone2', 'zone1']}
                  droppedItems={droppedItems}
                />
                <DraggableItem
                  type="COMPONENT"
                  label="VIP"
                  data={t('category')}
                  componentSize={0.5}
                  fit="content"
                  zones={['zone2', 'zone1']}
                  droppedItems={droppedItems}
                />
                <DraggableItem
                  type="COMPONENT"
                  label="19-01-2025"
                  data={t('lastUpdate')}
                  componentSize={0.5}
                  fit="content"
                  zones={['zone2', 'zone1']}
                  droppedItems={droppedItems}
                />
                <DraggableItem
                  type="COMPONENT"
                  label="andredumais.com"
                  data={t('website')}
                  componentSize={0.5}
                  fit="content"
                  zones={['zone2', 'zone1']}
                  droppedItems={droppedItems}
                />
                <DraggableItem
                  type="COMPONENT"
                  label="info@adumais.com"
                  data={t('email')}
                  componentSize={0.5}
                  fit="content"
                  zones={['zone2', 'zone1']}
                  droppedItems={droppedItems}
                />
                <DraggableItem
                  type="COMPONENT"
                  label="000-000-0000"
                  data={t('phone')}
                  componentSize={0.5}
                  fit="content"
                  zones={['zone2', 'zone1']}
                  droppedItems={droppedItems}
                />
                <Typography
                  sx={{ mt: 2, px: 0.4, fontWeight: 600, fontSize: 11 }}
                >
                  {t('list')}
                </Typography>
                <DraggableItem
                  type="COMPONENT"
                  label="#9732"
                  data={['09-12-2024', '78.34$', '0.00$', t('paid')]}
                  componentSize={0.5}
                  fit="list"
                  zones={['zone5']}
                  droppedItems={droppedItems}
                />
                <DraggableItem
                  type="COMPONENT"
                  label="#8723"
                  data={['04-09-2024', '821.19$', '821.19$', t('unpaid')]}
                  componentSize={0.5}
                  fit="list"
                  zones={['zone5']}
                  droppedItems={droppedItems}
                />
                <DraggableItem
                  type="COMPONENT"
                  label="Damien Laporte"
                  data={['999-999-9999', 'dl@adumais.com']}
                  componentSize={0.5}
                  fit="list"
                  zones={['zone3']}
                  droppedItems={droppedItems}
                />
              </Box>
            </Grid>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default DragDropBos;
