import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import OnboardLayout from './OnboardLayout';
import { CheckCircleOutlined, PendingOutlined } from '@mui/icons-material';
import GeneralText from '../../stories/general-components/GeneralText';
import { TextField } from '@mui/material';
import { setOnboardData } from '../../redux/actions-v2/coreAction';


const Step2 = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const pageCode = 'step2Onboard';

    const onboardData = useSelector((state) => state.core.onboardData);

    const [selectedSoftwares, setSelectedSoftwares] = useState(onboardData?.softwares || [])
    const [hoveredSoftware, setHoveredSoftware] = useState('')

    const softwares = [
        {
            id: 'mondaycom',
            name: 'Monday',
            logo: '/assets/v3/connectors/mondaycom.png',
        },
        {
            id: 'trello',
            name: 'Trello',
            logo: '/assets/v3/connectors/trello.png',
        },
        {
            id: 'asana',
            name: 'Asana',
            logo: '/assets/v3/connectors/asana.png',
        },
        {
            id: 'quickbooks',
            name: 'Quickbooks',
            logo: '/assets/v3/connectors/quickbooks.png',
        },
        {
            id: 'zoho',
            name: 'Zoho',
            logo: '/assets/v3/connectors/zoho.png',
        },
        {
            id: 'airtable',
            name: 'Airtable',
            logo: '/assets/v3/connectors/airtable.png',
        },
        {
            id: 'suiteGoogle',
            name: 'Google Workspace',
            logo: '/assets/v3/connectors/google-workspace.png',
        },
        {
            id: 'microsft365',
            name: 'Microsoft 365',
            logo: '/assets/v3/connectors/microsoft-365.png',
        },
        {
            id: 'other',
            name: t(pageCode + 'Other'),
            logo: '',
        },
    ]

    const handleSelectSoftware = (id) => {
        if (selectedSoftwares?.includes(id)) {
            dispatch(setOnboardData({ ...onboardData, softwares: selectedSoftwares?.filter((item) => item !== id) }))
            setSelectedSoftwares(selectedSoftwares?.filter((item) => item !== id))
        } else {
            dispatch(setOnboardData({ ...onboardData, softwares: [...selectedSoftwares, id] }))
            setSelectedSoftwares([...selectedSoftwares, id])
        }
    }


    return (

        <OnboardLayout stepTitle={t(pageCode + 'Title')} stepDesc={t(pageCode + 'Desc')} restrictNext={selectedSoftwares?.length > 0 ? false : true}>
            <div className='row'>
                {softwares?.map((software) => (
                    <div onMouseEnter={() => setHoveredSoftware(software?.id)} onMouseLeave={() => setHoveredSoftware('')} className={(software?.id === 'other' && selectedSoftwares?.includes('other')) ? 'col-12' : 'col-6'} key={software.id}>
                        <div className='mt-3 mb-3' style={{ backgroundColor: selectedSoftwares?.includes(software?.id) ? onboardData?.secColor + '10' : 'transparent', height: '60px', cursor: 'pointer', border: '1px solid', borderColor: selectedSoftwares?.includes(software?.id) ? onboardData?.secColor : '#69696950', boxShadow: hoveredSoftware === software?.id ? '5px 5px 15px 0px rgba(0,0,0,0.1)' : '5px 5px 15px 0px rgba(0,0,0,0.06)', borderRadius: '6px', padding: '10px' }}>
                            <div className='d-flex' style={{ position: 'relative', width: '100%' }}>
                                <div onClick={() => handleSelectSoftware(software?.id)} style={{ position: 'absolute', right: '0', top: '0' }}>
                                    {selectedSoftwares?.includes(software.id) && (
                                        <CheckCircleOutlined htmlColor={onboardData?.secColor} />
                                    )}    </div>
                                <div style={{ width: software?.id === 'other' && selectedSoftwares?.includes('other') ? '' : '100%' }} onClick={() => handleSelectSoftware(software?.id)} className={software?.id === 'other' && selectedSoftwares?.includes('other') ? 'col-6' : ''}>
                                    {software?.id === 'other' ? <PendingOutlined htmlColor={onboardData?.mainColor} /> : <img src={software?.logo} height={20} alt={software?.name + ' Logo'} />}
                                    <GeneralText
                                        text={software?.name}
                                        primary={true}
                                        fontSize={'12px'}
                                        size={selectedSoftwares?.includes(software?.id) ? 'medium' : 'regular'}

                                    />  </div>
                                {software?.id === 'other' && selectedSoftwares?.includes('other') && (
                                    <div className='col-5 mx-3'>
                                        <TextField
                                            id="outlined-basic"
                                            placeholder={t('otherSoftwareName')}
                                            variant="filled"
                                            slotProps={{
                                                input: { disableUnderline: true },
                                            }}
                                            sx={{
                                                height: '42px',
                                                marginTop: '-1px',
                                                backgroundColor: '#FFFFFF',
                                                border: '1px solid',
                                                borderColor: onboardData?.secColor,
                                                borderRadius: '6px',
                                                '& .MuiFilledInput-root': {
                                                    height: '40px',
                                                    backgroundColor: '#FFFFFF',
                                                    borderRadius: '6px',
                                                }
                                            }}

                                            value={onboardData?.otherSoftware}
                                            onChange={(e) => dispatch(setOnboardData({ ...onboardData, otherSoftware: e.target.value }))}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </OnboardLayout >

    );
};

export default Step2;
