import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import Typo from '../Typo';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const AllInOne = ({ translationCode }) => {
  const { t, } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      style={{
        marginTop: '150px',
      }}
      className="container"
    >
      {' '}
      <div className={isMobile ? 'row px-4' : 'row'}>
        <div className="col-md-6 col-12">
          <img
            src="/assets/website/v3/shop.png"
            width={isMobile ? 400 : 500}
            alt={t('imageOptimizedWorker')}
            style={{ borderRadius: '15px' }}
          />
        </div>
        <div className="col-md-6 col-12">
          {' '}
          <Typo
            text={t(translationCode + 'Title')}
            variant="h2"
            className="mt-4"
          />
          <Typo
            text={t(translationCode + 'Sub')}
            variant="subTitle"
            className="mt-3"
          />
          <div className="mt-5">
            <Typo
              text={t(translationCode + 'Item1')}
              variant="body2"
              className="mt-1"
            />
          </div>
          <div className="mt-2">
            <Typo
              text={t(translationCode + 'Item2')}
              variant="body2"
              className="mt-1"
            />
          </div>
          <div className="mt-2">
            <Typo
              text={t(translationCode + 'Item3')}
              variant="body2"
              className="mt-1"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllInOne;
