import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import Typo from '../Typo';

const TryBos = ({ translationCode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigateFeatures = () => {
    navigate('/products/bos/perform');
  };

  return (
    <Box
      className="container"
      sx={{
        marginTop: isMobile ? '225px' : '450px',
        width: '100%',
        position: 'relative',
      }}
    >
      <div className="row">
        <div className="col-md-5 col-12 py-4">
          <Typo
            variant="h3"
            text={t(translationCode + 'Title')}
            sx={{
              maxWidth: '373px',
            }}
            className={isMobile ? 'px-4 mt-5' : 'mt-5'}
          />
          <Typo
            variant="h2"
            text={t(translationCode + 'Title2')}
            sx={{
              maxWidth: '373px',
            }}
            className={isMobile ? 'px-4 mt-2' : 'mt-2'}
          />

          <Typo
            variant="body2"
            text={t(translationCode + 'FeaturesDesc')}
            sx={{
              maxWidth: '423px',
            }}
            className={isMobile ? 'px-4 mt-4' : 'mt-4'}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={navigateFeatures}
            disableElevation
            className={isMobile ? 'mx-4' : ''}
            sx={{
              borderRadius: '21px',
              marginTop: isMobile ? '30px' : '80px',
              padding: '8px 20px',
              textTransform: 'none',
            }}
          >
            {t(translationCode + 'CTA')}
          </Button>
        </div>
        <div className="col-md-7 col-12">
          <div
            style={{ marginTop: '30px' }}
            className={isMobile ? 'row align-c mx-3' : 'row align-c'}
          >
            <div className="col-md-6 col-12">
              <Card
                elevation={0}
                sx={{
                  width: isMobile ? '100%' : '273px',
                  height: '264px',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 2,
                  borderRadius: '20px',
                  border: '1px solid #1b1b1b14',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CardMedia
                  component="img"
                  image="/assets/website/v3/features_gen.png"
                  alt="Collaboration"
                  sx={{ width: '157px' }}
                />
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typo
                    variant="subTitle"
                    text={t(translationCode + 'Collab')}
                    className="mt-4"
                    bold
                  />
                  <Typo
                    variant="p"
                    text={t(translationCode + 'CollabDesc')}
                    className="mt-2"
                  />
                </CardContent>
              </Card>

              <Card
                elevation={0}
                sx={{
                  marginTop: '30px',
                  width: isMobile ? '100%' : '273px',
                  height: '270px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 2,
                  borderRadius: '20px',
                  border: '1px solid #1b1b1b14',
                }}
              >
                <CardMedia
                  component="img"
                  image="/assets/website/v3/cards-centralize.png"
                  alt="Expérience"
                  sx={{ width: '157px' }}
                />
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typo
                    variant="subTitle"
                    text={t(translationCode + 'Exp')}
                    className="mt-4"
                    bold
                  />
                  <Typo
                    variant="p"
                    text={t(translationCode + 'ExpDesc')}
                    className="mt-2"
                  />
                </CardContent>
              </Card>
            </div>
            <div className="col-md-6 col-12">
              <Card
                elevation={0}
                sx={{
                  marginTop: '80px',
                  width: isMobile ? '100%' : '273px',
                  height: '358px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 2,
                  borderRadius: '20px',
                  border: '1px solid #1b1b1b14',
                }}
              >
                <CardMedia
                  component="img"
                  image="/assets/website/v3/connect-softwares.png"
                  alt="Cube helix"
                  sx={{ width: '263px', height: '228px', marginTop: '-33.5px' }}
                />
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typo
                    variant="subTitle"
                    text={t(translationCode + 'Connex')}
                    className="mt-4"
                    bold
                  />
                  <Typo
                    variant="p"
                    text={t(translationCode + 'ConnexDesc')}
                    className="mt-2"
                  />
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default TryBos;
