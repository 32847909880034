import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Paper,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
  Typography,
  Divider,
  Tooltip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AddCircleOutline } from '@mui/icons-material';
import GeneralText from '../../stories/general-components/GeneralText';

const FieldsPopper = ({ onSelect, text, fields, existingFields }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (field) => {
    onSelect(field);
    setOpen(false);
  };

  return (
    <div>
      <div className="d-flex middle-content clickable" ref={anchorRef}>
        {text && (

          <Typography
            color={isDarkMode ? '#F7f7f7' : '#696969'}
            fontSize={10}
            onClick={handleToggle}
          >
            {text || ''}
          </Typography>

        )}
        <Tooltip
          title={t('addField')}
          placement="top"
          arrow
        >
          <span>
            <AddCircleOutline
              htmlColor={isDarkMode ? '#F7f7f7' : '#696969'}
              onClick={handleToggle}
              sx={{ fontSize: '14px', cursor: 'pointer', marginLeft: text ? '5px' : '-25px' }}
            />  </span>
        </Tooltip>
      </div>

      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 9999 }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={0} sx={{ width: '320px', padding: 2, marginBottom: '180px' }}>
            <GeneralText
              primary={true}
              size="bold"
              fontSize="12px"
              text={t('fields')}
              classNameComponent="mb-2 px-4"
            />
            <Divider component='div' />
            <List dense className='px-4'>
              {fields?.map((field) => (
                <ListItem
                  button={existingFields?.includes(field?.value) ? null : 'true'}
                  dense
                  divider
                  sx={{ p: 0, m: 0, cursor: existingFields?.includes(field?.value) ? 'not-allowed' : 'pointer' }}
                  key={field?.value}
                  onClick={existingFields?.includes(field?.value) ? null : () => handleSelect(field)}
                >
                  <ListItemText
                    primary={field?.name}
                    primaryTypographyProps={{ fontSize: '11px', color: existingFields?.includes(field?.value) ? '#A9A9A9' : '#000000' }}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </ClickAwayListener>
      </Drawer>
    </div>
  );
};

export default FieldsPopper;
