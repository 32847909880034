import React, { useEffect, useMemo, useState, useCallback } from 'react';

// redux
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import animationData from '../lotties/empty-box';
import moment from 'moment';

// utils
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import ModalLarge from './Base/ModalLarge';
import nodeAxiosFirebase from '../utils/nodeAxiosFirebase';

// MUI components
import {
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Divider,
  Tabs,
  Tab,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { StarOutlined } from '@mui/icons-material';
import Lottie from 'react-lottie';

const StyledSearch = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
  },
  // Reduced width
  width: '100%', // Or a specific percentage like '75%' or '50%'
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  borderRadius: '8px',
  cursor: 'pointer',
  transition: 'background-color 0.2s ease',
  '&:hover': {
    backgroundColor: '#f5f5f550',
  },
}));

const StyledCheckbox = styled(FormControlLabel)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0.2),
  borderRadius: 0,
  transition: 'background-color 0.2s ease',
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
  '& .MuiCheckbox-root': {
    color: theme.palette.text.secondary,
  },
  '& .Mui-checked': {
    color: theme.palette.primary.main,
  },
}));


const ModalNewConnector = ({
  isOpen,
  modalCloseHandler,
  structureId,
  type
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';


  const [term, setTerm] = useState('');
  const [modules, setModules] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [debouncedTerm, setDebouncedTerm] = useState('');

  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );


  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructures = businessStructure?.structures;
  const structure = businessStructures.find((s) => s.id === structureId);

  const defaultLottieOptions = useMemo(
    () => ({
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
    }),
    []
  );

  const categories = [
    { value: 'feature', label: t('feature'), desc: 'Module, tableau de bord, rapport de Node' },
    { value: 'integration', label: t('integration'), desc: 'Données gérées par logiciel externe' },
    { value: 'plugin', label: t('plugin'), desc: 'Automatisme ou exécution automatique' },
    { value: 'account', label: t('account'), desc: 'Communication inter-comptes' },
    { value: 'component', label: t('component'), desc: 'Bloc, champ ou menu personnalisé' },
    { value: 'template', label: t('template'), desc: 'Courriel, PDF ou liste personnalisé' },
  ];


  const handleGetData = useCallback(async () => {
    try {
      const response = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/marketplace`,
        body: {
          query: debouncedTerm,  // Use debounced term here
          categories: selectedCategories,
        },
      });

      setModules(response || []);
    } catch (error) {
      console.error(error);
    }
  }, [debouncedTerm, selectedCategories, t]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedTerm(term);
    }, 300);


    return () => {
      clearTimeout(timeoutId);
    };
  }, [term]);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);


  const handleCategoryChange = (category) => {
    setSelectedCategories(prevSelected => {
      if (prevSelected.includes(category)) {
        return prevSelected.filter(c => c !== category);
      } else {
        return [...prevSelected, category];
      }
    });
  };

  const handleModuleClick = (module) => {
    setSelectedModule(module);
  };

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  // console.log(selectedModule)

  return (
    <ModalLarge
      isOpen={isOpen}
      subTitle={t('addNewConnectorDesc')}
      modalCloseHandler={modalCloseHandler}
      icon={structure?.icon}
      title={t('addConnector')}
      btnLabel={t('add')}
      fullWidth
      maxWidth='lg'
    >
      <div style={{ justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
        <Tabs value={activeTab} onChange={handleTabChange}
          sx={{
            height: '42px',
            maxHeight: '42px',
            minHeight: '42px',
            backgroundColor: isDarkMode ? '#000' : '#FFF',
            borderRadius: '40px',
            position: 'relative',
            overflow: 'hidden',
            '.MuiTabs-indicator': {
              display: 'none',
            },
            '&::before': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: 0,
              height: '100%',
              width: '100%',
              borderRadius: '4px',
              transition: 'transform 0.3s ease-in-out',
              zIndex: 1,
            },
          }}>
          <Tab label={t('searchModules')} style={{
            fontWeight:
              (activeTab === 0)
                ? '600'
                : '500',
            textTransform: 'capitalize',
            padding: '10px 20px 10px 20px',
            fontSize: '13px',
          }} sx={{
            position: 'relative',
            zIndex: 2,
            minHeight: '42px',
            maxHeight: '42px',
            height: '42px',
            color: isDarkMode
              ? '#FFFFFF !important'
              : (activeTab !== 0)
                ? '#000000 !important'
                : '#FFFFFF !important',
            backgroundColor:
              (activeTab === 0)
                ? businessPreference?.mainColor
                : 'transparent',
            borderRadius: '30px',
            transition: 'color 0.3s ease-in-out',
            '&:hover': {
              color: '#FFFFFF',
            },
          }} />
          <Tab label={t('modulesActivated')} style={{
            fontWeight:
              (activeTab === 1)
                ? '600'
                : '500',
            textTransform: 'capitalize',
            padding: '10px 20px 10px 20px',
            fontSize: '13px',
          }} sx={{
            position: 'relative',
            zIndex: 2,
            minHeight: '42px',
            maxHeight: '42px',
            height: '42px',
            color: isDarkMode
              ? '#FFFFFF !important'
              : (activeTab !== 1)
                ? '#000000 !important'
                : '#FFFFFF !important',
            backgroundColor:
              (activeTab === 1)
                ? businessPreference?.mainColor
                : 'transparent',
            borderRadius: '30px',
            transition: 'color 0.3s ease-in-out',
            '&:hover': {
              color: '#FFFFFF',
            },
          }} />

        </Tabs>
      </div>

      {activeTab === 0 && !selectedModule && (
        <>
          <Grid container spacing={2} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <Grid item xs={3} sx={{ borderRadius: '8px', backgroundColor: '#00000008', padding: 2, }}>
              <Typography variant="h6" fontSize={'14px'} fontWeight={600} gutterBottom>{t('types')}</Typography>
              <Divider component="div" sx={{ mb: 2 }} />
              {categories?.map(category => (
                <StyledCheckbox
                  key={category?.value}
                  sx={{
                    backgroundColor: selectedCategories.includes(category?.value) ? businessPreference?.mainColor + '20' : 'transparent',
                  }}
                  control={
                    <Checkbox
                      checked={selectedCategories.includes(category?.value)}
                      onChange={() => handleCategoryChange(category?.value)}
                    />
                  }
                  label={category?.label}
                />
              ))}
            </Grid>

            <Grid item xs={9} sx={{ paddingLeft: '25px' }}>
              <Grid item xs={12} mt={2} mb={2}>
                <StyledSearch
                  placeholder={t('searchModule')}
                  variant="outlined"
                  sx={{
                    maxHeight: '42px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '40px',
                      backgroundColor: isDarkMode ? '#000' : '#FFF',
                    },
                    '& .MuiInputBase-input': {
                      padding: '10px 20px',
                    },
                  }}
                  value={term}
                  onChange={(e) => setTerm(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid container spacing={2} sx={{ height: '48vh', overflowY: 'scroll' }}>
                {modules?.length > 0 && modules?.map(module => (
                  <Grid item xs={12} sm={6} md={4} key={module.id}>
                    <StyledCard onClick={() => handleModuleClick(module)} elevation={0} sx={{ paddingLeft: 1.3 }}>
                      <CardMedia
                        component="img"
                        alt={module?.title}
                        image={module?.logoUrl || 'default_image.jpg'}
                        sx={{
                          mt: 2,
                          width: 30,
                          height: 30,
                          objectFit: 'contain',
                          alignSelf: 'flex-start',
                        }}
                      />
                      <CardContent>
                        <Box display="flex" justifyContent="space-between" alignItems="flex-start" >
                          <Typography variant="h6" component="div" sx={{ fontWeight: 600, fontSize: '1rem' }}>
                            {module?.title}
                          </Typography>

                          {module?.releaseDate ? (
                            <Box
                              sx={{
                                backgroundColor: businessPreference?.mainColor + '40',
                                color: businessPreference?.mainColor,
                                px: 1,
                                borderRadius: 1,
                                fontSize: '0.75rem',
                                fontWeight: 500,
                                ml: 1,
                                whiteSpace: 'nowrap'
                              }}
                            >
                              {t('releaseSoon')}
                            </Box>
                          ) : module?.bestSeller ? (<Box
                            sx={{
                              backgroundColor: businessPreference?.mainColor + '40',
                              color: businessPreference?.mainColor,
                              px: 1,
                              borderRadius: 1,
                              fontSize: '0.75rem',
                              fontWeight: 500,
                              ml: 1,
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {t('bestSeller')}
                          </Box>) : ('')}
                        </Box>

                        <Typography variant="body2" color="text.secondary" sx={{ mb: 1, fontSize: '0.8rem' }}>
                          {module?.description}
                        </Typography>

                        <Divider component="div" sx={{ width: '100%', height: '1px', }} />

                        <Box display="flex" alignItems="center" gap={1} className='middle-content' mt={0.3}>

                          <Typography variant="body2" sx={{ fontWeight: 500 }}>
                            <StarOutlined sx={{ fontSize: '12px', paddingTop: '3px' }} /> {module?.rating || '0'} ({module?.votes || '0'})
                          </Typography>
                          <Typography variant="body2" color="text.secondary" fontSize="0.75rem">
                            {t('by')} {module?.author || '-'}
                          </Typography>
                        </Box>
                      </CardContent>
                    </StyledCard>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {activeTab === 1 && !selectedModule && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: '10px',
            textAlign: 'center',
          }}
        >
          <Lottie
            options={defaultLottieOptions}
            height={60}
            width={80}
          />
          <Typography
            variant="h6"
            sx={{ fontSize: '13px', mt: 1 }}
          >
            {t('emptyListTitle')}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: '10px',
              mt: 0.5,
              color: 'text.secondary',
            }}
          >
            {t('emptyListDescription')}
          </Typography>
        </div>
      )}
      {selectedModule && (
        <div className='p-3'>

        </div>
      )}
    </ModalLarge>
  );
};

export default ModalNewConnector;