import { Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

//general
import PrivateRoute from './components/PrivateRoute';
import Inbox from './screens/mainPages/Inbox';
import NodeToolsDev from './screens/specific/NodeToolsDev';
import DashboardNode from './screens/specific/DashboardNode';
import ResourceNode from './screens/specific/ResourceNode';
import MarketPlaceNode from './screens/specific/MarketPlaceNode';

const NodeRoutes = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Routes>

      <Route
        path="/inbox"
        element={
          <PrivateRoute
            permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER', 'PERSONAL']}
          >
            <Inbox />
          </PrivateRoute>
        }
      />
      <Route
        path="/dev"
        element={
          <PrivateRoute
            permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER', 'PERSONAL']}
          >
            <NodeToolsDev />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute
            permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER', 'PERSONAL']}
          >
            <DashboardNode />
          </PrivateRoute>
        }
      />
      <Route
        path="/resource"
        element={
          <PrivateRoute
            permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER', 'PERSONAL']}
          >
            <ResourceNode />
          </PrivateRoute>
        }
      />
      <Route
        path="/marketplace"
        element={
          <PrivateRoute
            permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER', 'PERSONAL']}
          >
            <MarketPlaceNode />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default NodeRoutes;
