import React, { } from 'react';
import SiteLayout from '../SiteLayout';
import HeaderModules from '../components/bos/HeaderModules';
import ModulesList from '../components/bos/ModulesList';
import ModulesIndustries from '../components/bos/ModulesIndustries';
import ModulesLayout from '../components/bos/ModulesLayout';
import ModulesFAQ from '../components/bos/ModulesFAQ';
import { useTranslation } from 'react-i18next';

const ExternalBos = () => {
  const { t } = useTranslation();
  const pageCode = 'modulesBOS';
  return (
    <SiteLayout
      helmetData={{
        name: t(pageCode + 'SEO'),
        description: t(pageCode + 'SEODesc'),
      }}
    >
      <div className="mt-5 mb-5">
        <HeaderModules translationCode={pageCode + 'Header'} />
      </div>
      <div className="mt-5 mb-5">
        <ModulesList translationCode={pageCode + 'List'} />
      </div>
      <div className="mt-5 mb-5">
        <ModulesIndustries translationCode={pageCode + 'Indus'} />
      </div>
      <div className="mt-5 mb-5">
        <ModulesLayout translationCode={pageCode + 'Layout'} />
      </div>
      <div className="mt-5 mb-5">
        <ModulesFAQ translationCode={pageCode + 'FAQ'} />
      </div>
    </SiteLayout>
  );
};

export default ExternalBos;
