import React, { } from 'react';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import Typo from '../Typo';
import PanoramaVerticalOutlinedIcon from '@mui/icons-material/PanoramaVerticalOutlined';
import PanoramaHorizontalOutlinedIcon from '@mui/icons-material/PanoramaHorizontalOutlined';
import { useMediaQuery } from '@mui/material';

const AlignCases = ({ translationCode }) => {
  const { t, } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';
  const localStorageColor = localStorage.getItem('mainColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';

  const lightMainColor = chroma(mainColor).brighten(1.2).hex();

  return (
    <div
      style={{
        marginTop: '100px',
        backgroundColor: lightMainColor + '20',
        padding: '35px',
        borderRadius: '20px',
      }}
      className="container"
    >
      {' '}
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          gap={1}
          px={2}
          py={1}
          borderRadius="20px"
          border={isDarkMode ? '1px solid #FFFFFF80' : '1px solid #2222221a'}
        >
          <Typography
            variant="body2"
            fontWeight="medium"
            textAlign="center"
            color={isDarkMode ? '#FFFFFF' : '#222222'}
            sx={{ fontFamily: "'Montserrat', Helvetica" }}
          >
            {t(translationCode + 'Chip')}
          </Typography>
        </Box>

        <Typo
          text={t(translationCode + 'Top')}
          variant="h2"
          className="align-c mt-3"
        />
      </Box>
      <div className="row mt-4">
        <div
          style={{ padding: isMobile ? '20px' : '40px' }}
          className="col-md-6 col-12"
        >
          <div
            style={{
              backgroundColor: isDarkMode ? '#000000' : '#FFFFFF',
              borderRadius: '15px',
              padding: '40px',
            }}
          >
            <PanoramaVerticalOutlinedIcon
              color={isDarkMode ? 'white' : 'black'}
              sx={{ fontSize: '30px' }}
            />

            <Typo
              text={t(translationCode + 'VertiTitle')}
              variant="subTitle"
              className="mt-2"
              bold
            />
            <Typo
              text={t(translationCode + 'VertiDesc')}
              variant="body1"
              className="mt-2"
            />
            <Typo
              text={t(translationCode + 'VertiPointT1')}
              variant="body1"
              bold
              className="mt-5"
            />
            <Typo
              text={t(translationCode + 'VertiPointP1')}
              variant="p"
              className="mt-1"
            />
            <Typo
              text={t(translationCode + 'VertiPointT2')}
              variant="body1"
              bold
              className="mt-4"
            />
            <Typo
              text={t(translationCode + 'VertiPointP2')}
              variant="p"
              className="mt-1"
            />
            <Typo
              text={t(translationCode + 'VertiPointT3')}
              variant="body1"
              bold
              className="mt-4"
            />
            <Typo
              text={t(translationCode + 'VertiPointP3')}
              variant="p"
              className="mt-1"
            />
          </div>
        </div>
        <div
          style={{ padding: isMobile ? '20px' : '40px' }}
          className="col-md-6 col-12"
        >
          <div
            style={{
              backgroundColor: isDarkMode ? '#000000' : '#FFFFFF',
              borderRadius: '15px',
              padding: '40px',
            }}
          >
            <PanoramaHorizontalOutlinedIcon
              color={isDarkMode ? 'white' : 'black'}
              sx={{ fontSize: '30px' }}
            />
            <Typo
              text={t(translationCode + 'HorizTitle')}
              variant="subTitle"
              className="mt-2"
              bold
            />
            <Typo
              text={t(translationCode + 'HorizDesc')}
              variant="body1"
              className="mt-2"
            />
            <Typo
              text={t(translationCode + 'HorizPointT1')}
              variant="body1"
              bold
              className="mt-5"
            />
            <Typo
              text={t(translationCode + 'HorizPointP1')}
              variant="p"
              className="mt-1"
            />
            <Typo
              text={t(translationCode + 'HorizPointT2')}
              variant="body1"
              bold
              className="mt-4"
            />
            <Typo
              text={t(translationCode + 'HorizPointP2')}
              variant="p"
              className="mt-1"
            />
            <Typo
              text={t(translationCode + 'HorizPointT3')}
              variant="body1"
              bold
              className="mt-4"
            />
            <Typo
              text={t(translationCode + 'HorizPointP3')}
              variant="p"
              className="mt-1"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlignCases;
