import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'; // Keep import if used conditionally
import chroma from 'chroma-js';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Paper, Typography, Tooltip, IconButton } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { toast } from 'react-toastify';

// Redux Actions
import * as modalActions from '../../redux/actions/modal-actions';
import * as drawerActions from '../../redux/actions-v2/drawer-actions';
import { setGeneralStatus } from '../../redux/actions-v2/coreAction';

// Components
import ErrorBoundary from '../../components/@generalComponents/ErrorBoundary';
import ListLayout from '../children-components/ListLayout';
import ContentLayout from '../children-components/ContentLayout';
import ActionLayout from '../children-components/ActionLayout';
import Loading from '../general-components/Loading';
import GeneralText from '../general-components/GeneralText';
import FieldsPopper from '../../components/@generalComponents/FieldsPopper';
import ComponentPopper from '../../components/@generalComponents/ComponentPopper';
import SettingsPopper from '../../components/@generalComponents/SettingsPopper';

// Utilities & Data
import animationData from '../../lotties/empty-box';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import defaultFieldsResolver from '../../utils/defaultFieldsResolver';

// Style
import 'react-perfect-scrollbar/dist/css/styles.css';

const BlockLayoutScroll = React.memo(
  ({
    fontColor,
    elementDetails,
    layout,
    childrenComponent,
    heightPercentage,
    handleAddItem,
    handleCreateNew,
    blockWidth,
    activeIndex,
    editMode,
    handleDelete,
    handleRefresh,
    structure,
    fromList,
    publicMode
  }) => {
    switch (childrenComponent) {
      case 'list':
        return (
          <ListLayout
            elementDetails={elementDetails}
            layout={layout}
            activeIndex={activeIndex}
            handleRefresh={handleRefresh}
            structure={structure}
            editMode={editMode}
            fromList={fromList}
            publicMode={publicMode}
          />
        );
      case 'content':
        return (
          <ContentLayout
            fontColor={fontColor}
            elementDetails={elementDetails}
            layout={layout}
            blockWidth={blockWidth}
            activeIndex={activeIndex}
            publicMode={publicMode}
            handleDelete={handleDelete}
            handleRefresh={handleRefresh}
            editMode={editMode}
            structure={structure}
            fromList={fromList}
          />
        );
      case 'action':
        return (
          <ActionLayout
            elementDetails={elementDetails}
            layout={layout}
            structureData={structure}
            heightPercentage={heightPercentage}
            editMode={editMode}
            publicMode={publicMode}
            handleRefresh={handleRefresh}
            handleCreateNew={handleCreateNew}
            handleAddItem={handleAddItem}
            activeIndex={activeIndex}
            fromList={fromList}
          />
        );
      default:
        return null;
    }
  }
);

const BlockLayout = ({
  layout,
  elementDetails,
  childrenComponent,
  editMode,
  publicMode,
  activeIndex,
  setDrag,
  handleRemoveBlock,
  handleUpdateBlock,
  handleRefresh,
  structureId: parentStructureId,
  moduleName,
  heightPercentage,
  noPadding,
  fromList,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { structureId: routeStructureId } = useParams();

  // --- Selectors ---
  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );
  const currentUser = useSelector((state) => state.core.user);
  const currentStatus = useSelector((state) => state.core.status);
  const singleElement = useSelector(
    (state) => state.element.singleElementDetails
  );


  // --- Derived Data ---
  const currentLangCode = i18n.language;
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDarkMode = theme.palette.mode === 'dark';
  const blockStructureId = layout?.structureId || parentStructureId;

  // Find the structure details for *this block*
  const structure = useMemo(
    () => businessStructure?.structures?.find((s) => s.id === blockStructureId),
    [businessStructure?.structures, blockStructureId]
  );

  const structureFields = useMemo(() => structure?.fields || [], [structure]);
  const structureCollectionField = useMemo(
    () => structure?.collectionField,
    [structure]
  );
  const structureIconName = useMemo(
    () => structure?.icon || 'FolderOutlined',
    [structure]
  );
  const structureName = useMemo(() => structure?.name, [structure]);

  // --- State ---
  const [availableFields, setAvailableFields] = useState([]);

  // --- Memoized Values ---
  const blockData = useMemo(
    () => ({
      // Combine relevant config from layout and elementDetails.header
      name: elementDetails?.header?.name || '',
      filter: elementDetails?.header?.filter || {},
      labels: elementDetails?.header?.labels ?? true,
      createBtn: elementDetails?.header?.createBtn || false,
      editBtn: elementDetails?.header?.editBtn || false,
      refreshBtn: elementDetails?.header?.refreshBtn || false,
      realtime: elementDetails?.header?.realtime || false,
      icon: elementDetails?.header?.icon || '',
      infos: elementDetails?.header?.infos || {},
      color: layout?.color || 'white',
      bgPattern: layout?.bgPattern || '',
      match: layout?.match || '',
      structureId: blockStructureId,
      i: layout?.i,
      changesInProgress: elementDetails?.header?.changesInProgress || [],
      groups: elementDetails?.groups || [],
    }),
    [elementDetails?.header, elementDetails?.groups, layout, blockStructureId]
  );

  const blockContentData = useMemo(
    () => elementDetails?.data || [],
    [elementDetails?.data]
  );

  const defaultLottieOptions = useMemo(
    () => ({
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
    }),
    []
  );

  // --- Dynamic Styles ---
  const mainColor = theme.palette.primary.main;
  const secColor = theme.palette.secondary.main;

  const {
    bgColor,
    headerBgColor,
    borderColor,
    fontColor,
    // iconColor, // Derived from fontColor or headerTextColor now
    headerTextColor,
    headerIconColor,
  } = useMemo(() => {
    const isSingleContent =
      layout?.contentType === 'content' && blockContentData?.length === 1;
    const statusColor =
      isSingleContent && layout?.data?.[0]?.typeValue === 'status'
        ? (structureFields
          .find((f) => f.typeData === 'status')
          ?.selections?.find(
            (s) =>
              s[`label_${currentLangCode}`]?.toString() ===
              layout.data[0].transformedValue?.toString()
          )?.color || mainColor) + '30'
        : null;

    let resolvedBgColor = isDarkMode ? '#171717' : '#f6f6f685'; // Default background
    let resolvedHeaderBgColor = 'transparent'; // Default header background
    let resolvedBorderColor = '#69696930'; // Default border
    let resolvedFontColor = isDarkMode ? '#fff' : '#000'; // Default font color (for content)
    let resolvedHeaderTextColor = resolvedFontColor;
    let resolvedHeaderIconColor = resolvedFontColor;

    const colorMapping = {
      transparent: {
        bg: 'transparent',
        border: '#69696930',
        header: 'transparent',
        font: isDarkMode ? '#fff' : '#000',
      },
      white: {
        bg: isDarkMode ? '#171717' : '#ffffff',
        bgOpacity: isDarkMode ? '' : '90',
        border: '#69696930',
        header: isDarkMode
          ? `linear-gradient(178deg, ${'#0f0f0f'} 25%, ${'#1c1c1c'} 52%, #1c1c1c 75%)`
          : `linear-gradient(178deg, ${'#ffffffb3'} 25%, ${'#fff'} 52%, #FFFFFF 75%)`, // Slight transparency
        font: isDarkMode ? '#fff' : '#000',
      },
      mainColor: {
        bg: isDarkMode ? '#171717' : mainColor,
        bgOpacity: isDarkMode ? '85' : '10',
        border: chroma(mainColor).darken(0.99).hex() + '30',
        header: isDarkMode
          ? `linear-gradient(178deg, ${chroma(mainColor).darken(4).hex() + 'b3'
          } 25%, ${'#0f0f0f'} 52%, #1c1c1c 75%)`
          : `linear-gradient(178deg, ${chroma(mainColor)
            .alpha(0.06)
            .css()} 25%, ${chroma(mainColor)
              .alpha(0.1)
              .css()} 52%, #FFFFFF 75%)`,
        font: isDarkMode ? '#fff' : mainColor, // Use mainColor for text in light mode
        headerText: isDarkMode ? '#fff' : chroma(mainColor).darken(1.5).hex(), // Darker text for header in light mode
      },
      secColor: {
        bg: isDarkMode ? '#171717' : secColor, // Use dark bg, or secColor in light
        bgOpacity: isDarkMode ? '85' : '10', // Apply opacity
        border: chroma(secColor).darken(0.99).hex() + '30',
        header: isDarkMode
          ? `linear-gradient(178deg, ${chroma(secColor).darken(4).hex() + 'b3'
          } 25%, ${'#0f0f0f'} 52%, #1c1c1c 75%)`
          : `linear-gradient(178deg, ${chroma(secColor)
            .alpha(0.09)
            .css()} 25%, ${chroma(secColor)
              .alpha(0.014)
              .css()} 52%, #FFFFFF 75%)`,
        font: isDarkMode ? '#fff' : secColor, // Use secColor for text in light mode
        headerText: isDarkMode ? '#fff' : chroma(secColor).darken(1.5).hex(), // Darker text for header in light mode
      },
    };

    const selectedColorConf =
      colorMapping[blockData.color] || colorMapping['white']; // Fallback to white

    resolvedBgColor = selectedColorConf.bg;
    if (selectedColorConf.bgOpacity && resolvedBgColor !== 'transparent') {
      resolvedBgColor += selectedColorConf.bgOpacity; // Append opacity hex if defined
    }
    // Special case for single content status block
    if (statusColor && isSingleContent) {
      resolvedBgColor = statusColor;
      resolvedFontColor =
        chroma.contrast(statusColor, '#FFF') > 4.5 ? '#fff' : '#000'; // Auto contrast for font
    } else if (
      isSingleContent &&
      blockData.color !== 'white' &&
      blockData.color !== 'transparent'
    ) {
      // For single content with main/sec color bg, use white/black text
      resolvedFontColor = isDarkMode
        ? '#fff'
        : chroma.contrast(selectedColorConf.bg, '#FFF') > 4.5
          ? '#fff'
          : '#000';
    } else {
      resolvedFontColor = selectedColorConf.font;
    }

    resolvedBorderColor = selectedColorConf.border;
    resolvedHeaderBgColor = editMode ? selectedColorConf.header : 'transparent';
    resolvedHeaderTextColor = selectedColorConf.headerText || resolvedFontColor; // Use specific header text or fallback
    resolvedHeaderIconColor =
      selectedColorConf.headerIcon || resolvedHeaderTextColor; // Use specific header icon or match text

    return {
      bgColor: resolvedBgColor,
      headerBgColor: resolvedHeaderBgColor,
      borderColor: resolvedBorderColor,
      fontColor: resolvedFontColor,
      iconColor: resolvedFontColor, // Re-added for clarity, even if it matches fontColor
      headerTextColor: resolvedHeaderTextColor,
      headerIconColor: resolvedHeaderIconColor,
    };
  }, [
    blockData.color,
    layout?.contentType,
    blockContentData?.length,
    isDarkMode,
    mainColor,
    secColor,
    editMode,
    currentLangCode,
    structureFields,
    layout?.data,
  ]);

  // --- Icon Component ---
  const HeaderIcon = useMemo(
    () => Icons[structureIconName] || Icons.FolderOutlined,
    [structureIconName]
  );

  // --- Field Definitions for Poppers ---
  useEffect(() => {
    if (structure) {
      const defaultFields = defaultFieldsResolver(structureCollectionField, t);
      const combinedFields = [...structureFields, ...defaultFields];
      const uniqueFieldsMap = new Map(
        combinedFields.map((field) => [field.value, field])
      );
      setAvailableFields(Array.from(uniqueFieldsMap.values()));
    } else {
      setAvailableFields([]);
    }
  }, [structure, structureCollectionField, structureFields, t]);

  // --- Event Handlers ---
  const handleChangeBlockSetting = useCallback(
    (key, value, position) => {
      handleUpdateBlock({ ...blockData, [key]: value }, layout.i, position);
    },
    [handleUpdateBlock, layout.i, blockData]
  );

  const handleToggleCreateBtn = useCallback(() => {
    handleChangeBlockSetting('createBtn', !blockData.createBtn, 'header');
  }, [handleChangeBlockSetting, blockData.createBtn]);

  const handleToggleEditBtn = useCallback(() => {
    handleChangeBlockSetting('editBtn', !blockData.editBtn, 'header');
  }, [handleChangeBlockSetting, blockData.editBtn]);

  const handleToggleRefreshBtn = useCallback(() => {
    handleChangeBlockSetting('refreshBtn', !blockData.refreshBtn, 'header');
  }, [handleChangeBlockSetting, blockData.refreshBtn]);

  const handleAddItemToList = useCallback(
    async (item, option, group, type, quantity) => {
      if (!singleElement?.documentPath) {
        toast.error(t('cannotAddItemError'));
        return;
      }
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: group ? group : `add-item-${layout.i}`,
          type: 'skeleton',
        })
      );
      try {
        await nodeAxiosFirebase({
          t,
          method: 'POST',
          url: `coreSeqV3`,
          body: {
            name: item?.name || t('item'),
            tags: item?.tags || [],
            elementPath: singleElement.documentPath + '/items',
            structureId: blockStructureId,
            blockStructure: routeStructureId,
            tabIndex: activeIndex,
            blockIdentifiant: layout.i,
            lang: currentLangCode,
            data: {
              status: 0,
              targetId: 'users/' + singleElement?.targetId,
              dependencyId: singleElement.documentPath,
              targetProfileId: singleElement?.targetProfileId
                ? 'profiles/' + singleElement.targetProfileId
                : null,
              hookedWith:
                item?.hookedId ||
                `${type === 0 ? 'services' : 'articles'}/${item?.id || item?.objectID
                }`,
              quantity: quantity || 1,
              group: group || '',
              options: option
                ? [
                  {
                    name: option.name,
                    price: option.price,
                    tax1: option.tax1 ?? true,
                    tax2: option.tax2 ?? true,
                  },
                ]
                : [],
              price: item?.price || 0,
              cost: item?.cost || 0,
            },
          },
        });
        dispatch(
          setGeneralStatus({
            status: 'success',
            position: `add-item-${layout.i}`,
            type: 'skeleton',
          })
        );
        handleRefresh();
        toast.success(t('itemAddedSuccess'));
      } catch (error) {
        console.error('Error adding item:', error);
        dispatch(
          setGeneralStatus({
            status: 'error',
            position: `add-item-${layout.i}`,
            type: 'skeleton',
            error: error,
          })
        );
      }
    },
    [
      dispatch,
      t,
      layout.i,
      singleElement,
      blockStructureId,
      routeStructureId,
      activeIndex,
      currentLangCode,
      handleRefresh,
    ]
  );


  const openCreateElementModal = useCallback((defaultValues) => {
    if (!blockStructureId) {
      toast.error(t('cannotCreateElementNoStructure'));
      return;
    }

    if (layout?.type === 'mainCardItems' || layout?.type === 'secCardItems') {
      dispatch(
        modalActions.modalAddItem({
          isOpen: true,
          group: null,
          defaultTab: 0,
          onSelect: handleAddItemToList,
        })
      );
    } else {
      const match = layout?.match?.split(':')[0];

      dispatch(
        modalActions.modalElementCreation({
          isOpen: true,
          fromElement: true,
          structureId: layout?.structureId,
          defaultFields: defaultValues?.length > 0 ? defaultValues : [],
          elementPath: layout?.mainElement?.documentPath || null,
          dependencyHook: layout?.match || '',
          dependencyId: singleElement?.documentPath,
          targetProfileId:
            match === 'targetProfileId'
              ? layout?.mainElement?.targetProfileId
              : null,
          targetId: layout?.mainElement?.targetId,
          blockLayoutDetails: {
            blockIdentifiant: layout?.i,
            blockStructure: routeStructureId,
            tabIndex: activeIndex,
          },
          handleDone: handleRefresh,
        })
      );
    }
  }, [
    dispatch,
    t,
    layout?.type,
    layout?.match,
    layout?.i,
    blockStructureId,
    routeStructureId,
    singleElement,
    activeIndex,
    handleAddItemToList,
    handleRefresh,
  ]);

  const handleRefreshLinkedData = useCallback(async () => {
    if (!singleElement?.documentPath) {
      toast.error(t('cannotRefreshDataError'));
      return;
    }
    dispatch(
      setGeneralStatus({
        status: 'loading',
        position: `refresh-block-${layout.i}`,
        type: 'pulse',
      })
    );
    try {
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `coreSeqV3/refresh`,
        body: { elementPath: singleElement.documentPath },
      });
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: `refresh-block-${layout.i}`,
          type: 'pulse',
        })
      );
      handleRefresh();
      toast.success(t('linkedDataRefreshed'));
    } catch (error) {
      console.error('Error refreshing linked data:', error);
      dispatch(
        setGeneralStatus({
          status: 'error',
          position: `refresh-block-${layout.i}`,
          type: 'pulse',
          error: error,
        })
      );
    }
  }, [dispatch, t, layout.i, singleElement?.documentPath, handleRefresh]);

  const openEditElementDrawer = useCallback(() => {
    const primaryDataSource = blockContentData?.find(
      (item) => item.primaryData
    );
    const elementToEdit = primaryDataSource?.primaryData;

    if (elementToEdit?.elementPath && elementToEdit?.elementId) {
      dispatch(
        drawerActions.viewElement({
          isDrawerOpen: true,
          item: {
            id: elementToEdit.elementId,
            documentPath: elementToEdit.elementPath,
            name: elementToEdit.name,
          },
          handleDrawerClose: () => {
            dispatch(drawerActions.viewElement({ isDrawerOpen: false }));
            handleRefresh();
          },
          type: 'edit',
        })
      );
    } else {
      toast.info(t('noEditableElementFound'));
    }
  }, [dispatch, blockContentData, handleRefresh, t]);

  const handleAddField = useCallback(
    async (selectedField) => {
      if (!selectedField) return;

      const currentColumns = layout?.data || [];

      if (
        currentColumns.some((col) => col.structureValue === selectedField.value)
      ) {
        toast.info(t('fieldAlreadyExists'));
        return;
      }

      const newColumn = {
        value: selectedField.value,
        structureValue: selectedField.value,
        showLabel: true,
        action: 'none',
        markdown: false,
        size: 12,
        sub: {},
        structureId: blockStructureId,
        valueColor: 'primary',
        weight: 'regular',
        fieldType: selectedField.typeData || 'text', // Use typeData from field
        width: 100, // Default width? Needs adjustment based on layout type?
        ...(selectedField.typeData === 'date' && { dateFormat: 'DD/MM/YYYY' }),
        ...(selectedField.typeData === 'currency' && {
          currencySymbol: '$',
          decimalPlaces: 2,
        }),
      };

      if (selectedField.value === 'targetId')
        newColumn.value = newColumn.structureValue = 'targetName';
      else if (selectedField.value === 'targetProfileId')
        newColumn.value = newColumn.structureValue = 'targetProfileName';
      else if (selectedField.value === 'dependencyId')
        newColumn.value = newColumn.structureValue = 'dependencyName';
      else if (selectedField.value === 'assignedToId')
        newColumn.value = newColumn.structureValue = 'assignedToName';

      const updatedColumns = [...currentColumns, newColumn];

      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: `update-components-${layout.i}`,
          type: 'pulse',
        })
      );
      try {
        await nodeAxiosFirebase({
          t,
          method: 'POST',
          url: `business/structure`,
          body: {
            structureId: routeStructureId,
            type: 'components',
            lang: currentLangCode,
            data: {
              from: 'business',
              tabIndex: activeIndex,
              blockIdentifiant: layout.i,
              componentsData: updatedColumns,
            },
          },
        });
        dispatch(
          setGeneralStatus({
            status: 'success',
            position: `update-components-${layout.i}`,
            type: 'pulse',
          })
        );
        handleRefresh();
      } catch (error) {
        console.error('Error adding field:', error);
        dispatch(
          setGeneralStatus({
            status: 'error',
            position: `update-components-${layout.i}`,
            type: 'pulse',
            error: error,
          })
        );
      }
    },
    [
      dispatch,
      t,
      layout?.data,
      layout?.i,
      blockStructureId,
      routeStructureId,
      currentLangCode,
      activeIndex,
      handleRefresh,
    ]
  );

  const handleDeleteField = useCallback(
    async (indexToDelete) => {
      const currentColumns = layout?.data || [];
      if (indexToDelete < 0 || indexToDelete >= currentColumns.length) return;

      const updatedColumns = currentColumns.filter(
        (_, index) => index !== indexToDelete
      );

      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: `update-components-${layout.i}`,
          type: 'pulse',
        })
      );
      try {
        await nodeAxiosFirebase({
          t,
          method: 'POST',
          url: `business/structure`,
          body: {
            structureId: routeStructureId,
            type: 'components',
            lang: currentLangCode,
            data: {
              from: 'business',
              tabIndex: activeIndex,
              blockIdentifiant: layout.i,
              componentsData: updatedColumns,
            },
          },
        });
        dispatch(
          setGeneralStatus({
            status: 'success',
            position: `update-components-${layout.i}`,
            type: 'pulse',
          })
        );
        handleRefresh();
      } catch (error) {
        console.error('Error deleting field:', error);
        dispatch(
          setGeneralStatus({
            status: 'error',
            position: `update-components-${layout.i}`,
            type: 'pulse',
            error: error,
          })
        );
      }
    },
    [
      dispatch,
      t,
      layout?.data,
      layout?.i,
      routeStructureId,
      currentLangCode,
      activeIndex,
      handleRefresh,
    ]
  );

  // --- Helper Functions ---
  const shouldDisplayHeader = useMemo(() => {
    return (
      editMode ||
      !(layout?.contentType === 'content' && blockContentData?.length === 1)
    );
  }, [editMode, layout?.contentType, blockContentData?.length]);

  // --- Render ---
  const isLoadingBlock =
    currentStatus?.status === 'loading' &&
    currentStatus?.position === `edit-block-${layout?.i}`;

  return (
    <ErrorBoundary>
      <Paper
        elevation={0}
        sx={{
          backdropFilter: 'blur(5.8px)',
          background: 'transparent',
          borderRadius: '8px',
          border: editMode ? `0.5px solid ${borderColor}` : 'none',
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          animation: editMode
            ? `softShake 1.2s ease-in-out ${Math.random() * 1.5}s infinite`
            : 'none',
          '@keyframes softShake': {
            '0%, 100%': { transform: 'translateX(0)' },
            '25%': { transform: 'translateX(-0.5px)' },
            '50%': { transform: 'translateX(0.5px)' },
            '75%': { transform: 'translateX(-0.5px)' },
          },
        }}
      >
        {/* Block Header */}
        {shouldDisplayHeader && (
          <div
            onMouseEnter={() => editMode && setDrag(true)}
            onMouseLeave={() => editMode && setDrag(false)}
            style={{
              position: 'sticky', // Changed from 'relative' to 'sticky'
              top: 0,
              zIndex: 10,
              borderRadius: '8px 8px 0 0',
              background: headerBgColor,
              width: '100%',
              borderBottom: editMode ? `0.5px solid ${borderColor}` : 'none',
              minHeight: isTablet ? '30px' : '35px',
              maxHeight: isTablet ? '30px' : '35px',
              padding: '0 8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: editMode ? 'move' : 'default',
            }}
          >
            {/* Left side: Icon, Title, Dev Info */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                flexShrink: 1,
              }}
            >
              {isLoadingBlock ? (
                <Loading size={'small'} type={'circle'} sx={{ ml: 0.5 }} />
              ) : blockData.realtime ? (
                <Tooltip title={t('realtimeData')}>
                  <Icons.PodcastsOutlined
                    sx={{
                      fontSize: isTablet ? '14px' : '16px',
                      color: headerIconColor,
                      mr: 1,
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title={structureName || t('blockType')}>
                  <HeaderIcon
                    sx={{
                      fontSize: isTablet ? '14px' : '16px',
                      color: headerIconColor,
                      mr: 1,
                    }}
                  />
                </Tooltip>
              )}

              {editMode ? (
                <input
                  value={blockData.name}
                  onChange={(e) =>
                    handleChangeBlockSetting('name', e.target.value, 'header')
                  }
                  placeholder={t('blockNamePlaceholder')}
                  style={{
                    flexGrow: 1,
                    border: 'none',
                    outline: 'none',
                    background: 'transparent',
                    fontWeight: 600,
                    fontSize: isTablet ? '12px' : '14px',
                    color: headerTextColor,
                    padding: '2px 4px',
                    marginRight: '8px',
                  }}
                />
              ) : (
                <Typography
                  noWrap
                  sx={{
                    fontWeight: 600,
                    fontSize: isTablet ? '12px' : '14px',
                    color: headerTextColor,
                    mr: 1,
                  }}
                >
                  {blockData.name || t('newBlock')} {/* Added fallback name */}
                </Typography>
              )}

              {businessPreference?.preferences?.devMode && (
                <Typography
                  sx={{
                    color: '#888',
                    fontSize: '10px',
                    mr: 1,
                    whiteSpace: 'nowrap',
                  }}
                >
                  ({blockData.i} | {blockData.match} |{' '}
                  {blockData.structureId?.slice(0, 5)})
                </Typography>
              )}

              {blockData.infos?.display && !editMode && (
                <Tooltip title={blockData.infos.text || ''}>
                  <Icons.InfoOutlined
                    sx={{
                      fontSize: '14px',
                      color: headerIconColor,
                      cursor: 'default',
                    }}
                  />
                </Tooltip>
              )}
            </div>

            {/* Right side: Actions & Status Icons */}
            <div
              style={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}
            >
              {(blockData.createBtn || editMode) &&
                childrenComponent !== 'content' && (
                  <Tooltip title={t('createNewElement')}>
                    <IconButton
                      size="small"
                      onClick={
                        editMode
                          ? handleToggleCreateBtn
                          : openCreateElementModal
                      }
                      sx={{ p: '4px' }}
                    >
                      <Icons.AddOutlined
                        sx={{
                          fontSize: '18px',
                          color: editMode
                            ? blockData.createBtn
                              ? headerIconColor
                              : '#cccccc'
                            : headerIconColor,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}

              {(blockData.editBtn || editMode) &&
                childrenComponent === 'content' && (
                  <Tooltip title={t('editElement')}>
                    <IconButton
                      size="small"
                      onClick={
                        editMode ? handleToggleEditBtn : openEditElementDrawer
                      }
                      sx={{ p: '4px' }}
                    >
                      <Icons.EditOutlined
                        sx={{
                          fontSize: '16px',
                          color: editMode
                            ? blockData.editBtn
                              ? headerIconColor
                              : '#cccccc'
                            : headerIconColor,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}

              {(blockData.refreshBtn || editMode) &&
                childrenComponent === 'content' &&
                layout.structureId !== routeStructureId &&
                moduleName !== 'contacts' && (
                  <Tooltip title={t('refreshTargets')}>
                    <IconButton
                      size="small"
                      onClick={
                        editMode
                          ? handleToggleRefreshBtn
                          : handleRefreshLinkedData
                      }
                      sx={{ p: '4px' }}
                    >
                      <Icons.CachedOutlined
                        sx={{
                          fontSize: '16px',
                          color: editMode
                            ? blockData.refreshBtn
                              ? headerIconColor
                              : '#cccccc'
                            : headerIconColor,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}

              {blockData.filter?.operator && !editMode && (
                <Tooltip title={t('filterActive')}>
                  <span>
                    <IconButton size="small" disabled sx={{ p: '4px' }}>
                      <Icons.FilterListOutlined
                        sx={{ fontSize: '16px', color: headerIconColor }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {blockData.order?.direction && !editMode && (
                <Tooltip
                  title={`${t(blockData.order.direction + 'Order')} - ${structureFields.find(
                    (f) => f.value === blockData.order.field
                  )?.name ||
                    t(blockData.order.field) ||
                    ''
                    }`}
                >
                  <span>
                    <IconButton size="small" disabled sx={{ p: '4px' }}>
                      {blockData.order.direction === 'asc' ? (
                        <Icons.ArrowUpward
                          sx={{ fontSize: '16px', color: headerIconColor }}
                        />
                      ) : (
                        <Icons.ArrowDownward
                          sx={{ fontSize: '16px', color: headerIconColor }}
                        />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              {blockData.changesInProgress?.some(
                (c) => c.tab === activeIndex && c.block === layout?.i
              ) &&
                !editMode && (
                  <Tooltip title={t('changesInProgress')}>
                    <Icons.HourglassBottom
                      sx={{ fontSize: '16px', color: headerIconColor, ml: 0.5 }}
                    />
                  </Tooltip>
                )}

              {editMode && (
                <>
                  <Tooltip
                    title={t('blockSettings')}
                    key={`${layout.i}-settings`}
                  >
                    <IconButton size="small" sx={{ p: '4px' }}>
                      <SettingsPopper
                        onSelect={handleUpdateBlock}
                        iden={layout.i}
                        blockData={layout}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={t('blockTypeAndData')}
                    key={`${layout.i}-layout`}
                  >
                    <IconButton size="small" sx={{ p: '4px' }}>
                      <ComponentPopper
                        onSelect={handleUpdateBlock}
                        iden={layout.i}
                        blockData={layout}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('deleteBlock')} key={`${layout.i}-delete`}>
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveBlock(layout.i)}
                      sx={{ p: '4px' }}
                    >
                      <Icons.DeleteOutlined
                        sx={{
                          fontSize: '16px',
                          color: theme.palette.error.main,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        )}

        {/* Block Content */}
        <div
          style={{
            flexGrow: 1, // Takes remaining vertical space
            overflow: 'hidden', // Let children handle scroll
            position: 'relative',
            background:
              !shouldDisplayHeader || headerBgColor === 'transparent'
                ? bgColor
                : 'transparent',
            border: `1px solid ${borderColor}`,
            borderRadius: editMode ? '0 0 8px 8px' : '8px',
            // Padding adjusted: Applied within child or PerfectScrollbar if used
            padding: 0, // Remove padding here
            display: !shouldDisplayHeader ? 'flex' : 'flex', // Use flex for both cases now
            flexDirection: 'column', // Ensure column direction
            justifyContent: !shouldDisplayHeader ? 'flex-start' : undefined,
            alignItems: !shouldDisplayHeader ? 'flex-start' : undefined,
          }}
        >
          {/* Restricted Overlay */}
          {layout?.isRestricted &&
            !currentUser?.isAdmin &&
            !blockData.groups?.some((g) =>
              currentUser?.groups?.includes(g)
            ) && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: 20,
                  width: '100%',
                  height: '100%',
                  backdropFilter: 'blur(3px)',
                  backgroundColor: isDarkMode ? '#1c1c1c95' : '#FFFFFF95',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: shouldDisplayHeader ? '0 0 8px 8px' : '8px',
                }}
              >
                <Icons.LockOutlined fontSize="large" color="warning" />
                <GeneralText
                  text={t('restrictedAccess')}
                  fontSize="14px"
                  fontWeight="medium"
                  primary={true}
                  sx={{ mt: 1 }}
                />
                <GeneralText
                  text={t('restrictedAccessMsg')}
                  fontSize="12px"
                  primary={true}
                />
              </div>
            )}

          {/* Empty State (for lists primarily) */}
          {childrenComponent === 'list' &&
            blockContentData?.length === 0 &&
            !isLoadingBlock ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                padding: '10px',
                textAlign: 'center',
              }}
            >
              <Lottie
                options={defaultLottieOptions}
                height={isTablet ? 40 : 60}
                width={isTablet ? 50 : 80}
              />
              <Typography
                variant="h6"
                sx={{ fontSize: isTablet ? '11px' : '13px', mt: 1 }}
              >
                {t('emptyListTitle')}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: isTablet ? '9px' : '10px',
                  mt: 0.5,
                  color: 'text.secondary',
                }}
              >
                {t('emptyListDescription')}
              </Typography>
            </div>
          ) : (
            /* Block Content Area (Handles scroll conditionally) */
            <div style={{ flexGrow: 1, overflow: 'hidden', width: '100%' }}>
              {childrenComponent === 'action' ? (
                <BlockLayoutScroll
                  fontColor={fontColor}
                  elementDetails={{ ...elementDetails, data: blockContentData }}
                  layout={layout}
                  blockWidth={'100%'}
                  structure={structure}
                  editMode={editMode}
                  childrenComponent={childrenComponent}
                  heightPercentage={heightPercentage}
                  handleAddItem={handleAddItemToList}
                  handleDelete={handleDeleteField}
                  handleCreateNew={openCreateElementModal}
                  handleRefresh={handleRefresh}
                  fromList={fromList}
                  activeIndex={activeIndex}
                  publicMode={publicMode}
                />
              ) : (
                <PerfectScrollbar
                  options={{ wheelPropagation: false, suppressScrollX: true }}
                  style={{
                    height: '100%',
                    width: '100%',
                    padding:
                      noPadding || layout?.contentType === 'action'
                        ? 0
                        : shouldDisplayHeader
                          ? '8px'
                          : '12px',
                  }} // Apply padding here
                >
                  <BlockLayoutScroll
                    fontColor={fontColor}
                    elementDetails={{
                      ...elementDetails,
                      data: blockContentData,
                    }}
                    layout={layout}
                    blockWidth={'100%'}
                    structure={structure}
                    editMode={editMode}
                    childrenComponent={childrenComponent}
                    heightPercentage={heightPercentage}
                    handleAddItem={handleAddItemToList}
                    handleDelete={handleDeleteField}
                    handleCreateNew={openCreateElementModal}
                    handleRefresh={handleRefresh}
                    fromList={fromList}
                    activeIndex={activeIndex}
                    publicMode={publicMode}
                  />
                </PerfectScrollbar>
              )}
            </div>
          )}

          {currentUser?.activeBusiness?.role !== 'EMPLOYEE' &&
            currentUser?.activeBusiness?.role !== 'VIEWER' &&
            !editMode &&
            childrenComponent === 'content' &&
            availableFields.length > 0 && (
              <div
                style={{
                  padding: '8px',
                  textAlign: 'center',
                  flexShrink: 0
                }}
              >
                <FieldsPopper
                  onSelect={handleAddField}
                  text={t('addField')}
                  fields={availableFields}
                  buttonVariant="outlined"
                  buttonSize="small"
                  existingFields={elementDetails?.data?.map(c => c.value || c.structureValue)}
                />
              </div>
            )}
        </div>
      </Paper>
    </ErrorBoundary>
  );
};

export default BlockLayout;
