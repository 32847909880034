import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Paper,
  Divider,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SettingsOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';

import GeneralText from '../../stories/general-components/GeneralText';

import Button from '../../stories/general-components/Button';
import Select from '../../stories/general-components/Select';

const SettingsPopper = ({ onSelect, iden, blockData }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';


  const [open, setOpen] = useState(false);
  const [settingsBlock, setSettingsBlock] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const handleToggle = (event) => {
    event.preventDefault();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = () => {
    const updatedData = {
      ...settingsBlock,
      groups: selectedGroups,
    };

    onSelect(updatedData, iden);
    setOpen(false);
  };

  const handleGroupToggle = (group) => {
    setSelectedGroups((prevSelected) =>
      prevSelected.includes(group)
        ? prevSelected.filter((g) => g !== group)
        : [...prevSelected, group]
    );
  };



  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );

  const groupsList = businessPreference?.groups?.map((group) => group?.identifiant);

  const structureFields =
    businessStructure?.structures?.find((s) => s.id === blockData?.structureId)
      ?.fields || [];

  const mergedFields = [
    ...(structureFields || []),
    {
      name: t('timeStamp'),
      value: 'timeStamp',
    },
    {
      name: t('lastUpdate'),
      value: 'lastUpdate',
    },
  ];

  useEffect(() => {
    if (blockData) {
      setSettingsBlock((prev) => ({
        ...(prev || {}),
        ...(blockData?.header || {}),
        action: blockData?.action || 'none',
      }));

    }
  }, [blockData]);



  return (
    <>
      <SettingsOutlined
        htmlColor={isDarkMode ? '#F7f7f7' : '#696969'}
        onClick={handleToggle}
        sx={{ fontSize: '14px', cursor: 'pointer', ml: 1 }}
      />
      <div >
        <Drawer anchor="right" open={open} onClose={handleClose}>
          <Paper
            elevation={0}
            sx={{ width: '320px', padding: 2, marginBottom: '180px' }}
          >
            <GeneralText
              primary={true}
              size="bold"
              fontSize="12px"
              text={t('settingsBlock')}
              classNameComponent="mb-2 px-4"
            />
            <GeneralText
              primary={true}
              size="bold"
              fontSize="10px"
              text={t('groups')}
              classNameComponent="mb-2 px-4 mt-3"
            />
            <Divider component="div" />

            <div className="mt-3 px-4">
              {groupsList?.map((group) => (
                <FormControlLabel
                  key={group}
                  control={
                    <Checkbox
                      checked={selectedGroups?.includes(group)}
                      onChange={() => handleGroupToggle(group)}
                    />
                  }
                  label={businessPreference?.groups?.find(
                    (g) => g?.identifiant === group
                  )?.name}
                />
              ))}
            </div>

            <GeneralText
              primary={true}
              size="bold"
              fontSize="10px"
              text={t('actionOnClick')}
              classNameComponent="mb-2 px-4 mt-4"
            />
            <Divider component="div" />

            <div className="mt-2 px-4">
              <Select
                label={t('action')}
                noEmpty
                value={settingsBlock?.action}
                selections={[
                  {
                    label: t('none'),
                    id: 'none',
                    value: 'none',
                  },
                  {
                    label: t('edit'),
                    id: 'edit',
                    value: 'edit',
                  },
                  {
                    label: t('openParent'),
                    id: 'openParent',
                    value: 'openParent',
                  },
                  {
                    label:
                      blockData?.contentType
                        === 'list'
                        ? t('quickview')
                        : t('copy'),
                    id:
                      blockData?.contentType === 'list'
                        ? 'quickview'
                        : 'copy',
                    value:
                      blockData?.contentType === 'list'
                        ? 'quickview'
                        : 'copy',
                  },
                  blockData?.contentType === 'list' && (
                    {
                      label: t('open'),
                      id: 'navigate',
                      value: 'navigate',
                    }
                  )
                ]}
                onChange={(e, value) =>
                  setSettingsBlock({
                    ...blockData,
                    action: value,
                  })
                }
                fullWidth
              />
            </div>

            <GeneralText
              primary={true}
              size="bold"
              fontSize="10px"
              text={t('filter')}
              classNameComponent="mb-2 px-4 mt-4"
            />
            <Divider component="div" />
            <div className="mt-2 px-3">

              <div>
                <Select
                  label={t('field')}
                  noEmpty
                  value={blockData?.filter?.field}
                  selections={[
                    { label: t('isDone'), value: 'isDone', id: 'isDone' },

                  ]}
                  onChange={(e, value) =>
                    setSettingsBlock({
                      ...blockData,
                      filter: {
                        ...blockData?.filter,
                        field: value,
                      },
                    })
                  }
                  fullWidth
                />
              </div>
              <div>
                <Select
                  label={t('operator')}
                  value={blockData?.filter?.operator}
                  selections={[
                    { label: '==', value: '==', id: '==' },
                    { label: '!=', value: '!=', id: '!=' },
                    { label: '>', value: '>', id: '>' },
                    { label: '>=', value: '>=', id: '>=' },
                    { label: '<', value: '<', id: '<' },
                    { label: '<=', value: '<=', id: '<=' },
                  ]}
                  onChange={(e, value) =>
                    setSettingsBlock({
                      ...blockData,
                      filter: {
                        ...blockData?.filter,
                        operator: value,
                      },
                    })
                  }
                  fullWidth
                />
              </div>
              <div className='mt-3'>
                {blockData?.filter?.field === 'isDone' ? (
                  <Select
                    label={t('value')}
                    noEmpty
                    value={blockData?.filter?.value}
                    selections={[
                      { label: t('yes'), value: true, id: true },
                      { label: t('no'), value: false, id: false },
                    ]}
                    onChange={(e, value) =>
                      setSettingsBlock({
                        ...blockData,
                        filter: {
                          ...blockData?.filter,
                          value: value,
                        },
                      })
                    }
                    fullWidth
                  />
                ) : (
                  <TextField
                    label={t('value')}
                    value={blockData?.filter?.value}
                    onChange={(e) =>
                      setSettingsBlock({
                        ...blockData,
                        filter: {
                          ...blockData?.filter,
                          value: e.target.value,
                        },
                      })
                    }
                    fullWidth
                  />
                )}
              </div>

            </div>
            <GeneralText
              primary={true}
              size="bold"
              fontSize="10px"
              text={t('order')}
              classNameComponent="mb-2 px-4 mt-4"
            />
            <Divider component="div" />
            <div className="mt-3 px-3">


              <div >
                <Select
                  label={t('fields')}
                  value={blockData?.order?.field}
                  selections={mergedFields?.map((field) => ({
                    label: field?.name,
                    value: field?.value,
                    id: field?.value,
                  }))}
                  onChange={(e, value) =>
                    setSettingsBlock({
                      ...blockData,
                      order: {
                        ...blockData?.order,
                        field: value,
                      },
                    })
                  }
                  fullWidth
                />
              </div>
              <div >
                <Select
                  label={t('direction')}
                  value={blockData?.order?.direction}
                  selections={[
                    { label: t('asc'), value: 'asc', id: 'asc' },
                    {
                      label: t('descen'),
                      value: 'desc',
                      id: 'desc',
                    },
                  ]}
                  onChange={(e, value) =>
                    setSettingsBlock({
                      ...blockData,
                      order: {
                        ...blockData?.order,
                        direction: value,
                      },
                    })
                  }
                  fullWidth
                />
              </div>


            </div>
            {/* <GeneralText
              primary={true}
              size="bold"
              fontSize="10px"
              text={t('tabs')}
              classNameComponent="mb-2 px-4 mt-4"
            />
            <Divider component="div" />
            <div className="mt-3 px-3"></div> */}
            <div className='px-4 mt-5'><Button label={t('save')} onClick={handleSelect} fullWidth /></div>
          </Paper>
        </Drawer>
      </div>
    </>
  );
};

export default SettingsPopper;
