import * as React from 'react';
const PulseIcon = ({ mainColor }) => (
  <svg
    id="Calque_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39.78 39.29"
  >
    <g id="Calque_1-2">
      <rect
        fill={mainColor}
        opacity={0.69}
        y={17.2}
        width={22.09}
        height={22.09}
        rx={5.34}
        ry={5.34}
      />
      <rect
        fill={mainColor}
        x={17.7}
        width={22.09}
        height={39.29}
        rx={5.34}
        ry={5.34}
      />
    </g>
  </svg>
);
export default PulseIcon;
