import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import * as Icons from '@mui/icons-material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SettingsOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import GeneralText from '../../stories/general-components/GeneralText';
import Button from '../../stories/general-components/Button';
import NodeAI from '../../components/NodeAI';
import { setNodeAssistant } from '../../redux/actions-v2/coreAction';

const ModalLarge = ({
  isOpen,
  modalCloseHandler,
  title,
  subTitle,
  icon,
  disabledNextStep,
  fullWidth,
  displaySettings,
  disabledPrim,
  fullHeight,
  handleSec,
  maxWidth,
  handlePrim,
  children,
  labels,
  isCentered
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDarkMode = theme.palette.mode === 'dark';

  const currentUser = useSelector((state) => state.core.user);
  const businessPreference = useSelector((state) => state.core.businessData);
  const alertMsg = useSelector((state) => state.core.nodeAssistant);
  const IconComponent = Icons?.[icon];
  const [animationKey, setAnimationKey] = useState(0);

  const handleCreateClick = () => {
    if (handlePrim) handlePrim();
  };

  useEffect(() => {
    setAnimationKey((prevKey) => prevKey + 1);
  }, [alertMsg?.text]);

  useEffect(() => {
    if (isOpen) {
      dispatch(setNodeAssistant(t('')));
    }
  }, [isOpen, title]);

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth || 'md'}
        open={isOpen}
        sx={{
          '& .MuiDialog-paper': {
            height: fullHeight ? '80%' : null,
            borderRadius: '10px',
            width: maxWidth === 'xl' ? null : '100%',
            maxWidth: maxWidth === 'lg' ? '1300px' : maxWidth === 'xl' ? null : '850px',
            marginBottom: '135px',

          },
        }}
        onClose={modalCloseHandler}
      >
        <DialogContent
          sx={{
            display: isCentered ? 'none' : 'block',
            maxHeight: fullHeight ? '90vh' : '68vh',
            padding: '25px',
            overflowY: 'auto',
          }}
        >
          {children}
        </DialogContent>
      </Dialog>
      <motion.div
        initial={{ bottom: '0', left: '0', width: '90%', height: '90px' }}
        animate={
          isCentered
            ? {
              bottom: '15%',
              left: '45%',
              translateX: '-50%',
              translateY: '-30%',
              width: '40%',
              height: '400px',
            }
            : {
              bottom: '0',
              left: '0',
              width: '90%',
              height: '90px',
            }
        }
        transition={{ type: 'spring', stiffness: 200, damping: 20 }}
        style={{
          position: 'fixed',
          margin: '2% 5%',
          borderRadius: '10px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
          backgroundColor: isDarkMode ? '#1a1a1a' : 'white',
          zIndex: 1400,
          padding: '15px',
          display: isOpen ? 'block' : 'none',
        }}
      >
        <div>
          {isCentered ? (
            <div
              style={{
                height: '375px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <>{children}</>
            </div>
          ) : (
            <DialogTitle
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div style={{ display: 'flex', right: 0 }}>
                {icon && (
                  <IconComponent
                    htmlColor={businessPreference?.mainColor}
                    style={{ marginTop: '-1px' }}
                  />
                )}{' '}
                <div>
                  <GeneralText
                    text={title}
                    size="bold"
                    fontSize={isTablet ? '14px' : '17px'}
                    primary={false}
                    color={businessPreference?.mainColor}
                    classNameComponent="px-2"
                  />
                  <div style={{ marginTop: isTablet ? '-6px' : '-8px' }}>
                    <GeneralText
                      text={subTitle}
                      size="regular"
                      fontSize={isTablet ? '9px' : '10px'}
                      primary={false}
                      color={'#69696970'}
                      classNameComponent="px-2"
                    />
                  </div>
                </div>
                <div
                  className="mx-3"
                  style={{ display: 'flex', right: 0, marginTop: '-5px' }}
                >
                  {displaySettings &&
                    (currentUser?.activeBusiness?.role === 'SUPER-ADMIN' ||
                      currentUser?.activeBusiness?.role === 'ADMIN') && (
                      <IconButton size="medium" onClick={displaySettings}>
                        <SettingsOutlined />
                      </IconButton>
                    )}
                </div>
              </div>
              {(businessPreference?.formula?.toUpperCase() === 'RISE' ||
                businessPreference?.formula?.toUpperCase() === 'TRIAL') && (
                  <div
                    style={{
                      marginTop: '-7px',
                    }}
                    className="d-flex align-c middle-content"
                  >
                    <div
                      className="mx-3"
                      style={{
                        width: 17,
                        margin: 1,
                        transform: 'rotate(45deg)',
                      }}
                    >
                      <motion.div
                        animate={{ rotate: [0, 360, 0] }}
                        key={animationKey}
                        transition={{
                          duration: 2,
                          ease: 'easeInOut',
                        }}
                        style={{
                          display: 'inline-block',
                        }}
                      >
                        <NodeAI
                          primaryColor={
                            alertMsg?.color
                              ? alertMsg?.color
                              : businessPreference?.mainColor
                          }
                          secondaryColor={
                            alertMsg?.color
                              ? alertMsg?.color + '50'
                              : businessPreference?.secColor
                          }
                        />
                      </motion.div>
                    </div>
                    <motion.div
                      key={animationKey}
                      style={{
                        backgroundColor: alertMsg?.color
                          ? alertMsg?.color + '10'
                          : businessPreference?.mainColor + '10',
                        borderRadius: '10px 10px 10px 0px',
                        display: 'inline-flex',
                        color: alertMsg?.color
                          ? alertMsg?.color
                          : businessPreference?.mainColor || '#000',
                        fontSize: '13px',
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                      }}
                      initial={{ width: 50 }}
                      animate={{ width: 'auto' }}
                      transition={{
                        duration: 3,
                        ease: 'easeInOut',
                      }}
                    >
                      <div
                        style={{
                          margin: '4px 20px',
                        }}
                      >
                        {alertMsg?.text?.split('').map((char, index) => (
                          <motion.span
                            key={`${animationKey}-${index}`}
                            custom={index}
                            initial="hidden"
                            animate="visible"
                          >
                            {char === ' ' ? '\u00A0' : char}
                          </motion.span>
                        ))}
                      </div>
                    </motion.div>
                  </div>
                )}
              <div style={{ marginTop: '-3px' }} className="d-flex">
                {handleSec && (
                  <Button
                    variant="text"
                    onClick={handleSec}
                    label={labels?.secondary || t('cancel')}
                    fullWidth
                    buttonSx={{
                      minWidth: '150px',
                      marginLeft: 2,
                      lineHeight: 1,
                    }}
                  />
                )}
                {handlePrim && (
                  <Button
                    onClick={handleCreateClick}
                    label={labels?.primary || t('create')}
                    fullWidth
                    disabled={disabledPrim}
                    buttonSx={{
                      minWidth: '200px',
                      marginLeft: 2,
                    }}
                  />
                )}
              </div>
            </DialogTitle>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default ModalLarge;
