import React, { } from 'react';
import SiteLayout from '../SiteLayout';
import HeaderEntreprise from '../components/bos/HeaderEntreprise';
import BlocsEntreprise from '../components/bos/BlocsEntreprise';
import SplitEntreprise from '../components/bos/SplitEntreprise';
import EventsEntreprise from '../components/bos/EventsEntreprise';
import { useTranslation } from 'react-i18next';

const EntrepriseBos = () => {
  const { t } = useTranslation();
  const pageCode = 'entrepriseBOS';
  return (
    <SiteLayout
      helmetData={{
        name: t(pageCode + 'SEO'),
        description: t(pageCode + 'SEODesc'),
      }}
    >
      <div className="mt-5 mb-5">
        <HeaderEntreprise translationCode={pageCode + 'Header'} />
      </div>
      <div className="mt-5 mb-5">
        <BlocsEntreprise translationCode={pageCode + 'Blocs'} />
      </div>
      <div className="mt-5 mb-5">
        <SplitEntreprise translationCode={pageCode + 'Split'} />
      </div>
      <div className="mt-5 mb-5">
        <EventsEntreprise translationCode={pageCode + 'Events'} />
      </div>
    </SiteLayout>
  );
};

export default EntrepriseBos;
