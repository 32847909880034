import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import OnboardLayout from './OnboardLayout';
import { Button } from '@mui/material';
import { setOnboardData } from '../../redux/actions-v2/coreAction';
import GeneralText from '../../stories/general-components/GeneralText';
import { CalendarMonthOutlined, CheckCircleOutlined } from '@mui/icons-material';


const Step5 = () => {
    const dispatch = useDispatch();
    const { t, } = useTranslation();

    const pageCode = 'step5Onboard';


    const onboardData = useSelector((state) => state.core.onboardData);


    return (

        <OnboardLayout stepTitle={t(pageCode + 'Title')} stepDesc={t(pageCode + 'Desc')} restrictNext={false}>


            <div className='row'> <div className='col-6 mt-5'> <GeneralText
                text={t(pageCode + 'Text')}
                primary={true}
                fontSize="18px"
                size="medium"
                classNameComponent='mt-3'
            />

                <div className='mt-3 mb-5'>
                    <div className='d-flex mt-2'>
                        <CheckCircleOutlined sx={{ mr: 1, fontSize: '14px' }} /> <GeneralText text={t('step5Text1')} primary={true} fontSize="13px" size="regular" />
                    </div>
                    <div className='d-flex mt-2'>
                        <CheckCircleOutlined sx={{ mr: 1, fontSize: '14px' }} /> <GeneralText text={t('step5Text2')} primary={true} fontSize="13px" size="regular" />
                    </div>
                    <div className='d-flex mt-2'>
                        <CheckCircleOutlined sx={{ mr: 1, fontSize: '14px' }} /> <GeneralText text={t('step5Text3')} primary={true} fontSize="13px" size="regular" />
                    </div>

                </div>
                <Button
                    variant="text"
                    color="primary"
                    size="large"
                    disableElevation
                    sx={{
                        p: onboardData?.planWithExpert ? 1.6 : 0,
                        textTransform: 'none',
                        marginTop: '100px', color: onboardData?.planWithExpert ? '#a5c70e' : '#000000',
                        textAlign: 'left',
                        lineHeight: '1',
                        backgroundColor: onboardData?.planWithExpert ? '#a5c70e10' : 'transparent',
                        borderRadius: '10px',
                        borderColor: onboardData?.planWithExpert ? '#a5c70e' : 'transparent',
                        border: onboardData?.planWithExpert ? '1px solid' : 'none'
                    }}
                    className='mt-3'
                    onClick={() => {
                        dispatch(setOnboardData({ ...onboardData, planWithExpert: onboardData?.planWithExpert === true ? false : true }));
                    }}
                >
                    {!onboardData?.planWithExpert && <CalendarMonthOutlined sx={{ mr: 1 }} />}
                    {onboardData?.planWithExpert && <CheckCircleOutlined sx={{ mr: 1 }} />}
                    {onboardData?.planWithExpert ? t('weWillContactYou') : t('planWithExpert')}
                </Button> </div><div className='col-6 py-4'>    <img
                    src='/assets/website/v3/onboard.jpeg'
                    className='img-fluid'
                    alt='step5'
                    style={{ borderRadius: '14px' }}
                    width='220px'
                /></div></div>




        </OnboardLayout >

    );
};

export default Step5;
