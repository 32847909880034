import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import Typo from '../Typo';

const EventsEntreprise = ({ translationCode }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';
  const localStorageColor = localStorage.getItem('mainColor');
  const localStorageSecColor = localStorage.getItem('secColor');
  const mainColor =
    theme.palette.primary.main || localStorageColor || '#1604DD';
  const secColor =
    theme.palette.secondary.main || localStorageSecColor || '#000000';

  const darkMainColor = chroma(mainColor).darken(1.2).hex();

  return (
    <div
      style={{
        marginTop: isMobile ? '120px' : '200px',
      }}
      className="container"
    >
      <div className={isMobile ? 'row px-4' : 'row'}>
        <div className="col-md-6 col-12">
          <Typo
            variant="h2"
            text={t(translationCode + 'Title')}
            className="mt-4"
            bold
          />

          <Typo
            variant="body2"
            text={t(translationCode + 'Desc')}
            className="mt-4"
            sx={{ maxWidth: '490px' }}
          />
          <Button
            variant="contained"
            color="primary"
            disableElevation
            style={{
              marginTop: '40px',
              marginBottom: '40px',
              textTransform: 'none',
              borderRadius: '50px',
              padding: '8px 20px',
              backgroundColor: darkMainColor,
            }}
          >
            {t(translationCode + 'CTA')}
          </Button>
        </div>
        <div className="col-md-6 col-12">
          <img src="/assets/website/v3/Events.svg" />
        </div>
      </div>
    </div>
  );
};

export default EventsEntreprise;
