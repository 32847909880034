import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import * as Icons from '@mui/icons-material';
import Checkbox from '../stories/general-components/Checkbox';
import TextField from '../stories/general-components/TextField';
import Select from '../stories/general-components/Select';
import DrawerSide from '../stories/layout-components/DrawerSide';
import { fetchBusinessData } from '../redux/actions-v2/coreAction';
import { IconButton, Tooltip } from '@mui/material';
import GeneralText from '../stories/general-components/GeneralText';
import nodeAxiosFirebase from '../utils/nodeAxiosFirebase';
import FieldEditor from '../components/@generalComponents/FieldEditor';
import Button from '../stories/general-components/Button';
import Blocks from '../stories/layout-components/Block';
import IconSelector from '../components/@generalComponents/IconSelector';

const StructureSidebar = ({ closeSidebar, structureId, isDrawerOpen }) => {
  const { t, i18n } = useTranslation();
  const currentLangCode = i18n.language;
  const dispatch = useDispatch();

  const [selectedStructure, setSelectedStructure] = useState({});
  const [statusFields, setStatusFields] = useState([]);
  const [isOpenIcon, setIsOpenIcon] = useState(false);
  const [icon, setIcon] = useState('');
  const [fields, setFields] = useState([]);
  const [currentSelection, setCurrentSelection] = useState('settings');

  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );

  const businessStructures = businessStructure?.structures;
  const currentStrucure = businessStructures.find((s) => s.id === structureId);

  const IconStructure = Icons[selectedStructure?.icon || 'HelpOutline'];

  const handleSaveField = (field, value) => {
    setSelectedStructure((currentPreference) => ({
      ...currentPreference,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    try {
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/structure`,
        showLoading: true,
        body: {
          type: 'fields',
          lang: currentLangCode,
          structureId: selectedStructure?.id,
          data: {
            fieldsData: fields,
          },
        },
      });

      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/structure`,
        body: {
          type: 'general',
          lang: currentLangCode,
          structureId: selectedStructure?.id,
          data: {
            name: selectedStructure?.name,
            icon: selectedStructure?.icon,
            attribute1: selectedStructure?.attribute1,
            attribute2: selectedStructure?.attribute2,
            attribute3: selectedStructure?.attribute3,
            attribute4: selectedStructure?.attribute4,
            isPublic: selectedStructure?.isPublic,
            print: selectedStructure?.print,
            email: selectedStructure?.email,
            edit: selectedStructure?.edit,
            delete: selectedStructure?.delete,
            creation: selectedStructure?.creation,
            favorite: selectedStructure?.favorite,
            tags: selectedStructure?.tags,
            convert: selectedStructure?.convert,
            reference: selectedStructure?.reference,
            referenceField: selectedStructure?.referenceField,
            flowOnApprove: selectedStructure?.flowOnApprove,
            approvalTerm: selectedStructure?.approvalTerm,
            statusDone: selectedStructure?.statusDone,
            statusPending: selectedStructure?.statusPending,
            flow: selectedStructure?.flow,
            share: selectedStructure?.share,
            trackActivity: selectedStructure?.trackActivity,
            accessCodeRequired: selectedStructure?.accessCodeRequired,
            view: selectedStructure?.view,
            displayLogo: selectedStructure?.displayLogo ?? true,
            viewForm: selectedStructure?.viewForm || 'default',
            layout: selectedStructure?.layout || 'fw',
            bgImage: selectedStructure?.bgImage,
            approvalMethod: selectedStructure?.approvalMethod,
            btnStart: selectedStructure?.btnStart,
            intro: selectedStructure?.intro,
            bottomText: selectedStructure?.bottomText,
            nps: selectedStructure?.nps,
            npsField: selectedStructure?.npsField,
            npsDays: selectedStructure?.npsDays,
            npsEmail: selectedStructure?.npsEmail,
            npsCommentMsg: selectedStructure?.npsCommentMsg,
            npsTemplate: selectedStructure?.npsTemplate,
            matchedAttributes: selectedStructure?.matchedAttributes,
            matchedCollection: selectedStructure?.matchedCollection,
            matchedValue: selectedStructure?.matchedValue,
          },
        },
      });
      closeSidebar();
      dispatch(fetchBusinessData(businessPreference?.id, t));
    } catch (error) {
      console.error('Error saving fields:', error);
    }
  };

  const handleActivatePublic = async () => {
    try {
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/structure`,
        body: {
          type: 'general',
          lang: currentLangCode,
          structureId: selectedStructure?.id,
          data: {
            isPublic: true,
          },
        },
      });
      setSelectedStructure((currentPreference) => ({
        ...currentPreference,
        isPublic: true,
      }));
      dispatch(fetchBusinessData(businessPreference?.id, t));
    } catch (error) {
      console.error('Error saving fields:', error);
    }
  };

  useEffect(() => {
    const detailedStruc = currentStrucure;

    if (detailedStruc) {
      setSelectedStructure({
        ...detailedStruc,
        isPublic: detailedStruc?.element?.isPublic,
        matchedAttributes: detailedStruc?.crossPreferences?.matchedAttributes,
        matchedCollection: detailedStruc?.crossPreferences?.matchedCollection,
        matchedValue: detailedStruc?.crossPreferences?.matchedValue,
        print: detailedStruc?.element?.preferences?.print,
        email: detailedStruc?.element?.preferences?.email,
        edit: detailedStruc?.element?.preferences?.edit,
        delete: detailedStruc?.element?.preferences?.delete,
        creation: detailedStruc?.element?.preferences?.creation,
        favorite: detailedStruc?.element?.preferences?.favorite,
        tags: detailedStruc?.element?.preferences?.tags,
        approvalMethod: detailedStruc?.element?.preferences?.approvalMethod,
        convert: detailedStruc?.element?.preferences?.convert,
        reference: detailedStruc?.element?.preferences?.reference,
        referenceField: detailedStruc?.element?.preferences?.referenceField,
        flowOnApprove: detailedStruc?.element?.preferences?.flowOnApprove,
        approvalTerm: detailedStruc?.element?.preferences?.approvalTerm,
        statusDone: detailedStruc?.element?.preferences?.statusDone,
        statusPending: detailedStruc?.element?.preferences?.statusPending,
        flow: detailedStruc?.element?.preferences?.flow,
        share: detailedStruc?.element?.preferences?.share,
        billField: detailedStruc?.element?.preferences?.billField,
        shipField: detailedStruc?.element?.preferences?.shipField,
        trackActivity: detailedStruc?.element?.publicPage?.trackActivity,
        accessCodeRequired:
          detailedStruc?.element?.publicPage?.accessCodeRequired,
        view: detailedStruc?.element?.publicPage?.view || 'default',
        viewForm: detailedStruc?.element?.publicForm?.view || 'default',
        layout: detailedStruc?.element?.publicForm?.layout,
        displayLogo: detailedStruc?.element?.publicForm?.displayLogo ?? true,
        bgImage: detailedStruc?.element?.publicForm?.bgImage,
        btnStart:
          detailedStruc?.element?.publicForm?.['btnStart_' + currentLangCode],
        intro: detailedStruc?.element?.publicForm?.['intro_' + currentLangCode],
        bottomText:
          detailedStruc?.element?.publicPage?.['bottomText_' + currentLangCode],
        nps: detailedStruc?.element?.preferences?.nps,
        npsField: detailedStruc?.element?.preferences?.npsField,
        npsDays: detailedStruc?.element?.preferences?.npsDays,
        npsEmail:
          detailedStruc?.element?.preferences?.['npsEmail_' + currentLangCode],
        npsTemplate:
          detailedStruc?.element?.preferences?.[
          'npsTemplate_' + currentLangCode
          ],
        npsCommentMsg:
          detailedStruc?.element?.preferences?.[
          'npsCommentMsg_' + currentLangCode
          ],
      });

      //find the field with value 'status'
      const statusField = detailedStruc?.fields?.find(
        (field) => field?.value === 'status'
      );

      if (statusField) {
        setStatusFields(statusField?.selections || []);
      }

      setFields(detailedStruc?.fields || []);
    }
  }, [structureId, currentStrucure]);

  const handleCopyLink = (type) => {
    const url = `https://usenode.com/structure-public?structureId=${selectedStructure?.id}&businessId=${businessPreference?.id}&lang=${currentLangCode}&name=${selectedStructure?.name}`;
    const script = ` <script src="https://usenode.com/embedStructure.js" async></script>
    <script>
        const script = document.createElement('script');
        script.setAttribute('businessId', ${businessPreference?.id});
        script.setAttribute('structureId', ${selectedStructure?.id});
        script.setAttribute('width', '100%');
        script.setAttribute('height', '100%');
        script.setAttribute('typeForm', ${selectedStructure?.viewForm});
        document.body.appendChild(script);
    </script>`;

    const iframe = `
    <iframe src="https://usenode.com/structure-public?structureId=${selectedStructure?.id}&businessId=${businessPreference?.id}&lang=${currentLangCode}&name=${selectedStructure?.name}" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>`;
    navigator.clipboard
      .writeText(type === 'url' ? url : type === 'script' ? script : iframe)
      .then(
        () => {
          toast.success(t('copied'));
        },
        () => {
          toast.error(t('failed'));
        }
      );
  };

  const handleCloseEmpty = () => {
    setIsOpenIcon(false);
  };

  const handleCloseIcon = (iconName) => {
    setIsOpenIcon(false);
    setIcon(iconName);
    handleUpdateIcon(iconName);
  };

  const handleUpdateIcon = async (iconName) => {
    const updatedPreference = { ...selectedStructure, icon: iconName || icon };
    setSelectedStructure(updatedPreference);
  };

  const settingsSelection = [
    {
      id: 'settings',
      label: t('settings'),
    },
    {
      id: 'fields',
      label: t('fields'),
    },
    {
      id: 'attributes',
      label: t('attributes'),
    },
    {
      id: 'public',
      label: t('publicElement'),
    },
    {
      id: 'NPS',
      label: 'NPS',
    },
  ];

  return (
    <DrawerSide
      title={currentStrucure?.name || t('structure')}
      handleDrawerClose={closeSidebar}
      isDrawerOpen={isDrawerOpen}
      handleSave={handleSave}
      isCreation={true}
      width={currentSelection === 'fields' ? '120vh' : null}
      selection={settingsSelection}
      setCurrentSelection={setCurrentSelection}
      currentSelection={currentSelection}
    >
      {isOpenIcon && (
        <IconSelector
          handleCloseIcon={handleCloseIcon}
          isOpen={isOpenIcon}
          handleClose={handleCloseEmpty}
        />
      )}
      {currentSelection === 'settings' && (
        <div className="row">
          <div className="col-12">
            <TextField
              label={t('name')}
              value={selectedStructure?.name || ''}
              onChange={(e) => handleSaveField('name', e.target.value)}
              fullWidth
              key={selectedStructure?.id + 'name'}
              margin="dense"
            />
          </div>
          <div className="col-11">
            <Checkbox
              fieldType={'boolean'}
              staticField={false}
              defaultValue={false}
              required={false}
              label={t('isPublic')}
              value={selectedStructure?.isPublic || false}
              fullWidth
              onBlur={(event, value) =>
                handleSaveField('isPublic', event.target.checked)
              }
              onChange={(event, value) =>
                handleSaveField('isPublic', event.target.checked)
              }
            />
          </div>
          <div className="col-1 mt-3">
            <Tooltip title={t('updateIcon')}>
              <IconButton onClick={() => setIsOpenIcon(true)} size="large">
                <IconStructure />
              </IconButton>
            </Tooltip>
          </div>
          <div className="col-6"></div>
          <div className="col-12">
            <GeneralText
              text={t('actions')}
              primary={true}
              fontSize="12px"
              classNameComponent="mt-3 px-2"
              size="medium"
            />
          </div>
          {(selectedStructure?.collectionField !== 'items') && (
            <div className="col-4">
              <Checkbox
                fieldType={'boolean'}
                staticField={false}
                defaultValue={false}
                required={false}
                label={t('print')}
                value={selectedStructure?.print || false}
                fullWidth
                onBlur={(event, value) =>
                  handleSaveField('print', event.target.checked)
                }
                onChange={(event, value) =>
                  handleSaveField('print', event.target.checked)
                }
              />
            </div>
          )}
          {(selectedStructure?.collectionField === 'cardsuninvoiced' ||
            selectedStructure?.collectionField === 'cardsinvoiced' ||
            selectedStructure?.collectionField === 'grids' ||
            selectedStructure?.collectionField === 'contacts') && (
              <div className="col-4">
                <Checkbox
                  fieldType={'boolean'}
                  staticField={false}
                  defaultValue={false}
                  required={false}
                  label={t('email')}
                  value={selectedStructure?.email || false}
                  fullWidth
                  onBlur={(event, value) =>
                    handleSaveField('email', event.target.checked)
                  }
                  onChange={(event, value) =>
                    handleSaveField('email', event.target.checked)
                  }
                />
              </div>
            )}
          {(selectedStructure?.collectionField !== 'items' ||
            selectedStructure?.collectionField !== 'payments') && (
              <div className="col-4">
                <Checkbox
                  fieldType={'boolean'}
                  staticField={false}
                  defaultValue={false}
                  required={false}
                  label={t('create')}
                  value={selectedStructure?.creation || false}
                  fullWidth
                  onBlur={(event, value) =>
                    handleSaveField('creation', event.target.checked)
                  }
                  onChange={(event, value) =>
                    handleSaveField('creation', event.target.checked)
                  }
                />
              </div>
            )}
          <div className="col-4">
            <Checkbox
              fieldType={'boolean'}
              staticField={false}
              defaultValue={false}
              required={false}
              label={t('edit')}
              value={selectedStructure?.edit || false}
              fullWidth
              onBlur={(event, value) =>
                handleSaveField('edit', event.target.checked)
              }
              onChange={(event, value) =>
                handleSaveField('edit', event.target.checked)
              }
            />
          </div>
          {selectedStructure?.collectionField !== 'payments' && (
            <div className="col-4">
              <Checkbox
                fieldType={'boolean'}
                staticField={false}
                defaultValue={false}
                required={false}
                label={t('delete')}
                value={selectedStructure?.delete || false}
                fullWidth
                onBlur={(event, value) =>
                  handleSaveField('delete', event.target.checked)
                }
                onChange={(event, value) =>
                  handleSaveField('delete', event.target.checked)
                }
              />
            </div>
          )}
          {(selectedStructure?.collectionField !== 'items' ||
            selectedStructure?.collectionField !== 'payments') && (
              <div className="col-4">
                <Checkbox
                  fieldType={'boolean'}
                  staticField={false}
                  defaultValue={false}
                  required={false}
                  label={t('favorite')}
                  value={selectedStructure?.favorite || false}
                  fullWidth
                  onBlur={(event, value) =>
                    handleSaveField('favorite', event.target.checked)
                  }
                  onChange={(event, value) =>
                    handleSaveField('favorite', event.target.checked)
                  }
                />
              </div>
            )}
          <div className="col-4">
            <Checkbox
              fieldType={'boolean'}
              staticField={false}
              defaultValue={false}
              required={false}
              label={t('tags')}
              value={selectedStructure?.tags || false}
              fullWidth
              onBlur={(event, value) =>
                handleSaveField('tags', event.target.checked)
              }
              onChange={(event, value) =>
                handleSaveField('tags', event.target.checked)
              }
            />
          </div>
          {(selectedStructure?.collectionField !== 'items' ||
            selectedStructure?.collectionField !== 'payments' ||
            selectedStructure?.collectionField !== 'contacts' ||
            selectedStructure?.collectionField !== 'profiles') && (
              <div className="col-4">
                <Checkbox
                  fieldType={'boolean'}
                  staticField={false}
                  defaultValue={false}
                  required={false}
                  label={t('convert')}
                  value={selectedStructure?.convert || false}
                  fullWidth
                  onBlur={(event, value) =>
                    handleSaveField('convert', event.target.checked)
                  }
                  onChange={(event, value) =>
                    handleSaveField('convert', event.target.checked)
                  }
                />
              </div>
            )}
          <div className="col-4">
            <Checkbox
              fieldType={'boolean'}
              staticField={false}
              defaultValue={false}
              required={false}
              label={t('reference')}
              value={selectedStructure?.reference || false}
              fullWidth
              onBlur={(event, value) =>
                handleSaveField('reference', event.target.checked)
              }
              onChange={(event, value) =>
                handleSaveField('reference', event.target.checked)
              }
            />
          </div>
          {(selectedStructure?.collectionField === 'cardsuninvoiced' ||
            selectedStructure?.collectionField === 'cardsinvoiced') && (
              <div className="col-4">
                <Checkbox
                  fieldType={'boolean'}
                  staticField={false}
                  defaultValue={false}
                  required={false}
                  label={t('share')}
                  value={selectedStructure?.share || false}
                  fullWidth
                  onBlur={(event, value) =>
                    handleSaveField('share', event.target.checked)
                  }
                  onChange={(event, value) =>
                    handleSaveField('share', event.target.checked)
                  }
                />
              </div>
            )}
          {(selectedStructure?.collectionField === 'cardsuninvoiced' ||
            selectedStructure?.collectionField === 'cardsinvoiced') && (
              <div className="col-4">
                <Checkbox
                  fieldType={'boolean'}
                  staticField={false}
                  defaultValue={false}
                  required={false}
                  label={t('npsActivated')}
                  value={selectedStructure?.nps || false}
                  fullWidth
                  onBlur={(event, value) =>
                    handleSaveField('nps', event.target.checked)
                  }
                  onChange={(event, value) =>
                    handleSaveField('nps', event.target.checked)
                  }
                />
              </div>
            )}
          <div className="col-4"></div>
          <div className="col-12">
            <GeneralText
              text={t('parameters')}
              primary={true}
              fontSize="12px"
              classNameComponent="mt-3 px-2"
              size="medium"
            />
          </div>
          {(selectedStructure?.collectionField === 'cardsuninvoiced' ||
            selectedStructure?.collectionField === 'cardsinvoiced') && (
              <div className="col-4">
                <TextField
                  label={t('approvalTerm')}
                  value={selectedStructure?.approvalTerm || ''}
                  onChange={(e) =>
                    handleSaveField('approvalTerm', e.target.value)
                  }
                  fullWidth
                  key={selectedStructure?.id + 'approvalTerm'}
                  variant="outlined"
                />
              </div>
            )}
          {(selectedStructure?.collectionField !== 'items' ||
            selectedStructure?.collectionField !== 'payments' ||
            selectedStructure?.collectionField !== 'contacts' ||
            selectedStructure?.collectionField !== 'services' ||
            selectedStructure?.collectionField !== 'articles') && (
              <div className="col-4">
                <Select
                  select
                  staticView
                  noEmpty
                  key={selectedStructure?.id + 'statusDone'}
                  label={t('statusDone')}
                  value={selectedStructure?.statusDone}
                  selections={statusFields?.map((field) => {
                    return {
                      value: field?.value,
                      id: field?.value,
                      label: field?.['label_' + currentLangCode],
                    };
                  })}
                  onChange={(e, value) => handleSaveField('statusDone', value)}
                  fullWidth
                />
              </div>
            )}
          {(selectedStructure?.collectionField !== 'items' ||
            selectedStructure?.collectionField !== 'payments' ||
            selectedStructure?.collectionField !== 'contacts' ||
            selectedStructure?.collectionField !== 'services' ||
            selectedStructure?.collectionField !== 'articles') && (
              <div className="col-4">
                <Select
                  select
                  staticView
                  key={selectedStructure?.id + 'statusPending'}
                  label={t('statusPending')}
                  value={selectedStructure?.statusPending}
                  selections={statusFields?.map((field) => {
                    return {
                      value: field?.value,
                      id: field?.value,
                      label: field?.['label_' + currentLangCode],
                    };
                  })}
                  onChange={(e, value) => handleSaveField('statusPending', value)}
                  fullWidth
                />
              </div>
            )}
          {(selectedStructure?.collectionField === 'cardsuninvoiced' ||
            selectedStructure?.collectionField === 'cardsinvoiced') && (
              <div className="col-4">
                <Select
                  select
                  staticView
                  key={selectedStructure?.id + 'approvalMethod'}
                  label={t('approvalMethod')}
                  value={selectedStructure?.approvalMethod}
                  selections={[
                    {
                      value: 'all',
                      id: 'all',
                      label: t('allTog'),
                    },
                    {
                      value: 'selection',
                      id: 'selection',
                      label: t('selection'),
                    },
                  ]}
                  onChange={(e, value) =>
                    handleSaveField('approvalMethod', value)
                  }
                  fullWidth
                />
              </div>
            )}
          {(selectedStructure?.collectionField === 'cardsinvoiced' ||
            selectedStructure?.collectionField === 'cardsuninvoiced' ||
            selectedStructure?.collectionField === 'cardsexpense') &&
            fields?.filter((field) => field?.typeData === 'geo')?.length >
            0 && (
              <div className="col-4">
                <Select
                  select
                  staticView
                  noEmpty
                  key={selectedStructure?.id + 'billField'}
                  label={t('billField')}
                  value={selectedStructure?.billField}
                  selections={fields
                    ?.filter((field) => field?.typeData === 'geo')
                    ?.map((field) => {
                      return {
                        value: field?.value,
                        id: field?.value,
                        label: field?.name,
                      };
                    })}
                  onChange={(e, value) => handleSaveField('billField', value)}
                  fullWidth
                />
              </div>
            )}
          {(selectedStructure?.collectionField === 'cardsinvoiced' ||
            selectedStructure?.collectionField === 'cardsuninvoiced' ||
            selectedStructure?.collectionField === 'cardsexpense') &&
            fields?.filter((field) => field?.typeData === 'geo')?.length >
            0 && (
              <div className="col-4">
                <Select
                  select
                  staticView
                  noEmpty
                  key={selectedStructure?.id + 'shipField'}
                  label={t('shipField')}
                  value={selectedStructure?.shipField}
                  selections={fields
                    ?.filter((field) => field?.typeData === 'geo')
                    ?.map((field) => {
                      return {
                        value: field?.value,
                        id: field?.value,
                        label: field?.name,
                      };
                    })}
                  onChange={(e, value) => handleSaveField('shipField', value)}
                  fullWidth
                />
              </div>
            )}
          {(selectedStructure?.collectionField === 'cardsinvoiced' ||
            selectedStructure?.collectionField === 'cardsuninvoiced' ||
            selectedStructure?.collectionField === 'cardsexpense') && (
              <div className="col-4">
                <Select
                  select
                  staticView
                  noEmpty
                  key={selectedStructure?.id + 'matchedAttributes'}
                  label={t('matchedAttributes')}
                  value={selectedStructure?.matchedAttributes}
                  selections={[
                    {
                      value: [1, 2, 3, 4],
                      id: 'all4',
                      label: t('1234'),
                    },
                    {
                      value: [1, 2, 3],
                      id: 'all3',
                      label: t('123'),
                    },
                    {
                      value: [1, 2],
                      id: 'all2',
                      label: t('12'),
                    },
                    {
                      value: [1],
                      id: 'all1',
                      label: t('1'),
                    },
                  ]}
                  onChange={(e, value) =>
                    handleSaveField('matchedAttributes', value)
                  }
                  fullWidth
                />
              </div>
            )}
          {(selectedStructure?.collectionField === 'cardsinvoiced' ||
            selectedStructure?.collectionField === 'cardsuninvoiced' ||
            selectedStructure?.collectionField === 'cardsexpense') && (
              <div className="col-4">
                <Select
                  select
                  staticView
                  noEmpty
                  key={selectedStructure?.id + 'matchedValue'}
                  label={t('matchedValue')}
                  value={selectedStructure?.matchedValue}
                  selections={[
                    {
                      value: 'targetProfileDetails',
                      id: 'targetProfileDetails',
                      label: t('targetProfileDetails'),
                    },
                    {
                      value: 'targetDetails',
                      id: 'targetDetails',
                      label: t('targetDetails'),
                    },
                  ]}
                  onChange={(e, value) => handleSaveField('matchedValue', value)}
                  fullWidth
                />
              </div>
            )}
        </div>
      )}
      {currentSelection === 'attributes' && (
        <div className="row">
          <div>
            <Select
              label={t('attribute1')}
              key={selectedStructure?.id + 'attribute1'}
              selections={fields?.map((field) => {
                return {
                  value: field?.value,
                  id: field?.value,
                  label: field?.name,
                };
              })}
              value={selectedStructure?.attribute1}
              onChange={(e, value) => handleSaveField('attribute1', value)}
              fullWidth
            />
          </div>
          <div>
            <Select
              key={selectedStructure?.id + 'attribute2'}
              label={t('attribute2')}
              value={selectedStructure?.attribute2}
              selections={fields?.map((field) => {
                return {
                  value: field?.value,
                  id: field?.value,
                  label: field?.name,
                };
              })}
              onChange={(e, value) => handleSaveField('attribute2', value)}
              fullWidth
            />
          </div>
          <div>
            <Select
              key={selectedStructure?.id + 'attribute3'}
              label={t('attribute3')}
              value={selectedStructure?.attribute3}
              selections={fields?.map((field) => {
                return {
                  value: field?.value,
                  id: field?.value,
                  label: field?.name,
                };
              })}
              onChange={(e, value) => handleSaveField('attribute3', value)}
              fullWidth
            />
          </div>
          <div>
            <Select
              key={selectedStructure?.id + 'attribute4'}
              label={t('attribute4')}
              value={selectedStructure?.attribute4}
              selections={fields?.map((field) => {
                return {
                  value: field?.value,
                  id: field?.value,
                  label: field?.name,
                };
              })}
              onChange={(e, value) => handleSaveField('attribute4', value)}
              fullWidth
            />
          </div>
        </div>
      )}
      {currentSelection === 'fields' && (
        <div className="row">
          <FieldEditor fields={fields} setFields={setFields} />
        </div>
      )}
      {currentSelection === 'public' && (
        <>
          {currentStrucure?.element?.isPublic ? (
            <div className="row">
              <div className="col-12">
                <GeneralText
                  text={t('element')}
                  primary={true}
                  fontSize="12px"
                  classNameComponent="mt-3 px-2"
                  size="medium"
                />
              </div>
              <div className="col-4">
                <Checkbox
                  fieldType={'boolean'}
                  staticField={false}
                  defaultValue={false}
                  required={false}
                  label={t('trackActivity')}
                  value={selectedStructure?.trackActivity || false}
                  fullWidth
                  onBlur={(event, value) =>
                    handleSaveField('trackActivity', event.target.checked)
                  }
                  onChange={(event, value) =>
                    handleSaveField('trackActivity', event.target.checked)
                  }
                />
              </div>
              <div className="col-4">
                <Checkbox
                  fieldType={'boolean'}
                  staticField={false}
                  defaultValue={false}
                  required={false}
                  label={t('accessCodeRequired')}
                  value={selectedStructure?.accessCodeRequired || false}
                  fullWidth
                  onBlur={(event, value) =>
                    handleSaveField('accessCodeRequired', event.target.checked)
                  }
                  onChange={(event, value) =>
                    handleSaveField('accessCodeRequired', event.target.checked)
                  }
                />
              </div>

              <div className="col-4">
                <Select
                  select
                  noEmpty
                  staticView
                  key={selectedStructure?.id + 'view'}
                  label={t('view')}
                  value={selectedStructure?.view}
                  selections={[
                    {
                      value: 'default',
                      id: 'default',
                      label: t('default'),
                    },
                    {
                      value: 'tabs-default',
                      id: 'tabs-default',
                      label: t('tabs-default'),
                    },
                    {
                      value: 'custom',
                      id: 'custom',
                      label: t('custom'),
                    },
                  ]}
                  onChange={(e, value) => handleSaveField('view', value)}
                  fullWidth
                />
              </div>
              <div className="col-12">
                <TextField
                  multiline
                  label={t('publicText')}
                  value={selectedStructure?.bottomText || ''}
                  onChange={(e) =>
                    handleSaveField('bottomText', e.target.value)
                  }
                  fullWidth
                  key={selectedStructure?.id + 'bottomText'}
                />
              </div>
              <div className="col-12 mt-4">
                <GeneralText
                  text={t('form')}
                  primary={true}
                  fontSize="12px"
                  classNameComponent="mt-3 px-2"
                  size="medium"
                />
              </div>
              <div className="col-4">
                <Select
                  select
                  noEmpty
                  staticView
                  key={selectedStructure?.id + 'viewForm'}
                  label={t('viewForm')}
                  value={selectedStructure?.viewForm}
                  selections={[
                    {
                      value: 'default',
                      id: 'default',
                      label: t('default'),
                    },
                    {
                      value: 'chat',
                      id: 'chat',
                      label: t('chat'),
                    },
                    {
                      value: 'onebyone',
                      id: 'onebyone',
                      label: t('onebyone'),
                    },
                  ]}
                  onChange={(e, value) => handleSaveField('viewForm', value)}
                  fullWidth
                />
              </div>
              <div className="col-4">
                <Checkbox
                  fieldType={'boolean'}
                  staticField={false}
                  defaultValue={false}
                  required={false}
                  label={t('displayLogo')}
                  value={selectedStructure?.displayLogo ?? false}
                  fullWidth
                  onBlur={(event, value) =>
                    handleSaveField('displayLogo', event.target.checked)
                  }
                  onChange={(event, value) =>
                    handleSaveField('displayLogo', event.target.checked)
                  }
                />
              </div>
              <div className="col-4">
                <TextField
                  label={t('btnStart')}
                  value={selectedStructure?.btnStart || ''}
                  onChange={(e) => handleSaveField('btnStart', e.target.value)}
                  fullWidth
                  key={selectedStructure?.id + 'btnStart'}
                  variant="outlined"
                />
              </div>
              <div className="col-6">
                <TextField
                  label={t('bgImage')}
                  value={selectedStructure?.bgImage || ''}
                  onChange={(e) => handleSaveField('bgImage', e.target.value)}
                  fullWidth
                  key={selectedStructure?.id + 'bgImage'}
                  variant="outlined"
                />
              </div>

              <div className="col-12">
                <TextField
                  multiline
                  label={t('intro')}
                  value={selectedStructure?.intro || ''}
                  onChange={(e) => handleSaveField('intro', e.target.value)}
                  fullWidth
                  key={selectedStructure?.id + 'intro'}
                  variant="outlined"
                />
              </div>
              <div className="col-12 mt-4">
                <GeneralText
                  text={t('integrations')}
                  primary={true}
                  fontSize="12px"
                  classNameComponent="mt-3 px-2"
                  size="medium"
                />
              </div>
              <div className="mt-3 d-flex p-4">
                <Button
                  variant="text"
                  onClick={() => handleCopyLink('url')}
                  fullWidth
                  label={t('copyLink')}
                  endIcon="InsertLinkOutlined"
                />

                <Button
                  variant="text"
                  onClick={() => handleCopyLink('iframe')}
                  fullWidth
                  label={t('copyiFrame')}
                  endIcon="LaptopOutlined"
                  buttonSx={{ marginLeft: '20px' }}
                />
              </div>
            </div>
          ) : (
            <div className="row align-c px-4 mb-4">
              <Blocks
                noBorder
                noShadow
                noScroll
                empty={true}
                emptyMessage={t('youmustActivate')}
                emptyType={'activate'}
              />
              <Button
                variant="text"
                onClick={handleActivatePublic}
                fullWidth
                label={t('activate')}
                endIcon="PublicOutlined"
                buttonSx={{ marginTop: '20px' }}
              />
            </div>
          )}
        </>
      )}
      {currentSelection === 'NPS' && (
        <div className="row">
          <div className="col-8">
            <Select
              select
              noEmpty
              staticView
              key={selectedStructure?.id + 'npsField'}
              label={t('npsField')}
              value={selectedStructure?.npsField}
              selections={fields?.map((field) => {
                return {
                  value: field?.value,
                  id: field?.value,
                  label: field?.name,
                };
              })}
              onChange={(e, value) => handleSaveField('npsField', value)}
              fullWidth
            />
          </div>
          <div className="col-4">
            <TextField
              label={t('npsDays')}
              value={selectedStructure?.npsDays || 30}
              onChange={(e) => handleSaveField('npsDays', e.target.value)}
              type="number"
              fullWidth
              key={selectedStructure?.id + 'npsDays'}
              variant="outlined"
            />
          </div>

          <div className="col-8">
            <TextField
              multiline
              label={t('npsEmail')}
              value={selectedStructure?.npsEmail || ''}
              onChange={(e) => handleSaveField('npsEmail', e.target.value)}
              fullWidth
              key={selectedStructure?.id + 'npsEmail'}
              variant="outlined"
            />
          </div>
          <div className="col-4">
            <Select
              select
              noEmpty
              staticView
              fullWidth
              label={t('npsTemplate')}
              value={selectedStructure?.npsTemplate || ''}
              onChange={(e, value) => handleSaveField('npsTemplate', value)}
              key={selectedStructure?.id + 'npsTemplate'}
              selections={businessPreference?.emails?.map((field) => {
                return {
                  value: field?.id,
                  id: field?.id,
                  label: field?.name,
                };
              })}
            />
          </div>
          <div className="col-12">
            <TextField
              multiline
              label={t('npsCommentMsg')}
              value={selectedStructure?.npsCommentMsg || ''}
              onChange={(e) => handleSaveField('npsCommentMsg', e.target.value)}
              fullWidth
              key={selectedStructure?.id + 'npsCommentMsg'}
              variant="outlined"
            />
          </div>
        </div>
      )}
    </DrawerSide>
  );
};

export default StructureSidebar;
