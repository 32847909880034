import * as React from 'react';
const ListSVG = ({ color = '#e21e1e' }) => (
  <svg
    id="Calque_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13387.5 4687.5"
  >
    <defs>
      <style>
        {
          '.cls-1{fill:#c1c1c1;}.cls-2{fill:#e5e5e5;}.cls-3{fill:#9b9b9b;}.cls-4{fill:#676767;}'
        }
      </style>
    </defs>
    <g id="Calque_1-2-94">
      <rect
        fill="#e5e5e5"
        x={0}
        width={13387.5}
        height={4687.5}
        rx={293.09}
        ry={293.09}
      />
      <rect
        fill={color}
        x={672.34}
        y={688.6}
        width={3356.03}
        height={525.49}
        rx={218.04}
        ry={218.04}
      />
      <rect
        className="cls-1"
        x={672.34}
        y={1343.16}
        width={1988.54}
        height={321.63}
        rx={139.8}
        ry={139.8}
      />
      <rect
        fill={color}
        x={672.34}
        y={2515.97}
        width={2532.34}
        height={525.49}
        rx={218.04}
        ry={218.04}
      />
      <rect
        className="cls-1"
        x={672.34}
        y={3170.53}
        width={2900.36}
        height={321.63}
        rx={139.8}
        ry={139.8}
      />
      <rect
        fill={color}
        x={5021.04}
        y={641.97}
        width={2054.93}
        height={525.49}
        rx={218.04}
        ry={218.04}
      />
      <rect
        className="cls-1"
        x={5021.04}
        y={1296.53}
        width={1228.44}
        height={321.63}
        rx={139.8}
        ry={139.8}
      />
      <rect
        fill={color}
        x={5021.04}
        y={2469.34}
        width={1616.21}
        height={525.49}
        rx={218.04}
        ry={218.04}
      />
      <rect
        className="cls-1"
        x={5021.04}
        y={3123.9}
        width={943.68}
        height={321.63}
        rx={139.8}
        ry={139.8}
      />
      <rect
        fill={color}
        x={7696.38}
        y={642.72}
        width={1329.68}
        height={525.49}
        rx={218.04}
        ry={218.04}
      />
      <rect
        className="cls-1"
        x={7696.38}
        y={1297.28}
        width={865.46}
        height={321.63}
        rx={139.8}
        ry={139.8}
      />
      <rect
        fill={color}
        x={7696.38}
        y={2470.09}
        width={1695.32}
        height={525.49}
        rx={218.04}
        ry={218.04}
      />
      <rect
        className="cls-1"
        x={7696.38}
        y={3124.65}
        width={1081.06}
        height={321.63}
        rx={139.8}
        ry={139.8}
      />
      <rect
        fill={color}
        x={9941.26}
        y={643.72}
        width={2050.6}
        height={525.49}
        rx={218.04}
        ry={218.04}
      />
      <rect
        className="cls-1"
        x={9941.26}
        y={1298.28}
        width={1334.69}
        height={321.63}
        rx={139.8}
        ry={139.8}
      />
      <rect
        fill={color}
        x={9941.26}
        y={2471.09}
        width={2831.2}
        height={525.49}
        rx={218.04}
        ry={218.04}
      />
      <rect
        className="cls-1"
        x={9941.26}
        y={3125.65}
        width={609.34}
        height={321.63}
        rx={139.8}
        ry={139.8}
      />
      <rect
        className="cls-3"
        x={683.34}
        y={1985.5}
        width={11814.91}
        height={81}
      />
      <rect
        className="cls-3"
        x={675.04}
        y={3794.25}
        width={11814.91}
        height={81}
      />
    </g>
  </svg>
);

export default ListSVG;
