import { Routes, Route } from 'react-router-dom';

//general
import PrivateRoute from './components/PrivateRoute';
import Settings from './screens/settings/Settings';
import UsageBilling from './screens/settings/UsageBilling';
import SettingsTeam from './screens/settings/SettingsTeam';
import ElementDetails from './screens/mainPages/ElementDetails';
import ModuleList from './screens/mainPages/ModuleList';
import NodeMaster from './screens/specific/NodeMaster';
import Node from './screens/specific/Node';
import SettingsLocations from './screens/settings/SettingsLocations';
import ListDrop from './screens/lists/ListDrop';
import Inbox from './screens/mainPages/Inbox';
import Scheduled from './screens/specific/Scheduled';
import OverviewFlows from './screens/specific/OverviewFlows';
import UserParams from './screens/mainPages/UserParams';
import NodeAI from './sidebars/NodeAI';
import DashboardNode from './screens/specific/DashboardNode';
import NodeToolsDev from './screens/specific/NodeToolsDev';

const PrivateRoutes = () => {


  return (
    <Routes>
      <Route
        path="/dashboard-node"
        element={
          <PrivateRoute
            permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER', 'PERSONAL']}
          >
            <DashboardNode />
          </PrivateRoute>
        }
      />
      <Route
        path="/me"
        element={
          <PrivateRoute
            permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER', 'PERSONAL']}
          >
            <UserParams />
          </PrivateRoute>
        }
      />
      <Route
        path="/node-ai"
        element={
          <PrivateRoute
            permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER', 'PERSONAL']}
          >
            <NodeAI />
          </PrivateRoute>
        }
      />

      [///////////////////////// General //////////////////////////]
      <Route
        path="/:collection/:moduleName/:moduleId/:structureId"
        element={
          <PrivateRoute permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER']}>
            <ModuleList />
          </PrivateRoute>
        }
      />
      <Route
        path="/:collection/:moduleName/:moduleId/:structureId/:segmentId"
        element={
          <PrivateRoute permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER']}>
            <ModuleList />
          </PrivateRoute>
        }
      />
      <Route
        path="/element/:moduleName/:structureId/:elementId"
        element={
          <PrivateRoute permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER']}>
            <ElementDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/nodes/:nodeId"
        element={
          <PrivateRoute permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER']}>
            <ListDrop />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/flows"
        element={
          <PrivateRoute permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER']}>
            <OverviewFlows />
          </PrivateRoute>
        }
      />
      [///////////////////////// Finances //////////////////////////]
      <Route
        path="/settings/master"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <NodeMaster />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/scheduled"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <Scheduled />
          </PrivateRoute>
        }
      />
      <Route
        path="/inbox"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <Inbox />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/locations"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <SettingsLocations />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/page"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <Node />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/preferences"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <Node />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/usage"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <UsageBilling />
          </PrivateRoute>
        }
      />

      <Route
        path="/settings"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <Settings />
          </PrivateRoute>
        }
      />

      <Route
        path="/settings/team"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <SettingsTeam />
          </PrivateRoute>
        }
      />

    </Routes>
  );
};

export default PrivateRoutes;
