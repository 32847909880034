import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Chip,
  List,
  ListItemText,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Button,
  TextField,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { ref, onValue } from 'firebase/database';
import { realtimeDb } from '../../firebase';
import Blocks from '../../stories/layout-components/Block.jsx';
import GeneralText from '../../stories/general-components/GeneralText.jsx';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase.js';
import MainLayoutV2 from '../../layouts/MainLayoutV2.jsx';

const DashboardNode = () => {
  const { t, } = useTranslation();
  const [calls, setCalls] = useState();
  const [nodeData, setNodeData] = useState();
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [websiteVisits, setWebsiteVisits] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [activeRoutes, setActiveRoutes] = useState(0);
  const [objectives, setObjectives] = useState([]); // Removed initial data
  const [newObjectiveDate, setNewObjectiveDate] = useState('');
  const [newObjectiveDescription, setNewObjectiveDescription] = useState('');
  const [openObjectiveDialog, setOpenObjectiveDialog] = useState(false); // State for the modal

  const formattedDate = moment().add(5, 'hours').format('YYYY-MM-DD-HH');
  const formattedDateDisplay = moment().format('DD MMM HH');
  const formattedDateEndDisplay = moment().add(1, 'hours').format('HH');

  const customerGoal = 500;
  const salesGoal = 1000000;

  const customerPercentage = nodeData?.businesses?.length ? (nodeData.businesses.length / customerGoal) * 100 : 0;
  const salesPercentage = nodeData?.sales ? (nodeData.sales / salesGoal) * 100 : 0;

  useEffect(() => {
    const metricsRef = ref(realtimeDb, `metrics/${formattedDate}`);

    const unsubscribe = onValue(
      metricsRef,
      (snapshot) => {
        const data = snapshot.val();

        if (data) {
          const sumOfCalls = Object.values(data);
          const routes = sumOfCalls
            ?.map((route) => {
              return (
                Object.values(route)?.reduce((acc, val) => {
                  return acc + val;
                }, 0) || 0
              );
            })
            ?.reduce((acc, val) => {
              return acc + val;
            }, 0);

          setCalls(routes);
        } else {
          setCalls(0);
        }
      },
      (error) => {
        console.error('Error reading Realtime Database:', error);
      }
    );

    return () => unsubscribe();
  }, [formattedDate, lastRefresh]);

  const getNodeDashboard = async () => {
    try {
      const pageData = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/node-dashboard`,
        errorToast: t('errorLoadingDocument'),
        body: {},
      });
      setNodeData(pageData);
      setWebsiteVisits(pageData?.websiteVisits || 0);
      setTotalUsers(pageData?.totalUsers || 0);
      setActiveRoutes(pageData?.activeRoutes || 0);
    } catch (error) {
      console.error('Error getting node dashboard:', error);
    }
  };

  useEffect(() => {
    getNodeDashboard();
    const interval = setInterval(() => {
      getNodeDashboard();
      setLastRefresh(new Date());
    }, 900000);
    return () => clearInterval(interval);
  }, []);

  const handleOpenObjectiveDialog = () => {
    setOpenObjectiveDialog(true);
  };

  const handleCloseObjectiveDialog = () => {
    setOpenObjectiveDialog(false);
    setNewObjectiveDate('');
    setNewObjectiveDescription('');
  };

  const handleAddObjective = () => {
    if (newObjectiveDate && newObjectiveDescription) {
      setObjectives([...objectives, { date: newObjectiveDate, description: newObjectiveDescription, completed: false }]);
      handleCloseObjectiveDialog(); // Close the dialog after adding
    }
  };

  const actions = {
    add: handleOpenObjectiveDialog,
  };

  return (
    <MainLayoutV2 pageTitle={t('nodeDashboard')} icon='AdjustOutlined' subTitle='Objectifs et suivis' actions={actions}>
      <div className="p-3 row">
        {/* Calls Block */}
        <div className="col-3 mb-1">
          <Blocks noScroll heightPercentage={20} height={5} border>
            <div className="middle-content align-c p-3">
              <GeneralText text={calls} fontSize="48px" size="bold" primary={true} />
              <GeneralText text={t('calls')} fontSize="12px" size="medium" primary={true} />
              <Chip
                label={`${formattedDateDisplay}-${formattedDateEndDisplay}h`}
                size="small"
                style={{
                  backgroundColor: '#000000',
                  color: '#fff',
                  marginTop: '5px',
                  height: '18px',
                  '& .MuiChipLabel': { fontSize: '7px' },
                }}
              />
            </div>
          </Blocks>
        </div>

        {/* Accounts Block */}
        <div className="col-3 mb-1">
          <Blocks noScroll heightPercentage={20} height={5} border>
            <div className="middle-content align-c p-3">
              <GeneralText text={nodeData?.businesses?.length || 0} fontSize="48px" size="bold" primary={true} />
              <GeneralText text={t('accounts')} fontSize="16px" size="medium" primary={true} />
            </div>
          </Blocks>
        </div>

        {/* Sales Block */}
        <div className="col-3 mb-1">
          <Blocks noScroll heightPercentage={20} height={5} border>
            <div className="middle-content align-c p-3">
              <GeneralText text={(Number(nodeData?.sales / 10000) || 0)?.toFixed(2) + ' $'} fontSize="42px" size="bold" primary={true} />
              <GeneralText text={t('sales')} fontSize="16px" size="medium" primary={true} />
            </div>
          </Blocks>
        </div>

        <div className="col-3 mb-1">
          <Blocks noScroll heightPercentage={20} height={5} border>
            <div className="middle-content align-c p-3">
              <GeneralText text={t('activeRoutes')} fontSize="16px" size="medium" primary={true} style={{ marginTop: '10px' }} />
              <GeneralText text={activeRoutes} fontSize="24px" size="bold" primary={true} />
            </div>
          </Blocks>
        </div>

        {/* Thermometers Block */}
        <div className="col-5 mb-1">
          <Blocks noScroll heightPercentage={20} height={5} border>
            <div className="middle-content align-c p-3">
              <GeneralText text={t('customers')} fontSize="14px" size="bold" primary={true} />
              <LinearProgress variant="determinate" value={customerPercentage} sx={{ height: 10, borderRadius: 5, marginTop: '5px' }} />
              <GeneralText text={`${nodeData?.businesses?.length || 0} / ${customerGoal}`} fontSize="12px" size="medium" primary={true} />
              <GeneralText text={t('sales')} fontSize="14px" size="bold" primary={true} style={{ marginTop: '20px' }} />
              <LinearProgress variant="determinate" value={salesPercentage} sx={{ height: 10, borderRadius: 5, marginTop: '5px' }} />
              <GeneralText text={`$${((nodeData?.sales || 0) / 10000).toFixed(2)}K / $${(salesGoal / 1000000).toFixed(0)}M`} fontSize="12px" size="medium" primary={true} />
            </div>
          </Blocks>
        </div>

        {/* Red Dots Block */}
        <div className="col-4 mb-1">
          <Blocks noScroll heightPercentage={20} height={5} border>
            <div className="middle-content align-c p-3">
              {/*  Removed the extra div*/}
              <List>
                {nodeData?.redDots?.map((dot, index) => (
                  <ListItem divider key={index}>
                    <ListItemText primary={dot?.name} />
                  </ListItem>
                ))}
              </List>
            </div>
          </Blocks>
        </div>


        {/* Metrics Block */}
        <div className="col-3 mb-1">
          <Blocks noScroll heightPercentage={20} height={5} border>
            <div className="middle-content align-c p-3">
              <GeneralText text={t('websiteVisits')} fontSize="16px" size="medium" primary={true} />
              <GeneralText text={websiteVisits} fontSize="24px" size="bold" primary={true} />
              <GeneralText text={t('totalUsers')} fontSize="16px" size="medium" primary={true} style={{ marginTop: '10px' }} />
              <GeneralText text={totalUsers} fontSize="24px" size="bold" primary={true} />

            </div>
          </Blocks>
        </div>


        {/* Objectives Table Block */}
        <div className="col-8 mb-1">
          <Blocks noScroll heightPercentage={40} height={5} border>
            <div className="p-3">
              <GeneralText text={t('objectives')} fontSize="14px" size="bold" primary={true} />
              <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="objectives table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('date')}</TableCell>
                      <TableCell>{t('description')}</TableCell>
                      <TableCell>{t('status')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {objectives.map((objective, index) => (
                      <TableRow key={index}>
                        <TableCell>{objective.date}</TableCell>
                        <TableCell>{objective.description}</TableCell>
                        <TableCell>{objective.completed ? t('completed') : t('pending')}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Blocks>
        </div>
      </div>

      {/* Objective Dialog */}
      <Dialog open={openObjectiveDialog} onClose={handleCloseObjectiveDialog}>
        <DialogTitle>{t('addObjective')}</DialogTitle>
        <DialogContent>
          <TextField
            label={t('date')}
            type="date"
            value={newObjectiveDate}
            onChange={(e) => setNewObjectiveDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            sx={{ marginTop: 2 }}
          />
          <TextField
            label={t('description')}
            value={newObjectiveDescription}
            onChange={(e) => setNewObjectiveDescription(e.target.value)}
            fullWidth
            sx={{ marginTop: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseObjectiveDialog}>{t('cancel')}</Button>
          <Button onClick={handleAddObjective} variant="contained">{t('add')}</Button>
        </DialogActions>
      </Dialog>
    </MainLayoutV2>
  );
};

export default DashboardNode;