import * as Actions from '../actions/modal-actions';
const initialState = {
  modalConfirmation: { isOpen: false },
  modalAddItem: { isOpen: false },
  modalElementCreation: { isOpen: false },
  modalScan: { isOpen: false },
  modalInvoice: { isOpen: false },
  modalContact: { isOpen: false },
  modalEmail: { isOpen: false },
  modalQRCode: { isOpen: false },
  modalEmployee: { isOpen: false },
  modalSelect: { isOpen: false },
  modalGemini: { isOpen: false },
  modalConvert: { isOpen: false },
  modalPulseCreation: { isOpen: false },
  modalConnector: { isOpen: false },
};

export const modalReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.MODAL_APPOINTMENT_CONFIRMATION:
      return { ...initialState, modalAppointmentConfirmation: payload };
    case Actions.MODAL_CONFIRMATION:
      return { ...initialState, modalConfirmation: payload };
    case Actions.MODAL_OPTIONS:
      return { ...initialState, modalOptions: payload };
    case Actions.MODAL_CONTACT:
      return { ...initialState, modalContact: payload };
    case Actions.MODAL_CONVERT:
      return { ...initialState, modalConvert: payload };
    case Actions.MODAL_HANDLE_OPTIONS:
      return { ...initialState, modalHandleOptions: payload };
    case Actions.MODAL_ADD_ITEM:
      return { ...initialState, modalAddItem: payload };
    case Actions.MODAL_ELEMENT_CREATION:
      return { ...initialState, modalElementCreation: payload };
    case Actions.MODAL_PULSE_CREATION:
      return { ...initialState, modalPulseCreation: payload };
    case Actions.MODAL_GEMINI:
      return { ...initialState, modalGemini: payload };
    case Actions.MODAL_SCAN:
      return { ...initialState, modalScan: payload };
    case Actions.MODAL_INVOICE:
      return { ...initialState, modalInvoice: payload };
    case Actions.MODAL_CREATE_VARIANT:
      return { ...initialState, modalCreateVariant: payload };
    case Actions.MODAL_EMAIL:
      return { ...initialState, modalEmail: payload };
    case Actions.MODAL_STATEMENT:
      return { ...initialState, modalStatement: payload };
    case Actions.MODAL_QR_CODE:
      return { ...initialState, modalQRCode: payload };
    case Actions.MODAL_CONNECTORS:
      return { ...initialState, modalConnectors: payload };
    case Actions.MODAL_SIGN_IN:
      return { ...initialState, modalSignIn: payload };
    case Actions.MODAL_SCRIPT:
      return { ...initialState, modalScript: payload };
    case Actions.MODAL_CONNECTOR:
      return { ...initialState, modalConnector: payload };
    case Actions.MODAL_EMPLOYEE:
      return { ...initialState, modalEmployee: payload };
    default:
      return state;
  }
};
